import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { KamikazeActions } from '../store/actions';
import { selectUserData } from '../store/selectors';
import { CheckCoordinatesWithinCaflerAreaValidator } from '../validators/checkCoordinatesWithinCaflerArea.validator';
import { forcePresentDateValidator } from '../validators/forcePresentDate.validator';
import { notSameDirectionValidator } from '../validators/notSameDirection.validator';
import { googleMapsInputSuggestion } from '../validators/googleMapsInputSuggestion.validator';
import { PickUpDropOffDateValidator } from '../validators/pickUpDropOffDate.validator';
import { GoogleAddress } from '@cafler/common-ui';

@Injectable()
export class FormsHelperService {
  userData: any = {};
  constructor(
    private store: Store,
    private checkCoordinatesWithinCaflerAreaValidator: CheckCoordinatesWithinCaflerAreaValidator,
    private pickUpDropOffDateValidator: PickUpDropOffDateValidator
  ) {
    this.store.select(selectUserData).subscribe((userData) => (this.userData = userData));
  }
  private currentForm!: FormGroup;

  initializeForm(data: any, initialFormValues?: any) { //TODO CHANGE TYPE
    const fg = new FormGroup({});
    for (let key in data) {
     

      let defaultValue = initialFormValues ? initialFormValues[key] : null
      
      if(['pickupAddress', 'dropoffAddress', 'deliveryAddress'].indexOf(key) > -1 && defaultValue){
        const {address, pickupAddressLatitud, pickupAddressLongitud} = defaultValue
        defaultValue = this.buildGoogleAddress(address, pickupAddressLongitud, pickupAddressLatitud)
      }
      
      if(['pickupDate', 'dropoffDate', 'deliveryDate', 'appointmentDate'].indexOf(key) > -1){
        if(new Date(defaultValue) < new Date){
          defaultValue = null
        }
      }
      
      fg.addControl(key, new FormControl(defaultValue, []));
      
      if (key === 'phone' || key === 'pickupPhone' || key === 'dropoffPhone')
      fg.controls[key].addValidators([
        Validators.pattern('[- +()0-9]+'),
        Validators.minLength(5),
      ]);
      if (key === 'email' || key === 'pickupEmail' || key === 'dropoffEmail')
      fg.controls[key].addValidators(Validators.email);
      if (['pickupDate', 'dropoffDate', 'deliveryDate', 'appointmentDate'].indexOf(key) > -1) {
        fg.controls[key].addValidators(forcePresentDateValidator());
      }
      if (['pickupAddress', 'dropoffAddress', 'deliveryAddress'].indexOf(key) > -1) {
        this.addValidatorsAutosuggest(fg, key);
      }
      if (key === 'amount') fg.controls[key].addValidators([Validators.min(5), Validators.max(1000)]);
      if (data[key].requiredTrue) fg.controls[key].addValidators(Validators.requiredTrue);
      if (data[key].required !== false) fg.controls[key].addValidators(Validators.required);
      if (key === "termsAndConditions" || key ===  "minimumRequirements") {
        fg.controls[key].addValidators(Validators.requiredTrue);
      }     
    }
    this.currentForm = fg;
    return fg;
  }

  getCurrentForm() {
    return this.currentForm;
  }

  checkCurrentFormValidity(): string {
    for(let control in this.currentForm.controls){
      if(this.currentForm.controls[control].value === "defaultValue"){
        this.currentForm.get(control)?.setValue(null);
        return "INVALID";
      }
    }
    this.currentForm.updateValueAndValidity();
    return this.currentForm.status;
  }

  handleFormToppingsValidity(status: string, data: any, store = true) {
    if (status === 'VALID') {
      this.store.dispatch({ type: KamikazeActions.SetFormValidity, isFormValid: true });
      if (store) this.store.dispatch({ type: KamikazeActions.StoreToppingData, toppingData: data });
    } else if (status === 'PENDING') {
      this.store.dispatch({ type: KamikazeActions.SetFormValidity, isFormValid: false });
    } else {
      this.store.dispatch({ type: KamikazeActions.SetFormValidity, isFormValid: false });
      if (store) this.store.dispatch({ type: KamikazeActions.StoreToppingData, toppingData: null });
    }
  }

  handleFormValidity(status: string, data: any, store = true) {
    if (status === 'VALID') {
      this.store.dispatch({ type: KamikazeActions.SetFormValidity, isFormValid: true });
      if (store) this.store.dispatch({ type: KamikazeActions.StoreProductData, productData: data });
    } else if (status === 'PENDING') {
      this.store.dispatch({ type: KamikazeActions.SetFormValidity, isFormValid: false });
    } else {
      this.store.dispatch({ type: KamikazeActions.SetFormValidity, isFormValid: false });
      if (store) this.store.dispatch({ type: KamikazeActions.StoreProductData, productData: null });
    }
  }

  preloadB2BData(fg: FormGroup) {
    const fields = [
      'pickupEmail',
      'pickupPhone',
      'pickupCountryPhoneCode',
      'dropoffEmail',
      'dropoffPhone',
      'dropoffCountryPhoneCode',
      'email',
      'phone',
      'countryPhoneCode',
      'pickupFullName',
      'dropoffFullName',
      'fullName',
    ];
    for (let field of fields) {
      fg.controls.hasOwnProperty(field) &&
        fg.controls[field].setValue(this.getUserDataField(field));
    }
    return fg;
  }

  buildGoogleAddress(address: string, long: number, lat: number): GoogleAddress{
    return new GoogleAddress(address, {
      location: {
        latitude: lat,
        longitude: long,
      },
    });
  
  }
  
  private getUserDataField(field: string) {
    switch (field) {
      case 'email':
      case 'pickupEmail':
      case 'dropoffEmail':
        return this.userData.userData.email;
      case 'phone':
      case 'pickupPhone':
      case 'dropoffPhone':
        let phoneNumber = this.userData.userData.phoneNumber;
        if (phoneNumber.substring(0, 1) === '(') {
          phoneNumber = this.userData.userData.phoneNumber.substring(
            5,
            this.userData.userData.phoneNumber.length,
          );
        } else {
          phoneNumber = this.userData.userData.phoneNumber.substring(
            3,
            this.userData.userData.phoneNumber.length,
          );
        }
        return phoneNumber;
      case 'countryPhoneCode':
      case 'pickupCountryPhoneCode':
      case 'dropoffCountryPhoneCode':
        let contryCode = this.userData.userData.phoneNumber;
        if (contryCode.substring(0, 1) === '(') {
          contryCode = contryCode.substring(1, 4);
        } else {
          contryCode = contryCode.substring(0, 3);
        }
        return contryCode;
      case 'fullName':
      case 'dropoffFullName':
      case 'pickupFullName':
        return `${this.userData.userData.name} ${this.userData.userData.surname}`;
    }
  }

  loadFormGroupData(fg: FormGroup, data: any, isLoading: boolean) {
    for (let key in data) {
      let value = undefined;
      if (['pickupDate', 'dropoffDate', 'appointmentDate'].indexOf(key) > -1) {
        value = new Date(data[key]);
      } else {
        value = data[key];
      }
      fg.controls[key].setValue(value);
    }
    isLoading = false;
  }

  untilChangesHelper() {
    return (prev: any, curr: any) =>
      prev[0] === curr[0] && prev[1] === curr[1] && prev[2] === curr[2];
  }

  addValidatorCheckDates(fg: FormGroup) {
    fg.addValidators(this.pickUpDropOffDateValidator.createValidator());
  }

  addValidatorsAutosuggest(fg: FormGroup, key: string) {
    fg.controls[key].addValidators(googleMapsInputSuggestion());
    // fg.controls[key].addAsyncValidators(
       //this.checkCoordinatesWithinCaflerAreaValidator.createValidator(),
    // );
    fg.addValidators(notSameDirectionValidator());
    // fg.addValidators( checkIfSameCountryBingMapsValidator() );
  }
}
