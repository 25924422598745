import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KamikazeActions } from '../../store/actions';
import { Store } from '@ngrx/store';
import { selectIsVerticalProductsLoaded } from '../../store/selectors';
import { CaflerIconSettingsModel } from '@cafler/common-ui';
import { SingleDataHelperService } from 'src/app/helpers/single-data-helper.service';

@Component({
  selector: 'app-revision',
  templateUrl: './revision.page.html',
  styleUrls: ['./revision.page.scss'],
})
export class RevisionPage implements OnInit {
  fg!: FormGroup;
  productsSettings: any = {
    id: 'revision-selector',
    formControlName: 'productSelector',
    isTopping: false,
    products: [],
  };
  stampIcon = <CaflerIconSettingsModel>{
    name: 'caf-stamp',
    alt: 'cafler.product.revision.stamp.icon',
  };

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private singleDataHelperService: SingleDataHelperService,

  ) {
    const formControlName = this.productsSettings.formControlName;
    this.fg = new FormGroup({});
    this.fg.addControl(formControlName, new FormControl(null, [Validators.required]));

    this.store.dispatch({ type: KamikazeActions.FetchAvailableVerticalProducts });
  }

  ngOnInit() {
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: false });
    this.navigationService.currentPageTitle = 'cafler.product.service.title';
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'revision' });

    this.store.select(selectIsVerticalProductsLoaded).subscribe((productsLoaded) => {
      if (productsLoaded)
        this.singleDataHelperService.getCurrentVerticalProducts().subscribe((products) => {
          this.productsSettings.products = products;
        });
    });
  }

  pickProduct(page: any) {
    const currentProduct = this.productsSettings.products.find((product: any) => {
      return product.id === page;
    });

    this.store.dispatch({
      type: KamikazeActions.SetSelectedProduct,
      selectedProduct: currentProduct,
    });

    this.router
      .navigate([`${currentProduct?.alias}`], { relativeTo: this.activatedRoute })
      .then((s) => {
        this.navigationService.currentPageTitle = 'cafler.product.service.title';
      });
  }
}
