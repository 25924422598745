<app-stepper *ngIf="!isB2B && isStepperShown"></app-stepper>
<div [class.margin-left-app]="!isB2B && isStepperShown">
  <header>
    <app-wrapper [isStepperShown]="isStepperShown"></app-wrapper>
  </header>
  <main class="caf-lg-container">
    <router-outlet></router-outlet>
  </main>
  <footer class="footer">
    <app-footer *ngIf="isFooterVisible$ | async"></app-footer>
  </footer>
</div>
