import { VehicleFuelTypesEnum } from "../Enums/vehicle-fuel-types.enum";

export const FUEL_TYPE_FOR_CAR_ES = [VehicleFuelTypesEnum.FUEL95, VehicleFuelTypesEnum.DIESEL, VehicleFuelTypesEnum.ELECTRIC, VehicleFuelTypesEnum.HYBRID]
export const FUEL_TYPE_FOR_CAR_FR = [VehicleFuelTypesEnum.FUEL95, VehicleFuelTypesEnum.DIESEL, VehicleFuelTypesEnum.ELECTRIC, VehicleFuelTypesEnum.HYBRID, /*VehicleFuelTypesEnum.FUEL95E10*/]
export const FUEL_TYPE_FOR_CAR_UK = [VehicleFuelTypesEnum.FUEL95]

export const FUEL_TYPE_FOR_MOTORCYCLE_FROM_125cc_ES: any[]  = [VehicleFuelTypesEnum.FUEL95, VehicleFuelTypesEnum.ELECTRIC];
export const FUEL_TYPE_FOR_MOTORCYCLE_FROM_125cc_FR: any[] = [];
export const FUEL_TYPE_FOR_MOTORCYCLE_FROM_125cc_UK: any[]  = [VehicleFuelTypesEnum.FUEL95];

export const FUEL_TYPE_FOR_MOTORCYCLE_UP_125cc_ES: any[]  = [VehicleFuelTypesEnum.FUEL95, VehicleFuelTypesEnum.ELECTRIC];
export const FUEL_TYPE_FOR_MOTORCYCLE_UP_125cc_FR: any[]  = [];
export const FUEL_TYPE_FOR_MOTORCYCLE_UP_125cc_UK: any[]  = [];
 
export const FUEL_TYPE_FOR_VAN_ES: any[]  = [VehicleFuelTypesEnum.FUEL95, VehicleFuelTypesEnum.DIESEL, VehicleFuelTypesEnum.ELECTRIC, VehicleFuelTypesEnum.HYBRID]
export const FUEL_TYPE_FOR_VAN_FR: any[]  = []
export const FUEL_TYPE_FOR_VAN_UK: any[]  = []


