<section>
    <div *ngFor="let step of steps; let i = index" [class]="{'completedStep': step.isCompleted, 'active': step.isActive}" class="step" >
        <div class="step__row">
            <div *ngIf="step.icon">
                <caf-icon *ngIf="step.isCompleted; else emptyIcon" [settings]="step.icon"></caf-icon>
                <ng-template #emptyIcon>
                    <div class="empty-step-icon"></div>
                </ng-template>
            </div>
            <p class="step__name">{{step.name | translate}}</p>
        </div>
        <div [class]="i + 1 === settings.steps.length ? 'step__last-row' : 'step__time-row'">
            <div class="vertical-line"></div>
            <p class="step__time">{{step.time}}</p>
        </div>
    </div>
</section>
