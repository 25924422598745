import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import {
  LavadoFormPage,
  LavadoPage,
  ProductSelectionPage,
  TransferFormPage,
  MediaLargaDistanciaFormPage,
  OverviewPage,
  CheckoutPage,
  ItvFormPage,
  ItvFormWithAppointmentPage,
  ReturnToppingFormPage,
  MyServicesPage,
  RefuelPage,
  PreItvFormPage,
  ServiceSelectorPage,
  ValetSelectorPage
} from './pages';
import { ItvSelectorPage } from './pages/itv-selector/itv-selector.page';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SuccessComponent } from './pages/success/success.component';
import { ToppingSelectionPage } from './pages/topping-selection/topping-selection.page';
import { MaintenanceBundleComponent } from './pages/maintenance-bundle/maintenance-bundle.component';
import { MaintenanceFormComponent } from './pages/maintenance-form/maintenance-form.component';
import { ValetFormComponent } from './pages/valet-form/valet-form.component';
import { FormalitiesSelectorComponent } from './pages/formalities-selector/formalities-selector.component';
import { MyServiceDetailsPage } from './pages/my-service-details/my-service-details.page';
import { HoistFormComponent } from './pages/hoist-form/hoist-form.component';
import { AllYouNeedProductComponent } from './pages/allYouNeed-product/allYouNeed-product.component';
import { ItvFormWithHomologationComponent } from './pages/itv-form-with-homologation/itv-form-with-homologation.component';

// TODO: Deprecated, remove when possible
const toppings: Routes = [
  {
    path: '',
    component: ToppingSelectionPage,
  },
  {
    path: 'overview',
    children: [
      {
        path: '',
        component: OverviewPage,
      },
    ],
  },
  {
    path: 'return',
    children: [
      {
        path: '',
        component: ReturnToppingFormPage,
      },
      {
        path: 'overview',
        component: OverviewPage,
      },
    ],
  },
];

const routes: Routes = [
  {
    path: 'products',
    pathMatch: 'full',
    redirectTo: '/',
  },
  {
    path: 'checkout',
    component: CheckoutPage,
  },
  {
    path: 'success-payment',
    component: SuccessComponent,
  },
  {
    path: '',
    component: ProductSelectionPage,
    pathMatch: 'full',
  },
  {
    path: 'my-services', 
    children: [
      {
        path: '',
        component: MyServicesPage,
      },
      {
        path : 'service-details',
        component: MyServiceDetailsPage,
      }
    ],
  },
  {
    path: ':zoneSelected',
    // matcher: (url: any) => {
    //   const listOfZones = ['Barcelona','Madrid','Bilbao'];
    //   const posParams: { [key: string]: UrlSegment } = {};
    //   const consumed: UrlSegment[] = url;
    //   if(url.length > 0 && listOfZones.indexOf(url[0].path)>-1) {
    //     posParams.region = url[0];
    //     posParams.vertical = url[1];
    //     return {
    //       consumed,
    //       posParams
    //     }
    //   }
    //   return null;
    // },
    children: [
      {
        path: 'wash',
        children: [
          {
            path: '',
            component: LavadoPage,
          },
          {
            path: ':product-type',
            children: [
              {
                path: '',
                component: LavadoFormPage,
              },
              {
                path: 'overview',
                component: OverviewPage,
              },
              {
                path: 'add-on',
                children: toppings,
              },
            ],
          },
        ],
      },
      {
        path: 'valet',
        children: [
          {
            path: '',
            component: ValetSelectorPage,
          },
          {
            path: ':product-type',
            children: [
              {
                path: '',
                component: ValetFormComponent,
              },
              {
                path: 'overview',
                component: OverviewPage,
              },
              {
                path: 'add-on',
                children: toppings,
              },
            ],
          },
        ],
      },
      {
        path: 'formalities',
        children: [
          {
            path: '',
            component: FormalitiesSelectorComponent,
          },
        ],
      },
      {
        path: 'pre-mot',
        children: [
          {
            path: '',
            component: PreItvFormPage,
          },
          {
            path: 'overview',
            component: OverviewPage,
          },
          {
            path: 'add-on',
            children: toppings,
          },
        ],
      },
      {
        path: 'transfer',
        children: [
          {
            path: '',
            component: TransferFormPage,
          },
          {
            path: 'overview',
            component: OverviewPage,
          },
          {
            path: 'add-on',
            children: toppings,
          },
        ],
      },
      {
        path: 'tow-truck',
        children: [
          {
            path: '',
            component: HoistFormComponent,
          },
          {
            path: 'overview',
            component: OverviewPage,
          },
          {
            path: 'add-on',
            children: toppings,
          },
        ],
      },
      {
        path: 'all-you-need',
        children: [
          {
            path: '',
            component: AllYouNeedProductComponent,
          },
          {
            path: 'overview',
            component: OverviewPage,
          }
        ],
      },
      {
        path: 'refuel',
        children: [
          {
            path: '',
            component: RefuelPage,
          },
          {
            path: 'overview',
            component: OverviewPage,
          },
          {
            path: 'add-on',
            children: toppings,
          },
        ],
      },
      {
        path: 'medium-long-distance',
        children: [
          {
            path: '',
            component: MediaLargaDistanciaFormPage,
          },
          {
            path: 'overview',
            component: OverviewPage,
          },
          {
            path: 'add-on',
            children: toppings,
          },
        ],
      },
      {
        path: 'vehicle-service',
        children: [
          {
            path: '',
            component: ServiceSelectorPage,
          },
          {
            path: 'fine-tunning',
            children: [
              {
                path: '',
                component: MaintenanceFormComponent,
              },
              {
                path: 'overview',
                component: OverviewPage,
              },
              {
                path: 'add-on',
                children: toppings,
              },
            ],
          },
          {
            path: 'car-service',
            children: [
              {
                path: '',
                component: MaintenanceFormComponent,
              },
              {
                path: 'overview',
                component: OverviewPage,
              },
              {
                path: 'add-on',
                children: toppings,
              },
            ],
          },
          {
            path: 'maintenance-bundle',
            children: [
 
              {
                path:'',
                component: MaintenanceBundleComponent
              },
              {
                path:':product-type',
                children:[
                  {
                    path: '',
                    component: MaintenanceFormComponent,
                  },
                  {
                    path: 'overview',
                    component: OverviewPage,
                  },
                  {
                    path: 'add-on',
                    children: toppings,
                  },
                ]
              }
            ]
          },    
        ]
      },
      {
        path: 'mot',
        children: [
          {
            path: '',
            component: ItvSelectorPage,
          },
          {
            path: 'appointment',
            children:[
              {
                path: '',
                component: ItvFormWithAppointmentPage,
              },
              {
                path: 'overview',
                component: OverviewPage,
              },
              {
                path: 'add-on',
                children: toppings,
              },
            ]
          },
          {
            path: 'no-appointment',
            children:[
              {
                path: '',
                component: ItvFormPage,
              },
              {
                path: 'overview',
                component: OverviewPage,
              },
              {
                path: 'add-on',
                children: toppings,
              },
            ]
          },
          {
            path: 'homologation',
            children:[
              {
                path: '',
                component: ItvFormWithHomologationComponent,
              },
              {
                path: 'overview',
                component: OverviewPage,
              },
              {
                path: 'add-on',
                children: toppings,
              },
            ]
          }             
        ]
      }
    ],
  },

  { path: '**', redirectTo: 'not-found' },
  { path: 'not-found', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes) ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
