import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CaflerIconSettingsModel } from '@cafler/common-ui';
import { PopupHeaderModel } from '../popup-components/popup-header/popup-header.model';
import { PopupHowItWorksModel, iconSentence } from '../popup-components/popup-how-it-works/popup-how-it-works.model';
import { VehicleDataDisplayModel } from '../vehicle-data-display/models/vehicle-data-settings.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'replacement-vehicle-info-pop-up',
  templateUrl: './replacement-vehicle-info-pop-up.component.html',
  styleUrls: ['./replacement-vehicle-info-pop-up.component.scss']
})
export class ReplacementVehicleInfoPopUpComponent implements OnInit {
  @Output() closeEvent: EventEmitter<{}> = new EventEmitter<{}>();

  headerSettings: PopupHeaderModel = {
    title: 'cafler.replacement.vehicle.popup.header.title'
  }

  vehicleDataDisplaySetting: VehicleDataDisplayModel = {
    brand: 'Peugeot',
    model: '208',
    gearbox: 'cafler.vehicle-data-display.gearbox-manual.label',
    fuel: 'FUEL98',
    personsCapacity: 4,
    numberOfDoors: 3,
    luggageCapacity: 2,
    image: `https://${environment.assetsURL}/images/cars/Peugeot208.png`,
  }

  howItWorksSentences: iconSentence[] = [
    {
      icon: {
        name: 'caf-simple-car',
        alt: 'vehículo de sustitución',
      },
      sentence: 'cafler.replacement.vehicle.popup.how-it-works.sentence1'
    }, 
    {
      icon: {
        name: 'caf-envelope',
        alt: 'email',
      },
      sentence: 'cafler.replacement.vehicle.popup.how-it-works.sentence2'
    },
    {
      icon: {
        name: 'caf-person-2',
        alt: 'persona',
      },
      sentence: 'cafler.replacement.vehicle.popup.how-it-works.sentence3' 
    },
    {
      icon: {
        name: 'caf-paper-check',
        alt: 'contrato',
      },
      sentence: 'cafler.replacement.vehicle.popup.how-it-works.sentence4'    
    },    
  ]

  PopupHowItWorksModel: PopupHowItWorksModel = {
    title: 'cafler.replacement.vehicle.popup.how-it-works.title',
    sentences: this.howItWorksSentences,
  }


  beforeServiceStarts: any = {
    title:"cafler.replacement.vehicle.popup.before-service-starts.title",
    sentences: [
      'cafler.replacement.vehicle.popup.before-service-starts-sentence1',
      'cafler.replacement.vehicle.popup.before-service-starts-sentence2',
      'cafler.replacement.vehicle.popup.before-service-starts-sentence3',
      'cafler.replacement.vehicle.popup.before-service-starts-sentence4',
      'cafler.replacement.vehicle.popup.before-service-starts-sentence5',
      'cafler.replacement.vehicle.popup.before-service-starts-sentence6',
      'cafler.replacement.vehicle.popup.before-service-starts-sentence7',
      'cafler.replacement.vehicle.popup.before-service-starts-sentence8',
    ]

  }

  constructor() { }

  ngOnInit(): void {
  }


  close(){
    this.closeEvent.emit({})
  }
}
