<form class="form-container" [formGroup]="fg">
  <div class="cards-container">
    <caf-product-card
    class="card"
    [class]="'card' + (i + 1)"
    [settings]="product"
    *ngFor="let product of productsSettings.itvServices; let i = index"
    (productSelected)="pickProduct($event)"
  ></caf-product-card>
  </div>
</form>
