import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CaflerButtonSettingsModel, CaflerIconSettingsModel, CafNotificationSettingsModel } from '@cafler/common-ui';
import { Store } from '@ngrx/store';
import { selectCurrentCountry, selectIsB2B } from 'src/app/store/selectors';
import { VehicleFuelTypesEnum } from '../../Enums/vehicle-fuel-types.enum';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'refuel-topping-configurator',
  templateUrl: './refuel-topping-configurator.component.html',
  styleUrls: ['./refuel-topping-configurator.component.scss']
})
export class RefuelToppingConfiguratorComponent implements OnInit, OnDestroy{
  private readonly destroySubjects$ = new ReplaySubject<void>(1);

  @Input() settings: any = <any>{};

  @Output() onChangedRoute : EventEmitter<number> = new EventEmitter<number>();

  @Output() onGoBack: EventEmitter<number> = new EventEmitter<any>();
  @Output() onSubmitRefuel: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSubmitEmpty: EventEmitter<any> = new EventEmitter<any>();


  VehicleFuelType = VehicleFuelTypesEnum;

  notificationSettings: CafNotificationSettingsModel = {
    type: 'info',
    text: "cafler.toppings.refuel.info",
    fontSize: '16px',
  }

  errorSettings: CafNotificationSettingsModel = {
    type: 'error',
    text: "cafler.toppings.refuel.error"
  }

  button = {
    buttonSubmit: <CaflerButtonSettingsModel>{
      id: 'submitButton',
      text: 'cafler.toppings.washing.submit-button',
      actionName: 'showSpinner',
      disabled: false,
    },
    buttonBack: <CaflerButtonSettingsModel>{
      id: 'backButton',
      text: 'cafler.toppings.washing.back-button',
      actionName: 'showSpinner',
      disabled: false,
    },
  }

  arrowIcon = <CaflerIconSettingsModel>{
    name: 'caf-chevron-down-light',
    alt: 'Next icon',
  };

  showError = false;
  currentCountry: any;
  isB2B: boolean = false;

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    
    this.store.select(selectCurrentCountry).subscribe((country: any) => {
      if (country) {
        this.currentCountry = country;
      }
    });

    this.store.select(selectIsB2B)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((isB2B) => {
      this.isB2B = isB2B;
    });
    
  }

  setAmount(amount: number){
    this.settings.amount === amount? this.settings.amount = null : this.settings.amount = amount;
  }

  setType(type: VehicleFuelTypesEnum){
    this.settings.type === type? this.settings.type = null : this.settings.type = type;
  }

  back(){
    this.onGoBack.emit();
  }

  save(){
    // Only save the event if both are null or both have a value
    if(this.settings.amount === null && this.settings.type === null){
      this.onSubmitEmpty.emit(this.settings);
      this.showError = false;
      return;
    }

    if(this.settings.amount !== null && this.settings.type !== null){
      this.onSubmitRefuel.emit(this.settings);
      this.showError = false;
      return;
    }
    this.showError = true;   

  }

  changeRoute(newRoute: number){
    this.onChangedRoute.emit(newRoute);
  }
  
  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }

}
