import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { CaflerIconSettingsModel } from '@cafler/common-ui';
import { PopupHeaderModel } from './popup-header.model';

@Component({
  selector: 'popup-header',
  templateUrl: './popup-header.component.html',
  styleUrls: ['./popup-header.component.scss']
})

export class PopupHeaderComponent implements OnInit {
  @Input() settings: PopupHeaderModel = <PopupHeaderModel>{};
  @Output() closeEvent: EventEmitter<{}> = new EventEmitter<{}>();
  constructor() { }

  closeIcon = <CaflerIconSettingsModel>{
    name: 'caf-close-dark',
    alt: 'Close icon',
    height: '18px',
    width: '18px',
  };

  ngOnInit(): void {
  }

  close(): void {
    this.closeEvent.emit({ info: 'cancel' });
  }
    
}
