<form *ngIf="isAppInitialized" class="caf-md-container margin-top-forms" [formGroup]="fg">
    <!-- direccion recogida-->
    <div class="input-item">
      <span class="subtitle-1">{{'cafler.pickup-info.pickup-info.title' | translate}}</span>
      <div *ngIf="isB2B" class="caf-grid-row split-2-cols split-sm-2-cols">
        <caf-input-google-autosuggest
          class="col-start-sm-1 col-start-1 col-end-sm-10 col-end-10"
          (onValidAddress)="onValidAddress($event, settings.pickupAddress.formControlName)"
          (onInvalidAddress)="countPickupErrors()"
          [settings]="settings.pickupAddress"
        >
        </caf-input-google-autosuggest>
        <button
          class="h-align-center v-align-center complete-address-button col-start-sm-11 col-start-11 col-end-sm-12 col-end-12"
          (click)="getB2BAddress(settings.pickupAddress.formControlName)"
        >
          <caf-icon [settings]="settingsExtra.locationIcon"></caf-icon>
        </button>
      </div>
      <caf-input-google-autosuggest
        *ngIf="!isB2B"
        (onValidAddress)="onValidAddress($event, settings.pickupAddress.formControlName)"
        (onInvalidAddress)="countPickupErrors()"
        [settings]="settings.pickupAddress"
      >
      </caf-input-google-autosuggest>
      <caf-error
        *ngIf="timesFailedPickupAddress<=maxErrorsOnAddress"
        class="extra-margin-error"
        [settings]="settingsErrors.errorsPickupAddress"
      ></caf-error>
      <p *ngIf="timesFailedPickupAddress>maxErrorsOnAddress" class="error-message error-msg">
        {{'cafler.address-message-error-too-many-attempts-pickup.title' | translate }}
      </p>
    </div>
    <div *ngIf="fg.get('pickupAddress')?.errors?.checkCoordinatesWithinCaflerArea" class="error-message error-msg">
      <p>{{'cafler.address.message.error.address-outside-operative-zone.title' | translate }}</p>
      <article class="notification-container notification-container__warning mb-24">
        <caf-icon [settings]="settingsExtra.warningIcon"></caf-icon>
        <p>{{'cafler.address.message.error.address-outside-operative-zone.notification-text' | translate }} <a href="{{operativeZonesURL}}" target="_blank">{{'cafler-operative-zones' | translate}}</a></p>
      </article> 
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.pickupAddressDetails"></caf-input>
    </div>
    <!-- same delivery address-->
    <div class="input-item">
      <caf-dark-checkbox [settings]="settings.sameDeliveryAddress"></caf-dark-checkbox>
    </div>
    <!-- direccion de devolucion-->
    <div *ngIf="isSameDeliveryAddress" class="input-item">
      <span class="subtitle-1">{{'cafler.page.wash.dropoff.info.title' | translate}}</span>
      <div *ngIf="isB2B" class="caf-grid-row split-2-cols split-sm-2-cols">
        <caf-input-google-autosuggest
          class="col-start-sm-1 col-start-1 col-end-sm-10 col-end-10"
          (onValidAddress)="onValidAddress($event, settings.dropoffAddress.formControlName)"
          (onInvalidAddress)="countDropoffErrors()"
          [settings]="settings.dropoffAddress"
        >
        </caf-input-google-autosuggest>
        <button
          class="h-align-center v-align-center complete-address-button col-start-sm-11 col-start-11 col-end-sm-12 col-end-12"
          (click)="getB2BAddress(settings.dropoffAddress.formControlName)"
        >
          <caf-icon [settings]="settingsExtra.locationIcon"></caf-icon>
        </button>
      </div>
      <caf-input-google-autosuggest
        *ngIf="!isB2B"
        (onValidAddress)="onValidAddress($event, settings.dropoffAddress.formControlName)"
        (onInvalidAddress)="countDropoffErrors()"
        [settings]="settings.dropoffAddress"
      >
      </caf-input-google-autosuggest>
      <caf-error
        *ngIf="timesFailedDropoffAddress<=maxErrorsOnAddress"
        class="extra-margin-error"
        [settings]="settingsErrors.errorsDropoffAddress"
      ></caf-error>
      <p *ngIf="timesFailedDropoffAddress>maxErrorsOnAddress" class="error-message error-msg">
        {{'cafler.address-message-error-too-many-attempts-dropoff.title' | translate }}
      </p>
    </div>
    <div *ngIf="fg.get('dropoffAddress')?.errors?.checkCoordinatesWithinCaflerArea" class="error-message error-msg">
      <p>{{'cafler.address.message.error.address-outside-operative-zone.title' | translate }}</p>
      <article class="notification-container notification-container__warning mb-24">
        <caf-icon [settings]="settingsExtra.warningIcon"></caf-icon>
        <p>{{'cafler.address.message.error.address-outside-operative-zone.notification-text' | translate }} <a href="{{operativeZonesURL}}" target="_blank">{{'cafler-operative-zones' | translate}}</a></p>
      </article> 
    </div>
    <div *ngIf="isSameDeliveryAddress" class="input-item">
      <caf-input [settings]="settings.dropoffAddressDetails"></caf-input>
    </div>

    <!-- contact data-->
    <div class="input-item">
      <span class="subtitle-1">{{'cafler.contact.title' | translate}}</span>
      <caf-input [settings]="settings.fullName"></caf-input>
      <caf-error [settings]="settingsErrors.errorsFullName"></caf-error>
    </div>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-select
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settings.countryPhoneCode"
      ></caf-select>
      <caf-input
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settings.phone"
      ></caf-input>
    </div>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-error
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settingsErrors.errorsCountryPhoneCode"
      ></caf-error>
      <caf-error
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settingsErrors.errorsPhone"
      ></caf-error>
    </div>
    <div *ngIf="!isB2B" class="input-item">
      <caf-input [settings]="settings.email"></caf-input>
      <caf-error [settings]="settingsErrors.errorsEmail"></caf-error>
    </div>


    <!-- fecha del servicio-->
    <div class="input-item">
      <span class="subtitle-1">{{'cafler.page.service-date.info.title' | translate}}</span>
      <caf-datepicker [settings]="settings.pickupDate"></caf-datepicker>
      <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsPickupDate"></caf-error>
    </div>
  
    <!-- hora del servicio-->
    <div *ngIf="readyToDisplayHours">
      <div *ngIf="firstNewFeatureTry && renderNewFeatureSpeechBubble; else noNewFeatureSpeechBubble">
        <span class="subtitle-1 absolutePosition">{{'cafler.page.service-time.info.title' | translate}}</span>
        <caf-speech-bubble *ngIf="firstNewFeatureTry" [settings]="settingsExtra.speechBubble"></caf-speech-bubble>
      </div>
      <ng-template #noNewFeatureSpeechBubble>
        <span class="subtitle-1">{{'cafler.page.service-time.info.title' | translate}}</span>
      </ng-template>
  
      <caf-select [settings]="settings.selectTimeOption"></caf-select>
      <br/>    
        <div *ngIf="timeRangeOption === 'range'" class="caf-grid-row split-2-cols split-sm-2-cols">
          <caf-select #startServiceTimeComponent [settings]="settings.startPickUpTime"></caf-select>
          <caf-select #endServiceTimeComponent [settings]="settings.endPickUpTime"></caf-select>
        </div>
        <div *ngIf="timeRangeOption === 'specific'">
          <caf-multicolumn-select #startServiceTimeComponent [settings]="settings.specificTimeRange"></caf-multicolumn-select>
          <div *ngIf="!noSpecificHourAvailable">
            <div *ngIf="currentCountry.RegionIsoCode === 'esp'">
              <span *ngIf="currentCountry.PreferredRegionCurrency === 1" class="span-2">
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.spain.info_b2b' 
                    : 'cafler.page.service-time.specific-time.spain.info')
                  | translate
                }}
              </span>
              <span *ngIf="currentCountry.PreferredRegionCurrency === 3" class="span-2">
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.spain.info_uk_b2b' 
                    : 'cafler.page.service-time.specific-time.spain.info_uk')
                  | translate
                }}
              </span>
            </div>
  
            <div *ngIf="currentCountry.RegionIsoCode === 'fra'">
              <span *ngIf="currentCountry.PreferredRegionCurrency === 1" class="span-2">
  
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.france.info_b2b' 
                    : 'cafler.page.service-time.specific-time.france.info')
                  | translate
                }}
              </span>
              <span *ngIf="currentCountry.PreferredRegionCurrency === 3" class="span-2">
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.france.info_uk_b2b' 
                    : 'cafler.page.service-time.specific-time.france.info_uk')
                  | translate
                }}
              </span>
            </div>
            
            <div *ngIf="currentCountry.RegionIsoCode === 'gbr'">
              <span *ngIf="currentCountry.PreferredRegionCurrency === 1" class="span-2">
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.uk.info_b2b' 
                    : 'cafler.page.service-time.specific-time.uk.info')
                  | translate
                }}
              </span>
              <span *ngIf="currentCountry.PreferredRegionCurrency === 3" class="span-2">
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.uk.info_uk_b2b' 
                    : 'cafler.page.service-time.specific-time.uk.info_uk')
                  | translate
                }}
              </span>
            </div>
          </div>
        </div> 
    </div>
  
    <div
      class="caf-grid-row split-2-cols split-sm-2-cols"
      *ngIf="readyToDisplayHours"
    >
      <caf-error
        *ngIf="!combinationNotValid && !dayChangedFlag"
        class="extra-margin-error col-start-sm-1 col-start-1 col-end-sm-1 col-end-1"
        [settings]="settingsErrors.errorsStartPickUpTime"
      ></caf-error>
      <caf-error
        *ngIf="!combinationNotValid && !dayChangedFlag"
        class="extra-margin-error col-start-sm-2 col-start-2 col-end-sm-2 col-end-2"
        [settings]="settingsErrors.errorsEndPickUpTime"
      ></caf-error>
    </div>
  
    <div *ngIf="timeRangeOption === 'range'">
      <p class="error-message" *ngIf="readyToDisplayHours && noRangeHourAvailable">
        {{"cafler.page.service-time.error-range-hours-not-available" | translate}}
      </p>
      <p class="error-message" *ngIf="dayChangedFlag && !noRangeHourAvailable">
        {{"cafler.page.service-time.error-day-changed" | translate}}
      </p>
    </div>
  
    <div *ngIf="timeRangeOption === 'specific'">
      <p class="error-message" *ngIf="readyToDisplayHours && noSpecificHourAvailable">
        {{"cafler.page.service-time.error-specific-hours-not-available" | translate}}
      </p>
      <p class="error-message" *ngIf="dayChangedFlag && !noSpecificHourAvailable">
      {{"cafler.page.service-time.error-day-changed" | translate}}
      </p>
    </div>
    <p class="error-message" *ngIf="combinationNotValid">
      {{"cafler.page.service-time.error-not-compatible" | translate}}
    </p>
    <!-- vehicle information-->
    <div class="input-item">
        <span class="subtitle-1">{{'cafler.vehicle-info.title' | translate}}</span>
        <caf-select [settings]="settings.vehicleType"></caf-select>
        <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsVehicleType"></caf-error>
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.carPlaque"></caf-input>
      <caf-error [settings]="settingsErrors.errorsCarPlaque"></caf-error>
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.brand"></caf-input>
      <caf-error [settings]="settingsErrors.errorsBrand"></caf-error>
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.model"></caf-input>
      <caf-error [settings]="settingsErrors.errorsModel"></caf-error>
    </div>

    <div *ngIf="isrefuelSettingReadytoDisplay" class="refuel">
      <caf-notification *ngIf="isrefuelSettingReadytoDisplay && (fg.get('refuelType')?.errors || fg.get('refuelAmount')?.errors)" [settings]="settingsErrors.errorSettings"></caf-notification>

      <h3 class="subtitle-1">{{'cafler.product-type.refuel.choose-type.title' | translate}}</h3>
      <div>
        <div *ngIf="currentCountry.RegionIsoCode === 'esp'"  class="refuel__type">
            <div class="refuel__type__btn" [ngClass]="settings.refuelType === VehicleFuelType.FUEL95 ? 'selected' : ''" (click)="setType(VehicleFuelType.FUEL95)">{{'cafler.toppings.refuel.type.gasoline_95' | translate}}</div>
            <div class="refuel__type__btn" [ngClass]="settings.refuelType === VehicleFuelType.FUEL98 ? 'selected' : ''" (click)="setType(VehicleFuelType.FUEL98)">{{'cafler.toppings.refuel.type.gasoline_98' | translate}}</div>
            <div class="refuel__type__btn" [ngClass]="settings.refuelType === VehicleFuelType.DIESEL ? 'selected' : ''" (click)="setType(VehicleFuelType.DIESEL)">{{'cafler.toppings.refuel.type.diesel_e' | translate}}</div>
            <div class="refuel__type__btn" [ngClass]="settings.refuelType === VehicleFuelType.DIESEL_PREMIUM ? 'selected' : ''" (click)="setType(VehicleFuelType.DIESEL_PREMIUM)">{{'cafler.toppings.refuel.type.diesel_e10' | translate}}</div>
        </div>
    
        <div *ngIf="currentCountry.RegionIsoCode === 'gbr'" class="refuel__type">
            <div class="refuel__type__btn" [ngClass]="settings.refuelType === VehicleFuelType.FUEL95 ? 'selected' : ''" (click)="setType(VehicleFuelType.FUEL95)">{{'cafler.toppings.refuel.type.gasoline_95' | translate}}</div>
            <div class="refuel__type__btn" [ngClass]="settings.refuelType === VehicleFuelType.FUEL98 ? 'selected' : ''" (click)="setType(VehicleFuelType.FUEL98)">{{'cafler.toppings.refuel.type.gasoline_98' | translate}}</div>
            <div class="refuel__type__btn" [ngClass]="settings.refuelType === VehicleFuelType.DIESEL ? 'selected' : ''" (click)="setType(VehicleFuelType.DIESEL)">{{'cafler.toppings.refuel.type.diesel_e' | translate}}</div>
        </div>
    
        <div *ngIf="currentCountry.RegionIsoCode === 'fra'" class="refuel__type">
            <div class="refuel__type__btn" [ngClass]="settings.refuelType === VehicleFuelType.FUEL95 ? 'selected' : ''" (click)="setType(VehicleFuelType.FUEL95)">{{'cafler.toppings.refuel.type.gasoline_95' | translate}}</div>
            <div class="refuel__type__btn" [ngClass]="settings.refuelType === VehicleFuelType.FUEL95E10 ? 'selected' : ''" (click)="setType(VehicleFuelType.FUEL95E10)">{{'cafler.toppings.refuel.type.gasoline_95E10' | translate}}</div>
            <div class="refuel__type__btn" [ngClass]="settings.refuelType === VehicleFuelType.FUEL98 ? 'selected' : ''" (click)="setType(VehicleFuelType.FUEL98)">{{'cafler.toppings.refuel.type.gasoline_98' | translate}}</div>
            <div class="refuel__type__btn" [ngClass]="settings.refuelType === VehicleFuelType.DIESEL ? 'selected' : ''" (click)="setType(VehicleFuelType.DIESEL)">{{'cafler.toppings.refuel.type.diesel_e' | translate}}</div>
        </div>
    </div>
    <h3 class="subtitle-1">{{'cafler.toppings.refuel.chose-amount.title' | translate}}</h3>
    
    <div class="refuel__amount">
      <button *ngFor="let price of refuelPrices"
      class="refuel__amount__btn btn__border"
      [ngClass]="settings.refuelAmount === price ? 'selected' : ''"
      (click)="setAmount(price)">
        <div class="refuel__amount__content">
          {{price}}{{currentCountry.PreferredRegionCurrencySymbol}}
          <p *ngIf="isB2B" class="refuel__amount__tax-fee">{{'cafler.tax-fee-included' | translate}}</p>
        </div>
      </button>
    </div>
  
    <caf-notification [settings]="settingsExtra.notificationSettings"></caf-notification>

    </div>
    <!-- aditional data-->
    <div class="input-item">
      <span class="subtitle-1">{{'cafler.pickup-info.comments.title' | translate}}</span>
      <caf-textarea [settings]="settings.additionalComments"></caf-textarea>
    </div>
  </form>
  