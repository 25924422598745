import { Inject, Injectable, Injector, TemplateRef, Type } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { PopUpRef } from '../pop-up-ref';
import { PopUpComponent } from '../pop-up.component';
import { PopupSettingsModel } from '../model/pop-up-settings.model';
import { DOCUMENT } from '@angular/common';


@Injectable({
  providedIn: 'root',
})
export class CaflerPopUpService {
  constructor(private overlay: Overlay, private injector: Injector,  
    @Inject(DOCUMENT) private document: Document,
  ) {}

  open<R = any, T = any>(
    content: string | TemplateRef<any> | Type<any>,
    data: PopupSettingsModel,
  ): PopUpRef<R> {
    const configs = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['modal', 'is-active'],
      backdropClass: 'popup-background',
    });
    content = content ? content : 'Default text.';
    
    const overlayRef = this.overlay.create(configs);

    const cafOverlayRef = new PopUpRef<R, T>(overlayRef, content, data);

    const injector = this.createInjector(cafOverlayRef, this.injector);

    overlayRef.attach(new ComponentPortal(PopUpComponent, null, injector));

    this.document.body.classList.add('scroll-lock');

    return cafOverlayRef;
  }

  createInjector(ref: PopUpRef, inj: Injector) {
    const injectorTokens = new WeakMap([[PopUpRef, ref]]);
    return new PortalInjector(inj, injectorTokens); // TODO -> Fix deprecation
  }

  enableScroll() {
    this.document.body.classList.remove('scroll-lock');
  }
}
