import { createAction, props } from '@ngrx/store';
import { BECoreDataModel, BEProductModel } from '../models';

export enum KamikazeActions {
  BootstrappingApp = '[Bootstrap App] Bootstrapping App',
  BootstrappingAppSuccess = '[Bootstrap App] Bootstrapping App Success',
  InitApp = '[Bootstrap App] Initializing App',
  LoadDataB2B = '[Bootstrap App] Loading Data b2b',
  LoadDataB2BSuccess = '[Bootstrap App] Loading Data b2b Success',
  StoreCoreData = '[Bootstrap App] Store Core Data',
  SetIsB2B = '[Bootstrap App] Set Is B2B',
  InitAppSuccess = '[Bootstrap App] Init App Success',
  CheckIfThereIsAZone = '[Bootstrap App] Check If There Is A Zone In The URL',
  GetStations = '[Valet Page] Get Stations',
  GetStationsSuccess = '[Valet Page] Get Stations Success',
  GetMotStations = '[Mot Appointment Form Page] Get Mot Stations',
  GetMotStationsSuccess = '[Mot Appointment Form Page] Get Mot Stations Success',
  ConfirmOrder = '[Overview] Confirm Order',
  ConfirmedOrderSuccess = '[Overview] Confirmed Order Success',
  SetCurrentLang = '[Lang] Set Current Language',
  SetCurrentVerticalProducts = '[Bootstrap App] Set Current Vertical Products',
  CheckIfIsB2b = '[Bootstrap App] Checking if is b2b',
  CheckIfIsB2bSuccess = '[Bootstrap App] Checking if is b2b success',
  InitializeApp = '[KamikazeActions] Initialize App',
  InitializeAppB2B = '[KamikazeActions] Initializing app b2b',
  InitializeAppSuccess = '[KamikazeActions] Initialize App Success',
  InitializeAppB2BSuccess = '[KamikazeActions] Initialize App B2B Success',
  FetchAvailableVerticalProducts = '[KamikazeActions] Fetch available products for vertical',
  FetchAvailableVerticalProductsSuccess = '[KamikazeActions] Fetch available products for vertical success!',
  SetCurrentVertical = '[KamikazeActions] Set current vertical',
  BookOrder = '[KamikazeActions] Book order',
  BookOrderSuccess = '[KamikazeActions] Book order success',
  BookToppingsSuccess = '[OrderManager Topping] Book toppings success',
  BookOrderFail = '[KamikazeActions] Book order fail',
  SetFormValidity = '[KamikazeActions] Set Form Validity',
  SetFooterVisibility = '[KamikazeActions] Set Footer Visibility',
  SetCurrentPage = '[KamikazeActions] Set Current Page',
  SetNextPage = '[KamikazeActions] Set Next Page',
  SetIsAppInitialized = '[KamikazeActions] Set Is App Initialized',
  StoreCatalog = '[KamikazeActions] Store Catalog',
  StoreCatalogSuccess = '[KamikazeActions] Store Catalog Success',
  StoreProductData = '[KamikazeActions] Store Product Data',
  StoreProductType = '[KamikazeActions] Store Product Type',
  StoreProductTypeFromUrl = '[KamikazeActions] Store Product Type from the url',
  ProceedToCheckout = '[KamikazeActions] Proceed to Checkout',
  SetCurrentZone = '[KamikazeActions] Set New Zone',
  SetCurrentCountry = '[KamikazeActions] Set Current Country',
  SetOrderStatus = '[KamikazeActions] Update Order Status',
  SetIsLoading = '[KamikazeActions] Set Is Loading',
  SetAvailableToppings = '[KamikazeActions] Set Available Toppings',
  PurchaseCompleted = '[KamikazeActions] Purchase Completed',
  SetCoupon = '[OverView] Set Coupon',
  SetToppingsPicked = '[OrderManager Topping] set picked toppings',
  SetToppingsAdded = '[OrderManager Topping] set added toppings in the order',
  BookToppings = '[OrderManager Topping] Book toppings',
  RemoveToppings = '[OrderManager Topping] Delete toppings',
  SetToppingToDelete = '[OrderManager Topping] Set topping to delete',
  ClearToppingsAdded = '[OrderManager Topping] clean added toppings',
  ClearToppingsPicked = '[OrderManager Topping] clean picked toppings',
  SetDiscountPrice = '[OverView] Set Discount Price',
  UpdateOrderPrice = '[OverView] Update Order Price',
  IsCouponValid = '[OverView] Is Coupon Valid',
  RemoveCoupon = '[OverView] Ask backend to remove coupon',
  ClearCoupon = '[OverView] Coupon removed in frontend',
  RefreshPaymentSession = '[OverView] Refresh Payment Session',
  ResetSelectedToppings = '[KamikazeActions] Reset Selected Toppings',
  SetServiceTimeOptions = '[KamikazeActions] Set service Time Options',
  AddSelectedTopping = '[KamikazeActions] Add Selected Topping',
  AddSelectedToppingSuccess = '[KamikazeActions] Add Selected Topping Success',
  ContinueWithNoToppings = '[Toppings] Continue Without Adding Any Topping',
  AddSelectedToppingFail = '[KamikazeActions] Add Selected Topping Fail',
  SetCurrentTopping = '[KamikazeActions] Set Current Topping',
  ClearAvailableToppings = '[Toppings] Clear Available Toppings',
  ClearSelectedToppings = '[Toppings] Clear Selected Toppings',
  ClearSelectedToppingsSuccess = '[Toppings] Clear SelectedToppings Success',
  SetSelectedProduct = '[ProductSelectionPage] Set Selected Product',
  StoreToppingData = '[Toppings] Store Topping Data',
  GetAvailableHoursReturn = '[Toppings] Get Available Hours Return Topping',
  GetAvailableHoursReturnSuccess = '[Toppings] Get Available Hours Return Topping Success',
  GetServices = '[KamikazeActions] Get services',
  SetElementsAmount = '[BootstrapApp] Set the elements amount to show in my services page',
  SetPageLocation = '[BootstrapApp] Set the page location to show in my services',
  SetEnumMyServices = '[BootstrapApp] Set the enum for my services',
  GetServicesSuccess = '[BootstrapApp] Get services success',
  CancelService = '[BootstrapApp] Cancel services',
  CancelServiceSuccess = '[BootstrapApp] Cancel service success',
  ShowService = '[MyServices] Show more details of the service',
  ShowServiceSuccess = '[MyServices] Store full data of service to show',
  ReplaceProductId = '[KamikazeActions] Replace Product Id',
}

export const bootstrappingApp = createAction(KamikazeActions.BootstrappingApp);

export const bootstrappingAppSuccess = createAction(KamikazeActions.BootstrappingAppSuccess);

export const initApp = createAction(KamikazeActions.InitApp);

export const checkIfThereIsAZone = createAction(KamikazeActions.CheckIfThereIsAZone);

export const loadDataB2B = createAction(KamikazeActions.LoadDataB2B);

export const LoadDataB2BSuccess = createAction(KamikazeActions.LoadDataB2BSuccess);

export const storeCoreData = createAction(
  KamikazeActions.StoreCoreData,
  props<{ coreData: any }>(),
);

export const setIsB2B = createAction(
  KamikazeActions.SetIsB2B,
  props<{ isB2B: boolean; userData: any }>(),
);

export const checkIfIsB2b = createAction(KamikazeActions.CheckIfIsB2b);

export const initializeAppB2B = createAction(KamikazeActions.InitializeAppB2B);

export const initializeAppB2BSuccess = createAction(
  KamikazeActions.InitializeAppB2BSuccess,
  props<{ serviceType: any }>(),
);

export const initAppSuccess = createAction(KamikazeActions.InitAppSuccess);

export const setCurrentVerticalProducts = createAction(
  KamikazeActions.SetCurrentVerticalProducts,
  props<{ verticalProducts: any }>(),
);

export const initializeAppSuccess = createAction(
  KamikazeActions.InitializeAppSuccess,
  props<{ coreData: BECoreDataModel }>(),
);

export const CheckIfIsB2bSuccess = createAction(
  KamikazeActions.CheckIfIsB2bSuccess,
  props<{ isB2B: boolean }>(),
);

export const initializeApp = createAction(
  KamikazeActions.InitializeApp,
  props<{ isB2B: boolean }>(),
);

export const getStations = createAction(KamikazeActions.GetStations);

export const getStationsSuccess = createAction(KamikazeActions.GetStationsSuccess,
  props<{ stations: any }>(),
);

export const getMotStations = createAction(KamikazeActions.GetMotStations);

export const getMotStationsSuccess = createAction(KamikazeActions.GetMotStationsSuccess,
  props<{ stations: any }>(),
);

export const initializeAppB2bSuccess = createAction(
  KamikazeActions.InitializeAppB2BSuccess,
  props<{ zoneId: string; serviceType: number }>(),
);

export const fetchAvailableVerticalProducts = createAction(
  KamikazeActions.FetchAvailableVerticalProducts,
);

export const fetchAvailableVerticalProductsSuccess = createAction(
  KamikazeActions.FetchAvailableVerticalProductsSuccess,
  props<{ currentVerticalProducts: BEProductModel[] }>(),
);

export const setCurrentVertical = createAction(
  KamikazeActions.SetCurrentVertical,
  props<{ selectedVertical: number }>(),
);

export const bookOrder = createAction(KamikazeActions.BookOrder);

export const bookToppings = createAction(KamikazeActions.BookToppings);

export const SetToppingToDelete = createAction(KamikazeActions.SetToppingToDelete,props<{ toppingToDelete: string }>());

export const removeToppings = createAction(KamikazeActions.RemoveToppings);

export const bookOrderSuccess = createAction(
  KamikazeActions.BookOrderSuccess,
  props<{ bookedOrder: any }>(),
);

export const bookToppingsSuccess = createAction(
  KamikazeActions.BookToppingsSuccess,
  props<{ bookedOrder: any }>(),
);

export const bookOrderFail = createAction(
  KamikazeActions.BookOrderFail,
  props<{ error: any }>(),
);

export const setFormValidity = createAction(
  KamikazeActions.SetFormValidity,
  props<{ isFormValid: boolean }>(),
);

export const setFooterVisibility = createAction(
  KamikazeActions.SetFooterVisibility,
  props<{ isFooterVisible: boolean }>(),
);

export const setCurrentPage = createAction(
  KamikazeActions.SetCurrentPage,
  props<{ currentPage: string }>(),
);

export const setIsAppInitialized = createAction(
  KamikazeActions.SetIsAppInitialized,
  props<{ isAppInitialized: boolean }>(),
);

export const setCurrentLang = createAction(
  KamikazeActions.SetCurrentLang,
  props<{ currentLang: string }>(),
);

export const setServiceTimeOptions = createAction(
  KamikazeActions.SetServiceTimeOptions,
  props<{serviceTimeOptions: any[]}>(),
)

export const setCurrentCountry = createAction(
  KamikazeActions.SetCurrentCountry,
  props<{ currentCountry: string }>(),
);

export const setAvailableToppings = createAction(
  KamikazeActions.SetAvailableToppings,
  props<{ availableToppings: any[] }>(),
);

export const clearAvailableToppings = createAction(KamikazeActions.ClearAvailableToppings);

export const setNextPage = createAction(KamikazeActions.SetNextPage, props<{ nextPage: string }>());

export const storeCatalog = createAction(
  KamikazeActions.StoreCatalog,
  props<{ catalog: any; catalogName: string }>(),
);

export const storeCatalogSuccess = createAction(KamikazeActions.StoreCatalogSuccess);

export const storeProductData = createAction(
  KamikazeActions.StoreProductData,
  props<{ productData: any }>(),
);

export const storeProductType = createAction(
  KamikazeActions.StoreProductType,
  props<{ productType: any }>(),
);

export const storeProductTypeFromUrl = createAction(
  KamikazeActions.StoreProductTypeFromUrl,
  props<{ productType: any }>(),
);

export const proceedToCheckout = createAction(KamikazeActions.ProceedToCheckout);

export const setCurrentZone = createAction(
  KamikazeActions.SetCurrentZone,
  props<{ currentZone: any }>(),
);

export const setIsLoading = createAction(
  KamikazeActions.SetIsLoading,
  props<{ isLoading: boolean }>(),
);

export const setToppingsPicked = createAction(KamikazeActions.SetToppingsPicked,props<{ toppingsPicked: any }>() );

export const setToppingsAdded = createAction(KamikazeActions.SetToppingsAdded,props<{ toppingsAdded: any }>() );

export const clearToppingsAdded = createAction(KamikazeActions.ClearToppingsAdded);

export const clearToppingsPicked = createAction(KamikazeActions.ClearToppingsPicked);

export const setCoupon = createAction(KamikazeActions.SetCoupon, props<{ couponCode: string }>());

export const removeCoupon = createAction(KamikazeActions.RemoveCoupon);

export const setDiscountPrice = createAction(
  KamikazeActions.SetDiscountPrice,
  props<{ DiscountPrice: any }>(),
);

export const updateOrderPrice = createAction(
  KamikazeActions.UpdateOrderPrice,
  props<{ OrderPrice: any }>(),
);

export const isCouponValid = createAction(
  KamikazeActions.IsCouponValid,
  props<{ isCouponValid: boolean }>(),
);

export const refreshPaymentSession = createAction(
  KamikazeActions.RefreshPaymentSession,
  props<{ refreshPaymentSessionData: any }>(),
);

export const resetSelectedToppings = createAction(KamikazeActions.ResetSelectedToppings);

export const addSelectedTopping = createAction(KamikazeActions.AddSelectedTopping);

export const clearSelectedToppings = createAction(KamikazeActions.ClearSelectedToppings);

export const clearSelectedToppingsSuccess = createAction(
  KamikazeActions.ClearSelectedToppingsSuccess,
  props<{ bookedOrder: any }>(),
);

export const continueWithNoToppings = createAction(KamikazeActions.ContinueWithNoToppings);

export const addSelectedToppingSuccess = createAction(
  KamikazeActions.AddSelectedToppingSuccess,
  props<{ bookedOrder: any }>(),
);

export const addSelectedToppingFail = createAction(
  KamikazeActions.AddSelectedToppingFail,
  props<{ response: any }>(),
);

export const setCurrentTopping = createAction(
  KamikazeActions.SetCurrentTopping,
  props<{ currentTopping: any }>(),
);

export const setSelectedProduct = createAction(
  KamikazeActions.SetSelectedProduct,
  props<{ selectedProduct: any }>(),
);

export const storeToppingData = createAction(
  KamikazeActions.StoreToppingData,
  props<{ toppingData: any }>(),
);

export const getAvailableHoursReturn = createAction(KamikazeActions.GetAvailableHoursReturn);

export const getAvailableHoursReturnSucess = createAction(
  KamikazeActions.GetAvailableHoursReturnSuccess,
  props<{ availableToppingHours: any }>(),
);

export const setElementsAmount = createAction(
  KamikazeActions.SetElementsAmount,
  props<{elementsToShowInMyServices: number}>(),
);

export const setPageLocation = createAction(
  KamikazeActions.SetPageLocation,
  props<{pageLocationInMyServices: number}>(),
);

export const setUserId = createAction(
  KamikazeActions.GetServicesSuccess,
  props<{ userId: any }>(),
);

export const setEnumMyServices = createAction(
  KamikazeActions.SetEnumMyServices,
  props<{enumMyServices: number}>(),
);

export const getServicesSuccess = createAction(
  KamikazeActions.GetServicesSuccess,
  props<{ myServices: any }>(),
);

export const cancelServiceSuccess = createAction(
  KamikazeActions.CancelServiceSuccess,
  props<{ cancelledService: any }>(),
);


export const getServices = createAction(KamikazeActions.GetServices);

export const cancelService = createAction(KamikazeActions.CancelService,   props<{ selectedServiceToCancel: any }>());

export const showService = createAction(KamikazeActions.ShowService, props<{ selectedServiceToShow: any }>());

export const showServiceSuccess = createAction(KamikazeActions.ShowServiceSuccess, props<{ selectedServiceToShowData: any }>());

export const clearCoupon = createAction(KamikazeActions.ClearCoupon);

export const confirmOrder = createAction(KamikazeActions.ConfirmOrder);

export const confirmedOrderSuccess = createAction(KamikazeActions.ConfirmedOrderSuccess,
  props<{ confirmedOrder: any }>(),);

export const purchaseCompleted = createAction(KamikazeActions.PurchaseCompleted);

export const replaceProductId = createAction(KamikazeActions.ReplaceProductId,
  props<{ newProductId: string, productType: string }>(),);