import { Component, OnDestroy, OnInit } from '@angular/core';
import { CafNotificationSettingsModel, CaflerIconSettingsModel } from '@cafler/common-ui';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { first, pairwise, takeUntil } from 'rxjs/operators';
import { VerticalsTypeEnum } from 'src/app/Enums/verticalsType.enum';
import { PresentationFacade } from 'src/app/facades/presentation.facade';
import { TimeFormatterService } from 'src/app/helpers/time-formatter.service';
import {
  selectCurrentCountry,
  selectCurrentLang,
  selectServiceToShowData,
} from '../../store/selectors';
import { KamikazeActions } from 'src/app/store/actions';

import { ProgressServiceBarModel } from 'src/app/components/progress-service-bar/model/progress-service-bar.model';
import { HelperService } from 'src/app/services/helper.service';

import { VehicleFuelTypesEnum } from 'src/app/Enums/vehicle-fuel-types.enum';
import { CustomCurrencyPipe } from 'src/app/pipes/currency.pipe';
import { CaflerPopupHtmlCustomService } from 'src/app/components/popup-html-custom/service/popup-html-custom.service';
import { CancelPopupSettingsModel } from 'src/app/components/cancel-popup/models/cancel-popup-settings.model';
import { CancelPopupService } from 'src/app/components/cancel-popup/service/cancel-popup.service';
import { PhotoGallerySettings } from 'src/app/components/photo-gallery/display.model';
import { Image } from 'src/app/models/fe/image.model';
import * as moment from 'moment';
import { getNavigatorLang } from 'src/app/services/utils';
import { environment } from 'src/environments/environment';
import { ProgressVerticalBarModel } from 'src/app/components/progress-vertical-bar/progress-vertical-bar.model';
import { ServicesStateEnum } from 'src/app/Enums/serviceState.enum';
import { convertMetersToKilometers, convertMetersToMiles } from 'src/app/helpers/converter.service';
import { CountryIsoCode } from 'src/app/Enums/countryIsoCode.enum';
import { DistanceUnit } from 'src/app/models/distanceUnit';
import { getDistanceUnitPerCountryRegionIsoCode } from 'src/app/helpers/UnitsPerCountry';
import { DistanceUnitCodes } from 'src/app/Enums/unit.map';
import { vehicleStatusOptions } from 'src/app/constants';

@Component({
  selector: 'my-service-details',
  templateUrl: './my-service-details.page.html',
  styleUrls: ['./my-service-details.page.scss'],
})
export class MyServiceDetailsPage implements OnInit, OnDestroy {
  private readonly destroySubjects$ = new ReplaySubject<void>(1);

  pdfTrackingURL = `${environment.trackingURL}/api/tracking/order/download/details`;
  vehicleFuelTypesEnum = VehicleFuelTypesEnum;
  iconChevronDownBlueSettings = { name: 'caf-chevron-down-select', alt: 'chevron down' };
  isCommentShown = false;
  readytoLoad: boolean = false;
  serviceData: any = {};
  verticalName: string = '';
  orderDetails: any;
  vehicleChassisIcon!: CaflerIconSettingsModel;
  dateStartDateFormatted: string = '';
  isCheckList: boolean = false;
  timeRange: string = '';
  city: string = '';
  currency: any;
  lang: string = '';
  stationMotAddress: string = '';
  productConfiguration: any;
  progressBarStateSettings!: ProgressServiceBarModel;
  fuelType: string = '';
  fuelAmount: string = '';
  dropOffDate: string = '';
  dateEndDateFormatted: string = '';
  pickupTimeString: string = '';
  dropoffTimeString: string = '';
  stationName: string = '';
  trackingUrl!: string;
  mainProduct: any;
  toppings: any[] = [];
  servicesStateEnum = ServicesStateEnum;
  popUpRef: any;
  isInspectionPaid: boolean = false;
  isCancel: boolean = false;
  isInMaintenance: boolean = false;
  photoDisplayOptions: 'display' | 'noDisplay' | 'transitalia' = 'display';
  taxFee: number = 0;
  taxAmount: number = 0;
  distance?: string;
  distanceUnit?: string;
  verticalsTypeEnum = VerticalsTypeEnum;
  showProgressBar: boolean = true;
  showMap: boolean = false;
  currentCountry: any;
  isPaymentDetailsDisplay: boolean = true;
  isMotHomologation = false;
  homologationConfig: any;
  pickupPhotoGallerySettings: PhotoGallerySettings = {
    id: 'pickup',
    cap: 7,
    images: [],
  };
  vehicleStatusText: string = '';

  cancelNotification: CafNotificationSettingsModel = {
    text: 'cafler.my-service-details.cancel-notification',
    type: 'error',
    fontSize: '16px',
  };

  dropoffPhotoGallerySettings: PhotoGallerySettings = {
    id: 'dropoff',
    cap: 7,
    images: [],
  };

  downloadIcon = <CaflerIconSettingsModel>{
    name: 'caf-download',
    alt: 'descargar pdf',
    width: '22px',
    height: '22px',
  };

  simpleKeyIcon = <CaflerIconSettingsModel>{
    name: 'caf-simple-key',
    alt: 'key',
    width: '24px',
    height: '24px',
  };

  pinIcon = <CaflerIconSettingsModel>{
    name: 'caf-pin-location-transparent',
    alt: 'pin',
    width: '24px',
    height: '24px',
  };

  calendarIcon = <CaflerIconSettingsModel>{
    name: 'caf-simple-calendar',
    alt: 'fecha de servicio',
    width: '20px',
    height: '20px',
  };

  timeIcon = <CaflerIconSettingsModel>{
    name: 'caf-clock-dark-three-oclock',
    alt: 'hora del servicio',
    width: '20px',
    height: '20px',
  };

  galleryEmptyStateIcon = <CaflerIconSettingsModel>{
    name: 'caf-blue-car-with-check',
    alt: 'coche azul',
  };

  locationIcon = <CaflerIconSettingsModel>{
    name: 'caf-pin-location-transparent',
    alt: 'pin',
    width: '20px',
    height: '20px',
  };

  envelopeIcon = <CaflerIconSettingsModel>{
    name: 'caf-envelope',
    alt: 'email',
    width: '20px',
    height: '20px',
  };
  driverIcon = <CaflerIconSettingsModel>{
    name: 'caf-person-2',
    alt: 'driver',
    width: '20px',
    height: '20px',
  };

  phoneIcon = <CaflerIconSettingsModel>{
    name: 'caf-phone-outline',
    alt: 'phone',
    width: '20px',
    height: '20px',
  };

  fuelIcon = <CaflerIconSettingsModel>{
    name: 'caf-refueling-black',
    alt: 'tipo de combustible',
    width: '20px',
    height: '20px',
  };

  gearIcon = <CaflerIconSettingsModel>{
    name: 'caf-pre-mot-black',
    alt: 'estado del vehículo',
    width: '20px',
    height: '20px',
  };

  creditCardIcon = <CaflerIconSettingsModel>{
    name: 'caf-credit-card',
    alt: 'Itv tasa',
    width: '20px',
    height: '20px',
  };

  simpleCalendarIcon = <CaflerIconSettingsModel>{
    name: 'caf-simple-calendar',
    alt: 'fecha del servicio',
    width: '20px',
    height: '20px',
  };

  periodicMotPassed = <CaflerIconSettingsModel>{
    name: 'caf-mot-black',
    width: '20px',
    height: '20px',
  };

  PaperWithCheckIcon = <CaflerIconSettingsModel>{
    name: 'caf-paper-check',
    alt: 'paper with check icon',
    width: '20px',
    height: '20px',
  };

  DistanceIcon = <CaflerIconSettingsModel>{
    name: 'caf-distance',
    alt: 'distancia',
    width: '20px',
    height: '20px',
  };

  buttonSettings: any = {
    text: 'cafler.my-services-details.display-checklist',
    disabled: false,
  };

  emptyStateImgUrl = '../assets/svg/service-details.empty-state.svg';
  emptyStateValetImgUrl = '../assets/svg/service-details.empty-state.valet.svg';
  replacementReturnDate = '';

  progressVerticalBarSettings: ProgressVerticalBarModel = {
    steps: [
      {
        name: 'cafler.my-service.details.stepper.confirmed-state',
        time: '',
        isCompleted: false,
      },
      {
        name: 'cafler.my-service.details.stepper.picking-up-state',
        time: '',
        isCompleted: false,
      },
      {
        name: 'cafler.my-service.details.stepper.in-progress-state',
        time: '',
        isCompleted: false,
      },
      {
        name: 'cafler.my-service.details.stepper.finish-state',
        isCompleted: false,
      },
    ],
    status: ServicesStateEnum.INITIALIZED,
  };

  trackingIcon: CaflerIconSettingsModel = {
    name: 'caf-pin-3D',
    alt: '',
    width: '24px',
    height: '24px',
  };

  productsWithOutPaymentDetails: VerticalsTypeEnum[] = [VerticalsTypeEnum.TOW_TRUCK];

  trackingMessage: string = '';

  constructor(
    private presentationFacade: PresentationFacade,
    private timeFormatterService: TimeFormatterService,
    private helperService: HelperService,
    public customCurrencyPipe: CustomCurrencyPipe,
    private popupService: CancelPopupService,
    private popupHtmlService: CaflerPopupHtmlCustomService,
    private store: Store,
  ) {
    this.isInMaintenance = false; //TODO: change this flag for maintenance
    this.photoDisplayOptions = this.isInMaintenance ? 'noDisplay' : 'display';

    this.store.dispatch({
      type: KamikazeActions.SetCurrentPage,
      currentPage: 'my-services-details',
    });

    this.store
      .select(selectCurrentLang)
      .pipe(takeUntil(this.destroySubjects$))
      .pipe(first())
      .subscribe((lang) => {
        if (lang) {
          this.lang = lang;
        }
      });

    this.store
      .select(selectCurrentLang)
      .pipe(takeUntil(this.destroySubjects$))
      .pipe(pairwise())
      .subscribe(([prev, next]) => {
        if (prev == next) {
          return;
        }
        this.lang = next;
        this.getDateInProperLangAndFormat();
      });

    this.store
      .select(selectCurrentCountry)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((country: any) => {
        if (country.PreferredRegionCurrencySymbol) {
          this.currency = country.PreferredRegionCurrencySymbol;
        }
      });

    this.store
      .select(selectCurrentCountry)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((country: any) => {
        this.currentCountry = country;
      });
  }

  initProgressBar(
    state: ServicesStateEnum,
    trackingNode: { Title: string; Date: string | null }[],
  ) {
    switch (state) {
      case ServicesStateEnum.INITIALIZED:
        this.trackingIcon.name = 'caf-pin-3D';
        this.trackingIcon.alt = 'inicializado';
        this.trackingMessage = 'cafler.my-service.details.stepper.confirmed.message-state';
        break;
      case ServicesStateEnum.PENDING:
        this.trackingIcon.name = 'caf-pin-3D';
        this.trackingIcon.alt = 'inicializado';
        this.trackingMessage = 'cafler.my-service.details.stepper.confirmed.message-state';
        break;
      case ServicesStateEnum.CONFIRMED:
        this.trackingIcon.name = 'caf-pin-3D';
        this.trackingIcon.alt = 'Confirmado';
        this.trackingMessage = 'cafler.my-service.details.stepper.confirmed.message-state';
        break;
      case ServicesStateEnum.PICKING_UP:
        this.trackingIcon.name = 'caf-key-3D';
        this.trackingIcon.alt = 'recogiendo';
        this.trackingMessage = 'cafler.my-service.details.stepper.picking-up.message-state';
        break;
      case ServicesStateEnum.IN_PROGRESS:
        this.trackingIcon.name = 'caf-file-3D';
        this.trackingIcon.alt = 'en progreso';
        this.trackingMessage = 'cafler.my-service.details.stepper.in-progress.message-state';
        break;
      case ServicesStateEnum.DELIVERING:
        this.trackingIcon.name = 'caf-drivers-hat-3D';
        this.trackingIcon.alt = 'entregando';
        this.trackingMessage = 'cafler.my-service.details.stepper.delivering.message-state';
        break;
      case ServicesStateEnum.FINISHED:
        this.trackingMessage = 'cafler.my-service.details.stepper.finish.message-state';
        break;
      case ServicesStateEnum.CANCELLED:
      case ServicesStateEnum.CANCELLED_BY_CAFLER:
      case ServicesStateEnum.CANCELLED_BY_CLIENT:
        this.trackingMessage = 'cafler.my-service.details.stepper.cancelled.message-state';
        break;
    }

    if (
      state === ServicesStateEnum.INITIALIZED ||
      state === ServicesStateEnum.CONFIRMED ||
      state === ServicesStateEnum.PENDING
    ) {
      this.showProgressBar = false;
      return;
    }

    this.showProgressBar = true;

    for (let i = 0; i < this.progressVerticalBarSettings.steps.length; i++) {
      if (
        state === ServicesStateEnum.DELIVERING &&
        trackingNode[i].Title === ServicesStateEnum.IN_PROGRESS
      ) {
        this.progressVerticalBarSettings.steps[i].isCompleted = true;
        this.progressVerticalBarSettings.steps[i].isActive = false;
        break;
      }

      if (trackingNode[i].Title === state) {
        if (trackingNode[i].Title === ServicesStateEnum.FINISHED) {
          this.progressVerticalBarSettings.steps[i].isCompleted = true;
          const initProgress = moment(trackingNode[i - 2].Date);
          const endProgress = moment(trackingNode[i - 1].Date);

          let rangeH = endProgress.diff(initProgress, 'hours');
          let rangeM = 0;

          if (rangeH === 0) {
            rangeM = endProgress.diff(initProgress, 'minutes');
          }

          let rangeText = rangeH > 0 ? `(${rangeH} h)` : `(${rangeM} min)`;
          this.progressVerticalBarSettings.steps[i - 1].time = rangeText;
          this.progressVerticalBarSettings.steps[i].time = endProgress.format('DD/MM hh:mm A');
        }

        if (trackingNode[i].Title === ServicesStateEnum.IN_PROGRESS) {
          this.progressVerticalBarSettings.steps[i].isCompleted = true;
          this.progressVerticalBarSettings.steps[i].isActive = true;
        }
        break;
      }

      this.progressVerticalBarSettings.steps[i].isCompleted = true;

      if (trackingNode[i].Title === ServicesStateEnum.IN_PROGRESS) {
        continue;
      }

      if (trackingNode[i].Date !== null) {
        const a = moment(trackingNode[i].Date).locale(this.lang).format('DD/MM hh:mm A'); //TODO FORMATO DD MMM
        if (a) {
          this.progressVerticalBarSettings.steps[i].time = a;
        }
      }
    }
  }

  loadServiceData(serviceData: any) {
    try {
      this.isMotHomologation = serviceData.Products[0].LocalizationKey === 'mot-homologation';
      if (this.isMotHomologation) {
        this.homologationConfig =
          serviceData.Products[0].Configuration.HomologationSubConfiguration;
      }
      this.isPaymentDetailsDisplay = !this.productsWithOutPaymentDetails.includes(
        serviceData.VerticalType,
      );
      this.vehicleChassisIcon = this.getIconVehicleChassis(serviceData.VehicleChassisType);
      this.timeRange = this.timeFormatterService.getTimeRangeString(
        serviceData.ServiceStartDate.split(' ')[0],
        serviceData.ServiceEndDate.split(' ')[0],
      );

      this.taxFee = serviceData.ZoneTax;
      this.taxAmount = serviceData.OrderPriceWithoutTax * this.taxFee;

      this.pickupPhotoGallerySettings.images = serviceData?.PickupImages.map(
        (imgUrl: string, index: number) =>
          ({
            url: imgUrl,
            name: `imagen_de_recogida_${index}`,
            alt: `imagen de recogida ${index}`,
          } as Image),
      );

      this.dropoffPhotoGallerySettings.images = serviceData?.DropoffImages.map(
        (imgUrl: string, index: number) =>
          ({
            url: imgUrl,
            name: `imagen_de_devolución ${index}`,
            alt: `imagen de devolución ${index}`,
          } as Image),
      );

      this.mainProduct = serviceData.Products[0];
      this.toppings = serviceData.Products.slice(1);

      const productConfiguration = this.mainProduct.Configuration;
      this.productConfiguration = productConfiguration;

      if (productConfiguration?.FuelType) {
        const fuelAmountTaxFree = productConfiguration.MonetaryAmount / (1 + this.taxFee);

        this.fuelAmount = fuelAmountTaxFree.toFixed(2);
        this.fuelType = this.vehicleFuelTypesEnum[productConfiguration.FuelType];
      }

      this.isInspectionPaid = productConfiguration?.IsInspectionPaid;

      if (serviceData.TrackingUrl) {
        this.trackingUrl = serviceData.TrackingUrl;
      }

      if (serviceData.DistanceTraveled) {
        if (this.currentCountry?.RegionIsoCode === CountryIsoCode.GBR) {
          this.distance = convertMetersToMiles(serviceData.DistanceTraveled).toFixed(2);
        } else {
          this.distance = convertMetersToKilometers(serviceData.DistanceTraveled).toFixed(2);
        }

        const unit: DistanceUnit = getDistanceUnitPerCountryRegionIsoCode(
          this.currentCountry?.RegionIsoCode,
        );
        this.distanceUnit = DistanceUnitCodes[unit];
      }

      this.verticalName = VerticalsTypeEnum[serviceData.VerticalType].toLowerCase();
      this.progressBarStateSettings = {
        status: serviceData.OrderStatus,
      };

      this.isCancel =
        serviceData.OrderStatus == ServicesStateEnum.CANCELLED ||
        serviceData.OrderStatus == ServicesStateEnum.CANCELLED_BY_CLIENT ||
        serviceData.OrderStatus == ServicesStateEnum.CANCELLED_BY_CLIENT;

      this.showMap =
        serviceData.OrderStatus == ServicesStateEnum.PICKING_UP ||
        serviceData.OrderStatus == ServicesStateEnum.IN_PROGRESS ||
        serviceData.OrderStatus == ServicesStateEnum.DELIVERING;

      this.getDateInProperLangAndFormat();

      if (serviceData.VerticalType === VerticalsTypeEnum.VALET) {
        this.pickupTimeString = this.timeFormatterService.getTimeRangeString(
          serviceData.ServiceStartDate,
        );
        this.dropoffTimeString = this.timeFormatterService.getTimeRangeString(
          serviceData.ServiceEndDate,
        );
        this.stationName = this.helperService.getStationName(
          serviceData.Products[0].Configuration.StationId,
        );
      }

      if (productConfiguration?.AppointmentCode) {
        this.stationMotAddress = this.helperService.getStationMOTAddress(
          productConfiguration.StationId,
        );
      }

      if (productConfiguration?.VehicleStatus) {
        this.vehicleStatusText = vehicleStatusOptions[productConfiguration.VehicleStatus].text;
      }

      this.initProgressBar(serviceData.OrderStatus, serviceData.TrackingNodes);
    } catch (e) {
      console.error('Error loading service data: ', e);
    }
  }

  ngOnInit(): void {
    this.store
      .select(selectServiceToShowData)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((serviceData: any) => {
        if (serviceData) {
          this.serviceData = serviceData;
          this.loadServiceData(this.serviceData);

          const dateWithoutOffset = this.timeFormatterService.removeLocationAndOffset(
            this.serviceData.ServiceStartDate,
          );
          if (this.serviceData?.Products[1]?.Configuration?.AmountOfDays) {
            this.replacementReturnDate = moment(dateWithoutOffset)
              .add(this.serviceData.Products[1].Configuration.AmountOfDays, 'days')
              .locale(this.lang)
              .format('LL');
          }

          if (serviceData.IsTransitaliaService) {
            this.photoDisplayOptions = 'transitalia';
          }

          this.readytoLoad = true;
        }
      });
  }

  displayComment() {
    this.isCommentShown = !this.isCommentShown;
  }

  displayCheckList() {
    this.popUpRef = this.popupHtmlService.open('', {
      caflerHash: this.serviceData.OrderHash,
      lang: this.lang,
    });
  }

  redirectToCancelPopup() {
    const popupSettings = <CancelPopupSettingsModel>{
      id: 'cancelPopup',
      title: 'cafler.cancel.service.popup.title',
      description: 'cafler.cancel.service.popup.description',
      confirmButton: true,
      cancelButton: true,
      confirmButtonText: 'cafler.cancel.service.popup.keep.button',
      cancelButtonText: 'cafler.cancel.service.popup.cancel.button',
      confirmButtonClasses: 'caf-btn-keep-service',
      cancelButtonClasses: 'caf-btn-cancel-service',
    };
    const ref = this.popupService.open('', popupSettings);
    ref.afterClosed$.subscribe((data) => {
      if (data.data.info === 'confirm') {
      } else if (data.data.info === 'cancel') {
        this.store.dispatch({
          type: KamikazeActions.CancelService,
          selectedServiceToCancel: { caflerHash: this.serviceData.OrderHash },
        });
      }
    });
    return ref;
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();

    // this.store.dispatch({ type: KamikazeActions.ShowService, selectedServiceToShow: null });
    this.store.dispatch({
      type: KamikazeActions.ShowServiceSuccess,
      selectedServiceToShowData: null,
    });

    if (this.popUpRef) {
      this.popUpRef.close();
    }
  }

  isServiceCancelable(status: ServicesStateEnum): boolean {
    return [
      ServicesStateEnum.INITIALIZED,
      ServicesStateEnum.PICKING_UP,
      ServicesStateEnum.CONFIRMED,
    ].includes(status);
  }

  getIconVehicleChassis(vehicleChassisType: number): CaflerIconSettingsModel {
    const vehicleChassisIcon = <CaflerIconSettingsModel>{
      name: '',
      alt: '',
    };

    const getVehicleFamily = this.presentationFacade.getVehicleFamily(vehicleChassisType);

    switch (getVehicleFamily) {
      case 'car':
        vehicleChassisIcon.name = 'caf-green-car-3D';
        vehicleChassisIcon.alt = 'Car';
        break;
      case 'van':
        vehicleChassisIcon.name = 'caf-green-van-3D';
        vehicleChassisIcon.alt = 'Van';
        break;
      case 'motorcycle':
        vehicleChassisIcon.name = 'caf-green-moto-3D';
        vehicleChassisIcon.alt = 'Motorbike';
        break;
      default:
        vehicleChassisIcon.name = 'caf-green-car-3D';
        vehicleChassisIcon.alt = 'Car';
        break;
    }

    return vehicleChassisIcon;
  }

  getDateInProperLangAndFormat() {
    const startPickUpDate = this.timeFormatterService.removeLocationAndOffset(
      this.serviceData.ServiceStartDate,
    );
    const dropOffDate = this.timeFormatterService.removeLocationAndOffset(
      this.serviceData.ServiceEndDate,
    );

    this.dateStartDateFormatted = this.timeFormatterService.formatDateWithMonthName(
      startPickUpDate,
      this.lang,
    );
    this.dateEndDateFormatted = this.timeFormatterService.formatDateWithMonthName(
      dropOffDate,
      this.lang,
    );
  }
}
