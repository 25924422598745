<section class="overview-card overview-grey-card overview-breakdown-price">
    <h3 class="breakdown-price-title">
        {{'cafler.page.overview.breakdown-price.title' | translate}}
    </h3>
    <div class="breakdown-price-content">
        <div class="card-description" *ngFor="let item of priceBreakdown">
            <div class="price-pair">
                <div>
                    <span class="label" [innerHtml]="item.label | translate"></span>
                </div>        
                <span class="description">{{ item.value || '-' }}</span>
            </div>
            
            <div *ngIf=" item.days && item.days === 1" class="label label-day">
                <p class="price-replacement">{{item.amount + ' x ' + item.days + ' ' + ('cafler.day' | translate)}}</p>
            </div>
            <div *ngIf="item.days && item.days > 1" class="label label-day">
                <p class="price-replacement">{{item.amount + ' x ' + item.days + ' ' + ('cafler.days' | translate)}}</p>
            </div>
        </div>

        <div *ngIf="toppings.length > 0" class="toppings">
            <div class="toppings__container" *ngFor="let item of toppings">
                <div class="toppings__button-container">
                    <button class="topping-delete-button">
                        <caf-icon class="chevron-icon" (click)="removeTopping(item.id)" [settings]="iconExitSettings"></caf-icon>
                      </button>
                </div>
                <div class="toppings__content">
                    <div class="pair">
                        <div class="title-container">
                            <span class="topping-title">{{item.title | translate}}
                                <caf-badge class='extra-badge' style="display:-webkit-inline-box;" [settings] = "badge"></caf-badge>
                            </span>
                        </div> 
        
                        <span class="topping-price">{{item.productPrice}}</span>
                    </div>
                    <div class="pair">
                        <div *ngIf="item.serviceTime; else noServiceTime" class="topping-detail">
                            <span>{{item.detail | translate}} &bull; {{item.serviceTime}}</span>
                        </div>
                        <ng-template #noServiceTime>
                            <span class="topping-detail">{{item.detail | translate}}</span>
                        </ng-template>
                          
                        <span class="detail-price">{{item.detailPrice}}</span>
                    </div>
                </div> 
            </div>
        </div>

        <div *ngIf="taxes" class="card-description">
            <div class="price-pair">
                <div>
                    <span class="label" [innerHtml]="taxes.label | translate"></span>
                    <span style="padding-left: 5px;">
                      {{ ' ' + taxes.zoneTax * 100 + '%'}}
                    </span>
                </div>
                <span class="description">{{ taxes.value || '-' }}</span>
            </div>
        </div>
    </div>
</section>