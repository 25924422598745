export const datepickerMaxDate = () => {
  const now = new Date();
  const month = now.getMonth() + 6;
  now.setMonth(month);
  return now;
};
export function toNN(n: number) {
  return n > 9 ? '' + n : '0' + n;
}
export function getNavigatorLang() {
  let temp = navigator.language.split('-');
  let navigatorLangCode = 'en-UK';

  // // In catalan we use spanish instead of english as default
  if (temp[0] === 'es' || temp[0] === 'ca') {
    navigatorLangCode = 'es-ES';
  }
  if (temp[0] === 'de') {
    navigatorLangCode = 'de-DE';
  }
  if (temp[0] === 'it') {
    navigatorLangCode = 'it-IT';
  }
  if (temp[0] === 'fr') {
    navigatorLangCode = 'fr-FR';
  }
  return navigatorLangCode;
}
