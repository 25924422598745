import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { CaflerDatepickerSettingsModel, CaflerErrorSettingsModel, CaflerIconSettingsModel, GoogleAddress, GoogleMapsService } from '@cafler/common-ui';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { first, pairwise, takeUntil } from 'rxjs/operators';
import { PresentationFacade } from '../../facades/presentation.facade';
import { SingleDataHelperService } from '../../helpers/single-data-helper.service';
import { FormsHelperService } from '../../services/forms.service';
import { LaunchDarklyFlagNames, LaunchDarklyService } from '../../services/LaunchDarkly.service';
import { NavigationService } from '../../services/navigation.service';
import { datepickerMaxDate } from '../../services/utils';
import { KamikazeActions, setIsLoading } from '../../store/actions';
import { selectCurrentCountry, selectCurrentLang, selectCurrentZone, selectIsAppInitialized, selectIsB2B, selectProductData, selectSelectedProduct, selectServiceTimeOptions } from '../../store/selectors';
import { operativeZonesURLByCountry } from 'src/app/config/operativeZonesURLByCountry';

@Component({
  selector: 'app-maintenance-form',
  templateUrl: './maintenance-form.component.html',
  styleUrls: ['./maintenance-form.component.scss']
})
export class MaintenanceFormComponent implements OnInit {
  private readonly destroySubjects$ = new ReplaySubject<void>(1);
  fg!: FormGroup;
  isLoading: boolean = true;
  isB2B: boolean = false;
  isSameDeliveryAddress!: boolean;
  timesFailedPickupAddress = 0;
  timesFailedDropoffAddress = 0;
  maxErrorsOnAddress = 4;
  isAppInitialized: boolean = false;
  selectedProduct: any;
  operativeZonesURL: string = '';
  
  combinationNotValid: boolean = false;
  serviceTimeOptions: any[] = [];
  readyToDisplayHours = false;
  dayChangedFlag = false;
  renderNewFeatureSpeechBubble?: boolean = false;
  flagSubscription: any;
  timeRangeOption: string = ""; 
  firstNewFeatureTry: boolean = true;
  currentCountry: any;
  isSpecificHour: boolean = false;
  noSpecificHourAvailable: boolean = false;
  noRangeHourAvailable: boolean = false;

  @ViewChild('startServiceTimeComponent') startServiceTimeComponent: any;
  @ViewChild('endServiceTimeComponent') endServiceTimeComponent: any;

  settings: any = {
    serviceType: {
      id: 'serviceType',
      formControlName: 'serviceType',
      placeholder: 'cafler.page.generic.service-type.placeholder',
      disabled: false,
      options: [],
    },
    fullName: {
      id: 'fullName',
      formControlName: 'fullName',
      placeholder: 'cafler.contact.name.placeholder',
      disabled: false,
    },
    countryPhoneCode: {
      id: 'countryPhoneCode',
      formControlName: 'countryPhoneCode',
      placeholder: 'cafler.contact.country-phone-code.placeholder',
      disabled: false,
      options: [],
    },
    phone: {
      id: 'phone',
      formControlName: 'phone',
      placeholder: 'cafler.contact.phone.placeholder',
      disabled: false,
      type: 'phone',
    },
    email: {
      id: 'email',
      formControlName: 'email',
      placeholder: 'cafler.contact.email.placeholder',
      disabled: false,
      type: 'email',
    },
    vehicleType: {
      id: 'vehicleType',
      formControlName: 'vehicleType',
      placeholder: 'cafler.page.fuel.body-type.placeholder',
      disabled: false,
      options: [],
    },
    carPlaque: {
      id: 'carPlaque',
      formControlName: 'carPlaque',
      placeholder: 'cafler.vehicle-info.plate.placeholder',
      disabled: false,
      type: 'text',
    },
    brand: {
      id: 'brand',
      formControlName: 'brand',
      placeholder: 'cafler.vehicle-info.brand.placeholder',
      disabled: false,
      required: true,
      type: 'text',
    },
    model: {
      id: 'model',
      formControlName: 'model',
      placeholder: 'cafler.vehicle-info.model.placeholder',
      disabled: false,
      required: true,
      type: 'text',
    },
    pickupAddress: {
      id: 'pickupAddress',
      formControlName: 'pickupAddress',
      placeholder: 'cafler.pickup-info.pickup-address.placeholder',
      options: { businessSuggestions: true, placeSuggestions: true },
    },
    pickupAddressDetails: {
      id: 'pickupAddressDetails',
      formControlName: 'pickupAddressDetails',
      placeholder: 'cafler.pickup-info.pickup-details.placeholder',
      disabled: false,
      required: false,
    },
    sameDeliveryAddress: {
      id: 'sameDeliveryAddress',
      formControlName: 'sameDeliveryAddress',
      label: 'cafler.page.wash.same-address-delivery',
      disabled: false,
      labelAlign: 'right',
    },
    pickupDate: <CaflerDatepickerSettingsModel>{
      id: 'pickupDate',
      formControlName: 'pickupDate',
      placeholder: 'cafler.pickup-info.pickup-day.placeholder',
      minDate: new Date(),
      maxDate: datepickerMaxDate(),
      icon: <CaflerIconSettingsModel>{
        alt: 'calendar',
        name: 'caf-notes-book-dark',
      },
      daysDisabled: [6,0],
      lang: 'currentLang'
    },
    dropoffAddress: {
      id: 'dropoffAddress',
      formControlName: 'dropoffAddress',
      placeholder: 'cafler.pickup-info.pickup-address.placeholder',
      options: { businessSuggestions: true, placeSuggestions: true },
      required: false,
    },
    dropoffAddressDetails: {
      id: 'dropoffAddressDetails',
      formControlName: 'dropoffAddressDetails',
      placeholder: 'cafler.pickup-info.pickup-details.placeholder',
      disabled: false,
      required: false,
    },
    startPickUpTime: {
      id: 'startPickUpTime',
      formControlName: 'startPickUpTime',
      placeholder: 'cafler.page.service-time.from-placeholder',
      disabled: false,
      options: [],
      required: true,
      leftIconSettings: {
        name: 'caf-clock-cafler',
        alt: 'Clock icon',
        height: '25px',
        width: '25px',
      },
    },
    endPickUpTime: {
      id: 'endPickUpTime',
      formControlName: 'endPickUpTime',
      placeholder: 'cafler.page.service-time.until-placeholder',
      disabled: true,
      options: [],
      required: false,
      leftIconSettings: {
        name: 'caf-clock-cafler',
        alt: 'Clock icon',
        height: '25px',
        width: '25px',
      },
    
    },
    additionalComments: {
      id: 'additionalComments',
      formControlName: 'additionalComments',
      placeholder: 'cafler.pickup-info.textarea.comments',
      minlength: 0,
      disabled: false,
      required: false,
    },

    selectTimeOption: {
      id: 'selectTimeOption',
      placeholder: 'cafler.page.service-time.select-time-option.placeholder',
      formControlName: 'selectTimeOption',
      disabled: false,
      required:false,
      options: [
        {
          key: 'range',
          text: 'cafler.page.service-time.select-time-option.range.text',
          subtext: 'cafler.page.service-time.select-time-option.range.subtext'
        },
        { 
         key: 'specific',
         text: 'cafler.page.service-time.select-time-option.specific.text',
         subtext: 'cafler.page.service-time.select-time-option.specific.subtext' 
        },

      ],
    },
    specificTimeRange: {
      id: 'specificTimeRange',
      placeholder: 'cafler.page.service-time.specific-time.selector.placeholder',
      formControlName: 'specificTimeRange',
      disabled: false,
      options: [],
      required:false,
      rightIconSettings: {
        name: 'caf-clock-cafler',
        alt: 'Clock icon',
        height: '25px',
        width: '25px',
      },
    },
  };
  settingsExtra: any = {
    speechBubble: {
      id:'newFeature',
      text: 'cafler.page.service-time.new-feature.speech-bubble.text',
    },

    locationIcon: <CaflerIconSettingsModel>{
      alt: 'location',
      name: 'caf-pin-location',
    },
    warningIcon: <CaflerIconSettingsModel> { name: 'caf-warning-exclamation', alt: 'warning', width: '14px', height: '14px'},
  };

  settingsErrors: any = {
    errorsFullName: <CaflerErrorSettingsModel>{
      formControlName: 'fullName',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsCountryPhoneCode: <CaflerErrorSettingsModel>{
      formControlName: 'countryPhoneCode',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsPhone: <CaflerErrorSettingsModel>{
      formControlName: 'phone',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'pattern', text: 'cafler.forms.error.phone-pattern' },
        { key: 'minlength', text: 'cafler.forms.error.min-length' },
      ],
    },
    errorsEmail: <CaflerErrorSettingsModel>{
      formControlName: 'email',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'email', text: 'cafler.forms.error.email' },
      ],
    },
    errorsCarPlaque: <CaflerErrorSettingsModel>{
      formControlName: 'carPlaque',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' },{key:'invalidLicensePlate', text: 'cafler.forms.error.invalid-license-plate'}],
    },
    errorsModel: <CaflerErrorSettingsModel>{
      formControlName: 'model',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsBrand: <CaflerErrorSettingsModel>{
      formControlName: 'brand',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsPickupDate: <CaflerErrorSettingsModel>{
      formControlName: 'pickupDate',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'notPresent', text: 'cafler.forms.error.date-not-in-past' },
      ],
    },
    errorsPickupAddress: <CaflerErrorSettingsModel>{
      formControlName: 'pickupAddress',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'selectedAddress', text: 'cafler.forms.error.selected-address' },
        {
          key: 'streetNumberNeeded',
          text: 'cafler.address-message-error-missing-street-number.title',
        },

        { key: 'addressNumber', text: 'cafler.forms.error.address-number' },
        {
          key: 'checkCoordinatesWithinCaflerArea',
          text: 'cafler.forms.error.cafler-country-coverage',
        },
      ],
    },
    errorsVehicleType: <CaflerErrorSettingsModel>{
      formControlName: 'vehicleType',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsDropoffAddress: <CaflerErrorSettingsModel>{
      formControlName: 'dropoffAddress',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'selectedAddress', text: 'cafler.forms.error.selected-address' },
        {
          key: 'streetNumberNeeded',
          text: 'cafler.address-message-error-missing-street-number.title',
        },

        { key: 'addressNumber', text: 'cafler.forms.error.address-number' },
        {
          key: 'checkCoordinatesWithinCaflerArea',
          text: 'cafler.forms.error.cafler-country-coverage',
        },
      ],
    },
    errorsStartPickUpTime: <CaflerErrorSettingsModel>{
      formControlName: 'startPickUpTime',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsEndPickUpTime: <CaflerErrorSettingsModel>{
      formControlName: 'endPickUpTime',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
  };

  constructor(
    private store: Store,
    private navigationService: NavigationService,
    private presentationFacade: PresentationFacade,
    private formsService: FormsHelperService,
    private googleMapsService: GoogleMapsService,
    private singleDataHelperService: SingleDataHelperService,
    private launchDarklyService: LaunchDarklyService) {


      if(launchDarklyService.isLoaded()){
        this.flagSubscription = launchDarklyService.flagChange.subscribe(() => this.initFlags(launchDarklyService));
      }else{
         this.initFlags(launchDarklyService);
      }
  
      this.fg = this.formsService.initializeForm(this.settings);
      this.store.select(selectCurrentZone).subscribe((zone) => {
        if (zone) {
          const bbox = googleMapsService.getBoundingBox(
            zone?.UpperRightBoundingBoxCenterLatitude,
            zone?.UpperRightBoundingBoxCenterLongitude,
            zone?.LowerLeftBoundingBoxCenterLatitude,
            zone?.LowerLeftBoundingBoxCenterLongitude,
          );
          this.settings.pickupAddress.options.bounds = bbox;
          this.settings.dropoffAddress.options.bounds = bbox;
        }
      });
      this.fg.get('sameDeliveryAddress')?.setValue(false);
      this.fg.get('dropoffAddress')?.clearValidators();
      this.fg.get('dropoffAddress')?.updateValueAndValidity();
  
      this.store.select(selectCurrentCountry).subscribe((country: any) => {
        if (country) {
          // setting default prefix code number
          this.fg.get('countryPhoneCode')?.setValue(country.InternationalPhonePrefix);
          this.currentCountry = country;
          const isoCode: string = country.RegionIsoCode 
          if(isoCode === 'esp' || isoCode === 'gbr' || isoCode === 'fra'){
            this.operativeZonesURL = operativeZonesURLByCountry[isoCode]
          } 
        }
      });
  
      this.store.select(selectCurrentLang).subscribe((lang) => {
        if(lang){
          this.settings.pickupDate = {
            ...this.settings.pickupDate,
            lang: lang.split('-')[0]
          }
        } 
      })

     }

    ngOnInit() {
      this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: true });
      this.store.dispatch({ type: KamikazeActions.SetNextPage, nextPage: 'overview' });
      this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'maintenance' });
      this.store.dispatch({ type: KamikazeActions.ClearAvailableToppings });
      
      this.store.dispatch({
        type: KamikazeActions.SetFormValidity,
        isFormValid: this.formsService.checkCurrentFormValidity(),
      });
      // comprobamos si la app esta inicializada
      this.store
        .select(selectIsAppInitialized)
        .pipe(takeUntil(this.destroySubjects$))
        .subscribe((isAppInitialized: boolean) => {
          this.isAppInitialized = isAppInitialized;
        });
  
      this.store.select(selectIsB2B).subscribe((isB2B) => {
        this.isB2B = isB2B;
      });
  
      this.store.select(selectSelectedProduct).subscribe((product) => {
        if (product) {
          this.selectedProduct = product;
          this.fg.controls.serviceType.setValue(product.ordersManagerProductId);
          this.navigationService.currentPageTitle = `cafler.forms.${product.alias}.title`;
        }
      });

      this.presentationFacade.getCoreDataCatalog('TransferTypeEnum').subscribe((vehicleTypes) => {
        if (vehicleTypes) {
          const formattedVehicleTypes = vehicleTypes.map((t: any) => ({
            key: t.key,
            text: `cafler.product-type.media-larga-distancia.vehicle-type.${t.text}`,
          }));

          let vehicleProductType = this.selectedProduct.category.split('-')[1];
          this.settings.vehicleType.options = this.getProperVehicleTypes(vehicleProductType, formattedVehicleTypes);
        }
      });
  
      this.store.select(selectServiceTimeOptions).subscribe((serviceTimeOptions) => {
        if(serviceTimeOptions !== undefined){
          this.serviceTimeOptions = serviceTimeOptions;
        }
      })
  
      if(this.selectedProduct.category === 'BundledInspections-Motorbike'
      || this.selectedProduct.category === 'BundledInspections-Sedan'){
        //set minim day to pickup on tomorrow
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        this.settings.pickupDate.minDate = tomorrow;
      }

      this.fg
      .get(this.settings.pickupDate.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .pipe(first())
      .subscribe(() => {
        // set range time as the default option
        this.fg
        .get(this.settings.selectTimeOption.formControlName)?.setValue('range')
        this.readyToDisplayHours = true;
      });
  
      this.fg
      .get(this.settings.pickupDate.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .pipe(pairwise())
      .subscribe(([prev, next]) => {
        if((prev.getTime() === next.getTime())){
          return;
        }
    
        if (this.readyToDisplayHours) {
          this.resetServiceTimeStyles(true);
          this.getSlotHours(this.isSpecificHour);
        }
        this.readyToDisplayHours = true;    
      });
  
      this.fg
      .get(this.settings.selectTimeOption.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .pipe(first())
      .subscribe((timeOption) => {
  
        this.isSpecificHour = timeOption === 'specific';
        this.getSlotHours(this.isSpecificHour);
      });
      
      this.fg
      .get(this.settings.selectTimeOption.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .pipe(pairwise())
      .subscribe(([prev, next]) => {
        if(prev === next){
          return;
        }
        this.firstNewFeatureTry = false;
        this.isSpecificHour = next === 'specific';
  
        this.getSlotHours(this.isSpecificHour);
        this.resetTimeAndErrors();
        
      });
  
      this.fg
        .get(this.settings.specificTimeRange.formControlName)
        ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
        .subscribe((newValue) => {
          this.updateSpecificHours(newValue);
        });
  
        this.fg
        .get(this.settings.startPickUpTime.formControlName)
        ?.statusChanges.pipe(takeUntil(this.destroySubjects$))
        .subscribe((status) => {
          if(this.timeRangeOption === 'range'){
            this.changeEndPickupTimeDisability(status)
          }else{
            this.dayChangedFlag = false;
          }
        });
  
        this.fg
        .get(this.settings.startPickUpTime.formControlName)
        ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
        .subscribe((newValue) => {
  
          if(this.timeRangeOption === 'range'){
            this.updateEndRangeHours(newValue);
            return;
          }
        });
  
        this.fg
        .get(this.settings.endPickUpTime.formControlName)
        ?.statusChanges.pipe(takeUntil(this.destroySubjects$))
        .subscribe((status) => {
          if (status === 'VALID') {
            this.combinationNotValid = false;
          }
        });
  
      this.presentationFacade
        .getCountryCodes()
        .pipe(takeUntil(this.destroySubjects$))
        .subscribe((countryCodes) => {
          this.settings.countryPhoneCode.options = countryCodes;
        });
  
      this.singleDataHelperService
        .getCurrentVerticalProducts()
        .pipe(takeUntil(this.destroySubjects$))
        .subscribe((products:any) => {
          if (products) {
            const productCategory = this.selectedProduct.category;
            const typeOfCategory = productCategory.split('-'); 
            //transform category to lowerCamelCase
            typeOfCategory[0] = typeOfCategory[0].substring(0, 1).toLowerCase() + typeOfCategory[0].substring(1); 
                    
            switch(typeOfCategory.length){
              case 1:
                this.settings.serviceType.options = products[typeOfCategory[0]].map((product: any) => {
                  return { key: product.ordersManagerProductId, text: product.title, alias: product.alias };
                });
              break;
              case 2:
                //transform category to lowerCamelCase
                typeOfCategory[1] = typeOfCategory[1].substring(0, 1).toLowerCase() + typeOfCategory[1].substring(1); 
                this.settings.serviceType.options = products[typeOfCategory[0]][typeOfCategory[1]].map((product: any) => {
                  return { key: product.ordersManagerProductId, text: product.title, alias: product.alias };
                });
              break;
            }
          }
        });
  
      this.store
        .select(selectProductData)
        .pipe(takeUntil(this.destroySubjects$))
        .subscribe((productData) => {
          if (this.isLoading) {
            if (!!productData) {
              this.formsService.loadFormGroupData(this.fg, productData, this.isLoading);
              this.isSameDeliveryAddress = productData.sameDeliveryAddress;
              this.isLoading = false;
              if(this.selectedProduct){
                this.fg.controls.serviceType.setValue(this.selectedProduct.ordersManagerProductId);
              }
            } else {
              if (this.isB2B) {
                this.formsService.preloadB2BData(this.fg);
              }
            }
          }
        });
  
      // const selectedProduct = this.settings.serviceType.options.find((serviceType: any) => {
      //   return serviceType.alias === this.navigationService.getSelectedProduct();
      // });
      // this.fg.controls.serviceType.setValue(selectedProduct.key);
      // this.navigationService.currentPageTitle = selectedProduct.text;
  
      this.presentationFacade.getHolidays().subscribe((holidays) => {
        let holidaysDates = holidays.map((day: any) => {
          return new Date(day);
        });
  
        this.settings.pickupDate = {
          ...this.settings.pickupDate,
          datesDisabled: holidaysDates,
        };
      });
  
      this.navigationService.scrollToTop();
  
      this.fg.statusChanges.pipe(takeUntil(this.destroySubjects$)).subscribe((status) => {
        this.formsService.handleFormValidity(status, this.fg.value);
      });
  
      this.fg
        .get('sameDeliveryAddress')
        ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
        .subscribe((value: boolean) => {
          this.isSameDeliveryAddress = value;
          if (this.isSameDeliveryAddress) {
            this.formsService.addValidatorsAutosuggest(this.fg, 'dropoffAddress');
            this.fg.get('dropoffAddress')?.updateValueAndValidity();
          } else {
            this.fg.get('dropoffAddress')?.clearValidators();
            this.fg.get('dropoffAddress')?.setValue('');
            this.fg.get('dropoffAddressDetails')?.setValue('');
            this.fg.get('dropoffAddress')?.updateValueAndValidity();
          }
        });

        

    }


  initFlags(launchDarklyService: LaunchDarklyService) {
    this.renderNewFeatureSpeechBubble = launchDarklyService.getFlag(LaunchDarklyFlagNames.SPECIFIC_TIME_NEW_FEATURE);
   }

  getSlotHours(isSpecificHour: boolean) {
    this.presentationFacade
      .getPickupTimeOptions(
        this.fg.get(this.settings.pickupDate.formControlName)?.value,
        isSpecificHour,
      )
      .pipe(first())
      .subscribe((pickupTimeOptions: any) => {

        let timeOptions = JSON.parse(JSON.stringify(pickupTimeOptions));
        this.timeRangeOption = this.fg.get(this.settings.selectTimeOption.formControlName)?.value;
        
        if(isSpecificHour){
          let disabledHours = 0;
          timeOptions.map((entry: any) => {
              let date = new Date(entry.key.substring(0,19));
              let newDate = this.increaseTime(date, 15);
    
              entry.text = entry.text + ' - ' + this.getFormatedTime(newDate);
              if(entry.disabled){
                disabledHours++;
              }
            })
            this.noSpecificHourAvailable = disabledHours === timeOptions.length;
            this.settings.specificTimeRange.disabled = this.noSpecificHourAvailable;
           
            this.settings.specificTimeRange.options = timeOptions;
            this.fg.controls[this.settings.endPickUpTime.formControlName].clearValidators();
        }else{
          this.settings.startPickUpTime.options = timeOptions.slice(0, -3);
          this.settings.endPickUpTime.options = timeOptions.slice(3);
  
          this.settings.specificTimeRange.options = timeOptions;
          this.fg.controls[this.settings.endPickUpTime.formControlName].addValidators(Validators.required);

          this.noRangeHourAvailable = timeOptions.length < 3;
          this.settings.startPickUpTime.disabled = this.noRangeHourAvailable;
        }

        this.store.dispatch({
          type: KamikazeActions.SetServiceTimeOptions,
          serviceTimeOptions: timeOptions,
        });

      });
  }

  /**
   * return the time with a hh:mm format
   * @param date 
   */
  getFormatedTime(date: Date){

      let hours = (date.getHours() < 10 ? '0' : '') + date.getHours();
      let minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  
      return hours + ':' + minutes;
  }
  
  increaseTime(date: Date, minutes: number){
      const SECONDS = 60000;
  
      return new Date(date.getTime() + minutes * SECONDS)
  }

  getB2BAddress(formControlName: string) {
    this.presentationFacade
      .getAddressB2BToAutosuggest()
      .pipe(first())
      .subscribe((addressObj) => {
        this.fg.get(formControlName)?.setValue(addressObj);
      });
  }

  changeEndPickupTimeDisability(status: string){
    if (status === 'VALID') {
      this.settings.endPickUpTime.disabled = false;
      this.dayChangedFlag = false;
    } else {
      this.settings.endPickUpTime.disabled = true;
    }
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }

  updateSpecificHours(valueSelected: any){
    this.store.select(selectServiceTimeOptions).subscribe((serviceTimeOptions) => {
      this.serviceTimeOptions = serviceTimeOptions;
    })

    let elementSelected: any = this.serviceTimeOptions.find((el: any) => el.key === valueSelected);

    if(elementSelected !== undefined && !elementSelected.disabled){
      this.settings.startPickUpTime.options = elementSelected.key;
      this.settings.endPickUpTime.options = null;

      this.fg.controls.startPickUpTime.setValue(elementSelected.key);
      this.fg.controls.endPickUpTime.setValue(null);
    }    
  }

  updateEndRangeHours(valueSelected: any) {
    this.store.select(selectServiceTimeOptions).subscribe((serviceTimeOptions) => {
      this.serviceTimeOptions = serviceTimeOptions;
    })
    // Convert the value in a selectable element
    let elementSelected = this.serviceTimeOptions.find((el: any) => el.key === valueSelected);

    // Update the end time select with new values
    this.settings.endPickUpTime.options = this.serviceTimeOptions.slice(
      this.settings.startPickUpTime.options.indexOf(elementSelected) + 3,
    );

    // Check if the end value is still valid
    let startPickUpTimeControl = this.fg.get('startPickUpTime');
    let endPickUpTimeControl = this.fg.get('endPickUpTime');

    let isEndHourInValidRange = this.settings.endPickUpTime.options.find(
      (el: any) => el.key === endPickUpTimeControl?.value,
    );

    // Update end value
    if (startPickUpTimeControl?.valid && endPickUpTimeControl?.value && !isEndHourInValidRange) {
      this.fg.get('endPickUpTime')?.patchValue(null);
      this.combinationNotValid = true;
    }
  }

  resetServiceTimeStyles(dayChangedMode?: boolean) {
    if(this.startServiceTimeComponent !== undefined){
      this.startServiceTimeComponent.resetValue();
    }
    if(this.endServiceTimeComponent !== undefined){
      this.endServiceTimeComponent.resetValue();
    }
    
    this.combinationNotValid = false;
    if (dayChangedMode && this.serviceTimeOptions.length > 0) {
      this.dayChangedFlag = true;
    } else {
      this.dayChangedFlag = false;
    }
  }

  countPickupErrors() {
    this.timesFailedPickupAddress = this.timesFailedPickupAddress + 1;
  }

  countDropoffErrors() {
    this.timesFailedDropoffAddress = this.timesFailedDropoffAddress + 1;
  }
  resetTimeAndErrors(){
    this.fg.get('startPickUpTime')?.patchValue(null);
    this.fg.get('endPickUpTime')?.patchValue(null);
    this.fg.get('specificTimeRange')?.patchValue(null);
    this.resetServiceTimeStyles();
    this.combinationNotValid = false;
  }
  onValidAddress(value: GoogleAddress, formControlName: string){
    if(formControlName === 'pickupAddress'){
      this.timesFailedPickupAddress = 0;
    }
    if(formControlName === 'dropoffAddress'){
      this.timesFailedDropoffAddress = 0;
    }
    this.validateOperativeZone(value, formControlName)
  }

  validateOperativeZone(value: GoogleAddress, formControlName: string) {
    this.store.dispatch(setIsLoading({ isLoading: true }));
    this.presentationFacade
      .validateOperativeZone(value)
      .pipe(first())
      .subscribe((response: any) => {
        this.fg.get(formControlName)?.setErrors(null);
        this.store.dispatch(setIsLoading({ isLoading: false }));
      },
      (error:any) => {
        this.fg.get(formControlName)?.setErrors({ checkCoordinatesWithinCaflerArea: true });
        this.store.dispatch(setIsLoading({ isLoading: false }));
      });
    }

  getProperVehicleTypes(vehicleProductType: string, formattedVehicleTypes: any[]) {
    if(vehicleProductType==='Sedan'){
      return formattedVehicleTypes.filter((vehicleType: any) => {
        return !vehicleType.text.includes("Motorbike") && !vehicleType.text.includes("Scooter")
      });
    }
    if(vehicleProductType==='Motorbike'){
      return formattedVehicleTypes.filter((vehicleType: any) => {
        return vehicleType.text.includes("Motorbike") || vehicleType.text.includes("Scooter")
      });
    }
    return formattedVehicleTypes;
  }
}


