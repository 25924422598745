<form class="form-container" [formGroup]="fg">
    <div *ngIf="productsSettings.products">
      <h3>{{'cafler.product.valet.category.title' | translate }}</h3>
      <div class="cards-container">
        <caf-product-card
          class="card"
          [class]="'card' + (i + 1)"
          [settings]="product"
          *ngFor="let product of productsSettings.products; let i = index"
          (productSelected)="pickProduct($event)"
          (moreDetails)="showMoreDetails()"
        ></caf-product-card>
      </div>
    </div>
  </form>
  