import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CaflerButtonSettingsModel, CaflerErrorSettingsModel, CaflerIconSettingsModel, CafNotificationSettingsModel } from '@cafler/common-ui';
import { Store } from '@ngrx/store';
import { dniValidator } from 'src/app/validators/dni.validator';
import { nieValidator } from 'src/app/validators/nie.validator';
import { PresentationFacade } from '../../facades/presentation.facade';
import { FormsHelperService } from '../../services/forms.service';
import { selectIsB2B, selectProductData } from '../../store/selectors';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-insurance-topping-configurator',
  templateUrl: './insurance-topping-configurator.component.html',
  styleUrls: ['./insurance-topping-configurator.component.scss']
})
export class InsuranceToppingConfiguratorComponent implements OnInit,  OnDestroy {
  private readonly destroySubjects$ = new ReplaySubject<void>(1); 

  @Input() settings: any = <any>{};
  @Output() onGoBack: EventEmitter<number> = new EventEmitter<any>();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  notificationSettings: CafNotificationSettingsModel = {
    type: 'info',
    text: "cafler.toppings.insurance.info-message.label"
  }
  showError = false;
  isB2B: boolean = false;
  amountOfDays: number = 0;
  fg!: FormGroup;

  errorSettings: CafNotificationSettingsModel = {
    type: 'error',
    text: "cafler.toppings.insurance.error"
  }


  formSettings = {
    name: {
      id: 'name',
      placeholder: 'cafler.topping.insurance.name',
      formControlName: 'name',
      type: 'text',
      disabled: false
    },
    lastName: {
      id: 'lastName',
      placeholder: 'cafler.topping.insurance.lastname',
      formControlName: 'lastName',
      type: 'text',
      disabled: false
    },
    brand: {
      id: 'brand',
      placeholder: 'cafler.vehicle-info.brand.placeholder',
      formControlName: 'brand',
      type: 'text',
      disabled: false
    },
    model: {
      id: 'model',
      placeholder: 'cafler.vehicle-info.model.placeholder',
      formControlName: 'model',
      type: 'text',
      disabled: false
    },
    licensePlate: {
      id: 'licensePlate',
      placeholder: 'cafler.vehicle-info.plate.placeholder',
      formControlName: 'licensePlate',
      type: 'text',
      disabled: false
    },
    personalIDType: {
      id: 'personalIDType',
      placeholder: 'cafler.toppings.insurance.personal-id-type.placeholder',
      formControlName: 'personalIDType',
      options: [
        { key: '1', text: 'cafler.toppings.insurance.personal-id-type-1' },
        { key: '2', text: 'cafler.toppings.insurance.personal-id-type-2' },
      ],
    },
    licenseCountry: {
      id: 'licenseCountry',
      placeholder: 'cafler.toppings.insurance.license-country',
      formControlName: 'licenseCountry',
      options: [] as any[],
    },    
    personalIDNumber: {
      formControlName: 'personalIDNumber',
      type: 'text',
      placeholder: 'cafler.toppings.insurance.personal-id-data.placeholder',
      id: 'personalIDNumber',
      disabled: false,
    }
  }
  settingsErrors: any = {
    errorsPersonalIDType: <CaflerErrorSettingsModel>{
      formControlName: 'personalIDType',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsPersonalIDNumber: <CaflerErrorSettingsModel>{
      formControlName: 'personalIDNumber',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }, { key: 'invalidDNIDocumentNumber', text: 'cafler.forms.error.invalid-dni' }, { key: 'invalidNIEDocumentNumber', text: 'cafler.forms.error.invalid-nie' }],
    },
    errorsLicenseCountry: <CaflerErrorSettingsModel>{
      formControlName: 'licenseCountry',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsName: <CaflerErrorSettingsModel>{
      formControlName: 'name',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsLastName: <CaflerErrorSettingsModel>{
      formControlName: 'lastName',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsBrand: <CaflerErrorSettingsModel>{
      formControlName: 'brand',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsModel: <CaflerErrorSettingsModel>{
      formControlName: 'model',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsLicensePlate: <CaflerErrorSettingsModel>{
      formControlName: 'licensePlate',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
  }
  arrowIcon = <CaflerIconSettingsModel>{
    name: 'caf-chevron-down-light',
    alt: 'Next icon',
  };

  button = {
    buttonSubmit: <CaflerButtonSettingsModel>{
      id: 'submitButton',
      text: 'cafler.toppings.washing.submit-button',
      actionName: 'showSpinner',
      disabled: false,
    },
    buttonBack: <CaflerButtonSettingsModel>{
      id: 'backButton',
      text: 'cafler.toppings.washing.back-button',
      actionName: 'showSpinner',
      disabled: false,
    },
  }

  constructor(
    private formService: FormsHelperService, 
    private presentationFacade: PresentationFacade,
    private store: Store) { }

  ngOnInit(): void {
    this.fg = this.formService.initializeForm(this.formSettings);
    this.formSettings.licenseCountry.options = this.presentationFacade.getCountryLicenseCodes();

    this.store.select(selectIsB2B)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((isB2B: boolean) => {
      this.isB2B = isB2B;
    });
    
    this.store.select(selectProductData)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((data) => {
      this.settings.durationType = 0;

      const nameArray: string[] = data.fullName.split(' ');
      this.fg.get(this.formSettings.name.formControlName)?.patchValue(nameArray[0]);
      this.fg.get(this.formSettings.lastName.formControlName)?.patchValue(nameArray.slice(1).join(' '));
      this.fg.get(this.formSettings.brand.formControlName)?.patchValue(data.brand);
      this.fg.get(this.formSettings.model.formControlName)?.patchValue(data.model);
      this.fg.get(this.formSettings.licensePlate.formControlName)?.patchValue(data.carPlaque);
    })
    
    if(this.settings.elementsAdded.length === 1){
      const elemAdded = this.settings.elementsAdded[0];
      this.settings.durationAmount = elemAdded.insuranceDurationAmount;
      
      this.amountOfDays = elemAdded.insuranceDurationAmount;

      this.settings.documentNumber = elemAdded.documentNumber;
      this.settings.documentType = elemAdded.documentType;
      
      this.fg.value.personalIDNumber = elemAdded.documentNumber;
      this.settings.vehicleRegistrationCountry = elemAdded.vehicleRegistrationCountry;

      this.fillForm(elemAdded.documentNumber, elemAdded.documentType, elemAdded.vehicleRegistrationCountry);
      
    }
  }

  private fillForm(documentNumber: string, documentType: string, vehicleRegistrationCountry: string){
    this.fg.get('personalIDNumber')?.setValue(documentNumber)
    this.fg.get('personalIDType')?.setValue(documentType)
    this.fg.get('licenseCountry')?.setValue(vehicleRegistrationCountry)
  }

  back(){
    this.onGoBack.emit();
  }

  //TODO: The nie validator does not work properly, it does not validate that it is a valid nie, only that it has the correct format.
  //it is needed to extract the validation logic of the dni in a helper function and then use it in both validators.
  changePersonalNumberValidation(personalDocumentType: string){
    this.fg.controls['personalIDNumber'].clearValidators();
    switch(personalDocumentType){
      case '1':
        this.fg.controls['personalIDNumber'].addValidators(dniValidator())
        break;
      case '2':
        this.fg.controls['personalIDNumber'].addValidators(nieValidator())
        break;
    }
  }

  changeDocumentType(event: any){
    this.changePersonalNumberValidation(event)
  }

  save(){
    this.fg.markAllAsTouched();
    if(this.amountOfDays <= 0){
      this.showError = true;
      return
    }

    if(this.fg.valid){
      this.settings = {
        ...this.settings,
        durationAmount: this.amountOfDays,
        documentNumber: this.fg.value.personalIDNumber,
        documentType: this.fg.value.personalIDType,
        vehicleRegistrationCountry: this.fg.value.licenseCountry,
        brand: this.fg.value.brand,
        model: this.fg.value.model,
        licensePlate: this.fg.value.licensePlate, 
        ownerFirstName: this.fg.value.name,
        ownerLastName: this.fg.value.lastName,
      }
      // this.showError = true;
      this.onSubmit.emit(this.settings);
    }


  }

  chooseDays(days: number){
    this.amountOfDays = days;
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }

}
