import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { KamikazeActions } from '../../store/actions';
import { OverviewFacade } from '../../facades/overview.facade';
import {
  CaflerBadgeSettingsModel,
  CaflerButtonSettingsModel,
  CaflerCheckboxSettingsModel,
  CaflerErrorSettingsModel,
  CaflerIconSettingsModel,
  CaflerInputSettingsModel,
  CaflerPopupService,
  CaflerPopupSettingsModel,
} from '@cafler/common-ui';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectBookedOrder, selectCouponCode, selectCurrentCountry, selectCurrentVerticalProducts, selectIsB2B, selectIsCouponValid, selectProductData, selectProductType, selectSelectedProduct, selectToppingsAdded, selectToppingsPicked } from '../../store/selectors';
import { FormGroup } from '@angular/forms';
import { FormsHelperService } from '../../services/forms.service';
import { NavigationService } from '../../services/navigation.service';
import { InfoCard } from 'src/app/models/fe/Overview/InfoCard';
import { Topping } from 'src/app/models';
import { CustomCurrencyPipe } from 'src/app/pipes/currency.pipe';
import { NavigablePopupComponentService } from 'src/app/components/navigable-popup/service/navigable-popup.service';
import { NavigablePopupSettingsModel } from 'src/app/components/navigable-popup/model/navigable-popup-settings.model';
import { toppingsNameEnum } from 'src/app/Enums/toppings.enum';
import { TimeFormatterService } from 'src/app/helpers/time-formatter.service';
import { VehicleDataDisplayModel } from '../../components/vehicle-data-display/models/vehicle-data-settings.model';
import { environment } from 'src/environments/environment.prod';


@Component({
  selector: 'app-overview',
  templateUrl: './overview.page.html',
  styleUrls: ['./overview.page.scss'],
})
export class OverviewPage implements OnInit, OnDestroy {
  private readonly destroySubjects$ = new ReplaySubject<void>(1);

  buttonSettings: any = {
    text: 'cafler.toppings.add-toppings.new-button',
    disabled: false,
  };
  iconExitSettings = { name: 'caf-exit', alt: 'exit', height: '8px', width: '8px' };

  carDataSettings = <VehicleDataDisplayModel>{
    brand: 'Peugeot',
    model: '208',
    personsCapacity: 4,
    luggageCapacity: 2,
    image: `https://${environment.assetsURL}/images/cars/Peugeot208.png`,
    gearbox: 'cafler.product.transfer.replacement.car-data.gearbox-manual',
    fuel: 'cafler.product.transfer.replacement.car-data.fuel-gasoline',
    numberOfDoors: 3,
  }
  
  taxes: any = null;

  bookedOrder: any;
  discountPrice!: any
  fg!: FormGroup;
  orderDetails: any = {};
  productType: string = '';
  isB2B: boolean = false;
  isCouponValid?: boolean;
  couponCode!: string;

  productData: any = {};
  // Feature 
  flagSubscription: any;

  currentCountry: any;
  infoPricing: InfoCard[] = [];
  // totalPricing: InfoCard;
  toppings: Topping[] = [];
  currentVerticalProducts: any = {};
  currentProductType: string = '';
  popUpRef: any;
  toppingsPicked: [] = [];
  selectedProduct!: any;

  papersIcon = <CaflerIconSettingsModel>{
    name: 'caf-papers',
    alt: 'cafler.page.overview.papers.alt.icon',
  };
  papersPenIcon = <CaflerIconSettingsModel>{
    name: 'caf-paper-pen',
    alt: 'cafler.page.overview.paperpen.alt.icon',
  };
  gasIcon = <CaflerIconSettingsModel>{
    name: 'caf-gas',
    alt: 'cafler.page.overview.gas.alt.icon',
  };
  infoIcon = <CaflerIconSettingsModel>{
    name: 'caf-info-tooltip-outline',
    alt: 'cafler.page.overview.info.alt.icon',
  }
  badge = <CaflerBadgeSettingsModel>{
    text: 'cafler.topping.extra.badge-button',
  }
  paperCheck = <CaflerIconSettingsModel>{
    name: 'caf-paper-check',
    alt: 'cafler.page.overview.papercheck.alt.icon',
    width: '22px',
    height: '22px',
  }
  envelope = <CaflerIconSettingsModel>{
    name: 'caf-envelope',
    alt: 'cafler.page.overview.envelope.alt.icon',
    width: '22px',
    height: '22px',
  }
  person = <CaflerIconSettingsModel>{
    name: 'caf-person-2',
    alt: 'cafler.page.overview.person.alt.icon',
    width: '22px',
    height: '22px',
  }
  car = <CaflerIconSettingsModel>{
    name: 'caf-simple-car',
    alt: 'cafler.page.overview.papercheck.alt.icon',
    width: '22px',
    height: '22px',
  }

  settings = {
    termsAndConditions: <CaflerCheckboxSettingsModel>{
      id: 'termsAndConditions',
      formControlName: 'termsAndConditions',
      name: 'termsAndConditions',
      label: 'cafler.page.overview.terms-and-conditions.checkbox',
      labelAlign: 'right',
    },

    minimumRequirements: <CaflerCheckboxSettingsModel>{
      id: 'minimumRequirements',
      formControlName: 'minimumRequirements',
      name: 'minimumRequirements',
      label: 'cafler.page.overview.minimum-requirements.label',
      labelAlign: 'right',
    },
    coupon: <CaflerInputSettingsModel>{
      id: 'coupon',
      formControlName: 'coupon',
      placeholder: 'cafler.page.overview.coupon-placeholder',
      disabled: false,
      type: 'text',
      required: false,
    },
    couponButtonSubmit: <CaflerButtonSettingsModel>{
      id: 'couponButton',
      text: 'cafler.page.overview.coupon-button',
      disabled: true,
      required: false,
    },
  };
  settingsErrors = {
    errorsMinimumRequirements: <CaflerErrorSettingsModel>{
      formControlName: 'minimumRequirements',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsTermsAndConditions: <CaflerErrorSettingsModel>{
      formControlName: 'termsAndConditions',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
  };

  constructor(
    private store: Store,
    private overviewFacade: OverviewFacade,
    private timeFormatterService: TimeFormatterService,
    private formsService: FormsHelperService,
    private navigationService: NavigationService,
    private customCurrencyPipe: CustomCurrencyPipe,
    private popupService: CaflerPopupService,
    private navigablePopupService: NavigablePopupComponentService,
    
  ) {

    this.fg = this.formsService.initializeForm(this.settings);
    this.store.dispatch({ type: KamikazeActions.SetFormValidity, isFormValid: false });
    this.orderDetails = this.overviewFacade.getOverviewByProductType()

    this.store.select(selectIsB2B)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((isB2B: boolean) => {
      this.isB2B = isB2B;
    });

    this.store.select(selectProductData)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((productData) => {
      this.productData = productData;
    });

    this.store.select(selectCurrentVerticalProducts)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((currentVerticalProducts) => {
      this.currentVerticalProducts = currentVerticalProducts;
    });

    this.store.select(selectProductType)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((currentProduct) => {
      this.currentProductType = currentProduct;
    });

    this.store.select(selectSelectedProduct)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((selectedProduct) => {
      this.selectedProduct = selectedProduct;
    })
    
   this.store.select(selectBookedOrder)
   .pipe(takeUntil(this.destroySubjects$))
   .subscribe((bookedOrder) => {
      this.bookedOrder = bookedOrder;

      if(this.orderDetails.infoTaxes){
        this.taxes = {};
        this.taxes.label = this.orderDetails.infoTaxes.label;
        this.taxes.zoneTax = this.bookedOrder.ZoneTax;
        this.taxes.value = this.customCurrencyPipe.convert(
          this.bookedOrder.OrderPrice.TaxFreePrice *  this.bookedOrder.ZoneTax,
          overviewFacade.bookedOrder.OrderPrice.CurrencySymbol, 
        ) 
      }

      if(this.orderDetails.infoTotalPrice){
        this.orderDetails.infoTotalPrice.
          value = this.customCurrencyPipe.convert(
            this.bookedOrder.OrderPrice.TotalPrice,
            overviewFacade.bookedOrder.OrderPrice.CurrencySymbol,
          )
  
        this.discountPrice = this.customCurrencyPipe.convert(
          this.bookedOrder?.DiscountPrice?.TotalPrice,
          overviewFacade.bookedOrder.OrderPrice.CurrencySymbol,
        )
      }
    });

    let pickupTime = ""

    this.orderDetails?.infoPickup?.map((entry: any) => {
      if(entry.label === "cafler.pickup-info.pickup-time.placeholder"){
        pickupTime =  entry.value;
      }
    });

    let startTime = pickupTime.split(' - ')[0];
    let endTime = pickupTime.split(' - ')[1];

    if(endTime === 'null'){
      this.orderDetails.infoPickup?.map((entry: any) => {
        if(entry.label === "cafler.pickup-info.pickup-time.placeholder"){
          entry.value = startTime;
        }
      });
    }

    this.store
      .select(selectProductType)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((productType) => {
        this.productType = productType;
        if(productType === 'tow-truck' || productType === 'all-you-need'){
          this.fg.get('minimumRequirements')?.clearValidators();
        }
      });

    this.store
      .select(selectIsCouponValid)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((isValid) => {
        this.isCouponValid = isValid;
      });

    this.store
      .select(selectCouponCode)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((couponCode) => {
        this.fg.get('coupon')?.patchValue(couponCode);
        this.settings.couponButtonSubmit.disabled = !couponCode;
        this.couponCode = couponCode;
      });

    this.store
      .select(selectCurrentCountry)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((country) => {
        this.currentCountry = country;
      });

      this.store
      .select(selectToppingsPicked)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((toppingsPicked: any) => {
        this.toppingsPicked = toppingsPicked;
      });

    this.store
      .select(selectToppingsAdded)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((toppings) => {  
        const toppingsObj:Topping[] = [];

        toppings.forEach((t) => {
          if(t.ProductId !== null){
            let serviceTime = undefined;
            
            if(t.ProductDuration){
              serviceTime = this.timeFormatterService.extractDisplayStringFromDate(t.ProductDuration)
            }
            
            const title = t.LocalizationKey === 'refueling-topping' 
              ? `cafler.overview.${t.LocalizationKey}.${t.ProductCategory}.title`
              : `cafler.overview.${t.LocalizationKey}.title`

            const detail = t.LocalizationKey === 'refueling-topping' 
              ? `cafler.overview.${t.LocalizationKey}.${t.ProductCategory}.detail`
              : `cafler.overview.${t.LocalizationKey}.detail`


            let productPrice = t.ProductPrice
            let productConfiguredPriceAmount = t.ProductConfiguredPriceAmount

            if(this.isB2B){
              productPrice = t.FreeTaxProductPrice
              if(productConfiguredPriceAmount){
                productConfiguredPriceAmount = t.ProductConfiguredPriceAmount / (1 + t.ZoneTax)
              }
            }

            const obj: Topping = {
              id: t.ProductId,
              title: title,
              productPrice: this.customCurrencyPipe.convert(
                productPrice,
                overviewFacade.bookedOrder.OrderPrice.CurrencySymbol,
              ),
              detail: detail,
              detailPrice: this.customCurrencyPipe.convert(
                productConfiguredPriceAmount,
                overviewFacade.bookedOrder.OrderPrice.CurrencySymbol,
              ), 
              
              serviceTime: serviceTime
            }

            toppingsObj.push(obj)
         }
        });
        this.toppings = toppingsObj;
      });

      this.fg
        .get('coupon')
        ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
        .subscribe((formValue) => {
          this.settings.couponButtonSubmit.disabled = !formValue;
      });
  }


  removeTopping(toppingId: any) {
    this.store.dispatch({ type: KamikazeActions.SetToppingToDelete, toppingToDelete: toppingId });
    this.store.dispatch({ type: KamikazeActions.RemoveToppings });
  }

  openToppingPopUp(){
    const popupRevisionConfirmSettings = <NavigablePopupSettingsModel>{
      id: 'confirmedPopup',
      icon: <CaflerIconSettingsModel>{
        id: 'power-icon',
        name: 'caf-check-green-circle',
        alt: 'power',
      },
      availableToppings: this.overviewFacade.bookedOrder?.AvailableToppings,
      selectedToppings: this.toppingsPicked,
    };

    this.popUpRef = this.navigablePopupService.open(
      ''  ,
      popupRevisionConfirmSettings,
    );
    this.popUpRef.afterClosed$.subscribe((res:any) => {
      this.navigablePopupService.enableScroll();
    });
  }
  
  ngOnInit() {
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: true });
    this.store.dispatch({ type: KamikazeActions.SetNextPage, nextPage: 'checkout' });
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'overview' });

    this.navigationService.scrollToTop();
    this.navigationService.currentPageTitle = 'cafler.overview.title';

    this.fg.statusChanges.pipe(takeUntil(this.destroySubjects$)).subscribe((status) => {
      this.formsService.handleFormValidity(status, this.fg.value, false);
    });
    
  }

  openCancelationPolicy(content?: any) {
    const popupSettings = <CaflerPopupSettingsModel>{
      id: 'testPopup',
      description: 'cafler.cancel.service.popup.description',
    };
    const ref = this.popupService.open(content, popupSettings);
    return ref;
  }

  sendCoupon() {
    let couponCode = this.fg.get('coupon')?.value;
    this.store.dispatch({ type: KamikazeActions.SetCoupon, couponCode: couponCode });
    this.fg.get('coupon')?.setValue('');
  }

  removeCoupon() {
    this.store.dispatch({ type: KamikazeActions.RemoveCoupon });
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
    if(this.popUpRef){
      this.popUpRef.close();
    }
  }




}