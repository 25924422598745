<caf-tabs (onOpenSummary)="redirectToCaspio()" (onSendTab)="onTabSelected($event)">
    <caf-tab [settings]="settings.todaysServices">
        <caf-details-my-services 
          (onMoreDetails)="moreDetails($event)" 
          (duplicateService)="duplicateService($event)"
          [settings]="settings.servicesData">
        </caf-details-my-services>
      </caf-tab>
      <caf-tab [settings]="settings.futureServices">
        <caf-details-my-services 
          (onMoreDetails)="moreDetails($event)" 
          (duplicateService)="duplicateService($event)"
          [settings]="settings.servicesData">
        </caf-details-my-services>
      </caf-tab>
      <caf-tab [settings]="settings.pastServices">
        <caf-details-my-services 
          (onMoreDetails)="moreDetails($event)" 
          (duplicateService)="duplicateService($event)"
          [settings]="settings.servicesData">
        </caf-details-my-services>
      </caf-tab>
</caf-tabs>