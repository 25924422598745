import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SingleDataHelperService } from 'src/app/helpers/single-data-helper.service';
import { selectCurrentZone, selectIsVerticalProductsLoaded } from 'src/app/store/selectors';
import { PresentationFacade } from '../../facades/presentation.facade';
import { NavigationService } from '../../services/navigation.service';
import { KamikazeActions } from '../../store/actions';
import { CaflerIconSettingsModel } from '@cafler/common-ui';
import { nulledCities } from 'src/app/constants';

@Component({
  selector: 'app-itv-selector',
  templateUrl: './itv-selector.page.html',
  styleUrls: ['./itv-selector.page.scss'],
})
export class ItvSelectorPage implements OnInit {
  fg!: FormGroup;
  holidays: any[] = [];
  ordersManagerProductId: string = '';
  category: any[] = [];
  productsSettings: any = {
    id: 'mot-type',
    formControlName: 'productSelector',
    isTopping: false,
    itvServices: [
      {
        alias: 'appointment',
        category: '',
        alt: 'mot-appointment',
        description: 'Cafler.product-type.mot-appointment.description',
        id: 'mot-appointment',
        holidays: [],
        ordersManagerProductId: '',
        title: 'Cafler.product-type.mot-appointment.tittle',
        icon: <CaflerIconSettingsModel>{
          name: 'caf-calendar-green-tick-3D',
          width: '55px',
        },
      },
      {
        alias: 'no-appointment',
        alt: 'mot-no-appointment',
        category: '',
        description: 'Cafler.product-type.mot-no-appointment.description',
        id: 'mot-no-appointment',
        holidays: [],
        ordersManagerProductId: '',
        title: 'Cafler.product-type.mot-no-appointment.tittle',
        icon: <CaflerIconSettingsModel>{
          name: 'caf-calendar-with-cross-3D',
          width: '55px',
        },
      },
      {
        alias: 'homologation',
        alt: 'mot-with-homologation',
        category: '',
        description: 'Cafler.product-type.mot-with-homologation.description',
        id: 'mot-with-homologation',
        holidays: [],
        ordersManagerProductId: '',
        title: 'Cafler.product-type.mot-with-homologation.tittle',
        icon: <CaflerIconSettingsModel>{
          name: 'caf-homologation-3D',
          width: '55px',
        },
      },
    ],
  };
  private nulledCities = nulledCities;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private store: Store,
    private singleDataHelperService: SingleDataHelperService,
    private activatedRoute: ActivatedRoute,
  ) {
    const formControlName = this.productsSettings.formControlName;
    this.fg = new FormGroup({});
    this.fg.addControl(formControlName, new FormControl(null, [Validators.required]));
    // this.store.dispatch({ type: KamikazeActions.FetchAvailableVerticalProducts });
  }

  ngOnInit() {
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'mot-selector' });
    this.store.dispatch({ type: KamikazeActions.SetNextPage, nextPage: 'mot-type' });
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: false });
    this.navigationService.currentPageTitle = 'cafler.product.mot.title';

    this.store.select(selectIsVerticalProductsLoaded).subscribe((productsLoaded) => {
      if (productsLoaded)
        this.singleDataHelperService.getCurrentVerticalProducts().subscribe((products: any) => {
          this.holidays = products?.technicalInspection[0].holidays;
          this.ordersManagerProductId = products?.technicalInspection[0].ordersManagerProductId;
          this.category = products?.technicalInspection[0].category;
        });
    });
    this.hasToNulledCities();
  }

  pickProduct(page: any) {
    const currentProduct = this.productsSettings.itvServices.find((product: any) => {
      return product.id === page;
    });

    currentProduct.holidays = this.holidays;
    currentProduct.ordersManagerProductId = this.ordersManagerProductId;
    currentProduct.category = this.category;

    this.store.dispatch({
      type: KamikazeActions.SetSelectedProduct,
      selectedProduct: currentProduct,
    });

    this.router
      .navigate([`${currentProduct?.alias}`], { relativeTo: this.activatedRoute })
      .then((s) => {
        this.navigationService.currentPageTitle = 'cafler.product-type.mot.title';
      });
  }

  private hasToNulledCities(): void {
    this.store.select(selectCurrentZone).subscribe((productsLoaded) => {
      if (this.nulledCities.includes(productsLoaded.Name)) {
        this.productsSettings.itvServices = this.productsSettings.itvServices.filter(
          (item: any) => item.id != 'mot-with-homologation',
        );
      }
    });
  }
}
