
const DNI_LETTER_EXPECTED = 'TRWAGMYFPDXBNJZSQVHLCKE'

export function isDniValid(dni: string): boolean {

    const regexDNI = /[0-9]{8}[a-zA-Z]/gm;

    if(!regexDNI.test(dni)) {
      return false;
    }
    
    const numberSecuence = dni.substring(0, 8);
    const letter = dni.substring(8);
    const letterExpected = DNI_LETTER_EXPECTED[parseInt(numberSecuence) % 23];

    if(letterExpected !== letter){
      return false;
    }

    return true;
}

export function isNieValid(nie: string): boolean{
    
    const regexNIE = /[XYZ][0-9]{7}[A-Z]/gm;

    if(!regexNIE.test(nie)) {
        return false;
    }

    const niePrefix = nie.charAt(0);
    let niePrefixNumber = 0;

    switch (niePrefix) {
        case 'X': niePrefixNumber = 0; break;
        case 'Y': niePrefixNumber = 1; break;
        case 'Z': niePrefixNumber = 2; break;
    }

    return isDniValid(niePrefixNumber + nie.substring(1));

}