import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  CaflerErrorSettingsModel,
  CaflerIconSettingsModel,
  GoogleMapsService,
} from '@cafler/common-ui';
import { FormsHelperService } from '../../../services/forms.service';
import { KamikazeActions } from '../../../store/actions';
import { Store } from '@ngrx/store';
import {
  selectAvailableToppingHours,
  selectBookedOrder,
  selectCurrentTopping,
  selectCurrentZone,
  selectIsB2B,
  selectProductData,
} from '../../../store/selectors';
import { ReplaySubject } from 'rxjs';
import { NavigationService } from '../../../services/navigation.service';
import { PresentationFacade } from '../../../facades/presentation.facade';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-return-topping-form',
  templateUrl: './return-topping-form.page.html',
  styleUrls: ['./return-topping-form.page.scss'],
})
export class ReturnToppingFormPage implements OnInit, OnDestroy {
  private readonly destroySubjects$ = new ReplaySubject<void>(1);
  fg!: FormGroup;
  isLoading: boolean = true;
  isB2B: boolean = false;
  toppingId!: string;
  orderHash!: string;
  settings: any = {
    destinationAddress: {
      id: 'destinationAddress',
      formControlName: 'destinationAddress',
      placeholder: 'cafler.page.media-larga-distancia.directions.destination.placeholder',
      options: { businessSuggestions: true, placeSuggestions: false },
    },
    destinationAddressDetails: {
      id: 'destinationAddressDetails',
      formControlName: 'destinationAddressDetails',
      placeholder: 'cafler.pickup-info.pickup-details.placeholder',
      disabled: false,
      required: false,
      type: 'text',
    },
    destinationFullName: {
      id: 'destinationFullName',
      formControlName: 'destinationFullName',
      placeholder: 'cafler.contact.name.placeholder',
      disabled: false,
      type: 'text',
    },
    destinationCountryPhoneCode: {
      id: 'destinationCountryPhoneCode',
      formControlName: 'destinationCountryPhoneCode',
      placeholder: 'cafler.contact.country-phone-code.placeholder',
      disabled: false,
      options: [],
    },
    destinationPhone: {
      id: 'destinationPhone',
      formControlName: 'destinationPhone',
      placeholder: 'cafler.contact.phone.placeholder',
      disabled: false,
      type: 'phone',
    },
    destinationEmail: {
      id: 'destinationEmail',
      formControlName: 'destinationEmail',
      placeholder: 'cafler.contact.email.placeholder',
      disabled: false,
      type: 'email',
    },
    destinationDate: {
      id: 'destinationDate',
      formControlName: 'destinationDate',
      placeholder: 'cafler.page.media-larga-distancia.destination.pickup-date.placeholder',
      minDate: new Date(),
      disabled: true,
    },
    destinationTime: {
      id: 'destinationTime',
      formControlName: 'destinationTime',
      placeholder: 'cafler.page.media-larga-distancia.origin.pickup-time.placeholder',
      disabled: false,
      options: [],
    },
  };
  settingsExtra: any = {
    locationIcon: <CaflerIconSettingsModel>{
      alt: 'location',
      name: 'caf-pin-location',
    },
  };
  settingsErrors: any = {
    errorsDestinationFullName: <CaflerErrorSettingsModel>{
      formControlName: 'destinationFullName',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsDestinationCountryPhoneCode: <CaflerErrorSettingsModel>{
      formControlName: 'destinationCountryPhoneCode',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsDestinationPhone: <CaflerErrorSettingsModel>{
      formControlName: 'destinationPhone',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'pattern', text: 'cafler.forms.error.phone-pattern' },
        { key: 'minlength', text: 'cafler.forms.error.min-length' },
      ],
    },
    errorsDestinationEmail: <CaflerErrorSettingsModel>{
      formControlName: 'destinationEmail',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'pattern', text: 'cafler.forms.error.phone-pattern' },
        { key: 'minlength', text: 'cafler.forms.error.min-length' },
      ],
    },
    errorsDestinationDate: <CaflerErrorSettingsModel>{
      formControlName: 'destinationDate',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'notPresent', text: 'cafler.forms.error.date-not-in-past' },
      ],
    },
    errorsDestinationAddress: <CaflerErrorSettingsModel>{
      formControlName: 'destinationAddress',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'selectedAddress', text: 'cafler.forms.error.selected-address' },
        { key: 'addressNumber', text: 'cafler.forms.error.address-number' },
        {
          key: 'checkCoordinatesWithinCaflerArea',
          text: 'cafler.forms.error.cafler-country-coverage',
        },
      ],
    },
    errorsDestinationTime: <CaflerErrorSettingsModel>{
      formControlName: 'destinationTime',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
  };

  constructor(
    private store: Store,
    private navigationService: NavigationService,
    private presentationFacade: PresentationFacade,
    private formsService: FormsHelperService,
    private googleMapsService: GoogleMapsService,
  ) {
    this.fg = this.formsService.initializeForm(this.settings);
    this.store.select(selectCurrentZone).subscribe((zone) => {
      if (zone) {
        // this.settings.destinationAddress.options.countryCode = zone?.RegionCode;
        const bbox = googleMapsService.getBoundingBox(
          zone?.UpperRightBoundingBoxCenterLatitude,
          zone?.UpperRightBoundingBoxCenterLongitude,
          zone?.LowerLeftBoundingBoxCenterLatitude,
          zone?.LowerLeftBoundingBoxCenterLongitude,
        );

        this.settings.pickupAddress.options.bounds = bbox;
        this.settings.deliveryAddress.options.bounds = bbox;

        // setting default prefix code number
        this.fg
          .get(this.settings.destinationCountryPhoneCode.formControlName)
          ?.setValue(zone.DefaultPhonePrefix);
      }
      this.fg.get(this.settings.destinationAddress.formControlName)?.clearValidators();
      this.fg.get(this.settings.destinationAddress.formControlName)?.updateValueAndValidity();
    });
  }

  ngOnInit() {
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: true });
    this.store.dispatch({ type: KamikazeActions.SetNextPage, nextPage: 'overview' });
    this.store.dispatch({
      type: KamikazeActions.SetCurrentPage,
      currentPage: 'return-topping-form',
    });
    this.store.dispatch({ type: KamikazeActions.GetAvailableHoursReturn });

    this.navigationService.scrollToTop();
    this.navigationService.currentPageTitle = 'cafler.product-type.return.title';

    this.store.dispatch({
      type: KamikazeActions.SetFormValidity,
      isFormValid: this.formsService.checkCurrentFormValidity(),
    });

    this.store
      .select(selectIsB2B)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((isB2B) => {
        this.isB2B = isB2B;
      });

    this.store
      .select(selectBookedOrder)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((bookedOrder) => {
        this.orderHash = bookedOrder.OrderHash;
      });

    this.store
      .select(selectCurrentTopping)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((currentTopping) => {
        this.toppingId = currentTopping.id;
      });

    this.store
      .select(selectAvailableToppingHours)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((availableHours) => {
        this.settings.destinationTime.options = this.presentationFacade.formatHours(availableHours);
      });

    // Get phone prefixes
    this.presentationFacade
      .getCountryCodes()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((countryCodes) => {
        this.settings.destinationCountryPhoneCode.options = countryCodes;
      });

    // Get B2B data and fill form
    this.store
      .select(selectProductData)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((productData) => {
        if (this.isLoading) {
          if (!!productData) {
            let formattedDate = new Date(productData.pickupDate);
            let toppingDate = formattedDate.toLocaleDateString(undefined, {
              // Hack from https://stackoverflow.com/a/47160545
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            });
            const returnData = {
              destinationAddress: productData.pickupAddress,
              destinationAddressDetails: productData.pickupAddressDetails,
              destinationFullName: productData.fullName,
              destinationCountryPhoneCode: productData.countryPhoneCode,
              destinationPhone: productData.phone,
              destinationEmail: productData.email,
              destinationDate: toppingDate,
            };
            this.formsService.loadFormGroupData(this.fg, returnData, this.isLoading);
            this.isLoading = false;
          } else {
            if (this.isB2B) {
              this.formsService.preloadB2BData(this.fg);
            }
          }
        }
      });

    // Remove holidays from datepicker
    this.presentationFacade.getHolidays().subscribe((holidays) => {
      let holidaysDates = holidays.map((day: any) => {
        return new Date(day);
      });

      this.settings.pickupDate = {
        ...this.settings.pickupDate,
        datesDisabled: holidaysDates,
      };
    });

    this.fg.statusChanges.pipe(takeUntil(this.destroySubjects$)).subscribe((status) => {
      this.formsService.handleFormToppingsValidity(status, this.fg.value);
    });
  }
  getB2BAddress(formControlName: string) {
    this.presentationFacade
      .getAddressB2BToAutosuggest()
      .pipe(first())
      .subscribe((addressObj) => {
        this.fg.get(formControlName)?.setValue(addressObj);
      });
  }
  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }
}
