import { AbstractControl, ValidatorFn } from '@angular/forms';

export function googleMapsInputSuggestion(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value) {
      if (
        control.value.googleObject == null ||
        Object.keys(control.value.googleObject).length == 0
      ) {
        // There is not address selected, then error
        return { selectedAddress: true };
      } else if (control.value.googleObject.isValidAddress) {
        // in the 2nd version, if is street, establishment or premise is valid
        return null; // TODO -> Refactor this validator to keep existing errors like 'checkCoordinatesWithinCaflerArea'

      } else if (control.value.googleObject?.isStreetNumberMissing) {
        // if is route, indicate user it needs write the street number
        return { streetNumberNeeded: true };
      }
    }
    return { selectedAddress: true };
  };
}
