<form class="form-container form-margin-bottom" [formGroup]="fg">
  <h3>{{'cafler.product.wash.types.title' | translate }}</h3>
  <section class="wash-container" *ngIf="productsSettings.products['handWash']?.length > 0">
    <div class="wash-title-container">
      <caf-icon [settings]="handWashIcon"></caf-icon>
      <h4>{{'cafler.product.wash.handWash.title' | translate }}</h4>
    </div>
    <div class="cards-container">
      <caf-product-card
        class="card"
        [class]="'card' + (i + 1)"
        [settings]="product"
        *ngFor="let product of productsSettings.products['handWash']; let i = index"
        (productSelected)="pickProduct($event, 'handWash')"
        (moreDetails)="showMoreDetails(product.alias)"
      ></caf-product-card>
    </div>
  </section>
  <div *ngIf="productsSettings.products['washTunnel']?.length > 0">
    <div class="wash-title-container">
      <caf-icon [settings]="tunnelWashIcon"></caf-icon>
      <h4>{{'cafler.product.wash.washTunnel.title' | translate }}</h4>
    </div>
    <div class="cards-container">
      <caf-product-card
        class="card"
        [class]="'card' + (i + 1)"
        [settings]="product"
        *ngFor="let product of productsSettings.products['washTunnel']; let i = index"
        (productSelected)="pickProduct($event, 'washTunnel')"
        (moreDetails)="showMoreDetails(product.alias)"
      ></caf-product-card>
    </div>
  </div>
  <div *ngIf="productsSettings.products['details']?.length > 0">
    <div class="wash-title-container">
      <caf-icon [settings]="detailWashIcon"></caf-icon>
      <h4>{{'cafler.product.wash.details.title' | translate }}</h4>
    </div>
    <div class="cards-container">
      <caf-product-card
        class="card"
        [class]="'card' + (i + 1)"
        [settings]="product"
        *ngFor="let product of productsSettings.products['details']; let i = index"
        (productSelected)="pickProduct($event, 'details')"
        (moreDetails)="showMoreDetails(product.alias)"
      ></caf-product-card>
    </div>
  </div>
</form>
