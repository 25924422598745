
  <div *ngIf="!settings.servicesAvaiable" class="no-service-container">
    <img class="error-no-service-icon" [id]="settings.id" [src]="settings.icon" />
   
    <div class="caf-popup-overlay__body">
      <span *ngIf='settings.servicesTittle' class="title-popup">
        {{settings.servicesTittle | translate }}
      </span>
      <span class="subtitle-1 no-service-description">
        {{settings.noServiceDescription | translate }}
      </span>
    </div>
    <div class="caf-popup-overlay__footer">
      <caf-button
        (onClick)="cancel()"
        class="caf-btn-primary caf-btn-desk-small caf-btn-mob-small"
        [settings]="backButton"
    ></caf-button>
    </div>
  </div>