

export function getStationValetDetails() {
    
    return {
        id: "valet-how-it-works",
        title: "cafler.product-type.valet.popup.title",
        description: "cafler.product-type.valet.popup.description",
        howItWorks: [
            {
                icon: { name: 'caf-simple-calendar', alt: 'calendario'},
                sentence:"cafler.product-type.valet.popup.how-it-works.step1"
            },
            {
                icon: { name: 'caf-phone-outline', alt: 'llamada'},
                sentence:"cafler.product-type.valet.popup.how-it-works.step2"
            },
            {
                icon: { name: 'caf-person-icon', alt: 'contacto'},
                sentence:"cafler.product-type.valet.popup.how-it-works.step3"
            },
            {
                icon: { name: 'caf-wash-black', alt: 'servicio'},
                sentence:"cafler.product-type.valet.popup.how-it-works.step4"
            },
            {
                icon: { name: 'caf-transfer-black', alt: 'icono de movimiento'},
                sentence:"cafler.product-type.valet.popup.how-it-works.step5"
            },
        ],
        beforeServiceStarts: 'cafler.product-type.valet.popup.before-service-starts.description',
    };
}   
