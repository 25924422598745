<form class="caf-md-container margin-top-forms" [formGroup]="fg">
  <!-- delivery type -->
  <section class="input-item">
    <span class="subtitle-1"
      >{{'cafler.page.media-larga-distancia.delivery-type.title' | translate}}</span
    >
    <caf-select [settings]="settings.deliveryType"></caf-select>
    <caf-error
      class="extra-margin-error"
      [settings]="settingsErrors.errorsDeliveryType"
    ></caf-error>
  </section>
  <!-- custom delivery-->
  <section class="input-item">
    <caf-checkbox [settings]="settings.customDelivery" ></caf-checkbox>
  </section>

  <!-- pickup information-->
  <section>
      <div class="input-item">
        <span class="subtitle-1">{{'cafler.transfer-info.pickup-info.title' | translate}}</span>
        <div *ngIf="isB2B" class="caf-grid-row split-2-cols split-sm-2-cols">
          <caf-input-google-autosuggest
            class="col-start-sm-1 col-start-1 col-end-sm-10 col-end-10"
            (onValidAddress)="timesFailedPickupAddress=0"
            (onInvalidAddress)="countPickupErrors()"
            [settings]="settings.pickupAddress"
          >
          </caf-input-google-autosuggest>
          <button
            class="h-align-center v-align-center complete-address-button col-start-sm-11 col-start-11 col-end-sm-12 col-end-12"
            (click)="getB2BAddress(settings.pickupAddress.formControlName)"
          >
            <caf-icon [settings]="settingsExtra.locationIcon"></caf-icon>
          </button>
        </div>
        <caf-input-google-autosuggest
          *ngIf="!isB2B"
          (onValidAddress)="timesFailedPickupAddress=0"
          (onInvalidAddress)="countPickupErrors()"
          [settings]="settings.pickupAddress"
        >
        </caf-input-google-autosuggest>
        <caf-error
          *ngIf="timesFailedPickupAddress<=maxErrorsOnAddress"
          class="extra-margin-error"
          [settings]="settingsErrors.errorsPickupAddress"
        ></caf-error>
        <p *ngIf="timesFailedPickupAddress>maxErrorsOnAddress" class="error-message error-msg">
          {{'cafler.address-message-error-too-many-attempts-pickup.title' | translate }}
        </p>
      </div>
      <div class="input-item">
        <caf-input [settings]="settings.pickupAddressDetails"></caf-input>
      </div>
  </section>
  
  <!-- pick up contact data-->    
  <section>
      <div class="input-item">
        <span class="subtitle-1"
          >{{'cafler.page.media-larga-distancia.origin-contact.title' | translate}}</span
        >
        <caf-input [settings]="settings.fullName"></caf-input>
        <caf-error [settings]="settingsErrors.errorsFullName"></caf-error>
      </div>
      <div class="caf-grid-row split-2-cols split-sm-2-cols">
        <caf-select
          class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
          [settings]="settings.countryPhoneCode"
        ></caf-select>
        <caf-input
          class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
          [settings]="settings.phone"
        ></caf-input>
      </div>
      <div class="caf-grid-row split-2-cols split-sm-2-cols">
        <caf-error
          class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
          [settings]="settingsErrors.errorsCountryPhoneCode"
        ></caf-error>
        <caf-error
          class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
          [settings]="settingsErrors.errorsPhone"
        ></caf-error>
      </div>
      <div *ngIf="!isB2B" class="input-item">
        <caf-input [settings]="settings.email"></caf-input>
        <caf-error [settings]="settingsErrors.errorsEmail"></caf-error>
      </div>
  </section>

  <!-- dropoff information-->
  <section>
      <div class="input-item">
        <span class="subtitle-1">{{'cafler.page.wash.dropoff.info.title' | translate}}</span>
        <div *ngIf="isB2B" class="caf-grid-row split-2-cols split-sm-2-cols">
          <caf-input-google-autosuggest
            class="col-start-sm-1 col-start-1 col-end-sm-10 col-end-10"
            (onValidAddress)="timesFailedDeliveryAddress=0"
            (onInvalidAddress)="countDropoffErrors()"
            [settings]="settings.deliveryAddress"
          >
          </caf-input-google-autosuggest>
          <button
            class="h-align-center v-align-center complete-address-button col-start-sm-11 col-start-11 col-end-sm-12 col-end-12"
            (click)="getB2BAddress(settings.deliveryAddress.formControlName)"
          >
            <caf-icon [settings]="settingsExtra.locationIcon"></caf-icon>
          </button>
        </div>
        <caf-input-google-autosuggest
          *ngIf="!isB2B"
          (onValidAddress)="timesFailedDeliveryAddress=0"
          (onInvalidAddress)="countDropoffErrors()"
          [settings]="settings.deliveryAddress"
        >
        </caf-input-google-autosuggest>
        <caf-error
          *ngIf="timesFailedDeliveryAddress<=maxErrorsOnAddress"
          class="extra-margin-error"
          [settings]="settingsErrors.errorsDeliveryAddress"
        ></caf-error>
        <p *ngIf="timesFailedDeliveryAddress>maxErrorsOnAddress" class="error-message error-msg">
          {{'cafler.address-message-error-too-many-attempts-dropoff.title' | translate }}
        </p>
        <p *ngIf="fg.errors?.sameAddress" class="error-message error-msg">
          {{'cafler.forms.error.same-address' | translate }}
        </p>
      </div>
      <div class="input-item">
        <caf-input [settings]="settings.deliveryAddressDetails"></caf-input>
      </div>
  </section>

  <!-- dropoff contact data-->
  <section>
    <div class="input-item" >
      <span class="subtitle-1">
        {{'cafler.page.media-larga-distancia.destination-contact.title' | translate}}</span>
      <caf-input [settings]="settings.dropoffFullName"></caf-input>
      <caf-error [settings]="settingsErrors.errorsDropOffFullName"></caf-error>
    </div>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-select
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settings.dropoffCountryPhoneCode"
      ></caf-select>
      <caf-input
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settings.dropoffPhone"
      ></caf-input>
      <caf-error
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settingsErrors.errorsDropOffPhone"
      ></caf-error>
    </div>
    <caf-error [settings]="settingsErrors.errorsDropoffCountryPhoneCode"></caf-error>
    <div *ngIf="!isB2B" class="input-item">
      <caf-input [settings]="settings.dropoffEmail"></caf-input>
      <caf-error [settings]="settingsErrors.errorsDropOffEmail"></caf-error>
    </div>
  </section>
 


  <!-- fecha del servicio-->
  <section class="input-item">
    <span class="subtitle-1">{{'cafler.page.service-date.info.title' | translate}}</span>
    <caf-datepicker [settings]="settings.pickupDate"></caf-datepicker>
    <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsPickupDate"></caf-error>
    <p class="error-message" *ngIf="pastDateWhenDuplicatingError">{{'cafler.error.past-date-when-duplication' | translate}}</p>
  </section>

  <!-- hora del servicio-->
  <section *ngIf="fg.value.deliveryType && fg.value.deliveryType === 1; else truckDelivery"> 
    <div *ngIf="readyToDisplayHours" > 
      <span class="subtitle-1">{{'cafler.page.service-time.info.title' | translate}}</span>
      <caf-select [settings]="settings.selectTimeOption"></caf-select>
      <br/>    
        <div *ngIf="timeRangeOption === 'range'" class="caf-grid-row split-2-cols split-sm-2-cols">
          <caf-select #startServiceTimeComponent [settings]="settings.startPickUpTime"></caf-select>
          <caf-select #endServiceTimeComponent [settings]="settings.endPickUpTime"></caf-select>
        </div>
        <div *ngIf="timeRangeOption === 'specific'">
          <caf-multicolumn-select #startServiceTimeComponent [settings]="settings.specificTimeRange"></caf-multicolumn-select>
          <div *ngIf="!noSpecificHourAvailable">
            <div *ngIf="currentCountry.RegionIsoCode === 'esp'">
              <span *ngIf="currentCountry.PreferredRegionCurrency === 1" class="span-2">
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.spain.info_b2b' 
                    : 'cafler.page.service-time.specific-time.spain.info')
                  | translate
                }}
              </span>
              <span *ngIf="currentCountry.PreferredRegionCurrency === 3" class="span-2">
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.spain.info_uk_b2b' 
                    : 'cafler.page.service-time.specific-time.spain.info_uk')
                  | translate
                }}
              </span>
            </div>
  
            <div *ngIf="currentCountry.RegionIsoCode === 'fra'">
              <span *ngIf="currentCountry.PreferredRegionCurrency === 1" class="span-2">
  
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.france.info_b2b' 
                    : 'cafler.page.service-time.specific-time.france.info')
                  | translate
                }}
              </span>
              <span *ngIf="currentCountry.PreferredRegionCurrency === 3" class="span-2">
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.france.info_uk_b2b' 
                    : 'cafler.page.service-time.specific-time.france.info_uk')
                  | translate
                }}
              </span>
            </div>
            
            <div *ngIf="currentCountry.RegionIsoCode === 'gbr'">
              <span *ngIf="currentCountry.PreferredRegionCurrency === 1" class="span-2">
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.uk.info_b2b' 
                    : 'cafler.page.service-time.specific-time.uk.info')
                  | translate
                }}
              </span>
              <span *ngIf="currentCountry.PreferredRegionCurrency === 3" class="span-2">
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.uk.info_uk_b2b' 
                    : 'cafler.page.service-time.specific-time.uk.info_uk')
                  | translate
                }}
              </span>
            </div>
          </div>
        </div> 
    </div>
    
    <div
      class="caf-grid-row split-2-cols split-sm-2-cols"
      *ngIf="readyToDisplayHours"
    >
      <caf-error
        *ngIf="!combinationNotValid && !dayChangedFlag"
        class="extra-margin-error col-start-sm-1 col-start-1 col-end-sm-1 col-end-1"
        [settings]="settingsErrors.errorsStartPickUpTime"
      ></caf-error>
      <caf-error
        *ngIf="!combinationNotValid && !dayChangedFlag"
        class="extra-margin-error col-start-sm-2 col-start-2 col-end-sm-2 col-end-2"
        [settings]="settingsErrors.errorsEndPickUpTime"
      ></caf-error>
    </div>
  
    <div *ngIf="timeRangeOption === 'range'">
      <p class="error-message" *ngIf="readyToDisplayHours && noRangeHourAvailable">
        {{"cafler.page.service-time.error-range-hours-not-available" | translate}}
      </p>
      <p class="error-message" *ngIf="dayChangedFlag && !noRangeHourAvailable">
        {{"cafler.page.service-time.error-day-changed" | translate}}
      </p>
    </div>
  
    <div *ngIf="timeRangeOption === 'specific'">
      <p class="error-message" *ngIf="readyToDisplayHours && noSpecificHourAvailable">
        {{"cafler.page.service-time.error-specific-hours-not-available" | translate}}
      </p>
      <p class="error-message" *ngIf="dayChangedFlag && !noSpecificHourAvailable">
      {{"cafler.page.service-time.error-day-changed" | translate}}
      </p>
      <p class="error-message" *ngIf="duplicateSpecificHourError">{{'cafler.page.service-time.duplication-error-specific-hour-not-available' | translate}}</p>
    </div>
  
    <p class="error-message" *ngIf="combinationNotValid">
      {{"cafler.page.service-time.error-not-compatible" | translate}}
    </p>
  </section>
  <ng-template #truckDelivery>
    <div *ngIf="readyToDisplayHours" class="input-item">
      <caf-select [settings]="settings.pickupTime"></caf-select>
      <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsPickupTime"></caf-error>
    </div>
  </ng-template>

  <!-- dropoff date and time-->
  <section *ngIf="fg.value.deliveryType && fg.value.deliveryType !== 1">
    <span class="subtitle-1">{{'cafler.dropoff-info.dropoff-day.placeholder' | translate}}</span>
    <div class="input-item">
      <caf-datepicker [settings]="settings.dropoffDate"></caf-datepicker>
      <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsDropOffDate"></caf-error>
      <p *ngIf="fg.errors?.checkDeliveryDateLowerThanPickup" class="error-message error-msg">
        {{'cafler.forms.error.pickupdate-greater-than-delivery' | translate}}
      </p>
    </div>
    <div *ngIf="readyToDisplayHours" class="input-item">
      <caf-select [settings]="settings.dropoffTime"></caf-select>
      <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsDropoffTime"></caf-error>
    </div>
  </section>
  
  <!-- vehicle information-->
  <section>
    <div class="input-item">
      <span class="subtitle-1">{{'cafler.vehicle-info.title' | translate}}</span>
      <caf-select [settings]="settings.vehicleType"></caf-select>
      <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsVehicleType"></caf-error>
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.carPlaque"></caf-input>
      <caf-error [settings]="settingsErrors.errorsCarPlaque"></caf-error>
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.brand"></caf-input>
      <caf-error [settings]="settingsErrors.errorsBrand"></caf-error>
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.model"></caf-input>
      <caf-error [settings]="settingsErrors.errorsModel"></caf-error>
    </div>
  </section>

  <!-- aditional comments-->
  <section class="input-item">
    <span class="subtitle-1">{{'cafler.pickup-info.comments.title' | translate}}</span>
    <caf-textarea [settings]="settings.additionalComments"></caf-textarea>
  </section>
</form>
