import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { Store } from '@ngrx/store';
import { selectBookedOrder, selectIsB2B, selectPaymentHashes } from '../../store/selectors';
import { CaflerPopupService } from '@cafler/common-ui';
import { KamikazeResource } from '../../resources/kamikaze.resource';
import { KamikazeActions, setIsLoading } from '../../store/actions';
import { PresentationFacade } from '../../facades/presentation.facade';
import { OverviewFacade } from '../../facades/overview.facade';
import { ReplaySubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.page.html',
  styleUrls: ['./checkout.page.scss'],
})
export class CheckoutPage implements OnInit {
  private readonly destroySubjects$ = new ReplaySubject<void>(1);
  orderDetails: any = {};
  paymentId!: string;
  paymentData!: any;
  amount: number = 50;
  paymentHashes!: any;
  btnSettings = { id: 'pago', text: 'cafler.payment.button' };
  redirectResult!: string;
  sessionId!: string;
  iframeRestarted = true;
  isB2B = false;

  constructor(
    private store: Store,
    private navigationService: NavigationService,
    private modalService: CaflerPopupService,
    private presentationFacade: PresentationFacade,
    private resource: KamikazeResource,
    private overviewFacade: OverviewFacade,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.store.dispatch(setIsLoading({ isLoading: true }));

    this.sessionId = this.activatedRoute.snapshot.queryParams.sessionId;
    this.redirectResult = this.activatedRoute.snapshot.queryParams.redirectResult;

    if (this.sessionId && this.redirectResult) {
      this.iframeRestarted = false;
      this.resource.updatePaymentSession(this.redirectResult).subscribe((res) => {
        if (res.Authorized === true) {
          this.router.navigate(['success-payment']);
        } else {
          this.paymentData = res.PaymentData.PaymentData;
          this.paymentId = res.PaymentData.PaymentId;
          this.onPayFailed('payment failed');
        }
      });
    }

    this.store.dispatch(setIsLoading({ isLoading: false }));

    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: false });
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'checkout' });
    this.store.select(selectBookedOrder).subscribe((res) => {
      this.paymentId = res.PaymentData.PaymentId;
      this.paymentData = res.PaymentData.PaymentData;
    });

    this.store.select(selectPaymentHashes).subscribe((res) => {
      this.paymentHashes = res;
    });

    this.store.select(selectIsB2B).subscribe((isB2B) => {
      this.isB2B = isB2B;
    });

    this.navigationService.currentPageTitle = 'cafler.checkout.title';
    this.orderDetails = this.overviewFacade.getOverviewByProductType();
    // this.overviewFacade
    //   .getOverviewByProductType()
    //   .pipe(takeUntil(this.destroySubjects$))
    //   .subscribe((data) => {
    //     this.orderDetails = data;
    //   });
  }

  onPaySucceeded(e: any) {
    this.router.navigateByUrl('/success-payment').then(() => {
      this.navigationService.currentPageTitle = '';
    });
  }

  onPayCancelled(e: any) {
    this.iframeRestarted = false;
    let x = this.modalService.open('', this.presentationFacade.getModalErrorMessageSettings());
    x.afterClosed$.subscribe((res) => {
      this.iframeRestarted = true;
    });
  }

  onPayFailed(e: any) {
    this.iframeRestarted = false;
    let x = this.modalService.open('', this.presentationFacade.getModalErrorMessageSettings());
    x.afterClosed$.subscribe((res) => {
      this.iframeRestarted = true;
    });
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }
}
