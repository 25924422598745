import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControlName, FormGroup } from '@angular/forms';
import { KamikazeActions, setIsLoading } from '../../store/actions';
import { Store } from '@ngrx/store';
import { first, pairwise, takeUntil } from 'rxjs/operators';
import { ReplaySubject, zip } from 'rxjs';
import {
  selectCurrentCountry,
  selectCurrentLang,
  selectCurrentZone,
  selectIsB2B,
  selectMotStations,
  selectProductData,
  selectSelectedProduct,
} from '../../store/selectors';
import { FormsHelperService } from '../../services/forms.service';
import { NavigationService } from '../../services/navigation.service';
import { PresentationFacade } from '../../facades/presentation.facade';
import { datepickerMaxDate } from '../../services/utils';
import {
  CaflerErrorSettingsModel,
  CaflerIconSettingsModel,
  GoogleMapsService,
  GoogleAddress,
} from '@cafler/common-ui';
import { SingleDataHelperService } from 'src/app/helpers/single-data-helper.service';
import { operativeZonesURLByCountry } from 'src/app/config/operativeZonesURLByCountry';
import { nulledCities, nulledVehicles } from 'src/app/constants';

@Component({
  selector: 'app-itv-with-appointment-form',
  templateUrl: './itv-form-with-appointment.page.html',
  styleUrls: ['./itv-form-with-appointment.page.scss'],
})
export class ItvFormWithAppointmentPage implements OnInit, OnDestroy {
  private readonly destroySubjects$ = new ReplaySubject<void>(1);
  fg!: FormGroup;
  isLoading: boolean = true;
  isB2B: boolean = false;
  isSameDeliveryAddress!: boolean;
  timesFailedPickupAddress = 0;
  timesFailedDropoffAddress = 0;
  maxErrorsOnAddress = 4;
  startRangeAppointment: string = '';
  endRangeAppointment: string = '';
  serviceTimeOptions = [];
  currentCountry: any;
  initialForm: any;
  isDuplicated: boolean = false;
  operativeZonesURL: string = '';
  private currentCity!: string;

  selectedProduct: any;

  settings: any = {
    serviceType: {
      id: 'serviceType',
      formControlName: 'serviceType',
      placeholder: 'cafler.page.generic.service-type.placeholder',
      disabled: false,
      options: [],
    },
    fullName: {
      id: 'fullName',
      formControlName: 'fullName',
      placeholder: 'cafler.contact.name.placeholder',
      disabled: false,
    },
    countryPhoneCode: {
      id: 'countryPhoneCode',
      formControlName: 'countryPhoneCode',
      placeholder: 'cafler.contact.country-phone-code.placeholder',
      disabled: false,
      options: [],
    },
    vehicleType: {
      id: 'vehicleType',
      formControlName: 'vehicleType',
      placeholder: 'cafler.page.fuel.body-type.placeholder',
      disabled: false,
      options: [],
    },
    fuelType: {
      id: 'fuelType',
      formControlName: 'fuelType',
      placeholder: 'cafler.page.fuel.refuel.placeholder',
      disabled: false,
      options: [],
    },
    phone: {
      id: 'phone',
      formControlName: 'phone',
      placeholder: 'cafler.contact.phone.placeholder',
      disabled: false,
      type: 'phone',
    },
    email: {
      id: 'email',
      formControlName: 'email',
      placeholder: 'cafler.contact.email.placeholder',
      disabled: false,
      type: 'email',
    },
    carPlaque: {
      id: 'carPlaque',
      formControlName: 'carPlaque',
      placeholder: 'cafler.vehicle-info.plate.placeholder',
      disabled: false,
      type: 'text',
    },
    brand: {
      id: 'brand',
      formControlName: 'brand',
      placeholder: 'cafler.vehicle-info.brand.placeholder',
      disabled: false,
      required: true,
      type: 'text',
    },
    model: {
      id: 'model',
      formControlName: 'model',
      placeholder: 'cafler.vehicle-info.model.placeholder',
      disabled: false,
      required: true,
      type: 'text',
    },
    pickupAddress: {
      id: 'pickupAddress',
      formControlName: 'pickupAddress',
      placeholder: 'cafler.pickup-info.pickup-address.placeholder',
      options: { businessSuggestions: true, placeSuggestions: true },
    },
    pickupAddressDetails: {
      id: 'pickupAddressDetails',
      formControlName: 'pickupAddressDetails',
      placeholder: 'cafler.pickup-info.pickup-details.placeholder',
      disabled: false,
      required: false,
    },
    sameDeliveryAddress: {
      id: 'sameDeliveryAddress',
      formControlName: 'sameDeliveryAddress',
      label: 'cafler.page.wash.diff-address-delivery',
      labelAlign: 'right',
      disabled: false,
    },
    appointmentDate: {
      id: 'appointmentDate',
      formControlName: 'appointmentDate',
      placeholder: 'cafler.pickup-info.appointment-day.placeholder',
      minDate: new Date(),
      daysDisabled: [6, 0], // si quitas esto eliminar el hack del metodo checkAppointmentHour en kamikaze facade que hardcodea que no haya findes (mismo horario todos los dias)
      maxDate: datepickerMaxDate(),
      icon: <CaflerIconSettingsModel>{
        alt: 'calendar',
        name: 'caf-notes-book-dark',
      },
      lang: 'currentLang',
    },
    appointmentTime: {
      id: 'appointmentTime',
      formControlName: 'appointmentTime',
      seconds: false,
      disabled: false,
      icon: <CaflerIconSettingsModel>{
        alt: 'calendar',
        name: 'caf-clock-light',
      },
    },
    appointmentCode: {
      id: 'appointmentCode',
      formControlName: 'appointmentCode',
      placeholder: 'cafler.page.service-code.appointment-placeholder',
    },

    station: {
      id: 'selectTechnicalInspectionStation',
      formControlName: 'station',
      placeholder: 'cafler.page.service-Station.appointment-placeholder',
      disabled: false,
      options: [],
    },

    isTechnicalInspectionPaid: {
      id: 'isTechnicalInspectionPaid',
      formControlName: 'isTechnicalInspectionPaid',
      label: 'cafler.page.service-payed-mot-fees.appointment-placeholder',
      labelAlign: 'right',
    },
    dropoffAddress: {
      id: 'dropoffAddress',
      formControlName: 'dropoffAddress',
      placeholder: 'cafler.pickup-info.pickup-address.placeholder',
      options: { businessSuggestions: true, placeSuggestions: true },
      required: false,
    },
    dropoffAddressDetails: {
      id: 'dropoffAddressDetails',
      formControlName: 'dropoffAddressDetails',
      placeholder: 'cafler.pickup-info.pickup-details.placeholder',
      disabled: false,
      required: false,
    },
    additionalComments: {
      id: 'additionalComments',
      formControlName: 'additionalComments',
      placeholder: 'cafler.pickup-info.textarea.information',
      minlength: 0,
      disabled: false,
      required: false,
    },
  };
  settingsExtra: any = {
    locationIcon: <CaflerIconSettingsModel>{
      alt: 'location',
      name: 'caf-pin-location',
    },
    warningIcon: <CaflerIconSettingsModel>{
      name: 'caf-warning-exclamation',
      alt: 'warning',
      width: '14px',
      height: '14px',
    },
  };
  settingsErrors: any = {
    errorsFullName: <CaflerErrorSettingsModel>{
      formControlName: 'fullName',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsVehicleType: <CaflerErrorSettingsModel>{
      formControlName: 'vehicleType',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsFuelType: <CaflerErrorSettingsModel>{
      formControlName: 'vehicleType',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsCountryPhoneCode: <CaflerErrorSettingsModel>{
      formControlName: 'countryPhoneCode',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsPhone: <CaflerErrorSettingsModel>{
      formControlName: 'phone',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'pattern', text: 'cafler.forms.error.phone-pattern' },
        { key: 'minlength', text: 'cafler.forms.error.min-length' },
      ],
    },
    errorsEmail: <CaflerErrorSettingsModel>{
      formControlName: 'email',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'email', text: 'cafler.forms.error.email' },
      ],
    },
    errorsCarPlaque: <CaflerErrorSettingsModel>{
      formControlName: 'carPlaque',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'invalidLicensePlate', text: 'cafler.forms.error.invalid-license-plate' },
      ],
    },
    errorsModel: <CaflerErrorSettingsModel>{
      formControlName: 'model',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsBrand: <CaflerErrorSettingsModel>{
      formControlName: 'brand',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsAppointmentCode: <CaflerErrorSettingsModel>{
      formControlName: 'appointmentCode',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },

    errorsAppointmentDate: <CaflerErrorSettingsModel>{
      formControlName: 'appointmentDate',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'notPresent', text: 'cafler.forms.error.date-not-in-past' }, //TODO Ask which will be the best error message and make all translations.
      ],
    },

    // errorsAppointmentTime: <CaflerErrorSettingsModel>{
    //   formControlName: 'appointmentTime',
    //   errors: [
    //     { key: 'required', text: 'required' },
    //     { key: 'hourNotInStationRange', text: 'not-in-range' }
    //   ],
    // },

    errorsPickupAddress: <CaflerErrorSettingsModel>{
      formControlName: 'pickupAddress',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'selectedAddress', text: 'cafler.forms.error.selected-address' },
        {
          key: 'streetNumberNeeded',
          text: 'cafler.address-message-error-missing-street-number.title',
        },

        { key: 'addressNumber', text: 'cafler.forms.error.address-number' },
        {
          key: 'checkCoordinatesWithinCaflerArea',
          text: 'cafler.forms.error.cafler-country-coverage',
        },
      ],
    },
    errorsDropoffAddress: <CaflerErrorSettingsModel>{
      formControlName: 'dropoffAddress',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'selectedAddress', text: 'cafler.forms.error.selected-address' },
        {
          key: 'streetNumberNeeded',
          text: 'cafler.address-message-error-missing-street-number.title',
        },

        { key: 'addressNumber', text: 'cafler.forms.error.address-number' },
        {
          key: 'checkCoordinatesWithinCaflerArea',
          text: 'cafler.forms.error.cafler-country-coverage',
        },
      ],
    },
  };

  constructor(
    private store: Store,
    private navigationService: NavigationService,
    private presentationFacade: PresentationFacade,
    private formsService: FormsHelperService,
    private googleMapsService: GoogleMapsService,
    private singleDataHelperService: SingleDataHelperService,
  ) {
    this.initialForm = history.state.initialForm ? history.state.initialForm : null;
    this.isDuplicated = history.state.isDuplicated ? history.state.isDuplicated : false;

    this.fg = this.formsService.initializeForm(this.settings, this.initialForm);

    this.store
      .select(selectCurrentZone)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((zone) => {
        if (zone) {
          this.fg.get('sameDeliveryAddress')?.setValue(false);
          if (!this.isDuplicated) {
            this.fg.get('isTechnicalInspectionPaid')?.setValue(false);
          }

          this.fg.get('dropoffAddress')?.clearValidators();
          this.fg.get('dropoffAddress')?.updateValueAndValidity();

          // this.settings.pickupAddress.options.countryCode = zone?.RegionCode;
          const bbox = googleMapsService.getBoundingBox(
            zone?.UpperRightBoundingBoxCenterLatitude,
            zone?.UpperRightBoundingBoxCenterLongitude,
            zone?.LowerLeftBoundingBoxCenterLatitude,
            zone?.LowerLeftBoundingBoxCenterLongitude,
          );
          this.settings.pickupAddress.options.bounds = bbox;
          this.settings.dropoffAddress.options.bounds = bbox;
          this.currentCity = zone.Name;
        }
      });

    this.store
      .select(selectCurrentCountry)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((country: any) => {
        if (country) {
          // setting default prefix code number
          this.fg.get('countryPhoneCode')?.setValue(country.InternationalPhonePrefix);
          this.currentCountry = country;

          const isoCode: string = country.RegionIsoCode;
          if (isoCode === 'esp' || isoCode === 'gbr' || isoCode === 'fra') {
            this.operativeZonesURL = operativeZonesURLByCountry[isoCode];
          }
        }
      });

    this.store
      .select(selectCurrentLang)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((lang) => {
        if (lang) {
          this.settings.appointmentDate = {
            ...this.settings.appointmentDate,
            lang: lang.split('-')[0],
          };
        }
      });

    this.store
      .select(selectMotStations)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((stations) => {
        if (stations) {
          let formattedStations = stations.map((station: any) => {
            return { key: station.Id, text: station.Name };
          });
          this.settings.station.options = formattedStations;
        }
      });

    const obs1$ = this.store.select(selectCurrentZone);
    const obs2$ = this.presentationFacade.getCoreDataCatalog('TransferTypeEnum');
    const merged$ = zip(obs1$, obs2$);

    merged$.subscribe(([zone, vehiclesTypes]) => {
      let a = vehiclesTypes.filter((vehicleErased: any) => {
        return this.presentationFacade.isVehicleValidForMOTInCountry(
          vehicleErased.key,
          this.currentCountry.RegionIsoCode,
        );
      });

      if (nulledCities.includes(zone.Name)) {
        a = a.filter((vehicleErased: any) => {
          return !nulledVehicles.includes(vehicleErased.key);
        });
      }

      this.settings.vehicleType.options = a.map((t: any) => ({
        key: t.key,
        text: `cafler.product-type.media-larga-distancia.vehicle-type.${t.text}`,
      }));
    });

    this.presentationFacade
      .getCoreDataCatalog('TransferTypeEnum')
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((vehicleTypes) => {
        if (vehicleTypes) {
          this.settings.fuelType.options = this.presentationFacade.getFuelTypeFormattedOptions(
            this.settings.vehicleType.options[0].key,
            this.currentCountry.RegionIsoCode,
          );
        }
      });

    if (this.isDuplicated) {
      this.validateFormAddress(this.settings.pickupAddress.formControlName);
      this.validateFormAddress(this.settings.dropoffAddress.formControlName);
    }
  }

  ngOnInit() {
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: true });
    this.store.dispatch({ type: KamikazeActions.SetNextPage, nextPage: 'overview' });
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'appointment' });
    this.store.dispatch({ type: KamikazeActions.ClearAvailableToppings });
    this.store.dispatch({ type: KamikazeActions.GetMotStations });

    this.store.dispatch({
      type: KamikazeActions.SetFormValidity,
      isFormValid: this.formsService.checkCurrentFormValidity(),
    });

    this.store
      .select(selectIsB2B)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((isB2B) => {
        this.isB2B = isB2B;
      });

    this.store
      .select(selectSelectedProduct)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((product) => {
        if (product) {
          this.selectedProduct = product;
          this.fg.controls.serviceType.setValue(product.ordersManagerProductId);
          this.navigationService.currentPageTitle = product.title;
        }
      });

    // Get phone prefixes
    this.presentationFacade
      .getCountryCodes()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((countryCodes) => {
        this.settings.countryPhoneCode.options = countryCodes;
      });

    this.singleDataHelperService
      .getCurrentVerticalProducts()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((products: any) => {
        this.settings.serviceType.options = products.technicalInspection.map((product: any) => {
          return { key: product.ordersManagerProductId, text: product.title, alias: product.alias };
        });
      });

    this.fg
      .get(this.settings.vehicleType.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((newValue) => {
        this.fg.controls.fuelType.setValue(null);

        this.settings.fuelType.options = this.presentationFacade.getFuelTypeFormattedOptions(
          newValue,
          this.currentCountry.RegionIsoCode,
        );
      });

    // Get B2B data and fill form
    this.store
      .select(selectProductData)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((productData) => {
        if (this.isLoading) {
          if (!!productData) {
            this.formsService.loadFormGroupData(this.fg, productData, this.isLoading);
            this.isSameDeliveryAddress = productData.sameDeliveryAddress;
            this.isLoading = false;
            if (this.selectedProduct) {
              this.fg.controls.serviceType.setValue(this.selectedProduct.ordersManagerProductId);
            }
          } else {
            if (this.isB2B) {
              this.formsService.preloadB2BData(this.fg);
            }
          }
        }
      });

    // Remove holidays from datepicker
    this.presentationFacade
      .getHolidays()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((holidays) => {
        let holidaysDates = holidays.map((day: any) => {
          return new Date(day);
        });

        this.settings.appointmentDate = {
          ...this.settings.appointmentDate,
          datesDisabled: holidaysDates,
        };
      });

    this.navigationService.scrollToTop();
    this.navigationService.currentPageTitle = 'cafler.product.mot.title.appointment';

    this.fg.statusChanges.pipe(takeUntil(this.destroySubjects$)).subscribe((status) => {
      this.formsService.handleFormValidity(status, this.fg.value);
    });

    this.fg
      .get('sameDeliveryAddress')
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((value: boolean) => {
        this.isSameDeliveryAddress = value;
        if (this.isSameDeliveryAddress) {
          this.formsService.addValidatorsAutosuggest(this.fg, 'dropoffAddress');
          this.fg.get('dropoffAddress')?.updateValueAndValidity();
        } else {
          this.fg.get('dropoffAddress')?.clearValidators();
          this.fg.get('dropoffAddress')?.setValue('');
          this.fg.get('dropoffAddressDetails')?.setValue('');
          this.fg.get('dropoffAddress')?.updateValueAndValidity();
        }
      });
  }
  getB2BAddress(formControlName: string) {
    this.presentationFacade
      .getAddressB2BToAutosuggest()
      .pipe(first())
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((addressObj) => {
        this.fg.get(formControlName)?.setValue(addressObj);
      });
  }
  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }

  countPickupErrors() {
    this.timesFailedPickupAddress = this.timesFailedPickupAddress + 1;
  }

  countDropoffErrors() {
    this.timesFailedDropoffAddress = this.timesFailedDropoffAddress + 1;
  }

  validateFormAddress(formControlName: string) {
    const addressFormRef = this.fg.get(formControlName)?.value as GoogleAddress;
    addressFormRef.googleObject.isValidAddress = true;
  }

  validateHoursStation(event: any) {
    //TODO stationId and date is hardcoded, we have to send the correct station and date to back.
    const stationId = this.settings.station.options[0].key;
    const date = new Date();
    const time = event.target.value;

    this.presentationFacade
      .validateMOTStationHours(stationId, date, time)
      .pipe(first())
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe(
        (response: any) => {
          this.fg.get('appointmentTime')?.setErrors(null);
        },
        (error: any) => {
          this.fg.get('appointmentTime')?.setErrors({ hourNotInStationRange: true });

          this.startRangeAppointment =
            this.presentationFacade.removeSeconds(error.AdditionalDetails.AvailabilityStartTime) +
            'h';
          this.endRangeAppointment =
            this.presentationFacade.removeSeconds(error.AdditionalDetails.AvailabilityEndTime) +
            'h';
        },
      );
  }
  onValidAddress(value: GoogleAddress, formControlName: string) {
    if (formControlName === 'pickupAddress') {
      this.timesFailedPickupAddress = 0;
    }
    if (formControlName === 'dropoffAddress') {
      this.timesFailedDropoffAddress = 0;
    }
    this.validateOperativeZone(value, formControlName);
  }

  validateOperativeZone(value: GoogleAddress, formControlName: string) {
    this.store.dispatch(setIsLoading({ isLoading: true }));
    this.presentationFacade
      .validateOperativeZone(value)
      .pipe(first())
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe(
        (response: any) => {
          this.fg.get(formControlName)?.setErrors(null);
          this.store.dispatch(setIsLoading({ isLoading: false }));
        },
        (error: any) => {
          this.fg.get(formControlName)?.setErrors({ checkCoordinatesWithinCaflerArea: true });
          this.store.dispatch(setIsLoading({ isLoading: false }));
        },
      );
  }
}
