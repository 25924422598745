<section>
    <ul class="img-container">
        <li *ngFor="let img of displayPhotos; let i = index" >
            <div class="img-preview" *ngIf="backlog.length === 0 || moreContentDisplay || i < displayPhotos.length - 1">
                <img [src]="img.url" [ngStyle]="getImageStyle(img.url)" (click)="openModal(img)"/>
            </div>
            <div class="img-preview" *ngIf="backlog.length > 0 && !moreContentDisplay && i === displayPhotos.length - 1">
                <div class="display-more-text-container">
                    <p class="img-display-more-text">+{{backlog.length}} {{"cafler.more" | translate}}</p>
                </div>
                <img class="display-more" [src]="img.url" [ngStyle]="getImageStyle(img.url)" (click)="onDisplayMore()"/>
            </div>
        </li>
    </ul>
</section>

<div id="overlay" [class.open]="modalOpen" (click)="closeModal($event)"></div> 

<dialog [id]="modelId" class="modal-photo" [class.open]="modalOpen" (keydown)="onModalKeydown($event)">
    <header class="modal-photo__header">
        <h4>{{ "cafler.photo-gallery.modal.preview"| translate}}</h4>
        <button class="close-button" (click)="closeModal($event)" >
            <caf-icon [settings]="closeIcon"></caf-icon>
        </button>
    </header>
    <div class="img-modal-container">
        <img id="modalImage" class="full-image"[src]="selectedImage?.url">
    </div>
    <footer class="modal-photo__footer">
        <a class="link-button" [href]="selectedImage?.url" target="_blank">
            <p class="link">
                {{"cafler.photo-gallery.modal.open-img-new-window" | translate}}
            </p>
        </a>
        <caf-button
            (click)="downloadImage(selectedImage)"
            class="caf-btn-desk-small caf-btn-primary"
            [settings]="buttonSettings"
        ></caf-button>
      
    </footer>
</dialog>
