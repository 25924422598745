<div class="caf-md-container">
  <div
    class="overview-wrapper"
    *ngIf="!!orderDetails && !!productType"
  >

  <h1 *ngIf="productType==='transfer+replacement'" class="subtitle-2">{{"cafler.overview.transfer-replacement.transfer.title" | translate}}</h1>
    <section *ngIf="orderDetails.infoTruckDeliveryService" class="overview-card">
      <h3 class="subtitle-1 extra-margin">
        {{ 'cafler.overview.service-info.title' | translate }}
    </h3>
      <div class="card-description" *ngFor="let item of orderDetails.infoTruckDeliveryService">
        <span class="label">{{ item.label | translate }}</span>
        <span class="description">{{ item.value | translate }} </span>
      </div>
    </section>

    <!-- homologation details -->
    <section *ngIf="selectedProduct.id === 'mot-with-homologation'" class="overview-card">
      <h3 class="subtitle-1 extra-margin">{{"cafler.overview.mot-homologation.card-title" | translate}}</h3>
      <div class="card-description" *ngFor="let item of orderDetails.infoHomologation">
        <span class="label">{{ item.label | translate }}</span>
        <span class="description">{{ item.value | translate }}</span>
      </div>
    </section>

    <!-- All you need description -->
    <section *ngIf="orderDetails.infoDescription" class="overview-card">
      <h3>{{'cafler.overview.all-you-need' | translate}}</h3>
      <div class="card-description">
        <span class="big-description"> {{orderDetails.infoDescription[0].value}}</span>
      </div>
      <div class="card-description">
        <span class="label">{{ orderDetails.infoDescription[1].label | translate }}</span>
        <span class="description">{{  (orderDetails.infoDescription[1].value | translate) || '-' }}</span>
      </div>
      <div class="card-description">
        <span class="label">{{ orderDetails.infoDescription[2].label | translate }}</span>
        <span class="description">{{  (orderDetails.infoDescription[2].value | translate) || '-' }}</span>
      </div>
    </section>
    <!-- Info Contact -->
    <section *ngIf="orderDetails.infoContact && productType !== 'transfer+replacement' && (productType !== 'medium-long-distance' || productData.deliveryType === 1)" class="overview-card">
      <h3 *ngIf="productType==='all-you-need'; else otherProduct">{{"cafler.overview.contact-information.title" | translate}}</h3>
      <ng-template #otherProduct>
        <h3 t class="subtitle-1 extra-margin">
          {{'cafler.page.media-larga-distancia.origin-contact.title' | translate}}
        </h3> 
        </ng-template>
      <div class="card-description" *ngFor="let item of orderDetails.infoContact">
        <span class="label">{{ item.label | translate }}</span>
        <span class="description">{{  (item.value | translate) || '-' }}</span>
      </div>
    </section>

    <!-- Info Dropdown contact -->
    <section *ngIf="orderDetails.infoDropoffContact && productType !== 'transfer+replacement' && (productType !== 'medium-long-distance' || productData.deliveryType === 1)" class="overview-card">
      <h3 class="subtitle-1 extra-margin">{{ 'cafler.dropoff-contact.title' | translate }}</h3>
      <div class="card-description" *ngFor="let item of orderDetails.infoDropoffContact">
        <span class="label">{{ item.label | translate }}</span>
        <span class="description">{{ item.value || '-' }}</span>
      </div>
    </section>
    
    <section *ngIf="orderDetails.infoTruckDeliveryOrigin" class="overview-card">
      <h3 class="subtitle-1 extra-margin">
        {{ 'cafler.page.overview.origin.title' | translate }}       
      </h3>
      <div *ngFor="let item of orderDetails.infoTruckDeliveryOrigin; let i = index">
        <div class="card-description">
          <span class="label">{{ item.label | translate }}</span>
          <span class="description">{{ item.value || '-' }}</span>
        </div>
      </div>
      <div class="line"></div>
      <h3 class="subtitle-1 extra-margin">{{'cafler.overview.contact' | translate}}</h3>
      <div *ngFor="let item of orderDetails.infoContact let i = index">
        <div class="card-description">
          <span class="label">{{ item.label | translate }}</span>
          <span class="description">{{ item.value || '-' }}</span>
        </div>
      </div>
    </section>

    <section *ngIf="orderDetails.infoTruckDeliveryDestination" class="overview-card">
  
      <h3 class="subtitle-1 extra-margin">
        {{ 'cafler.page.overview.destination.title' | translate }}
      </h3
      >
      <div *ngFor="let item of orderDetails.infoTruckDeliveryDestination; let i = index">
        <div class="card-description">
          <span class="label">{{ item.label | translate }}</span>
          <span class="description">{{ item.value || '-' }}</span>
        </div>
      </div>
      <div class="line"></div>
      <h3 class="subtitle-1 extra-margin">{{'cafler.overview.contact' | translate}}</h3>
      <div *ngFor="let item of orderDetails.infoDropoffContact; let i = index">
        <div class="card-description">
          <span class="label">{{ item.label | translate }}</span>
          <span class="description">{{ item.value || '-' }}</span>
        </div>
      </div>
    </section>

    <!-- Info Vehicle -->
    <section *ngIf="orderDetails.infoVehicle && productType !== 'transfer+replacement'" class="overview-card">
      <h3 class="subtitle-1 extra-margin">{{ 'cafler.vehicle-info.title' | translate }}</h3>
      <div class="card-description" *ngFor="let item of orderDetails.infoVehicle">
        <span class="label">{{ item.label | translate }}</span>
        <span class="description">{{ (item.value | translate) || '-' }}</span>
      </div>
    </section>

    <!-- Info Pickup and Dropdown -->
    <section class="overview-card" *ngIf="orderDetails.infoPickup && !productData.station || productType === 'valet'">
      <h3 class="subtitle-1 extra-margin">
        {{ 'cafler.overview.transfer-replacement.title' | translate }}
      </h3>
      <div class="card-description" *ngFor="let item of orderDetails.infoPickup">
        <span class="label">{{ item.label | translate }}</span>
        <span class="description">{{ item.value || '-' }}</span>
      </div>
      <div class="line" *ngIf="selectedProduct.id !== 'mot-no-appointment' && selectedProduct.id !== 'mot-with-homologation'"></div>
      <div class="card-description" *ngFor="let item of orderDetails.infoDropoff">
        <span class="label">{{ item.label | translate }}</span>
        <span class="description">{{ item.value || '-' }}</span>
      </div>
      <div class="line" *ngIf="orderDetails.infoDistance"></div>

      <div class="card-description" *ngIf="orderDetails.infoDistance">
        <span class="label">{{ orderDetails.infoDistance[0].label | translate }}</span>
        <span class="description">{{ orderDetails.infoDistance[0].value || '-' }}</span>
      </div>
    </section>

    <!-- Info Contact transfer+replacement -->
    <section *ngIf="orderDetails.infoContact && productType==='transfer+replacement' &&  productData.deliveryType === 1" class="overview-card">
      <h3 class="subtitle-1 extra-margin">
        {{'cafler.page.media-larga-distancia.origin-contact.title' | translate}}
     </h3>
      <div class="card-description" *ngFor="let item of orderDetails.infoContact">
        <span class="label">{{ item.label | translate }}</span>
        <span class="description">{{  (item.value | translate) || '-' }}</span>
      </div>
    </section>

    <!-- Info Dropdown contact transfer+replacement -->
    <section *ngIf="orderDetails.infoDropoffContact && productType==='transfer+replacement' &&  productData.deliveryType === 1" class="overview-card">
      <h3 class="subtitle-1 extra-margin">{{ 'cafler.dropoff-contact.title' | translate }}</h3>
      <div class="card-description" *ngFor="let item of orderDetails.infoDropoffContact">
        <span class="label">{{ item.label | translate }}</span>
        <span class="description">{{ item.value || '-' }}</span>
      </div>
    </section>

    <!-- Info Appointment -->
    <section class="overview-card" *ngIf="productData.station && productType !== 'valet'">
      <h3 class="subtitle-1 extra-margin">
        {{ 'cafler.overview.mot-appointment.info.title' | translate }}</h3>
      <div class="card-description" *ngFor="let item of orderDetails.infoAppointment">
        <span class="label">{{ item.label | translate }}</span>
        <span class="description">{{ (item.value | translate) || '-' }}</span>
      </div>
      <div>
        <div class="line"></div>
        <caf-icon class="info-icon" [settings]="infoIcon"></caf-icon>
        <span class="mot-appointment-info">
          {{ 'cafler.page.overview.mot-appointment.info.pickup' | translate }}
        </span>
      </div>
    </section>

    <!-- Info Service -->
    <section class="overview-card" *ngIf="orderDetails.infoService && productData.station && productType !== 'valet'">
      <h3 class="subtitle-1 extra-margin">
        {{ 'cafler.overview.service-info.title' | translate }}</h3
      >
      <div class="card-description" *ngFor="let item of orderDetails.infoService">
        <span class="label">{{ item.label | translate }}</span>
        <span class="description">{{ item.value || '-' }}</span>
      </div>
      <div *ngIf="orderDetails.infoDropoff" class="line"></div>
      <div class="card-description" *ngFor="let item of orderDetails.infoDropoff">
        <span class="label">{{ item.label | translate }}</span>
        <span class="description">{{ item.value || '-' }}</span>
      </div>
    </section>

    <!-- Replacement service data -->
    <div *ngIf="productType ==='transfer+replacement'" class="overview-wrapper">    
      <h1 class="subtitle-2">{{"cafler.overview.transfer-replacement.replacement-vehicle.title" | translate}}</h1>
      <section class="card-border overview-card">
        <vehicle-data-display [settings]="carDataSettings"></vehicle-data-display>
      </section>

      <section *ngIf="orderDetails.infoTruckDeliveryOrigin" class="overview-card">
        <h3 class="subtitle-1 extra-margin"
          >{{ 'cafler.page.overview.origin.title' | translate }}</h3
        >
        <div *ngFor="let item of orderDetails.infoTruckDeliveryOrigin; let i = index">
          <div class="card-description">
            <span class="label">{{ item.label | translate }}</span>
            <span class="description">{{ item.value || '-' }}</span>
          </div>
        </div>
        <div class="line"></div>
        <h3 class="subtitle-1 extra-margin">{{'cafler.overview.contact' | translate}}</h3>
        <div *ngFor="let item of orderDetails.infoContact let i = index">
          <div class="card-description">
            <span class="label">{{ item.label | translate }}</span>
            <span class="description">{{ item.value || '-' }}</span>
          </div>
        </div>
      </section>
  
      <section *ngIf="orderDetails.infoReplacementService" class="overview-card">
        <h3 class="subtitle-1 extra-margin"
          >{{ 'cafler.overview.transfer-replacement-info-service.title' | translate }}</h3
        >
        <div *ngFor="let item of orderDetails.infoReplacementService; let i = index">
          <div class="card-description">
            <span class="label">{{ item.label | translate }}</span>
            <span class="description">{{ item.value || '-' }}</span>
          </div>
        </div>
        <div class="line"></div>
        <h3 class="subtitle-1 extra-margin">{{'cafler.overview.contact' | translate}}</h3>
        <div *ngFor="let item of orderDetails.infoReplacementDropOff; let i = index">
          <div class="card-description">
            <span class="label">{{ item.label | translate }}</span>
            <span class="description">{{ item.value || '-' }}</span>
          </div>
        </div>
      </section>
    </div>
  </div>
  
    <!-- how it works?-->
    <article class="how-it-works-panel" *ngIf="productType ==='transfer+replacement'">
      <h3 class="how-it-works-title">{{"cafler.overview.transfer-replacement.how-it-works.title" | translate}}</h3>
      <div class="element-list-wrapper">
        <caf-icon class="icon-list" [settings]="car"></caf-icon>
        <span>{{'cafler.page.overview.how-it-work.1' | translate}}</span>
      </div>
      <div class="element-list-wrapper">
        <caf-icon class="icon-list" [settings]="envelope"></caf-icon>
        <span>{{'cafler.page.overview.how-it-work.2' | translate}}</span>
      </div>
      <div class="element-list-wrapper">
        <caf-icon class="icon-list" [settings]="person"></caf-icon>
        <span>{{'cafler.page.overview.how-it-work.3' | translate}}</span>
      </div>
      <div class="element-list-wrapper">
        <caf-icon class="icon-list" [settings]="paperCheck"></caf-icon>
        <span>{{'cafler.page.overview.how-it-work.4' | translate}}</span>
      </div>
    </article>


    <!-- minimum Requirements -->
    <div *ngIf="productType !== 'tow-truck'" class="overview-icons-card">
      <p>{{ 'cafler.page.overview.policy.title' | translate }}</p>
      <div class="icons-wrapper">
        <div class="icon-wrapper">
          <caf-icon [settings]="papersIcon"></caf-icon>
          <span class="span-1">{{ 'cafler.page.overview.policy.licence' | translate }}</span>
        </div>
        <div class="icon-wrapper">
          <caf-icon [settings]="papersPenIcon"></caf-icon>
          <span class="span-1">{{ 'cafler.page.overview.policy.assurance' | translate }}</span>
        </div>
        <div class="icon-wrapper">
          <caf-icon [settings]="gasIcon"></caf-icon>
          <span class="span-1">{{ 'cafler.page.overview.policy.fuel' | translate }}</span>
        </div>
      </div>
    </div>

    
    <form [formGroup]="fg" class="overview-form">

      <!-- pricing breakdown -->
      <div *ngIf="orderDetails.infoPricing && orderDetails.infoTotalPrice">
          <h3 class="subtitle-1 extra-margin"
            >{{ 'cafler.page.overview.coupon-title' | translate }}</h3
          >
          <div class="caf-grid-row split-2-cols split-sm-2-cols coupon-grid">
            <caf-input
              class="coupon-input input-item col-start-sm-1 col-start-1 col-end-sm-1 col-end-1"
              [settings]="settings.coupon"
            ></caf-input>
            <caf-button
              (click)="sendCoupon()"
              class="coupon-btn caf-btn-supplementary caf-btn-desk-small h-align-center v-align-center caf-btn-mob-extra-small col-start-sm-2 col-start-2 col-end-sm-2 col-end-2"
              [settings]="settings.couponButtonSubmit"
            ></caf-button>
          </div>
          <div *ngIf="!!couponCode && isCouponValid">
            <coupon-box [couponCode]="couponCode" (onBoxClosed)="removeCoupon()"></coupon-box>
          </div>
          <p class="coupon-error" *ngIf="isCouponValid === false && !fg.get('coupon')?.pristine">
            {{"cafler.page.overview.coupon.error" | translate}}
          </p>
          
          <price-breakdown [priceBreakdown]="orderDetails.infoPricing" [taxes]="taxes" [toppings]="toppings" (removeToppingEvent)="removeTopping($event)"></price-breakdown>
          
          <div class="overview-card total-price">
            <span>
              {{ orderDetails.infoTotalPrice.label | translate }}&nbsp;
              <span *ngIf="!isB2B" class="iva">{{ 'cafler.page.overview.iva' | translate }}</span>
            </span>
            <span class="description">{{ orderDetails.infoTotalPrice.value || '-' }}</span>
          </div>
      </div>

    <a class="cancelation-policy" (click)="openCancelationPolicy()">
      {{ 'cafler.overview.label.cancelation-policy' | translate }}</a>

    <div class="input-item conditions overview-input">
        <caf-checkbox [settings]="settings.termsAndConditions"></caf-checkbox>
        <a
          class="terms-conditions"
          href="https://cafler.com/legal/terms-and-conditions"
          target="_blank"
          >{{ 'cafler.page.overview.terms-and-conditions.label' | translate }}</a
        >
    </div>
    <caf-error
      class="extra-margin-error overview-error"
      [settings]="settingsErrors.errorsTermsAndConditions"
    ></caf-error>

    <div *ngIf="productType !== 'tow-truck' && productType !== 'all-you-need'" class="input-item overview-input">
      <caf-checkbox [settings]="settings.minimumRequirements"></caf-checkbox>
    </div>
    <caf-error
      class="extra-margin-error overview-error"
      [settings]="settingsErrors.errorsMinimumRequirements"
    ></caf-error>
  </form>

  <footer *ngIf="productType !== 'tow-truck' && productType !== 'all-you-need' " class="overview-footer">
    <caf-button
    (onClick)="openToppingPopUp()"
    class="caf-btn-primary caf-btn-desk-large caf-btn-mob-large"
    [settings]="buttonSettings"
  ></caf-button>
  </footer>

