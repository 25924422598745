import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tracking-display',
  templateUrl: './tracking-display.component.html',
  styleUrls: ['./tracking-display.component.scss'],
})
export class TrackingDisplayComponent implements OnInit {
  constructor() {}
  emptyStateImgUrl = '../assets/svg/service-details.empty-state.svg';
  ngOnInit(): void {}
}
