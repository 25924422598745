import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {  PhotoGallerySettings } from './display.model';
import { Image } from 'src/app/models/fe/image.model';
import { CaflerIconSettingsModel } from '@cafler/common-ui';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit {
  @Input() settings!: PhotoGallerySettings

  selectedImage!: Image | null
  displayPhotos: Image[] = []
  inCapPhotos: Image[] = [] 
  backlog: Image[] = []
  modalOpen = false;
  moreContentDisplay = false;

  closeIcon = <CaflerIconSettingsModel>{
    name: 'caf-close',
    alt: 'Close icon',
  };

  modelId!: string

  buttonSettings: any = {
    text: 'cafler.photo-gallery.modal.download',
    disabled: false,
  };
  base64Image: any;
 
  constructor(private cdr: ChangeDetectorRef) {}
  
  ngOnInit(): void {
    this.modelId = `${this.settings.id}-modalId`
    this.settings.images.forEach((img, indx) => {
      if(indx <= this.settings.cap){
        this.displayPhotos.push(img)
        this.inCapPhotos.push(img)
      }else{
        this.backlog.push(img)
      }
    })

  }
  
  onDisplayLess(){
    this.displayPhotos = this.inCapPhotos
    this.moreContentDisplay = false
  }

  onDisplayMore(){
    this.displayPhotos = this.displayPhotos.concat(this.backlog)
    this.moreContentDisplay = true
  }

  getImageStyle(url: string){
    return {
      'background-image': `url(${url})`,
      'background-size': 'cover',
      'background-position': 'center',
      'width': '100%',
      'cursor': 'pointer',
      'padding-bottom': '100%', 
    };
  }

  openModal(image: Image) {
    this.selectedImage = image;
    const modal = document.getElementById(this.modelId) as HTMLDialogElement;
    modal.showModal();
    this.modalOpen = true;
    document.body.style.overflow = 'hidden';
  }

  downloadImage(image: Image | null){
    if(!image) return 
  
    const extension = image.url.split('.')[image.url.split('.').length - 1]


    this.getBase64ImageFromURL(image.url).subscribe((base64data: any) => {
      this.base64Image = "data:image/jpg;base64," + base64data;
      // save image to disk
      var link = document.createElement("a");

      document.body.appendChild(link); // for Firefox

      link.setAttribute("href", this.base64Image);
      link.setAttribute("download", `${image.name}.${extension}`);
      link.click();
    });
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: any = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
  
  closeModal(event?: MouseEvent) {
    this.selectedImage = null;
    this.modalOpen = false;
    const modal = document.getElementById(this.modelId) as HTMLDialogElement;
    modal.close();
    document.body.style.overflow = 'auto';
  }

  onModalKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeModal();
    }
  }
}
