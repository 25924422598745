import { Component, OnDestroy, OnInit } from '@angular/core';
import { selectCurrentPage } from '../../store/selectors';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { CaflerIconSettingsModel } from '@cafler/common-ui';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit, OnDestroy {
  private readonly destroySubjects$ = new ReplaySubject<void>(1);
  classLine!: string;
  stepSideBar = {
    progress: 0,
    stepsLabels: [
      'cafler.stepper.first.label',
      'cafler.stepper.second.label',
      'cafler.stepper.third.label',
    ],
  };
  progressBar = {
    id: 'progressBar',
    value: 0,
    max: 3,
  };

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectCurrentPage)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((currentPage) => {
        if (currentPage == 'checkout') {
          this.progressBar.value = 2;
          this.stepSideBar.progress = 2;
        } else if (currentPage == 'overview') {
          this.progressBar.value = 1;
          this.stepSideBar.progress = 1;
        } else {
          this.progressBar.value = 0;
          this.stepSideBar.progress = 0;
        }
      });
  }

  returnSpotClass(progress: number, index: number): string {
    if (index - 1 <= progress) return 'dot-active';
    return 'dot-inactive';
  }

  returnLineClass(progress: number, index: number, totalSteps: number): string {
    if (index - 1 >= progress) {
      if (this.isLastTrackingStep(totalSteps, index - 1)) this.classLine = 'line-hidden';
      else this.classLine = 'line-inactive';
    } else {
      this.classLine = 'line-active';
    }
    return this.classLine;
  }

  isLastTrackingStep(totalSteps: number, index: number): boolean {
    return totalSteps === index + 1;
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }
}
