import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CaflerBadgeSettingsModel } from '@cafler/common-ui';
import { ToppingSelectorModel } from 'src/app/models/fe/topping-selector.model';

@Component({
  selector: 'app-topping-selector',
  templateUrl: './topping-selector.component.html',
  styleUrls: ['./topping-selector.component.scss'],
  
})
export class ToppingSelectorComponent implements OnInit {

  isDisabled = true;

  isAdditionalInformationShown = false;

  @Input() settings: ToppingSelectorModel = <ToppingSelectorModel>{};
  @Output() productSelected: EventEmitter<any> = new EventEmitter<any>();
  
  iconClockSettings = { name: 'caf-clock-light', alt: 'clock' , width: '19px', height: '19px' };
  iconChevronDownBlueSettings = { name: 'caf-chevron-down-blue', alt: 'chevron down'};
  iconChevronUpBlueSettings = { name: 'caf-chevron-up-blue', alt: 'chevron up' };
  iconDarkGreenCheckSettings = { name: 'caf-check-dark-green', alt: 'check',  width: '12px', height: '11px'};
  badgeSettings = <CaflerBadgeSettingsModel>{
    id: 'newBadge',
    text: 'cafler.toppings.washing.new-label',
    color: 'green',
    size: 'small',
  }
  constructor() {}

  ngOnInit(): void {
    !this.settings.elementsAdded
      ? this.settings.elementsAdded = 0
      : this.settings.elementsAdded = this.settings.elementsAdded;
  }
  
  displayAdditionalInformation() {
    this.isAdditionalInformationShown = !this.isAdditionalInformationShown
  }

  selectTopping() {
    this.settings.checked = !this.settings.checked;

    this.productSelected.emit({
      clicked: this.settings.checked, 
      id: this.settings.id,
      code: this.settings.toppingCode});
  }
}
