import { Component, HostListener, Input } from '@angular/core';
import { CaflerIconSettingsModel } from '@cafler/common-ui';
import { NavigationService } from '../../services/navigation.service';
import { Store } from '@ngrx/store';
import { selectCurrentPage, selectIsB2B } from '../../store/selectors';
import { environment } from '../../../environments/environment';
import { continueWithNoToppings } from '../../store/actions';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent {
  @Input() isStepperShown!: boolean;
  mobileSize!: boolean;
  iconSettings = <CaflerIconSettingsModel>{
    name: 'caf-chevron-dark-left',
    alt: 'Back icon',
  };
  iconLogoSettings = <CaflerIconSettingsModel>{
    name: 'caf-logo-cafler-horizontal',
    alt: 'Cafler logo',
  };
  phoneIconDesktop = <CaflerIconSettingsModel>{
    name: 'caf-phone-dark',
    alt: 'Phone icon',
  };
  phoneIconMobile = <CaflerIconSettingsModel>{
    name: 'caf-phone-dark-circle',
    alt: 'Phone icon',
  };
  title: string = '';
  showBackButton!: boolean;
  showLogo!: boolean;
  showPhone!: boolean;
  isB2B!: boolean;
  currentPage: any;

  // eslint-disable-next-line new-cap
  @HostListener('window:resize', ['$event'])
  sizeChange(event: any) {
    this.mobileSize = event.target.outerWidth <= 768;
  }

  constructor(private navigationService: NavigationService, private store: Store) {
    this.store.select(selectIsB2B).subscribe((isB2B) => {
      this.isB2B = isB2B;
    });
    this.mobileSize = window.innerWidth <= 768;
    this.showPhone = true;
    
    this.navigationService.getCurrentPageTitle().subscribe((title) => (this.title = title));
    
    this.store.select(selectCurrentPage).subscribe((page) => {
      this.currentPage = page;
      if (this.isB2B) {
        if(page === 'my-services'){
          this.showPhone = false;
          this.showBackButton = false;
        }else{
          this.showBackButton = true;
          this.showLogo = false;
        }
      } else if (page === 'product-selection') {
        this.showBackButton = false;
        this.showLogo = true;
      } else {
        this.showBackButton = true;
        this.showLogo = false;
      }
    });
  }
  
  backButton(): void {  
    //WARNING - Every time you add a new page to the B2B app that doesn't go directly to my-services, you need to add it to this array. 
    //TODO Find a better way to do this.
    
    const b2bPagesAllowedGoBack = ['overview', 'checkout', 'lavado', 'pre-itv', 'no-appointment', 'valet', 'appointment','revision','my-services-details', 'maintenance-bundle', 'maintenance' ];
    
    if(this.isB2B && !b2bPagesAllowedGoBack.includes(this.currentPage)){
      this.navigationService.goBackToMyService()
      return
    }

    this.navigationService.back();
  }

  continueWithoutTopping() {
    // this.store.dispatch(continueWithNoToppings());
    this.store.dispatch(continueWithNoToppings());
  }
}
