import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ValetHowItWorksPopupService } from '../../components/valet-how-it-works-popup/service/wash-details.service';
import { PresentationFacade } from '../../facades/presentation.facade';
import { SingleDataHelperService } from '../../helpers/single-data-helper.service';
import { NavigationService } from '../../services/navigation.service';
import { KamikazeActions } from '../../store/actions';
import { selectIsVerticalProductsLoaded } from '../../store/selectors';
import { getStationValetDetails } from './valet.helper';

@Component({
  selector: 'app-valet-selector',
  templateUrl: './valet-selector.page.html',
  styleUrls: ['./valet-selector.page.scss']
})
export class ValetSelectorPage implements OnInit {

  fg!: FormGroup;
  productsSettings: any = {
    id: 'valet-selector',
    formControlName: 'productSelector',
    isTopping: false,
    products: [],
  };
  popUpRef: any;
  private readonly destroySubjects$ = new ReplaySubject<void>(1);

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private presentationFacade: PresentationFacade,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private singleDataHelperService: SingleDataHelperService,
    private valetHowItWorksPopupService: ValetHowItWorksPopupService,
  ) {
    const formControlName = this.productsSettings.formControlName;
    this.fg = new FormGroup({});
    this.fg.addControl(formControlName, new FormControl(null, [Validators.required]));
   }

  ngOnInit() {
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'valet-selector' });
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: false });
    this.navigationService.currentPageTitle = 'cafler.product.valet.vertical.title';

    this.store.select(selectIsVerticalProductsLoaded)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((productsLoaded) => {
      if (productsLoaded)
        this.singleDataHelperService.getCurrentVerticalProducts()
        .pipe(takeUntil(this.destroySubjects$))
        .subscribe((products: any) => {
          if(products){
            this.productsSettings.products = products.valet;
            this.productsSettings.products[0].moreDetails = true;
            this.productsSettings.products.push(...this.createDisabledProducts());
            
          }
        });
    });
  }

  pickProduct(page: string) {
    
    const currentProduct = this.productsSettings.products.find((product: any) => {
      return product.id === page;
    });
    this.store.dispatch({
      type: KamikazeActions.SetSelectedProduct,
      selectedProduct: currentProduct,
    });
    this.router
      .navigate([`${currentProduct?.alias}`], { relativeTo: this.activatedRoute })
      .then((s) => {
        this.navigationService.currentPageTitle = 'cafler.product.valet.vertical.title';
      });
  }

  showMoreDetails() {

    const popUpSettings = getStationValetDetails()
    
    this.popUpRef = this.valetHowItWorksPopupService.open(
      ''  ,
      popUpSettings,
    );
    this.popUpRef.afterClosed$.subscribe((res:any) => {
      this.valetHowItWorksPopupService.enableScroll();
    });
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();

    if(this.popUpRef){
      this.popUpRef.close();
    }
  }

  createDisabledProducts() {
    return [
      {
        "id": "18138476-d32b-4b67-9f5b-a71ba76d468e",
        "price": "",
        "fromPriceKey": "",
        "title": "cafler.product-type.valet-airport.title",
        "description": "cafler.product-type.valet-airport.description",
        "iconDesktop": "./assets/img/products/valet-airport-desktop.jpg",
        "iconMobile": "./assets/img/products/valet-airport-mobile.jpg",
        "alias": "valet-train-station",
        "alt": "cafler.product-type.valet-airport.title",
        "isPaymentRequired": true,
        "holidays": [],
        "ordersManagerProductId": "18138476-d32b-4b67-9f5b-a71ba76d467e",
        "category": "Valet",
        "disabled": true,
        "badgeType": {
          "text": "cafler.coming.soon",
          "color": "greenCafler",
          "icon": ""
      }
    },
    {
      "id": "18138476-d32b-4b67-9f5b-a71ba76d469e",
      "price": "",
      "fromPriceKey": "",
      "title": "cafler.product-type.valet-custom.title",
      "description": "cafler.product-type.valet-custom.description",
      "iconDesktop": "./assets/img/products/valet-custom-desktop.jpg",
      "iconMobile": "./assets/img/products/valet-custom-mobile.jpg",
      "alias": "valet-train-station",
      "alt": "cafler.product-type.valet-custom.title",
      "isPaymentRequired": true,
      "holidays": [],
      "ordersManagerProductId": "18138476-d32b-4b67-9f5b-a71ba76d467e",
      "category": "Valet",
      "disabled": true,
      "badgeType": {
        "text": "cafler.coming.soon",
        "color": "greenCafler",
        "icon": ""
    }
  }]
  }
}
