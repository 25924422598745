<section *ngIf="readytoLoad" class="my-service-container">
  <header class="my-service-header">
    <div class="title-container">
      <h2 *ngIf="serviceData.Products[0].LocalizationKey !== 'mot-homologation'">
        {{'cafler.my-service-details.' + verticalName | translate}}
      </h2>
      <h2 *ngIf="serviceData.Products[0].LocalizationKey === 'mot-homologation'">
        {{ "cafler.product-type." + serviceData.Products[0].LocalizationKey + ".title" | translate}}
      </h2>
      <p>
        {{'cafler.my-service-details.service-number.label' | translate}}: {{serviceData.OrderHash}}
      </p>
    </div>
    <div>
      <a
        class="download-pdf"
        [href]="pdfTrackingURL + '?caflerHash=' + serviceData.OrderHash + '&lang=' + lang"
      >
        <caf-icon [settings]="downloadIcon"></caf-icon>
        <span>{{'cafler.my-service-details.download-pdf.label' | translate}}</span>
      </a>
    </div>
  </header>
  <div *ngIf="isCancel" style="width: 472px; margin-bottom: 21px">
    <caf-notification [settings]="cancelNotification"></caf-notification>
  </div>
  <section class="m-s-details-page-container">
    <section class="column">
      <!-- info vehiculo -->
      <article class="m-s-details-card-container" style="width: 472px">
        <header class="detail-card__header">
          <h3>{{'cafler.my-service-details.your-vehicle.section.title' | translate}}</h3>
          <div class="line"></div>
        </header>
        <div>
          <h4>{{'cafler.my-service-details.your-vehicle.title' | translate}}</h4>
          <div class="m-s-details-vehicle-details__header-container">
            <div class="vehicle-chassis-icon__container">
              <caf-icon [settings]="vehicleChassisIcon"></caf-icon>
            </div>
            <div class="m-s-details-vehicle-details__info-container">
              <h4>{{serviceData.VehicleBrand}} {{serviceData.VehicleModel}}</h4>
              <div class="vehicle-info">
                <span> {{serviceData.LicensePlate}}</span>
                <span *ngIf="fuelType">&nbsp;|&nbsp;{{fuelType | translate}} </span>
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="m-s-details-service-data">
          <h3 class="title-padding">
            {{'cafler.my-service-details.service-data.title' | translate}}
          </h3>
          <div
            *ngIf="serviceData.VerticalType !== verticalsTypeEnum.VALET"
            class="info-product-container"
          >
            <div class="m-s-details-service-data__element">
              <caf-icon [settings]="calendarIcon"></caf-icon>
              <span>{{dateStartDateFormatted}}</span>
            </div>
            <div class="m-s-details-service-data__element">
              <caf-icon [settings]="timeIcon"></caf-icon>
              <span>{{timeRange}}</span>
            </div>
            <!-- homologation-->
            <div *ngIf="isMotHomologation" class="fuel-container">
              <caf-icon [settings]="periodicMotPassed"></caf-icon>
              <div>
                <span
                  >{{'cafler.page.overview.homologation.periodic-mot'| translate}} :
                  {{homologationConfig.HasCompletedRegularInspections?
                  ('cafler.overview.homologation.periodic-mot-passed' | translate) :
                  ('cafler.overview.homologation.periodic-mot-not-passed' | translate)}}
                </span>
              </div>
            </div>
            <div *ngIf="isMotHomologation" class="fuel-container">
              <caf-icon [settings]="gearIcon"></caf-icon>
              <div>
                <span
                  >{{'cafler.page.homologation.homologation-type.' + homologationConfig.ReformType |
                  translate}}</span
                >
              </div>
            </div>
            <div *ngIf="isMotHomologation" class="fuel-container">
              <caf-icon [settings]="PaperWithCheckIcon"></caf-icon>
              <div>
                <span>
                  {{'cafler.page.overview.homologation.documentation-ready' | translate}}:
                  {{homologationConfig.AreDocumentsAvailable?
                  ('cafler.page.overview.homologation.documentation-ready.ready' | translate) :
                  ('cafler.page.overview.homologation.documentation-ready.not-ready' | translate)}}
                </span>
              </div>
            </div>
            <!-- tow truck -->
            <div *ngIf="vehicleStatusText" class="fuel-container">
              <caf-icon [settings]="gearIcon"></caf-icon>
              <div>
                <span
                  >{{'cafler.page.overview.budget.vehicle-status' | translate}}: {{vehicleStatusText
                  | translate}}</span
                >
              </div>
            </div>
            <!-- gasolina -->
            <div *ngIf="fuelType" class="fuel-container">
              <caf-icon [settings]="fuelIcon"></caf-icon>
              <div>
                <span>{{fuelType | translate}}</span>
                <div class="flex-group">
                  <p>
                    {{'cafler.my-service-details.amountFuel' | translate}}:
                    {{productConfiguration.MonetaryAmount}}{{this.currency}}
                  </p>
                  <p class="iva-no-included">({{'cafler.tax-fee-included' | translate}})</p>
                </div>
              </div>
            </div>
            <div *ngIf="productConfiguration?.AppointmentCode" class="mot-info-container">
              <div class="m-s-details-service-data__element">
                <caf-icon [settings]="PaperWithCheckIcon" title="código de la cita"></caf-icon>
                <span> {{productConfiguration.AppointmentCode}} </span>
              </div>
              <div class="m-s-details-service-data__element">
                <caf-icon [settings]="creditCardIcon"></caf-icon>
                <span>
                  {{(isInspectionPaid ? 'cafler.my-service-details.inspection-paid.label' :
                  'cafler.my-service-details.inspection-not-paid.label') | translate}}
                </span>
              </div>
            </div>
            <div class="m-s-details-service-data__element">
              <caf-icon [settings]="locationIcon"></caf-icon>
              <span>{{serviceData.OriginAddress}}</span>
            </div>
            <div
              *ngIf="productConfiguration?.AppointmentCode"
              class="m-s-details-service-data__element"
            >
              <caf-icon [settings]="locationIcon"></caf-icon>
              <span>{{stationMotAddress}}</span>
            </div>
            <div *ngIf="serviceData.DestinationAddress" class="m-s-details-service-data__element">
              <caf-icon [settings]="locationIcon"></caf-icon>
              <span>{{serviceData.DestinationAddress}}</span>
            </div>
            <div *ngIf="distance" class="m-s-details-service-data__element">
              <caf-icon [settings]="DistanceIcon"></caf-icon>
              <span>{{'cafler.distance' | translate}} {{distance}} {{distanceUnit}}</span>
            </div>
          </div>
          <!-- VALET -->
          <div
            *ngIf="serviceData.VerticalType === verticalsTypeEnum.VALET"
            class="info-product-container"
          >
            <div class="valet-info-container">
              <caf-icon [settings]="calendarIcon"></caf-icon>
              <div class="valet-info">
                <span>{{"cafler.my-service-details.valet.pickup.label" | translate}}</span>
                <span>{{dateStartDateFormatted}}</span>
                <span>{{pickupTimeString}}</span>
              </div>
            </div>
            <div class="valet-info-container">
              <caf-icon [settings]="calendarIcon"></caf-icon>
              <div class="valet-info">
                <span>{{"cafler.my-service-details.valet.dropoff.label" | translate}}</span>
                <span>{{dateEndDateFormatted}}</span>
                <span>{{dropoffTimeString}}</span>
              </div>
            </div>
            <div class="valet-info-container">
              <caf-icon [settings]="locationIcon"></caf-icon>
              <span>{{stationName}}</span>
            </div>
          </div>
        </div>

        <div *ngIf="serviceData.Comments" class="comment-displayer" (click)="displayComment()">
          <div class="line"></div>
          <div class="comment-section__container">
            <h3>{{ 'cafler.my-service-include.comment.label' | translate }}</h3>
            <caf-icon
              class="select-icon pointer"
              [class]="{ 'rotate-chevron': isCommentShown }"
              [settings]="iconChevronDownBlueSettings"
            ></caf-icon>
          </div>
          <div class="comment-content" *ngIf="isCommentShown">
            <div class="comment-content__box">
              <p>{{serviceData.Comments}}</p>
            </div>
          </div>
        </div>
      </article>
      <!-- lista comprobación -->
      <section
        *ngIf="serviceData.HasChecklist"
        class="m-s-details-card-continer checkList-container"
      >
        <span>{{'cafler.my-service-details.checklist.title' | translate}}</span>
        <caf-button
          (onClick)="displayCheckList()"
          class="caf-btn-supplementary caf-btn-desk-small caf-btn-mob-small ng-star-inserted"
          [settings]="buttonSettings"
        ></caf-button>
      </section>
      <!-- detalles vehiculo sustitución-->
      <article
        *ngIf="serviceData.VerticalType === verticalsTypeEnum.TRANSFER && serviceData.Products[1]?.Configuration?.AmountOfDays > 0"
        class="m-s-details-card-container"
        style="width: 472px"
      >
        <div class="m-s-details-service-data">
          <header class="detail-card__header">
            <h3 class="title-padding">{{'Datos del vehículo de sustitución' | translate}}</h3>
            <div class="line"></div>
          </header>
          <div class="info-product-container">
            <h3>{{"cafler.my-services-detail-pickup" | translate }}</h3>

            <div class="m-s-details-service-data__element">
              <caf-icon [settings]="calendarIcon"></caf-icon>
              <span>{{dateStartDateFormatted}}</span>
            </div>
            <div class="m-s-details-service-data__element">
              <caf-icon [settings]="timeIcon"></caf-icon>
              <span>{{timeRange}}</span>
            </div>
            <div class="m-s-details-service-data__element">
              <caf-icon [settings]="locationIcon"></caf-icon>
              <span>{{serviceData.OriginAddress}}</span>
            </div>
            <h3>{{"cafler.my-services-detail-delivery" | translate }}</h3>
            <div class="m-s-details-service-data__element">
              <caf-icon [settings]="calendarIcon"></caf-icon>
              <span>{{replacementReturnDate}}</span>
            </div>
            <div class="m-s-details-service-data__element">
              <caf-icon [settings]="timeIcon"></caf-icon>
              <span>08:00h - 20:00h</span>
            </div>
            <div class="m-s-details-service-data__element">
              <caf-icon [settings]="locationIcon"></caf-icon>
              <span>{{serviceData.OriginAddress}}</span>
            </div>
            <h3>{{"cafler.my-services-detail-driver" | translate}}</h3>
            <!-- Si el driver es diferente se lo indicamos, sino será el contacto de recogida-->
            <div *ngIf="serviceData.Products[1].Configuration.ContactName; else sameDriver">
              <div class="m-s-details-service-data__element">
                <caf-icon [settings]="driverIcon"></caf-icon>
                <span>{{serviceData.Products[1].Configuration.ContactName}}</span>
              </div>
              <div class="m-s-details-service-data__element">
                <caf-icon [settings]="phoneIcon"></caf-icon>
                <span>{{serviceData.Products[1].Configuration.ContactPhoneNumber}}</span>
              </div>
              <div class="m-s-details-service-data__element">
                <caf-icon [settings]="envelopeIcon"></caf-icon>
                <span>{{serviceData.Products[1].Configuration.ContactEmailAddress}}</span>
              </div>
            </div>
            <ng-template #sameDriver>
              <div class="m-s-details-service-data__element">
                <caf-icon [settings]="driverIcon"></caf-icon>
                <span>{{serviceData.OriginContactName}}</span>
              </div>
              <div class="m-s-details-service-data__element">
                <caf-icon [settings]="phoneIcon"></caf-icon>
                <span>{{serviceData.OriginContactPhoneNumber}}</span>
              </div>
              <div class="m-s-details-service-data__element">
                <caf-icon [settings]="envelopeIcon"></caf-icon>
                <span>{{serviceData.OriginContactEmailAddress}}</span>
              </div>
            </ng-template>
          </div>
        </div>
      </article>
      <!-- lista servicios -->
      <section *ngIf="isPaymentDetailsDisplay; else noPayment" class="service-breakdown">
        <h3>{{"cafler.my-service-details.service-breakdown.title" | translate}}</h3>

        <!-- main product -->
        <div *ngIf="mainProduct.Price !== 0">
          <div class="service-breakdown__product-price">
            <span
              >{{(mainProduct.LocalizationKey === null ||mainProduct.LocalizationKey === "") ?
              mainProduct.ProductName: "cafler.product-type." + mainProduct.LocalizationKey+
              ".title" | translate }}</span
            >
            <span>{{this.customCurrencyPipe.convert(mainProduct.PriceWithoutTax, currency)}}</span>
          </div>

          <div
            *ngIf="mainProduct.Configuration?.MonetaryAmount"
            class="service-breakdown__product-price"
          >
            <span
              >{{"cafler.product-type.refuel." + mainProduct.Configuration.FuelType + ".title" |
              translate}}</span
            >
            <span>{{this.customCurrencyPipe.convert(fuelAmount, currency)}}</span>
          </div>
        </div>
        <!-- Toppings -->
        <div *ngFor="let item of toppings">
          <div *ngIf="!item.Configuration?.MonetaryAmount" class="service-breakdown__product-price">
            <div class="flex-coloum">
              <span class="topping-title"
                >{{(item.LocalizationKey === null || item.LocalizationKey === "") ? item.ProductName
                : "cafler.overview." + item.LocalizationKey + ".title" | translate }}</span
              >
              <span
                >{{(item.LocalizationKey === null || item.LocalizationKey === "") ? null :
                "cafler.overview." + item.LocalizationKey + ".detail" | translate }}</span
              >
            </div>
            <span>{{this.customCurrencyPipe.convert(item.PriceWithoutTax, currency)}}</span>
          </div>
          <div *ngIf="item.Configuration?.MonetaryAmount" class="service-breakdown__product-price">
            <div class="flex-coloum">
              <span class="topping-title"
                >{{(item.LocalizationKey === null || item.LocalizationKey === "") ?
                item.ProductName: "cafler.overview." + item.LocalizationKey + "."+
                vehicleFuelTypesEnum[item.Configuration.FuelType].charAt(0) +
                vehicleFuelTypesEnum[item.Configuration.FuelType].slice(1).toLowerCase() + ".title"
                | translate }}</span
              >
              <span class="topping-detail"
                >{{(item.LocalizationKey === null || item.LocalizationKey === "") ?
                item.ProductName: "cafler.overview." + item.LocalizationKey + "."+
                vehicleFuelTypesEnum[item.Configuration.FuelType].charAt(0) +
                vehicleFuelTypesEnum[item.Configuration.FuelType].slice(1).toLowerCase() + ".detail"
                | translate }}</span
              >
            </div>
            <div class="flex-coloum" style="align-items: flex-end">
              <span>{{this.customCurrencyPipe.convert(item.PriceWithoutTax, currency)}}</span>
              <span
                >{{this.customCurrencyPipe.convert(item.Configuration?.FreeTaxMonetaryAmount,
                currency)}}</span
              >
            </div>
          </div>
        </div>

        <div class="service-breakdown__product-price" *ngIf="productConfiguration?.MonetaryAmount">
          <span
            >{{"cafler.product-type.refuel." + productConfiguration.FuelType + ".title" |
            translate}}</span
          >
          <span>{{this.customCurrencyPipe.convert(fuelAmount, currency)}}</span>
        </div>
        <div
          class="service-breakdown__product-price"
          *ngIf="serviceData.ServicePriceWithoutTax && serviceData.ServicePriceWithoutTax > 0"
        >
          <span>{{"cafler.my-service-details.cafler-fee.label" | translate}}</span>
          <span
            >{{this.customCurrencyPipe.convert(serviceData.ServicePriceWithoutTax, currency)}}</span
          >
        </div>
        <div
          class="service-breakdown__product-price"
          *ngIf="serviceData.FixedTransferPriceWithoutTax && serviceData.FixedTransferPriceWithoutTax > 0"
        >
          <span>{{"cafler.page.overview.pricing.specific-hour-cafler" | translate}}</span>
          <span
            >{{this.customCurrencyPipe.convert(serviceData.FixedTransferPriceWithoutTax,
            currency)}}</span
          >
        </div>
        <div
          *ngIf="serviceData.DiscountAmount && serviceData.DiscountAmount > 0"
          class="service-breakdown__product-price"
        >
          <span
            >{{"cafler.my-service-details.discountCode.label" | translate}}:
            {{serviceData.DiscountCode}}</span
          >
          <span class="discountAmount"
            >-{{this.customCurrencyPipe.convert(serviceData.DiscountAmount, currency)}}</span
          >
        </div>
        <div>
          <div class="service-breakdown__product-price">
            <span>{{ "cafler.product-type.iva.title" | translate}} {{taxFee * 100 + "%"}}</span>
            <span>{{this.customCurrencyPipe.convert(taxAmount, currency)}}</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="service-breakdown__product-price">
          <span>{{ "cafler.page.overview.pricing.total" | translate}}</span>
          <span>{{this.customCurrencyPipe.convert(serviceData.OrderPrice, currency)}}</span>
        </div>
      </section>
      <ng-template #noPayment>
        <div class="service-breakdown">
          <h3>{{'cafler.my-service-details.noPayment.title' | translate}}</h3>
          <p>{{'cafler.my-service-details.noPayment.description' | translate}}</p>
        </div>
      </ng-template>
      <a
        *ngIf="isServiceCancelable(serviceData.OrderStatus)"
        class="cancel-link"
        (click)="redirectToCancelPopup()"
        >{{"cafler.cancel.service.dropdown.text" | translate}}</a
      >
    </section>
    <section class="column">
      <info-container title="{{'cafler.my-service-details.tracking.title' | translate}}">
        <tracking-display></tracking-display>
      </info-container>
      <info-container title="{{'cafler.my-service-details.photo-gallery.title' | translate}}">
        <div [ngSwitch]="photoDisplayOptions">
          <section *ngSwitchCase="'noDisplay'" class="maintenance">
            <img
              [src]="emptyStateImgUrl"
              alt="servicio en mantenimiento"
              width="180px"
              height="145px"
            />
            <div class="maintenance__text-container">
              <p class="maintenance__title">
                {{"cafler.my-service-details.photo-gallery.maintenance.title" | translate}}
              </p>
              <p class="maintenance__description">
                {{ "cafler.my-service-details.photo-gallery.maintenance.description" | translate}}
              </p>
            </div>
          </section>

          <section *ngSwitchCase="'display'">
            <div
              *ngIf="pickupPhotoGallerySettings.images.length > 0 || dropoffPhotoGallerySettings.images.length > 0; else galleryEmptyState "
            >
              <div>
                <div class="gallery-title-container">
                  <caf-icon [settings]="simpleKeyIcon"></caf-icon>
                  <h5>{{"cafler.my-service-details.photo-gallery.pickup-vehicle" | translate}}</h5>
                </div>
                <photo-gallery
                  *ngIf="pickupPhotoGallerySettings.images.length > 0"
                  class="img-comp"
                  [settings]="pickupPhotoGallerySettings"
                ></photo-gallery>
              </div>
              <div>
                <div class="gallery-title-container">
                  <caf-icon [settings]="pinIcon"></caf-icon>
                  <h5>{{"cafler.my-service-details.photo-gallery.dropoff-vehicle" | translate}}</h5>
                </div>
                <photo-gallery
                  *ngIf="dropoffPhotoGallerySettings.images.length > 0; else dropoffPhotosEmptyState "
                  class="img-comp"
                  [settings]="dropoffPhotoGallerySettings"
                ></photo-gallery>
                <ng-template #dropoffPhotosEmptyState>
                  <div class="gallery-empty-state__container">
                    <div class="gallery-empty-state__header">
                      <div class="gallery-empty-state__img-container">
                        <caf-icon [settings]="galleryEmptyStateIcon"></caf-icon>
                      </div>
                      <p>
                        {{'cafler.my-service-details.photo-gallery.empty-state.dropoff.text' |
                        translate}}
                      </p>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
            <ng-template #galleryEmptyState>
              <div class="gallery-empty-state__container">
                <div class="gallery-empty-state__header">
                  <div class="vehicle-chassis-icon__container gallery-empty-state__img-container">
                    <caf-icon [settings]="galleryEmptyStateIcon"></caf-icon>
                  </div>
                  <p>{{'cafler.my-service-details.photo-gallery.empty-state.text' | translate}}</p>
                </div>
              </div>
            </ng-template>
          </section>

          <section *ngSwitchCase="'transitalia'" class="maintenance">
            <img
              [src]="emptyStateImgUrl"
              alt="servicio en mantenimiento"
              width="180px"
              height="145px"
            />
            <div class="maintenance__text-container">
              <h4 class="maintenance__title">
                {{"cafler.my-service-details.photo-gallery.maintenance.transitalia.title" |
                translate}}
              </h4>
              <p class="maintenance__description">
                {{ "cafler.my-service-details.photo-gallery.maintenance.transitalia.description" |
                translate}}
              </p>
            </div>
          </section>
        </div>
      </info-container>
    </section>
  </section>
</section>
