import { Component, Input, OnInit } from '@angular/core';
import { PopupHowItWorksModel } from './popup-how-it-works.model';

@Component({
  selector: 'popup-how-it-works',
  templateUrl: './popup-how-it-works.component.html',
  styleUrls: ['./popup-how-it-works.component.scss'],
})
export class PopupHowItWorksComponent implements OnInit {
  @Input() settings!: PopupHowItWorksModel;

  constructor() {}

  ngOnInit(): void {}
}
