import { Component, Input, OnInit } from '@angular/core';
import { CaflerIconSettingsModel } from '@cafler/common-ui';

@Component({
  selector: 'progress-service-bar',
  templateUrl: './progress-service-bar.component.html',
  styleUrls: ['./progress-service-bar.component.scss']
})
export class ProgressServiceBarComponent implements OnInit {

  @Input() settings: any = <any>{};
  
  icon!: CaflerIconSettingsModel
  text!: string

  constructor() { }

  ngOnInit(): void {
    this.icon = this.getStatusIcon(this.settings.status)
    this.text = this.getTextByStatus(this.settings.status)
  }

  getStatusIcon(status: string){
    const stateIcon = <CaflerIconSettingsModel>{
      name: '',
      alt: '',
    };
    
    switch (status) {
      case 'PickingUp':
        stateIcon.name = 'caf-key-3D';
        stateIcon.alt = 'picking up';
        break;
      case 'InProgress':
        stateIcon.name = 'caf-file-3D';
        stateIcon.alt = 'in progress';
        break;
      case 'Delivering':
          stateIcon.name = 'caf-drivers-hat-3D';
          stateIcon.alt = 'picking up';
          break;
      case 'Finished':
        stateIcon.name = 'caf-pin-3D';
        stateIcon.alt = 'finished';
        break;
      default:
        stateIcon.name = 'caf-pin-3D';
        stateIcon.alt = 'pin';
        break;
    }

    return stateIcon;
  }

  getTextByStatus(status: string){
    
    if(['Initialized', 'Pending','Confirmed'].includes(status)){
      return 'cafler.progress-service-bar.status.pending.text';
    }
    if('PickingUp' === status){
      return 'cafler.progress-service-bar.status.picking-up.text';
    }
    if('InProgress' === status){
      return 'cafler.progress-service-bar.status.in-progress.text';
    }
    if('Delivering' === status){
      return 'cafler.progress-service-bar.status.delivering.text';
    }
    if('Finished' === status){
      return 'cafler.progress-service-bar.status.finished.text';
    }

    return ''
  }
}
