<div class="menu-card"  
    (click)="selectCard(settings.category)" 
    [class]="{'selected-elements': settings.elementsAdded.length > 0}">
    <div class="first-row">
        <h2 class="menu-title">
            {{ settings.title | translate }}
        </h2>
        <div class="arrow-container">
            <caf-icon class="arrow" [settings]="iconArrowSettings"></caf-icon>
        </div>
    </div>
    <div *ngIf="settings.elementsAdded.length > 0" class="extra-elements">
        <caf-icon [settings]="iconDarkGreenCheckSettings"></caf-icon>
        <div *ngIf="settings.elementsAdded.length === 1; else pluralElementsAdded">
            <p>{{settings.elementsAdded.length}} {{'cafler.toppings.extra-added-singular' | translate}}</p>
        </div>
        <ng-template #pluralElementsAdded>
            <p>{{settings.elementsAdded.length}} {{'cafler.toppings.extra-added-plural' | translate}}</p>
        </ng-template>  
    </div>
    <div class="last-row">
        {{ settings.description | translate}}
    </div>   
</div>