export enum ServicesStateEnum {
    INITIALIZED = 'Initialized',
    PENDING = 'Pending',
    CONFIRMED = 'Confirmed',
    PICKING_UP = 'PickingUp',
    IN_PROGRESS = 'InProgress',
    DELIVERING = 'Delivering',
    FINISHED = 'Finished',
    CANCELLED = 'Cancelled',
    CANCELLED_BY_CLIENT = 'CancelledByClient',
    CANCELLED_BY_CAFLER = 'CancelledByCafler',
}