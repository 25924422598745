import { MetaReducer } from '@ngrx/store';
import { hydrationMetaReducer } from './hydration.reducer';
import { KAMIKAZE_STATE_NAME } from './state';
import { kamikazeReducer } from './reducers';
import { KamikazeEffects } from './effects';

export const reducers = { [KAMIKAZE_STATE_NAME]: kamikazeReducer };

export const effects = [KamikazeEffects];

export const metaReducers: MetaReducer[] = [hydrationMetaReducer];
