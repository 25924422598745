import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { isDniValid } from "./validatorsHelper";

const LETTER_EXPECTED = 'TRWAGMYFPDXBNJZSQVHLCKE'


export function dniValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const dni = control.value;

    const result = isDniValid(dni)
      ? null
      : { invalidDNIDocumentNumber: true };

    return result;
  };
}