import { ToppingEnum } from "../../Enums/topping.enum";

const handWash_interiorIncompatibles = [
    ToppingEnum.handWash_complete, 
    ToppingEnum.expressWash_interior, 
    ToppingEnum.expressWash_complete,
    ToppingEnum.tunnelWash_interior,
    ToppingEnum.tunnelWash_complete,
]

const handWash_exteriorIncompatibles = [
    ToppingEnum.handWash_complete,
    ToppingEnum.expressWash_exterior, 
    ToppingEnum.expressWash_complete, 
    ToppingEnum.tunnelWash_exterior,
    ToppingEnum.tunnelWash_complete,
]

const handWash_completeIncompatibles = [
    ToppingEnum.handWash_interior, 
    ToppingEnum.handWash_exterior, 
    ToppingEnum.expressWash_interior,
    ToppingEnum.expressWash_exterior,
    ToppingEnum.expressWash_complete,
    ToppingEnum.tunnelWash_interior,
    ToppingEnum.tunnelWash_exterior,
    ToppingEnum.tunnelWash_complete,
]

const expressWash_interiorIncompatibles = [
    ToppingEnum.expressWash_complete,      
    ToppingEnum.handWash_interior, 
    ToppingEnum.handWash_complete,
    ToppingEnum.tunnelWash_interior,
    ToppingEnum.tunnelWash_complete,
]

const expressWash_exteriorIncompatibles = [
    ToppingEnum.expressWash_complete,
    ToppingEnum.handWash_exterior, 
    ToppingEnum.handWash_complete, 
    ToppingEnum.tunnelWash_exterior,
    ToppingEnum.tunnelWash_complete,
]

const expressWash_completeIncompatibles = [
    ToppingEnum.expressWash_interior,
    ToppingEnum.expressWash_exterior,
    ToppingEnum.handWash_interior, 
    ToppingEnum.handWash_exterior,
    ToppingEnum.handWash_complete,
    ToppingEnum.tunnelWash_interior,
    ToppingEnum.tunnelWash_exterior,
    ToppingEnum.tunnelWash_complete,
]

const tunnelWash_exteriorIncompatibles = [
    ToppingEnum.expressWash_exterior,
    ToppingEnum.expressWash_complete,
    ToppingEnum.handWash_exterior,
    ToppingEnum.handWash_complete,
    ToppingEnum.tunnelWash_interior,
    ToppingEnum.tunnelWash_complete,
]

const tunnelWash_interiorIncompatibles = [ 
    ToppingEnum.expressWash_interior,
    ToppingEnum.expressWash_complete,
    ToppingEnum.handWash_interior,
    ToppingEnum.handWash_complete,
    ToppingEnum.tunnelWash_exterior,
    ToppingEnum.tunnelWash_complete,
]

const tunnelWash_completeIncompatibles = [
    ToppingEnum.expressWash_interior,
    ToppingEnum.expressWash_exterior,
    ToppingEnum.expressWash_complete,
    ToppingEnum.handWash_interior,
    ToppingEnum.handWash_exterior,
    ToppingEnum.handWash_complete,
    ToppingEnum.tunnelWash_interior,
    ToppingEnum.tunnelWash_exterior,
]

export const washToppingIncompatibleConfig = new Map<string, string[]>([
    [ToppingEnum.handWash_interior, handWash_interiorIncompatibles],
    [ToppingEnum.handWash_exterior, handWash_exteriorIncompatibles],
    [ToppingEnum.handWash_complete, handWash_completeIncompatibles],
    [ToppingEnum.expressWash_interior, expressWash_interiorIncompatibles],
    [ToppingEnum.expressWash_exterior, expressWash_exteriorIncompatibles],
    [ToppingEnum.expressWash_complete, expressWash_completeIncompatibles],
    [ToppingEnum.tunnelWash_exterior, tunnelWash_exteriorIncompatibles],
    [ToppingEnum.tunnelWash_interior, tunnelWash_interiorIncompatibles],
    [ToppingEnum.tunnelWash_complete, tunnelWash_completeIncompatibles],
]);