import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CaflerBadgeSettingsModel } from '@cafler/common-ui';
import { Topping } from 'src/app/models';

interface PriceBreakdown {
  label: string;
  value: string;
  days?: number;
  amount?: string;
}

interface Tax{
  label: string;
  value: string;
  zoneTax: number;
}

@Component({
  selector: 'price-breakdown',
  templateUrl: './price-breakdown.component.html',
  styleUrls: ['./price-breakdown.component.scss']
})
export class PriceBreakdownComponent implements OnInit {

  @Input() priceBreakdown: PriceBreakdown[] = [];
  @Input() toppings:Topping[] = [];
  @Input() taxes: Tax | null = null;
  @Output() removeToppingEvent: EventEmitter<string> = new EventEmitter<any>();

  badge = <CaflerBadgeSettingsModel>{
    text: 'cafler.topping.extra.badge-button',
  }
  iconExitSettings = { name: 'caf-exit', alt: 'exit', height: '8px', width: '8px' };

  constructor() {}

  ngOnInit(): void {}

  removeTopping(toppingId: string) {
    this.removeToppingEvent.emit(toppingId);
  }

}
