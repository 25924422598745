import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { KamikazeActions } from '../../store/actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(private navigationService: NavigationService, private store: Store) {}

  ngOnInit(): void {
    this.navigationService.currentPageTitle = '';
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: false });
  }
}
