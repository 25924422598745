import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectBookedOrder, selectCoreData,selectCurrentCountry,selectCurrentVerticalProducts,selectIsB2B, selectProductData, selectProductType, selectSelectedProduct, selectStations} from '../store/selectors';
import { DatePipe } from '@angular/common';
import { BEEnumItemModel, BEEnumModel } from '../models';
import { CustomCurrencyPipe } from '../pipes/currency.pipe';
import { HelperService } from '../services/helper.service';
import { OverviewInfo } from '../models/fe/Overview/OverviewInfo';
import { InfoCard } from '../models/fe/Overview/InfoCard';
import { TimeFormatterService } from '../helpers/time-formatter.service';
import * as moment from 'moment';
import { DistanceUnit } from '../models/distanceUnit';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { getDistanceUnitPerCountryRegionIsoCode } from '../helpers/UnitsPerCountry';
import { DistanceUnitCodes } from '../Enums/unit.map';
import { CountryIsoCode } from '../Enums/countryIsoCode.enum';
import { convertMetersToKilometers, convertMetersToMiles } from '../helpers/converter.service';

@Injectable()
export class OverviewFacade {
  private readonly destroySubjects$ = new ReplaySubject<void>(1);
  
  currentProductType: string = '';
  isB2B: boolean = false;
  productData: any = {};
  bookedOrder: any = {};
  coreData: any = {};
  motStations: any = {};
  currentVerticalProducts: any = {};
  selectedProduct: any;
  stations: any = [];
  currentCountry: any;

  constructor(
    private store: Store,
    private datePipe: DatePipe,
    private customCurrencyPipe: CustomCurrencyPipe,
    private helperService: HelperService,
  ) {
    this.store.select(selectProductType)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((currentProduct) => {
      this.currentProductType = currentProduct;
    });
    this.store.select(selectIsB2B)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((isB2B) => {
      this.isB2B = isB2B;
    });
    this.store.select(selectSelectedProduct)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((selectedProduct) => {
      this.selectedProduct = selectedProduct;
    });
    this.store.select(selectProductData)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((productData) => {
      this.productData = productData;
    });
    this.store.select(selectBookedOrder)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((bookedOrder) => {
      this.bookedOrder = bookedOrder;
    });
    this.store.select(selectCoreData)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((coreData) => {
      this.coreData = coreData;
    });

    this.store.select(selectCurrentVerticalProducts)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((currentVerticalProducts) => {
      this.currentVerticalProducts = currentVerticalProducts;
    });
    
    this.store.select(selectCurrentCountry)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((country: any) => {
      this.currentCountry = country;
    });
  
  }
 
  private getProduct(productData: any, typeOfCategory: string): any {
    if(!productData.serviceType){
      return null;
    }
    let product = null;

    switch(typeOfCategory?.length){
      case 1:
        product = this.currentVerticalProducts[this.selectedProduct.category].find(
          (p: any) => p.OrdersManagerId === productData.serviceType,
        );
      break;
      case 2:
        product = this.currentVerticalProducts[typeOfCategory[0]][typeOfCategory[1]].find(
          (p: any) => p.OrdersManagerId === productData.serviceType,
        );
      break;
      default:
        product = null;
    }

    return product;

  }

  getOverviewByProductType(): any {
    if (!this.productData && !this.bookedOrder) return {};

    const typeOfCategory = this.selectedProduct.category?.split('-'); 
    const productData = this.productData;
    const bookedOrder = this.bookedOrder;

    let product = this.getProduct(productData, typeOfCategory);

    const overviewInfoBuilder = new OverviewInfoBuilder(this.isB2B, this.datePipe, this.helperService,  new TimeFormatterService(), this.customCurrencyPipe)
    const productCreator = new ProductCreator(productData, bookedOrder, overviewInfoBuilder, new TimeFormatterService(), this.coreData, product, this.store, this.helperService, this.isB2B, this.currentCountry);
    
    switch(this.currentProductType){
      case("transfer"):
        return productCreator.buildTransfer();
      case("mot"):
        if(this.productData.station){
          return productCreator.buildMotWithAppointment();
        }
        if(this.productData.homologationType){
          return productCreator.buildMotWithHomologation();
        }
        return productCreator.buildMot();
      case("wash"):
        return productCreator.buildWash();
      case("revision"):
        return productCreator.buildMaintenance();
      case("pre-mot"):
        return productCreator.buildPreMot();
      case("medium-long-distance"):
        return productCreator.buildMediumLongDistance();
      case("refueling"):
        return productCreator.buildRefueling();
      case("valet"):
        return productCreator.buildValet();
      case("transfer+replacement"):
        return productCreator.buildTransferReplacement();
      case("tow-truck"):
        return productCreator.buildTowTruck();
      case("all-you-need"):
        return productCreator.buildAllYouNeed();
    }
  } 

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }


}

class ProductCreator {

  private productData: any;
  private bookedOrder: any;
  private overviewInfoBuilder : OverviewInfoBuilder;

  constructor(productData: any, bookedOrder: any, overviewInfoBuilder : OverviewInfoBuilder, 
     private timeFormatterService: TimeFormatterService,
     private coreData: any,
     private product: any,
     private store: Store,
     private helperService: HelperService,
     private isB2B: boolean,
     private currentCountry: any

    ){


    this.productData = productData;
    this.bookedOrder = bookedOrder;
    this.overviewInfoBuilder = overviewInfoBuilder;

  }

  buildTransferReplacement(){

    let timeString = this.timeFormatterService.getTimeRangeString(this.productData.startPickUpTime, this.productData.endPickUpTime);
    let isHourSpecific = this.productData.endPickUpTime === null;
    
    this.overviewInfoBuilder.setInfoContact(this.productData.fullName, this.productData.countryPhoneCode,this.productData.phone, this.productData.email);
    this.overviewInfoBuilder.setInfoDropoffContact(this.productData.dropoffFullName, this.productData.dropoffCountryPhoneCode, this.productData.dropoffPhone, this.productData.dropoffEmail);
    this.overviewInfoBuilder.setInfoVehicle(this.productData.carPlaque, this.productData.brand, this.productData.model, this.productData.vehicleType.Name);
    this.overviewInfoBuilder.setInfoPickUp('cafler.page.overview.pickup-address.placeholder', this.productData.pickupAddress.text, this.productData.pickupAddressDetails, this.productData.pickupDate, timeString);
    this.overviewInfoBuilder.setInfoDropOff('cafler.overview.transfer-replacement-dropoff.title', this.productData.deliveryAddress.text, this.productData.deliveryAddressDetails);
    this.overviewInfoBuilder.setInfoReplacementPickup('cafler.page.media-larga-distancia.directions.destination.placeholder', this.productData.pickupAddress.text, this.productData.pickupAddressDetails, this.productData.pickupDate, this.productData.startPickUpTime, this.productData.endPickUpTime);
    this.overviewInfoBuilder.setInfoReplacementDropOff('cafler.page.media-larga-distancia.directions.destination.placeholder', this.productData.pickupAddress.text, this.productData.pickupAddressDetails, this.productData.pickupDate, this.productData.daysReplacement);
    
    if(this.productData.replacementDriver === 'sameDriver'){
      this.overviewInfoBuilder.setInfoDriver(this.productData.fullName, this.productData.countryPhoneCode,this.productData.phone, this.productData.email);
    } else {
      this.overviewInfoBuilder.setInfoDriver(this.productData.driverFullName, this.productData.driverCountryPhoneCode,this.productData.driverPhone, this.productData.driverEmail);
    }
   
    const currencySymbol = this.bookedOrder?.ProductPrice?.CurrencySymbol;
    const totalPrice = this.bookedOrder?.OrderPrice?.TotalPrice;
    let productPrice = 0
    let feePrice = 0
    let fixedTransferPrice = this.bookedOrder?.FixedTransferPrice;

    if(this.isB2B){
      const zoneTax = this.bookedOrder?.ZoneTax
      const taxFee = this.bookedOrder?.OrderPrice?.TaxFreePrice * zoneTax 

      productPrice = this.bookedOrder?.ProductPrice?.TaxFreePrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TaxFreePrice;
      
      this.overviewInfoBuilder.setTaxFeePrice('iva', zoneTax, taxFee, currencySymbol);
      fixedTransferPrice = this.bookedOrder?.FreeTaxFixedTransferPrice 

    }else{
      productPrice = this.bookedOrder?.ProductPrice?.TotalPrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TotalPrice;
    }

    let distance = '';

    if(this.currentCountry?.RegionIsoCode === CountryIsoCode.GBR){
      distance = convertMetersToMiles(this.bookedOrder.Distance).toFixed(2);
    }else{
      distance = convertMetersToKilometers(this.bookedOrder.Distance).toFixed(2);
    }

    const unit: DistanceUnit = getDistanceUnitPerCountryRegionIsoCode(this.currentCountry?.RegionIsoCode);
    const unitCode: string = DistanceUnitCodes[unit]

    this.overviewInfoBuilder.setInfoDistance(distance, unitCode);

    // hardcoded transfer title because the merge of transfer vertical with replacement
    // this.overviewInfoBuilder.setInfoProdcuctPrice('transfer', productPrice, currencySymbol);
    this.overviewInfoBuilder.setInfoProductWithReplacementPrice(this.bookedOrder.AllProductsBooked, currencySymbol, this.productData.daysReplacement);
    
    if(isHourSpecific){
      this.overviewInfoBuilder.setInfoFixedPrice(fixedTransferPrice, currencySymbol);
    }

    this.overviewInfoBuilder.setInfoTotalPrice(totalPrice, currencySymbol);

  
    return this.overviewInfoBuilder.getProduct();


  }

  buildWash(){
    
    const pickupAddressText = 'cafler.page.overview.pickup-address.placeholder';
    let timeString = this.timeFormatterService.getTimeRangeString(this.productData.startPickUpTime, this.productData.endPickUpTime);
    let isHourSpecific = this.productData.endPickUpTime === null;

    this.overviewInfoBuilder.setInfoContact(this.productData.fullName, this.productData.countryPhoneCode,this.productData.phone, this.productData.email);
    this.overviewInfoBuilder.setInfoVehicle(this.productData.carPlaque, this.productData.brand, this.productData.model)
    this.overviewInfoBuilder.setInfoPickUp(pickupAddressText,this.productData.pickupAddress.text, this.productData.pickupAddressDetails, this.productData.pickupDate, timeString)
    
    if(this.productData.dropoffAddress){
      this.overviewInfoBuilder.setInfoDropOff('cafler.page.overview.pickup-details.droppoff-address.label',this.productData.dropoffAddress.text, this.productData.dropoffAddressDetails)
    }

    const currencySymbol = this.bookedOrder?.ProductPrice?.CurrencySymbol;
    const totalPrice = this.bookedOrder?.OrderPrice?.TotalPrice;
    let fixedTransferPrice = this.bookedOrder?.FixedTransferPrice;

    let productPrice = 0
    let feePrice = 0

    if(this.isB2B){
      const zoneTax = this.bookedOrder?.ZoneTax
      const taxFee = this.bookedOrder?.OrderPrice?.TaxFreePrice * zoneTax 

      productPrice = this.bookedOrder?.ProductPrice?.TaxFreePrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TaxFreePrice;
      fixedTransferPrice = this.bookedOrder?.FreeTaxFixedTransferPrice 
 
      this.overviewInfoBuilder.setTaxFeePrice('iva', zoneTax, taxFee, currencySymbol);
    }else{
      productPrice = this.bookedOrder?.ProductPrice?.TotalPrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TotalPrice;
    }

    this.overviewInfoBuilder.setInfoPrice(this.product.Title, productPrice, currencySymbol);
    if(feePrice > 0){
      this.overviewInfoBuilder.setInfoFeePrice(feePrice, currencySymbol);
    }

    if(isHourSpecific){
      this.overviewInfoBuilder.setInfoFixedPrice(fixedTransferPrice, currencySymbol);
    }

    this.overviewInfoBuilder.setInfoTotalPrice(totalPrice, currencySymbol);

    return this.overviewInfoBuilder.getProduct();
  }

  buildMaintenance(){
    
    const pickupAddressText = 'cafler.page.overview.pickup-address.placeholder';
    let timeString = this.timeFormatterService.getTimeRangeString(this.productData.startPickUpTime, this.productData.endPickUpTime);
    let isHourSpecific = this.productData.endPickUpTime === null;

    this.overviewInfoBuilder.setInfoContact(this.productData.fullName, this.productData.countryPhoneCode,this.productData.phone, this.productData.email);
    this.overviewInfoBuilder.setInfoVehicle(this.productData.carPlaque, this.productData.brand, this.productData.model)
    this.overviewInfoBuilder.setInfoPickUp(pickupAddressText,this.productData.pickupAddress.text, this.productData.pickupAddressDetails, this.productData.pickupDate, timeString)
    if(this.productData.dropoffAddress){
      this.overviewInfoBuilder.setInfoDropOff('cafler.page.overview.pickup-details.droppoff-address.label',this.productData.dropoffAddress.text, this.productData.dropoffAddressDetails)
    }

    const currencySymbol = this.bookedOrder?.ProductPrice?.CurrencySymbol;
    // const productPrice = this.bookedOrder?.ProductPrice?.TotalPrice;
    // const feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TotalPrice;
    const totalPrice = this.bookedOrder?.OrderPrice?.TotalPrice;
    let fixedTransferPrice = this.bookedOrder?.FreeTaxFixedTransferPrice 

    let productPrice = 0
    let feePrice = 0

    if(this.isB2B){
      const zoneTax = this.bookedOrder?.ZoneTax
      const taxFee = this.bookedOrder?.OrderPrice?.TaxFreePrice * zoneTax 

      productPrice = this.bookedOrder?.ProductPrice?.TaxFreePrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TaxFreePrice;
      fixedTransferPrice = this.bookedOrder?.FreeTaxFixedTransferPrice;

      this.overviewInfoBuilder.setTaxFeePrice('iva', zoneTax, taxFee, currencySymbol);
    }else{
      productPrice = this.bookedOrder?.ProductPrice?.TotalPrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TotalPrice;
    }

    this.overviewInfoBuilder.setInfoPrice(this.product.Title, productPrice, currencySymbol);
    this.overviewInfoBuilder.setInfoFeePrice(feePrice, currencySymbol);
    
    if(isHourSpecific){
      this.overviewInfoBuilder.setInfoFixedPrice(fixedTransferPrice, currencySymbol);
    }
    this.overviewInfoBuilder.setInfoTotalPrice(totalPrice, currencySymbol);

    return this.overviewInfoBuilder.getProduct();
  }

  buildPreMot(){
    
    const vehicleChassisEnum = this.coreData?.Enums.find(
      (item: BEEnumModel) => item.Name === 'TransferTypeEnum',
    );

    const fuelTypeEnum = this.coreData?.Enums.find(
      (item: BEEnumModel) => item.Name === 'FuelTypeEnum'
    );

    const vehicleType = vehicleChassisEnum.EnumItems.find(
      (elem: BEEnumItemModel) => elem.Value == this.productData.vehicleType,
    );

    const fuelType = fuelTypeEnum.EnumItems.find(
      (elem: BEEnumItemModel) => elem.Value == this.productData.fuelType,
    );

    const pickupAddressText = 'cafler.page.overview.pickup-address.placeholder';
    let timeString = this.timeFormatterService.getTimeRangeString(this.productData.startPickUpTime, this.productData.endPickUpTime);
    let isHourSpecific = this.productData.endPickUpTime === null;

    this.overviewInfoBuilder.setInfoContact(this.productData.fullName, this.productData.countryPhoneCode,this.productData.phone, this.productData.email);
    this.overviewInfoBuilder.setInfoVehicle(this.productData.carPlaque, this.productData.brand, this.productData.model, vehicleType.Name, fuelType.Name)
    this.overviewInfoBuilder.setInfoPickUp(pickupAddressText,this.productData.pickupAddress.text, this.productData.pickupAddressDetails, this.productData.pickupDate, timeString)
   
    if(this.productData.dropoffAddress){
      this.overviewInfoBuilder.setInfoDropOff('cafler.page.overview.pickup-details.droppoff-address.label',this.productData.dropoffAddress.text, this.productData.dropoffAddressDetails)
    }

    const currencySymbol = this.bookedOrder?.ProductPrice?.CurrencySymbol;
    const totalPrice = this.bookedOrder?.OrderPrice?.TotalPrice;
    let fixedTransferPrice = this.bookedOrder?.FreeTaxFixedTransferPrice;
    let productPrice = 0
    let feePrice = 0
    let preMotPrice_mot = 0

    if(this.isB2B){
      const zoneTax = this.bookedOrder?.ZoneTax
      const taxFee = this.bookedOrder?.OrderPrice?.TaxFreePrice * zoneTax 

      productPrice = this.bookedOrder?.ProductPrice?.TaxFreePrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TaxFreePrice;
      
      if(this.productData.preMotType === 'preMotMot'){
        preMotPrice_mot = this.bookedOrder?.AllProductsBooked[1].FreeTaxProductPrice;
      }
      fixedTransferPrice = this.bookedOrder?.FreeTaxFixedTransferPrice;

      this.overviewInfoBuilder.setTaxFeePrice('iva', zoneTax, taxFee, currencySymbol);
    }else{
      productPrice = this.bookedOrder?.ProductPrice?.TotalPrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TotalPrice;

      if(this.productData.preMotType === 'preMotMot'){
        preMotPrice_mot = this.bookedOrder?.AllProductsBooked[1].ProductPrice;
      }
    }

    this.overviewInfoBuilder.setInfoPrice(this.product.Title, productPrice, currencySymbol);

    if(this.productData.preMotType === 'preMotMot'){
      this.overviewInfoBuilder.setInfoPrice(this.bookedOrder.AllProductsBooked[1].LocalizationKey, preMotPrice_mot, currencySymbol);
    }

    this.overviewInfoBuilder.setInfoFeePrice(feePrice, currencySymbol);
    if(isHourSpecific){
      this.overviewInfoBuilder.setInfoFixedPrice(fixedTransferPrice, currencySymbol);
    }
    this.overviewInfoBuilder.setInfoTotalPrice(totalPrice, currencySymbol);

    return this.overviewInfoBuilder.getProduct();
  }

  buildTransfer(){

    let timeString = this.timeFormatterService.getTimeRangeString(this.productData.startPickUpTime, this.productData.endPickUpTime);
    let isHourSpecific = this.productData.endPickUpTime === null;

    this.overviewInfoBuilder.setInfoContact(this.productData.fullName, this.productData.countryPhoneCode,this.productData.phone, this.productData.email);
    this.overviewInfoBuilder.setInfoDropoffContact(this.productData.dropoffFullName, this.productData.dropoffCountryPhoneCode, this.productData.dropoffPhone, this.productData.dropoffEmail);
    this.overviewInfoBuilder.setInfoVehicle(this.productData.carPlaque, this.productData.brand, this.productData.model, this.productData.vehicleType.Name);
    this.overviewInfoBuilder.setInfoPickUp('cafler.page.overview.pickup-address.placeholder', this.productData.pickupAddress.text, this.productData.pickupAddressDetails, this.productData.pickupDate, timeString);
    this.overviewInfoBuilder.setInfoDropOff('cafler.page.media-larga-distancia.directions.destination.placeholder', this.productData.deliveryAddress.text, this.productData.deliveryAddressDetails)
    
    const currencySymbol = this.bookedOrder?.ProductPrice?.CurrencySymbol;
    const totalPrice = this.bookedOrder?.OrderPrice?.TotalPrice;
    let productPrice = 0
    let fixedTransferPrice = this.bookedOrder?.FixedTransferPrice;

    if(this.isB2B){
      const zoneTax = this.bookedOrder?.ZoneTax
      const taxFee = this.bookedOrder?.OrderPrice?.TaxFreePrice * zoneTax 

      productPrice = this.bookedOrder?.ProductPrice?.TaxFreePrice;
      
      this.overviewInfoBuilder.setTaxFeePrice('iva', zoneTax, taxFee, currencySymbol);
      fixedTransferPrice = this.bookedOrder?.FreeTaxFixedTransferPrice 
    }else{
      productPrice = this.bookedOrder?.ProductPrice?.TotalPrice;
    }
    
    let distance = '';

    if(this.currentCountry?.RegionIsoCode === CountryIsoCode.GBR){
      distance = convertMetersToMiles(this.bookedOrder.Distance).toFixed(2);
    }else{
      distance = convertMetersToKilometers(this.bookedOrder.Distance).toFixed(2);
    }

    const unit: DistanceUnit = getDistanceUnitPerCountryRegionIsoCode(this.currentCountry?.RegionIsoCode);
    const unitCode: string = DistanceUnitCodes[unit]

    this.overviewInfoBuilder.setInfoDistance(distance, unitCode);
    // hardcoded transfer title because the merge of transfer vertical with replacement
    this.overviewInfoBuilder.setInfoPrice('transfer', productPrice, currencySymbol);
    
    if(isHourSpecific){
      this.overviewInfoBuilder.setInfoFixedPrice(fixedTransferPrice, currencySymbol);
    }
    this.overviewInfoBuilder.setInfoTotalPrice(totalPrice, currencySymbol);

  
    return this.overviewInfoBuilder.getProduct();

  }

  buildMot(){
    const pickupAddressText = 'cafler.page.overview.pickup-address.placeholder';

    let timeString = this.timeFormatterService.getTimeRangeString(this.productData.startPickUpTime, this.productData.endPickUpTime);
    let isHourSpecific = this.productData.endPickUpTime === null;

    const vehicleChassisEnum = this.coreData?.Enums.find(
      (item: BEEnumModel) => item.Name === 'TransferTypeEnum',
    );

    const fuelTypeEnum = this.coreData?.Enums.find(
      (item: BEEnumModel) => item.Name === 'FuelTypeEnum'
    );

    const vehicleType = vehicleChassisEnum.EnumItems.find(
      (elem: BEEnumItemModel) => elem.Value == this.productData.vehicleType,
    );

    const fuelType = fuelTypeEnum.EnumItems.find(
      (elem: BEEnumItemModel) => elem.Value == this.productData.fuelType,
    );

    this.overviewInfoBuilder.setInfoContact(this.productData.fullName, this.productData.countryPhoneCode,this.productData.phone, this.productData.email);
    this.overviewInfoBuilder.setInfoVehicle(this.productData.carPlaque, this.productData.brand, this.productData.model, vehicleType.Name, fuelType.Name)
    this.overviewInfoBuilder.setInfoPickUp(pickupAddressText,this.productData.pickupAddress.text, this.productData.pickupAddressDetails, this.productData.pickupDate, timeString)
    if(this.productData.dropoffAddress){
      this.overviewInfoBuilder.setInfoDropOff('cafler.page.overview.pickup-address.placeholder',this.productData.dropoffAddress.text, this.productData.dropoffAddressDetails)
    }
        
    const currencySymbol = this.bookedOrder?.ProductPrice?.CurrencySymbol;
    const totalPrice = this.bookedOrder?.OrderPrice?.TotalPrice;

    let fixedTransferPrice = this.bookedOrder?.FixedTransferPrice;
    let productPrice = 0
    let feePrice = 0

    if(this.isB2B){
      const zoneTax = this.bookedOrder?.ZoneTax
      const taxFee = this.bookedOrder?.OrderPrice?.TaxFreePrice * zoneTax 

      productPrice = this.bookedOrder?.ProductPrice?.TaxFreePrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TaxFreePrice;
      fixedTransferPrice = this.bookedOrder?.FreeTaxFixedTransferPrice 
 
      this.overviewInfoBuilder.setTaxFeePrice('iva', zoneTax, taxFee, currencySymbol);
    }else{
      productPrice = this.bookedOrder?.ProductPrice?.TotalPrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TotalPrice;
    }


    this.overviewInfoBuilder.setInfoPrice(this.bookedOrder.AllProductsBooked[0].LocalizationKey, productPrice, currencySymbol);
    this.overviewInfoBuilder.setInfoFeePrice(feePrice, currencySymbol);

    if(isHourSpecific){
      this.overviewInfoBuilder.setInfoFixedPrice(fixedTransferPrice, currencySymbol);
    }
    this.overviewInfoBuilder.setInfoTotalPrice(totalPrice, currencySymbol);

    return this.overviewInfoBuilder.getProduct();
  }

  buildMotWithAppointment(){

    const vehicleChassisEnum = this.coreData?.Enums.find(
      (item: BEEnumModel) => item.Name === 'TransferTypeEnum',
    );

    const fuelTypeEnum = this.coreData?.Enums.find(
      (item: BEEnumModel) => item.Name === 'FuelTypeEnum'
    );

    const vehicleType = vehicleChassisEnum.EnumItems.find(
      (elem: BEEnumItemModel) => elem.Value == this.productData.vehicleType,
    );

    const fuelType = fuelTypeEnum.EnumItems.find(
      (elem: BEEnumItemModel) => elem.Value == this.productData.fuelType,
    );

    let isPaid = this.productData.isTechnicalInspectionPaid;

    this.overviewInfoBuilder.setInfoContact(this.productData.fullName, this.productData.countryPhoneCode,this.productData.phone, this.productData.email);
    this.overviewInfoBuilder.setInfoVehicle(this.productData.carPlaque, this.productData.brand, this.productData.model, vehicleType.Name, fuelType.Name)

    this.overviewInfoBuilder.setInfoAppointment(this.productData.appointmentDate, this.productData.appointmentTime, this.productData.appointmentCode, this.productData.station, isPaid);
    this.overviewInfoBuilder.setInfoService('cafler.page.overview.pickup-address.placeholder', this.productData.pickupAddress.text, this.productData.pickupAddressDetails);
  
    if(this.productData.dropoffAddress){
      this.overviewInfoBuilder.setInfoDropOff('cafler.page.overview.pickup-details.droppoff-address.label',this.productData.dropoffAddress.text, this.productData.dropoffAddressDetails)
    }
  
    const currencySymbol = this.bookedOrder?.ProductPrice?.CurrencySymbol;
    const totalPrice = this.bookedOrder?.OrderPrice?.TotalPrice;
    let productPrice = 0
    let feePrice = 0

    if(this.isB2B){
      const zoneTax = this.bookedOrder?.ZoneTax
      const taxFee = this.bookedOrder?.OrderPrice?.TaxFreePrice * zoneTax 

      productPrice = this.bookedOrder?.ProductPrice?.TaxFreePrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TaxFreePrice;

      this.overviewInfoBuilder.setTaxFeePrice('iva', zoneTax, taxFee, currencySymbol);
    }else{
      productPrice = this.bookedOrder?.ProductPrice?.TotalPrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TotalPrice;
    }
    
    if(!isPaid){
      this.overviewInfoBuilder.setInfoPrice(this.product.Title, productPrice, currencySymbol);
    }
   
    this.overviewInfoBuilder.setInfoFeePrice(feePrice, currencySymbol);
    this.overviewInfoBuilder.setInfoTotalPrice(totalPrice, currencySymbol);
  
    return this.overviewInfoBuilder.getProduct();  
  }

  buildMotWithHomologation(){
    const pickupAddressText = 'cafler.page.overview.pickup-address.placeholder';
    let timeString = this.timeFormatterService.getTimeRangeString(this.productData.startPickUpTime, this.productData.endPickUpTime);

    const vehicleChassisEnum = this.coreData?.Enums.find(
      (item: BEEnumModel) => item.Name === 'TransferTypeEnum',
    );
    const fuelTypeEnum = this.coreData?.Enums.find(
      (item: BEEnumModel) => item.Name === 'FuelTypeEnum'
    );
    const vehicleType = vehicleChassisEnum.EnumItems.find(
      (elem: BEEnumItemModel) => elem.Value == this.productData.vehicleType,
    );
    const fuelType = fuelTypeEnum.EnumItems.find(
      (elem: BEEnumItemModel) => elem.Value == this.productData.fuelType,
    );

    this.overviewInfoBuilder.setInfoContact(this.productData.fullName, this.productData.countryPhoneCode,this.productData.phone, this.productData.email);
    this.overviewInfoBuilder.setInfoVehicle(this.productData.carPlaque, this.productData.brand, this.productData.model, vehicleType.Name, fuelType.Name)
    
    this.overviewInfoBuilder.setInfoPickUp(pickupAddressText,this.productData.pickupAddress.text, this.productData.pickupAddressDetails, this.productData.pickupDate, timeString)


    this.overviewInfoBuilder.setInfoHomologation(this.productData.periodicMot, this.productData.documentationReady, this.productData.homologationType);
    this.overviewInfoBuilder.setInfoService('cafler.page.overview.pickup-address.placeholder', this.productData.pickupAddress.text, this.productData.pickupAddressDetails);
  
    if(this.productData.dropoffAddress){
      this.overviewInfoBuilder.setInfoDropOff('cafler.page.overview.pickup-details.droppoff-address.label',this.productData.dropoffAddress.text, this.productData.dropoffAddressDetails)
    }
  
    const currencySymbol = this.bookedOrder?.ProductPrice?.CurrencySymbol;
    const totalPrice = this.bookedOrder?.OrderPrice?.TotalPrice;
    let productPrice = 0
    let feePrice = 0

    if(this.isB2B){
      const zoneTax = this.bookedOrder?.ZoneTax
      const taxFee = this.bookedOrder?.OrderPrice?.TaxFreePrice * zoneTax 

      productPrice = this.bookedOrder?.ProductPrice?.TaxFreePrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TaxFreePrice;

      this.overviewInfoBuilder.setTaxFeePrice('iva', zoneTax, taxFee, currencySymbol);
    }else{
      productPrice = this.bookedOrder?.ProductPrice?.TotalPrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TotalPrice;
    }
    
    this.overviewInfoBuilder.setInfoPrice(this.bookedOrder.AllProductsBooked[0].LocalizationKey, productPrice, currencySymbol);
    this.overviewInfoBuilder.setInfoFeePrice(feePrice, currencySymbol);
    this.overviewInfoBuilder.setInfoTotalPrice(totalPrice, currencySymbol);
  
    return this.overviewInfoBuilder.getProduct();   
    
  }

  buildMediumLongDistance(){
 
    if(this.productData.deliveryType === 1){
      return this.buildTransfer();
    }

    // ---------------------- DEPRECATED ----------------------------------

    const transferTypeEnum = this.coreData?.Enums.find(
      (item: BEEnumModel) => item.Name === 'AvailableTransferType',
    );

    const vehicleChassisEnum = this.coreData?.Enums.find(
      (item: BEEnumModel) => item.Name === 'TransferTypeEnum',
    );

    const transportType = transferTypeEnum.EnumItems.find(
      (elem: BEEnumItemModel) => elem.Value == this.productData.deliveryType,
    );
    const vehicleType = vehicleChassisEnum.EnumItems.find(
      (elem: BEEnumItemModel) => elem.Value == this.productData.vehicleType,
    );

    
    this.overviewInfoBuilder.setTruckDeliveryInfoService(transportType.Name, this.productData.customDelivery);

    this.overviewInfoBuilder.setInfoTruckDeliveryOrigin(this.productData.pickupAddress?.text, this.productData.pickupAddressDetails, this.productData.pickupDate, this.productData.pickupTime,
      this.productData.fullName, this.productData.countryPhoneCode, this.productData.phone);

    this.overviewInfoBuilder.setInfoContact(this.productData.fullName, this.productData.countryPhoneCode,this.productData.phone, this.productData.email);
    this.overviewInfoBuilder.setInfoDropoffContact(this.productData.dropoffFullName, this.productData.dropoffCountryPhoneCode,this.productData.dropoffPhone, this.productData.dropoffEmail);

    this.overviewInfoBuilder.setInfoTruckDeliveryDestination(this.productData.deliveryAddress?.text, this.productData.deliveryAddressDetails, this.productData.dropoffDate, this.productData.dropoffTime,
      this.productData.dropoffFullName, this.productData.dropoffCountryPhoneCode, this.productData.dropoffPhone);

    this.overviewInfoBuilder.setInfoVehicle(this.productData.carPlaque, this.productData.brand, this.productData.model, vehicleType.Name);

    const currencySymbol = this.bookedOrder?.ProductPrice?.CurrencySymbol;
    const totalPrice = this.bookedOrder?.OrderPrice?.TotalPrice;

    let productPrice = 0

    if(this.isB2B){
      const zoneTax = this.bookedOrder?.ZoneTax
      const taxFee = this.bookedOrder?.OrderPrice?.TaxFreePrice * zoneTax 

      productPrice = this.bookedOrder?.ProductPrice?.TaxFreePrice;
      
      this.overviewInfoBuilder.setTaxFeePrice('iva', zoneTax, taxFee, currencySymbol);
    }else{
      productPrice = this.bookedOrder?.ProductPrice?.TotalPrice;
    }

    this.overviewInfoBuilder.setInfoPrice(this.product.Title, productPrice, currencySymbol);

    this.overviewInfoBuilder.setInfoTotalPrice(totalPrice, currencySymbol);

    return this.overviewInfoBuilder.getProduct();
  }

  buildRefueling(){
    const pickupAddressText = 'cafler.page.overview.pickup-address.placeholder';
    let timeString = this.timeFormatterService.getTimeRangeString(this.productData.startPickUpTime, this.productData.endPickUpTime);
    let isHourSpecific = this.productData.endPickUpTime === null;


    this.overviewInfoBuilder.setInfoContact(this.productData.fullName, this.productData.countryPhoneCode,this.productData.phone, this.productData.email);
    this.overviewInfoBuilder.setInfoVehicle(this.productData.carPlaque, this.productData.brand, this.productData.model)
    this.overviewInfoBuilder.setInfoPickUp(pickupAddressText,this.productData.pickupAddress.text, this.productData.pickupAddressDetails, this.productData.pickupDate, timeString)
    
    if(this.productData.dropoffAddress){
      this.overviewInfoBuilder.setInfoDropOff('cafler.page.overview.pickup-details.droppoff-address.label',this.productData.dropoffAddress.text, this.productData.dropoffAddressDetails)
    }
    const currencySymbol = this.bookedOrder?.ProductPrice?.CurrencySymbol;
    const totalPrice = this.bookedOrder?.OrderPrice?.TotalPrice;
    
    let fixedTransferPrice = this.bookedOrder?.FixedTransferPrice;
    let feePrice = 0
    let refuelAmount = this.productData.refuelAmount

    if(this.isB2B){
      const zoneTax = this.bookedOrder?.ZoneTax
      const taxFee = this.bookedOrder?.OrderPrice?.TaxFreePrice * zoneTax 

      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TaxFreePrice;
      const fuelAmountTaxFree = refuelAmount / (1 + zoneTax)
      refuelAmount = fuelAmountTaxFree.toFixed(2);

      fixedTransferPrice = this.bookedOrder?.FreeTaxFixedTransferPrice 


      this.overviewInfoBuilder.setTaxFeePrice('iva', zoneTax, taxFee, currencySymbol);
    }else{
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TotalPrice;
    }

    this.overviewInfoBuilder.setInfoPrice(`refuel.${this.productData.refuelType}`, refuelAmount, currencySymbol);
    this.overviewInfoBuilder.setInfoFeePrice(feePrice, currencySymbol);
    if(isHourSpecific){
      const fixedTransferPrice = this.bookedOrder?.FixedTransferPrice;
      this.overviewInfoBuilder.setInfoFixedPrice(fixedTransferPrice, currencySymbol);
    }
    this.overviewInfoBuilder.setInfoTotalPrice(totalPrice, currencySymbol);

    return this.overviewInfoBuilder.getProduct();
  }

  buildValet(){
    const pickupAddressText = 'cafler.page.overview.pickup-address.placeholder';
    const dropoffAddressText = 'cafler.page.overview.pickup-details.droppoff-address.label';
    const pickupTimeString = this.timeFormatterService.getTimeRangeString(this.productData.startPickUpTime);
    const dropoffTimeString = this.timeFormatterService.getTimeRangeString(this.productData.endPickUpTime);
    const startPickUpTime = new Date(
      this.timeFormatterService.removeLocationAndOffset(this.productData.startPickUpTime));
    const endPickUpTime = new Date(
      this.timeFormatterService.removeLocationAndOffset(this.productData.endPickUpTime));
    
    const daysOfService = this.timeFormatterService.getNumberOfDaysBetweenTwoDates(startPickUpTime, endPickUpTime);

    const stationName = this.helperService.getStationName(this.productData.station);

    this.overviewInfoBuilder.setInfoContact(this.productData.fullName, this.productData.countryPhoneCode,this.productData.phone, this.productData.email);
    this.overviewInfoBuilder.setInfoVehicle(this.productData.carPlaque, this.productData.brand, this.productData.model)
    
    this.overviewInfoBuilder.setInfoPickUp(pickupAddressText, stationName, this.productData.pickupAddressDetails,this.productData.pickupDate, pickupTimeString)
    this.overviewInfoBuilder.setInfoDropOff(dropoffAddressText, stationName, this.productData.dropoffAddressDetails, this.productData.dropoffDate, dropoffTimeString)
    
    const currencySymbol = this.bookedOrder?.ProductPrice?.CurrencySymbol;
    const totalPrice = this.bookedOrder?.OrderPrice?.TotalPrice;

    let productPrice = 0
    let feePrice = 0

    if(this.isB2B){
      const zoneTax = this.bookedOrder?.ZoneTax
      const taxFee = this.bookedOrder?.OrderPrice?.TaxFreePrice * zoneTax 

      productPrice = this.bookedOrder?.ProductPrice?.TaxFreePrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TaxFreePrice;
      
      this.overviewInfoBuilder.setTaxFeePrice('iva', zoneTax, taxFee, currencySymbol);
    }else{
      productPrice = this.bookedOrder?.ProductPrice?.TotalPrice;
      feePrice = this.bookedOrder?.CaflerFee?.CaflerFeePrice?.TotalPrice;
    }


    this.overviewInfoBuilder.setInfoPricingByDays(this.product.Title, daysOfService, productPrice, currencySymbol);
    this.overviewInfoBuilder.setInfoFeePrice(feePrice, currencySymbol);
    this.overviewInfoBuilder.setInfoTotalPrice(totalPrice, currencySymbol);

    return this.overviewInfoBuilder.getProduct();
  }

  buildTowTruck(){
    let timeString = this.timeFormatterService.getTimeRangeString(this.productData.startPickUpTime, this.productData.endPickUpTime);
    
    this.overviewInfoBuilder.setInfoContact(this.productData.fullName, this.productData.countryPhoneCode,this.productData.phone, this.productData.email);
    this.overviewInfoBuilder.setInfoDropoffContact(this.productData.dropoffFullName, this.productData.dropoffCountryPhoneCode, this.productData.dropoffPhone, this.productData.dropoffEmail);
    this.overviewInfoBuilder.setInfoVehicle(this.productData.carPlaque, this.productData.brand, this.productData.model, undefined, undefined, this.productData.vehicleStatus)
    this.overviewInfoBuilder.setInfoPickUp('cafler.page.overview.pickup-address.placeholder', this.productData.pickupAddress.text, this.productData.pickupAddressDetails, this.productData.pickupDate, timeString);
    this.overviewInfoBuilder.setInfoDropOff('cafler.page.media-larga-distancia.directions.destination.placeholder', this.productData.deliveryAddress.text, this.productData.deliveryAddressDetails)
   
    let distance = '';

    if(this.currentCountry?.RegionIsoCode === CountryIsoCode.GBR){
      distance = convertMetersToMiles(this.bookedOrder.Distance).toFixed(2);
    }else{
      distance = convertMetersToKilometers(this.bookedOrder.Distance).toFixed(2);
    }

    const unit: DistanceUnit = getDistanceUnitPerCountryRegionIsoCode(this.currentCountry?.RegionIsoCode);
    const unitCode: string = DistanceUnitCodes[unit]

    this.overviewInfoBuilder.setInfoDistance(distance, unitCode);

    return this.overviewInfoBuilder.getProduct();
  }


  buildAllYouNeed(){
    const pickupTimeString = this.timeFormatterService.getTimeRangeString(this.productData.startPickUpTime, this.productData.endPickUpTime);
    
    this.overviewInfoBuilder.setInfoContact(this.productData.fullName, this.productData.countryPhoneCode,this.productData.phone, this.productData.email);
    this.overviewInfoBuilder.setDescription(this.productData.description, this.productData.pickupDate, pickupTimeString);
    return this.overviewInfoBuilder.getProduct();
  }
}

export class OverviewInfoBuilder{
  product = {} as OverviewInfo;

  constructor(
    private isB2B: boolean,
    private datePipe: DatePipe, 
    private helperService: HelperService, 
    private timeFormatterService: TimeFormatterService,
    private customCurrencyPipe: CustomCurrencyPipe,
  ){
    this.product.infoPricing = [];
  };

  setInfoTruckDeliveryOrigin(address: string, details: string, date: string, time: string, fullName: string,  countryPhoneCode: string, phone: string){
    const infoTruckDeliveryOrigin: InfoCard[] = [];
    const dateString = this.datePipe.transform(new Date(date), 'dd/MM/yyyy');

    infoTruckDeliveryOrigin.push({
      label: 'cafler.page.overview.origin.address',
      value: address,
    });
    if (details) {
      infoTruckDeliveryOrigin.push({
        label: 'cafler.pickup-info.pickup-details.label',
        value: details,
      });
    }
    infoTruckDeliveryOrigin.push({
      label: 'cafler.page.overview.origin.date',
      value: dateString,
    });
    infoTruckDeliveryOrigin.push({
      label: 'cafler.page.overview.origin.time',
      value: this.timeFormatterService.getTimeFromDateString(time),
    });

    // infoTruckDeliveryOrigin.push({
    //   label: 'cafler.contact.name.placeholder',
    //   value: fullName,
    // });
    // infoTruckDeliveryOrigin.push({
    //   label: 'cafler.page.overview.origin.phone',
    //   value: `${countryPhoneCode} ${phone}`,
    // });

    this.product.infoTruckDeliveryOrigin = infoTruckDeliveryOrigin; 
  }

  setInfoTruckDeliveryDestination(address: string, details: string, date:string, time: string, fullName: string,  countryPhoneCode: string, phone: string){
    const infoTruckDeliveryDestination: InfoCard[] = [];

    infoTruckDeliveryDestination.push({
      label: 'cafler.page.overview.destination.address',
      value: address,
    });
    if (details) {
      infoTruckDeliveryDestination.push({
        label: 'cafler.pickup-info.pickup-details.label',
        value: details,
      });
    }
    infoTruckDeliveryDestination.push({
      label: 'cafler.page.overview.destination.date',
      value: this.datePipe.transform(new Date(date), 'dd/MM/yyyy'),
    });
    infoTruckDeliveryDestination.push({
      label: 'cafler.page.overview.destination.time',
      value: this.timeFormatterService.getTimeFromDateString(time),
    });
    // infoTruckDeliveryDestination.push({
    //   label: 'cafler.contact.name.placeholder',
    //   value: fullName,
    // });
    // infoTruckDeliveryDestination.push({
    //   label: 'cafler.page.overview.destination.phone',
    //   value: `${countryPhoneCode} ${phone}`,
    // });

    this.product.infoTruckDeliveryDestination = infoTruckDeliveryDestination;
  }

 
  setInfoContact(fullName: string, countryPhoneCode: string, phone: string, email: string){
    
    const infoContact: InfoCard[] = [];

    infoContact.push({ 
      label: 'cafler.contact.name.placeholder', 
      value: fullName 
    });
    infoContact.push({
      label: 'cafler.page.overview.destination.phone',
      value: `${countryPhoneCode} ${phone}`,
    });
    if (!this.isB2B) {
      infoContact.push({ 
        label: 'cafler.contact.email.label', 
        value: email 
      });
    }

    this.product.infoContact = infoContact;
  }

  setInfoDriver(fullName: string, countryPhoneCode: string, phone: string, email: string){
    
    const infoDriver: InfoCard[] = [];

    infoDriver.push({ 
      label: 'cafler.contact.name.placeholder', 
      value: fullName 
    });
    infoDriver.push({
      label: 'cafler.page.overview.destination.phone',
      value: `${countryPhoneCode} ${phone}`,
    });
    if (!this.isB2B) {
      infoDriver.push({ 
        label: 'cafler.contact.email.label', 
        value: email 
      });
    }

    this.product.infoDriver = infoDriver;
  }

  setInfoDropoffContact(fullName: string, countryPhoneCode: string, phone: string, email: string){
    
    const infoDropoffContact: InfoCard[] = [];

    infoDropoffContact.push({ 
      label: 'cafler.contact.name.placeholder', 
      value: fullName 
    });
    infoDropoffContact.push({
      label: 'cafler.page.overview.destination.phone',
      value: `${countryPhoneCode} ${phone}`,
    });
    if (!this.isB2B) {
      infoDropoffContact.push({ 
        label: 'cafler.contact.email.label', 
        value: email 
      });
    }

    this.product.infoDropoffContact = infoDropoffContact;
  }

  setInfoVehicle(carPlaque: string,  brand: string, model: string, vehicleType?: string, fuelType?: string, vehicleStatus?: number){

    const infoVehicle: InfoCard[] = [];

    if(vehicleType){
      infoVehicle.push({
        label: 'cafler.page.overview.budget.vehicle-type',
        value: `cafler.product-type.media-larga-distancia.vehicle-type.${vehicleType}`,
      })
    }
    if(fuelType){
      infoVehicle.push({
        label: 'cafler.page.overview.budget.fuel-type',
        value: `cafler.product-type.technical-inspection.fuel-type.${fuelType}`,
      })
    }
    if(vehicleStatus){
      infoVehicle.push({
        label: 'cafler.page.overview.budget.vehicle-status',
        value: `cafler.vehicle-info.status.options.${vehicleStatus}`,
      })
    }

    infoVehicle.push({
      label: 'cafler.vehicle-info.plate.placeholder',
      value: carPlaque,
    });

    infoVehicle.push({
      label: 'cafler.page.overview.body-info.brand.title',
      value: brand || '-',
    });
    
    infoVehicle.push({
      label: 'cafler.page.overview.body-info.model.title',
      value: model || '-',
    });

    this.product.infoVehicle = infoVehicle;
  }

  setInfoDistance(distance: string, unitCode: string){
    const distanceInfo: InfoCard[] = [];

    distanceInfo.push({
      label: 'cafler.distance',
      value: `${distance} ${unitCode}`,
    });
    
    this.product.infoDistance = distanceInfo;
  }

  setInfoPickUp(displayText: string, address: string, details: string, date?: string, time?: string){

    const infoPickup: InfoCard[] = [];

    infoPickup.push({
      label: displayText,
      value: address,
    });

    if (details) {
      infoPickup.push({
        label: 'cafler.pickup-info.pickup-details.label',
        value: details,
      });
    }

    infoPickup.push({
      label: 'cafler.pickup-info.pickup-day.placeholder',
      value: this.datePipe.transform(date, 'dd/MM/yyyy'),
    });
    infoPickup.push({
      label: 'cafler.pickup-info.pickup-time.placeholder',
      value: time 
    });
  
    this.product.infoPickup = infoPickup;
  }

  setInfoDropOff(displayText: string, address: string, details: string, date?: string, time?: string, replacement?: boolean){

    const infoDropoff: InfoCard[] = [];

    infoDropoff.push({
      label: displayText,
      value: address,
    });

    if (details) {
      infoDropoff.push({
        label: 'cafler.pickup-info.pickup-details.label',
        value: details,
      });
    }
    if(date && time){
      infoDropoff.push({
        label: 'cafler.dropoff-info.dropoff-day.placeholder',
        value: this.datePipe.transform(date, 'dd/MM/yyyy'),
      });
      if(replacement){
        infoDropoff.push({
          label: 'cafler.overview.transfer-replacement-dropoff.title',
          value: time 
        });  
      } else {
        infoDropoff.push({
          label: 'cafler.dropoff-info.dropoff-time.placeholder',
          value: time 
        });
      }
    }
    
    this.product.infoDropoff = infoDropoff;
  }

  setInfoReplacementPickup(displayText: string, address: string, details: string, date: string, startPickUpTime: string, endPickUpTime: string){

    const infoReplacementService: InfoCard[] = [];

    const startHour = this.getHourFromDateTime(startPickUpTime);
    let endHour;
    if(endPickUpTime){
      endHour = this.getHourFromDateTime(endPickUpTime);
    } else {
      endHour = this.getSpecificHourFromDateTime(startPickUpTime);
    }


    infoReplacementService.push({
      label: displayText,
      value: address,
    });

    if (details) {
      infoReplacementService.push({
        label: 'cafler.pickup-info.pickup-details.label',
        value: details,
      });
    }
    infoReplacementService.push({
      label: 'cafler.dropoff-info.dropoff-day.placeholder',
      value: this.datePipe.transform(date, 'dd/MM/yyyy'),
    });
    infoReplacementService.push({
      label: 'cafler.dropoff-info.dropoff-time.placeholder',
      value: startHour + ' - ' + endHour, 
    });
    
    this.product.infoReplacementService = infoReplacementService;
  }

  setInfoReplacementDropOff(displayText: string, address: string, details: string, date: string, daysReplacement: number){

    const infoReplacementDropOff: InfoCard[] = [];

    infoReplacementDropOff.push({
      label: displayText,
      value: address,
    });

    if (details) {
      infoReplacementDropOff.push({
        label: 'cafler.pickup-info.pickup-details.label',
        value: details,
      });
    }

    const dropoffDate = moment(date).add(daysReplacement, 'days').format('DD/MM/YYYY');
    infoReplacementDropOff.push({
      label: 'cafler.dropoff-info.dropoff-day.placeholder',
      value: dropoffDate
    });
    infoReplacementDropOff.push({
      label: 'cafler.dropoff-info.dropoff-time.placeholder',
      value: "08:00" + ' - ' + "20:00", 
    });
    
    this.product.infoReplacementDropOff = infoReplacementDropOff;
  }

  setTruckDeliveryInfoService(transportType: string, isCustomDelivery: boolean){
    const infoTruckDeliveryService: InfoCard[] = [];

    infoTruckDeliveryService.push({
      label: "cafler.page.overview.budget.transport-type",
      value: `cafler.product-type.media-larga-distancia.delivery-type.${transportType}`
    });
    infoTruckDeliveryService.push({
      label: "cafler.page.overview.budget.personalized-delivery",
      value: isCustomDelivery ? "cafler.yes" : "cafler.no"
    });

    this.product.infoTruckDeliveryService = infoTruckDeliveryService;
  }

  setDescription(description: string, date: string, pickupTime: string){
    const descriptionInfo: InfoCard[] = [];

    descriptionInfo.push({
      value: description,
    });

    descriptionInfo.push({
      label: 'cafler.pickup-info.pickup-day.placeholder',
      value: this.datePipe.transform(date, 'dd/MM/yyyy'),
    });

    descriptionInfo.push({
      label: 'cafler.pickup-info.pickup-time.placeholder',
      value: pickupTime
    });

    this.product.infoDescription = descriptionInfo;
  }

  setInfoService(displayText: string, address: string, details?: string, ){

    const infoService: InfoCard[] = [];

    infoService.push({
      label: displayText,
      value: address,
    });

    if (details) {
      infoService.push({
        label: 'cafler.pickup-info.pickup-details.label',
        value: details,
      });
    }

    this.product.infoService = infoService;
  }

  setInfoAppointment(date: string, time: string, code: string, stationId: string, isPaid: boolean){
    
    const infoAppointment: InfoCard[] = [];
    const appointmentAddress = this.helperService.getStationMOTAddress(stationId);
    
    infoAppointment.push({
      label: 'cafler.pickup-info.appointment.pickup-day.placeholder',
      value: this.datePipe.transform(date, 'dd/MM/yyyy'),
    });

    infoAppointment.push({
      label: 'cafler.page.service-time.appointment-placeholder',
      value: time
    });

    infoAppointment.push({
      label: 'cafler.page.service-code.appointment-placeholder',
      value: code,
    });
    
    infoAppointment.push({
      label: 'cafler.page.service-Station.appointment-placeholder',
      value: appointmentAddress,
    });
    
    infoAppointment.push({
      label: 'cafler.page.service-Station.appointment-paid',
      value: isPaid ? 'cafler.isPaid' : 'cafler.notPaid',
    });
    this.product.infoAppointment = infoAppointment;
  }

  setInfoHomologation(periodicMot: string, documentationReady: boolean, homologationType: boolean){

    const infoHomologation = [];


    infoHomologation.push({
      label: 'cafler.page.overview.homologation.homologation-type',
      value: 'cafler.page.homologation.homologation-type.' + homologationType,
    });

    infoHomologation.push({
      label: 'cafler.page.overview.homologation.periodic-mot',
      value: periodicMot === 'withPeriodicMot'? 'cafler.overview.homologation.periodic-mot-passed' : 'cafler.overview.homologation.periodic-mot-not-passed',
    });

    infoHomologation.push({
      label: 'cafler.page.overview.homologation.documentation-ready',
      value: documentationReady? 'cafler.page.overview.homologation.documentation-ready.ready' : 'cafler.page.overview.homologation.documentation-ready.not-ready',
    });

    this.product.infoHomologation = infoHomologation;

  }

  setInfoPricingReplacementByDays(title: string, days: number, price: number, currencySymbol: string){

  const infoPrice = [];
  
  infoPrice.push({
    label: `cafler.product-type.${title}.title`,
    days: days,
    value: this.customCurrencyPipe.convert(
      price,
      currencySymbol,
    ),
  });

  this.product.infoPricing.push(...infoPrice);
  }

  setInfoPricingByDays(title: string, days: number, price: number, currencySymbol: string){

    const infoPrice = [];
    
    infoPrice.push({
      label: `cafler.product-type.${title}.title`,
      days: days,
      value: this.customCurrencyPipe.convert(
        price,
        currencySymbol,
      ),
    });

    this.product.infoPricing.push(...infoPrice);
  }

  setInfoPrice(title: string, price: number, currencySymbol: string){
    
    const infoPrice = [];
    
    infoPrice.push({
      label: `cafler.product-type.${title}.title`,
      value: this.customCurrencyPipe.convert(
        price,
        currencySymbol,
      ),
    });

    this.product.infoPricing.push(...infoPrice);
    
  }

  setTaxFeePrice(title: string, fee: number, price: number, currencySymbol: string){    
    const infoPrice: InfoCard = {
      label: `cafler.product-type.${title}.title`,
      value: this.customCurrencyPipe.convert(
        price,
        currencySymbol,
      ),
    };

    this.product.infoTaxes = infoPrice;
    
  }

  setInfoProductWithReplacementPrice(products: any[], currencySymbol: string, daysReplacement: number){
    
    const infoPrice = [];
    for (let i = 0; i < products.length; i++) {
      if (i !== products.length - 1) {
        infoPrice.push({
          label: `cafler.product-type.${products[i].LocalizationKey}.title`,
          value: this.customCurrencyPipe.convert(
            products[i].ProductPrice,
            currencySymbol,
            ),
          });
      } else {
        const calculatedPrice = products[i].ProductPrice / daysReplacement;
        infoPrice.push({
          label: `cafler.product-type.${products[i].LocalizationKey}.title`,
          days: daysReplacement,
          amount: `${calculatedPrice.toFixed(2)}${currencySymbol}`,
          value: this.customCurrencyPipe.convert(
            products[i].ProductPrice,
            currencySymbol,
            ),
          });
        }
      }

    this.product.infoPricing.push(...infoPrice);
    
  }

  setInfoFeePrice(price: number, currencySymbol: string){
    
    const infoPrice = [];
    infoPrice.push({
      label: 'cafler.page.overview.pricing.tasa-cafler',
        value: this.customCurrencyPipe.convert(
          price,
          currencySymbol,
        ),
    });

    this.product.infoPricing.push(...infoPrice);
    
  }

  setInfoFixedPrice(price: number, currencySymbol: string){
    const infoPrice = [];

    infoPrice.push({
      label: 'cafler.page.overview.pricing.specific-hour-cafler',
      value: this.customCurrencyPipe.convert(
        price, 
        currencySymbol
      ), 
    })
    this.product.infoPricing.push(...infoPrice);
  }

  setInfoTotalPrice(price: number, currencySymbol: string){
    const infoTotalPrice: InfoCard = {
      label: 'cafler.page.overview.pricing.total',
      value: this.customCurrencyPipe.convert(
        price,
        currencySymbol,
      ),
    }    
  
    this.product.infoTotalPrice = infoTotalPrice
  }

  getProduct(){
    return this.product;
  }

  getHourFromDateTime(date: string){
    const internationalDate = date.split(" ")[0];
    return moment(internationalDate).format('HH:mm');
  }

  getSpecificHourFromDateTime(date: string){
    const internationalDate = date.split(" ")[0];
    return moment(internationalDate).clone().add(15, "minutes").format('HH:mm');
  }


 
}
