<div>
    <p class="breadcrumb"><span (click)="onGoBackEvent()" class="pointer">{{'cafler.toppings.breadbcrumb.main-menu' | translate}}</span><caf-icon class="rotate-chevron" [settings]="arrowIcon"></caf-icon> {{'cafler.toppings.breadbcrumb.washing-menu' | translate}}</p>
    <div *ngFor="let item of display;">
        <div class="title-container">
            <h3 class="title">{{ item.title | translate}}</h3>
            <p>{{ item.description | translate}}</p>
        </div>
        <div *ngFor="let washProduct of item.washProducts" class="wash-product" >
            <app-topping-selector [settings] = "washProduct" (productSelected)="productSelection($event)"></app-topping-selector>
        </div>
    </div>
    <footer>
        <caf-button
        class="caf-btn-secondary caf-btn-desk-small caf-btn-mob-extra-small"
        (onClick)="onGoBackEvent()"
        [settings]="buttons.buttonBack"></caf-button>
        <caf-button
        class="caf-btn-complementary caf-btn-desk-small caf-btn-mob-extra-small"
        (onClick)="onSubmitEvent()"
        [settings]="buttons.buttonSubmit"
      ></caf-button>
    </footer>
</div>

