import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { KamikazeResource } from '../resources/kamikaze.resource';
import { BEProductModel, BECoreDataModel, BEBookOrderModel } from '../models';
import { Store } from '@ngrx/store';
import { removeToppings, KamikazeActions } from '../store/actions';
import { B2b10HelperService } from '../services/b2b-10-helper.service';
import { environment } from '../../environments/environment';
import { selectCurrentVertical, selectCurrentZoneId, selectIsB2B } from '../store/selectors';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { BEOrdersManagerProductModel } from '../models/be/BE-orders-manager-product.model';
import { VerticalsTypeEnum } from '../Enums/verticalsType.enum';
import { VehiclesTypesEnum } from '../Enums/vehiclesTypes.enum';
import { AllYouNeedProduct, TimeSlot } from '../models/types';

@Injectable()
export class KamikazeFacade {

  zoneId!: string;

  constructor(
    private kamikazeRequest: KamikazeResource,
    private store: Store,
    private b2bHelper: B2b10HelperService,
    private route: ActivatedRoute,
  ) {

    this.store.select(selectCurrentZoneId).subscribe((zoneId) => {
      this.zoneId = zoneId;
    });
  }

  getCoreData(isB2B: boolean): Observable<any> {
    // return this.kamikazeRequest.getCoreData(isB2B);
    return this.kamikazeRequest.getInfoRegions().pipe(
      map((item: any) => {
        let newCoreData = this.translateCoreDataRegionsFromOrdersManager(item);
        return newCoreData;
      }),
    );
  }



  createProductOrder(params: BEBookOrderModel, isB2B: boolean): Observable<any> {
    return this.kamikazeRequest.createProductOrder(params, isB2B);
  }

  setOrderStatus(OrderHash: string, PaymentHash: string): Observable<any> {
    return this.kamikazeRequest.setOrderStatus({ OrderHash, PaymentHash });
  }

  setCouponCode(OrderHash: string, couponCode: string, isB2B: boolean): Observable<any> {
    return this.kamikazeRequest.setCouponCode(OrderHash, couponCode, isB2B);
  }

  clearSelectedToppings(OrderHash: string): Observable<any> {
    return this.kamikazeRequest.clearSelectedToppings(OrderHash);
  }

  checkAppointmentHour(stationId: string, date: any, time: any, isB2B: boolean): Observable<any> {
    let appointmentFormatted = this.mergeHourAndDate(time, date);
    return this.kamikazeRequest.checkAppointmentHour(stationId, appointmentFormatted, isB2B);
  }

  mergeHourAndDate(hour: string, date: Date) {
    let res = 
      date.getFullYear() + '-' + 
      ((date.getMonth()+1) > 9? (date.getMonth()+1) : ("0" + (date.getMonth()+1) )) + '-' + 
      ((date.getDate() > 9)? date.getDate() : ('0' + date.getDate()))  + 'T' + hour;
    return res;
  }

  removeCoupon(OrderHash: string, isB2B: boolean): Observable<any> {
    return this.kamikazeRequest.removeCoupon(OrderHash, isB2B);
  }

  getZone(): Observable<string> {
    return this.route.queryParams.pipe(map((params) => params.zoneId));
  }

  async buildOrdersManagerProductData(
    productData: any,
    zone: string,
    vertical: number,
    userData: any,
    isB2B: boolean,
  ){
  
    
    const OriginAddress = productData.pickupAddress?.text;
    const OriginAddressDetails = productData.pickupAddressDetails || '';
    const OriginAddressLatitude = productData.pickupAddress?.googleObject.location.latitude;
    const OriginAddressLongitude = productData.pickupAddress?.googleObject.location.longitude;
    const dropoffAddress = productData.dropoffAddress || productData.deliveryAddress || undefined;
    const DestinationAddress = dropoffAddress?.text || '';
    const DestinationAddressDetails =
      productData?.dropoffAddressDetails || productData?.deliveryAddressDetails || '';
    const DestinationAddressLatitude = dropoffAddress?.googleObject.location.latitude;
    const DestinationAddressLongitude = dropoffAddress?.googleObject.location.longitude;
    let productId: string = productData.serviceType;

    let serviceConfiguration: any = {
      VerticalType: vertical
    }

    if(vertical === VerticalsTypeEnum.TOW_TRUCK){
      serviceConfiguration = {
        VehicleMovementStatus: productData.vehicleStatus,
        VerticalType: vertical
      }
    }

    const isMotHomologation = productData.homologationType !== undefined;

    if(vertical === VerticalsTypeEnum.MOT || vertical === VerticalsTypeEnum.PRE_MOT){
      // TODO: se esta harcodeando los tipos de vehiculos, ya que actualmente back solo acepta los vehiculos de 1 (turismo) y 5 (furgoneta) y motos
      let vehicleType = productData.vehicleType;
      
      if(productData.vehicleType === VehiclesTypesEnum.ATV || productData.vehicleType === VehiclesTypesEnum.MINIVAN){
        vehicleType = VehiclesTypesEnum.SEDAN;
      }else if(productData.vehicleType === VehiclesTypesEnum.LIGHTVAN || productData.vehicleType === VehiclesTypesEnum.BOXTRUCK){
        vehicleType = VehiclesTypesEnum.VAN;
      }
      
      if(productData.preMotType){
        productId = productData.preMotType === 'preMotMot'
        ? await this.getPreTechnicalInspectionProductId(isB2B, zone, vehicleType, productData.fuelType, true) 
        : productData.serviceType;
      }else{
        // regular MOT 
        productId = await this.getTechnicalInspectionProductId(isB2B, zone, vehicleType, productData.fuelType, isMotHomologation);
      }
    
    }

    if(vertical === VerticalsTypeEnum.VALET){
      serviceConfiguration = {
        StationId: productData.station,
        VerticalType: vertical
      }
    }
    if(vertical === VerticalsTypeEnum.REFUELING){
      serviceConfiguration = {
        MonetaryAmount: productData.refuelAmount,
        FuelType: productData.refuelType,
        VerticalType: vertical
      }
    }
    if(vertical === VerticalsTypeEnum.LONG_DISTANCE_TRANSFER){
      serviceConfiguration = {
        UseCustomDelivery : productData.customDelivery,
        TransferType: productData.deliveryType,
        VerticalType: vertical
      }
    }

    if(vertical === VerticalsTypeEnum.TRANSFER && 
      productData.replacementVehicle && 
      productData.replacementVehicle === 'withReplacementVehicle'){

        let additionalConfigurations: any = {
          AmountOfDays: productData.daysReplacement,
          VehicleCategory: 'basic'
        }

        if(productData.replacementDriver == 'differentDriver'){
          additionalConfigurations = {
            ...additionalConfigurations,
            ContactName: productData.driverFullName,
            ContactPhone: productData.driverCountryPhoneCode + productData.driverPhone,
            ContactEmail: productData.driverEmail
          }
        }


        serviceConfiguration = {
          VerticalType: vertical,
          AdditionalConfigurations: JSON.stringify(additionalConfigurations)
        }
        
        if(productData.replacementDriver == 'differentDriver'){
          serviceConfiguration = {
            ...serviceConfiguration,
            ContactName: productData.driverFullName,
            ContactPhone: productData.driverCountryPhoneCode + productData.driverPhone,
            ContactEmail: productData.driverEmail
          }
        }

    }
    //TODO meterlo en ITV arriba
    if(productData.appointmentDate !== undefined){
      serviceConfiguration = {
        AppointmentTime: this.mergeHourAndDate(productData.appointmentTime, productData.appointmentDate),
        AppointmentCode: productData.appointmentCode,
        StationId: productData.station,
        IsInspectionPaid : productData.isTechnicalInspectionPaid,
        VerticalType: vertical
      }
    }

    if(productData.homologationType !== undefined){
      serviceConfiguration = {
        HasCompletedRegularInspections: productData.periodicMot === 'withPeriodicMot',
        AreDocumentsAvailable: productData.documentationReady,
        ReformType: productData.homologationType,
        VerticalType: vertical
      }
    }

    let bookOrderData = {
      ZoneId: zone,
      ProductId: productId,
      ClientId : isB2B ? userData?.userId || undefined : undefined,
      OriginAddress: OriginAddress,
      OriginAddressDetails: OriginAddressDetails,
      OriginContactName: productData.fullName || productData.pickupFullName,
      OriginContactPhoneNumber: productData.phone
      ? `${productData.countryPhoneCode}${productData.phone}`
      : `${productData.pickupCountryPhoneCode}${productData.pickupPhone}`,
      OriginContactEmailAddress: productData.email || productData.pickupEmail,
      OriginAddressLatitude: OriginAddressLatitude,
      OriginAddressLongitude: OriginAddressLongitude,
      DestinationAddress: DestinationAddress,
      DestinationAddressDetails: DestinationAddressDetails,
      DestinationContactName: productData.dropoffFullName || undefined,
      DestinationContactPhoneNumber: productData.dropoffPhone
      ? `${productData.dropoffCountryPhoneCode} ${productData.dropoffPhone}`
      : undefined,
      DestinationContactEmailAddress: productData.dropoffEmail || undefined,
      DestinationAddressLatitude: DestinationAddressLatitude,
      DestinationAddressLongitude: DestinationAddressLongitude,
      LicensePlate: productData.carPlaque,
      VehicleChassisType: productData.vehicleType,
      // hay chasis en todas las verticales???
      VehicleBrand: productData.brand,
      VehicleModel: productData.model,
      // en la vertical 11 (Valet) el nombre de endPickUpTime es incoherente ya que es el dropoffTime
      ServiceStartDate:
      vertical === VerticalsTypeEnum.LONG_DISTANCE_TRANSFER && productData.deliveryType !== 1
        ? productData.pickupTime
        : productData.startPickUpTime,
      ServiceEndDate:
      vertical === VerticalsTypeEnum.LONG_DISTANCE_TRANSFER && productData.deliveryType !== 1
        ? productData.dropoffTime
        : productData.endPickUpTime,
      Comments: productData.additionalComments,
      ServiceConfiguration: serviceConfiguration
    }

    // si es itv añadimos el tipo de combustible (en los demas no sabemos si afectaria)
    if(vertical === VerticalsTypeEnum.MOT) {
      bookOrderData = <any>{
        ...bookOrderData,
        VehicleFuelType: productData.fuelType
      }
    }

    return bookOrderData;


}
  

  buildAllYouNeedOrder(
    productData: any,
    zone: string,
    userData: any,
    isB2B: boolean,
  ): any {
    const product: AllYouNeedProduct = {
      ZoneId: zone,
      ClientId: isB2B ? userData?.userId || undefined : undefined,
      ServiceDescription: productData.description,
      ContactName: productData.fullName,
      ContactPhone: `${productData.countryPhoneCode}${productData.phone}`,
      ContactEmailAddress: productData.email,
      PickupRangeStart: productData.startPickUpTime,
      PickupRangeEnd: productData.endPickUpTime,
    }

    return product;
  }

  setCurrentVertical(): void {
    this.store.select(selectCurrentVertical).pipe(
      map((vertical) => {
        this.store.dispatch({ type: KamikazeActions.SetCurrentVertical, payload: vertical });
      }),
    );
  }

  buildReturnToppingOrder(
    orderHash: string,
    productId: string,
    productData: any,
    toppingData: any,
  ): any {
    const DestinationAddress = toppingData.destinationAddress?.text;
    const DestinationAddressDetails = toppingData?.destinationAddressDetails || '';
    const DestinationAddressLatitude =
      toppingData.destinationAddress?.googleObject?.location.latitude;
    const DestinationAddressLongitude =
      toppingData.destinationAddress?.googleObject?.location.longitude;

    var dateParts = toppingData.destinationDate.split('/');
    // month is 0-based, that's why we need dataParts[1] - 1 //hack from https://stackoverflow.com/a/33299764
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

    return {
      OrderHash: orderHash,
      ProductId: productId,
      ServiceConfiguration: {
        DestinationAddress: DestinationAddress,
        DestinationAddressDetails: DestinationAddressDetails,
        DestinationAddressLatitude: DestinationAddressLatitude,
        DestinationAddressLongitude: DestinationAddressLongitude,
        DestinationName: toppingData.destinationFullName,
        DestinationPhone: toppingData.destinationPhone,
        DestinationEmail: toppingData.destinationEmail,
        DestinationServiceDate: dateObject,
        DestinationServiceTime: toppingData.destinationTime,
      },
    };
  }

  async getTechnicalInspectionProductId(isB2B: boolean, zoneId:string, chassisType: number, fuelType: number, isMotHomologation: boolean) {
    return await this.kamikazeRequest.getTechnicalInspectionProductId(isB2B, zoneId, chassisType, fuelType, isMotHomologation);
  }

  async getPreTechnicalInspectionProductId(isB2B: boolean, zoneId:string, chassisType: number, fuelType: number, includeTechnicalInspection: boolean) {
    return await this.kamikazeRequest.getPreTechnicalInspectionProductId(isB2B, zoneId, chassisType, fuelType, includeTechnicalInspection);
  }

  addToppingsToOrder(isB2B: boolean, orderHash: string, toppings: any){    
    return this.kamikazeRequest.addToppingsToOrder(isB2B, orderHash, toppings);
  }

  removeToppingsFromOrder(isB2B: boolean, orderHash: string, toppingId: string){
    return this.kamikazeRequest.removeToppingsFromOrder(isB2B, orderHash, toppingId);
  }

  buildWashToppingOrder(orderHash: string, productId: string): any {
    return {
      OrderHash: orderHash,
      ProductId: productId,
      ServiceConfiguration: {},
    };
  }

  validateOperativeZone(zoneId: string, latitude: number, longitude: number){
    return this.kamikazeRequest.validateOperativeZone(zoneId, latitude, longitude);
  }

  createReturnToppingOrder(body: any) {
    return this.kamikazeRequest.createReturnToppingOrder(body);
  }

  bookProduct(isB2B: boolean, productData: any){
    return this.kamikazeRequest.bookProduct(isB2B, productData);
  }

  bookAllYouNeedProduct(isB2B: boolean, productData: AllYouNeedProduct){
    return this.kamikazeRequest.bookAllYouNeedProduct(isB2B, productData);
  }

  createWashingToppingOrder(body: any) {
    return this.kamikazeRequest.createWashingToppingOrder(body);
  }

  getProductAvailabilities(dateToSend: any): Observable<any> {
    return this.kamikazeRequest.getProductAvailabilities(dateToSend);
  }

  getVehicleReplacementProductId(isB2B: boolean, zoneId:string) {
    return this.kamikazeRequest.getVehicleReplacementProductId(isB2B, zoneId);
  }
  
  getAllYouWantTimeAvailability(isB2B: boolean, zoneId: string, date: Date ): Observable<TimeSlot[]> {
    return this.kamikazeRequest.getAllYouWantTimeAvailability(isB2B, zoneId, date);
  }

  getToppingAvailabilities(dateToSend: any): Observable<any> {
    return this.kamikazeRequest.getToppingAvailabilities(dateToSend);
  }

  confirmOrder(body: any, isB2B: boolean): Observable<any> {
    return this.kamikazeRequest.confirmOrder(body, isB2B);
  }
  getStations(productId: string): Observable<any> {
    return this.kamikazeRequest.getStations(productId);
  }
  getMotStations(isB2B: boolean, zoneId: string): Observable<any> {
    return this.kamikazeRequest.getMotStations(isB2B, zoneId);
  }
  getServices(elementsAmount: number, page: number, servicesEnum: any, userId: any): Observable<any>{
    return this.kamikazeRequest.getServices(elementsAmount, page, servicesEnum, userId);
  }
  cancelService(caflerHash: string, userId: any): Observable<any>{
    return this.kamikazeRequest.cancelService(caflerHash, userId);
  }

  getDataDetailsFromService(orderHash: string): Observable<any>{
    return this.kamikazeRequest.getServiceDetailsData(orderHash);
  }

  translateCoreDataRegionsFromOrdersManager(item: any) {
    let fuelTypeEnum = [];
    let transferTypeEnum = [];
    let availableTransferType = [];
    let verticalTypeEnum = [];
    let myServicesTypeEnum = [];
    for (let x in item.AvailableVehicleFuelType) {
      fuelTypeEnum.push({
        Name: x,
        Value: item.AvailableVehicleFuelType[x],
      });
    }
    for (let x in item.AvailableVehicleChassisType) {
      transferTypeEnum.push({
        Name: x,
        Value: item.AvailableVehicleChassisType[x],
      });
    }
    for (let x in item.AvailableTransferType) {
      availableTransferType.push({
        Name: x,
        Value: item.AvailableTransferType[x],
      });
    }
    for (let x in item.AvailableVerticalType) {
      verticalTypeEnum.push({
        Name: x,
        Value: item.AvailableVerticalType[x],
      });
    }
    myServicesTypeEnum = [
      {
        Name: 'Today',
        Value: 1
      }
    ]
    let coreDataTemp = {
      Enums: [
        {
          Name: 'FuelTypeEnum',
          EnumItems: fuelTypeEnum,
        },
        {
          Name: 'TransferTypeEnum',
          EnumItems: transferTypeEnum,
        },
        {
          Name: 'AvailableTransferType',
          EnumItems: availableTransferType,
        },
        {
          Name: 'VerticalTypeEnum',
          EnumItems: verticalTypeEnum,
        },
      ],
      Regions: item.Regions,
    };
    return coreDataTemp;
  }

  mockData(): any{
    return  {
      "BundledInspections": {
          "Sedan": [
              {
                  "Alias": "Integral Washing",
                  "Icon": "integral-washing",
                  "Id": "00000000-0000-0000-0000-000000000000",
                  "IsPaymentRequired": true,
                  "Price": {
                      "Currency": 1,
                      "CurrencySymbol": "€",
                      "Tax": 0.21,
                      "TaxFreePrice": 9.99,
                      "TotalPrice": 177.91
                  },
                  "Title": "integral-washing",
                  "Holidays": [],
                  "OrdersManagerId": "94e4e76b-309d-415c-a63a-42d8ddcfa6ec",
                  "Category": "Details",
                  "Time": 20,
                  "BadgeType": {
                      "Text": "cafler.product.wash.badge-new",
                      "Color": "strongBlue",
                      "Icon": ""
                  }
              },
              {
                  "Alias": "Integral Washing",
                  "Icon": "integral-washing",
                  "Id": "00000000-0000-0000-0000-000000000000",
                  "IsPaymentRequired": true,
                  "Price": {
                      "Currency": 1,
                      "CurrencySymbol": "€",
                      "Tax": 0.21,
                      "TaxFreePrice": 9.99,
                      "TotalPrice": 177.91
                  },
                  "Title": "integral-washing",
                  "Holidays": [],
                  "OrdersManagerId": "94e4e76b-309d-415c-a63a-42d8ddcfa6ec",
                  "Category": "Details",
                  "Time": 20,
                  "BadgeType": {
                      "Text": "cafler.product.wash.badge-new",
                      "Color": "strongBlue",
                      "Icon": ""
                  }
              },
              {
                  "Alias": "Integral Washing",
                  "Icon": "integral-washing",
                  "Id": "00000000-0000-0000-0000-000000000000",
                  "IsPaymentRequired": true,
                  "Price": {
                      "Currency": 1,
                      "CurrencySymbol": "€",
                      "Tax": 0.21,
                      "TaxFreePrice": 9.99,
                      "TotalPrice": 177.91
                  },
                  "Title": "integral-washing",
                  "Holidays": [],
                  "OrdersManagerId": "94e4e76b-309d-415c-a63a-42d8ddcfa6ec",
                  "Category": "Details",
                  "Time": 20,
                  "BadgeType": {
                      "Text": "cafler.product.wash.badge-new",
                      "Color": "strongBlue",
                      "Icon": ""
                  }
              }
          ],
          "Motorbike": [
              {
                  "Alias": "Integral Washing",
                  "Icon": "integral-washing",
                  "Id": "00000000-0000-0000-0000-000000000000",
                  "IsPaymentRequired": true,
                  "Price": {
                      "Currency": 1,
                      "CurrencySymbol": "€",
                      "Tax": 0.21,
                      "TaxFreePrice": 9.99,
                      "TotalPrice": 177.91
                  },
                  "Title": "integral-washing",
                  "Holidays": [],
                  "OrdersManagerId": "94e4e76b-309d-415c-a63a-42d8ddcfa6ec",
                  "Category": "Details",
                  "Time": 20,
                  "BadgeType": {
                      "Text": "cafler.product.wash.badge-new",
                      "Color": "strongBlue",
                      "Icon": ""
                  }
              },
              {
                  "Alias": "Integral Washing",
                  "Icon": "integral-washing",
                  "Id": "00000000-0000-0000-0000-000000000000",
                  "IsPaymentRequired": true,
                  "Price": {
                      "Currency": 1,
                      "CurrencySymbol": "€",
                      "Tax": 0.21,
                      "TaxFreePrice": 9.99,
                      "TotalPrice": 177.91
                  },
                  "Title": "integral-washing",
                  "Holidays": [],
                  "OrdersManagerId": "94e4e76b-309d-415c-a63a-42d8ddcfa6ec",
                  "Category": "Details",
                  "Time": 20,
                  "BadgeType": {
                      "Text": "cafler.product.wash.badge-new",
                      "Color": "strongBlue",
                      "Icon": ""
                  }
              }
          ]
      },
      "RegularProducts": [
          {
              "Alias": "Integral Washing",
              "Icon": "integral-washing",
              "Id": "00000000-0000-0000-0000-000000000000",
              "IsPaymentRequired": true,
              "Price": {
                  "Currency": 1,
                  "CurrencySymbol": "€",
                  "Tax": 0.21,
                  "TaxFreePrice": 9.99,
                  "TotalPrice": 177.91
              },
              "Title": "integral-washing",
              "Holidays": [],
              "OrdersManagerId": "94e4e76b-309d-415c-a63a-42d8ddcfa6ec",
              "Category": "Details",
              "Time": 20,
              "BadgeType": {
                  "Text": "cafler.product.wash.badge-new",
                  "Color": "strongBlue",
                  "Icon": ""
              }
          },
          {
              "Alias": "Integral Washing",
              "Icon": "integral-washing",
              "Id": "00000000-0000-0000-0000-000000000000",
              "IsPaymentRequired": true,
              "Price": {
                  "Currency": 1,
                  "CurrencySymbol": "€",
                  "Tax": 0.21,
                  "TaxFreePrice": 9.99,
                  "TotalPrice": 177.91
              },
              "Title": "integral-washing",
              "Holidays": [],
              "OrdersManagerId": "94e4e76b-309d-415c-a63a-42d8ddcfa6ec",
              "Category": "Details",
              "Time": 20,
              "BadgeType": {
                  "Text": "cafler.product.wash.badge-new",
                  "Color": "strongBlue",
                  "Icon": ""
              }
          }
      ]
  }
  
  
  
  }

}