import { Component, Input, OnInit } from '@angular/core';
import { CaflerIconSettingsModel } from '@cafler/common-ui';
import { VehicleDataDisplayModel } from './models/vehicle-data-settings.model';

type VehicleCharacteristicsModel = {
  icon: CaflerIconSettingsModel,
  number?: number,
  text?: string
}

@Component({
  selector: 'vehicle-data-display',
  templateUrl: './vehicle-data-display.component.html',
  styleUrls: ['./vehicle-data-display.component.scss']
})
export class VehicleDataDisplayComponent implements OnInit {

  @Input() settings!: VehicleDataDisplayModel;
  
  vehicleCharacteristics!: VehicleCharacteristicsModel[];

  constructor() { }

  ngOnInit(): void {
    this.vehicleCharacteristics = this.buildVehicleData(this.settings)

  }


  private buildVehicleData(settings: VehicleDataDisplayModel): VehicleCharacteristicsModel[]{
    const result: VehicleCharacteristicsModel[] = [];

    if(settings.gearbox){
      let info: VehicleCharacteristicsModel = {     
        icon: <CaflerIconSettingsModel>{
          name: 'caf-gearbox',
          alt: 'caja de cambios',
          width: '11px',
          height: '11px'
        },
        text: settings.gearbox
      }
      result.push(info)
    }

    if(settings.fuel){
      let info: VehicleCharacteristicsModel = {     
        icon: <CaflerIconSettingsModel>{
          name: 'caf-gas-station',
          alt: 'tipo de gasolina',
          width: '11px',
          height: '11px'
        },
        text: settings.fuel
      }
      result.push(info)
    }
    if(settings.personsCapacity){
      let info: VehicleCharacteristicsModel = {     
        icon: <CaflerIconSettingsModel>{
          name: 'caf-user',
          alt: 'número de personas',
          width: '11px',
          height: '11px'
        },
        number: settings.personsCapacity,
        text: 'cafler.vehicle-data-display.persons'
      }
      result.push(info)
    }
    if(settings.numberOfDoors){
      let info: VehicleCharacteristicsModel = {     
        icon: <CaflerIconSettingsModel>{
          name: 'caf-door',
          alt: 'número de puertas',
          width: '11px',
          height: '11px'
        },
        number: settings.numberOfDoors,
        text:'cafler.vehicle-data-display.doors'
      }
      result.push(info)
    }
    if(settings.luggageCapacity){
      let info: VehicleCharacteristicsModel = {     
        icon: <CaflerIconSettingsModel>{
          name: 'caf-luggage',
          alt: 'número de maletas',
          width: '11px',
          height: '11px'
        },
        number: settings.luggageCapacity,
        text:'cafler.vehicle-data-display.luggage'
      }
      result.push(info)
    }

    return result
  }
}
