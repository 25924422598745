import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { isNieValid } from "./validatorsHelper";

export function nieValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    
    const nie = control.value;

    const result = isNieValid(nie)
      ? null
      : { invalidNIEDocumentNumber: true };

    return result; 
  };
}