import { BEProductModel, BECoreDataModel } from '../models';

export const KAMIKAZE_STATE_NAME = 'kamikaze';

export interface AppState {
  isB2B: boolean;
  userData: any;
  coreData: any;
  currentVerticalProducts: BEProductModel[] | null;
  currentZone: any;
  selectedVertical: number;
  bookedOrder: any;
  productType: string;
  productData: any;
  currentPage: string;
  currentLang: string;
  currentCountry: any;
  nextPage: string;
  catalogs: any;
  isFooterVisible: boolean;
  isFormValid: boolean;
  isLoading: boolean;
  isCoreDataLoaded: boolean;
  isVerticalProductsLoaded: boolean;
  isAppInitialized: boolean;
  isCouponValid?: boolean;
  serviceTimeOptions: any[];
  availableToppings: any[];
  selectedToppings: any[];
  currentTopping?: any;
  selectedProduct: any;
  toppingData: any;
  availableToppingHours: any[];
  stations: any[];
  motStations: any[];
  myServices: any[];
  elementsToShowInMyServices: number;
  pageLocationInMyServices: number;
  enumMyServices: number;
  selectedServiceToCancel: any;
  selectedServiceToShow: any;
  selectedServiceToShowData: any;
  toppingsPicked: {code: string, id:string, category: string, serviceConfiguration:any}[]; 
  toppingsAdded: any[];
  toppingToDelete: string;
}

export const appInitialState: AppState = {
  isB2B: false,
  userData: null,
  currentZone: null,
  selectedVertical: 0,
  coreData: null,
  currentVerticalProducts: null,
  bookedOrder: null,
  productData: {},
  productType: '',
  currentPage: '',
  currentLang: '',
  currentCountry: null,
  nextPage: '',
  catalogs: {},
  isFooterVisible: false,
  isFormValid: false,
  isLoading: false,
  isCouponValid: undefined,
  serviceTimeOptions: [],
  isCoreDataLoaded: false,
  isVerticalProductsLoaded: false,
  availableToppings: [],
  selectedToppings: [],
  currentTopping: null,
  isAppInitialized: false,
  selectedProduct: null,
  toppingData: null,
  availableToppingHours: [],
  stations: [],
  motStations: [],
  myServices: [],
  elementsToShowInMyServices: 0,
  pageLocationInMyServices: 0,
  enumMyServices: 0,
  selectedServiceToCancel: null,
  selectedServiceToShow: null,
  selectedServiceToShowData: null,
  toppingsPicked: [],
  toppingsAdded: [],
  toppingToDelete: '',
};
