<form *ngIf="isAppInitialized" class="caf-md-container margin-top-forms" [formGroup]="fg">

    <!-- ESTACION   -->
    <div class="input-item">
        <span class="subtitle-1">{{ 'cafler.page.service-station.title' | translate}}</span>
        <caf-select [settings]="settings.station"></caf-select>
        <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsStation"></caf-error>
    </div>
    <!-- fecha del servicio de recigida-->
    <div class="input-item">
        <span class="subtitle-1">{{'cafler.page.valet.service-date.info.title' | translate}}</span>
        <caf-datepicker [settings]="settings.pickupDate"></caf-datepicker>
        <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsPickupDate"></caf-error>
    </div>
    <!-- hora del servicio de recogida-->
    <div *ngIf="readyToDisplayPickUpHours">   
        <caf-multicolumn-select [settings]="settings.startPickUpTime"></caf-multicolumn-select>
        <caf-error
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settingsErrors.errorsStartPickUpTime"
        ></caf-error>
    </div>
    <!-- fecha del servicio de devolución-->
    <div class="input-item">
        <span class="subtitle-1">{{'cafler.page.service-date-dropoff.info.title' | translate}}</span>
        <caf-datepicker [settings]="settings.dropoffDate"></caf-datepicker>
        <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsDropoffDate"></caf-error>
        <p *ngIf="fg.errors?.dropOffDateIsLaterThanPickOff" class="error-message error-msg">
            {{'cafler.forms.error.dropOffDateIsLaterThanPickOff' | translate }}
        </p>
    </div>
    <!-- hora del servicio de devolución-->
    <div *ngIf="readyToDisplayDropOffHours">   
        <caf-multicolumn-select [settings]="settings.endPickUpTime"></caf-multicolumn-select>
        <caf-error
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settingsErrors.errorsEndPickUpTime"
        ></caf-error>
    </div>
    <p *ngIf="fg.errors?.endPickUpTimeIsLaterThanStartPickUpTime" class="error-message error-msg">
        {{'cafler.forms.error.endPickUpTimeIsLaterThanStartPickUpTime' | translate }}
    </p>
    <!-- contact data-->
    <div class="input-item">
        <span class="subtitle-1">{{'cafler.contact.title' | translate}}</span>
        <caf-input [settings]="settings.fullName"></caf-input>
        <caf-error [settings]="settingsErrors.errorsFullName"></caf-error>
    </div>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
        <caf-select
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settings.countryPhoneCode"
        ></caf-select>
        <caf-input
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settings.phone"
        ></caf-input>
    </div>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
        <caf-error
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settingsErrors.errorsCountryPhoneCode"
        ></caf-error>
        <caf-error
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settingsErrors.errorsPhone"
        ></caf-error>
    </div>
    <span class="phone-message">{{ 'cafler.page.valet.phone.message' | translate}}</span>
    <div *ngIf="!isB2B" class="input-item">
        <caf-input [settings]="settings.email"></caf-input>
        <caf-error [settings]="settingsErrors.errorsEmail"></caf-error>
    </div>
    <!-- vehicle information-->
    <div class="input-item">
        <span class="subtitle-1">{{'cafler.vehicle-info.title' | translate}}</span>
        <caf-select [settings]="settings.vehicleType"></caf-select>
        <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsVehicleType"></caf-error>
      </div>
    <div class="input-item">
        <caf-input [settings]="settings.carPlaque"></caf-input>
        <caf-error [settings]="settingsErrors.errorsCarPlaque"></caf-error>
    </div>
    <div class="input-item">
        <caf-input [settings]="settings.brand"></caf-input>
        <caf-error [settings]="settingsErrors.errorsBrand"></caf-error>
    </div>
    <div class="input-item">
        <caf-input [settings]="settings.model"></caf-input>
        <caf-error [settings]="settingsErrors.errorsModel"></caf-error>
    </div>
    <caf-notification [settings]="notificationSettings"></caf-notification>
    <!-- aditional data-->
    <div class="input-item">
        <span class="subtitle-1">{{'cafler.pickup-info.comments.title' | translate}}</span>
        <caf-textarea [settings]="settings.additionalComments"></caf-textarea>
    </div>
</form>