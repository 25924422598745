<article>
    <div class="vehicle-data-display__data-info__title-container">
        <span>{{settings.brand}} {{settings.model}}</span>
        <p>{{'cafler.vehicle-data-display.similar-label' | translate}}</p>
    </div>
    <div class="container">
        <div class="vehicle-data-display__data-info">
            <div class="grid-section">
                <div *ngFor="let elem of vehicleCharacteristics" class="display-characteristic">
                    <caf-icon [settings]="elem.icon"></caf-icon>
                    <div class="display-characteristic__text-container">
                        <p *ngIf="elem.number">{{elem.number}}</p>
                        <p *ngIf="elem.text">{{elem.text | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
        <section class="vehicle-data-display__img-container">
            <img class="car-image" [src]=settings.image alt="{{settings.brand}} {{settings.model}}" width="133.64px" height="74.75px" >
        </section>
    </div>
</article>