<form class="caf-md-container margin-top-forms" [formGroup]="fg">
  <section *ngIf="currentCountry.RegionIsoCode=== 'esp'" class="input-item">
    <h3 class="subtitle-1">{{"cafler.product.transfer.replacement.title" | translate}}</h3>
    <div class="radio-btn-container" >
      <button class="radio-btn-box" [disabled]='isDuplicated' [ngClass]="{'selected': fg.get(this.settingsRadio.withReplacementVehicle.formControlName)?.value === 'withReplacementVehicle'}"  (click)="changeActiveVehicleContainer('withReplacementVehicle')">    
        <caf-radio-button
        [settings]="settingsRadio.withReplacementVehicle"
        ></caf-radio-button>
      </button>
      <button class="radio-btn-box" [disabled]='isDuplicated' [ngClass]="{'selected': fg.get(this.settingsRadio.withReplacementVehicle.formControlName)?.value  === 'withoutReplacementVehicle'}"  (click)="changeActiveVehicleContainer('withoutReplacementVehicle')">    
        <caf-radio-button
          [settings]="settingsRadio.withoutReplacementVehicle"
          ></caf-radio-button> 
      </button>
    </div>
  </section>

  <h3 *ngIf="replacementMode" class="subtitle-1">{{"cafler.product.transfer.replacement.car.title" | translate}}</h3>
  <section class="card-border" *ngIf="replacementMode">
    <vehicle-data-display [settings]="carDataSettings"></vehicle-data-display>
  </section>

  <section *ngIf="replacementMode">
    <article class="notification-container notification-container__info">
      <caf-icon [settings]="settingsExtra.infoIcon"></caf-icon>
      <p>{{"cafler.replacement.any-doubt" | translate}} <button (click)="openPopUp()">{{"cafler.read-more" | translate}}</button></p>
    </article>
  </section>

  <!-- direccion recogida-->
  <div class="input-item">
    <span class="subtitle-1">{{'cafler.transfer-info.pickup-info.title' | translate}}</span>
    <div
      *ngIf="isB2B"
      class="caf-grid-row split-2-cols split-sm-2-cols"
    >
      <caf-input-google-autosuggest
        (onValidAddress)="onValidAddress($event, settings.pickupAddress.formControlName)"
        (onInvalidAddress)="countPickupErrors()"
        class="col-start-sm-1 col-start-1 col-end-sm-10 col-end-10"
        [settings]="settings.pickupAddress"
      >
      </caf-input-google-autosuggest>
      <button
        class="h-align-center v-align-center complete-address-button col-start-sm-11 col-start-11 col-end-sm-12 col-end-12"
        (click)="getB2BAddress(settings.pickupAddress.formControlName)"
      >
        <caf-icon [settings]="settingsExtra.locationIcon"></caf-icon>
      </button>
    </div>
    <caf-input-google-autosuggest
      *ngIf="!isB2B"
      (onValidAddress)="onValidAddress($event, settings.pickupAddress.formControlName)"
      (onInvalidAddress)="countPickupErrors()"
      [settings]="settings.pickupAddress"
    >
    </caf-input-google-autosuggest>
    <caf-error
      *ngIf="timesFailedPickupAddress<=maxErrorsOnAddress"
      class="extra-margin-error"
      [settings]="settingsErrors.errorsPickupAddress"
    ></caf-error>
    <p *ngIf="timesFailedPickupAddress>maxErrorsOnAddress" class="error-message error-msg">
    {{'cafler.address-message-error-too-many-attempts-pickup.title' | translate }}
    </p>

    <div *ngIf="fg.get('pickupAddress')?.errors?.checkCoordinatesWithinCaflerArea" class="error-message error-msg">
      <div *ngIf="isB2B; else operativeZonesB2C">
        <p>{{'cafler.address.message.error.address-outside-operative-zone.B2B.message' | translate}}</p>
      </div>
      <ng-template #operativeZonesB2C >
        <p>{{'cafler.address.message.error.address-outside-operative-zone.title' | translate }}</p>
        <article class="notification-container notification-container__warning mb-24">
          <caf-icon [settings]="settingsExtra.warningIcon"></caf-icon>
          <p>{{'cafler.address.message.error.address-outside-operative-zone.notification-text' | translate }} <a href="{{operativeZonesURL}}" target="_blank">{{'cafler-operative-zones' | translate}}</a></p>
        </article> 
      </ng-template>
    </div>
    
  </div>
  <div class="input-item">
    <caf-input [settings]="settings.pickupAddressDetails"></caf-input>
  </div>

  <!-- fecha del servicio replacement-->
  <div *ngIf="replacementMode" class="input-item">
    <span class="subtitle-1">{{'cafler.page.service-date.info.title' | translate}}</span>
    <caf-datepicker [settings]="settings.pickupDate"></caf-datepicker>
    <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsPickupDate"></caf-error>
    <p class="error-message" *ngIf="pastDateWhenDuplicatingError">{{'cafler.error.past-date-when-duplication' | translate}}</p>
  </div>

    <!-- hora del servicio-->
    <div *ngIf="readyToDisplayHours && replacementMode">
      <div *ngIf="firstNewFeatureTry && renderNewFeatureSpeechBubble; else noNewFeatureSpeechBubble">
        <span class="subtitle-1 absolutePosition">{{'cafler.page.service-time.info.title' | translate}}</span>
        <caf-speech-bubble *ngIf="firstNewFeatureTry" [settings]="settingsExtra.speechBubble"></caf-speech-bubble>
      </div>
      <ng-template #noNewFeatureSpeechBubble>
        <span class="subtitle-1">{{'cafler.page.service-time.info.title' | translate}}</span>
      </ng-template>
  
      <caf-select [settings]="settings.selectTimeOption"></caf-select>
      <br/>    
        <div *ngIf="timeRangeOption === 'range'" class="caf-grid-row split-2-cols split-sm-2-cols">
          <caf-select #startServiceTimeComponent [settings]="settings.startPickUpTime"></caf-select>
          <caf-select #endServiceTimeComponent [settings]="settings.endPickUpTime"></caf-select>
        </div>
        <div *ngIf="timeRangeOption === 'specific'">
          <caf-multicolumn-select #startServiceTimeComponent [settings]="settings.specificTimeRange"></caf-multicolumn-select>
          <div *ngIf="!noSpecificHourAvailable">
            <div *ngIf="currentCountry.RegionIsoCode === 'esp'">
              <span *ngIf="currentCountry.PreferredRegionCurrency === 1" class="span-2">
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.spain.info_b2b' 
                    : 'cafler.page.service-time.specific-time.spain.info')
                  | translate
                }}
              </span>
              <span *ngIf="currentCountry.PreferredRegionCurrency === 3" class="span-2">
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.spain.info_uk_b2b' 
                    : 'cafler.page.service-time.specific-time.spain.info_uk')
                  | translate
                }}
              </span>
            </div>
  
            <div *ngIf="currentCountry.RegionIsoCode === 'fra'">
              <span *ngIf="currentCountry.PreferredRegionCurrency === 1" class="span-2">
  
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.france.info_b2b' 
                    : 'cafler.page.service-time.specific-time.france.info')
                  | translate
                }}
              </span>
              <span *ngIf="currentCountry.PreferredRegionCurrency === 3" class="span-2">
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.france.info_uk_b2b' 
                    : 'cafler.page.service-time.specific-time.france.info_uk')
                  | translate
                }}
              </span>
            </div>
            
            <div *ngIf="currentCountry.RegionIsoCode === 'gbr'">
              <span *ngIf="currentCountry.PreferredRegionCurrency === 1" class="span-2">
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.uk.info_b2b' 
                    : 'cafler.page.service-time.specific-time.uk.info')
                  | translate
                }}
              </span>
              <span *ngIf="currentCountry.PreferredRegionCurrency === 3" class="span-2">
                {{
                  (isB2B
                    ? 'cafler.page.service-time.specific-time.uk.info_uk_b2b' 
                    : 'cafler.page.service-time.specific-time.uk.info_uk')
                  | translate
                }}
              </span>
            </div>
          </div>
        </div> 
    </div>

  <!-- contact pickup data-->
  <div>
    <div class="input-item">
      <span class="subtitle-1"
        >{{'cafler.page.media-larga-distancia.origin-contact.title' | translate}}</span
      >
      <caf-input [settings]="settings.fullName"></caf-input>
      <caf-error [settings]="settingsErrors.errorsFullName"></caf-error>
    </div>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-select
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settings.countryPhoneCode"
      ></caf-select>
      <caf-input
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settings.phone"
      ></caf-input>
    </div>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-error
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settingsErrors.errorsCountryPhoneCode"
      ></caf-error>
      <caf-error
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settingsErrors.errorsPhone"
      ></caf-error>
    </div>

    <div *ngIf="replacementMode || !isB2B" class="input-item">
      <caf-input [settings]="settings.email"></caf-input>
      <caf-error [settings]="settingsErrors.errorsEmail"></caf-error>
    </div>
  </div>

  <!-- direccion de devolucion-->
  <div class="input-item">
    <span class="subtitle-1">{{'cafler.page.wash.dropoff.info.title' | translate}}</span>
    <div *ngIf="isB2B" class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-input-google-autosuggest
        (onValidAddress)="onValidAddress($event, settings.deliveryAddress.formControlName)"
        (onInvalidAddress)="countDropoffErrors()"
        class="col-start-sm-1 col-start-1 col-end-sm-10 col-end-10"
        [settings]="settings.deliveryAddress"
      >
      </caf-input-google-autosuggest>
      <button
        class="h-align-center v-align-center complete-address-button col-start-sm-11 col-start-11 col-end-sm-12 col-end-12"
        (click)="getB2BAddress(settings.deliveryAddress.formControlName)"
      >
        <caf-icon [settings]="settingsExtra.locationIcon"></caf-icon>
      </button>
    </div>
    <caf-input-google-autosuggest
      (onValidAddress)="onValidAddress($event, settings.deliveryAddress.formControlName)"
      (onInvalidAddress)="countDropoffErrors()"
      *ngIf="!isB2B"
      [settings]="settings.deliveryAddress"
    >
    </caf-input-google-autosuggest>
    <caf-error
      *ngIf="timesFailedDropoffAddress<=maxErrorsOnAddress"
      class="extra-margin-error"
      [settings]="settingsErrors.errorsDropoffAddress"
    ></caf-error>
    <p *ngIf="timesFailedDropoffAddress>maxErrorsOnAddress" class="error-message error-msg">
      {{'cafler.address-message-error-too-many-attempts-dropoff.title' | translate }}
    </p>
    <p *ngIf="fg.errors?.sameAddress" class="error-message error-msg">
      {{'cafler.forms.error.same-address' | translate }}
    </p>
    <div *ngIf="fg.get('deliveryAddress')?.errors?.checkCoordinatesWithinCaflerArea" class="error-message error-msg">
      <div *ngIf="isB2B; else operativeZonesB2C">
        <p>{{'cafler.address.message.error.address-outside-operative-zone.B2B.message' | translate}}</p>
      </div>
      <ng-template #operativeZonesB2C >
        <p>{{'cafler.address.message.error.address-outside-operative-zone.title' | translate }}</p>
        <article class="notification-container notification-container__warning mb-24">
          <caf-icon [settings]="settingsExtra.warningIcon"></caf-icon>
          <p>{{'cafler.address.message.error.address-outside-operative-zone.notification-text' | translate }} <a href="{{operativeZonesURL}}" target="_blank">{{'cafler-operative-zones' | translate}}</a></p>
        </article> 
      </ng-template>
    </div>
  </div>
  <div class="input-item">
    <caf-input [settings]="settings.deliveryAddressDetails"></caf-input>
  </div>
  <!-- contact delivery data-->
  <div>
    <div class="input-item">
      <span class="subtitle-1"
        >{{'cafler.pickup-info.destination-contact.title' | translate}}
      </span>
      <caf-input [settings]="settings.dropoffFullName"></caf-input>
      <caf-error [settings]="settingsErrors.errorsDropoffFullName"></caf-error>
    </div>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-select
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settings.dropoffCountryPhoneCode"
      ></caf-select>
      <caf-input
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settings.dropoffPhone"
      ></caf-input>
    </div>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-error
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settingsErrors.errorsDropOffPhone"
      ></caf-error>
      <caf-error
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settingsErrors.errorsDropoffCountryPhoneCode"
      ></caf-error>
    </div>
    <div *ngIf="!isB2B" class="input-item">
      <caf-input [settings]="settings.dropoffEmail"></caf-input>
      <caf-error [settings]="settingsErrors.errorsDropoffEmail"></caf-error>
    </div>
  </div>

  <!-- fecha del servicio (transfer)-->
  <div *ngIf="!replacementMode" class="input-item">
    <span class="subtitle-1">{{'cafler.page.service-date.info.title' | translate}}</span>
    <caf-datepicker [settings]="settings.pickupDate"></caf-datepicker>
    <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsPickupDate"></caf-error>
  </div>

  <!-- hora del servicio transfer-->
  <div *ngIf="readyToDisplayHours && !replacementMode">
    <div *ngIf="firstNewFeatureTry && renderNewFeatureSpeechBubble; else noNewFeatureSpeechBubble">
      <span class="subtitle-1 absolutePosition">{{'cafler.page.service-time.info.title' | translate}}</span>
      <caf-speech-bubble *ngIf="firstNewFeatureTry" [settings]="settingsExtra.speechBubble"></caf-speech-bubble>
    </div>
    <ng-template #noNewFeatureSpeechBubble>
      <span class="subtitle-1">{{'cafler.page.service-time.info.title' | translate}}</span>
    </ng-template>

    <caf-select [settings]="settings.selectTimeOption"></caf-select>
    <br/>    
    <div *ngIf="timeRangeOption === 'range'" class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-select #startServiceTimeComponent [settings]="settings.startPickUpTime"></caf-select>
      <caf-select #endServiceTimeComponent [settings]="settings.endPickUpTime"></caf-select>
    </div>

      <div *ngIf="timeRangeOption === 'specific'">
        <caf-multicolumn-select #startServiceTimeComponent [settings]="settings.specificTimeRange"></caf-multicolumn-select>
        <div *ngIf="!noSpecificHourAvailable">
          <div *ngIf="currentCountry.RegionIsoCode === 'esp'">
            <span *ngIf="currentCountry.PreferredRegionCurrency === 1" class="span-2">
              {{
                (isB2B
                  ? 'cafler.page.service-time.specific-time.spain.info_b2b' 
                  : 'cafler.page.service-time.specific-time.spain.info')
                | translate
              }}
            </span>
            <span *ngIf="currentCountry.PreferredRegionCurrency === 3" class="span-2">
              {{
                (isB2B
                  ? 'cafler.page.service-time.specific-time.spain.info_uk_b2b' 
                  : 'cafler.page.service-time.specific-time.spain.info_uk')
                | translate
              }}
            </span>
          </div>

          <div *ngIf="currentCountry.RegionIsoCode === 'fra'">
            <span *ngIf="currentCountry.PreferredRegionCurrency === 1" class="span-2">

              {{
                (isB2B
                  ? 'cafler.page.service-time.specific-time.france.info_b2b' 
                  : 'cafler.page.service-time.specific-time.france.info')
                | translate
              }}
            </span>
            <span *ngIf="currentCountry.PreferredRegionCurrency === 3" class="span-2">
              {{
                (isB2B
                  ? 'cafler.page.service-time.specific-time.france.info_uk_b2b' 
                  : 'cafler.page.service-time.specific-time.france.info_uk')
                | translate
              }}
            </span>
          </div>
          
          <div *ngIf="currentCountry.RegionIsoCode === 'gbr'">
            <span *ngIf="currentCountry.PreferredRegionCurrency === 1" class="span-2">
              {{
                (isB2B
                  ? 'cafler.page.service-time.specific-time.uk.info_b2b' 
                  : 'cafler.page.service-time.specific-time.uk.info')
                | translate
              }}
            </span>
            <span *ngIf="currentCountry.PreferredRegionCurrency === 3" class="span-2">
              {{
                (isB2B
                  ? 'cafler.page.service-time.specific-time.uk.info_uk_b2b' 
                  : 'cafler.page.service-time.specific-time.uk.info_uk')
                | translate
              }}
            </span>
          </div>
        </div>

      </div> 
  </div>
  
  <div
    class="caf-grid-row split-2-cols split-sm-2-cols"
    *ngIf="readyToDisplayHours && !replacementMode"
  >
    <caf-error
      *ngIf="!combinationNotValid && !dayChangedFlag"
      class="extra-margin-error col-start-sm-1 col-start-1 col-end-sm-1 col-end-1"
      [settings]="settingsErrors.errorsStartPickUpTime"
    ></caf-error>
    <caf-error
      *ngIf="!combinationNotValid && !dayChangedFlag"
      class="extra-margin-error col-start-sm-2 col-start-2 col-end-sm-2 col-end-2"
      [settings]="settingsErrors.errorsEndPickUpTime"
    ></caf-error>
  </div>

  <div *ngIf="timeRangeOption === 'range' && !replacementMode">
    <p class="error-message" *ngIf="readyToDisplayHours && noRangeHourAvailable">
      {{"cafler.page.service-time.error-range-hours-not-available" | translate}}
    </p>
    <p class="error-message" *ngIf="dayChangedFlag && !noRangeHourAvailable">
      {{"cafler.page.service-time.error-day-changed" | translate}}
    </p>
  </div>

  <div *ngIf="timeRangeOption === 'specific' && !replacementMode">
    <p class="error-message" *ngIf="readyToDisplayHours && noSpecificHourAvailable">
      {{"cafler.page.service-time.error-specific-hours-not-available" | translate}}
    </p>
    <p class="error-message" *ngIf="dayChangedFlag && !noSpecificHourAvailable">
    {{"cafler.page.service-time.error-day-changed" | translate}}
    </p>
    <p class="error-message" *ngIf="duplicateSpecificHourError">{{'cafler.page.service-time.duplication-error-specific-hour-not-available' | translate}}</p>
  </div>

  <p class="error-message" *ngIf="combinationNotValid && !replacementMode">
    {{"cafler.page.service-time.error-not-compatible" | translate}}
  </p>
  <!-- vehicle information-->
  <div class="input-item">
    <span class="subtitle-1">{{'cafler.vehicle-info.title' | translate}}</span>
    <caf-select [settings]="settings.vehicleType"></caf-select>
    <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsVehicleType"></caf-error>
  </div>
  <div class="input-item">
    <caf-input [settings]="settings.carPlaque"></caf-input>
    <caf-error [settings]="settingsErrors.errorsCarPlaque"></caf-error>
  </div>
  <div class="input-item">
    <caf-input [settings]="settings.brand"></caf-input>
    <caf-error [settings]="settingsErrors.errorsBrand"></caf-error>
  </div>
  <div class="input-item">
    <caf-input [settings]="settings.model"></caf-input>
    <caf-error [settings]="settingsErrors.errorsModel"></caf-error>
  </div>

  <!-- days with price for replacement -->
  <div *ngIf="replacementMode">
    <h3 class="subtitle-1">{{"cafler.product.transfer.replacement.configure-car.title" | translate}}</h3>
    <p> {{"cafler.product.transfer.replacement.configure-car.subtitle" | translate}}</p>
    <div class="input-item">
      <caf-select-price [settings]="settings.daysReplacement" ></caf-select-price>
    </div>
    <p class="error-message" *ngIf="fg.get('daysReplacement')?.errors?.required && !fg.get('daysReplacement')?.pristine">
      {{"cafler.forms.error.required" | translate}}
      </p>
  </div>

  <section *ngIf="replacementMode" class="notification-remainder" >
    <caf-notification [settings]="settingsExtra.notificationSettings"></caf-notification>
  </section>

  <!-- same driver radio button -->
  <section *ngIf="replacementMode" class="input-item">
    <p class="subtitle-1">{{"cafler.product.transfer.replacement.same-driver.title" | translate}}</p>
    <p>{{"cafler.product.transfer.replacement.same-driver.subtitle" | translate}}</p>
    <div class="radio-btn-container" >
      <button class="radio-btn-box" [ngClass]="{'selected': fg.get(this.settingsRadio.sameDriver.formControlName)?.value === 'sameDriver'}"  (click)="changeActiveDriverContainer('sameDriver')">    
        <caf-radio-button
        [settings]="settingsRadio.sameDriver"
        ></caf-radio-button>
      </button>
      <button class="radio-btn-box" [ngClass]="{'selected': fg.get(this.settingsRadio.sameDriver.formControlName)?.value  === 'differentDriver'}"  (click)="changeActiveDriverContainer('differentDriver')">    
        <caf-radio-button
          [settings]="settingsRadio.differentDriver"
          ></caf-radio-button> 
      </button>
    </div>
  </section>

  <!-- different driver for replacement -->
  <section *ngIf="replacementMode && differentDriverMode">
  
    <div class="input-item">
      <span class="subtitle-1"
        >{{'cafler.product.transfer.replacement.driver-contact.title' | translate}}
      </span>
      <caf-input [settings]="settings.driverFullName"></caf-input>
      <caf-error [settings]="settingsErrors.errorsDriverFullName"></caf-error>
    </div>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-select
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settings.driverCountryPhoneCode"
      ></caf-select>
      <caf-input
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settings.driverPhone"
      ></caf-input>
    </div>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-error
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settingsErrors.errorsDriverPhone"
      ></caf-error>
      <caf-error
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settingsErrors.errorsDriverCountryPhoneCode"
      ></caf-error>
    </div>
    
    <div class="input-item">
      <caf-input [settings]="settings.driverEmail"></caf-input>
      <caf-error [settings]="settingsErrors.errorsDriverEmail"></caf-error>
    </div>
  </section>


  <!-- aditional data-->
  <div class="input-item">
    <span class="subtitle-1">{{'cafler.pickup-info.comments.title' | translate}}</span>
    <caf-textarea [settings]="settings.additionalComments"></caf-textarea>
  </div>
</form>
