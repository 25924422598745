<form class="caf-md-container margin-top-forms" [formGroup]="fg">
    <!-- product description-->
    <div class="product-info">
      <p>{{"cafler.product.all-you-need-product.description" | translate}}</p>
      <h4 class="subtitle-2">{{"cafler.product.how-does-it-works.title" | translate}}</h4>
      <div class="explanations">
          <div *ngFor="let step of explanation" class="flex-group explanation-container">
              <caf-icon [settings]="step.icon"></caf-icon>
              <p>{{step.text | translate}}</p>
          </div>
      </div>
    </div>

    <caf-notification [settings]="settingsExtra.disclaimerSettings"></caf-notification>

    <div class="input-item form-section">
        <h3 class="subtitle-1">{{'cafler.page.what-do-you-need' | translate}}</h3>
        <caf-textarea [settings]="settings.description" class="textarea"></caf-textarea>
    </div>

    <div class="form-section">
      <h3 class="subtitle-1">{{"cafler.page.when-do-you-need-it.title" | translate}}</h3>
      <!-- fecha del servicio-->
      <div class="input-item">
          <h4 class="subtitle-1">{{'cafler.page.service-date.info.title' | translate}}</h4>
          <caf-datepicker [settings]="settings.pickupDate"></caf-datepicker>
          <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsPickupDate"></caf-error>
      </div>
      
      <div *ngIf="readyToDisplayHours">
          <div class="caf-grid-row split-2-cols split-sm-2-cols">
              <caf-select #startServiceTimeComponent [settings]="settings.startPickUpTime"></caf-select>
              <caf-select #endServiceTimeComponent [settings]="settings.endPickUpTime"></caf-select>
          </div>
      </div>
    </div>
          
    <div class="caf-grid-row split-2-cols split-sm-2-cols" >
        <caf-error
            *ngIf="!combinationNotValid && !dayChangedFlag"
            class="extra-margin-error col-start-sm-1 col-start-1 col-end-sm-1 col-end-1"
            [settings]="settingsErrors.errorsStartPickUpTime"
        ></caf-error>
        <caf-error
            *ngIf="!combinationNotValid && !dayChangedFlag"
            class="extra-margin-error col-start-sm-2 col-start-2 col-end-sm-2 col-end-2"
            [settings]="settingsErrors.errorsEndPickUpTime"
        ></caf-error>
    </div>
        
    <p class="error-message" *ngIf="readyToDisplayHours && noRangeHourAvailable">
        {{"cafler.page.service-time.error-range-hours-not-available" | translate}}
    </p>
    <p class="error-message" *ngIf="dayChangedFlag && !noRangeHourAvailable">
        {{"cafler.page.service-time.error-day-changed" | translate}}
    </p>

    <p class="error-message" *ngIf="combinationNotValid">
        {{"cafler.page.service-time.error-not-compatible" | translate}}
    </p>
    <p *ngIf="readyToDisplayHours" class="disclaimer-text">{{'cafler.hoist.email.disclaimer' | translate}}</p>
    <!-- contact pickup data-->
    <div class="form-section">
        <div class="input-item">
          <h4 class="subtitle-1">{{'cafler.form.contact.information.title' | translate}}</h4>
          <caf-input [settings]="settings.fullName"></caf-input>
          <caf-error [settings]="settingsErrors.errorsFullName"></caf-error>
        </div>
        <div class="caf-grid-row split-2-cols split-sm-2-cols">
          <caf-select
            class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
            [settings]="settings.countryPhoneCode"
          ></caf-select>
          <caf-input
            class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
            [settings]="settings.phone"
          ></caf-input>
        </div>
        <div class="caf-grid-row split-2-cols split-sm-2-cols">
          <caf-error
            class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
            [settings]="settingsErrors.errorsCountryPhoneCode"
          ></caf-error>
          <caf-error
            class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
            [settings]="settingsErrors.errorsPhone"
          ></caf-error>
        </div>
        <div class="input-item">
          <caf-input [settings]="settings.email"></caf-input>
          <caf-error [settings]="settingsErrors.errorsEmail"></caf-error>
        </div>
    </div>
</form>