import { Component, OnInit } from '@angular/core';
import { CaflerTabSettingsModel } from '@cafler/common-ui';
import { NavigationService } from 'src/app/services/navigation.service';
import { Store } from '@ngrx/store';
import { KamikazeActions, setCurrentVertical, setCurrentVerticalProducts, setEnumMyServices } from 'src/app/store/actions';
import { selectCurrentLang, selectCurrentZone, selectIsAppInitialized, selectServices, selectUserId } from 'src/app/store/selectors';
import { CaflerDetailsMyServicesSettingsModel } from '@cafler/common-ui/lib/models/component-models/cafler-details-my-services-settings.model';
import { TextFormatterService } from 'src/app/helpers/text-formatter.service';
import { Router } from '@angular/router';
import { ReplaySubject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DuplicationFormService } from 'src/app/services/duplication-form.service';

@Component({
    selector: 'my-services',
    templateUrl: './my-services.page.html',
    styleUrls: ['./my-services.page.scss'],
  })
  export class MyServicesPage implements OnInit {
    constructor(
      private navigationService: NavigationService,
      private store: Store,
      private textFormatterService: TextFormatterService,
      private router: Router,
      private duplicationFormService: DuplicationFormService
    ) 
    {
      this.store.dispatch(setCurrentVerticalProducts({ verticalProducts: null }));
      this.store.dispatch(setCurrentVertical({ selectedVertical: 0 }));
  
      this.store.select(selectCurrentZone).subscribe((zone: any) => {
        if (zone) {
          this.zone = zone;
        }
      });
    }

    private readonly destroySubjects$ = new ReplaySubject<void>(1);
    zone: any;
    popUpRef: any;
    currentLang!: string;
    userId: any;
    settings: any = {
    todaysServices: <CaflerTabSettingsModel>{
      titleDesktop: 'cafler.my.services.today.desktop',
      titleMobile: 'cafler.my.services.today.mobile',
      isActive: true,
      index: 1,
    },
    futureServices: <CaflerTabSettingsModel>{
      titleDesktop: 'cafler.my.services.future.desktop',
      titleMobile: 'cafler.my.services.future.mobile',
      isActive: false,
      index: 2,
    },
    pastServices: <CaflerTabSettingsModel>{
      titleDesktop: 'cafler.my.services.past.desktop',
      titleMobile: 'cafler.my.services.past.mobile',
      isActive: false,
      index: 3,
    },
    servicesData: <CaflerDetailsMyServicesSettingsModel>{
      serviceEnum: 1,
      formControlName: 'servicesData',
      services: [],
      headers: {
        pickupTime: 'cafler.page.my-services.pickup-time.placeholder',
        pickupDateAndTime: 'cafler.page.my-services.pickup-date-time.placeholder',
        pickupDate:'cafler.page.my-services.pickup-date.placeholder',
        carPlate: 'cafler.page.my-services.car-plate.placeholder',
        serviceType: 'cafler.page.my-services.servie-type.placeholder',
        pickupReturnAddress: 'cafler.page.my-services.pickup-return-address.placeholder',
        serviceStatus: 'cafler.page.my-services.status.placeholder'
      },
      emptyState:{
        titleToday: 'cafler.empty.state.my.services.today.title',
        descriptionToday: 'cafler.empty.state.my.services.today.description',
        titleFuture: 'cafler.empty.state.my.services.future.title',
        descriptionFuture: 'cafler.empty.state.my.services.future.description',
        titlePast: 'cafler.empty.state.my.services.past.title',
        descriptionPast: 'cafler.empty.state.my.services.past.description'
      }
    }
    }
    ngOnInit() {
      this.store.dispatch(setEnumMyServices({enumMyServices : 1}))
      this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'my-services' });
      this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: false });
      this.navigationService.currentPageTitle = 'cafler.page.my-services.title';

      this.store.select(selectIsAppInitialized).subscribe((isAppInitialized) => {
        if (isAppInitialized) {
          this.store.dispatch({ type: KamikazeActions.GetServices });
      }});

      this.store.select(selectCurrentLang).subscribe((lang) => {
        this.currentLang = lang;
      });

      this.store.select(selectServices).subscribe((res:any[])=>{
        if(res){
          const parsedServices = this.textFormatterService.convertObjectToCamelCase(
            res,
            this.textFormatterService.pascalCaseToCamelCase,
          )
          // set subtype of mot
          parsedServices.map((service:any)=>{
            if(service.serviceSubType) {
              service.serviceType = service.serviceSubType;
            }
          });
          this.settings.servicesData.services = parsedServices;
        }
      })

      timer(300000)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe(()=>{
        window.location.reload();
     })
    }

    redirectToCaspio(){
      this.store.select(selectUserId).subscribe((res:any) =>{
        this.userId = res
        const caspioUrl = `https://c2acf308.caspio.com/dp/804e90004641d30e71bb41fcad3b?ClientId=${this.userId}`;
        window.open(caspioUrl, '_blank');
      })
    }

    onTabSelected(tabIndex: number){
      this.store.dispatch(setEnumMyServices({enumMyServices : tabIndex}))
      this.store.dispatch({ type: KamikazeActions.GetServices });
      this.settings.servicesData.serviceEnum = tabIndex;
    }

    moreDetails(data: any){
      this.store.dispatch({ type: KamikazeActions.ShowService, selectedServiceToShow: data });
      this.router.navigate(['/my-services/service-details']);
    }

    ngOnDestroy() {
      this.destroySubjects$.next();
      this.destroySubjects$.complete();
      if(this.popUpRef){
        this.popUpRef.close();
      }
    }

    duplicateService (data: any){
      this.duplicationFormService.duplicateForm(data, this.zone, true);
    }
  }