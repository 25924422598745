import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

declare let gtag: Function;

@Injectable()
export class NavigationService {
  private history: string[] = [];
  private currentTitle: BehaviorSubject<string> = new BehaviorSubject<string>('Home');

  constructor(private location: Location, private router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(): void {
    this.location.back();
  }

  goBackToMyService(): void {
    this.router.navigateByUrl('/my-services')
  }

  getCurrentPageTitle(): Observable<string> {
    return this.currentTitle.asObservable();
  }

  set currentPageTitle(title: string) {
    this.currentTitle.next(title);
  }

  getSelectedProduct() {
    return this.router.routerState.snapshot.url.split('/').pop() || '';
  }

  getB2B10DataAsync(): Observable<any> {
    return this.route.queryParams.pipe(
      map((params) => {
        const data = params['b2bParams'] || '{}';
        return JSON.parse(data);
      }),
    );
  }

  getB2B10Data() {
    let queryParams = this.route.snapshot.queryParams;
    let data = queryParams['b2bParams'] || '{}';
    return JSON.parse(data);
  }

  getParam(param: string) {
    return this.route.queryParams.pipe(
      map((params) => {
        const data = params[param];
        return data;
      }),
    );
  }
  scrollToTop(): void {
    const header = document.querySelector('header');
    if (header) header.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  redirectIfB2B() {}
}
