import { createSelector } from '@ngrx/store';
import { AppState, KAMIKAZE_STATE_NAME } from './state';
import { BEEnumModel, BEZoneModel } from '../models';

export const selectKamikazeState = (state: any) => state[KAMIKAZE_STATE_NAME];

export const selectCurrentZoneId = createSelector(
  selectKamikazeState,
  (state: AppState) => state.currentZone?.ZoneId,
);

export const selectCurrentVertical = createSelector(
  selectKamikazeState,
  (state: AppState) => state.selectedVertical,
);

export const selectFinancialData = createSelector(
  selectKamikazeState,
  (state: AppState) => state,
);

export const selectFormValidity = createSelector(
  selectKamikazeState,
  (state: AppState) => state.isFormValid,
);

export const selectFooterVisibility = createSelector(
  selectKamikazeState,
  (state: AppState) => state.isFooterVisible,
);

export const selectCurrentPage = createSelector(
  selectKamikazeState,
  (state: AppState) => state.currentPage,
);

export const selectCurrentLang = createSelector(
  selectKamikazeState,
  (state: AppState) => state.currentLang,
);

export const selectCurrentCountry = createSelector(
  selectKamikazeState,
  (state: AppState) => state.currentCountry,
);

export const selectNextPage = createSelector(
  selectKamikazeState,
  (state: AppState) => state.nextPage,
);

export const selectCatalog = (catalog: string) =>
  createSelector(selectKamikazeState, (state: AppState) => state.catalogs[catalog]);

export const selectProductData = createSelector(
  selectKamikazeState,
  // @ts-ignore
  (state: AppState) => state.productData,
);

export const selectToppingData = createSelector(
  selectKamikazeState,
  (state: AppState) => state.toppingData,
);

export const selectProductType = createSelector(
  selectKamikazeState,
  (state: AppState) => state.productType,
);

export const selectCoreData = createSelector(
  selectKamikazeState,
  (state: AppState) => state.coreData,
);

export const selectCoreDataEnum = (catalog: string) =>
  createSelector(selectKamikazeState, (state: AppState) =>
    state.coreData?.Enums.find((item: BEEnumModel) => item.Name === catalog),
  );

export const selectCoreDataZones = createSelector(
  selectKamikazeState,
  (state: AppState) => state.coreData?.Zones,
);

export const selectStations = createSelector(
  selectKamikazeState,
  (state: AppState) => state.stations,
);

export const selectMotStations = createSelector(
  selectKamikazeState,
  (state: AppState) => state.motStations,
);

export const selectCoreDataRegions = createSelector(
  selectKamikazeState,
  (state: AppState) => state.coreData?.Regions,
);

export const selectZonesFromSelectedRegion = createSelector(
  selectKamikazeState,
  selectCurrentCountry,
  (state: AppState, selectedCountry) => {
    if (selectedCountry) {
      let selectedRegion = state.coreData?.Regions.find((region: any) => {
        return region.RegionIsoCode === selectedCountry.RegionIsoCode;
      });
      return selectedRegion.Zones;
    }
  },
);

export const selectCoreDataZone = (zone: string) =>
  createSelector(selectKamikazeState, (state: AppState) =>
    state.coreData?.Zones.find((item: BEZoneModel) => item.Id === zone),
  );

export const selectCurrentZone = createSelector(
  selectKamikazeState,
  (state: AppState) =>
    // state.coreData?.Zones.find((item: BEZoneModel) => item.Id === state.currentZoneId),
    state.currentZone,
);

export const selectCurrentVerticalProducts = createSelector(
  selectKamikazeState,
  (state: AppState) => state.currentVerticalProducts,
);

export const selectBookedOrder = createSelector(
  selectKamikazeState,
  (state: AppState) => state.bookedOrder,
);

export const selectIsLoading = createSelector(
  selectKamikazeState,
  (state: AppState) => state.isLoading,
);

export const selectIsCoreDataLoaded = createSelector(
  selectKamikazeState,
  (state: AppState) => state.isCoreDataLoaded,
);

export const selectIsVerticalProductsLoaded = createSelector(
  selectKamikazeState,
  (state: AppState) => state.isVerticalProductsLoaded,
);

export const selectPaymentHashes = createSelector(selectKamikazeState, (state: AppState) => ({
  PaymentHash: state.bookedOrder?.PaymentData?.PaymentHash,
  OrderHash: state.bookedOrder?.OrderHash,
}));

export const selectIsAppInitialized = createSelector(
  selectKamikazeState,
  (state: AppState) => state.isAppInitialized,
);

export const selectIsB2B = createSelector(selectKamikazeState, (state: AppState) => state.isB2B);

export const selectUserData = createSelector(
  selectKamikazeState,
  (state: AppState) => state.userData,
);

export const selectToppingToDelete = createSelector(
  selectKamikazeState,
  (state: AppState) => state.toppingToDelete,
);

export const selectToppingsPicked = createSelector(
  selectKamikazeState,
  (state: AppState) => state.toppingsPicked,
);

export const selectToppingsAdded = createSelector(
  selectKamikazeState,
  (state: AppState) => state.toppingsAdded,
);
export const selectCouponCode = createSelector(
  selectKamikazeState,
  (state: AppState) => state.bookedOrder?.couponCode,
);

export const selectServiceTimeOptions = createSelector(
  selectKamikazeState,
  (state: AppState) => state.serviceTimeOptions,
);

// que hace esto?
// export const selectVerticalRegion = createSelector(
//   selectKamikazeState,
//   (state: AppState) => state.coreData?.Zones.find((z:any) => z.Id === state.currentZoneId)?.RegionCode,
// );

export const selectIsCouponValid = createSelector(
  selectKamikazeState,
  (state: AppState) => state.isCouponValid,
);

export const selectAvailableToppings = createSelector(
  selectKamikazeState,
  (state: AppState) => state.availableToppings,
);
export const isProductPayable = createSelector(
  selectKamikazeState,
  (state: AppState) => state.selectedProduct?.isPaymentRequired,
);

export const selectSelectedProduct = createSelector(
  selectKamikazeState,
  (state: AppState) => state.selectedProduct,
);

export const selectSelectedToppings = createSelector(
  selectKamikazeState,
  (state: AppState) => state.selectedToppings,
);

export const selectCurrentTopping = createSelector(
  selectKamikazeState,
  (state: AppState) => state.currentTopping,
);

export const selectAvailableToppingHours = createSelector(
  selectKamikazeState,
  (state: AppState) => state.availableToppingHours,
);

export const selectServices = createSelector(
  selectKamikazeState,
  (state: AppState) => state.myServices,
);

export const selectElementsToShowInMyServices = createSelector(
  selectKamikazeState,
  (state: AppState) => state.elementsToShowInMyServices,
);

export const selectPageLocationInMyServices = createSelector(
  selectKamikazeState,
  (state: AppState) => state.pageLocationInMyServices,
);

export const selectEnumMyServices= createSelector(
  selectKamikazeState, 
  (state: AppState) =>
    state.enumMyServices,
);

export const selectedServiceForCancel = createSelector(
  selectKamikazeState,
  (state: AppState) => state.selectedServiceToCancel,
)

export const selectUserId = createSelector(
  selectKamikazeState, 
  (state: AppState) =>
    state.userData?.userId
);

export const selectCaflerHashToBeCancelled = createSelector(
  selectKamikazeState, 
  (state: AppState) =>
    state.selectedServiceToCancel?.caflerHash,
);

export const selectServiceToShow = createSelector(
  selectKamikazeState, 
  (state: AppState) =>
    state.selectedServiceToShow,
);

export const selectServiceToShowData = createSelector(
  selectKamikazeState, 
  (state: AppState) =>
    state.selectedServiceToShowData,
);
