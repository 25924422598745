<div *ngIf="!isB2B">
  <div class="caf-md-container checkout-wrapper">
    <div class="padding-top-bottom">
      <div class="overview-card total-price">
        <span>
          {{ orderDetails.infoTotalPrice.label | translate }}&nbsp;
          <span class="iva">{{ 'cafler.page.overview.iva' | translate }}</span>
        </span>
        <span class="description">{{ orderDetails.infoTotalPrice.value || '-' }}</span>
      </div>
    </div>
    <span class="subtitle-2">{{ 'cafler.page.checkout.payment.method' | translate}}</span>
    <caf-payment-checkout
      *ngIf="iframeRestarted"
      class="padding-top-bottom"
      [paymentId]="paymentId"
      [paymentData]="paymentData"
      [amount]="amount"
      [paymentHashes]="paymentHashes"
      [btnSettings]="btnSettings"
      (paymentFailed)="onPayFailed($event)"
      (paymentRefused)="onPayCancelled($event)"
      (paymentSucceeded)="onPaySucceeded($event)"
    ></caf-payment-checkout>
  </div>
</div>
