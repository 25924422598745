import {
  enableProdMode
} from "@angular/core";
import {
  platformBrowserDynamic
} from "@angular/platform-browser-dynamic";

import * as Sentry from "@sentry/angular-ivy";
import { environment } from './environments/environment';
import { AppModule } from "./app/app.module";

Sentry.init({
  environment: environment.sentryEnvironment,
  dsn: environment.sentryDSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  tracePropagationTargets: ["localhost", "https://products.cafler.com", "https://products.development.cafler.com"],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log('Bootstrap success'))
  .catch((err) => console.error(err));
