<caf-progress-bar class="show-on-large caf-progress-bar" [settings]="progressBar">
</caf-progress-bar>
<div class="stepper-wrapper">
  <a href="https://cafler.com/">
    <img src="../assets/svg/logo.svg" alt="Logo Cafler" />
  </a>
  <div>
    <div *ngFor="let step of stepSideBar.stepsLabels; let i = index" class="steps-wrapper">
      <div class="dot" [class]="returnSpotClass(stepSideBar.progress, i + 1)">
        <span>{{ i + 1 }}</span>
      </div>
      <span class="step-description"> {{ step | translate }}</span>
      <div
        class="line"
        [class]="returnLineClass(stepSideBar.progress, i + 1, stepSideBar.stepsLabels.length)"
      ></div>
    </div>
  </div>
</div>
