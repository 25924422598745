import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  addSelectedTopping,
  continueWithNoToppings,
  KamikazeActions,
  setCurrentTopping,
} from '../../store/actions';
import { PresentationFacade } from '../../facades/presentation.facade';
import { ReplaySubject } from 'rxjs';
import { CaflerButtonSettingsModel } from '@cafler/common-ui';
import { selectCurrentTopping } from '../../store/selectors';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-product-selection',
  templateUrl: './topping-selection.page.html',
  styleUrls: ['./topping-selection.page.scss'],
})
export class ToppingSelectionPage implements OnInit, OnDestroy {
  isLoading$!: any;
  private readonly destroySubjects$ = new ReplaySubject<void>(1);

  mobileSize!: boolean;
  fg: FormGroup = new FormGroup({});
  availableToppings!: any[];
  noToppingButton = <CaflerButtonSettingsModel>{
    id: 'no-topping',
    text: 'No Topping',
  };

  // eslint-disable-next-line new-cap
  @HostListener('window:resize', ['$event'])
  sizeChange(event: any) {
    this.mobileSize = event.target.outerWidth <= 768;
  }

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private store: Store,
    private presentationFacade: PresentationFacade,
    private activatedRoute: ActivatedRoute,
  ) {
    this.isLoading$ = this.presentationFacade.isLoading$;
  }

  ngOnInit() {
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: false });
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'topping-selection' });
    this.store.dispatch({ type: KamikazeActions.SetNextPage, nextPage: 'overview' });
    this.store.dispatch({ type: KamikazeActions.ClearSelectedToppings });
    this.navigationService.scrollToTop();
    this.navigationService.currentPageTitle = 'cafler.page.toppings.title';

    this.mobileSize = window.innerWidth <= 768;

    this.presentationFacade.getCurrentAvailableToppings().subscribe((availableToppings) => {
      this.availableToppings = availableToppings;
    });
  }

  pickTopping(toppingId: any) {
    const selectedTopping: any = this.availableToppings.find(
      (topping: any) => topping.id === toppingId,
    );
    this.store.dispatch(setCurrentTopping({ currentTopping: selectedTopping }));
  }

  continueWithoutTopping() {
    // this.store.dispatch(continueWithNoToppings());
    this.store.dispatch(continueWithNoToppings());
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }
}
