import { Pipe } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'customCurrencyPipe',
})
export class CustomCurrencyPipe extends CurrencyPipe {
  convert(value: number | string, currencyCode?: string): string {
    const currencyFormat = super.transform(value, currencyCode);
    return currencyFormat?.replace(/\s/g, '') || '';
  }
}
