import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CancelPopupRef } from './cancel-popup-ref';
import { CaflerButtonSettingsModel, CaflerIconSettingsModel } from '@cafler/common-ui';
import { CancelPopupSettingsModel } from './models/cancel-popup-settings.model';

@Component({
  selector: 'cancel-popup',
  templateUrl: './cancel-popup.component.html',
  styleUrls: ['./cancel-popup.component.scss', './popup.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CancelPopupComponent implements OnInit {
  contentType: 'template' | 'string' | 'component' | undefined;
  content: any;
  context: any;
  settings!: CancelPopupSettingsModel;
  confirmButton = <CaflerButtonSettingsModel>{
    id: 'okButton',
    text: 'Aceptar',
  };
  cancelButton = <CaflerButtonSettingsModel>{
    id: 'noButton',
    text: 'Cancelar',
  };
  closeIcon = <CaflerIconSettingsModel>{
    name: 'caf-close-dark',
    alt: 'Close icon',
  };
  constructor(private ref: CancelPopupRef) {}

  cancel() {
    this.ref.close({ info: 'cancel' });
  }
  confirm() {
    this.ref.close({ info: 'confirm' });
  }
  
  ngOnInit() {
    this.settings = this.ref.data;
    if (this.settings.cancelButtonText) this.cancelButton.text = this.settings.cancelButtonText;
    if (this.settings.confirmButtonText) this.confirmButton.text = this.settings.confirmButtonText;
  }
}
