import {
  ChangeDetectorRef,
  Inject,
  Injectable,
  Injector,
  NgZone,
  TemplateRef,
  Type,
} from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { NavigablePopupComponent } from '../navigable-popup.component';
import { NavigablePopUpRef } from '../navigable-popup-ref';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class NavigablePopupComponentService {
  constructor(
    private overlay: Overlay,
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  open<R = any, T = any>(
    content: string | TemplateRef<any> | Type<any>,
    data: any,
  ): NavigablePopUpRef<R> {
    const configs = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['modal', 'is-active'],
      backdropClass: 'popup-background',
    });
    content = content ? content : 'Default text.';
    const overlayRef = this.overlay.create(configs);

    const cafOverlayRef = new NavigablePopUpRef<R, T>(overlayRef, content, data);

    const injector = this.createInjector(cafOverlayRef, this.injector);

    overlayRef.attach(new ComponentPortal(NavigablePopupComponent, null, injector));

    this.document.body.classList.add('scroll-lock');

    return cafOverlayRef;
  }

  createInjector(ref: NavigablePopUpRef, inj: Injector) {
    const injectorTokens = new WeakMap([[NavigablePopUpRef, ref]]);
    return new PortalInjector(inj, injectorTokens); // TODO -> Fix deprecation
  }

  enableScroll() {
    this.document.body.classList.remove('scroll-lock');
  }
}
