import { VerticalsTypeEnum } from './Enums/verticalsType.enum';
import { CardData } from './models/types';

export const vehicleStatusOptions = [
  { key: 1, text: 'cafler.vehicle-info.status.options.1' },
  { key: 2, text: 'cafler.vehicle-info.status.options.2' },
  { key: 3, text: 'cafler.vehicle-info.status.options.3' },
];

export const AllYouNeedProductVertical: CardData = {
  id: VerticalsTypeEnum.ALL_YOU_NEED,
  alt: 'cafler.product.all-you-need.alt',
  description: 'cafler.product.all-you-need.description',
  icon: {
    alt: 'cafler.product.all-you-need.icon.alt',
    height: '55px',
    name: 'caf-magic-hat-3D',
    width: '55px',
  },
  moreDetails: false,
  price: '',
  fromPriceKey: 'cafler_tyres_placeholder_prices',
  title: 'cafler.product.all-you-need.title',
  moreDetailsText: '',
};

export const nulledCities = ['Zaragoza', 'Bilbao', 'Alicante'];
export const nulledVehicles = [20, 21];
