<div class="container">
    <p class="breadcrumb"><span (click)="back()" class="pointer">{{'cafler.toppings.breadbcrumb.main-menu' | translate }}</span><caf-icon class="rotate-chevron" [settings]="arrowIcon"></caf-icon> {{'cafler.toppings.breadbcrumb.refuel-menu' | translate}}</p>
    
    <div class="flex-group">
        <h3 class="title">{{'cafler.toppings.breadbcrumb.refuel-menu' | translate}}</h3>
        <p>{{'cafler.toppings.refuel.price-label' | translate}}:</p>
        <div *ngIf="currentCountry.RegionIsoCode === 'esp'" class="flex-group">
            <p class="price-title">4,99{{currentCountry.PreferredRegionCurrencySymbol}}</p>
            <p *ngIf="isB2B" class="iva-no-included">({{'cafler.tax-fee-included' | translate}})</p>
        </div>
        <div *ngIf="currentCountry.RegionIsoCode !== 'esp'"  class="flex-group">
            <p class="price-title">6,99{{currentCountry.PreferredRegionCurrencySymbol}}</p> 
            <p *ngIf="isB2B" class="iva-no-included">({{'cafler.tax-fee-included' | translate}})</p>             
        </div>
    </div>
    
    <p>{{'cafler.toppings.refuel.description' | translate}}</p>

    <div class="product-container">
        <caf-notification *ngIf="showError" [settings]="errorSettings"></caf-notification>

        <h3 class="subtitle-1">{{'cafler.toppings.refuel.chose-type.title' | translate}}</h3>
    
        <div>
            <div *ngIf="currentCountry.RegionIsoCode === 'esp'"  class="fuel-types">
                <div class="fuel-type-button" [ngClass]="settings.type === VehicleFuelType.FUEL95 ? 'selected' : ''" (click)="setType(VehicleFuelType.FUEL95)">{{'cafler.toppings.refuel.type.gasoline_95' | translate}}</div>
                <div class="fuel-type-button" [ngClass]="settings.type === VehicleFuelType.FUEL98 ? 'selected' : ''" (click)="setType(VehicleFuelType.FUEL98)">{{'cafler.toppings.refuel.type.gasoline_98' | translate}}</div>
                <div class="fuel-type-button" [ngClass]="settings.type === VehicleFuelType.DIESEL ? 'selected' : ''" (click)="setType(VehicleFuelType.DIESEL)">{{'cafler.toppings.refuel.type.diesel_e' | translate}}</div>
                <div class="fuel-type-button" [ngClass]="settings.type === VehicleFuelType.DIESEL_PREMIUM ? 'selected' : ''" (click)="setType(VehicleFuelType.DIESEL_PREMIUM)">{{'cafler.toppings.refuel.type.diesel_e10' | translate}}</div>
            </div>
    
            <div *ngIf="currentCountry.RegionIsoCode === 'gbr'" class="fuel-types">
                <div class="fuel-type-button" [ngClass]="settings.type === VehicleFuelType.FUEL95 ? 'selected' : ''" (click)="setType(VehicleFuelType.FUEL95)">{{'cafler.toppings.refuel.type.gasoline_95' | translate}}</div>
                <div class="fuel-type-button" [ngClass]="settings.type === VehicleFuelType.FUEL98 ? 'selected' : ''" (click)="setType(VehicleFuelType.FUEL98)">{{'cafler.toppings.refuel.type.gasoline_98' | translate}}</div>
                <div class="fuel-type-button" [ngClass]="settings.type === VehicleFuelType.DIESEL ? 'selected' : ''" (click)="setType(VehicleFuelType.DIESEL)">{{'cafler.toppings.refuel.type.diesel_e' | translate}}</div>
            </div>
    
            <div *ngIf="currentCountry.RegionIsoCode === 'fra'" class="fuel-types">
                <div class="fuel-type-button" [ngClass]="settings.type === VehicleFuelType.FUEL95 ? 'selected' : ''" (click)="setType(VehicleFuelType.FUEL95)">{{'cafler.toppings.refuel.type.gasoline_95' | translate}}</div>
                <div class="fuel-type-button" [ngClass]="settings.type === VehicleFuelType.FUEL95E10 ? 'selected' : ''" (click)="setType(VehicleFuelType.FUEL95E10)">{{'cafler.toppings.refuel.type.gasoline_95E10' | translate}}</div>
                <div class="fuel-type-button" [ngClass]="settings.type === VehicleFuelType.FUEL98 ? 'selected' : ''" (click)="setType(VehicleFuelType.FUEL98)">{{'cafler.toppings.refuel.type.gasoline_98' | translate}}</div>
                <div class="fuel-type-button" [ngClass]="settings.type === VehicleFuelType.DIESEL ? 'selected' : ''" (click)="setType(VehicleFuelType.DIESEL)">{{'cafler.toppings.refuel.type.diesel_e' | translate}}</div>
            </div>
        </div>
    
        <h3 class="subtitle-1">{{'cafler.toppings.refuel.chose-amount.title' | translate}}</h3>
            
        <div *ngIf="settings.vehicleType === 'motorbike'; else carPrices">
            <div class="fuel-amount">
                <button class="fuel-amount-button button-border" [ngClass]="settings.amount === 5 ? 'selected' : ''" (click)="setAmount(5)">
                    <div class="fuel-amount-button__content">
                        5{{currentCountry.PreferredRegionCurrencySymbol}}
                        <p *ngIf="isB2B" class="fuel-amount-button__tax-fee">{{'cafler.tax-fee-included' | translate}}</p>
                    </div>
                </button>
                <button class="fuel-amount-button button-border" [ngClass]="settings.amount === 10 ? 'selected' : ''" (click)="setAmount(10)">
                    <div class="fuel-amount-button__content">
                        10{{currentCountry.PreferredRegionCurrencySymbol}}
                        <p *ngIf="isB2B" class="fuel-amount-button__tax-fee">{{'cafler.tax-fee-included' | translate}}</p>
                    </div>
                </button>
                <button class="fuel-amount-button button-border" [ngClass]="settings.amount === 15 ? 'selected' : ''" (click)="setAmount(15)">
                    <div class="fuel-amount-button__content">
                        15{{currentCountry.PreferredRegionCurrencySymbol}}
                        <p *ngIf="isB2B" class="fuel-amount-button__tax-fee">{{'cafler.tax-fee-included' | translate}}</p>
                    </div>
                </button>
                <button class="fuel-amount-button button-border" [ngClass]="settings.amount === 20 ? 'selected' : ''" (click)="setAmount(20)">
                    <div class="fuel-amount-button__content">
                        20{{currentCountry.PreferredRegionCurrencySymbol}}
                        <p *ngIf="isB2B" class="fuel-amount-button__tax-fee">{{'cafler.tax-fee-included' | translate}}</p>
                    </div>
                </button>
            </div>
        </div>
        <ng-template #carPrices>
            <div class="fuel-amount">
                <button class="fuel-amount-button button-border" [ngClass]="settings.amount === 10 ? 'selected' : ''" (click)="setAmount(10)">
                    <div class="fuel-amount-button__content">
                        10{{currentCountry.PreferredRegionCurrencySymbol}}
                        <p *ngIf="isB2B" class="fuel-amount-button__tax-fee">{{'cafler.tax-fee-included' | translate}}</p>
                    </div>
                </button>
                <button class="fuel-amount-button button-border" [ngClass]="settings.amount === 20 ? 'selected' : ''" (click)="setAmount(20)">
                    <div class="fuel-amount-button__content">
                        20{{currentCountry.PreferredRegionCurrencySymbol}}
                        <p *ngIf="isB2B" class="fuel-amount-button__tax-fee">{{'cafler.tax-fee-included' | translate}}</p>
                    </div>
                </button>
                <button class="fuel-amount-button button-border" [ngClass]="settings.amount === 50 ? 'selected' : ''" (click)="setAmount(50)">
                    <div class="fuel-amount-button__content">
                        50{{currentCountry.PreferredRegionCurrencySymbol}}
                        <p *ngIf="isB2B" class="fuel-amount-button__tax-fee">{{'cafler.tax-fee-included' | translate}}</p>
                    </div>
                </button>
                <button class="fuel-amount-button button-border" [ngClass]="settings.amount === 80 ? 'selected' : ''" (click)="setAmount(80)">
                    <div class="fuel-amount-button__content">
                        80{{currentCountry.PreferredRegionCurrencySymbol}}
                        <p *ngIf="isB2B" class="fuel-amount-button__tax-fee">{{'cafler.tax-fee-included' | translate}}</p>
                    </div>
                </button>
            </div>
        </ng-template>
    
        <caf-notification [settings]="notificationSettings"></caf-notification>
    
    </div>
    
    <footer>
        <caf-button
        class="caf-btn-secondary caf-btn-desk-small caf-btn-mob-extra-small"        (onClick)="back()"
        [settings]="button.buttonBack"></caf-button>
        <caf-button
        class="caf-btn-complementary caf-btn-desk-small caf-btn-mob-extra-small"        (onClick)="save()"
        [settings]="button.buttonSubmit"
    ></caf-button>
    </footer>
</div>