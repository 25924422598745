<div class="noToppingsContainer" *ngIf="noToppingsAvailable; else toppings">
  <img class="error-no-service-icon" id="noToppingsAvailableIcon" [src]="noToppingsAvailableIcon" />
  
  <div class="caf-popup-overlay__body">
    <p>{{'cafler.toppings.not-available-toppings' | translate}}</p>
  </div>
  <div class="caf-popup-overlay__footer">
    <caf-button
      (onClick)="cancel()"
      class="caf-btn-primary caf-btn-desk-small caf-btn-mob-small"
      [settings]="backButton"
  ></caf-button>
  </div>
</div>

<ng-template #toppings>
  <header class="header-container--sticky">
    <h3>{{'cafler.toppings.add-services-title' | translate}}</h3>
    <caf-icon class="pointer" (click)="cancel()" [settings]="closeIcon"></caf-icon>
  </header>
  <div [ngSwitch]="page">
    <div *ngSwitchCase="'Home'" class='cards-container'>
      <div *ngFor="let topping of displayToppings;" 
        [ngSwitch]="topping.displayType" class="menu-container">
        <app-menu-card *ngSwitchCase="displayType.CARD" [settings] = "topping.settings" (productSelected)= productSelect($event)></app-menu-card>
        <app-topping-selector *ngSwitchCase="displayType.SELECTOR" [settings] = 'topping.settings' (productSelected)="productSelection($event)"></app-topping-selector>
      </div>
      <button
        (click)="sendToppings()"
        class='addToppingsButton'
      >{{'cafler.toppings-add-services-button' | translate }}</button>
    </div>
    <div *ngSwitchCase="toppingsEnum.REFUEL">
      <refuel-topping-configurator 
        [settings]="refuelToppingSettings"
        (onGoBack)="setPage('Home')"
        (onSubmitRefuel)="addRefuel($event)"
        (onSubmitEmpty)="deleteRefuel($event)" >
      </refuel-topping-configurator>
    </div>
    <div *ngSwitchCase="toppingsEnum.WASH">
      <app-wash-topping-configurator
        [settings]="washingToppingSettings"
        (onSubmit)="addWashing($event)" 
        (onGoBack)="setPage('Home')">
      </app-wash-topping-configurator>
    </div>

    <div *ngSwitchCase="toppingsEnum.INSURANCE">
      <app-insurance-topping-configurator
        [settings]="insuranceToppingSettings"
        (onGoBack)="setPage('Home')"
        (onSubmit)="addInsurance($event)">
      </app-insurance-topping-configurator>
    </div>
    
  </div>
</ng-template>


