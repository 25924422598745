import { Injectable } from '@angular/core';
import { KamikazeFacade } from '../facades/kamikaze.facade';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  addSelectedTopping,
  addSelectedToppingSuccess,
  cancelServiceSuccess,
  checkIfThereIsAZone,
  continueWithNoToppings,
  fetchAvailableVerticalProducts,
  getAvailableHoursReturnSucess,
  getServicesSuccess,
  initializeAppB2BSuccess,
  KamikazeActions,
  loadDataB2B,
  setCurrentCountry,
  setCurrentZone,
  setElementsAmount,
  setEnumMyServices,
  setPageLocation,
  setUserId,
  showServiceSuccess,
  storeCoreData,
} from './actions';
import { catchError, first, map, skip, switchMap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import {
  selectAvailableToppings,
  selectBookedOrder,
  selectCaflerHashToBeCancelled,
  selectCoreDataEnum,
  selectCoreDataZones,
  selectCouponCode,
  selectCurrentLang,
  selectCurrentTopping,
  selectCurrentVertical,
  selectCurrentZoneId,
  selectElementsToShowInMyServices,
  selectEnumMyServices,
  selectIsB2B,
  selectNextPage,
  selectPageLocationInMyServices,
  selectPaymentHashes,
  selectProductData,
  selectSelectedProduct,
  selectServiceToShow,
  selectToppingData,
  selectToppingsAdded,
  selectToppingsPicked,
  selectToppingToDelete,
  selectUserData,
  selectUserId,
} from './selectors';
import { Store } from '@ngrx/store';
import { NavigationService } from '../services/navigation.service';
import { environment } from '../../environments/environment';
import { HelperService } from '../services/helper.service';
import { PresentationFacade } from '../facades/presentation.facade';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageCodesEnum } from '../config/languageCodes.enum';
import { BECouponModel } from '../models';
import { setIsLoading } from '../store/actions';
import { CaflerIconSettingsModel, CaflerPopupService, CaflerPopupSettingsModel } from '@cafler/common-ui';
import { CaflerPopupToppingService } from '../components/popup-topping/service/popup-topping.service';
import { PopupSettingsModel } from '../components/popup-topping/model/popup-topping-settings.model';
import { SingleDataHelperService } from '../helpers/single-data-helper.service';
import { VerticalsTypeEnum } from '../Enums/verticalsType.enum';
import { Product } from '../models/types';
import Swal from 'sweetalert2';
@Injectable()
export class KamikazeEffects {
  constructor(
    private kamikazeFacade: KamikazeFacade,
    private presentationFacade: PresentationFacade,
    private helperService: HelperService,
    private navigationService: NavigationService,
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private translate: TranslateService,
    private popupServicePrueba: CaflerPopupToppingService,
    private singleDataHelperService: SingleDataHelperService
  ) {}

  bootstrappingApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.BootstrappingApp),
        map((action, i) => {
          this.store.dispatch(setElementsAmount({elementsToShowInMyServices: 100}))
          this.store.dispatch(setPageLocation({pageLocationInMyServices: 1}))
          this.store.dispatch(setEnumMyServices({enumMyServices : 1}))
          let x$: Observable<any>[] = [
            this.helperService.checkIsB2B(),
            this.helperService.initTranslations(),
          ];
          forkJoin(x$).subscribe(() => {
            this.helperService.injectGoogleMaps();

            // this.store.dispatch(initApp());
          });
        }),
      ),
    { dispatch: false },
  );

  initApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.InitApp),
        withLatestFrom(this.store.select(selectIsB2B)),
        map(([action, isB2B], i) => {
          this.kamikazeFacade
            .getCoreData(isB2B)
            .pipe(first())
            .subscribe((coreData) => {
              this.store.dispatch(storeCoreData({ coreData }));
              this.store.dispatch(checkIfThereIsAZone());
              // this.helperService.getValue()

              if (isB2B) {
                this.store.dispatch(loadDataB2B());
              } else {
                this.store.dispatch({ type: KamikazeActions.InitAppSuccess });
              }
            });
        }),
        // TODO -> Catch flow
      ),
    { dispatch: false },
  );

  loadDataB2B$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.LoadDataB2B),
        withLatestFrom(
          this.store.select(selectUserData),
          this.store.select(selectCoreDataZones),
          this.store.select(selectCoreDataEnum('VerticalTypeEnum')),
        ),
        map(([action, userData, zones, verticalTypes], i) => {
          // @ts-ignore
          let b2bZoneName = userData?.userZone?.zoneName;

          // esto es
          if (b2bZoneName.indexOf('Paris') > -1) b2bZoneName = 'Paris';

          const currentZone = zones?.find((z: any) => z.Title === b2bZoneName);
          this.helperService
            .setZoneAndRegionFromName(b2bZoneName)
            .subscribe((zoneAndRegion: any) => {
              this.store.dispatch(setCurrentCountry({ currentCountry: zoneAndRegion.region }));
              this.store.dispatch(setCurrentZone({ currentZone: zoneAndRegion.zone }));

              // Revisar porque pero el find no funciona bien
              // @ts-ignore
              // const service = verticalTypes?.EnumItems.find((z: any) => {
              //   z.Name === userData.serviceType
              // });

              // los enums de b2b y b2c se llaman diferente
              // if(userData.serviceType === 'VehicleWash'){
              //   userData.serviceType = 'wash';
              // }

              let service: any;
              verticalTypes?.EnumItems.forEach((z: any) => {
                // let minusculas = .toLowerCase();
                let verticalsAreTheSame = this.compareVertical(userData, z)
                if(verticalsAreTheSame){
                  service = z;
                }
              });

              if (userData.serviceType === 'MyServices') {
                this.store.dispatch({ type: KamikazeActions.InitAppSuccess });
              }
              else {
                this.store.dispatch(initializeAppB2BSuccess({ serviceType: service.Value }));
              }
            });

          // return ({type: KamikazeActions.InitializeAppB2BSuccess, serviceType: 1})
        }),
      ),
    { dispatch: false },
  );

  setCurrentLang$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.SetCurrentLang),
        withLatestFrom(this.store.select(selectCurrentLang)),
        map(([action, currentLang], i) => {
          this.translate.use(currentLang);
        }),
      ),
    { dispatch: false },
  );

  initAppSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.InitAppSuccess),
        withLatestFrom(this.store.select(selectCurrentVertical), this.store.select(selectIsB2B)),
        map(([action, currentVertical, isB2B], i) => {
          if (currentVertical !== 0 && !isB2B) {
            this.store.dispatch(fetchAvailableVerticalProducts());
            // type: KamikazeActions.FetchAvailableVerticalProducts};
          }
        }),
      ),
    { dispatch: false },
  );


  getStations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KamikazeActions.GetStations),
      withLatestFrom(
        this.store.select(selectSelectedProduct)
      ),
      switchMap(([action, selectedProduct], i) => 
        this.kamikazeFacade.getStations(selectedProduct.id).pipe(
          map((stations, i) => {
            return {
              type: KamikazeActions.GetStationsSuccess,
              stations: stations,
            };
          }
        ))
  )));

  getMotStations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KamikazeActions.GetMotStations),
      withLatestFrom(
        this.store.select(selectCurrentZoneId),
        this.store.select(selectIsB2B),
      ),
      switchMap(([action, zoneId, isB2B], i) =>
        this.kamikazeFacade.getMotStations(isB2B, zoneId).pipe(
          map((motStations, i) => {
            return {
              type: KamikazeActions.GetMotStationsSuccess,
              stations: motStations.Stations,
            };
          }),
        ),
      ),
    ),
  );



  storeProductType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KamikazeActions.StoreProductType),
      map((action, i) => {
        return { type: KamikazeActions.InitAppSuccess };
      }),
    ),
  );

  checkIfThereIsAZone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.CheckIfThereIsAZone),
        map((action, i) => {
          this.helperService.readIfThereIsAZoneInTheUrl();
        }),
      ),
    { dispatch: false },
  );

  initializeAppSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KamikazeActions.InitializeAppSuccess),
      switchMap((action) => {
        if (environment.applicationType === 'b2b') {
          return this.navigationService.getB2B10DataAsync().pipe(
            first(),
            map((b2b10Data) => {
              // Si userLang es 'en', traducciones de uk
              let userLang;
              if (b2b10Data.userLang === 'en') {
                userLang = 'uk';
              } else {
                userLang = b2b10Data.userLang;
              }
              // @ts-ignore
              const lang = LanguageCodesEnum[userLang];
              this.translate.use(lang);
              return { type: KamikazeActions.LoadDataB2B, userData: b2b10Data };
            }),
          );
        }
        return [];
      }),
    ),
  );

  initializeAppB2bSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KamikazeActions.InitializeAppB2BSuccess),
      map((action, i) => {
        return { type: KamikazeActions.FetchAvailableVerticalProducts };
      }),
    ),
  );

  fetchAvailableVerticalProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KamikazeActions.FetchAvailableVerticalProducts),
      withLatestFrom(
        this.store.select(selectCurrentZoneId),
        this.store.select(selectCurrentVertical),
        this.store.select(selectIsB2B),
      ),
      switchMap(([action, zoneId, verticalType, isB2B], i) =>
        this.singleDataHelperService.getVerticalProducts(zoneId, verticalType, isB2B).pipe(
          map((currentVerticalProducts, i) => {
            
            let verticalProducts = currentVerticalProducts
            if( verticalType === VerticalsTypeEnum.ALL_YOU_NEED){
              const AllYouNeedProductInfo: any = {  
                  "alias": "magic",
                  "id": "123123123123123-961e-46ed-b4d9-12123123123",
                  "isPaymentRequired": false,
                  "price": {
                      "currency": 1,
                      "currencySymbol": "€",
                      "tax": 0,
                      "taxFreePrice": 0,
                      "totalPrice": 0
                  },
                  "title": "magic",
                  "holidays": [],
                  "ordersManagerId": "0f1706f0-9516-4240-ab5f-asdadasd",
                  "category": "magic",
                  "time": 30
              }
                           

              verticalProducts = [AllYouNeedProductInfo]
            }

            return {
              type: KamikazeActions.FetchAvailableVerticalProductsSuccess,
              currentVerticalProducts: verticalProducts,
            };
          }),
        ),
      ),
    ),
  );

  fetchAvailableVerticalProductsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.FetchAvailableVerticalProductsSuccess),
        withLatestFrom(
          this.store.select(selectCurrentVertical),
          this.store.select(selectIsB2B),
          this.store.select(selectUserData),
        ),
        map(([action, currentVertical, isB2B, userData], i) => {
          if (isB2B) {
            const productType = this.presentationFacade.getVerticalById(Number(currentVertical));
            let nextPage;
            switch(productType) {
              case 'lavado': {
                nextPage = 'lavado-selector';
                break;
              }
              case 'refueling': {
                nextPage = 'refuel';
                break;
              }
              case 'revision': {
                nextPage = 'vehicle-service';
                break;
              }
              default: {
                nextPage = productType;
              }
            }
            this.router.navigateByUrl(`/${userData.userZone.zoneName}/${nextPage}`).then((s) => {
              this.navigationService.currentPageTitle =
                'cafler.product-type.' + productType + '.title' || '';
            });
            this.store.dispatch({
              type: KamikazeActions.StoreProductType,
              productType: productType,
            });
          }
        }),
      ),
    { dispatch: false },
  );

  bookOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.BookOrder),
        withLatestFrom(
          this.store.select(selectProductData),
          this.store.select(selectCurrentZoneId),
          this.store.select(selectCurrentVertical),
          this.store.select(selectUserData),
          this.store.select(selectIsB2B),
        ),
        map(async ([action, productData, zoneId, vertical, userData, isB2B], i) => {
          
          if(vertical === VerticalsTypeEnum.ALL_YOU_NEED){
            const product = this.kamikazeFacade.buildAllYouNeedOrder(
              productData,
              zoneId,
              userData,
              isB2B
              )
              
            this.kamikazeFacade.bookAllYouNeedProduct(isB2B, product).subscribe((bookedOrderOrdersManagerFormat: any) => {
              let bookedOrder = this.helperService.getBookedOrderLegacyFormat(bookedOrderOrdersManagerFormat);
              this.store.dispatch({
                type: KamikazeActions.BookOrderSuccess,
                bookedOrder: bookedOrder,
              });
            }, (error) => {
              this.store.dispatch({
                type: KamikazeActions.BookOrderFail,
                error: error,
              });
            })
           
            return 
          }
            
          const ordersManagerProductData = 
          await this.kamikazeFacade.buildOrdersManagerProductData(
              productData,
              zoneId,
              vertical,
              userData,
              isB2B);

          this.kamikazeFacade.bookProduct(isB2B, ordersManagerProductData).subscribe((bookedOrderOrdersManagerFormat: any) => {
            let bookedOrder = this.helperService.getBookedOrderLegacyFormat(bookedOrderOrdersManagerFormat);
            this.store.dispatch({
              type: KamikazeActions.BookOrderSuccess,
              bookedOrder: bookedOrder,
            });
          }, (error) => {
            this.store.dispatch({
              type: KamikazeActions.BookOrderFail,
              error: error,
            });
          });
        }),
        catchError((e) => of({ type: KamikazeActions.BookOrderFail, bookedOrder: e })),
      ),
    { dispatch: false },
  );

  bookOrderFail = createEffect(
    () => 
        this.actions$.pipe(
          ofType(KamikazeActions.BookOrderFail),
          map((action: any, i) => {
    
            const errorTitle = action?.error.ErrorCode === 'hourOutsideRange' ? 
              'bad-request.hourOutsideRange.title' : ''

            const errorMessage = action?.error.ErrorCode === 'hourOutsideRange' 
              ? 'bad-request.hourOutsideRange.description' 
              : 'bad-request.noAvaibleHour'

            const popupSettings = <PopupSettingsModel>{
              id: 'toppingPopup',
              servicesTittle: errorTitle,
              noServiceDescription: errorMessage,
              icon: 'assets/img/error-icon.png',
              servicesAvaiable: false,
              confirmButtonText:"confirmar",
              cancelButtonText:"cancelar"
            };
        
            const ref = this.popupServicePrueba.open('',popupSettings);
            return ref;

          }),
        ),
        { dispatch: false },
  )

  bookOrderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.BookOrderSuccess),
        withLatestFrom(
          this.store.select(selectAvailableToppings),
          this.store.select(selectNextPage),
        ),
        map(([action, availableToppings, nextPage], i) => {
          // @ts-ignore
          if (availableToppings?.length > 0) {
            this.router.navigateByUrl(`${this.router.url}/${nextPage}`);
          } else {
            this.router.navigateByUrl(`${this.router.url}/overview`);
          }
        }),
      ),
    { dispatch: false },
  );

  confirmOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.ConfirmOrder),
        withLatestFrom(
          this.store.select(selectBookedOrder),
          this.store.select(selectIsB2B)
        ),
        map(([action, bookedOrder, isB2B], i) => {
          let body = 
          isB2B ? {
            OrderHash: bookedOrder.OrderHash,
          }
          : {
            OrderHash: bookedOrder.OrderHash,
            PaymentMethod: 2
          };

          this.kamikazeFacade.confirmOrder(body, isB2B).subscribe((confirmedOrder: any) => {
            if(confirmedOrder?.FinancialData.HasToBeCharged){
              this.store.dispatch({
                type: KamikazeActions.ConfirmedOrderSuccess,
                confirmedOrder: confirmedOrder,
              });
            }
          })
        }),
      ),
    { dispatch: false },
  )

  confirmedOrderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.ConfirmedOrderSuccess),
        map((action, i) => {
          this.router.navigateByUrl('checkout');
        }),
      ),
    { dispatch: false },
  )

  setOrderStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.SetOrderStatus),
        withLatestFrom(this.store.select(selectPaymentHashes)),
        map(([action, hashes], i) => {
          this.kamikazeFacade
            .setOrderStatus(hashes.OrderHash, hashes.PaymentHash)
            .subscribe((s) => true);
        }),
      ),
    { dispatch: false },
  );

  


  buildToppingOrder(toppings: any[]){

    const body: {Toppings: [{ProductId: string, ServiceConfiguration: string}?]} = {Toppings: []};
  
    // Debido a la alta frecuencia de fallo al bookear un topping the insurance, se ha decidido que siempre se bookee
    // el último para garantizar que el resto de toppings se bookeen correctamente.

    const mutableToppings = [...toppings]; 
    const toppingsSorted = mutableToppings.sort((a, b) => {
      if (a.Category !== "Insurance" && b.Category === "Insurance") {
        return -1;
      }
      return 0;
    });

    toppingsSorted.forEach((t: any) => {
      let serviceConfiguration: string = '{}';

      if(t.ServiceConfiguration.amount && t.ServiceConfiguration.type){
        serviceConfiguration = JSON.stringify({MonetaryAmount: t.ServiceConfiguration.amount, FuelType: t.ServiceConfiguration.type});
      }
      
      if(t.Category === 'Insurance'){
        serviceConfiguration = JSON.stringify(
          {
            Brand: t.ServiceConfiguration.brand,
            Model: t.ServiceConfiguration.model,
            LicensePlate: t.ServiceConfiguration.licansePlate,
            OwnerFirstName: t.ServiceConfiguration.firstName,
            OwnerLastName: t.ServiceConfiguration.lastName,
            DurationAmount: t.ServiceConfiguration.insuranceDurationAmount,
            DurationType: parseInt(t.ServiceConfiguration.insuranceDurationType),
            DocumentNumber: t.ServiceConfiguration.documentNumber,
            DocumentType: parseInt(t.ServiceConfiguration.documentType),
            VehicleRegistrationCountry: t.ServiceConfiguration.vehicleRegistrationCountry
          }
        );
     }
      
      body.Toppings.push({ProductId: t.ProductId, ServiceConfiguration: serviceConfiguration})
      
    }); 
    return body;
  }

  updateToppingsState(productsAdded: any, toppingsPicked: any){
    const toppingsAdded = productsAdded.filter((p: any) => p.ProductId !== null);  
    this.store.dispatch({type: KamikazeActions.SetToppingsAdded, toppingsAdded: toppingsAdded});

    const newToppingsPicked = toppingsPicked.filter((toppingPicked: any) => toppingsAdded.find((toppingAdded: any) => toppingAdded.ProductId === toppingPicked.ProductId));
    this.store.dispatch({type: KamikazeActions.SetToppingsPicked, toppingsPicked: newToppingsPicked});

  }

  addToppingsToOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.BookToppings),
        withLatestFrom(this.store.select(selectIsB2B), this.store.select(selectPaymentHashes), this.store.select(selectToppingsPicked)),
        map(([action, isB2B, hashes, toppings], i) => {
          this.store.dispatch({ type: KamikazeActions.SetIsLoading, isLoading: true });
          
          const body = this.buildToppingOrder(toppings);
          
          this.kamikazeFacade
            .addToppingsToOrder(isB2B, hashes.OrderHash,body)
            .subscribe(
              (res: any) => {
                if(res.Errors?.length > 0){
                  let errors = [
                    'error-message.topping-insurance.title',
                    'error-message.topping-insurance.list-description',
                    'error-message.topping-insurance.buttton-confirm',
                    'error-message.topping-insurance.try-again'
                    ]
                res.Errors[0].ToppingErrorCodes.forEach((errorCode: string) => {
                  if(errorCode !== 'general-validation-error'){
                    errors.push("error-message.topping-insurance.error." + errorCode);
                  }
                });
                this.presentationFacade.showInsuranceToppingErrorPopup(errors);
                  
                }

              this.updateToppingsState(res.Products, toppings);
                
              const bookedOrder = this.helperService.getBookedOrderLegacyFormat(res);
              this.store.dispatch({
                type: KamikazeActions.BookToppingsSuccess,
                bookedOrder: bookedOrder,
              });

              this.store.dispatch({ type: KamikazeActions.SetIsLoading, isLoading: false });
          },
            (error) => {
              this.updateToppingsState(error.Products, toppings);

              
              const bookedOrder = this.helperService.getBookedOrderLegacyFormat(error);
              
              this.store.dispatch({
                type: KamikazeActions.BookToppingsSuccess,
                bookedOrder: bookedOrder,
              });
   
              this.store.dispatch({ type: KamikazeActions.SetIsLoading, isLoading: false });
            });


          })),        
        { dispatch: false },
  );

  removeToppings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.RemoveToppings),
        withLatestFrom(this.store.select(selectIsB2B), this.store.select(selectPaymentHashes), this.store.select(selectToppingToDelete), this.store
        .select(selectToppingsAdded), this.store.select(selectToppingsPicked)),
        map(([action, isB2B, hashes, toppingId, toppingsAdded, toppingsPicked], i) => {
          this.store.dispatch({ type: KamikazeActions.SetIsLoading, isLoading: true });
          this.kamikazeFacade.removeToppingsFromOrder(isB2B, hashes.OrderHash, toppingId).subscribe((res: any) => {
        
          const newToppingsAdded = toppingsAdded.filter((t: any) => t.ProductId !== toppingId);

          const newToppingsPicked = toppingsPicked.filter((t: any) => t.ProductId !== toppingId);

          const bookedOrder = this.helperService.getBookedOrderLegacyFormat(res);
          this.store.dispatch({
            type: KamikazeActions.BookToppingsSuccess,
            bookedOrder: bookedOrder,
          });

          this.store.dispatch({ type: KamikazeActions.SetToppingsAdded, toppingsAdded: newToppingsAdded });
          this.store.dispatch({ type: KamikazeActions.SetToppingsPicked, toppingsPicked: newToppingsPicked });
          this.store.dispatch({ type: KamikazeActions.SetToppingToDelete, toppingToDelete: "" });
          this.store.dispatch({ type: KamikazeActions.SetIsLoading, isLoading: false });
          
          });
        }),
      ),
      { dispatch: false },
  );

  setCoupon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.SetCoupon),
        withLatestFrom(
          this.store.select(selectPaymentHashes), 
          this.store.select(selectCouponCode),
          this.store.select(selectIsB2B)),
        map(([action, hashes, couponCode, isB2B], i) => {
          this.store.dispatch({ type: KamikazeActions.SetIsLoading, isLoading: true });
          this.kamikazeFacade
            .setCouponCode(hashes.OrderHash, couponCode, isB2B)
            .subscribe((res: any) => {
              this.store.dispatch({
                type: KamikazeActions.IsCouponValid,
                isCouponValid: res.CouponApplied,
              });

              if (res.CouponApplied) {
                this.store.dispatch({
                  type: KamikazeActions.SetDiscountPrice,
                  DiscountPrice: res.OrderData.DiscountAmount,
                });
                this.store.dispatch({
                  type: KamikazeActions.UpdateOrderPrice,
                  OrderPrice: res.OrderData.OrderPriceAfterDiscount,
                });
                this.store.dispatch({
                  type: KamikazeActions.RefreshPaymentSession,
                  refreshPaymentSessionData: {
                    PaymentData: res.PaymentData?.PaymentData,
                    PaymentId: res.PaymentData?.PaymentId,
                    PaymentHash: res.PaymentData?.PaymentHash,
                  },
                });
              } else {
                this.store.dispatch({ type: KamikazeActions.ClearCoupon });
              }

              this.store.dispatch({ type: KamikazeActions.SetIsLoading, isLoading: false });
            });
        }),
      ),
    { dispatch: false },
  );

 
  removeCoupon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.RemoveCoupon),
        withLatestFrom(
          this.store.select(selectPaymentHashes),
          this.store.select(selectIsB2B)
          ),
        map(([action, hashes, isB2B], i) => {
          this.store.dispatch({ type: KamikazeActions.SetIsLoading, isLoading: true });
          this.kamikazeFacade.removeCoupon(hashes.OrderHash, isB2B).subscribe((res: any) => {
            this.store.dispatch({
              type: KamikazeActions.UpdateOrderPrice,
              OrderPrice: res.OrderData.OrderPriceAfterDiscount,
            });
            this.store.dispatch({ type: KamikazeActions.SetIsLoading, isLoading: false });
          });
        }),
      ),
    { dispatch: false },
  );

  // TODO: Deprecated, remove when possible
  setCurrentTopping$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.SetCurrentTopping),
        withLatestFrom(this.store.select(selectCurrentTopping)),
        map(([action, currentTopping], i) => {
          // @ts-ignore
          const productType = this.presentationFacade.getVerticalById(currentTopping?.vertical);
          let nextPage = productType;

          // No deberia entrar aqui pero por si acaso
          if (!currentTopping) {
            this.router.navigateByUrl(`${this.router.url}/overview`);
          }

          if (!currentTopping.isConfigurationRequired) {
            // Si no necesita configuración añadir producto
            this.store.dispatch(addSelectedTopping());
          } else {
            switch (currentTopping.vertical) {
              case 1:
                this.router.navigateByUrl(`${this.router.url}/${currentTopping.alias}`);
                break;
              default:
                this.router.navigateByUrl(`${this.router.url}/${nextPage}/${currentTopping.alias}`);
                break;
            }
          }
        }),
      ),
    { dispatch: false },
  );

  // TODO: Deprecated, remove when possible
  addSelectedTopping$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.AddSelectedTopping),
        withLatestFrom(
          this.store.select(selectBookedOrder),
          this.store.select(selectProductData),
          this.store.select(selectToppingData),
          this.store.select(selectCurrentTopping),
        ),
        map(([action, bookedOrder, productData, toppingData, currentTopping], i) => {
          let bookingBody;
          switch (currentTopping?.vertical) {
            case 1:
              bookingBody = this.kamikazeFacade.buildReturnToppingOrder(
                bookedOrder.OrderHash,
                currentTopping.id,
                productData,
                toppingData,
              );
              this.kamikazeFacade
                .createReturnToppingOrder(bookingBody)
                .subscribe((bookedOrder) =>
                  this.store.dispatch(
                    addSelectedToppingSuccess({ bookedOrder: bookedOrder.Order }),
                  ),
                );
              break;
            case 3:
              bookingBody = this.kamikazeFacade.buildWashToppingOrder(
                bookedOrder.OrderHash,
                currentTopping.id,
              );
              this.kamikazeFacade
                .createWashingToppingOrder(bookingBody)
                .subscribe((bookedOrder) =>
                  this.store.dispatch(
                    addSelectedToppingSuccess({ bookedOrder: bookedOrder.Order }),
                  ),
                );
              break;
            default:
              this.store.dispatch(continueWithNoToppings());
              break;
          }
        }),
        catchError((e) => of({ type: KamikazeActions.AddSelectedToppingFail, response: e })),
      ),
    { dispatch: false },
  );

  // TODO: Deprecated, remove when possible
  clearSelectedToppings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.ClearSelectedToppings),
        withLatestFrom(this.store.select(selectBookedOrder)),
        map(([action, bookedOrder], i) => {
          this.store.dispatch({ type: KamikazeActions.SetIsLoading, isLoading: true });
          this.kamikazeFacade.clearSelectedToppings(bookedOrder.OrderHash).subscribe((res) => {
            this.store.dispatch({
              type: KamikazeActions.ClearSelectedToppingsSuccess,
              bookedOrder: res.Order,
            });
          });
        }),
      ),
    { dispatch: false },
  );

  // TODO: Deprecated, remove when possible
  continueWithNoToppings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.ContinueWithNoToppings),
        withLatestFrom(this.store.select(selectBookedOrder)),
        map(([action, bookedOrder], i) => {
          this.store.dispatch(addSelectedToppingSuccess({ bookedOrder }));
        }),
      ),
    { dispatch: false },
  );

  // TODO: Deprecated, remove when possible
  addSelectedToppingSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.AddSelectedToppingSuccess),
        withLatestFrom(this.store.select(selectBookedOrder)),
        map((action, i) => {
          this.router.navigateByUrl(`${this.router.url}/overview`);
        }),
      ),
    { dispatch: false },
  );

  // TODO: Deprecated, remove when possible
  getAvailableHoursReturn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KamikazeActions.GetAvailableHoursReturn),
        withLatestFrom(
          this.store.select(selectBookedOrder),
          this.store.select(selectCurrentTopping),
        ),
        map(([action, bookedOrder, currentTopping], i) => {
          let dateToSend = {
            ToppingId: currentTopping.id,
            OrderHash: bookedOrder.OrderHash,
          };
          this.kamikazeFacade
            .getToppingAvailabilities(dateToSend)
            .subscribe((availableToppingHours: any[]) => {
              this.store.dispatch(getAvailableHoursReturnSucess({ availableToppingHours }));
            });
        }),
      ),
    { dispatch: false },
  );

  getMyServices$ = createEffect(
    () =>
    this.actions$.pipe(
      ofType(KamikazeActions.GetServices),
      withLatestFrom(
        this.store.select(selectElementsToShowInMyServices),
        this.store.select(selectPageLocationInMyServices),
        this.store.select(selectEnumMyServices),
        this.store.select(selectUserId),
      ),
      
      map(([action, elementsAmount, page, enumMyServices, selectUserId], i) =>{
      this.store.dispatch({ type: KamikazeActions.SetIsLoading, isLoading: true });
      this.kamikazeFacade.getServices(elementsAmount, page, enumMyServices, selectUserId).subscribe((myServices:any[]) => {
        this.store.dispatch(getServicesSuccess({myServices}));
        this.store.dispatch({ type: KamikazeActions.SetIsLoading, isLoading: false });
      })
    }),
  ),
  { dispatch: false },
  );

  cancelOrder$ = createEffect(
    () =>
    this.actions$.pipe(
      ofType(KamikazeActions.CancelService),
      withLatestFrom(
        this.store.select(selectUserId),
        this.store.select(selectCaflerHashToBeCancelled)
      ),
      map(([action, userId, caflerHash], i) =>{
      this.kamikazeFacade.cancelService(caflerHash, userId).subscribe((cancelledService:any[]) => {
        this.store.dispatch(cancelServiceSuccess({cancelledService}));
      })
    }),
  ),
  { dispatch: false },
  );


  cancelOrderSuccess$ = createEffect(
    () =>
    this.actions$.pipe(
      ofType(KamikazeActions.CancelServiceSuccess),
      map((action, i) =>{
        window.location.reload()
    }),
  ),
  { dispatch: false },
  );

  showService$ = createEffect(
    () =>
    this.actions$.pipe(
      ofType(KamikazeActions.ShowService),
      withLatestFrom(
        this.store.select(selectServiceToShow),
      ),
      map(([action, serviceToShow], i) =>{
        this.kamikazeFacade.getDataDetailsFromService(serviceToShow.caflerHash)
        .subscribe((selectedServiceToShowData:any) => {
          this.store.dispatch(showServiceSuccess({selectedServiceToShowData}));
        })
    }),
  ),
  { dispatch: false },
  );

  compareVertical(userData: any, z: any): boolean{
    return (userData.serviceType === 'Transfer' && z.Name === 'transfer') ||
      (userData.serviceType === 'VehicleWash' && z.Name === 'wash') ||
      (userData.serviceType === 'pre-mot' && z.Name === 'pre-mot') ||
      (userData.serviceType === 'Fuel' && z.Name === 'refuelling') ||
      (userData.serviceType === 'Revision' && z.Name === 'mechanical-inspection') ||
      (userData.serviceType === 'TowTruckTransfer' && z.Name === 'long-distance-transfer') ||
      (userData.serviceType === 'Valet' && z.Name === 'valet') ||
      (userData.serviceType === 'Formalities' && z.Name === 'formalities') || 
      (userData.serviceType === 'TechnicalInspection' && z.Name === 'mot') || 
      (userData.serviceType === 'tow-truck' && z.Name === 'towtruck') ||
      (userData.serviceType === 'all-you-need' && z.Name === 'whatever-you-need')
  }

}





