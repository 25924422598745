import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { bootstrappingApp, setElementsAmount, setIsAppInitialized, setPageLocation } from './store/actions';
import { KamikazeFacade } from './facades/kamikaze.facade';
import {
  selectCurrentPage,
  selectFooterVisibility,
  selectIsB2B,
  selectIsLoading,
} from './store/selectors';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CaflerSpinnerService } from '@cafler/common-ui';
import { MyMonitoringService } from './services/logging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly destroySubjects$ = new ReplaySubject<void>(1);
  isLoading: boolean = true;
  isFooterVisible$!: Observable<boolean>;
  isB2B!: boolean;
  isStepperShown: boolean = false;
  constructor(
    private myMonitoringService: MyMonitoringService,
    private store: Store,
    private kamikazeFacade: KamikazeFacade,
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef,
    private spinnerService: CaflerSpinnerService,
  ) {
    this.store.dispatch(setIsAppInitialized({isAppInitialized: false}))
    this.store.dispatch(setElementsAmount({elementsToShowInMyServices: 100}))
    this.store.dispatch(setPageLocation({pageLocationInMyServices: 1}))
    this.store.dispatch(bootstrappingApp());
    this.store.select(selectIsLoading).subscribe((isLoading) => {
      this.isLoading = isLoading;
      if (isLoading) this.spinnerService.show();
      else this.spinnerService.hide();
    });
    this.store.select(selectFooterVisibility).subscribe((isFooterVisible) => {
      this.isFooterVisible$ = of(isFooterVisible);
    });
    this.kamikazeFacade.setCurrentVertical();
  }

  ngOnInit() {




        this.store
      .select(selectCurrentPage)
      .pipe(takeUntil(this.destroySubjects$), debounceTime(0))
      .subscribe((currentPage) => {
        this.isStepperShown =
          ['pre-itv', 'product-selection', 'maintenance-selector', 'maintenance-bundle', 'lavado-selector', 'revision', 'my-services', 'valet-selector'].indexOf(currentPage) ===
          -1;
        this.cdRef.detectChanges();
      });
    this.store.select(selectIsB2B).subscribe((isB2B) => {
      this.isB2B = isB2B;
    });
    this.translate.onLangChange.pipe(takeUntil(this.destroySubjects$)).subscribe((lang) => {
      // this.store.dispatch(setCurrentLang({currentLang: lang.lang}));
    });
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }
}
