<form *ngIf="isAppInitialized" class="caf-md-container margin-top-forms" [formGroup]="fg">
    
    <!-- tipo de servicio-->
    <section class="input-item">
      <h3 class="subtitle-1">{{"cafler.product.pre-mot.radio-button.title" | translate}}</h3>

      <div class="radio-btn-container" >
        <button class="radio-btn-box" [ngClass]="{'selected': fg.get(this.settingRadio.preMotMot.formControlName)?.value === 'preMot'}"  (click)="changeActiveRadioButtonContainer('preMot')">    
          <caf-radio-button
          [settings]="settingRadio.preMot"
          ></caf-radio-button>
        </button>
  
        <button class="radio-btn-box" [ngClass]="{'selected': fg.get(this.settingRadio.preMotMot.formControlName)?.value  === 'preMotMot'}"  (click)="changeActiveRadioButtonContainer('preMotMot')">    
          <caf-radio-button
            [settings]="settingRadio.preMotMot"
            ></caf-radio-button> 
        </button>
      </div>
      
    </section>

    <!-- direccion recogida-->
    <section>
      <div class="input-item">
        <h3 class="subtitle-1">{{'cafler.pickup-info.pickup-info.title' | translate}}</h3>
        <div *ngIf="isB2B" class="caf-grid-row split-2-cols split-sm-2-cols">
          <caf-input-google-autosuggest
            class="col-start-sm-1 col-start-1 col-end-sm-10 col-end-10"
            (onValidAddress)="onValidAddress($event, settings.pickupAddress.formControlName)"
            (onInvalidAddress)="countPickupErrors()"
            [settings]="settings.pickupAddress"
          >
          </caf-input-google-autosuggest>
          <button
            class="h-align-center v-align-center complete-address-button col-start-sm-11 col-start-11 col-end-sm-12 col-end-12"
            (click)="getB2BAddress(settings.pickupAddress.formControlName)"
          >
            <caf-icon [settings]="settingsExtra.locationIcon"></caf-icon>
          </button>
        </div>
        <caf-input-google-autosuggest
          *ngIf="!isB2B"
          (onValidAddress)="onValidAddress($event, settings.pickupAddress.formControlName)"
          (onInvalidAddress)="countPickupErrors()"
          [settings]="settings.pickupAddress"
        >
        </caf-input-google-autosuggest>
        <caf-error
          *ngIf="timesFailedPickupAddress<=maxErrorsOnAddress"
          class="extra-margin-error"
          [settings]="settingsErrors.errorsPickupAddress"
        ></caf-error>
        <p *ngIf="timesFailedPickupAddress>maxErrorsOnAddress" class="error-message error-msg">
          {{'cafler.address-message-error-too-many-attempts-pickup.title' | translate }}
        </p>
        <div *ngIf="fg.get('pickupAddress')?.errors?.checkCoordinatesWithinCaflerArea" class="error-message error-msg">
          <p>{{'cafler.address.message.error.address-outside-operative-zone.title' | translate }}</p>
          <article class="notification-container notification-container__warning mb-24">
            <caf-icon [settings]="settingsExtra.warningIcon"></caf-icon>
            <p>{{'cafler.address.message.error.address-outside-operative-zone.notification-text' | translate }} <a href="{{operativeZonesURL}}" target="_blank">{{'cafler-operative-zones' | translate}}</a></p>
          </article> 
        </div>
      </div>
      <div class="input-item">
        <caf-input [settings]="settings.pickupAddressDetails"></caf-input>
      </div>
    </section>

    <!-- same delivery address-->
    <section class="input-item">
      <caf-dark-checkbox [settings]="settings.sameDeliveryAddress"></caf-dark-checkbox>
    </section>

    <!-- direccion de devolucion-->
    <section *ngIf="isSameDeliveryAddress">
      <div class="input-item">
        <h3 class="subtitle-1">{{'cafler.page.wash.dropoff.info.title' | translate}}</h3>
        <div *ngIf="isB2B" class="caf-grid-row split-2-cols split-sm-2-cols">
          <caf-input-google-autosuggest
            class="col-start-sm-1 col-start-1 col-end-sm-10 col-end-10"
            (onValidAddress)="onValidAddress($event, settings.dropoffAddress.formControlName)"
            (onInvalidAddress)="countDropoffErrors()"
            [settings]="settings.dropoffAddress"
          >
          </caf-input-google-autosuggest>
          <button
            class="h-align-center v-align-center complete-address-button col-start-sm-11 col-start-11 col-end-sm-12 col-end-12"
            (click)="getB2BAddress(settings.dropoffAddress.formControlName)"
          >
            <caf-icon [settings]="settingsExtra.locationIcon"></caf-icon>
          </button>
        </div>
        <caf-input-google-autosuggest
          *ngIf="!isB2B"
          (onValidAddress)="onValidAddress($event, settings.dropoffAddress.formControlName)"
          (onInvalidAddress)="countDropoffErrors()"
          [settings]="settings.dropoffAddress"
        >
        </caf-input-google-autosuggest>
        <caf-error
          *ngIf="timesFailedDropoffAddress<=maxErrorsOnAddress"
          class="extra-margin-error"
          [settings]="settingsErrors.errorsDropoffAddress"
        ></caf-error>
        <p *ngIf="timesFailedDropoffAddress>maxErrorsOnAddress" class="error-message error-msg">
          {{'cafler.address-message-error-too-many-attempts-dropoff.title' | translate }}
        </p>
      </div>
      <div *ngIf="fg.get('dropoffAddress')?.errors?.checkCoordinatesWithinCaflerArea" class="error-message error-msg">
        <p>{{'cafler.address.message.error.address-outside-operative-zone.title' | translate }}</p>
        <article class="notification-container notification-container__warning mb-24">
          <caf-icon [settings]="settingsExtra.warningIcon"></caf-icon>
          <p>{{'cafler.address.message.error.address-outside-operative-zone.notification-text' | translate }} <a href="{{operativeZonesURL}}" target="_blank">{{'cafler-operative-zones' | translate}}</a></p>
        </article> 
      </div>
      <div class="input-item">
        <caf-input [settings]="settings.dropoffAddressDetails"></caf-input>
      </div>
    </section>
    
    <!-- fecha del servicio-->
    <section class="input-item">
      <h3 class="subtitle-1">{{'cafler.page.service-date.info.title' | translate}}</h3>
      <caf-datepicker [settings]="settings.pickupDate"></caf-datepicker>
      <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsPickupDate"></caf-error>
    </section>
  
    <!-- hora del servicio-->
    <section *ngIf="readyToDisplayHours">
      <div>
        <div *ngIf="firstNewFeatureTry && renderNewFeatureSpeechBubble; else noNewFeatureSpeechBubble">
          <h3 class="subtitle-1 absolutePosition">{{'cafler.page.service-time.info.title' | translate}}</h3>
          <caf-speech-bubble *ngIf="firstNewFeatureTry" [settings]="settingsExtra.speechBubble"></caf-speech-bubble>
        </div>
        <ng-template #noNewFeatureSpeechBubble>
          <span class="subtitle-1">{{'cafler.page.service-time.info.title' | translate}}</span>
        </ng-template>
    
        <caf-select [settings]="settings.selectTimeOption"></caf-select>
        <br/>    
          <div *ngIf="timeRangeOption === 'range'" class="caf-grid-row split-2-cols split-sm-2-cols">
            <caf-select #startServiceTimeComponent [settings]="settings.startPickUpTime"></caf-select>
            <caf-select #endServiceTimeComponent [settings]="settings.endPickUpTime"></caf-select>
          </div>
          <div *ngIf="timeRangeOption === 'specific'">
            <caf-multicolumn-select #startServiceTimeComponent [settings]="settings.specificTimeRange"></caf-multicolumn-select>
            <div *ngIf="!noSpecificHourAvailable">
              <div *ngIf="currentCountry.RegionIsoCode === 'esp'">
                <span *ngIf="currentCountry.PreferredRegionCurrency === 1" class="span-2">
                  {{
                    (isB2B
                      ? 'cafler.page.service-time.specific-time.spain.info_b2b' 
                      : 'cafler.page.service-time.specific-time.spain.info')
                    | translate
                  }}
                </span>
                <span *ngIf="currentCountry.PreferredRegionCurrency === 3" class="span-2">
                  {{
                    (isB2B
                      ? 'cafler.page.service-time.specific-time.spain.info_uk_b2b' 
                      : 'cafler.page.service-time.specific-time.spain.info_uk')
                    | translate
                  }}
                </span>
              </div>
    
              <div *ngIf="currentCountry.RegionIsoCode === 'fra'">
                <span *ngIf="currentCountry.PreferredRegionCurrency === 1" class="span-2">
    
                  {{
                    (isB2B
                      ? 'cafler.page.service-time.specific-time.france.info_b2b' 
                      : 'cafler.page.service-time.specific-time.france.info')
                    | translate
                  }}
                </span>
                <span *ngIf="currentCountry.PreferredRegionCurrency === 3" class="span-2">
                  {{
                    (isB2B
                      ? 'cafler.page.service-time.specific-time.france.info_uk_b2b' 
                      : 'cafler.page.service-time.specific-time.france.info_uk')
                    | translate
                  }}
                </span>
              </div>
              
              <div *ngIf="currentCountry.RegionIsoCode === 'gbr'">
                <span *ngIf="currentCountry.PreferredRegionCurrency === 1" class="span-2">
                  {{
                    (isB2B
                      ? 'cafler.page.service-time.specific-time.uk.info_b2b' 
                      : 'cafler.page.service-time.specific-time.uk.info')
                    | translate
                  }}
                </span>
                <span *ngIf="currentCountry.PreferredRegionCurrency === 3" class="span-2">
                  {{
                    (isB2B
                      ? 'cafler.page.service-time.specific-time.uk.info_uk_b2b' 
                      : 'cafler.page.service-time.specific-time.uk.info_uk')
                    | translate
                  }}
                </span>
              </div>
            </div>
          </div> 
      </div>
    
      <div
        class="caf-grid-row split-2-cols split-sm-2-cols"
        *ngIf="readyToDisplayHours"
      >
        <caf-error
          *ngIf="!combinationNotValid && !dayChangedFlag"
          class="extra-margin-error col-start-sm-1 col-start-1 col-end-sm-1 col-end-1"
          [settings]="settingsErrors.errorsStartPickUpTime"
        ></caf-error>
        <caf-error
          *ngIf="!combinationNotValid && !dayChangedFlag"
          class="extra-margin-error col-start-sm-2 col-start-2 col-end-sm-2 col-end-2"
          [settings]="settingsErrors.errorsEndPickUpTime"
        ></caf-error>
      </div>
    
      <div *ngIf="timeRangeOption === 'range'">
        <p class="error-message" *ngIf="readyToDisplayHours && noRangeHourAvailable">
          {{"cafler.page.service-time.error-range-hours-not-available" | translate}}
        </p>
        <p class="error-message" *ngIf="dayChangedFlag && !noRangeHourAvailable">
          {{"cafler.page.service-time.error-day-changed" | translate}}
        </p>
      </div>
    
      <div *ngIf="timeRangeOption === 'specific'">
        <p class="error-message" *ngIf="readyToDisplayHours && noSpecificHourAvailable">
          {{"cafler.page.service-time.error-specific-hours-not-available" | translate}}
        </p>
        <p class="error-message" *ngIf="dayChangedFlag && !noSpecificHourAvailable">
        {{"cafler.page.service-time.error-day-changed" | translate}}
        </p>
      </div>
      <p class="error-message" *ngIf="combinationNotValid">
        {{"cafler.page.service-time.error-not-compatible" | translate}}
      </p>
    </section>

    <!-- vehicle information-->
    <section>
      <h3 class="subtitle-1">{{'cafler.vehicle-info.title' | translate}}</h3>
      <div class="input-item">
        <caf-select [settings]="settings.vehicleType"></caf-select>
        <caf-error [settings]="settingsErrors.errorsVehicleType"></caf-error>
      </div>
      <div class="input-item">
        <caf-select [settings]="settings.fuelType"></caf-select>
        <caf-error [settings]="settingsErrors.errorsFuelType"></caf-error>
      </div>
      <p class="error-message" *ngIf="vehicleChangeFlag">
        {{"cafler.page.service-time.error-vehicle-changed" | translate}}
      </p>
      <div class="input-item">
        <caf-input [settings]="settings.carPlaque"></caf-input>
        <caf-error [settings]="settingsErrors.errorsCarPlaque"></caf-error>
      </div>
      <div class="input-item">
        <caf-input [settings]="settings.brand"></caf-input>
        <caf-error [settings]="settingsErrors.errorsBrand"></caf-error>
      </div>
      <div class="input-item">
        <caf-input [settings]="settings.model"></caf-input>
        <caf-error [settings]="settingsErrors.errorsModel"></caf-error>
      </div>
    </section>

    <!-- contact data-->
    <section>
      <div class="input-item">
        <h3 class="subtitle-1">{{'cafler.contact.title' | translate}}</h3>
        <caf-input [settings]="settings.fullName"></caf-input>
        <caf-error [settings]="settingsErrors.errorsFullName"></caf-error>
      </div>
      <div class="caf-grid-row split-2-cols split-sm-2-cols">
        <caf-select
          class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
          [settings]="settings.countryPhoneCode"
        ></caf-select>
        <caf-input
          class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
          [settings]="settings.phone"
        ></caf-input>
      </div>
      <div class="caf-grid-row split-2-cols split-sm-2-cols">
        <caf-error
          class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
          [settings]="settingsErrors.errorsCountryPhoneCode"
        ></caf-error>
        <caf-error
          class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
          [settings]="settingsErrors.errorsPhone"
        ></caf-error>
      </div>
      <div *ngIf="!isB2B" class="input-item">
        <caf-input [settings]="settings.email"></caf-input>
        <caf-error [settings]="settingsErrors.errorsEmail"></caf-error>
      </div>
    </section>

    <!-- aditional data-->
    <section class="input-item">
      <h3 class="subtitle-1">{{'cafler.pickup-info.comments.title' | translate}}</h3>
      <caf-textarea [settings]="settings.additionalComments"></caf-textarea>
    </section>
  </form>