<form class="caf-md-container margin-top-forms" [formGroup]="fg">
    <!-- product description-->
    <p>{{"cafler.product.hoist.description" | translate}}</p>
    <h4 class="subtitle-2">{{"cafler.product.how-does-it-works.title" | translate}}</h4>
    <div class="explanations">
        <div *ngFor="let step of hoistExplanation" class="flex-group explanation-container">
            <caf-icon [settings]="step.icon"></caf-icon>
            <p>{{step.text | translate}}</p>
        </div>
    </div>


    <h3 class="subtitle-1">{{'cafler.hoist.vehicle-status.title' | translate }}</h3>
    <caf-select [settings]="settings.vehicleStatus"></caf-select>
    <!-- direccion recogida-->
    <div class="input-item">
      <span class="subtitle-1">{{'cafler.transfer-info.pickup-info.title' | translate}}</span>
      <div
        *ngIf="isB2B"
        class="caf-grid-row split-2-cols split-sm-2-cols"
      >
        <caf-input-google-autosuggest
        (onValidAddress)="timesFailedPickupAddress=0"
        (onInvalidAddress)="countPickupErrors()"
          class="col-start-sm-1 col-start-1 col-end-sm-10 col-end-10"
          [settings]="settings.pickupAddress"
        >
        </caf-input-google-autosuggest>
        <button
          class="h-align-center v-align-center complete-address-button col-start-sm-11 col-start-11 col-end-sm-12 col-end-12"
          (click)="getB2BAddress(settings.pickupAddress.formControlName)"
        >
          <caf-icon [settings]="settingsExtra.locationIcon"></caf-icon>
        </button>
      </div>
      <caf-input-google-autosuggest
        *ngIf="!isB2B"
        (onValidAddress)="timesFailedPickupAddress=0"
        (onInvalidAddress)="countPickupErrors()"
        [settings]="settings.pickupAddress"
      >
      </caf-input-google-autosuggest>
      <caf-error
        *ngIf="timesFailedPickupAddress<=maxErrorsOnAddress"
        class="extra-margin-error"
        [settings]="settingsErrors.errorsPickupAddress"
      ></caf-error>
      <p *ngIf="timesFailedPickupAddress>maxErrorsOnAddress" class="error-message error-msg">
      {{'cafler.address-message-error-too-many-attempts-pickup.title' | translate }}
      </p>
  
      <div *ngIf="fg.get('pickupAddress')?.errors?.checkCoordinatesWithinCaflerArea" class="error-message error-msg">
        <div *ngIf="isB2B; else operativeZonesB2C">
          <p>{{'cafler.address.message.error.address-outside-operative-zone.B2B.message' | translate}}</p>
        </div>
        <ng-template #operativeZonesB2C >
          <p>{{'cafler.address.message.error.address-outside-operative-zone.title' | translate }}</p>
          <article class="notification-container notification-container__warning mb-24">
            <caf-icon [settings]="settingsExtra.warningIcon"></caf-icon>
            <p>{{'cafler.address.message.error.address-outside-operative-zone.notification-text' | translate }} <a href="{{operativeZonesURL}}" target="_blank">{{'cafler-operative-zones' | translate}}</a></p>
          </article> 
        </ng-template>
      </div>
      
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.pickupAddressDetails"></caf-input>
    </div>

    <!-- contact pickup data-->
    <div>
      <div class="input-item">
        <span class="subtitle-1"
          >{{'cafler.page.media-larga-distancia.origin-contact.title' | translate}}</span
        >
        <caf-input [settings]="settings.fullName"></caf-input>
        <caf-error [settings]="settingsErrors.errorsFullName"></caf-error>
      </div>
      <div class="caf-grid-row split-2-cols split-sm-2-cols">
        <caf-select
          class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
          [settings]="settings.countryPhoneCode"
        ></caf-select>
        <caf-input
          class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
          [settings]="settings.phone"
        ></caf-input>
      </div>
      <div class="caf-grid-row split-2-cols split-sm-2-cols">
        <caf-error
          class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
          [settings]="settingsErrors.errorsCountryPhoneCode"
        ></caf-error>
        <caf-error
          class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
          [settings]="settingsErrors.errorsPhone"
        ></caf-error>
      </div>
      <div class="input-item">
        <caf-input [settings]="settings.email"></caf-input>
        <caf-error [settings]="settingsErrors.errorsEmail"></caf-error>
        <p class="disclaimer-text">{{'cafler.hoist.email.disclaimer' | translate}}</p>
      </div>

    </div>
  
    <!-- direccion de devolucion-->
    <div class="input-item">
      <span class="subtitle-1">{{'cafler.page.direction.delivery.title' | translate}}</span>
      <div *ngIf="isB2B" class="caf-grid-row split-2-cols split-sm-2-cols">
        <caf-input-google-autosuggest
        (onValidAddress)="timesFailedPickupAddress=0"
        (onInvalidAddress)="countPickupErrors()"
          class="col-start-sm-1 col-start-1 col-end-sm-10 col-end-10"
          [settings]="settings.deliveryAddress"
        >
        </caf-input-google-autosuggest>
        <button
          class="h-align-center v-align-center complete-address-button col-start-sm-11 col-start-11 col-end-sm-12 col-end-12"
          (click)="getB2BAddress(settings.deliveryAddress.formControlName)"
        >
          <caf-icon [settings]="settingsExtra.locationIcon"></caf-icon>
        </button>
      </div>
      <caf-input-google-autosuggest
      (onValidAddress)="timesFailedPickupAddress=0"
      (onInvalidAddress)="countPickupErrors()"
        *ngIf="!isB2B"
        [settings]="settings.deliveryAddress"
      >
      </caf-input-google-autosuggest>
      <caf-error
        *ngIf="timesFailedDropoffAddress<=maxErrorsOnAddress"
        class="extra-margin-error"
        [settings]="settingsErrors.errorsDropoffAddress"
      ></caf-error>
      <p *ngIf="timesFailedDropoffAddress>maxErrorsOnAddress" class="error-message error-msg">
        {{'cafler.address-message-error-too-many-attempts-dropoff.title' | translate }}
      </p>
      <p *ngIf="fg.errors?.sameAddress" class="error-message error-msg">
        {{'cafler.forms.error.same-address' | translate }}
      </p>
      <div *ngIf="fg.get('deliveryAddress')?.errors?.checkCoordinatesWithinCaflerArea" class="error-message error-msg">
        <div *ngIf="isB2B; else operativeZonesB2C">
          <p>{{'cafler.address.message.error.address-outside-operative-zone.B2B.message' | translate}}</p>
        </div>
        <ng-template #operativeZonesB2C >
          <p>{{'cafler.address.message.error.address-outside-operative-zone.title' | translate }}</p>
          <article class="notification-container notification-container__warning mb-24">
            <caf-icon [settings]="settingsExtra.warningIcon"></caf-icon>
            <p>{{'cafler.address.message.error.address-outside-operative-zone.notification-text' | translate }} <a href="{{operativeZonesURL}}" target="_blank">{{'cafler-operative-zones' | translate}}</a></p>
          </article> 
        </ng-template>
      </div>
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.deliveryAddressDetails"></caf-input>
    </div>
    <!-- contact delivery data-->
    <div>
      <div class="input-item">
        <span class="subtitle-1"
          >{{'cafler.page.contact-delivery.title' | translate}}
        </span>
        <caf-input [settings]="settings.dropoffFullName"></caf-input>
        <caf-error [settings]="settingsErrors.errorsDropoffFullName"></caf-error>
      </div>
      <div class="caf-grid-row split-2-cols split-sm-2-cols">
        <caf-select
          class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
          [settings]="settings.dropoffCountryPhoneCode"
        ></caf-select>
        <caf-input
          class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
          [settings]="settings.dropoffPhone"
        ></caf-input>
      </div>
      <div class="caf-grid-row split-2-cols split-sm-2-cols">
        <caf-error
          class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
          [settings]="settingsErrors.errorsDropOffPhone"
        ></caf-error>
        <caf-error
          class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
          [settings]="settingsErrors.errorsDropoffCountryPhoneCode"
        ></caf-error>
      </div>
      <div *ngIf="!isB2B" class="input-item">
        <caf-input [settings]="settings.dropoffEmail"></caf-input>
        <caf-error [settings]="settingsErrors.errorsDropoffEmail"></caf-error>
      </div>
    </div>
  
    <!-- fecha del servicio (transfer)-->
    <div class="input-item">
      <span class="subtitle-1">{{'cafler.page.service-date.info.title' | translate}}</span>
      <caf-datepicker [settings]="settings.pickupDate"></caf-datepicker>
      <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsPickupDate"></caf-error>
    </div>
  
    <!-- hora del servicio transfer-->
    <div *ngIf="readyToDisplayHours">
      <div class="caf-grid-row split-2-cols split-sm-2-cols">
        <caf-select #startServiceTimeComponent [settings]="settings.startPickUpTime"></caf-select>
        <caf-select #endServiceTimeComponent [settings]="settings.endPickUpTime"></caf-select>
      </div>
    </div>
    
    <div
    class="caf-grid-row split-2-cols split-sm-2-cols"
    
    >
      <caf-error
        *ngIf="!combinationNotValid && !dayChangedFlag"
        class="extra-margin-error col-start-sm-1 col-start-1 col-end-sm-1 col-end-1"
        [settings]="settingsErrors.errorsStartPickUpTime"
      ></caf-error>
      <caf-error
        *ngIf="!combinationNotValid && !dayChangedFlag"
        class="extra-margin-error col-start-sm-2 col-start-2 col-end-sm-2 col-end-2"
        [settings]="settingsErrors.errorsEndPickUpTime"
      ></caf-error>
    </div>
  
    <p class="error-message" *ngIf="readyToDisplayHours && noRangeHourAvailable">
      {{"cafler.page.service-time.error-range-hours-not-available" | translate}}
    </p>
    <p class="error-message" *ngIf="dayChangedFlag && !noRangeHourAvailable">
      {{"cafler.page.service-time.error-day-changed" | translate}}
    </p>
  
    <p class="error-message" *ngIf="combinationNotValid">
      {{"cafler.page.service-time.error-not-compatible" | translate}}
    </p>
    <p *ngIf="readyToDisplayHours" class="disclaimer-text">{{'cafler.hoist.email.disclaimer' | translate}}</p>

    <!-- vehicle information-->
    <div class="input-item">
      <span class="subtitle-1">{{'cafler.vehicle-info.title' | translate}}</span>
      <caf-select [settings]="settings.vehicleType"></caf-select>
      <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsVehicleType"></caf-error>
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.carPlaque"></caf-input>
      <caf-error [settings]="settingsErrors.errorsCarPlaque"></caf-error>
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.brand"></caf-input>
      <caf-error [settings]="settingsErrors.errorsBrand"></caf-error>
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.model"></caf-input>
      <caf-error [settings]="settingsErrors.errorsModel"></caf-error>
    </div>
    <!-- aditional data-->
    <div class="input-item">
      <span class="subtitle-1">{{'cafler.pickup-info.comments.title' | translate}}</span>
      <caf-textarea [settings]="settings.additionalComments"></caf-textarea>
    </div>
  </form>
  