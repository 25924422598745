export enum VerticalsTypeEnum {
  TRANSFER = 1,
  MECHANICAL_INSPECTION = 2,
  WASH = 3,
  REFUELING = 4,
  PRE_MOT = 5,
  LONG_DISTANCE_TRANSFER = 6,
  MOT = 7,
  VEHICLE_INSURANCE = 8,
  VEHICLE_DATA_COLLECTION = 9,
  ACCESSORY = 10,
  VALET = 11,
  TYRES = 12,
  FORMALITIES = 13,
  PARKING= 14,
  REPLACEMENT_VEHICLE = 15,
  TOW_TRUCK = 16,
  ALL_YOU_NEED = 17,
  }