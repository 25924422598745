import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PopupToppingRef } from './popup-topping-ref';
import {
  CaflerButtonSettingsModel,
} from '@cafler/common-ui';
import { PopupSettingsModel } from './model/popup-topping-settings.model';


@Component({
  selector: 'popup-topping',
  templateUrl: './popup-topping.component.html',
  styleUrls: ['./popup-topping.component.scss', './popup.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopupToppingsComponent implements OnInit {
  contentType: 'template' | 'string' | 'component' | undefined;
  content: any;
  context: any;
  settings!: PopupSettingsModel;

  confirmButton = <CaflerButtonSettingsModel>{
    id: 'okButton',
    text: 'cafler.page.toppings.popup.Accept.button',
  };
  cancelButton = <CaflerButtonSettingsModel>{
    id: 'noButton',
    text: 'cafler.page.toppings.popup.Cancel.button',
  };
  backButton = <CaflerButtonSettingsModel>{
    id: 'backButton',
    text: 'cafler.page.toppings.popup.no-services.Back.button'
  }
  
  constructor(private ref: PopupToppingRef) {}

  cancel() {
    this.ref.close({ info: 'cancel' });
  }
  confirm() {
    this.ref.close({ info: 'confirm' });
  }

  ngOnInit() {  
     this.settings = this.ref.data;
     if(this.settings.cancelButtonText) this.cancelButton.text = this.settings.cancelButtonText;
     if(this.settings.confirmButtonText) this.confirmButton.text = this.settings.confirmButtonText;
  }

}
