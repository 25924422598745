import { Subject } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { TemplateRef, Type } from '@angular/core';
import { PopupSettingsModel } from './model/pop-up-settings.model';


export interface OverlayToppingCloseEvent<R> {
    type: 'backdropClick' | 'close';
    data: R | undefined;
}

export class PopUpRef<R = any, T = any> {
    afterClosed$ = new Subject<OverlayToppingCloseEvent<R>>();

    constructor(
        public overlay: OverlayRef,
        public content: string | TemplateRef<any> | Type<any>,
        public data: PopupSettingsModel,
    ) {
        overlay.backdropClick().subscribe(() => {
        this._close('backdropClick', undefined);
        });
    }

    close(data?: R) {
        this._close('close', data);
    }

    private _close(type: 'backdropClick' | 'close', data?: R) {
        this.overlay.dispose();
        this.afterClosed$.next({
            type,
            data,
        });
        
        this.afterClosed$.complete();
    }
}