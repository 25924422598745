import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PopupHtmlCustomRef } from './popup-html-custom-ref';
import {
  CaflerButtonSettingsModel, CaflerIconSettingsModel,
} from '@cafler/common-ui';
import { PopupSettingsModel } from './model/popup-html-custom-settings.model';
import { KamikazeResource } from '../../resources/kamikaze.resource';


@Component({
  selector: 'popup-html-custom',
  templateUrl: './popup-html-custom.component.html',
  styleUrls: ['./popup-html-custom.component.scss', './popup.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopupHtmlCustomComponent implements OnInit {
  contentType: 'template' | 'string' | 'component' | undefined;
  content: any;
  context: any;
  settings!: PopupSettingsModel;
  htmlData: any;

  closeIcon = <CaflerIconSettingsModel>{
    name: 'caf-close',
    alt: 'Close icon',
  };
  
  constructor(
    private ref: PopupHtmlCustomRef,
    // private sanitizer: DomSanitizer,
    private resource: KamikazeResource,
    ) {}

  cancel() {
    this.ref.close({ info: 'cancel' });
  }

  ngOnInit() {  
    this.resource.getToppingChecklistHtmlData(this.ref.data.lang, this.ref.data.caflerHash).subscribe((res: any) => {
      this.htmlData = res;
      //this.htmlData = this.sanitizer.bypassSecurityTrustUrl(res);
    });
  }

}
