import { Component, OnInit } from '@angular/core';
import { CaflerPopupService } from '@cafler/common-ui';
import { Store } from '@ngrx/store';
import { PresentationFacade } from '../../facades/presentation.facade';
import { KamikazeActions } from '../../store/actions';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
  constructor(
    private modalService: CaflerPopupService,
    private presentationFacade: PresentationFacade,
    private store: Store,
  ) {}

  ngOnInit(): void {
    let x = this.modalService.open('', this.presentationFacade.getModalConfirmMessageSettings());
    this.store.dispatch({ type: KamikazeActions.PurchaseCompleted });
    x.afterClosed$.subscribe((res) => {
      window.location.href = 'https://cafler.com';
    });
  }
}
