import { WashDetailsSettingsModel } from "src/app/components/wash-details-topping/models/wash-details-settings.model";


export type washTypes = "premium-exterior-car-wash" 
 | "premium-interior-car-wash" 
 | "premium-complete-car-wash"
 | "express-complete-wash"
 | "tunnel-exterior-car-wash" 
 | "tunnel-interior-car-wash" 
 | "tunnel-complete-car-wash" 
 | "tunnel-motorbike-wash" 
 | "integral-washing"
 | "upholstery-detail-wash-5-seats"
 | "headlight-polishing"
 

export function getWashDetailsSettings(productAlias: washTypes): WashDetailsSettingsModel {
    return washPrdouctDetails[productAlias] 
}   

const washPrdouctDetails = {

    "premium-exterior-car-wash": <WashDetailsSettingsModel>{
        id: "premium-exterior-car-wash",
        title: "cafler.wash-details.premium-exterior-car-wash.title",
        description: "cafler.wash-details.premium-exterior-car-wash.description",
        howItWorks: [
            {
                icon: { name: 'caf-simple-calendar', alt: 'calendario'},
                sentence:"cafler.wash-details.premium.howItWorks-sentence1"
            },
            {
                icon: { name: 'caf-person-icon', alt: 'icono de persona'},
                sentence:"cafler.wash-details.premium.howItWorks-sentence2"
            },
            {
                icon: { name: 'caf-wash-black', alt: 'wash'},
                sentence:"cafler.wash-details.premium.howItWorks-sentence3"
            },
        ],
        whatIncludes: [
            "cafler.toppings.washing.features.bodywork-clean-complete",
            "cafler.toppings.washing.features.exterior-windows-clean",
            "cafler.toppings.washing.features.protection-wax",
            "cafler.toppings.washing.features.glossy-finish",
            "cafler.toppings.washing.features.disinfection-complete"
        ]
    },
    "premium-interior-car-wash": <WashDetailsSettingsModel>{
        id: "premium-interior-car-wash",
        title: "cafler.wash-details.premium-interior-car-wash.title",
        description: "cafler.wash-details.premium-interior-car-wash.description",
        howItWorks: [
            {
                icon: { name: 'caf-simple-calendar', alt: 'calendario'},
                sentence:"cafler.wash-details.premium.howItWorks-sentence1"
            },
            {
                icon: { name: 'caf-person-icon', alt: 'icono de persona'},
                sentence:"cafler.wash-details.premium.howItWorks-sentence2"
            },
            {
                icon: { name: 'caf-wash-black', alt: 'wash'},
                sentence:"cafler.wash-details.premium.howItWorks-sentence3"
            },
        ],
        whatIncludes: [
            "cafler.toppings.washing.features.deep-aspirate",
            "cafler.toppings.washing.features.carpet-aspirate",
            "cafler.toppings.washing.features.interior-windows-clean",
            "cafler.toppings.washing.features.door-clean",
            "cafler.toppings.washing.features.frame-door-clean",
            "cafler.toppings.washing.features.interior-plastic-clean",
            "cafler.toppings.washing.features.dashboard-console-clean",
            "cafler.toppings.washing.features.trunk-clean",
            "cafler.toppings.washing.features.disinfection-complete"
        ]
    },
    "premium-complete-car-wash":<WashDetailsSettingsModel> {
        id: "premium-complete-car-wash",
        title: "cafler.wash-details.premium-complete-car-wash.title",
        description: "cafler.wash-details.premium-complete-car-wash.description",
        howItWorks: [
            {
                icon: { name: 'caf-simple-calendar', alt: 'calendario'},
                sentence:"cafler.wash-details.premium.howItWorks-sentence1"
            },
            {
                icon: { name: 'caf-person-icon', alt: 'icono de persona'},
                sentence:"cafler.wash-details.premium.howItWorks-sentence2"
            },
            {
                icon: { name: 'caf-wash-black', alt: 'wash'},
                sentence:"cafler.wash-details.premium.howItWorks-sentence3"
            },
        ],
        whatIncludes: [
            "cafler.toppings.washing.features.bodywork-clean-complete",
            "cafler.toppings.washing.features.exterior-windows-clean",
            "cafler.toppings.washing.features.protection-wax",
            "cafler.toppings.washing.features.glossy-finish",
            "cafler.toppings.washing.features.disinfection-complete",
            "cafler.toppings.washing.features.deep-aspirate",
            "cafler.toppings.washing.features.carpet-aspirate",
            "cafler.toppings.washing.features.interior-windows-clean",
            "cafler.toppings.washing.features.door-clean",
            "cafler.toppings.washing.features.frame-door-clean",
            "cafler.toppings.washing.features.interior-plastic-clean",
            "cafler.toppings.washing.features.dashboard-console-clean",
            "cafler.toppings.washing.features.trunk-clean", 
        ]
    },
    "express-complete-wash": <WashDetailsSettingsModel> {
        id: "express-complete-wash",
        title: "cafler.wash-details.express-complete-wash.title",
        description: "cafler.wash-details.express-complete-wash.description",
        howItWorks: [
            {
                icon: { name: 'caf-simple-calendar', alt: 'calendario'},
                sentence:"cafler.wash-details.premium.howItWorks-sentence1"
            },
            {
                icon: { name: 'caf-person-icon', alt: 'icono de persona'},
                sentence:"cafler.wash-details.premium.howItWorks-sentence2"
            },
            {
                icon: { name: 'caf-wash-black', alt: 'wash'},
                sentence:"cafler.wash-details.premium.howItWorks-sentence3"
            },
        ],
        whatIncludes: [
            "cafler.toppings.washing.features.bodywork-clean",
            "cafler.wash-details-details.whatIncludes.exterior-windows",
            "cafler.toppings.washing.features.disinfection-complete",
            "cafler.toppings.washing.features.deep-aspirate",
            "cafler.toppings.washing.features.carpet-aspirate",
            "cafler.toppings.washing.features.interior-windows-clean",
            "cafler.toppings.washing.features.door-clean",
            "cafler.toppings.washing.features.frame-door-clean",
            "cafler.toppings.washing.features.interior-plastic-clean",
            "cafler.toppings.washing.features.dashboard-console-clean"
        ]
              
    },
    "tunnel-exterior-car-wash": <WashDetailsSettingsModel> {
        id: "tunnel-exterior-car-wash",
        title: "cafler.wash-details.tunnel-exterior-car-wash.title",
        description: "cafler.wash-details.tunnel-exterior-car-wash.description",
        howItWorks: [
            {
                icon: { name: 'caf-simple-calendar', alt: 'calendario'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence1"
            },
            {
                icon: { name: 'caf-person-icon', alt: 'icono de persona'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence2"
            },
            {
                icon: { name: 'caf-simple-car', alt: 'car'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence3"
            },
            {
                icon: { name: 'caf-wash-black', alt: 'wash'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence4"
            },
        ],
        whatIncludes: [
            "cafler.wash-details-tunnel.whatIncludes.wash.bodywork-windows-rotating-brushes",
            "cafler.toppings.washing.features.air-dry"
        ]
    },
    "tunnel-interior-car-wash": <WashDetailsSettingsModel> {
        id: "tunnel-interior-car-wash",
        title: "cafler.wash-details.tunnel-interior-car-wash.title",
        description: "cafler.wash-details.tunnel-interior-car-wash.description",
        howItWorks: [
            {
                icon: { name: 'caf-simple-calendar', alt: 'calendario'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence1"
            },
            {
                icon: { name: 'caf-person-icon', alt: 'icono de persona'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence2"
            },
            {
                icon: { name: 'caf-simple-car', alt: 'car'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence3"
            },
            {
                icon: { name: 'caf-wash-black', alt: 'wash'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence4"
            },
        ],
        whatIncludes: [
            "cafler.wash-details-tunnel.whatIncludes.wash.carpet.seats",
            "cafler.wash-details-tunnel.whatIncludes.wash.dashboard-console",
            "cafler.wash-details-tunnel.whatIncludes.wash.windows-interior-plastic-doors",
            "cafler.wash-details-tunnel.whatIncludes.wash.trunk-clean"
        ]
    },
    "tunnel-complete-car-wash":<WashDetailsSettingsModel> {
        id: "tunnel-complete-car-wash",
        title: "cafler.wash-details.tunnel-complete-car-wash.title",
        description: "cafler.wash-details.tunnel-complete-car-wash.description",
        howItWorks: [
            {
                icon: { name: 'caf-simple-calendar', alt: 'calendario'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence1"
            },
            {
                icon: { name: 'caf-person-icon', alt: 'icono de persona'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence2"
            },
            {
                icon: { name: 'caf-simple-car', alt: 'car'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence3"
            },
            {
                icon: { name: 'caf-wash-black', alt: 'wash'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence4"
            },
        ],
        whatIncludes: [
            "cafler.wash-details-tunnel.whatIncludes.wash.bodywork-windows-rotating-brushes",
            "cafler.toppings.washing.features.air-dry",
            "cafler.wash-details-tunnel.whatIncludes.wash.carpet.seats",
            "cafler.wash-details-tunnel.whatIncludes.wash.dashboard-console",
            "cafler.wash-details-tunnel.whatIncludes.wash.windows-interior-plastic-doors",
            "cafler.wash-details-tunnel.whatIncludes.wash.trunk-clean"
        ]
    },
    "tunnel-motorbike-wash":<WashDetailsSettingsModel> {
        id: "tunnel-motorbike-wash",
        title: "cafler.wash-details.tunnel-motorbike-wash.title",
        description: "cafler.wash-details.tunnel-motorbike-wash.description",
        howItWorks: [
            {
                icon: { name: 'caf-simple-calendar', alt: 'calendario'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence1"
            },
            {
                icon: { name: 'caf-person-icon', alt: 'icono de persona'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence2"
            },
            {
                icon: { name: 'caf-simple-car', alt: 'car'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence3"
            },
            {
                icon: { name: 'caf-wash-black', alt: 'wash'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence4"
            },
        ],
        whatIncludes: [
            "cafler.wash-details-tune.moto.whatIncludes.wash.trunk-clean-fairing-seat",
            "cafler.wash-details-tune.moto.whatIncludes.wash.chassis-mechanical"
        ]
    },

    "integral-washing": <WashDetailsSettingsModel> {
        id: "integral-washing",
        title: "cafler.wash-details.integral-washing.title",
        description: "cafler.wash-details.integral-washing.description",
        howItWorks: [
            {
                icon: { name: 'caf-simple-calendar', alt: 'calendario'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence1"
            },
            {
                icon: { name: 'caf-person-icon', alt: 'icono de persona'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence2"
            },
            {
                icon: { name: 'caf-simple-car', alt: 'car'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence3"
            },
            {
                icon: { name: 'caf-wash-black', alt: 'wash'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence4"
            },
        ],
        whatIncludes: [
            "cafler.wash-details-details.whatIncludes.chassis",
            "cafler.wash-details-details.whatIncludes.exterior-windows",
            "cafler.toppings.washing.features.protection-wax",
            "cafler.toppings.washing.features.glossy-finish",
            "cafler.wash-details-details.whatIncludes.tires",
            "cafler.wash-details-details.whatIncludes.wheels",
            "cafler.toppings.washing.features.disinfection-complete",
            "cafler.wash-details-details.whatIncludes.vacuuming",
            "cafler.wash-details-details.whatIncludes.carpet",
            "cafler.wash-details-details.whatIncludes.tapestry-carpet",
            "cafler.toppings.washing.features.interior-windows-clean",
            "cafler.toppings.washing.features.door-clean",
            "cafler.toppings.washing.features.frame-door-clean",
            "cafler.toppings.washing.features.interior-plastic-clean",
            "cafler.toppings.washing.features.dashboard-console-clean",
            "cafler.toppings.washing.features.trunk-clean"

        ]
    },

    "upholstery-detail-wash-5-seats": <WashDetailsSettingsModel> {
        id: "upholstery-detail-wash-5-seats",
        title: "cafler.wash-details.upholstery-detail-wash-5-seats.title",
        description: "cafler.wash-details.upholstery-detail-wash-5-seats.description",
        howItWorks: [
            {
                icon: { name: 'caf-simple-calendar', alt: 'calendario'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence1"
            },
            {
                icon: { name: 'caf-person-icon', alt: 'icono de persona'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence2"
            },
            {
                icon: { name: 'caf-simple-car', alt: 'car'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence3"
            },
            {
                icon: { name: 'caf-wash-black', alt: 'wash'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence4"
            },
        ],
        whatIncludes: [
            "cafler.wash-details-details.whatIncludes.seat",
            "cafler.wash-details-details.whatIncludes.tapistry-vacuuming",
            "cafler.wash-details-details.whatIncludes.tapistry-dry"
        ]
    },
    "headlight-polishing": <WashDetailsSettingsModel> {
        id: "headlight-polishing",
        title: "cafler.wash-details.headlight-polishing.title",
        description: "cafler.wash-details.headlight-polishing.description",
        howItWorks: [
            {
                icon: { name: 'caf-simple-calendar', alt: 'calendario'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence1"
            },
            {
                icon: { name: 'caf-person-icon', alt: 'icono de persona'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence2"
            },
            {
                icon: { name: 'caf-simple-car', alt: 'car'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence3"
            },
            {
                icon: { name: 'caf-wash-black', alt: 'wash'},
                sentence:"cafler.wash-details.tunnel.howItWorks-sentence4"
            },
        ],
        whatIncludes: [
            "cafler.wash-details-details.whatIncludes.light",
            "cafler.wash-details-details.whatIncludes.protection-wax",
            "cafler.wash-details-details.whatIncludes.windows"
        ]
    }


}