<div *ngIf="currentPage !== 'my-services-details'">
  <div class="stepper-body"></div>
  <div *ngIf="currentPage === 'topping-selection' && !mobileSize" class="wrapper-body-toppings">
    <h2 class="title">{{ title | translate }}</h2>
    <div class="continue-no-toppings-title no-topping-container">
      <a class="no-topping-link" (click)="continueWithoutTopping()">
        {{ 'cafler.page.toppings.no-topping-button' | translate }}</a
      >
    </div>
  </div>
  <div *ngIf="currentPage !== 'topping-selection'" class="wrapper-body">
    <h2 class="title" [innerHTML]="title | translate"></h2>
  </div>
  <a href="https://cafler.com/">
    <caf-icon *ngIf="showLogo" class="logo-icon" [settings]="iconLogoSettings"></caf-icon>
  </a>
  <caf-icon
    *ngIf="showBackButton"
    [class]="{
      'chevron-icon': !isStepperShown || isB2B,
      'chevron-icon-desktop': isStepperShown && !isB2B
    }"
    (click)="backButton()"
    [settings]="iconSettings"
  >
  </caf-icon>
  <div class="buttons-wrapper">
    <a *ngIf="showPhone" class="hide-on-large phone-wrapper" href="tel:+34 900 649 071">
      <caf-icon [settings]="phoneIconDesktop"></caf-icon>
    </a>
    <app-language-selector></app-language-selector>
  </div>
</div>

<!-- Simple header -->
<div *ngIf="currentPage === 'my-services-details'" class="simple-header">
    <a (click)="backButton()" class="pointer" style="display: flex; gap: 13px;">
      <caf-icon
        *ngIf="showBackButton"
        [settings]="iconSettings"
      >
      </caf-icon>
      <p>{{'cafler-header-go-back' | translate}}</p>
    </a>  
    <div class="buttons-wrapper">
      <a *ngIf="showPhone" class="hide-on-large phone-wrapper" href="tel:+34 900 649 071">
        <caf-icon [settings]="phoneIconDesktop"></caf-icon>
      </a>
      <app-language-selector></app-language-selector>
    </div>
</div>


