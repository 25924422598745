<form class="caf-md-container margin-top-forms" [formGroup]="fg">
  <!-- direccion recogida-->
  <section>
    <div class="input-item">
      <h3 class="subtitle-1">{{'cafler.pickup-info.pickup-info.title' | translate}}</h3>
      <div *ngIf="isB2B" class="caf-grid-row split-2-cols split-sm-2-cols">
        <caf-input-google-autosuggest
          class="col-start-sm-1 col-start-1 col-end-sm-10 col-end-10"
          (onValidAddress)="onValidAddress($event, settings.pickupAddress.formControlName)"
          (onInvalidAddress)="countPickupErrors()"
          [settings]="settings.pickupAddress"
        >
        </caf-input-google-autosuggest>
        <button
          class="h-align-center v-align-center complete-address-button col-start-sm-11 col-start-11 col-end-sm-12 col-end-12"
          (click)="getB2BAddress(settings.pickupAddress.formControlName)"
        >
          <caf-icon [settings]="settingsExtra.locationIcon"></caf-icon>
        </button>
      </div>
      <caf-input-google-autosuggest
        *ngIf="!isB2B"
        (onValidAddress)="onValidAddress($event, settings.pickupAddress.formControlName)"
        (onInvalidAddress)="countPickupErrors()"
        [settings]="settings.pickupAddress"
      >
      </caf-input-google-autosuggest>
      <caf-error
        *ngIf="timesFailedPickupAddress<=maxErrorsOnAddress"
        class="extra-margin-error"
        [settings]="settingsErrors.errorsPickupAddress"
      ></caf-error>
      <p *ngIf="timesFailedPickupAddress>maxErrorsOnAddress" class="error-message error-msg">
        {{'cafler.address-message-error-too-many-attempts-pickup.title' | translate }}
      </p>
      <div *ngIf="fg.get('pickupAddress')?.errors?.checkCoordinatesWithinCaflerArea" class="error-message error-msg">
          <p>{{'cafler.address.message.error.address-outside-operative-zone.title' | translate }}</p>
          <article class="notification-container notification-container__warning mb-24">
            <caf-icon [settings]="settingsExtra.warningIcon"></caf-icon>
            <p>{{'cafler.address.message.error.address-outside-operative-zone.notification-text' | translate }} <a href="{{operativeZonesURL}}" target="_blank">{{'cafler-operative-zones' | translate}}</a></p>
          </article> 
      </div>
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.pickupAddressDetails"></caf-input>
    </div>
  </section>
  
  <!-- same delivery address-->
  <!-- <div class="input-item">
    <caf-checkbox [settings]="settings.sameDeliveryAddress"></caf-checkbox>
  </div> -->
  <!-- direccion de devolucion-->
  <div *ngIf="isSameDeliveryAddress" class="input-item">
    <span class="subtitle-1">{{'cafler.page.wash.dropoff.info.title' | translate}}</span>
    <div *ngIf="isB2B" class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-input-google-autosuggest
        class="col-start-sm-1 col-start-1 col-end-sm-10 col-end-10"
        (onValidAddress)="onValidAddress($event, settings.dropoffAddress.formControlName)"
        (onInvalidAddress)="countDropoffErrors()"
        [settings]="settings.dropoffAddress"
      >
      </caf-input-google-autosuggest>
      <button
        class="h-align-center v-align-center complete-address-button col-start-sm-11 col-start-11 col-end-sm-12 col-end-12"
        (click)="getB2BAddress(settings.dropoffAddress.formControlName)"
      >
        <caf-icon [settings]="settingsExtra.locationIcon"></caf-icon>
      </button>
    </div>
    <caf-input-google-autosuggest
      *ngIf="!isB2B"
      (onValidAddress)="onValidAddress($event, settings.dropoffAddress.formControlName)"
      (onInvalidAddress)="countDropoffErrors()"
      [settings]="settings.dropoffAddress"
    >
    </caf-input-google-autosuggest>
    <caf-error
      *ngIf="timesFailedDropoffAddress<=maxErrorsOnAddress"
      class="extra-margin-error"
      [settings]="settingsErrors.errorsDropoffAddress"
    ></caf-error>
    <p *ngIf="timesFailedDropoffAddress>maxErrorsOnAddress" class="error-message error-msg">
      {{'cafler.address-message-error-too-many-attempts-dropoff.title' | translate }}
    </p>
    <p *ngIf="fg.errors?.sameAddress" class="error-message error-msg">
      {{'cafler.forms.error.same-address' | translate }}
    </p>
  </div>

  <div *ngIf="isSameDeliveryAddress" class="input-item">
    <caf-input [settings]="settings.dropoffAddressDetails"></caf-input>
  </div>

  <section>
    <h2 class="subtitle-1 appointment-date-header">{{'cafler.page.service-previous-itv-appointment-info' | translate}}</h2>
    <!-- información de la cita-->
    <section>
      <h3 class="subtitle-1">{{'cafler.page.service-appointment-time' | translate}}</h3>
      <div class="input-item">
        <caf-datepicker [settings]="settings.appointmentDate"></caf-datepicker>
        <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsAppointmentDate"></caf-error>
      </div>
  
      <div class="input-item">
        <caf-timepicker [settings]="settings.appointmentTime" (focusout)="validateHoursStation($event)"></caf-timepicker>
        <p *ngIf="fg.get('appointmentTime')?.errors?.hourNotInStationRange" class="error-message error-msg">
          {{ 'cafler.forms.error.hourNotInStationRange' | translate: {startRange: startRangeAppointment, endRange: endRangeAppointment} }}
        </p>
      </div>
    </section>
    <!-- información del servicio-->
  
    <div>
      <h2 class="subtitle-1">{{'cafler.page.service-appointment-info' | translate}}</h2>
      <div class="input-item">
        <caf-input [settings]="settings.appointmentCode"></caf-input>
        <caf-error [settings]="settingsErrors.errorsAppointmentCode"></caf-error>
      </div>
      <caf-select [settings]="settings.station"></caf-select>
    </div>
  
    <div class="input-item">
      <caf-checkbox [settings]="settings.isTechnicalInspectionPaid"></caf-checkbox>
    </div>
  </section>

  <!-- vehicle information-->
  <section>
    <h3 class="subtitle-1">{{'cafler.vehicle-info.title' | translate}}</h3>
    <div class="input-item">
      <caf-select [settings]="settings.vehicleType"></caf-select>
      <caf-error [settings]="settingsErrors.errorsVehicleType"></caf-error>
    </div>
    <div class="input-item">
      <caf-select [settings]="settings.fuelType"></caf-select>
      <caf-error [settings]="settingsErrors.errorsFuelType"></caf-error>
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.carPlaque"></caf-input>
      <caf-error [settings]="settingsErrors.errorsCarPlaque"></caf-error>
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.brand"></caf-input>
      <caf-error [settings]="settingsErrors.errorsBrand"></caf-error>
    </div>
    <div class="input-item">
      <caf-input [settings]="settings.model"></caf-input>
      <caf-error [settings]="settingsErrors.errorsModel"></caf-error>
    </div>
  </section>
  
  <!-- contact data-->
  <section>
    <div class="input-item">
      <span class="subtitle-1">{{'cafler.contact.title' | translate}}</span>
      <caf-input [settings]="settings.fullName"></caf-input>
      <caf-error [settings]="settingsErrors.errorsFullName"></caf-error>
    </div>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-select
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settings.countryPhoneCode"
      ></caf-select>
      <caf-input
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settings.phone"
      ></caf-input>
    </div>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-error
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settingsErrors.errorsCountryPhoneCode"
      ></caf-error>
      <caf-error
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settingsErrors.errorsPhone"
      ></caf-error>
    </div>
    <div *ngIf="!isB2B" class="input-item">
      <caf-input [settings]="settings.email"></caf-input>
      <caf-error [settings]="settingsErrors.errorsEmail"></caf-error>
    </div>
  </section>
  
  <!-- aditional data-->
  <section class="input-item">
    <h3 class="subtitle-1">{{'cafler.pickup-info.instruction.title' | translate}}</h3>
    <caf-textarea [settings]="settings.additionalComments"></caf-textarea>
  </section>


</form>

