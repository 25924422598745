import { Injectable, Injector, TemplateRef, Type } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { PopupHtmlCustomRef } from '../popup-html-custom-ref';
import { PopupHtmlCustomComponent } from '../popup-html-custom.component';
import { PopupSettingsModel } from '../model/popup-html-custom-settings.model';


@Injectable({
  providedIn: 'root',
})
export class CaflerPopupHtmlCustomService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  open<R = any, T = any>(
    content: string | TemplateRef<any> | Type<any>,
    data: any,
  ): PopupHtmlCustomRef<R> {
    const configs = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['modal', 'is-active'],
      backdropClass: 'popup-background',
    });
    content = content ? content : 'Default text.';
    const overlayRef = this.overlay.create(configs);

    const cafOverlayRef = new PopupHtmlCustomRef<R, T>(overlayRef, content, data);

    const injector = this.createInjector(cafOverlayRef, this.injector);

    overlayRef.attach(new ComponentPortal(PopupHtmlCustomComponent, null, injector));

    return cafOverlayRef;
  }

  createInjector(ref: PopupHtmlCustomRef, inj: Injector) {
    const injectorTokens = new WeakMap([[PopupHtmlCustomRef, ref]]);
    return new PortalInjector(inj, injectorTokens); // TODO -> Fix deprecation
  }
}
