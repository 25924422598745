import { Injectable } from '@angular/core';
import { PresentationFacade } from '../facades/presentation.facade';
import { SingleDataHelperService } from '../helpers/single-data-helper.service';
import { first } from 'rxjs/operators';
import { KamikazeFacade } from '../facades/kamikaze.facade';
import { KamikazeActions, setCurrentVerticalProducts, setIsLoading } from '../store/actions';
import { Store } from '@ngrx/store';
import { VerticalsTypeEnum } from '../Enums/verticalsType.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from './navigation.service';

@Injectable()
export class DuplicationFormService {
  constructor(    
    private store: Store,
    private router: Router,
    private presentationFacade: PresentationFacade,
    private singleDataHelperService: SingleDataHelperService,
    private kamikazeFacade: KamikazeFacade,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    ) {}

  
  async duplicateForm(data: any, zone: any, isB2B: boolean) {
    this.store.dispatch(setIsLoading({ isLoading: true }));
    this.resetPreviousProductFromState()

    const {caflerHash} = data;

    let detailsData: any;
    let products: any;
    
    try{
      detailsData = await this.kamikazeFacade.getDataDetailsFromService(caflerHash).pipe(first()).toPromise();
      products = await this.singleDataHelperService.getVerticalProducts(zone.ZoneId, detailsData.VerticalType, isB2B).pipe(first()).toPromise()
    }catch(e){
      // TODO Handle error
      console.log(e);
    }

    const toppings = this.getToppingsFromProducts(detailsData.Products);
    const id = detailsData.Products[0].ProductId;
    const configuration: any = this.getConfigurationFromProducts(detailsData.Products);
    
    this.store.dispatch(setCurrentVerticalProducts({ verticalProducts: products }));

    const replacement = detailsData.Products[0].LocalizationKey === 'transfer-pack-replacement-vehicle'
    const homologation = configuration.HomologationSubConfiguration;
    
    let finalForm = {...detailsData, ...configuration[0], Id: id}

    if(replacement){
     finalForm = {...finalForm, AdditionalReplacementVehicleConfiguration: detailsData.Products.AdditionalReplacementVehicleConfiguration} 
    }

    const formattedForm = this.singleDataHelperService.getFormattedForm(finalForm) 
    
    let productType = this.presentationFacade.getVerticalById(Number(detailsData.VerticalType));
    
    switch (productType) {
        case 'revision':
            productType = 'vehicle-service';
            break;
        case 'refueling':
            productType = 'refuel';
            break
    }

    this.store.dispatch({
      type: KamikazeActions.SetToppingsAdded,
      toppingsAdded: toppings,
    })

    this.store.dispatch({
      type: KamikazeActions.SetCurrentVertical,
      selectedVertical: detailsData.VerticalType,
    });

    const productName = Object.keys(products)[0]

    let verticalProduct = products[productName].find((product: any) => {
      return product.OrdersManagerId === detailsData.Products[0].ProductId;
   });

   let currentProduct = {
      ordersManagerProductId: verticalProduct.OrdersManagerId,
      category: verticalProduct.Category,
      title: verticalProduct.Title,
      holidays: verticalProduct.Holidays
   }

    this.store.dispatch({
      type: KamikazeActions.SetSelectedProduct,
      selectedProduct: currentProduct,
    });

    this.store.dispatch({ type: KamikazeActions.StoreProductType, productType: productType });

    const URL = this.getURL(zone.Name, detailsData.VerticalType, productType, detailsData.Products[0]);
    this.store.dispatch(setIsLoading({ isLoading: false }));

    this.router.navigate([URL], { relativeTo: this.activatedRoute, state:{
      initialForm: formattedForm,
      isDuplicated: true
    } })
  }


  private resetPreviousProductFromState(){
    this.store.dispatch({
      type: KamikazeActions.SetServiceTimeOptions,
      serviceTimeOptions: null,
    });
    
    this.store.dispatch({ 
      type: KamikazeActions.StoreProductData, 
      productData: null 
    });

    this.store.dispatch({ type: KamikazeActions.ClearToppingsAdded });
  }

  private getURL(zoneName: string, verticalType: number, productType: string, product: any ): string{
    let URL = `/${zoneName}/${productType}`;  
    let prodType = '';

    switch(verticalType){
        case VerticalsTypeEnum.MOT:
            let motType = this.getMotType(product);
            if(motType){
                prodType = `/${motType}`
            }
            break
        case VerticalsTypeEnum.WASH:
            let washType = product.LocalizationKey;
            if(washType){
                prodType = `/${washType}`
            }
            break
        case VerticalsTypeEnum.MECHANICAL_INSPECTION:
            let inspectionType = product.LocalizationKey;
            const isBundle = product.Configuration['BundleName'] !== null;
            
            if(isBundle){
                prodType = `/maintenance-bundle`
            }

            if(inspectionType){
                prodType += `/${inspectionType}`
            }
    }
    return URL + prodType;
  }

  private getToppingsFromProducts(products: any): any[]{
    if(products.length <= 1) return [];

    const toppings: any[] = []

    products.forEach((product: any) => {
      if(product.IsTopping){
        toppings.push(product)
      }
    })

    return toppings;
  }

  private getMotType(product: any): string | null{
    const EMPTY_ID= "00000000-0000-0000-0000-000000000000";

    if(product.VerticalType !== 7) return null;

    if(product.Configuration['StationId'] !== EMPTY_ID){
      return 'appointment';
    }else if(product.Configuration.HomologationSubConfiguration){
      return 'homologation';
    }
    else {
      return 'no-appointment';
    }
  }

  private getConfigurationFromProducts(products: any) {
    const configuration: any[] = [];

    products.forEach((product: any) => {
      if(product.Configuration && !product.IsTopping){
        configuration.push(product.Configuration)
      }
    });

    return configuration;
  }
}
