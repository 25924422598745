<form class="form-container" [formGroup]="fg">
  <div class="cards-container">
    <div class="card" *ngFor="let product of productsSettings.products; let i = index">
      <caf-product-card
        [class]="'card' + (i + 1)"
        [settings]="product"
        (productSelected)="pickProduct($event)"
      ></caf-product-card>
    </div>
  </div>
  <div class="center">
    <hr class="stamp-line" />
    <p>{{ 'cafler.product.revision.stamp.description' | translate }}</p>
    <caf-icon [settings]="stampIcon"></caf-icon>
  </div>
</form>
