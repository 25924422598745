<div class="topping-selector" 
    [class] = "{disabled: settings.disabled, selected: settings.checked}">
    <div class="clickable-container" (click)="selectTopping()">
        <div class="first-row">
            <div class="title-container">
                <h2 class="topping-selector-title">{{ settings.title | translate }}</h2>
                <caf-badge
                    *ngIf="settings.isNew"
                    [settings]="badgeSettings">
                </caf-badge>
            </div>
            <div>
                <label class="checkbox-label">
                    <p class="checkbox-text">
                        {{ 'cafler.toppings.washing.selected-button' | translate }}
                    </p>
                    <div type="checkbox"  class="caf-checkbox__body">
                        <span
                            class="checkbox"
                            ng-model="true"
                            [class] = "{checkbox_checked: settings.checked}"
                        ></span>
                    </div>
                </label>
            </div>
        </div>
        <div class="second-row" *ngIf="settings.elementsAdded && settings.elementsAdded! > 0 || settings.serviceDuration !== ''">
            <div *ngIf="settings.elementsAdded && settings.elementsAdded! > 0" class="extra-elements">
                <caf-icon [settings]="iconDarkGreenCheckSettings"></caf-icon>
                <div *ngIf="settings.elementsAdded === 1; else pluralElementsAdded">
                    <p>{{settings.elementsAdded}} {{'cafler.toppings.extra-added-singular' | translate}}</p>
                </div>
                <ng-template #pluralElementsAdded>
                    <p>{{settings.elementsAdded}} {{'cafler.toppings.extra-added-plural' | translate}}</p>
                </ng-template>  
            </div>
            <div class="service-duration-container" *ngIf="settings.serviceDuration !== ''">
                <caf-icon [settings]="iconClockSettings"></caf-icon>
                <span class="service-duration">{{ settings.serviceDuration | translate }}</span>
            </div>
        </div>
        <div *ngIf="settings.description" class="description">
            <span> 
                {{ settings.description | translate }}
            </span>
        </div>
    </div>

    <div class="last-row">
        <div *ngIf='settings.additionalInformation.length > 0; else noAdditionalInfo' class="more-info" (click)="displayAdditionalInformation()">
            <span class="more-info-text">
                {{ 'cafler.toppings.washing.included-button' | translate }}
            </span>
            <caf-icon
                class="select-icon"
                [class]="{ 'rotate-chevron-more-info': isAdditionalInformationShown }"
                [settings]="iconChevronDownBlueSettings"
            ></caf-icon>          
        </div>
        <ng-template #noAdditionalInfo><div></div></ng-template>
        <div class="price-container" (click)="selectTopping()">
            <span class="price" >
                {{ settings.price | translate }}
            </span>
        </div>
    </div>
    <div *ngIf="isAdditionalInformationShown">
        <ul>
            <div *ngFor="let info of settings.additionalInformation">
                <li>
                    {{ info | translate }}
                </li>
            </div>
        </ul>
    </div>
</div>
