import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimeFormatterService {

  constructor() { }

  /**
  * Remove the location and offset from the time string
  * @param time: string format: yyyy-MM-dd'T'HH:mm:ss+01 Europe/Madrid" 
  * @return: Date format: yyyy-MM-dd'T'HH:mm:ss"
  */
  removeLocationAndOffset(time: string){
    return new Date(time.split('T')[0] + 'T' + time.split('T')[1].substring(0,5))
  }

  /*
  * Returns the number of days between two dates, 
  * if the dates are the same, it returns 0.
  * if the two dates are the same day but differenct time, it returns 1.
  */
  getNumberOfDaysBetweenTwoDates(date1: Date, date2: Date){
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.ceil(Math.abs((date1.getTime() - date2.getTime()) / oneDay));
    return diffDays
  }

  /**
   * Increase the time of a date by the number of minutes
   */
  increaseTime(date: Date, minutes: number){
    const SECONDS = 60000;

    return new Date(date.getTime() + minutes * SECONDS)
  }

  /**
   * Return the time format from a date
   * @param date 
   * @returns time with the following format: HH:MM
   */
  getTimeFromDate(date: Date){
    let hours = (date.getHours() < 10 ? '0' : '') + date.getHours();      
    let minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  
    return hours + ':' + minutes;
  }



  /**
   * Return the time from a date string with the following format: yyyy-MM-dd'T'HH:mm:ss+01 Europe/Madrid
   * @param date 
   * @returns time with the following format: HH:MM
   */
  getTimeFromDateString(date: String){
    let dateSplitted = date.split('T');
    let timeFormatted = dateSplitted[1].substring(0,5);
    return timeFormatted;
  }

  /**
   * return the string for a time range
   * @param startPickUpTime 
   * @param endPickUpTime 
   * @returns 
   */

  getTimeRangeString(startPickUpTime: string, endPickUpTime?: string){
    if(endPickUpTime){
      const startTime = this.getTimeFromDateString(startPickUpTime);
      const endTime = this.getTimeFromDateString(endPickUpTime);

      return `${startTime}h - ${endTime}h`;
    }
    const date = new Date(startPickUpTime.substring(0,19))
    const newDate = this.increaseTime(date, 15);
  
    return this.getTimeFromDate(date) + 'h - ' + this.getTimeFromDate(newDate) + 'h';
  }

  
  /**
   * removes the seconds of a string with de folowing format: HH:MM:SS
   * @param time 
   * @returns 
   */
  removeSeconds(time: string){
    const hour = time.split(':')[0];
    const minutes = time.split(':')[1];

    return `${hour}:${minutes}`;
  } 

  
  formatDateWithMonthName(date: Date, lang: string = 'en') {
    return moment(date).locale(lang).format('LL')
  }

  /**
   * return the string for a time string with the format: HH:MM:SS
   * and return the string with the format: HHh:MM PM/AM
   **/
  get12HourTime(time: string){
    return moment(time, "HH:mm:ss").format("hh:mm A");
  }

   /**
   * return the string for a time string with the format: HH:MM:SS
   * and return the string with the format: HHh:MM min
   **/

   extractDisplayStringFromDate(time: string){
  
    const stringBuilder = [];
    const [hour, minutes] = time.split(':');
    
    if(hour !== '00'){
      stringBuilder.push(`${Number(hour)}h `);
    }
    
    if(minutes !== '00'){
      stringBuilder.push(`${Number(minutes)} min`);
    }
    
    return stringBuilder.join("").trim();
  };

  dateToIsoString(date: Date) {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = (num: number) => {
        return (num < 10 ? '0' : '') + num;
      };

    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds())
    );
  }

}
