import { createReducer, on } from '@ngrx/store';
import { appInitialState, AppState } from './state';
import {
  bookOrder,
  bookOrderFail,
  bookOrderSuccess,
  bookToppingsSuccess,
  fetchAvailableVerticalProducts,
  fetchAvailableVerticalProductsSuccess,
  initializeAppB2BSuccess,
  setCurrentPage,
  setCurrentVertical,
  setFooterVisibility,
  setFormValidity,
  setNextPage,
  setCurrentZone,
  storeCatalog,
  storeProductData,
  storeProductType,
  purchaseCompleted,
  setCurrentLang,
  setCurrentCountry,
  setIsLoading,
  setCoupon,
  setDiscountPrice,
  updateOrderPrice,
  isCouponValid,
  removeCoupon,
  clearCoupon,
  refreshPaymentSession,
  setIsAppInitialized,
  setAvailableToppings,
  addSelectedTopping,
  setCurrentTopping,
  resetSelectedToppings,
  addSelectedToppingSuccess,
  setSelectedProduct,
  clearAvailableToppings,
  continueWithNoToppings,
  storeToppingData,
  getAvailableHoursReturnSucess,
  clearSelectedToppingsSuccess,
  bootstrappingApp,
  storeCoreData,
  setIsB2B,
  initAppSuccess,
  setCurrentVerticalProducts,
  storeProductTypeFromUrl,
  confirmedOrderSuccess,
  setServiceTimeOptions,
  getStationsSuccess,
  getMotStationsSuccess,
  setElementsAmount,
  setPageLocation,
  getServicesSuccess,
  setUserId,
  cancelService,
  setEnumMyServices,
  setToppingsPicked,
  setToppingsAdded,
  clearToppingsAdded,
  clearToppingsPicked,
  bookToppings,
  removeToppings,
  SetToppingToDelete,
  showService,
  showServiceSuccess,
  replaceProductId,
} from './actions';

export const kamikazeReducer = createReducer(
  appInitialState,

  on(setElementsAmount, (state: AppState, {elementsToShowInMyServices}) => {
    return{
      ...state,
      elementsToShowInMyServices,
    }
  }),
 
  on(setPageLocation, (state: AppState, {pageLocationInMyServices}) =>{
    return{
      ...state,
      pageLocationInMyServices
    }
  }),

  on(setEnumMyServices, (state: AppState, {enumMyServices}) =>{
    return{
      ...state,
      enumMyServices 
    }
  }), 

  on(setUserId, (state: AppState, {userId}) =>{
    return{
      ...state,
      userId 
    }
  }), 

  on(bootstrappingApp, (state: AppState) => {
    return {
      ...state,
      isLoading: true,
    };
  }),

  on(setIsB2B, (state: AppState, { isB2B, userData }) => {
    return {
      ...state,
      isB2B: isB2B,
      userData: userData,
    };
  }),

  on(storeCoreData, (state: AppState, { coreData }) => {
    return {
      ...state,
      coreData: coreData,
      isCoreDataLoaded: true,
    };
  }),

  on(initAppSuccess, (state: AppState) => {
    return {
      ...state,
      isLoading: false,
      isAppInitialized: true,
    };
  }),
  on(initializeAppB2BSuccess, (state: AppState, { serviceType }) => {
    return {
      ...state,
      // currentZoneId: zoneId,
      selectedVertical: serviceType,
      // isLoading: false,
      // isAppInitialized: true,
      isCoreDataLoaded: true,
    };
  }),

  on(fetchAvailableVerticalProducts, (state: AppState) => {
    return {
      ...state,
      isLoading: true,
      // isVerticalProductsLoaded: false,
    };
  }),

  on(fetchAvailableVerticalProductsSuccess, (state: AppState, { currentVerticalProducts }) => {
    return {
      ...state,
      currentVerticalProducts,
      isLoading: false,
      isVerticalProductsLoaded: true,
    };
  }),

  on(setCurrentVertical, (state: AppState, { selectedVertical }) => {
    return {
      ...state,
      selectedVertical,
    };
  }),

  on(setCurrentVerticalProducts, (state: AppState, { verticalProducts }) => {
    return {
      ...state,
      currentVerticalProducts: verticalProducts,
    };
  }),

  on(bookOrder, (state: AppState) => {
    return {
      ...state,
      isLoading: true,
    };
  }),

  on(bookToppings, (state: AppState) => {
    return {
      ...state,
      isLoading: true,
    };
  }),

  on(SetToppingToDelete, (state: AppState, { toppingToDelete }) => {
    return {
      ...state,
      toppingToDelete,
    };
  }),

  on(bookOrderSuccess, (state: AppState, { bookedOrder }) => {
    return {
      ...state,
      bookedOrder: bookedOrder,
      isLoading: false,
    };
  }),
  on(bookToppingsSuccess, (state: AppState, { bookedOrder }) => {
    return {
      ...state,
      bookedOrder: bookedOrder,
    };
  }),
  on(bookOrderFail, (state: AppState, { error }) => {
    return {
      ...state,
      isLoading: false,
    };
  }),

  on(setFormValidity, (state: AppState, { isFormValid }) => {
    return {
      ...state,
      isFormValid,
    };
  }),

  on(setFooterVisibility, (state: AppState, { isFooterVisible }) => {
    return {
      ...state,
      isFooterVisible,
    };
  }),

  on(setCurrentPage, (state: AppState, { currentPage }) => {
    return {
      ...state,
      currentPage,
    };
  }),

  on(setNextPage, (state: AppState, { nextPage }) => {
    return {
      ...state,
      nextPage,
    };
  }),

  on(storeCatalog, (state: AppState, { catalog, catalogName }) => {
    const catalogs = state.catalogs;
    return {
      ...state,
      catalogs: {
        ...catalogs,
        [catalogName]: catalog,
      },
    };
  }),

  on(storeProductType, (state: AppState, { productType }) => {
    return {
      ...state,
      productType,
    };
  }),
  on(storeProductTypeFromUrl, (state: AppState, { productType }) => {
    return {
      ...state,
      productType,
    };
  }),

  on(storeProductData, (state: AppState, { productData }) => {
    return {
      ...state,
      productData,
    };
  }),
  on(storeToppingData, (state: AppState, { toppingData }) => {
    return {
      ...state,
      toppingData,
    };
  }),
  on(setCurrentZone, (state: AppState, { currentZone }) => {
    return {
      ...state,
      currentZone,
    };
  }),
  on(setCurrentLang, (state: AppState, { currentLang }) => {
    return {
      ...state,
      currentLang,
    };
  }),
  on(setCurrentCountry, (state: AppState, { currentCountry }) => {
    return {
      ...state,
      currentCountry,
    };
  }),
  on(setIsLoading, (state: AppState, { isLoading }) => {
    return {
      ...state,
      isLoading,
    };
  }),
  on(purchaseCompleted, (state: AppState) => {
    return {
      ...state,
      isLoading: false,
    };
  }),
  on(setToppingsPicked, (state: AppState, { toppingsPicked }) => {
    return {
      ...state,
      toppingsPicked,
  
    };
  }),
  on(setToppingsAdded,(state: AppState, { toppingsAdded }) => {
    return {
      ...state,
      toppingsAdded,
    };
  }),
  on(clearToppingsAdded,(state: AppState) => {
    return {
      ...state,
      toppingsAdded: [],
    };
  }),

  on(clearToppingsPicked, (state: AppState) => {
      return {
    ...state,
    toppingsPicked: [],
  }; 
  }),

  on(setCoupon, (state: AppState, { couponCode }) => {
    return {
      ...state,
      bookedOrder: {
        ...state.bookedOrder,
        couponCode: couponCode,
      },
    };
  }),
  on(setIsAppInitialized, (state: AppState, { isAppInitialized }) => {
    return {
      ...state,
      isAppInitialized,
    };
  }),
  on(removeCoupon, (state: AppState) => {
    return {
      ...state,
      bookedOrder: {
        ...state.bookedOrder,
        couponCode: null,
        DiscountPrice: null,
      },
    };
  }),
  on(clearCoupon, (state: AppState) => {
    return {
      ...state,
      bookedOrder: {
        ...state.bookedOrder,
        couponCode: null,
        DiscountPrice: null,
      },
    };
  }),
  on(setDiscountPrice, (state: AppState, { DiscountPrice }) => {
    return {
      ...state,
      bookedOrder: {
        ...state.bookedOrder,
        DiscountPrice: {
          ...state.bookedOrder.DiscountPrice,
          TotalPrice : DiscountPrice
        },
      },
    };
  }),
  on(setServiceTimeOptions, (state: AppState, {serviceTimeOptions}) => {
    return {
      ...state,
      serviceTimeOptions,
    };
  }),
  on(setAvailableToppings, (state: AppState, { availableToppings }) => {
    return {
      ...state,
      availableToppings,
    };
  }),
  on(clearAvailableToppings, (state: AppState) => {
    return {
      ...state,
      availableToppings: [],
    };
  }),
  on(updateOrderPrice, (state: AppState, { OrderPrice }) => {
    return {
      ...state,
      bookedOrder: {
        ...state.bookedOrder,
        OrderPrice: {
          ...state.bookedOrder.OrderPrice,
          TotalPrice: OrderPrice
        },
      },
    };
  }),
  on(isCouponValid, (state: AppState, { isCouponValid }) => {
    return {
      ...state,
      isCouponValid,
    };
  }),

  on(refreshPaymentSession, (state: AppState, { refreshPaymentSessionData }) => {
    return {
      ...state,
      bookedOrder: {
        ...state.bookedOrder,
        PaymentData: {
          ...state.bookedOrder.PaymentData,
          PaymentId: refreshPaymentSessionData.PaymentId,
          PaymentHash: refreshPaymentSessionData.PaymentHash,
          PaymentData: refreshPaymentSessionData.PaymentData,
        },
      },
    };
  }),
  on(addSelectedTopping, (state: AppState) => {
    return {
      ...state,
      isLoading: true,
      selectedToppings: [
        ...state.selectedToppings,
        // state.currentTopping
      ],
    };
  }),
  on(addSelectedToppingSuccess, (state: AppState, { bookedOrder }) => {
    return {
      ...state,
      bookedOrder: bookedOrder,
      isLoading: false,
    };
  }),
  on(clearSelectedToppingsSuccess, (state: AppState, { bookedOrder }) => {
    return {
      ...state,
      bookedOrder: bookedOrder,
      isLoading: false,
    };
  }),
  on(continueWithNoToppings, (state: AppState) => {
    return {
      ...state,
      isLoading: false,
    };
  }),
  on(resetSelectedToppings, (state: AppState) => {
    return {
      ...state,
      selectedToppings: [],
    };
  }),
  on(setCurrentTopping, (state: AppState, { currentTopping }) => {
    return {
      ...state,
      currentTopping: currentTopping,
    };
  }),
  on(setSelectedProduct, (state: AppState, { selectedProduct }) => {
    return {
      ...state,
      selectedProduct: selectedProduct,
    };
  }),

  on(getStationsSuccess, (state: AppState, { stations }) => {
    return {
      ...state,
      stations: stations,
    };
  }),

  on(getMotStationsSuccess, (state: AppState, { stations }) => {
    return {
      ...state,
      motStations: stations,
    };
  }),

  on(getAvailableHoursReturnSucess, (state: AppState, { availableToppingHours }) => {
    return {
      ...state,
      availableToppingHours,
    };
  }),
  on(confirmedOrderSuccess, (state: AppState, { confirmedOrder }) => {
    return {
      ...state,
      bookedOrder: {
        ...state.bookedOrder,
        PaymentData: {
          OrderHash: confirmedOrder.FinancialData.OrderHash,
          PaymentData: confirmedOrder.FinancialData.PaymentSession.PaymentData,
          PaymentId: confirmedOrder.FinancialData.PaymentSession.PaymentId,
          ExpiresAt: confirmedOrder.FinancialData.PaymentSession.ExpiresAt,
        }
      }
    };
  }),
  on(getServicesSuccess, (state: AppState, { myServices }) =>{
    return{
      ...state,
      myServices
    }
  }),
  on(cancelService, (state: AppState, { selectedServiceToCancel }) =>{
    return{
      ...state,
      selectedServiceToCancel
    }
  }),
  on(showService, (state: AppState, { selectedServiceToShow }) =>{
    return{
      ...state,
      selectedServiceToShow
    }
  }),
  on(showServiceSuccess, (state: AppState, { selectedServiceToShowData }) =>{
    return{
      ...state,
      selectedServiceToShowData
    }
  }),
  on(replaceProductId, (state: AppState, { newProductId, productType }) => {
    return {
      ...state,
      productType: productType,
      selectedProduct: {
        id: newProductId,
        ordersManagerProductId: newProductId
      },
    };
  }),

);
