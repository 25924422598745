import { Inject, Injectable, Injector, TemplateRef, Type } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { ValetHowItWorksRef } from '../valet-how-it-works-popup-ref';
import { ValetHowItWorksPopupComponent } from '../valet-how-it-works-popup.component';

import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ValetHowItWorksPopupService {
  constructor(
    private overlay: Overlay, 
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document,
    ) {}

  open<R = any, T = any>(
    content: string | TemplateRef<any> | Type<any>,
    data: any,
  ): ValetHowItWorksRef<R> {
    const configs = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['modal', 'is-active'],
      backdropClass: 'popup-background',
    });
    content = content ? content : 'Default text.';
    const overlayRef = this.overlay.create(configs);

    const cafOverlayRef = new ValetHowItWorksRef<R, T>(overlayRef, content, data);

    const injector = this.createInjector(cafOverlayRef, this.injector);

    overlayRef.attach(new ComponentPortal(ValetHowItWorksPopupComponent, null, injector));

    this.document.body.classList.add('scroll-lock');

    return cafOverlayRef;
  }

  createInjector(ref: ValetHowItWorksRef, inj: Injector) {
    const injectorTokens = new WeakMap([[ValetHowItWorksRef, ref]]);
    return new PortalInjector(inj, injectorTokens); // TODO -> Fix deprecation
  }

  enableScroll() {
    this.document.body.classList.remove('scroll-lock');
  }
}
