<section class="section">
  <!-- TODO temporal empty state for tracking-->
  <img [src]="emptyStateImgUrl" alt="servicio en mantenimiento" width="180px" height="145px" />
  <div class="maintenance__text-container">
    <div class="text-container">
      <h4 class="title bold">
        {{ 'cafler.my-service-details.tracking.maintenance.title' | translate }}
      </h4>
      <p class="text">
        {{ 'cafler.my-service-details.tracking.maintenance.description' | translate }}
      </p>
    </div>
  </div>
  <!-- <section *ngIf="isInMaintenance; else trackingReadyToDisplay" class="maintenance">
      <img [src]="emptyStateImgUrl" alt="servicio en mantenimiento" width="180px" height="145px" />
      <div class="maintenance__text-container">
        <p class="maintenance__title">
          {{ 'cafler.my-service-details.tracking.maintenance.title' | translate }}
        </p>
        <p class="maintenance__description">
          {{ 'cafler.my-service-details.tracking.maintenance.description' | translate }}
        </p>
      </div>
    </section> -->
  <!-- <ng-template #trackingReadyToDisplay>
      <section *ngIf="serviceData.OrderStatus !== servicesStateEnum.FINISHED">
        <div class="gallery-empty-state__container">
          <div class="gallery-empty-state__header">
            <div class="gallery-empty-state__img-container">
              <caf-icon [settings]="trackingIcon"></caf-icon>
            </div>
            <p>{{ trackingMessage | translate }}</p>
          </div>
        </div>
      </section>
      <section *ngIf="showMap || (showProgressBar && !isCancel)" class="tracking-container"> -->
  <!-- mapa -->
  <!-- <div *ngIf="showMap">
          <section
            *ngIf="
              trackingUrl && serviceData.OrderStatus != servicesStateEnum.IN_PROGRESS;
              else emptyState
            "
          >
            <iframe
              width="354"
              height="505"
              frameborder="0"
              style="border: 0"
              [src]="trackingUrl | safe"
            ></iframe>
          </section>
          <ng-template #emptyState>
            <div
              *ngIf="serviceData.VerticalType === verticalsTypeEnum.VALET; else regularEmptyState"
              class="empty-state__container"
            >
              <div class="empty-state__img-container">
                <img [src]="emptyStateValetImgUrl" />
              </div>
              <div class="empty-state__description-container">
                <h4 class="empty-state__text">
                  {{ 'my-services.more-details.tracking.product-valet.title' | translate }}
                </h4>
                <p class="empty-state__text">
                  {{ 'my-services.more-details.tracking.product-valet.description' | translate }}
                </p>
              </div>
            </div>
            <ng-template #regularEmptyState>
              <div class="empty-state__container">
                <div class="empty-state__img-container">
                  <img [src]="emptyStateImgUrl" />
                </div>
                <div class="empty-state__description-container">
                  <h4 class="empty-state__text">
                    {{ 'my-services.more-details.tracking.title' | translate }}
                  </h4>
                  <p class="empty-state__text">
                    {{ 'my-services.more-details.tracking.description' | translate }}
                  </p>
                </div>
              </div>
            </ng-template>
          </ng-template>
        </div>
        <progress-vertical-bar
          *ngIf="showProgressBar"
          [settings]="progressVerticalBarSettings"
        ></progress-vertical-bar>
      </section>
    </ng-template> -->
</section>
