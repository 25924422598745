import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SingleDataHelperService } from 'src/app/helpers/single-data-helper.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { KamikazeActions } from 'src/app/store/actions';
import { selectIsVerticalProductsLoaded } from 'src/app/store/selectors';

@Component({
  selector: 'app-maintenance-bundle',
  templateUrl: './maintenance-bundle.component.html',
  styleUrls: ['./maintenance-bundle.component.scss']
})
export class MaintenanceBundleComponent implements OnInit {

  fg!: FormGroup;
  productsSettings: any = {
    id: 'maintenance-bundle-selector',
    formControlName: 'productSelector',
    isTopping: false,
    products: [],
  };
  categories: string[] = [];

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private singleDataHelperService: SingleDataHelperService,
  ) { 
    const formControlName = this.productsSettings.formControlName;
    this.fg = new FormGroup({});
    this.fg.addControl(formControlName, new FormControl(null, [Validators.required]));
 
  }

  ngOnInit(): void {
    
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'maintenance-bundle' });
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: false });
    this.navigationService.currentPageTitle = 'cafler.product.maintenance-bundle.title';

    this.store.select(selectIsVerticalProductsLoaded).subscribe((productsLoaded) => {
      if (productsLoaded)
        this.singleDataHelperService.getCurrentVerticalProducts().subscribe((products: any) => {
          this.productsSettings.products = products['bundledInspections'];
        });
    });
  }

  pickProduct(page: any, category: string) {

    const currentProduct = this.productsSettings.products[category].find((product: any) => {
      return product.id === page;
    });

    this.store.dispatch({
      type: KamikazeActions.SetSelectedProduct,
      selectedProduct: currentProduct,
    });

    this.router
      .navigate([`${currentProduct?.alias}`], { relativeTo: this.activatedRoute })
      .then((s) => {
        this.navigationService.currentPageTitle = 'cafler.product-type.maintenance-bundle.title';
      });
  }

}
