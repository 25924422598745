import { GoogleMapsInitializerModel } from '@cafler/common-ui/lib/models/module-initializer.model';

export const environment = {
  production: true,
  applicationType: 'b2c',
  ordersManagerApiURL: 'https://products-api.cafler.com',
  apiURL: 'https://kamikaze-api.cafler.com',
  api10URL: 'https://business-api.cafler.com',
  trackingURL: 'https://tracking-api.cafler.com',
  launchDarkly: {
    sdkKey:'sdk-a1fae07d-5d07-4ecc-a4e6-6677c0291f8c',
    clientSideID: '62d65e7fe17847112255a834'
  },
  sentryEnvironment:"production",
  sentryDSN: "https://c11b51e051f84a8f8e8f1cc8aa2c568b@o970615.ingest.sentry.io/4505431803297792",
  assetsURL: 'caflerweb.azureedge.net',
  ownerShipChangeURL: 'https://tramites.cafler.com/',
  oauthAppId: {
    azureBc2: {
      appId: '',
      tenantId: '',
      web: {
        redirectUrl: '',
      },
      android: {
        redirectUrl: null,
      },
    },
  },
  googleMaps: <GoogleMapsInitializerModel>{
    apiKey: 'AIzaSyArE5UCV4aTIxfG2EkPTUfjqwTj-7WRiOk',
  },
  adyen: {
    apiUrl: 'https://kamikaze-api.cafler.com',
    clientKey: 'live_FNKBA7XH4ZFAZIJJYMVRLQS66QDUUBUQ',
    apiKey:
      'AQEnhmfuXNWTK0Qc+iSRh3A3hOWdRI5fPr/AeDG4VH4vNNvE7sXpKJh1EMFdWw2+5HzctViMSCJMYAc=-Lbxyo0kcN/eV8HeLx6c5qp8twTNhhj6fC6aIyhiNNq4=-,PGI(dWq3vfmq%p6',
    environment: 'live',
    locale: 'es_ES',
    paymentMethodsConfiguration: {
      ideal: {
        showImage: true,
      },
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        name: 'Credit or debit card',
        amount: {
          value: 0,
          currency: 'EUR',
        },
      },
    },
  },
  translationSettings: {
    path: '/assets/translations/default/',
    defaultLanguage: 'es',
  },
  b2b10URL: 'https://business.cafler.com',
  appInsights: {
    instrumentationKey: 'e273f6f3-5cbc-4b35-ba37-a5148adb6e1c'
  },
};
