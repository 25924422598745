import { NgModule,ErrorHandler, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CaflerFECommonUiModule, CaflerPaymentsModule } from '@cafler/common-ui';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FooterComponent, WrapperComponent } from './components';
import {PopupToppingsComponent, CancelPopupComponent} from './components';
import { NavigationService } from './services/navigation.service';
import { ReactiveFormsModule } from '@angular/forms';
import {
  RevisionPage,
  RevisionFormPage,
  LavadoPage,
  LavadoFormPage,
  TransferFormPage,
  ProductSelectionPage,
  MediaLargaDistanciaFormPage,
  OverviewPage,
  CheckoutPage,
  ItvFormPage,
  ItvFormWithAppointmentPage,
  ReturnToppingFormPage,
  MyServicesPage,
  RefuelPage,
  PreItvFormPage,
  ItvSelectorPage,
  ServiceSelectorPage,
  ValetSelectorPage
} from './pages';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OverviewFacade } from './facades/overview.facade';
import { FormsHelperService } from './services/forms.service';
import { PhonePipe } from './pipes/phone.pipe';
import { CustomCurrencyPipe } from './pipes/currency.pipe';
import { LanguageSelectorComponent } from './components';
import { HelperService } from './services/helper.service';
import { PresentationFacade } from './facades/presentation.facade';
import { KamikazeFacade } from './facades/kamikaze.facade';
import { KamikazeResource } from './resources/kamikaze.resource';
import { B2b10HelperService } from './services/b2b-10-helper.service';
import { effects, metaReducers, reducers } from './store';
import { StepperComponent } from './components/stepper/stepper.component';
import { CheckCoordinatesWithinCaflerAreaValidator } from './validators/checkCoordinatesWithinCaflerArea.validator';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { HttpRequestInterceptor } from './interceptors/httpRequest.interceptor';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SuccessComponent } from './pages/success/success.component';
import { CouponBoxComponent } from './components/coupon-box/coupon-box.component';
import { ToppingSelectionPage } from './pages/topping-selection/topping-selection.page';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { LaunchDarklyService } from './services/LaunchDarkly.service';
import { MyMonitoringService } from './services/logging.service';
import { MenuCardComponent } from './components/menu-card/menu-card.component';
import { ToppingSelectorComponent } from './components/topping-selector/topping-selector.component';
import { WashToppingConfiguratorComponent } from './components/wash-topping-configurator/wash-topping-configurator.component';
import { NavigablePopupComponent } from './components/navigable-popup/navigable-popup.component';
import { RefuelToppingConfiguratorComponent } from './components/refuel-topping-configurator/refuel-topping-configurator.component';
import { PopupHtmlCustomComponent } from './components/popup-html-custom/popup-html-custom.component';
import { InsuranceToppingConfiguratorComponent } from './components/insurance-topping-configurator/insurance-topping-configurator.component';
import { WashDetailsPopupComponent } from './components/wash-details-topping/wash-details-popup.component';
import { MaintenanceBundleComponent } from './pages/maintenance-bundle/maintenance-bundle.component';
import { MaintenanceFormComponent } from './pages/maintenance-form/maintenance-form.component';
import { ValetFormComponent } from './pages/valet-form/valet-form.component';
import { ValetHowItWorksPopupComponent } from './components/valet-how-it-works-popup/valet-how-it-works-popup.component';
import { PickUpDropOffDateValidator } from './validators/pickUpDropOffDate.validator';
import { FormalitiesSelectorComponent } from './pages/formalities-selector/formalities-selector.component';
import { MyServiceDetailsPage } from './pages/my-service-details/my-service-details.page';
import { ProgressServiceBarComponent } from './components/progress-service-bar/progress-service-bar.component';
import { SafePipe } from './pipes/safe.pipe';
import { VehicleDataDisplayComponent } from './components/vehicle-data-display/vehicle-data-display.component';
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { ReplacementVehicleInfoPopUpComponent } from './components/replacement-vehicle-info-pop-up/replacement-vehicle-info-pop-up.component';
import { PopupHeaderComponent } from './components/popup-components/popup-header/popup-header.component';
import { PopupHowItWorksComponent } from './components/popup-components/popup-how-it-works/popup-how-it-works.component';
import { PopupBeforeServiceStartsComponent } from './components/popup-components/popup-before-service-starts/popup-before-service-starts.component';
import { DuplicationFormService } from './services/duplication-form.service';
import { PhotoGalleryComponent } from './components/photo-gallery/photo-gallery.component';
import { ProgressVerticalBarComponent } from './components/progress-vertical-bar/progress-vertical-bar.component';
import { HoistFormComponent } from './pages/hoist-form/hoist-form.component';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';
import { AllYouNeedProductComponent } from './pages/allYouNeed-product/allYouNeed-product.component';
import { PriceBreakdownComponent } from './components/price-breakdown/price-breakdown.component';
import { ItvFormWithHomologationComponent } from './pages/itv-form-with-homologation/itv-form-with-homologation.component';
import { TrackingDisplayComponent } from './components/tracking-display/tracking-display.component';
import { InfoContainerComponent } from './components/info-container/info-container.component';

registerLocaleData(localeEs, 'es-ES');
registerLocaleData(localeEn, 'en-EN');
registerLocaleData(localeFr, 'fr-FR');

@NgModule({
  declarations: [
    AppComponent,
    WrapperComponent,
    FooterComponent,
    PopupToppingsComponent,
    PopupHtmlCustomComponent,
    CancelPopupComponent,
    ProductSelectionPage,
    RevisionPage,
    RevisionFormPage,
    LavadoPage,
    LavadoFormPage,
    TransferFormPage,
    ToppingSelectionPage,
    ItvFormPage,
    ItvFormWithAppointmentPage,
    MediaLargaDistanciaFormPage,
    OverviewPage,
    CheckoutPage,
    ReturnToppingFormPage,
    LanguageSelectorComponent,
    PhonePipe,
    CustomCurrencyPipe,
    StepperComponent,
    NotFoundComponent,
    SuccessComponent,
    CouponBoxComponent,
    ItvSelectorPage,
    MyServicesPage,
    RefuelToppingConfiguratorComponent,
    MenuCardComponent,
    ToppingSelectorComponent,
    WashToppingConfiguratorComponent,
    NavigablePopupComponent,
    InsuranceToppingConfiguratorComponent,
    RefuelPage,
    InsuranceToppingConfiguratorComponent,
    PreItvFormPage,
    WashDetailsPopupComponent,
    MaintenanceBundleComponent,
    ServiceSelectorPage,
    MaintenanceFormComponent,
    ValetSelectorPage,
    ValetFormComponent,
    ValetHowItWorksPopupComponent,
    FormalitiesSelectorComponent,
    MyServiceDetailsPage,
    ProgressServiceBarComponent,
    SafePipe,
    VehicleDataDisplayComponent,
    PopUpComponent,
    ReplacementVehicleInfoPopUpComponent,
    PopupHeaderComponent,
    PopupHowItWorksComponent,
    PopupBeforeServiceStartsComponent,
    PhotoGalleryComponent,
    ProgressVerticalBarComponent,
    HoistFormComponent,
    AllYouNeedProductComponent,
    PriceBreakdownComponent,
    ItvFormWithHomologationComponent,
    TrackingDisplayComponent,
    InfoContainerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    PortalModule,
    OverlayModule,
    CommonModule,
    AppRoutingModule,
    CaflerFECommonUiModule.forRoot({
      env: environment,
      translationSettings: { path: environment.translationSettings.path },
    }),
    CaflerPaymentsModule.forRoot({
      paymentEnv: {
        apiURL: environment.adyen.apiUrl,
        adyen: environment.adyen,
      },
      translationSettings: { path: environment.translationSettings.path },
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    ReactiveFormsModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
  ],
  providers: [
    HelperService,
    PresentationFacade,
    NavigationService,
    OverviewFacade,
    KamikazeFacade,
    KamikazeResource,
    FormsHelperService,
    DatePipe,
    PhonePipe,
    CurrencyPipe,
    CustomCurrencyPipe,
    B2b10HelperService,
    DuplicationFormService,
    LaunchDarklyService,
    MyMonitoringService,
    CheckCoordinatesWithinCaflerAreaValidator,
    PickUpDropOffDateValidator,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    } 
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function appInitializerFactory(translate: TranslateService, helperService: HelperService) {
  return () => {
    translate.setDefaultLang('es');
    return translate.use('es');
  };
}
