import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KamikazeActions } from '../../store/actions';
import { Store } from '@ngrx/store';
import { first, pairwise, takeUntil } from 'rxjs/operators';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import {
  selectCurrentCountry,
  selectCurrentZone,
  selectCurrentLang,
  selectIsB2B,
  selectProductData,
  selectServiceTimeOptions,
} from '../../store/selectors';
import { NavigationService } from '../../services/navigation.service';
import { FormsHelperService } from '../../services/forms.service';
import { PresentationFacade } from '../../facades/presentation.facade';
import { datepickerMaxDate } from '../../services/utils';
import {
  CaflerErrorSettingsModel,
  CaflerIconSettingsModel,
  GoogleAddress,
  GoogleMapsService,
} from '@cafler/common-ui';
import { LaunchDarklyService } from 'src/app/services/LaunchDarkly.service';

import { checkDeliveryDateLowerThanPickupValidator } from '../../validators/checkDeliveryDateLowerThanPickup.validator';
import { SingleDataHelperService } from 'src/app/helpers/single-data-helper.service';
import { TimeFormatterService } from 'src/app/helpers/time-formatter.service';

@Component({
  selector: 'app-media-larga-distancia-form',
  templateUrl: './media-larga-distancia-form.page.html',
  styleUrls: ['./media-larga-distancia-form.page.scss'],
})
export class MediaLargaDistanciaFormPage implements OnInit, OnDestroy {
  isLoading: boolean = true;
  private readonly destroySubjects$ = new ReplaySubject<void>(1);
  fg!: FormGroup;
  isB2B: boolean = false;
  timesFailedPickupAddress = 0;
  timesFailedDeliveryAddress = 0;
  maxErrorsOnAddress = 0;
  readyToDisplayHours = false;
  currentCountry!: any;
  serviceTimeOptions: any[] = [];
  combinationNotValid: boolean = false;
  dayChangedFlag = false;
  isSpecificHour: boolean = false;
  timeRangeOption: string = ""; 
  firstNewFeatureTry: boolean = true;
  noSpecificHourAvailable: boolean = false;
  noRangeHourAvailable: boolean = false;
  pastDateWhenDuplicatingError: boolean = false
  flagSubscription: any;

  initialForm: any;
  isDuplicated: boolean = false;
  duplicateSpecificHourError: boolean = false;

  @ViewChild('startServiceTimeComponent') startServiceTimeComponent: any;
  @ViewChild('endServiceTimeComponent') endServiceTimeComponent: any;
  

  truckDeliverySettings: string[] = ['pickupTime', 'dropoffDate', 'dropoffTime' ]
  rolledDeliverySettings: string[] = ['startPickUpTime' ]
 
  settings: any = {
    serviceType: {
      id: 'serviceType',
      formControlName: 'serviceType',
      placeholder: '',
      disabled: false,
    },
    deliveryType: {
      id: 'deliveryType',
      formControlName: 'deliveryType',
      placeholder: 'cafler.page.media-larga-distancia.delivery-type.placeholder',
      disabled: false,
      options: [],
    },
    vehicleType: {
      id: 'vehicleType',
      formControlName: 'vehicleType',
      placeholder: 'cafler.page.fuel.body-type.placeholder',
      disabled: false,
      options: [],
    },
    customDelivery: {
      id: 'customDelivery',
      name: 'customDelivery',
      formControlName: 'customDelivery',
      label: 'cafler.page.media-larga-distancia.custom-delivery',
      labelAlign:'left',
      checked: false,
      //disabled: false,
    },
    carPlaque: {
      id: 'carPlaque',
      formControlName: 'carPlaque',
      placeholder: 'cafler.vehicle-info.plate.placeholder',
      disabled: false,
      type: 'text',
    },
    brand: {
      id: 'brand',
      formControlName: 'brand',
      placeholder: 'cafler.vehicle-info.brand.placeholder',
      disabled: false,
      required: true,
      type: 'text',
    },
    model: {
      id: 'model',
      formControlName: 'model',
      placeholder: 'cafler.vehicle-info.model.placeholder',
      disabled: false,
      required: true,
      type: 'text',
    },
    pickupAddress: {
      id: 'pickupAddress',
      formControlName: 'pickupAddress',
      placeholder: 'cafler.page.media-larga-distancia.directions.origin.placeholder',
      options: { businessSuggestions: true, placeSuggestions: true },
    },
    pickupAddressDetails: {
      id: 'pickupAddressDetails',
      formControlName: 'pickupAddressDetails',
      placeholder: 'cafler.pickup-info.pickup-details.placeholder',
      required: false,
    },
    deliveryAddress: {
      id: 'deliveryAddress',
      formControlName: 'deliveryAddress',
      placeholder: 'cafler.page.media-larga-distancia.directions.destination.placeholder',
      options: { businessSuggestions: true, placeSuggestions: true },
    },
    deliveryAddressDetails: {
      id: 'deliveryAddressDetails',
      formControlName: 'deliveryAddressDetails',
      placeholder: 'cafler.pickup-info.pickup-details.placeholder',
      required: false,
    },
    fullName: {
      id: 'fullName',
      formControlName: 'fullName',
      placeholder: 'cafler.contact.name.placeholder',
      disabled: false,
    },
    countryPhoneCode: {
      id: 'countryPhoneCode',
      formControlName: 'countryPhoneCode',
      placeholder: 'cafler.contact.country-phone-code.placeholder',
      disabled: false,
      options: [],
    },
    phone: {
      id: 'phone',
      formControlName: 'phone',
      placeholder: 'cafler.contact.phone.placeholder',
      disabled: false,
      type: 'phone',
    },
    email: {
      id: 'email',
      formControlName: 'email',
      placeholder: 'cafler.contact.email.placeholder',
      disabled: false,
      type: 'email',
    },
    pickupDate: {
      id: 'pickupDate',
      formControlName: 'pickupDate',
      placeholder: 'cafler.pickup-info.pickup-day.placeholder',
      minDate: new Date(),
      maxDate: datepickerMaxDate(),
      daysDisabled: [6,0],
      icon: <CaflerIconSettingsModel>{
        alt: 'calendar',
        name: 'caf-notes-book-dark',
      },
      lang: 'currentLang'
    },
    dropoffFullName: {
      id: 'dropoffFullName',
      formControlName: 'dropoffFullName',
      placeholder: 'cafler.contact.name.placeholder',
      disabled: false,
    },
    dropoffCountryPhoneCode: {
      id: 'dropoffCountryPhoneCode',
      formControlName: 'dropoffCountryPhoneCode',
      placeholder: 'cafler.contact.country-phone-code.placeholder',
      disabled: false,
      options: [],
    },
    dropoffPhone: {
      id: 'dropoffPhone',
      formControlName: 'dropoffPhone',
      placeholder: 'cafler.contact.phone.placeholder',
      disabled: false,
      type: 'phone',
    },
    dropoffEmail: {
      id: 'dropoffEmail',
      formControlName: 'dropoffEmail',
      placeholder: 'cafler.contact.email.placeholder',
      disabled: false,
      type: 'email',
    },
    additionalComments: {
      id: 'additionalComments',
      formControlName: 'additionalComments',
      placeholder: 'cafler.pickup-info.textarea.comments',
      minlength: 0,
      disabled: false,
      required: false,
    },

    startPickUpTime: {
      id: 'startPickUpTime',
      formControlName: 'startPickUpTime',
      placeholder: 'cafler.page.service-time.from-placeholder',
      disabled: false,
      required: false,
      options: [],
      leftIconSettings: {
        name: 'caf-clock-cafler',
        alt: 'Clock icon',
        height: '25px',
        width: '25px',
      },
    },

    endPickUpTime: {
      id: 'endPickUpTime',
      formControlName: 'endPickUpTime',
      placeholder: 'cafler.page.service-time.until-placeholder',
      disabled: true,
      required: false,
      options: [],
      leftIconSettings: {
        name: 'caf-clock-cafler',
        alt: 'Clock icon',
        height: '25px',
        width: '25px',
      },
    },

    selectTimeOption: {
      id: 'selectTimeOption',
      placeholder: 'cafler.page.service-time.select-time-option.placeholder',
      formControlName: 'selectTimeOption',
      disabled: false,
      required: false,
      options: [
        {
          key: 'range',
          text: 'cafler.page.service-time.select-time-option.range.text',
          subtext: 'cafler.page.service-time.select-time-option.range.subtext'
        },
        { 
         key: 'specific',
         text: 'cafler.page.service-time.select-time-option.specific.text',
         subtext: 'cafler.page.service-time.select-time-option.specific.subtext' 
        },
      ],
    },

    specificTimeRange: {
      id: 'specificTimeRange',
      placeholder: 'cafler.page.service-time.specific-time.selector.placeholder',
      formControlName: 'specificTimeRange',
      disabled: false,
      options: [],
      required: false,
      rightIconSettings: {
        name: 'caf-clock-cafler',
        alt: 'Clock icon',
        height: '25px',
        width: '25px',
      },
    },

    pickupTime: {
      id: 'pickupTime',
      formControlName: 'pickupTime',
      placeholder: 'cafler.page.media-larga-distancia.origin.pickup-time.placeholder',
      disabled: false,
      required: false,
      options: [],
    },
    dropoffDate: {
      id: 'dropoffDate',
      formControlName: 'dropoffDate',
      placeholder: 'cafler.page.media-larga-distancia.destination.pickup-date.placeholder',
      minDate: new Date(),
      required: false,
      daysDisabled: [6,0],
      icon: <CaflerIconSettingsModel>{
        alt: 'calendar',
        name: 'caf-notes-book-dark',
      },
      lang: 'currentLang'
    },
    dropoffTime: {
      id: 'dropoffTime',
      formControlName: 'dropoffTime',
      placeholder: 'cafler.page.media-larga-distancia.origin.pickup-time.placeholder',
      disabled: false,
      required: false,
      options: [],
    },
  };
  settingsExtra: any = {
    locationIcon: <CaflerIconSettingsModel>{
      alt: 'location',
      name: 'caf-pin-location',
    },
  };
  settingsErrors: any = {
    errorsFullName: <CaflerErrorSettingsModel>{
      formControlName: 'fullName',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsDropOffFullName: <CaflerErrorSettingsModel>{
      formControlName: 'dropoffFullName',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsCountryPhoneCode: <CaflerErrorSettingsModel>{
      formControlName: 'countryPhoneCode',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsPhone: <CaflerErrorSettingsModel>{
      formControlName: 'phone',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'pattern', text: 'cafler.forms.error.phone-pattern' },
        { key: 'minlength', text: 'cafler.forms.error.min-length' },
      ],
    },
    errorsDropoffCountryPhoneCode: <CaflerErrorSettingsModel>{
      formControlName: 'dropoffCountryPhoneCode',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsDropOffPhone: <CaflerErrorSettingsModel>{
      formControlName: 'dropoffPhone',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'pattern', text: 'cafler.forms.error.phone-pattern' },
        { key: 'minlength', text: 'cafler.forms.error.min-length' },
      ],
    },
    errorsDropOffEmail: <CaflerErrorSettingsModel>{
      formControlName: 'dropoffEmail',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'pattern', text: 'cafler.forms.error.phone-pattern' },
        { key: 'minlength', text: 'cafler.forms.error.min-length' },
      ],
    },
    errorsEmail: <CaflerErrorSettingsModel>{
      formControlName: 'email',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'email', text: 'cafler.forms.error.email' },
      ],
    },
    errorsCarPlaque: <CaflerErrorSettingsModel>{
      formControlName: 'carPlaque',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' },{key:'invalidLicensePlate', text: 'cafler.forms.error.invalid-license-plate'}],
    },
    errorsModel: <CaflerErrorSettingsModel>{
      formControlName: 'model',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsBrand: <CaflerErrorSettingsModel>{
      formControlName: 'brand',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsPickupDate: <CaflerErrorSettingsModel>{
      formControlName: 'pickupDate',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'notPresent', text: 'cafler.forms.error.date-not-in-past' },
      ],
    },
    errorsStartPickUpTime: <CaflerErrorSettingsModel>{
      formControlName: 'startPickUpTime',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsEndPickUpTime: <CaflerErrorSettingsModel>{
      formControlName: 'endPickUpTime',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsDropOffDate: <CaflerErrorSettingsModel>{
      formControlName: 'dropoffDate',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'notPresent', text: 'cafler.forms.error.date-not-in-past' },
      ],
    },
    errorsPickupAddress: <CaflerErrorSettingsModel>{
      formControlName: 'pickupAddress',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'selectedAddress', text: 'cafler.forms.error.selected-address' },
        {
          key: 'streetNumberNeeded',
          text: 'cafler.address-message-error-missing-street-number.title',
        },

        { key: 'addressNumber', text: 'cafler.forms.error.address-number' },
        {
          key: 'checkCoordinatesWithinCaflerArea',
          text: 'cafler.forms.error.cafler-country-coverage',
        },
      ],
    },
    errorsdeliveryAddress: <CaflerErrorSettingsModel>{
      formControlName: 'deliveryAddress',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'selectedAddress', text: 'cafler.forms.error.selected-address' },
        {
          key: 'streetNumberNeeded',
          text: 'cafler.address-message-error-missing-street-number.title',
        },

        { key: 'addressNumber', text: 'cafler.forms.error.address-number' },
        {
          key: 'checkCoordinatesWithinCaflerArea',
          text: 'cafler.forms.error.cafler-country-coverage',
        },
      ],
    },
    errorsDeliveryType: <CaflerErrorSettingsModel>{
      formControlName: 'deliveryType',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsVehicleType: <CaflerErrorSettingsModel>{
      formControlName: 'vehicleType',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsDropoffTime: <CaflerErrorSettingsModel>{
      formControlName: 'dropoffTime',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsPickupTime: <CaflerErrorSettingsModel>{
      formControlName: 'pickupTime',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
  };

  constructor(
    private store: Store,
    private navigationService: NavigationService,
    private presentationFacade: PresentationFacade,
    private formsService: FormsHelperService,
    private timeFormatterService: TimeFormatterService,
    private googleMapsService: GoogleMapsService,
    private launchDarklyService: LaunchDarklyService,
    private singleDataHelperService: SingleDataHelperService,

  ) {

    this.initialForm = history.state.initialForm ? history.state.initialForm : null;
    this.isDuplicated = history.state.isDuplicated ? history.state.isDuplicated : false;

    this.fg = this.formsService.initializeForm(this.settings, this.initialForm);
    this.store.dispatch({ type: KamikazeActions.FetchAvailableVerticalProducts });

    this.initDefaultDeliveryType()

    this.store.select(selectCurrentCountry).subscribe((country: any) => {
      if (country) {
        this.currentCountry = country;
      }
    });

    this.store.select(selectIsB2B).subscribe((isB2B) => {
      this.isB2B = isB2B;
    });

    this.store.select(selectCurrentCountry).subscribe((country: any) => {
      if (country) {
        // setting default prefix code number
        this.fg
          .get('countryPhoneCode')
          ?.setValue(country?.DefaultInternationalPhonePrefixPhonePrefix);
        this.fg.get('dropoffCountryPhoneCode')?.setValue(country?.InternationalPhonePrefix);
      }
    });

    this.store.select(selectCurrentLang).subscribe((lang) => {
      if(lang){
        this.settings.pickupDate = {
          ...this.settings.pickupDate,
          lang: lang.split('-')[0]
        }

        this.settings.dropoffDate = {
          ...this.settings.dropoffDate,
          lang: lang.split('-')[0]
        }
      }
    });

    if (this.isDuplicated) {
      this.settings.deliveryType.disabled = true;

      this.validateFormAddress(this.settings.pickupAddress.formControlName)
      this.validateFormAddress(this.settings.deliveryAddress.formControlName)
    } 
  }

  setMinimumPickUpDateOnTomorrow(){
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.settings.pickupDate.minDate = tomorrow;
  }

  ngOnInit() {
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: true });
    this.store.dispatch({ type: KamikazeActions.SetNextPage, nextPage: 'overview' });
    
    this.setMinimumPickUpDateOnTomorrow()

    this.store.dispatch({
      type: KamikazeActions.SetCurrentPage,
      currentPage: 'medium-long-distance',
    });
    this.store.dispatch({ type: KamikazeActions.ClearAvailableToppings });

    this.store.dispatch({
      type: KamikazeActions.SetFormValidity,
      isFormValid: this.formsService.checkCurrentFormValidity(),
    });

    this.store.select(selectServiceTimeOptions).subscribe((serviceTimeOptions) => {
      if(serviceTimeOptions !== undefined){
        this.serviceTimeOptions = serviceTimeOptions;
      }
    })

    this.fg
    .get(this.settings.deliveryType.formControlName)
    ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
    .subscribe((deliveryType: number) => {
      this.initDeliveryTypeValidators(deliveryType)
    });

    this.fg
      .get(this.settings.pickupDate.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((date) => {
        const deliveryType = this.fg.get(this.settings.deliveryType.formControlName)?.value;

        if(!date || !deliveryType) return;

        if(deliveryType === 1 ){

          // this.fg
          // .get(this.settings.selectTimeOption.formControlName)?.setValue('range')

          if (this.readyToDisplayHours) {
            this.resetServiceTimeStyles(true);
            this.getSlotHours(this.isSpecificHour);
          }
          this.readyToDisplayHours = true;
          return
        }

        // this.fg
        // .get(this.settings.pickupTime.formControlName)?.setValue(null)

        this.getSlotHoursForTruckDelivery(date)
          .pipe(takeUntil(this.destroySubjects$))
          .subscribe((hours: any) => {
            this.settings.pickupTime.options = hours;
          });

        this.readyToDisplayHours = true;
      });

    this.fg
      .get(this.settings.dropoffDate.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((date) => {
        if(!date) return;

        this.fg
        .get(this.settings.dropoffTime.formControlName)?.setValue(null)

        this.getSlotHoursForTruckDelivery(date)
          .pipe(takeUntil(this.destroySubjects$))
          .subscribe((hours: any) => {
            this.settings.dropoffTime.options = hours;
          });

        this.readyToDisplayHours = true;

    });

    this.fg
      .get(this.settings.selectTimeOption.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .pipe(first())
      .subscribe((timeOption) => {

        this.isSpecificHour = timeOption === 'specific';
        this.getSlotHours(this.isSpecificHour);
      });

    this.fg
      .get(this.settings.selectTimeOption.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .pipe(pairwise())
      .subscribe(([prev, next]) => {
        if(prev === next){
          return;
        }
        this.firstNewFeatureTry = false;
        this.isSpecificHour = next === 'specific';

        this.getSlotHours(this.isSpecificHour);
        this.resetTimeAndErrors();
      });

    this.fg
      .get(this.settings.specificTimeRange.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((newValue) => {
        this.updateSpecificHours(newValue);
      });

    this.fg
      .get(this.settings.startPickUpTime.formControlName)
      ?.statusChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((status) => {
        if(this.timeRangeOption === 'range'){
          this.changeEndPickupTimeDisability(status)
        }else{
          this.dayChangedFlag = false;
        }
      });

    this.fg
      .get(this.settings.endPickUpTime.formControlName)
      ?.statusChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((status) => {
        if (status === 'VALID') {
          this.combinationNotValid = false;
        }
      });
 
    this.navigationService.scrollToTop();

    this.presentationFacade
      .getCountryCodes()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((countryCodes) => {
        this.settings.countryPhoneCode.options = countryCodes;
        this.settings.dropoffCountryPhoneCode.options = countryCodes;
      });

    this.singleDataHelperService
      .getCurrentVerticalProducts()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((products:any) => {
        if (products) {
          this.fg.controls.serviceType.setValue(products.longDistanceTransfer[0].ordersManagerProductId);
          this.store.dispatch({
            type: KamikazeActions.SetSelectedProduct,
            selectedProduct: products.longDistanceTransfer[0],
          });
        }
        if(this.isDuplicated && this.initialForm){
          this.timeRangeOption = this.fg.get(this.settings.selectTimeOption.formControlName)?.value;
          
          if(this.fg.get(this.settings.pickupDate.formControlName)?.value){
            this.readyToDisplayHours = true;
            
            const isSpecificHour = this.timeRangeOption === 'specific'
            this.getSlotHours(isSpecificHour);
      
            if(isSpecificHour){
              this.updateSpecificHours(this.initialForm.specificTimeRange);
            } 
          }else{
            this.pastDateWhenDuplicatingError= true
          }
        }
      });

    this.presentationFacade
      .getCoreDataCatalog('AvailableTransferType')
      .subscribe((transferTypes) => {
        if (transferTypes) {
          // Ocultar los transfer de tipo grúa
          transferTypes = transferTypes.filter((t: any) => t.text === 'OnRoad');

          this.settings.deliveryType.options = transferTypes
          .filter((t: any) => this.currentCountry.RegionIsoCode  === 'esp' || t.text === 'OnRoad')
          .map((t: any) => ({
            key: t.key,
            text: `cafler.product-type.media-larga-distancia.delivery-type.${t.text}`,
          }));
        }
      });

    this.fg.controls.pickupDate.valueChanges
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((value) => {
        this.settings.dropoffDate.minDate = value;
      });

    this.presentationFacade.getCoreDataCatalog('TransferTypeEnum').subscribe((vehicleTypes) => {
      if (vehicleTypes) {
        let temp = vehicleTypes.filter((elem:any )=> {
          return (elem.key !== 20 && elem.key !== 21)
        });
        this.settings.vehicleType.options = temp.map((t: any) => {
          return ({
          key: t.key,
          text: `cafler.product-type.media-larga-distancia.vehicle-type.${t.text}`,
        })
      });

      }
    });


    this.store.select(selectCurrentZone).subscribe((zone) => {
      this.settings.pickupAddress.options.countryCode = zone?.RegionCode;
      this.settings.deliveryAddress.options.countryCode = zone?.RegionCode;
    });

    this.store
      .select(selectProductData)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((productData) => {
        if (this.isLoading) {
          if (!!productData) {
            this.formsService.loadFormGroupData(this.fg, productData, this.isLoading);
            this.isLoading = false;
          } else {
            if (this.isB2B) {
              this.formsService.preloadB2BData(this.fg);
            }
            this.fg.controls.serviceType.setValue(this.navigationService.getSelectedProduct());
          }
        }
      });

    this.presentationFacade.getHolidays().subscribe((holidays) => {
      let holidaysDates = holidays.map((day: any) => {
        return new Date(day);
      });
      // setting min date to tomorrow because there is not validation about the current hours and you cant select a morning hour during the afternoon
      // and noone should order medium-long distance for the same day
      holidaysDates.unshift(new Date());

      if (holidaysDates.length > 0) {
        this.settings.pickupDate = {
          ...this.settings.pickupDate,
          datesDisabled: holidaysDates,
        };
        this.settings.dropoffDate = {
          ...this.settings.dropoffDate,
          datesDisabled: holidaysDates,
        };
      }
    });

    this.fg
      .get(this.settings.startPickUpTime.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((newValue) => {
        
        if(this.timeRangeOption === 'range'){
          this.updateEndRangeHours(newValue);
          return;
        }
      });

    this.navigationService.currentPageTitle = 'cafler.product-type.medium-long-distance.title';
    this.fg.get('customDelivery')?.setValue(false);
    this.fg.statusChanges.pipe(takeUntil(this.destroySubjects$)).subscribe((status) => {
      this.formsService.handleFormValidity(status, this.fg.value);
    });
  }

  initDefaultDeliveryType(){
    const ROLLED_DELIVERY = 1
    this.fg
    .get(this.settings.deliveryType.formControlName)?.setValue(ROLLED_DELIVERY);

    this.initDeliveryTypeValidators(ROLLED_DELIVERY);
  }
  removeValidators(settings: string[]){
    settings.forEach((setting: any) => {
      this.fg.controls[setting].clearValidators();
      this.fg.get(setting)?.patchValue(null);

    });
  }

  addRequiredValidators(settings: string[]){
    settings.forEach((setting: any) => {
        this.fg.controls[setting].addValidators(Validators.required);
    });
  }

  initDeliveryTypeValidators(deliveryType: number){

    // this.fg.get('pickupDate')?.patchValue(null);
    // this.fg.get('dropoffDate')?.patchValue(null);

    // this.resetTimeAndErrors() 
    
    if(deliveryType === 1){
      this.removeValidators(this.truckDeliverySettings)
      this.addRequiredValidators(this.rolledDeliverySettings)
      return;
    }

    this.removeValidators(this.rolledDeliverySettings)
    this.addRequiredValidators(this.truckDeliverySettings)
  } 

  changeEndPickupTimeDisability(status: string){
    if (status === 'VALID') {
      this.settings.endPickUpTime.disabled = false;
      this.dayChangedFlag = false;
    } else {
      this.settings.endPickUpTime.disabled = true;
    }
  }
  getB2BAddress(formControlName: string) {
    this.presentationFacade
      .getAddressB2BToAutosuggest()
      .pipe(first())
      .subscribe((addressObj) => {
        this.fg.get(formControlName)?.setValue(addressObj);
      });
  }
  countPickupErrors() {
    this.timesFailedPickupAddress = this.timesFailedPickupAddress + 1;
  }

  countDropoffErrors() {
    this.timesFailedDeliveryAddress = this.timesFailedDeliveryAddress + 1;
  }

  validateFormAddress(formControlName: string){
    const addressFormRef = this.fg.get(formControlName)?.value as GoogleAddress
    addressFormRef.googleObject.isValidAddress = true 
  }

  getSlotHoursForTruckDelivery(date: Date): Observable<any> {
    let subject = new Subject<any>();
    //let hours: string[] = [];

    this.presentationFacade
    .getMediumLongDistanceTimeSpans(
      date,
      this.fg.value.serviceType,
      this.fg.get(this.settings.deliveryType.formControlName)?.value,
      )
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((res:any) => {
        //hours = res;
        subject.next(res);
    });

    return subject.asObservable();
  }

  getSlotHours(isSpecificHour: boolean) {
    this.presentationFacade
      .getPickupTimeOptions(
        this.fg.get(this.settings.pickupDate.formControlName)?.value,
        isSpecificHour,
        this.fg.get(this.settings.deliveryType.formControlName)?.value,
        
      )
      .pipe(first())
      .subscribe((pickupTimeOptions: any) => {

        let timeOptions = JSON.parse(JSON.stringify(pickupTimeOptions));
        this.timeRangeOption = this.fg.get(this.settings.selectTimeOption.formControlName)?.value;
        
        if(isSpecificHour){
          let disabledHours = 0;
          timeOptions.map((entry: any) => {

              let date = new Date(entry.key.substring(0,19));
              let newDate = this.timeFormatterService.increaseTime(date, 15);
    
              entry.text = entry.text + ' - ' + this.timeFormatterService.getTimeFromDate(newDate);
              if(entry.disabled){
                disabledHours++;
              }
            })
            this.noSpecificHourAvailable = disabledHours === timeOptions.length;
            this.settings.specificTimeRange.disabled = this.noSpecificHourAvailable;
           
            this.settings.specificTimeRange.options = timeOptions;
            this.fg.controls[this.settings.endPickUpTime.formControlName].clearValidators();
            if(this.isDuplicated){
              const time = this.fg.get(this.settings.specificTimeRange.formControlName)?.value
  
              let elementSelected = timeOptions.find((el: any) => el.key === time);
  
              if(!elementSelected.disabled){
                this.fg.get(this.settings.specificTimeRange.formControlName)?.setValue(time)
              }else{
                this.duplicateSpecificHourError = true 
              }
            } 
        }else{
          this.settings.startPickUpTime.options = timeOptions.slice(0, -3);
          this.settings.endPickUpTime.options = timeOptions.slice(3);
  
          this.settings.specificTimeRange.options = timeOptions;
          this.fg.controls[this.settings.endPickUpTime.formControlName].addValidators(Validators.required);

          this.noRangeHourAvailable = timeOptions.length < 3;
          this.settings.startPickUpTime.disabled = this.noRangeHourAvailable;
          if(this.isDuplicated){
            const pickUpTime = this.fg.get(this.settings.startPickUpTime.formControlName)?.value
            const dropoffTime = this.fg.get(this.settings.endPickUpTime.formControlName)?.value
  
            let elementSelectedA = timeOptions.find((el: any) => el.key === pickUpTime);
            let elementSelectedB = timeOptions.find((el: any) => el.key === dropoffTime);
   
            if(!elementSelectedA?.disabled){
              this.fg.get(this.settings.startPickUpTime.formControlName)?.setValue(pickUpTime)
            }else{
              this.duplicateSpecificHourError = true 
            }
            if(!elementSelectedB?.disabled){
              this.fg.get(this.settings.endPickUpTime.formControlName)?.setValue(dropoffTime)
            }else{
              this.duplicateSpecificHourError = true  
            }
          }
        
        }

        this.store.dispatch({
          type: KamikazeActions.SetServiceTimeOptions,
          serviceTimeOptions: timeOptions,
        });

      });
  }

  updateSpecificHours(valueSelected: any){
    this.store.select(selectServiceTimeOptions).subscribe((serviceTimeOptions) => {
      this.serviceTimeOptions = serviceTimeOptions;
      
      if(!serviceTimeOptions || serviceTimeOptions && serviceTimeOptions.length === 0) return 
  
  
      let elementSelected: any = this.serviceTimeOptions.find((el: any) => el.key === valueSelected);
  
      if(elementSelected !== undefined && !elementSelected?.disabled){
        this.settings.startPickUpTime.options = elementSelected.key;
        this.settings.endPickUpTime.options = null;
  
        this.fg.controls.startPickUpTime.setValue(elementSelected.key);
        this.fg.controls.endPickUpTime.setValue(null);
      }    
    })

  }

  updateEndRangeHours(valueSelected: any) {
    this.store.select(selectServiceTimeOptions).subscribe((serviceTimeOptions) => {
      this.serviceTimeOptions = serviceTimeOptions;

      if(!serviceTimeOptions || serviceTimeOptions && serviceTimeOptions.length === 0) return 

      // Convert the value in a selectable element
      let elementSelected = this.serviceTimeOptions.find((el: any) => el.key === valueSelected);
  
      // Update the end time select with new values
      this.settings.endPickUpTime.options = this.serviceTimeOptions.slice(
        this.settings.startPickUpTime.options.indexOf(elementSelected) + 3,
      );
  
      // Check if the end value is still valid
      let startPickUpTimeControl = this.fg.get('startPickUpTime');
      let endPickUpTimeControl = this.fg.get('endPickUpTime');
  
      let isEndHourInValidRange = this.settings.endPickUpTime.options.find(
        (el: any) => el.key === endPickUpTimeControl?.value,
      );
  
      // Update end value
      if (startPickUpTimeControl?.valid && endPickUpTimeControl?.value && !isEndHourInValidRange) {
        this.fg.get('endPickUpTime')?.patchValue(null);
        this.combinationNotValid = true;
      }

    })
  }

  resetServiceTimeStyles(dayChangedMode?: boolean) {
    if(this.startServiceTimeComponent !== undefined){
      this.startServiceTimeComponent.resetValue();
    }
    if(this.endServiceTimeComponent !== undefined){
      this.endServiceTimeComponent.resetValue();
    }
    
    this.combinationNotValid = false;
    if (dayChangedMode && this.serviceTimeOptions.length > 0) {
      this.dayChangedFlag = true;
    } else {
      this.dayChangedFlag = false;
    }
  }

  resetTimeAndErrors(){
    this.fg.get('startPickUpTime')?.patchValue(null);
    this.fg.get('endPickUpTime')?.patchValue(null);
    this.fg.get('specificTimeRange')?.patchValue(null);
    this.resetServiceTimeStyles();
    this.combinationNotValid = false;
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }
}
