import { CountryIsoCode } from "../Enums/countryIsoCode.enum";
import { DistanceUnit } from "../models/distanceUnit";

export function getDistanceUnitPerCountryRegionIsoCode(countryIsoCode: CountryIsoCode): DistanceUnit{
    switch(countryIsoCode){
        case CountryIsoCode.GBR:
            return 'miles';
        default:
            return 'kilometers';
    }
}