import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectAvailableToppings,
  selectBookedOrder,
  selectCoreData,
  selectCoreDataEnum,
  selectCoreDataRegions,
  selectCurrentCountry,
  selectCurrentLang,
  selectCurrentVertical,
  selectCurrentZone,
  selectCurrentZoneId,
  selectFooterVisibility,
  selectIsB2B,
  selectIsLoading,
  selectSelectedProduct,
  selectUserData,
} from '../store/selectors';
import { FEEnumItemModel, ZoneModel, VerticalModel, BEVerticalModel, BEEnumModel } from '../models';
import { map, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {
  CaflerBadgeSettingsModel,
  CaflerIconSettingsModel,
  CaflerPopupSettingsModel,
  CaflerProductCardSettingsModel,
  GoogleAddress,
} from '@cafler/common-ui';
import { KamikazeFacade } from './kamikaze.facade';
import { isNgTemplate } from '@angular/compiler';
import { TextFormatterService } from '../helpers/text-formatter.service';
import { TimeFormatterService } from '../helpers/time-formatter.service';
import { VehicleFuelTypesEnum } from '../Enums/vehicle-fuel-types.enum';
import { VehiclesTypesEnum } from '../Enums/vehiclesTypes.enum';
import { FUEL_TYPE_FOR_CAR_ES, FUEL_TYPE_FOR_CAR_FR, FUEL_TYPE_FOR_CAR_UK, FUEL_TYPE_FOR_MOTORCYCLE_FROM_125cc_ES, FUEL_TYPE_FOR_MOTORCYCLE_FROM_125cc_FR, FUEL_TYPE_FOR_MOTORCYCLE_FROM_125cc_UK, FUEL_TYPE_FOR_MOTORCYCLE_UP_125cc_ES, FUEL_TYPE_FOR_MOTORCYCLE_UP_125cc_FR, FUEL_TYPE_FOR_MOTORCYCLE_UP_125cc_UK, FUEL_TYPE_FOR_VAN_ES, FUEL_TYPE_FOR_VAN_FR, FUEL_TYPE_FOR_VAN_UK } from '../config/fuelPerCountryAndVehicleType';
import { TimeSlot } from '../models/types';
import Swal from 'sweetalert2';

@Injectable()
export class PresentationFacade {
  public isLoading$!: BehaviorSubject<boolean>;
  public isFooterVisible$!: BehaviorSubject<boolean>;
  public currentZoneId!: string;
  public isB2B!: boolean;
  public selectedProduct!: any;
  
  coreData: any = {};

  constructor(
    private kamikazeFacade: KamikazeFacade,
    private store: Store,
    private translate: TranslateService,
    private timeFormatterService: TimeFormatterService,
    private textFormatterService: TextFormatterService,

  ) {

    this.store.select(selectIsLoading).pipe(
      map((isLoading: boolean) => {
        this.isLoading$.next(isLoading);
      }),
    );
    this.store.select(selectFooterVisibility).pipe(
      map((visible: boolean) => {
        this.isFooterVisible$.next(visible);
      }),
    );
    this.store.select(selectCurrentZoneId).subscribe((currentZoneId: string) => {
      this.currentZoneId = currentZoneId;
    });

    this.store.select(selectCoreData).subscribe((coreData) => {
      this.coreData = coreData;
    });
    
    this.store.select(selectIsB2B).subscribe((isB2B: boolean) => {
      this.isB2B = isB2B;
    });

    this.store.select(selectSelectedProduct).subscribe((selectedProduct: any) => {
      this.selectedProduct = selectedProduct;
    });
  }


  public removeSeconds(time:string){
    return this.timeFormatterService.removeSeconds(time);
  }

  public getCoreDataCatalog(catalogName: string): Observable<FEEnumItemModel[]> {
    return this.store.select(selectCoreDataEnum(catalogName)).pipe(
      map((beCatalog) => {
        const parsedCatalog = this.textFormatterService.convertObjectToCamelCase(
          beCatalog?.EnumItems,
          this.textFormatterService.pascalCaseToCamelCase,
        );
        return parsedCatalog.map((i: any) => ({ key: i.value, text: i.name }));
      }),
    );
  }

  public getCoreDataZones(): Observable<ZoneModel[]> {
    return this.store
      .select(selectCoreDataRegions)
      .pipe(
        map((beZones) =>
          this.textFormatterService.convertObjectToCamelCase(
            beZones,
            this.textFormatterService.pascalCaseToCamelCase,
          ),
        ),
      );
  }

  public getZoneVerticals(): Observable<CaflerProductCardSettingsModel[]> {
    return combineLatest(
      this.store.select(selectCurrentZone),
      this.store.select(selectCurrentLang),
      this.store.select(selectCurrentCountry),
    ).pipe(
      map(([currentZone, currentLang, currentCountry], i) => {
        if (currentZone) {
          const parsedObject = this.textFormatterService.convertObjectToCamelCase(
            currentZone,
            this.textFormatterService.pascalCaseToCamelCase,
          );
          let verticals = parsedObject.verticals.map((vertical: any) => {
            const price = (Math.round(vertical.minimumTotalPriceFrom * 100) / 100).toFixed(2);
            return <any>{
              id: vertical.verticalType,
              fromPriceKey: `cafler.product.generic.price-desde.label`,
              price: price + currentCountry.PreferredRegionCurrencySymbol,
              title: `cafler.product.${vertical.localizationKey}.title`,
              description: `cafler.product.${vertical.localizationKey}.description`,
              icon: <CaflerIconSettingsModel> {
                name: `caf-${vertical.iconKey}`,
                alt: `caf-${vertical.iconKey}`,
                width: '55px',
                height: '55px'
              },
              alt: `cafler.product.${vertical.localizationKey}.title`,
            };
          });
          if (!this.isB2B) {
            // Hide medium-long-distance from products
            verticals = verticals.filter(
              (v: VerticalModel) => v.title.indexOf('medium-long-distance') == -1,
            );
          }
          return verticals;
        }
      }),
    );
  }

  public getCoreDataZone(zoneId: string): Observable<ZoneModel[]> {
    return this.store
      .select(selectCurrentZoneId)
      .pipe(
        map((beZone) =>
          this.textFormatterService.convertObjectToCamelCase(
            beZone,
            this.textFormatterService.pascalCaseToCamelCase,
          ),
        ),
      );
  }

  public getCurrentAvailableToppings(): Observable<any[]> {
    return this.store.select(selectAvailableToppings).pipe(
      map((beAvailableToppings) => {
        if (beAvailableToppings) {
          const parsedProducts = this.textFormatterService.convertObjectToCamelCase(
            beAvailableToppings,
            this.textFormatterService.pascalCaseToCamelCase,
          );
          return parsedProducts.map((topping: any) => {
            return <any>{
              id: topping.id,
              price: this.translate.instant(`cafler.product.generic.price.label`, {
                price: (Math.round(topping.price.totalPrice * 100) / 100).toFixed(2),
                currency: topping.price.currencySymbol,
              }),
              title: `cafler.product-type.${topping.title}.title`,
              description: `cafler.product-type.${topping.title}.description`,
              iconDesktop: `./assets/img/toppings/${topping.icon}-desktop.jpg`,
              iconMobile: `./assets/img/toppings/${topping.icon}-mobile.jpg`,
              alias: topping.title,
              vertical: topping.verticalType,
              isConfigurationRequired: topping.isConfigurationRequired,
            };
          });
        }
      }),
    );
  }

  public getCurrencyFromVertical() {
    return this.store.select(selectCurrentZone).pipe(
      withLatestFrom(this.store.select(selectCurrentVertical)),
      map(([zone, currentVerticalIndex], index) => {
        return zone?.Verticals[currentVerticalIndex].FromPrice.CurrencySymbol;
      }),
    );
  }
  public getBookedOrder(): Observable<any> {
    return this.store.select(selectBookedOrder);
  }

  public getVerticalById(verticalId: number) {
    const verticals: any = {
      1: 'transfer',
      2: 'revision',
      3: 'wash',
      4: 'refueling',
      5: 'pre-mot',
      6: 'medium-long-distance',
      7: 'mot',
      11: 'valet',
      13: 'formalities',
      14: 'parking',
      15: 'replacement-vehicle',
      16: 'tow-truck',
      17: 'all-you-need',

    };
    return verticals[verticalId];
  }

  public getModalConfirmMessageSettings() {
    const popupPaymentCorrectSettings = <CaflerPopupSettingsModel>{
      id: 'confirmedPopup',
      title: this.translate.instant('cafler.modal.payment-confirmed-b2c.title'),
      description: this.translate.instant('cafler.modal.payment-confirmed.description'),
      icon: <CaflerIconSettingsModel>{
        id: 'power-icon',
        name: 'caf-check-green-circle',
        alt: 'power',
      },
      confirmButton: false,
      cancelButton: true,
      cancelButtonText: this.translate.instant('cafler.modal.payment-confirmed.button'),
      cancelButtonClasses: 'caf-btn-primary caf-btn-desk-medium caf-btn-mob-medium',
    };

    return popupPaymentCorrectSettings;
  }

  public getModalErrorMessageSettings() {
    const popupPaymentFailedSettings = <CaflerPopupSettingsModel>{
      id: 'failedPopup',
      title: this.translate.instant('cafler.modal.payment-refused.title'),
      description: this.translate.instant('cafler.modal.payment-refused.description'),
      icon: <CaflerIconSettingsModel>{
        id: 'power-icon',
        name: 'caf-error-red-circle',
        alt: 'cafler.error.red.circle.icon..alt',
        height: '40px',
      },
      confirmButton: false,
      cancelButton: true,
      cancelButtonText: this.translate.instant('cafler.modal.payment-refused.button'),
      cancelButtonClasses: 'caf-btn-primary caf-btn-desk-medium caf-btn-mob-medium',
    };
    return popupPaymentFailedSettings;
  }

  public getB2BModalMessageSettings() {
    const popupB2BConfirmSettings = <CaflerPopupSettingsModel>{
      id: 'confirmedPopup',
      title: this.translate.instant('cafler.modal.payment-confirmed.title'),
      description: this.translate.instant('cafler.modal.payment-confirmed.description'),
      icon: <CaflerIconSettingsModel>{
        id: 'power-icon',
        name: 'caf-check-green-circle',
        alt: 'power',
      },
      confirmButton: false,
      cancelButton: true,
      cancelButtonText: this.translate.instant('cafler.modal.payment-confirmed.new-service'),
      cancelButtonClasses: 'caf-btn-primary caf-btn-desk-medium caf-btn-mob-medium',
    };
    return popupB2BConfirmSettings;
  }
  
  public getTowTruckModalMessageSettings() {
    const popupConfirmSettings = <CaflerPopupSettingsModel>{
      id: 'confirmedTowTruckPopup',
      title: this.translate.instant('cafler.modal.payment-confirmed.tow-truck.title'),
      description: this.translate.instant('cafler.modal.payment-confirmed.tow-truck.description'),
      icon: <CaflerIconSettingsModel>{
        id: 'power-icon',
        name: 'caf-check-green-circle',
        alt: 'power',
      },
      confirmButton: false,
      cancelButton: true,
      cancelButtonText: this.translate.instant('cafler.modal.payment-confirmed.new-service'),
      cancelButtonClasses: 'caf-btn-primary caf-btn-desk-medium caf-btn-mob-medium',
    };
    return popupConfirmSettings;
  }

  public getAllYouNeedModalMessageSettings() {
    const popupConfirmSettings = <CaflerPopupSettingsModel>{
      id: 'confirmedTowTruckPopup',
      title: this.translate.instant('cafler.modal.payment-confirmed.all-you-need.title'),
      description: this.translate.instant('cafler.modal.payment-confirmed.all-you-need.description'),
      icon: <CaflerIconSettingsModel>{
        id: 'power-icon',
        name: 'caf-check-green-circle',
        alt: 'power',
      },
      confirmButton: false,
      cancelButton: true,
      cancelButtonText: this.translate.instant('cafler.modal.payment-confirmed.new-service'),
      cancelButtonClasses: 'caf-btn-primary caf-btn-desk-medium caf-btn-mob-medium',
    };
    return popupConfirmSettings;
  }
  
  public getRevisionModalMessageSettings() {
    const popupRevisionConfirmSettings = <CaflerPopupSettingsModel>{
      id: 'confirmedPopup',
      title: this.translate.instant('cafler.modal.payment-revision.title'),
      description: this.translate.instant('cafler.modal.payment-revision.description'),
      icon: <CaflerIconSettingsModel>{
        id: 'power-icon',
        name: 'caf-check-green-circle',
        alt: 'power',
      },
      confirmButton: false,
      cancelButton: true,
      cancelButtonText: this.translate.instant('cafler.modal.payment-revision.button'),
      cancelButtonClasses: 'caf-btn-primary caf-btn-desk-medium caf-btn-mob-medium',
    };

    return popupRevisionConfirmSettings;
  }

  public getPickupTimeOptions(dateOfTheOrder: Date, isSpecificHours: boolean, deliveryType: number = 1) {
    let dateToSend = {
      selectedDate: this.timeFormatterService.dateToIsoString(dateOfTheOrder),
      productId: this.selectedProduct.ordersManagerProductId,
      isB2B: this.isB2B,
      isSpecificHours: isSpecificHours,
      transferType: deliveryType
    };
    return this.kamikazeFacade.getProductAvailabilities(dateToSend).pipe(
      map((result: any[]) => {
        let resultFormatted = result.map((item: any) => {
          return {
            key: item.Key,
            text: item.Text,
            disabled: item.Disabled,
          }
        })
        return resultFormatted
    })
    );
  }

  getAllYouWantTimeOptions(date: Date){
    return this.kamikazeFacade.getAllYouWantTimeAvailability(this.isB2B, this.currentZoneId, date).pipe(
      map((result: TimeSlot[]) => {
        const resultFormatted = result.map((item: any) => {
          return {
            key: item.Key,
            text: item.Text,
            disabled: item.Disabled,
          }
        })
        return resultFormatted
      })
    );
  }
  
  getFuelTypeFormattedOptions(vehicleType: number, regionIsoCode: string) {

    const fuelTypeEnum = this.coreData?.Enums.find(
     (item: BEEnumModel) => item.Name === 'FuelTypeEnum'
    );

    const fuelTypes = this.getFuelTypePerVehicleTypeAndCountry(vehicleType, regionIsoCode);
    if(fuelTypes){
      return fuelTypeEnum.EnumItems.filter((obj:any) => fuelTypes.includes(obj.Value)).map((t:any) => 
      
      ({
        key: t.Value, 
        text: `cafler.product-type.technical-inspection.fuel-type.${t.Name}`
      })
    );
    }
  }
  
  isVehicleValidForMOTInCountry(vehicleType: number, regionIsoCode: string) {
    const listOfFuel = this.getFuelTypePerVehicleTypeAndCountry(vehicleType, regionIsoCode)
    return listOfFuel && listOfFuel.length > 0;
  }

  getVehicleFamily(vehicleType: number) {
    const carTypes = [VehiclesTypesEnum.SEDAN, VehiclesTypesEnum.ATV, VehiclesTypesEnum.MINIVAN]
    const motorcycleTypes = [VehiclesTypesEnum.MOTORCYCLE_FROM_125cc, VehiclesTypesEnum.MOTORCYCLE_UP_125cc]
    const VanTypes = [VehiclesTypesEnum.VAN, VehiclesTypesEnum.LIGHTVAN, VehiclesTypesEnum.BOXTRUCK]
    
    if(carTypes.includes(vehicleType)) {
      return 'car';
    }
    if(motorcycleTypes.includes(vehicleType)) {
      return 'motorcycle';
    }
    if(VanTypes.includes(vehicleType)) {
      return 'van';
    }

    return undefined;
  }


  private getFuelTypePerVehicleTypeAndCountry(vehicleType: number, regionIsoCode: string) {
    const familyType = this.getVehicleFamily(vehicleType);

    //TODO: refactorizar esto
    switch (regionIsoCode) {
      case 'esp':
        if(familyType === 'car') {
          return FUEL_TYPE_FOR_CAR_ES;
        }
        if(familyType === 'motorcycle' && vehicleType === VehiclesTypesEnum.MOTORCYCLE_FROM_125cc) {
          return FUEL_TYPE_FOR_MOTORCYCLE_FROM_125cc_ES;
        }
        if(familyType === 'motorcycle' && vehicleType === VehiclesTypesEnum.MOTORCYCLE_UP_125cc) {
          return FUEL_TYPE_FOR_MOTORCYCLE_UP_125cc_ES;
        }
        if(familyType === 'van') {
          return FUEL_TYPE_FOR_VAN_ES;
        }
        return [];
      case 'fra':
        if(familyType === 'car') {
          return FUEL_TYPE_FOR_CAR_FR;
        }
        if(familyType === 'motorcycle' && vehicleType === VehiclesTypesEnum.MOTORCYCLE_FROM_125cc) {
          return FUEL_TYPE_FOR_MOTORCYCLE_FROM_125cc_FR;
        }
        if(familyType === 'motorcycle' && vehicleType === VehiclesTypesEnum.MOTORCYCLE_UP_125cc) {
          return FUEL_TYPE_FOR_MOTORCYCLE_UP_125cc_FR;
        }
        if(familyType === 'van') {
          return FUEL_TYPE_FOR_VAN_FR;
        }
        return [];
      case 'gbr':
        if(familyType === 'car') {
          return FUEL_TYPE_FOR_CAR_UK;
        }
        if(familyType === 'motorcycle' && vehicleType === VehiclesTypesEnum.MOTORCYCLE_FROM_125cc) {
          return FUEL_TYPE_FOR_MOTORCYCLE_FROM_125cc_UK;
        }
        if(familyType === 'motorcycle' && vehicleType === VehiclesTypesEnum.MOTORCYCLE_UP_125cc) {
          return FUEL_TYPE_FOR_MOTORCYCLE_UP_125cc_UK;
        }
        if(familyType === 'van') {
          return FUEL_TYPE_FOR_VAN_UK;
        }
        return [];
      default:
        return [];
    }
  }

  public getMediumLongDistanceTimeSpans(dateOfTheOrder: Date, productId: string, deliveryType: number = 1) {
    let dateToSend = {
      selectedDate: this.timeFormatterService.dateToIsoString(dateOfTheOrder),
      productId: this.selectedProduct.ordersManagerProductId,
      isB2B: this.isB2B,
      isSpecificHours: false,
      transferType: deliveryType
    };
    return this.kamikazeFacade.getProductAvailabilities(dateToSend).pipe(
      map((result: any[]) => {
          let resultFormatted = result.map((item: any) => {
            return {
              key: item.Key,
              text: item.Text
            }
          })
          return resultFormatted
      })
      );
  }

  public formatHours(availableToppingHours: any) {
    return availableToppingHours.map((slotHour: any) => {
      return { key: slotHour, text: slotHour };
    });
  }

  public getCountryCodes() {
    return of([
      { key: '+34', text: '+34' },
      { key: '+33', text: '+33' },
      { key: '+44', text: '+44' },
      { key: '+39', text: '+39' },
    ]);
  }

  public getHomologationTypes() {
    return of([
      { key: 1,
        date: 'cafler.homologation-type.RemolqueBallWithProject',
        price: 'cafler.page.homologation.homologation-with-project',
       },
      { key: 2,
        date: 'cafler.homologation-type.RemolqueBallWithoutProject',
        price: 'cafler.page.homologation.homologation-without-project',
      },
      { key: 3,
        date: 'cafler.homologation-type.ReformWithProject',
        price: 'cafler.page.homologation.homologation-with-project',
      },
      { key: 4,
        date: 'cafler.homologation-type.ReformWithoutProject',
        price: 'cafler.page.homologation.homologation-without-project',
      },
      { key: 5,
        date: 'cafler.homologation-type.PotencyModification',
        price: '',
      },
      { key: 6,
        date: 'cafler.homologation-type.ServiceChange',
        price: '',
      },
      { key: 7,
        date: 'cafler.homologation-type.ReLicensing',
        price: '',
      },
    ]);
  }

  public isOrderingForToday(dateOrdering: Date) {
    let today = new Date();
    return (
      dateOrdering.getFullYear() === today.getFullYear() &&
      dateOrdering.getMonth() === today.getMonth() &&
      dateOrdering.getDate() === today.getDate()
    );
  }

  public getNextQuarter(todayDate: Date) {
    let minutes = todayDate.getMinutes();
    switch (true) {
      case minutes >= 0 && minutes < 15: {
        return 15;
      }
      case minutes >= 15 && minutes < 30: {
        return 30;
      }
      case minutes >= 30 && minutes < 45: {
        return 45;
      }
      case minutes >= 45 && minutes < 60: {
        return 0;
      }
      default: {
        return 0;
      }
    }
  }

  public getCountries(regions: any) {
    return regions.map((region: any) => {
      return {
        lang: region.LanguageIsoCode,
        selector: {
          key: region.RegionIsoCode,
          text: 'cafler.product-selection.select-country.' + region.RegionIsoCode,
        },
      };
    });
  }

  public getZones(zones: any) {
    return zones.map((zone: any) => {
      return {
        key: zone.ZoneId,
        text: zone.Name,
      };
    });
  }

  public getHolidays() {
    return this.store.select(selectSelectedProduct).pipe(
      map((selectedProduct) => {
        if (!selectedProduct) {
          return [];
        }
        return selectedProduct.holidays;
      }),
    );
  }

  public getAddressB2BToAutosuggest(): Observable<any> {
    return this.store.select(selectUserData).pipe(
      map((userData) => {
        let b2bAddress = new GoogleAddress(userData.userData.businessAddress, {
          location: {
            latitude: userData.userData.businessAddressLatitude,
            longitude: userData.userData.businessAddressLongitude,
          },
        });
        // It's coming from our DB so we consider it's a valid address
        b2bAddress.googleObject.isValidAddress = true;
        return b2bAddress;
      }),
    );
  }


  public validateOperativeZone(address: GoogleAddress): Observable<any> {
    return this.kamikazeFacade.validateOperativeZone(
      this.currentZoneId, address.googleObject.location.latitude, address.googleObject.location.longitude)
  }

  // public getTechnicalInspectionProductId(isB2B: boolean, chassisType: number, fuelType: number): Observable<any> {
  //   return this.kamikazeFacade.getTechnicalInspectionProductId(isB2B, chassisType, fuelType)
  // }

  public validateMOTStationHours(stationId: string, date: any, time: any ): Observable<any> {
    return this.kamikazeFacade.checkAppointmentHour(stationId, date, time, this.isB2B)
  }

  public showInsuranceToppingErrorPopup(errors: string[]){

    this.translate.get(errors).subscribe((res: any) => {
  
      let errorCodes = errors.slice();
      errorCodes.splice(0,4);
    Swal.fire({
      title: res[errors[0]],
      icon: 'error',
      confirmButtonText: res[errors[2]],
      confirmButtonColor: '#180C2C',
      html: 
      `<p>${res[errors[1]]}: </p>
      <ul>
      ${
        errorCodes.map((key: string) => {
          return "<li>" + res[key] + "</li>"
        }).join("")
      }
      </ul>
      ${res[errors[3]]}
      <style>
      ul {
        list-style-type: none;
        padding: 0;
      }
      </style>
      `
    });
    });
  }

  public getCountryLicenseCodes(): any[]{
    return [
      {key: 'ES', text: 'cafler.toppings.insurance.country.spain'},
      {key: 'FR', text: 'cafler.toppings.insurance.country.france'},
      {key: 'GB', text: 'cafler.toppings.insurance.country.united-kingdom'},
      {key: 'DE', text: 'cafler.toppings.insurance.country.germany'},
      {key: 'IT', text: 'cafler.toppings.insurance.country.italy'},
      {key: 'PT', text: 'cafler.toppings.insurance.country.portugal'},
      {key: 'NL', text: 'cafler.toppings.insurance.country.netherlands'},
      {key: 'BE', text: 'cafler.toppings.insurance.country.belgium'},
      {key: 'US', text: 'cafler.toppings.insurance.country.united-states'},
      {key: 'CA', text: 'cafler.toppings.insurance.country.canada'},
      {key: 'MX', text: 'cafler.toppings.insurance.country.mexico'},
      {key: 'CH', text: 'cafler.toppings.insurance.country.switzerland'},
      {key: 'DK', text: 'cafler.toppings.insurance.country.denmark'},
      {key: 'AT', text: 'cafler.toppings.insurance.country.austria'},
      {key: 'RU', text: 'cafler.toppings.insurance.country.russia'},
      {key: 'PL', text: 'cafler.toppings.insurance.country.poland'},
      {key: 'CZ', text: 'cafler.toppings.insurance.country.czech-republic'},
      {key: 'HR', text: 'cafler.toppings.insurance.country.croatia'},
      {key: 'GR', text: 'cafler.toppings.insurance.country.greece'},
    ]}
}
