import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function notSameDirectionValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!!control.get('pickupAddress')?.value) {
      const fromObj = control.get('pickupAddress')?.value;
      const fromAddress = fromObj.bingObject?.address?.formattedAddress || fromObj.text;
      if (!!control.get('deliveryAddress')?.value || !!control.get('dropoffAddress')?.value) {
        const toObj = control.get('deliveryAddress')?.value || control.get('dropoffAddress')?.value;
        const toAddress = toObj.bingObject?.address?.formattedAddress || toObj.text;
        if (fromAddress === toAddress && fromAddress != undefined) return { sameAddress: true };
      }
    }
    return null;
  };
}
