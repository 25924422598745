import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { datepickerMaxDate } from '../../services/utils';
import { CafNotificationSettingsModel, CaflerErrorSettingsModel, CaflerIconSettingsModel, GoogleAddress, GoogleMapsService } from '@cafler/common-ui';
import { Store } from '@ngrx/store';
import { NavigationService } from '../../services/navigation.service';
import { PresentationFacade } from '../../facades/presentation.facade';
import { FormsHelperService } from '../../services/forms.service';
import { LaunchDarklyFlagNames, LaunchDarklyService } from '../../services/LaunchDarkly.service';
import { NavigablePopupComponentService } from '../../components/navigable-popup/service/navigable-popup.service';
import { SingleDataHelperService } from '../../helpers/single-data-helper.service';
import { KamikazeResource } from '../../resources/kamikaze.resource';
import { TextFormatterService } from '../../helpers/text-formatter.service';
import { KamikazeFacade } from '../../facades/kamikaze.facade';
import { CaflerPopUpService } from '../../components/pop-up/service/pop-up.service';
import { TimeFormatterService } from '../../helpers/time-formatter.service';
import { FormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { selectCurrentCountry, selectCurrentLang, selectCurrentZone, selectIsB2B, selectProductData, selectServiceTimeOptions } from '../../store/selectors';
import { KamikazeActions, setIsLoading } from '../../store/actions';
import { first, pairwise, takeUntil } from 'rxjs/operators';
import { PopupSettingsModel } from '../../components/pop-up/model/pop-up-settings.model';
import { operativeZonesURLByCountry } from '../../config/operativeZonesURLByCountry';
import { vehicleStatusOptions } from 'src/app/constants';

@Component({
  selector: 'app-hoist-form',
  templateUrl: './hoist-form.component.html',
  styleUrls: ['./hoist-form.component.scss']
})
export class HoistFormComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  isB2B!: boolean;
  private readonly destroySubjects$ = new ReplaySubject<void>(1);
  fg!: FormGroup;

  initialForm: any;
  pastDateWhenDuplicatingError: boolean = false
  timesFailedPickupAddress = 0;
  timesFailedDropoffAddress = 0;
  maxErrorsOnAddress = 4;
  combinationNotValid: boolean = false;
  readyToDisplayHours = false;
  dayChangedFlag = false;
  serviceTimeOptions: any[] = [];
  renderNewFeatureSpeechBubble?: boolean = false;
  flagSubscription: any;
  firstNewFeatureTry: boolean = true;
  currentCountry: any;
  noRangeHourAvailable: boolean = false;
  isDuplicated: boolean = false;
  duplicateSpecificHourError: boolean = false;

  zoneId: string = '';
  operativeZonesURL: string = '';


  @ViewChild('startServiceTimeComponent') startServiceTimeComponent: any;
  @ViewChild('endServiceTimeComponent') endServiceTimeComponent: any;

  settings: any = {
    serviceType: {
      id: 'serviceType',
      formControlName: 'serviceType',
      placeholder: '',
      disabled: false,
    },
    carPlaque: {
      id: 'carPlaque',
      formControlName: 'carPlaque',
      placeholder: 'cafler.vehicle-info.plate.placeholder',
      disabled: false,
      type: 'text',
    },
    vehicleStatus: {
      id: 'vehicleStatus',
      formControlName: 'vehicleStatus',
      placeholder: 'cafler.page.select-option.placeholder',
      disabled: false,
      options: vehicleStatusOptions,
    },
    brand: {
      id: 'brand',
      formControlName: 'brand',
      placeholder: 'cafler.vehicle-info.brand.placeholder',
      disabled: false,
      type: 'text',
      required: true,
    },
    model: {
      id: 'model',
      formControlName: 'model',
      placeholder: 'cafler.vehicle-info.model.placeholder',
      disabled: false,
      type: 'text',
      required: true,
    },
    pickupAddress: {
      id: 'pickupAddress',
      formControlName: 'pickupAddress',
      placeholder: 'cafler.pickup-info.pickup-address.placeholder',
      options: { businessSuggestions: true, placeSuggestions: true },
    },
    pickupAddressDetails: {
      id: 'pickupAddressDetails',
      formControlName: 'pickupAddressDetails',
      placeholder: 'cafler.pickup-info.pickup-details.placeholder',
      disabled: false,
      required: false,
      type: 'text',
    },
    pickupDate: {
      id: 'pickupDate',
      formControlName: 'pickupDate',
      placeholder: 'cafler.pickup-info.pickup-day.placeholder',
      minDate: new Date(),
      maxDate: datepickerMaxDate(),
      daysDisabled: [6,0],
      icon: <CaflerIconSettingsModel>{
        alt: 'calendar',
        name: 'caf-notes-book-dark',
      },
      lang: 'currentLang'
    },
    startPickUpTime: {
      id: 'startPickUpTime',
      formControlName: 'startPickUpTime',
      placeholder: 'cafler.page.service-time.from-placeholder',
      disabled: false,
      options: [],
      required: true,
      leftIconSettings: {
        name: 'caf-clock-cafler',
        alt: 'Clock icon',
        height: '25px',
        width: '25px',
      },
    },
    endPickUpTime: {
      id: 'endPickUpTime',
      formControlName: 'endPickUpTime',
      placeholder: 'cafler.page.service-time.until-placeholder',
      disabled: true,
      options: [],
      required: false,
      leftIconSettings: {
        name: 'caf-clock-cafler',
        alt: 'Clock icon',
        height: '25px',
        width: '25px',
      },
    },
    deliveryAddress: {
      id: 'deliveryAddress',
      formControlName: 'deliveryAddress',
      placeholder: 'cafler.page.media-larga-distancia.directions.destination.placeholder',
      options: { businessSuggestions: true, placeSuggestions: true },
    },
    deliveryAddressDetails: {
      id: 'deliveryAddressDetails',
      formControlName: 'deliveryAddressDetails',
      placeholder: 'cafler.pickup-info.pickup-details.placeholder',
      disabled: false,
      required: false,
      type: 'text',
    },
    additionalComments: {
      id: 'additionalComments',
      formControlName: 'additionalComments',
      placeholder: 'cafler.pickup-info.textarea.comments',
      minlength: 0,
      maxlength: 200,
      disabled: false,
      required: false,
    },
    vehicleType: {
      id: 'vehicleType',
      formControlName: 'vehicleType',
      placeholder: 'cafler.page.fuel.body-type.placeholder',
      disabled: false,
      options: [],
    },
    fullName: {
      id: 'fullName',
      formControlName: 'fullName',
      placeholder: 'cafler.contact.name.placeholder',
      disabled: false,
      type: 'text',
    },
    countryPhoneCode: {
      id: 'countryPhoneCode',
      formControlName: 'countryPhoneCode',
      placeholder: 'cafler.contact.country-phone-code.placeholder',
      disabled: false,
      options: [],
    },
    phone: {
      id: 'phone',
      formControlName: 'phone',
      placeholder: 'cafler.contact.phone.placeholder',
      disabled: false,
      type: 'phone',
    },
    dropoffFullName: {
      id: 'dropoffFullName',
      formControlName: 'dropoffFullName',
      placeholder: 'cafler.contact.name.placeholder',
      disabled: false,
      type: 'text',
    },
    dropoffCountryPhoneCode: {
      id: 'dropoffCountryPhoneCode',
      formControlName: 'dropoffCountryPhoneCode',
      placeholder: 'cafler.contact.country-phone-code.placeholder',
      disabled: false,
      options: [],
    },
    dropoffPhone: {
      id: 'dropoffPhone',
      formControlName: 'dropoffPhone',
      placeholder: 'cafler.contact.phone.placeholder',
      disabled: false,
      type: 'phone',
    },
    email: {
      id: 'email',
      formControlName: 'email',
      placeholder: 'cafler.contact.email.placeholder',
      disabled: false,
      type: 'email',
    },
    dropoffEmail: {
      id: 'email',
      formControlName: 'dropoffEmail',
      placeholder: 'cafler.contact.email.placeholder',
      disabled: false,
      type: 'email',
    },
  };

  settingsExtra: any = {
    speechBubble: {
      id:'newFeature',
      text: 'cafler.page.service-time.new-feature.speech-bubble.text',
    },

    locationIcon: <CaflerIconSettingsModel>{
      alt: 'location',
      name: 'caf-pin-location',
    },

    infoIcon: <CaflerIconSettingsModel> {
      name: 'caf-info-tooltip-outline',
      alt: 'Info icon',
    },
    warningIcon: <CaflerIconSettingsModel> { name: 'caf-warning-exclamation', alt: 'warning', width: '14px', height: '14px'},

    notificationSettings: <CafNotificationSettingsModel>{
      type: 'info',
      text: "cafler.transfer.dropoff-schedule.info",
      fontSize: '16px',
    },
  };

  settingsErrors: any = {
    errorsFullName: <CaflerErrorSettingsModel>{
      formControlName: 'fullName',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsCountryPhoneCode: <CaflerErrorSettingsModel>{
      formControlName: 'countryPhoneCode',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsDropoffCountryPhoneCode: <CaflerErrorSettingsModel>{
      formControlName: 'dropoffCountryPhoneCode',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsDropoffFullName: <CaflerErrorSettingsModel>{
      formControlName: 'dropoffFullName',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsDriverFullName: <CaflerErrorSettingsModel>{
      formControlName: 'driverFullName',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsDriverCountryPhoneCode: <CaflerErrorSettingsModel>{
      formControlName: 'driverCountryPhoneCode',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsDriverEmail: <CaflerErrorSettingsModel>{
      formControlName: 'driverEmail',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    }, 
    errorsDriverPhone: <CaflerErrorSettingsModel>{
      formControlName: 'driverPhone',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsPhone: <CaflerErrorSettingsModel>{
      formControlName: 'phone',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'pattern', text: 'cafler.forms.error.phone-pattern' },
        { key: 'minlength', text: 'cafler.forms.error.min-length' },
      ],
    },
    errorsDropOffPhone: <CaflerErrorSettingsModel>{
      formControlName: 'dropoffPhone',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'pattern', text: 'cafler.forms.error.phone-pattern' },
        { key: 'minlength', text: 'cafler.forms.error.min-length' },
      ],
    },
    errorsEmail: <CaflerErrorSettingsModel>{
      formControlName: 'email',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'email', text: 'cafler.forms.error.email' },
      ],
    },
    errorsDropoffEmail: <CaflerErrorSettingsModel>{
      formControlName: 'dropoffEmail',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'email', text: 'cafler.forms.error.email' },
      ],
    },
    errorsCarPlaque: <CaflerErrorSettingsModel>{
      formControlName: 'carPlaque',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' },{key:'invalidLicensePlate', text: 'cafler.forms.error.invalid-license-plate'}],
    },
    errorsModel: <CaflerErrorSettingsModel>{
      formControlName: 'model',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsBrand: <CaflerErrorSettingsModel>{
      formControlName: 'brand',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsPickupDate: <CaflerErrorSettingsModel>{
      formControlName: 'pickupDate',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'notPresent', text: 'cafler.forms.error.date-not-in-past' },
      ],
    },
    errorsStartPickUpTime: <CaflerErrorSettingsModel>{
      formControlName: 'startPickUpTime',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsEndPickUpTime: <CaflerErrorSettingsModel>{
      formControlName: 'endPickUpTime',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsVehicleType: <CaflerErrorSettingsModel>{
      formControlName: 'vehicleType',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsPickupAddress: <CaflerErrorSettingsModel>{
      formControlName: 'pickupAddress',
      
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'selectedAddress', text: 'cafler.forms.error.selected-address' },
        {
          key: 'streetNumberNeeded',
          text: 'cafler.address-message-error-missing-street-number.title',
        },

        { key: 'addressNumber', text: 'cafler.forms.error.address-number' },
        {
          key: 'checkCoordinatesWithinCaflerArea',
          text: 'cafler.forms.error.cafler-country-coverage',
        },
      ],
    },
    errorsDropoffAddress: <CaflerErrorSettingsModel>{
      formControlName: 'deliveryAddress',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'selectedAddress', text: 'cafler.forms.error.selected-address' },
        {
          key: 'streetNumberNeeded',
          text: 'cafler.address-message-error-missing-street-number.title',
        },

        { key: 'addressNumber', text: 'cafler.forms.error.address-number' },
        {
          key: 'checkCoordinatesWithinCaflerArea',
          text: 'cafler.forms.error.cafler-country-coverage',
        },
      ],
    },
  };

 
  hoistExplanation = [
    {
      text: 'cafler.product.hoist.step-1',
      icon : {
        name: 'caf-transfer-black',
        alt: '',
        width: '20px',
        height: '20px',
      }
    },
    {
      text: 'cafler.product.hoist.step-2',
      icon : {
        name: 'caf-paper-check',
        alt: '',
        width: '20px',
        height: '20px',
      }
    },
    {
      text: 'cafler.product.hoist.step-3',
      icon : {
        name: 'caf-simple-car',
        alt: '',
        width: '20px',
        height: '20px',
      }
    },
  ]


  popUpRef: any; 


  constructor(
    private store: Store,
    private navigationService: NavigationService,
    private presentationFacade: PresentationFacade,
    private formsService: FormsHelperService,
    private googleMapsService: GoogleMapsService,
    private launchDarklyService: LaunchDarklyService,
    private navigablePopupService: NavigablePopupComponentService,
    private singleDataHelperService: SingleDataHelperService,
    private resource: KamikazeResource,
    private textFormatter: TextFormatterService,
    private kamikazeFacade: KamikazeFacade,
    private caflerPopUpService: CaflerPopUpService,
    private timeFormatterService: TimeFormatterService

  ) {
    
    if(launchDarklyService.isLoaded()){
      this.flagSubscription = launchDarklyService.flagChange.subscribe(() => this.initFlags(launchDarklyService));
    }else{
      this.initFlags(launchDarklyService);
    }

    this.initialForm = history.state.initialForm ? history.state.initialForm : null;
    this.isDuplicated = history.state.isDuplicated ? history.state.isDuplicated : false;

    this.fg = this.formsService.initializeForm(this.settings, this.initialForm);

    // this.store.dispatch({ type: KamikazeActions.FetchAvailableVerticalProducts });

    this.store.select(selectCurrentZone).subscribe((zone) => {
      this.settings.pickupAddress.options.countryCode = zone?.RegionCode;
      this.settings.deliveryAddress.options.countryCode = zone?.RegionCode;
    });

    this.store.select(selectCurrentCountry).subscribe((country: any) => {
      if (country) {
        // setting default prefix code number
        this.fg.get('countryPhoneCode')?.setValue(country.InternationalPhonePrefix);
        this.fg.get('dropoffCountryPhoneCode')?.setValue(country.InternationalPhonePrefix);
        this.fg.get('driverCountryPhoneCode')?.setValue(country.InternationalPhonePrefix);
        this.currentCountry = country;
        // this.settings.pickupAddress.options.countryCode = country?.RegionIsoCode;
        // this.settings.deliveryAddress.options.countryCode = country?.RegionIsoCode;
        const isoCode: string = country.RegionIsoCode 
        if(isoCode === 'esp' || isoCode === 'gbr' || isoCode === 'fra'){
          this.operativeZonesURL = operativeZonesURLByCountry[isoCode]
        }
      }
    });

    this.store.select(selectCurrentLang).subscribe((lang) => {
      if(lang){
        this.settings.pickupDate = {
          ...this.settings.pickupDate,
          lang: lang.split('-')[0]
        }
      } 
    })
    
    if (this.isDuplicated) {
      this.validateFormAddress(this.settings.pickupAddress.formControlName)
      this.validateFormAddress(this.settings.deliveryAddress.formControlName)

    }
  }

  ngOnInit() {
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: true });
    this.store.dispatch({ type: KamikazeActions.SetNextPage, nextPage: 'overview' });
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'tow-truck' });
    this.store.dispatch({ type: KamikazeActions.ClearAvailableToppings });
  
    this.store.dispatch({
      type: KamikazeActions.SetFormValidity,
      isFormValid: this.formsService.checkCurrentFormValidity(),
    });

    this.setMinimumPickUpDateOnTomorrow()

    this.navigationService.scrollToTop();

    this.store.select(selectIsB2B).subscribe((isB2B) => {
      this.isB2B = isB2B;
    });

    this.store.select(selectServiceTimeOptions).subscribe((serviceTimeOptions) => {
      if(serviceTimeOptions !== undefined){
        this.serviceTimeOptions = serviceTimeOptions;
      }
    })

    this.fg
    .get(this.settings.pickupDate.formControlName)
    ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
    .pipe(first())
    .subscribe(() => {
      // set range time as the default option
      this.readyToDisplayHours = true;
      this.getSlotHours(false);
    });

    this.fg
    .get(this.settings.pickupDate.formControlName)
    ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
    .pipe(pairwise())
    .subscribe(([prev, next]) => {
      if((prev.getTime() === next.getTime())){
        return;
      }
      if (this.readyToDisplayHours) {
        this.resetServiceTimeStyles(true);
        this.getSlotHours(false);
      }
      this.readyToDisplayHours = true;
    });
    
    this.fg
      .get(this.settings.startPickUpTime.formControlName)
      ?.statusChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((status) => {
        this.changeEndPickupTimeDisability(status)
      });

    this.fg
      .get(this.settings.endPickUpTime.formControlName)
      ?.statusChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((status) => {
        if (status === 'VALID') {
          this.combinationNotValid = false;
        }
      });

    this.presentationFacade
      .getCountryCodes()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((countryCodes) => {
        this.settings.dropoffCountryPhoneCode.options = countryCodes;
        this.settings.countryPhoneCode.options = countryCodes;
      });

    this.singleDataHelperService
      .getCurrentVerticalProducts()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((products: any) => {
        if (products) {
          this.fg.controls.serviceType.setValue(products.towTruck[0].ordersManagerProductId);
          this.store.dispatch({
            type: KamikazeActions.SetSelectedProduct,
            selectedProduct: products.towTruck[0],
          });
          
          if(this.isDuplicated && this.initialForm){
            
            if(this.fg.get(this.settings.pickupDate.formControlName)?.value){
              this.readyToDisplayHours = true;
              const isSpecificHour = false;
              this.getSlotHours(isSpecificHour);     
            }else{
              this.pastDateWhenDuplicatingError= true
            }
          }
        }
      });

    this.presentationFacade.getCoreDataCatalog('TransferTypeEnum').subscribe((vehicleTypes) => {
      if (vehicleTypes) {
        this.settings.vehicleType.options = vehicleTypes.map((t: any) => ({
          key: t.key,
          text: `cafler.product-type.media-larga-distancia.vehicle-type.${t.text}`,
        }));
      }
    });

    this.store.select(selectCurrentZone).subscribe((zone) => {
      this.settings.pickupAddress.options.countryCode = zone?.RegionCode;
      this.settings.deliveryAddress.options.countryCode = zone?.RegionCode;
    });

    this.store
      .select(selectProductData)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((productData) => {
        if (this.isLoading) {
          if (!!productData) {
            this.formsService.loadFormGroupData(this.fg, productData, this.isLoading);
            this.isLoading = false;
          } else {
            if (this.isB2B) {
              this.formsService.preloadB2BData(this.fg);
            }
          
            // this.fg.controls.serviceType.setValue(this.navigationService.getSelectedProduct());
          }
        }
      });

    this.presentationFacade.getHolidays().subscribe((holidays) => {
      let holidaysDates = [];
      if(holidays){
        holidaysDates = holidays.map((day: any) => {
          return new Date(day);
        });
      }


      this.settings.pickupDate = {
        ...this.settings.pickupDate,
        datesDisabled: holidaysDates,
      };
    });

    this.navigationService.currentPageTitle = 'cafler.product.hoist.title';
    this.fg.statusChanges.pipe(takeUntil(this.destroySubjects$)).subscribe((status) => {
      this.formsService.handleFormValidity(status, this.fg.value);
    });

    this.fg
      .get(this.settings.startPickUpTime.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((newValue) => {
        this.updateEndRangeHours(newValue);
      });
  }

  validateFormAddress(formControlName: string){
    const addressFormRef = this.fg.get(formControlName)?.value as GoogleAddress
    addressFormRef.googleObject.isValidAddress = true 
  }
  

  initFlags(launchDarklyService: LaunchDarklyService) {
    this.renderNewFeatureSpeechBubble = launchDarklyService.getFlag(LaunchDarklyFlagNames.SPECIFIC_TIME_NEW_FEATURE);
  }

  changeEndPickupTimeDisability(status: string){
    if (status === 'VALID') {
      this.settings.endPickUpTime.disabled = false;
      this.dayChangedFlag = false;
    } else {
      this.settings.endPickUpTime.disabled = true;
    }
  }


  getSlotHours(isSpecificHour: boolean) {
    this.presentationFacade
      .getPickupTimeOptions(
        this.fg.get(this.settings.pickupDate.formControlName)?.value,
        isSpecificHour,
      )
      .pipe(first())
      .subscribe((pickupTimeOptions: any) => {

        let timeOptions = JSON.parse(JSON.stringify(pickupTimeOptions));

        this.settings.startPickUpTime.options = timeOptions.slice(0, -3);
        this.settings.endPickUpTime.options = timeOptions.slice(3);

        if(this.isDuplicated){
          const pickUpTime = this.fg.get(this.settings.startPickUpTime.formControlName)?.value
          const dropoffTime = this.fg.get(this.settings.endPickUpTime.formControlName)?.value

          let elementSelectedA = timeOptions.find((el: any) => el.key === pickUpTime);
          let elementSelectedB = timeOptions.find((el: any) => el.key === dropoffTime);
  
          if(!elementSelectedA.disabled){
            this.fg.get(this.settings.startPickUpTime.formControlName)?.setValue(pickUpTime)
          }else{
            this.duplicateSpecificHourError = true 
          }
          if(!elementSelectedB.disabled){
            this.fg.get(this.settings.endPickUpTime.formControlName)?.setValue(dropoffTime)
          }else{
            this.duplicateSpecificHourError = true  
          }
        }
        this.fg.controls[this.settings.endPickUpTime.formControlName].addValidators(Validators.required);

        this.noRangeHourAvailable = timeOptions.length < 3;
        this.settings.startPickUpTime.disabled = this.noRangeHourAvailable;
        

        this.store.dispatch({
          type: KamikazeActions.SetServiceTimeOptions,
          serviceTimeOptions: timeOptions,
        });

      });
  }

  getB2BAddress(formControlName: string) {
    this.presentationFacade
      .getAddressB2BToAutosuggest()
      .pipe(first())
      .subscribe((addressObj) => {
        this.fg.get(formControlName)?.setValue(addressObj);
      });
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }

  updateSpecificHours(valueSelected: any){
    this.store.select(selectServiceTimeOptions).subscribe((serviceTimeOptions) => {
      this.serviceTimeOptions = serviceTimeOptions;
      
      if(!serviceTimeOptions || serviceTimeOptions && serviceTimeOptions.length === 0) return 
      
      let elementSelected: any = serviceTimeOptions.find((el: any) => el.key === valueSelected);
      
      if(elementSelected !== undefined && !elementSelected.disabled){
        this.settings.startPickUpTime.options = elementSelected.key;
        this.settings.endPickUpTime.options = null;
  
        this.fg.controls.startPickUpTime.setValue(elementSelected.key);
        this.fg.controls.endPickUpTime.setValue(null);
      }    
    })

  }

  updateEndRangeHours(valueSelected: any) {
    this.store.select(selectServiceTimeOptions).subscribe((serviceTimeOptions) => {
      this.serviceTimeOptions = serviceTimeOptions;

      if(!serviceTimeOptions || serviceTimeOptions && serviceTimeOptions.length === 0) return 
 
      // Convert the value in a selectable element
      let elementSelected = serviceTimeOptions.find((el: any) => el.key === valueSelected);
  
      // Update the end time select with new values
      this.settings.endPickUpTime.options = serviceTimeOptions.slice(
        this.settings.startPickUpTime.options.indexOf(elementSelected) + 3,
      );
  
      // Check if the end value is still valid
      let startPickUpTimeControl = this.fg.get('startPickUpTime');
      let endPickUpTimeControl = this.fg.get('endPickUpTime');
      
      const endPickUpTime = serviceTimeOptions.slice(3);

      let isEndHourInValidRange = endPickUpTime.find(
        (el: any) => el.key === endPickUpTimeControl?.value,
      );
  
      // Update end value
      if (startPickUpTimeControl?.valid && endPickUpTimeControl?.value && !isEndHourInValidRange) {
        this.fg.get('endPickUpTime')?.patchValue(null);
        this.combinationNotValid = true;
      }
    })
  }

  resetServiceTimeStyles(dayChangedMode?: boolean) {
    if(this.startServiceTimeComponent !== undefined){
      this.startServiceTimeComponent.resetValue();
    }
    if(this.endServiceTimeComponent !== undefined){
      this.endServiceTimeComponent.resetValue();
    }
    
    this.combinationNotValid = false;
    if (dayChangedMode && this.serviceTimeOptions.length > 0) {
      this.dayChangedFlag = true;
    } else {
      this.dayChangedFlag = false;
    }
  }
  countPickupErrors() {
    this.timesFailedPickupAddress = this.timesFailedPickupAddress + 1;
  }
  countDropoffErrors() {
    this.timesFailedDropoffAddress = this.timesFailedDropoffAddress + 1;
  }

  resetTimeAndErrors(){
    this.fg.get('startPickUpTime')?.patchValue(null);
    this.fg.get('endPickUpTime')?.patchValue(null);
    this.fg.get('specificTimeRange')?.patchValue(null);
    this.resetServiceTimeStyles();
    this.combinationNotValid = false;
  }


  getPriceAvailabilities(){
    const dateOfTheService = this.fg.get('pickupDate')?.value;
    this.resource.getVehicleReplacementDaysAndPrices(this.isB2B,this.zoneId, dateOfTheService)
    .subscribe((response: any) => {
      const responseFormatted = this.textFormatter.convertObjectToCamelCase(response,this.textFormatter.pascalCaseToCamelCase);
      this.settings.daysReplacement.options = responseFormatted.availabilitySlots;
    })
  }

  setMinimumPickUpDateOnTomorrow(){
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.settings.pickupDate.minDate = tomorrow;
  }
}