// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { GoogleMapsInitializerModel } from '@cafler/common-ui/lib/models/module-initializer.model';

export const environment = {
  production: false,
  applicationType: 'b2c',
  ordersManagerApiURL: 'https://products-api.development.cafler.com',
  apiURL: 'https://kamikaze-api.development.cafler.com',
  api10URL: 'https://cafler-business-dev.azurewebsites.net',
  trackingURL: 'https://tracking-api.development.cafler.com',
  launchDarkly: {
    sdkKey:'sdk-a858f22d-dfff-4216-ab65-1071510b385c',
    clientSideID: '62d65e7fe17847112255a833'
  },
  B2CProdUrl: 'https://products.cafler.com/',
  B2BUrl: 'https://products.cafler.com/',
  sentryEnvironment:"development",
  sentryDSN: "https://c11b51e051f84a8f8e8f1cc8aa2c568b@o970615.ingest.sentry.io/4505431803297792",
  assetsURL: 'caflerweb.azureedge.net',
  ownerShipChangeURL: 'https://tramites.development.cafler.com/',
  oauthAppId: {
    azureBc2: {
      appId: '24d347c4-8960-4d7c-a585-484bf3d80db6',
      tenantId: '38e0a06f-5e98-4f79-b789-9ac3f3c7638b',
      web: {
        redirectUrl: 'http://localhost:4200/login',
      },
      android: {
        redirectUrl: null,
      },
    },
  },
  googleMaps: <GoogleMapsInitializerModel>{
    apiKey: 'AIzaSyDkLJZSUDd4eJOjBJOJjFQxNlpgRODCxbI',
  },
  adyen: {
    apiUrl: 'https://kamikaze-api.development.cafler.com',
    clientKey: 'test_2BNGRYP2WZFSLE2AZ3AFQNRPNE3RA76V',
    apiKey:
      'AQEnhmfuXNWTK0Qc+iSRh3A3hOWdRI5fPr/AeDG4VH4vNNvE7sXpKJh1EMFdWw2+5HzctViMSCJMYAc=-S4ica4aLjwRWFghAg82Mw/696F9OM4PavFOVKQBU8lA=-+,TbTwLst<H:3g.9',
    environment: 'test',
    locale: 'es_ES',
    paymentMethodsConfiguration: {
      ideal: {
        showImage: true,
      },
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        name: 'Credit or debit card',
        amount: {
          value: 0,
          currency: 'EUR',
        },
      },
    },
  },
  translationSettings: {
    path: '/assets/translations/default/',
    defaultLanguage: 'es',
  },
  b2b10URL: 'http://localhost:3000',
  appInsights: {
    instrumentationKey: '705c99d9-e1e0-4cef-92a3-5d2037d08962'
  },
};
