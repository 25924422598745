
   
  const METERS_TO_KILOMETERS_FACTOR = 1000;
  const METERS_TO_MILES_FACTOR = 0.000621371;

  export function convertMetersToKilometers(meters: number): number {
    const kilometers: number = meters / METERS_TO_KILOMETERS_FACTOR;
    return kilometers;
  }

  export function convertMetersToMiles(meters: number): number {
    const miles: number = meters * METERS_TO_MILES_FACTOR;
    return miles;
  }
