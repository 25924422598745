<div class="close-icon-container">
  <caf-icon class="pointer" (click)="confirm()" [settings]="closeIcon"></caf-icon>
</div>
<div *ngIf="settings.icon" class="popup-overlay__header hide-on-medium">
  <caf-icon [settings]="settings.icon"></caf-icon>
</div>
<div class="popup-overlay__body">
  <span *ngIf="settings.title" [class]="settings.titleClasses" [innerHTML]="settings.title | translate" class="title-popup">
  </span>
  <span *ngIf="settings.description" [innerHTML]="settings.description | translate" class="description-popup"></span>
</div>

<div class="popup-overlay__footer">
  <caf-button
    *ngIf="settings.confirmButton"
    (onClick)="confirm()"
    [settings]="confirmButton"
    [class]="settings.confirmButtonClasses"
    class="margins"
  ></caf-button>
  <caf-button
    *ngIf="settings.cancelButton"
    (onClick)="cancel()"
    [settings]="cancelButton"
    [class]="settings.cancelButtonClasses"
    class="margins second-button"
  ></caf-button>
</div>
