<section class="container">
    <!-- TODO: change name -->
    <h4 class="title">{{ settings.title | translate }}</h4>
    <div class="sentence-container">
        <div *ngFor="let item of settings.sentences" class="sentence">
            <caf-icon *ngIf="item.icon" [settings]="item.icon"></caf-icon>
            <p>{{item.sentence | translate}}</p>
        </div>
    </div>
</section>
