


const workshopAtHome = {
    id: "car-service",
    title: "cafler.wash-details.car-service.title",
    description: "cafler.wash-details.car-service.description",
    howItWorks: [
        {
            icon: { name: 'caf-simple-calendar', alt: 'calendario'},
            sentence:"cafler.wash-details.howItWorks-sentence1"
        },
        {
            icon: { name: 'caf-pin-location-transparent', alt: 'localización'},
            sentence:"cafler.wash-details.howItWorks-sentence2"
        },
        {
            icon: { name: 'caf-maintenance-black', alt: 'mantenimiento'},
            sentence:"cafler.wash-details.howItWorks-sentence3"
        },
        {
            icon: { name: 'caf-paper', alt: 'papel'},
            sentence:"cafler.wash-details.howItWorks-sentence4"
        },
        {
            icon: { name: 'caf-person-icon', alt: 'papel'},
            sentence:"cafler.wash-details.howItWorks-sentence5"
        },
    ]
  }

  const fineTuning = {
    id: "fine-tunning",
    title: "cafler.wash-details.fine-tuning.title",
    description: "cafler.wash-details.fine-tuning.description",
    howItWorks: [
        {
            icon: { name: 'caf-simple-calendar', alt: 'calendario'},
            sentence:"cafler.wash-details.howItWorks-sentence1"
        },
        {
            icon: { name: 'caf-pin-location-transparent', alt: 'localización'},
            sentence:"cafler.wash-details.howItWorks-sentence2"
        },
        {
            icon: { name: 'caf-maintenance-black', alt: 'mantenimiento'},
            sentence:"cafler.wash-details.howItWorks-sentence3"
        },
        {
            icon: { name: 'caf-paper', alt: 'papel'},
            sentence:"cafler.wash-details.howItWorks-sentence4"
        },
        {
            icon: { name: 'caf-person-icon', alt: 'papel'},
            sentence:"cafler.wash-details.howItWorks-sentence5"
        },
    ]
  }

  const bundleMaintenance = {
    id: "maintenance-bundle",
    title: "cafler.wash-details.bundle-maintenance.title",
    description: "cafler.wash-details.bundle-maintenance.description",
    howItWorks: [
        {
            icon: { name: 'caf-simple-calendar', alt: 'calendario'},
            sentence:"cafler.wash-details.howItWorks-sentence1"
        },
        {
            icon: { name: 'caf-pin-location-transparent', alt: 'localización'},
            sentence:"cafler.wash-details.howItWorks-sentence2"
        },
        {
            icon: { name: 'caf-maintenance-black', alt: 'mantenimiento'},
            sentence:"cafler.wash-details.howItWorks-sentence3"
        },
        {
            icon: { name: 'caf-paper', alt: 'papel'},
            sentence:"cafler.wash-details.howItWorks-sentence4"
        },
    ],
    whatIncludesTitle: "cafler.service.maintenance-details.whatIncludes.title",
    whatIncludes: [
        "cafler.mechanical-inspection.features.oil-change",
        "cafler.mechanical-inspection.features.oil-filter-change",
        "cafler.mechanical-inspection.features.air-filter-change",
        "cafler.mechanical-inspection.features.fuel-filter-change",
        "cafler.mechanical-inspection.features.cabin-filter-change",
        "cafler.mechanical-inspection.features.review",
    ]
  }

  export function getProductDetails(prodcut: string){
    switch (prodcut) {
        case "car-service":
            return workshopAtHome;
        case "fine-tunning":
            return fineTuning;
        case "maintenance-bundle":
            return bundleMaintenance;
        default:
            return null;
    }
}