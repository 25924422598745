import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KamikazeActions } from '../../store/actions';
import { Store } from '@ngrx/store';
import { first, pairwise, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { selectCurrentZone, selectIsB2B, selectProductData } from '../../store/selectors';
import { NavigationService } from '../../services/navigation.service';
import { FormsHelperService } from '../../services/forms.service';
import { PresentationFacade } from '../../facades/presentation.facade';
import { datepickerMaxDate } from '../../services/utils';
import {
  CaflerErrorSettingsModel,
  CaflerIconSettingsModel,
  GoogleMapsService,
} from '@cafler/common-ui';
import { SingleDataHelperService } from 'src/app/helpers/single-data-helper.service';

@Component({
  selector: 'app-revision-form',
  templateUrl: './revision-form.page.html',
  styleUrls: ['./revision-form.page.scss'],
})
export class RevisionFormPage implements OnInit, OnDestroy {
  isLoading: boolean = true;
  private readonly destroySubjects$ = new ReplaySubject<void>(1);
  fg!: FormGroup;
  isB2B: boolean = false;
  isSameDeliveryAddress!: boolean;
  timesFailedPickupAddress = 0;
  timesFailedDropoffAddress = 0;
  maxErrorsOnAddress = 4;

  combinationNotValid: boolean = false;
  serviceTimeOptions = [];
  readyToDisplayHours = false;
  dayChangedFlag = false;
  @ViewChild('startServiceTimeComponent') startServiceTimeComponent: any;
  @ViewChild('endServiceTimeComponent') endServiceTimeComponent: any;

  settings: any = {
    serviceType: {
      id: 'serviceType',
      formControlName: 'serviceType',
      placeholder: 'Tipo de revisión',
      disabled: false,
      options: [],
    },
    fullName: {
      id: 'fullName',
      formControlName: 'fullName',
      placeholder: 'cafler.contact.name.placeholder',
      disabled: false,
    },
    countryPhoneCode: {
      id: 'countryPhoneCode',
      formControlName: 'countryPhoneCode',
      placeholder: 'cafler.contact.country-phone-code.placeholder',
      disabled: false,
      options: [],
    },
    phone: {
      id: 'phone',
      formControlName: 'phone',
      placeholder: 'cafler.contact.phone.placeholder',
      disabled: false,
      type: 'phone',
    },
    email: {
      id: 'email',
      formControlName: 'email',
      placeholder: 'cafler.contact.email.placeholder',
      disabled: false,
      type: 'email',
    },
    carPlaque: {
      id: 'carPlaque',
      formControlName: 'carPlaque',
      placeholder: 'cafler.vehicle-info.plate.placeholder',
      disabled: false,
      type: 'text',
    },
    // totalVehicleKilometers: {
    //   id: 'totalVehicleKilometers',
    //   formControlName: 'totalVehicleKilometers',
    //   placeholder: 'cafler.vehicle-info.total-vehicle-kms.placeholder',
    //   type: 'number',
    //   disabled: false,
    // },
    brand: {
      id: 'brand',
      formControlName: 'brand',
      placeholder: 'cafler.vehicle-info.brand.placeholder',
      disabled: false,
      required: false,
      type: 'text',
    },
    model: {
      id: 'model',
      formControlName: 'model',
      placeholder: 'cafler.vehicle-info.model.placeholder',
      disabled: false,
      required: false,
      type: 'text',
    },
    pickupAddress: {
      id: 'pickupAddress',
      formControlName: 'pickupAddress',
      placeholder: 'cafler.pickup-info.pickup-address.placeholder',
      options: { businessSuggestions: true, placeSuggestions: true },
    },
    pickupAddressDetails: {
      id: 'pickupAddressDetails',
      formControlName: 'pickupAddressDetails',
      placeholder: 'cafler.pickup-info.pickup-details.placeholder',
      disabled: false,
      required: false,
    },
    sameDeliveryAddress: {
      id: 'sameDeliveryAddress',
      formControlName: 'sameDeliveryAddress',
      label: 'cafler.page.wash.same-address-delivery',
      disabled: false,
    },
    pickupDate: {
      id: 'pickupDate',
      formControlName: 'pickupDate',
      placeholder: 'cafler.pickup-info.pickup-day.placeholder',
      minDate: new Date(),
      maxDate: datepickerMaxDate(),
      icon: <CaflerIconSettingsModel>{
        alt: 'calendar',
        name: 'caf-notes-book-dark',
      },
    },
    dropoffAddress: {
      id: 'dropoffAddress',
      formControlName: 'dropoffAddress',
      placeholder: 'cafler.pickup-info.pickup-address.placeholder',
      options: { businessSuggestions: true, placeSuggestions: true },
      required: false,
    },
    dropoffAddressDetails: {
      id: 'dropoffAddressDetails',
      formControlName: 'dropoffAddressDetails',
      placeholder: 'cafler.pickup-info.pickup-details.placeholder',
      disabled: false,
      required: false,
    },
    startPickUpTime: {
      id: 'startPickUpTime',
      formControlName: 'startPickUpTime',
      placeholder: 'cafler.page.service-time.from-placeholder',
      disabled: false,
      options: [],
      required: true,
      leftIconSettings: {
        name: 'caf-clock-cafler',
        alt: 'Clock icon',
        height: '25px',
        width: '25px',
      },
    },
    endPickUpTime: {
      id: 'endPickUpTime',
      formControlName: 'endPickUpTime',
      placeholder: 'cafler.page.service-time.until-placeholder',
      disabled: true,
      options: [],
      required: true,
      leftIconSettings: {
        name: 'caf-clock-cafler',
        alt: 'Clock icon',
        height: '25px',
        width: '25px',
      },
    },
    additionalComments: {
      id: 'additionalComments',
      formControlName: 'additionalComments',
      placeholder: 'cafler.pickup-info.textarea.comments',
      minlength: 0,
      maxlength: 200,
      disabled: false,
      required: false,
    },
  };
  settingsExtra: any = {
    locationIcon: <CaflerIconSettingsModel>{
      alt: 'location',
      name: 'caf-pin-location',
    },
  };
  settingsErrors: any = {
    errorsFullName: <CaflerErrorSettingsModel>{
      formControlName: 'fullName',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsCountryPhoneCode: <CaflerErrorSettingsModel>{
      formControlName: 'countryPhoneCode',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsPhone: <CaflerErrorSettingsModel>{
      formControlName: 'phone',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'pattern', text: 'cafler.forms.error.phone-pattern' },
        { key: 'minlength', text: 'cafler.forms.error.min-length' },
      ],
    },
    errorsEmail: <CaflerErrorSettingsModel>{
      formControlName: 'email',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'email', text: 'cafler.forms.error.email' },
      ],
    },
    errorsCarPlaque: <CaflerErrorSettingsModel>{
      formControlName: 'carPlaque',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' },{key:'invalidLicensePlate', text: 'cafler.forms.error.invalid-license-plate'}],
    },
    errorsPickupDate: <CaflerErrorSettingsModel>{
      formControlName: 'pickupDate',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'notPresent', text: 'cafler.forms.error.date-not-in-past' },
      ],
    },
    errorsPickupAddress: <CaflerErrorSettingsModel>{
      formControlName: 'pickupAddress',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'selectedAddress', text: 'cafler.forms.error.selected-address' },
        {
          key: 'streetNumberNeeded',
          text: 'cafler.address-message-error-missing-street-number.title',
        },

        { key: 'addressNumber', text: 'cafler.forms.error.address-number' },
        {
          key: 'checkCoordinatesWithinCaflerArea',
          text: 'cafler.forms.error.cafler-country-coverage',
        },
      ],
    },
    errorsDropoffAddress: <CaflerErrorSettingsModel>{
      formControlName: 'dropoffAddress',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'selectedAddress', text: 'cafler.forms.error.selected-address' },
        {
          key: 'streetNumberNeeded',
          text: 'cafler.address-message-error-missing-street-number.title',
        },

        { key: 'addressNumber', text: 'cafler.forms.error.address-number' },
        {
          key: 'checkCoordinatesWithinCaflerArea',
          text: 'cafler.forms.error.cafler-country-coverage',
        },
      ],
    },
    errorsStartPickUpTime: <CaflerErrorSettingsModel>{
      formControlName: 'startPickUpTime',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsEndPickUpTime: <CaflerErrorSettingsModel>{
      formControlName: 'endPickUpTime',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
  };
  currentRegion: string = 'es';

  constructor(
    private store: Store,
    private navigationService: NavigationService,
    private presentationFacade: PresentationFacade,
    private formsService: FormsHelperService,
    private googleMapsService: GoogleMapsService,
    private singleDataHelperService: SingleDataHelperService,

  ) {
    this.fg = this.formsService.initializeForm(this.settings);
    this.store.select(selectCurrentZone).subscribe((zone) => {
      if (zone) {
        const bbox = googleMapsService.getBoundingBox(
          zone?.UpperRightBoundingBoxCenterLatitude,
          zone?.UpperRightBoundingBoxCenterLongitude,
          zone?.LowerLeftBoundingBoxCenterLatitude,
          zone?.LowerLeftBoundingBoxCenterLongitude,
        );
        this.settings.pickupAddress.options.bounds = bbox;
        this.settings.dropoffAddress.options.bounds = bbox;

        // setting default prefix code number
        this.fg.get('countryPhoneCode')?.setValue(zone.DefaultPhonePrefix);
      }
      this.fg.get('sameDeliveryAddress')?.setValue(false);
      this.fg.get('dropoffAddress')?.clearValidators();
      this.fg.get('dropoffAddress')?.updateValueAndValidity();
    });
  }

  ngOnInit() {
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: true });
    this.store.dispatch({ type: KamikazeActions.SetNextPage, nextPage: 'overview' });
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'revision-form' });
    this.store.dispatch({ type: KamikazeActions.ClearAvailableToppings });

    this.store.dispatch({
      type: KamikazeActions.SetFormValidity,
      isFormValid: this.formsService.checkCurrentFormValidity(),
    });

    this.navigationService.scrollToTop();

    this.store.select(selectIsB2B).subscribe((isB2B) => {
      this.isB2B = isB2B;
    });

    this.fg
      .get(this.settings.pickupDate.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .pipe(first())
      .subscribe(() => {
        this.getSlotHours();
      });

    this.fg
      .get(this.settings.pickupDate.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .pipe(pairwise())
      .subscribe(([prev, next]) => {
        if (prev.getTime() !== next.getTime()) {
          this.getSlotHours();
        }
      });

    this.fg
      .get(this.settings.startPickUpTime.formControlName)
      ?.statusChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((status) => {
        if (status === 'VALID') {
          this.settings.endPickUpTime.disabled = false;
          this.dayChangedFlag = false;
        } else {
          this.settings.endPickUpTime.disabled = true;
        }
      });

    this.fg
      .get(this.settings.endPickUpTime.formControlName)
      ?.statusChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((status) => {
        if (status === 'VALID') {
          this.combinationNotValid = false;
        }
      });

    this.fg
      .get(this.settings.startPickUpTime.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((newValue) => {
        this.updateEndRangeHours(newValue);
      });

    this.presentationFacade
      .getCountryCodes()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((countryCodes) => {
        this.settings.countryPhoneCode.options = countryCodes;
      });

    this.singleDataHelperService
      .getCurrentVerticalProducts()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((products) => {
        this.settings.serviceType.options = products.map((product: any) => {
          return { key: product.id, text: product.title, alias: product.alias };
        });
        this.fg.controls.serviceType.setValue(this.navigationService.getSelectedProduct());
      });

    this.store
      .select(selectProductData)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((productData) => {
        if (this.isLoading) {
          if (!!productData) {
            this.formsService.loadFormGroupData(this.fg, productData, this.isLoading);
            this.isSameDeliveryAddress = productData.sameDeliveryAddress;
            this.isLoading = false;
          } else {
            if (this.isB2B) {
              this.formsService.preloadB2BData(this.fg);
            }
            const selectedProduct = this.settings.serviceType.options.find((serviceType: any) => {
              return serviceType.alias === this.navigationService.getSelectedProduct();
            });
            this.fg.controls.serviceType.setValue(selectedProduct.key);
            this.navigationService.currentPageTitle = selectedProduct.text;
          }
        }
      });

    this.presentationFacade.getHolidays().subscribe((holidays) => {
      let holidaysDates = holidays.map((day: any) => {
        return new Date(day);
      });

      this.settings.pickupDate = {
        ...this.settings.pickupDate,
        datesDisabled: holidaysDates,
      };
    });

    this.fg.statusChanges.pipe(takeUntil(this.destroySubjects$)).subscribe((status) => {
      this.formsService.handleFormValidity(status, this.fg.value);
    });

    this.fg
      .get('sameDeliveryAddress')
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((value: boolean) => {
        this.isSameDeliveryAddress = value;
        if (this.isSameDeliveryAddress) {
          this.formsService.addValidatorsAutosuggest(this.fg, 'dropoffAddress');
          this.fg.get('dropoffAddress')?.updateValueAndValidity();
        } else {
          this.fg.get('dropoffAddress')?.clearValidators();
          this.fg.get('dropoffAddress')?.setValue('');
          this.fg.get('dropoffAddressDetails')?.setValue('');
          this.fg.get('dropoffAddress')?.updateValueAndValidity();
        }
      });
  }

  getSlotHours() {
    this.presentationFacade
      .getPickupTimeOptions(
        this.fg.get(this.settings.pickupDate.formControlName)?.value,
        this.fg.value.serviceType,
      )
      .pipe(first())
      .subscribe((pickupTimeOptions: any) => {
        this.serviceTimeOptions = pickupTimeOptions;
        this.settings.startPickUpTime.options = this.serviceTimeOptions.slice(0, -1);
        this.settings.endPickUpTime.options = this.serviceTimeOptions.slice(1);

        if (this.readyToDisplayHours) {
          this.resetServiceTimeStyles();
        }
        this.readyToDisplayHours = true;
      });
  }
  getB2BAddress(formControlName: string) {
    this.presentationFacade
      .getAddressB2BToAutosuggest()
      .pipe(first())
      .subscribe((addressObj) => {
        this.fg.get(formControlName)?.setValue(addressObj);
      });
  }
  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }
  updateEndRangeHours(valueSelected: any) {
    // Convert the value in a selectable element
    let elementSelected = this.serviceTimeOptions.find((el: any) => el.key === valueSelected);

    // Update the end time select with new values
    this.settings.endPickUpTime.options = this.serviceTimeOptions.slice(
      this.settings.startPickUpTime.options.indexOf(elementSelected) + 1,
    );

    // Check if the end value is still valid
    let endPickUpTimeControl = this.fg.get('endPickUpTime');
    let isEndHourInValidRange = this.settings.endPickUpTime.options.find(
      (el: any) => el.key === endPickUpTimeControl?.value,
    );

    // Update end value
    if (endPickUpTimeControl?.valid && !isEndHourInValidRange) {
      this.fg.get('endPickUpTime')?.patchValue(null);
      this.combinationNotValid = true;
    }
  }
  resetServiceTimeStyles() {
    this.startServiceTimeComponent.resetValue();
    this.endServiceTimeComponent.resetValue();
    this.combinationNotValid = false;
    if (this.serviceTimeOptions.length > 0) {
      this.dayChangedFlag = true;
    } else {
      this.dayChangedFlag = false;
    }
  }

  countPickupErrors() {
    this.timesFailedPickupAddress = this.timesFailedPickupAddress + 1;
  }

  countDropoffErrors() {
    this.timesFailedDropoffAddress = this.timesFailedDropoffAddress + 1;
  }
}
