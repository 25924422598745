import { ToppingSelectorModel } from "src/app/models/fe/topping-selector.model";
import { ToppingEnum } from "../../Enums/topping.enum";


export const allToppingProducts = new Map<string, ToppingSelectorModel>([
    [ToppingEnum.handWash_interior, {
        id: '',
        title: 'cafler.toppings.washing.handwash.interior.title',
        toppingCode: ToppingEnum.handWash_interior,
        serviceDuration: '',
        category: 'handWash',
        description: '',
        price: '',
        disabled: false,
        checked: false,
        additionalInformation: [
          'cafler.toppings.washing.features.deep-aspirate', 
          'cafler.toppings.washing.features.carpet-aspirate',
          'cafler.toppings.washing.features.interior-windows-clean',
          'cafler.toppings.washing.features.door-clean',
          'cafler.toppings.washing.features.frame-door-clean',
          'cafler.toppings.washing.features.interior-plastic-clean',
          'cafler.toppings.washing.features.dashboard-console-clean',
          'cafler.toppings.washing.features.trunk-clean',
          'cafler.toppings.washing.features.disinfection-complete',
        ],
      }],
    [ToppingEnum.handWash_exterior,  {
        id: '',
        title: 'cafler.toppings.washing.handwash.exterior.title',
        serviceDuration: '',
        toppingCode: ToppingEnum.handWash_exterior,
        category: 'handWash',
        description: '',
        price: '',
        checked: false,
        disabled: false,
        additionalInformation: [
          'cafler.toppings.washing.features.bodywork-clean-complete',
          'cafler.toppings.washing.features.exterior-windows-clean',
          'cafler.toppings.washing.features.protection-wax',
          'cafler.toppings.washing.features.glossy-finish',
          'cafler.toppings.washing.features.disinfection-complete',
        ],
      }],
    [ToppingEnum.handWash_complete, {
        id: '',
        title: 'cafler.toppings.washing.handwash.complete.title',
        serviceDuration: '',
        toppingCode: ToppingEnum.handWash_complete,
        category: 'handWash',
        description: '',
        price: '',
        disabled: false,
        checked: false,
        additionalInformation: [
          'cafler.toppings.washing.features.deep-aspirate', 
          'cafler.toppings.washing.features.carpet-aspirate',
          'cafler.toppings.washing.features.interior-windows-clean',
          'cafler.toppings.washing.features.door-clean',
          'cafler.toppings.washing.features.frame-door-clean',
          'cafler.toppings.washing.features.interior-plastic-clean',
          'cafler.toppings.washing.features.dashboard-console-clean',
          'cafler.toppings.washing.features.trunk-clean',
          'cafler.toppings.washing.features.bodywork-clean-complete',
          'cafler.toppings.washing.features.exterior-windows-clean',
          'cafler.toppings.washing.features.protection-wax',
          'cafler.toppings.washing.features.glossy-finish',
          'cafler.toppings.washing.features.disinfection-complete',
        ],
      }],
    [ToppingEnum.expressWash_interior, {
        id: '',
        title: 'cafler.toppings.washing.expresswash.interior.title',
        serviceDuration: '',
        category: 'expressWash',
        toppingCode: ToppingEnum.expressWash_interior,
        description: '',
        price: '',
        disabled: false,
        checked: false,
        additionalInformation: [
          'cafler.toppings.washing.features.seat-aspirate',
          'cafler.toppings.washing.features.carpet-aspirate',
          'cafler.toppings.washing.features.interior-windows-clean',
          'cafler.toppings.washing.features.door-clean',
          'cafler.toppings.washing.features.dashboard-clean',
          'cafler.toppings.washing.features.trunk-clean',
        ],
      }],
    [ToppingEnum.expressWash_exterior, {
        id: '',
        title: 'cafler.toppings.washing.expresswash.exterior.title',
        serviceDuration: '',
        category: 'expressWash',
        toppingCode: ToppingEnum.expressWash_exterior,
        description: '',
        price: '',
        disabled: false,
        checked: false,
        additionalInformation: [
          'cafler.toppings.washing.features.bodywork-clean',
          'cafler.toppings.washing.features.exterior-windows-clean',
          'cafler.toppings.washing.features.protection-wax',
          'cafler.toppings.washing.features.glossy-finish',
        ],
      }],
    [ToppingEnum.expressWash_complete, {
        id: '',
        title: 'cafler.toppings.washing.expresswash.complete.title',
        serviceDuration: '',
        category: 'expressWash',
        toppingCode: ToppingEnum.expressWash_complete,
        description: '',
        price: '',
        disabled: false,
        checked: false,
        additionalInformation: [
          'cafler.toppings.washing.features.seat-aspirate',
          'cafler.toppings.washing.features.carpet-aspirate',
          'cafler.toppings.washing.features.interior-windows-clean',
          'cafler.toppings.washing.features.door-clean',
          'cafler.toppings.washing.features.dashboard-clean',
          'cafler.toppings.washing.features.trunk-clean',
          'cafler.toppings.washing.features.bodywork-clean',
          'cafler.toppings.washing.features.exterior-windows-clean',
          'cafler.toppings.washing.features.protection-wax',
          'cafler.toppings.washing.features.glossy-finish',
        ],
      }],
    [
      ToppingEnum.tunnelWash_interior, {
        id: '',
        title: 'cafler.toppings.washing.tunnelwash.interior.title',
        serviceDuration: '',
        category: 'tunnelWash',
        toppingCode: ToppingEnum.tunnelWash_interior,
        description: '',
        price: '',
        disabled: false,
        checked: false,
        additionalInformation: [
          "cafler.toppings.washing.features.seat-aspirate",
          "cafler.toppings.washing.features.carpet-aspirate",
          "cafler.toppings.washing.features.interior-windows-clean",
          "cafler.toppings.washing.features.door-clean",
          "cafler.toppings.washing.features.dashboard-clean",
          "cafler.toppings.washing.features.trunk-clean"
        ],
        isNew: true
    }],
    [
      ToppingEnum.tunnelWash_exterior, {
        id: '',
        title: 'cafler.toppings.washing.tunnelwash.exterior.title',
        serviceDuration: '',
        category: 'tunnelWash',
        toppingCode: ToppingEnum.tunnelWash_exterior,
        description: '',
        price: '',
        disabled: false,
        checked: false,
        additionalInformation: [
          'cafler.toppings.washing.features.rotating-brushes',
          'cafler.toppings.washing.features.air-dry'
        ],
    }],
    [
      ToppingEnum.tunnelWash_complete, {
        id: '',
        title: 'cafler.toppings.washing.tunnelwash.complete.title',
        serviceDuration: '',
        category: 'tunnelWash',
        toppingCode: ToppingEnum.tunnelWash_complete,
        description: '',
        price: '',
        disabled: false,
        checked: false,
        additionalInformation: [
          "cafler.toppings.washing.features.trunk-clean",
          "cafler.toppings.washing.features.air-drying-clean",
          "cafler.toppings.washing.features.seat-aspirate",
          "cafler.toppings.washing.features.interior-windows-clean",
          "cafler.toppings.washing.features.door-clean",
          "cafler.toppings.washing.features.dashboard-clean",
          "cafler.toppings.washing.features.trunk-clean"
        ],
        isNew: true
    }],
    [
      ToppingEnum.tunnelWash_motorcycle, {
        id: '',
        title: 'cafler.toppings.washing.tunnelwash.motorcycle.title',
        serviceDuration: '',
        category: 'tunnelWash',
        toppingCode: ToppingEnum.tunnelWash_motorcycle,
        description: '',
        price: '',
        disabled: false,
        checked: false,
        additionalInformation: [
          "cafler.toppings.washing.features.bodywork-wash",
          "cafler.toppings.washing.features.upholstery-wash",
          "cafler.toppings.washing.features.pipe.leakage-wash",
          "cafler.toppings.washing.features.headlamps-lights-wash",
          "cafler.toppings.washing.features.tyre-wash",
        ],
        isNew: true
    }]
]);
