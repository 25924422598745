import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'popup-before-service-starts',
  templateUrl: './popup-before-service-starts.component.html',
  styleUrls: ['./popup-before-service-starts.component.scss']
})
export class PopupBeforeServiceStartsComponent implements OnInit {
  @Input() settings: any = <any>{}

  constructor() { }

  ngOnInit(): void {
  }

}
