import { Injectable } from '@angular/core';
import { CaflerProductCardSettingsModel, GoogleMapsService } from '@cafler/common-ui';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, first, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  bootstrappingApp,
  bootstrappingAppSuccess,
  initApp,
  KamikazeActions,
  setCurrentCountry,
  setCurrentLang,
  setCurrentVertical,
  setCurrentZone,
  setIsB2B,
  setSelectedProduct,
  storeProductType,
  storeProductTypeFromUrl,
} from '../store/actions';
import {
  selectCoreData,
  selectCurrentCountry,
  selectCurrentVerticalProducts,
  selectIsAppInitialized,
  selectIsB2B,
  selectMotStations,
  selectStations,
} from '../store/selectors';
import { NavigationService } from './navigation.service';
import { getNavigatorLang } from './utils';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SingleDataHelperService } from '../helpers/single-data-helper.service';

const pascalCasePattern = new RegExp('^([A-Z])([a-z]+)');

@Injectable()
export class HelperService {
  constructor(
    private googlemapsService: GoogleMapsService,
    private navigationService: NavigationService,
    private store: Store,
    private translate: TranslateService,
    private router: Router,
    private singleDataHelperService: SingleDataHelperService,
  ) {
    this.store.select(selectCoreData).subscribe((coreData: any) => {
      if (coreData) {
        this.enums = coreData.Enums;
      }
    });

    this.store.select(selectIsB2B).subscribe((isB2B: any) => {
      if (isB2B) {
        this.isB2B = isB2B;
      }
    });

    this.store
      .select(selectMotStations)
      .pipe()
      .subscribe((stations) => {
        if (stations) {
          this.stationsMOT = stations;
        }
      });

    this.store.select(selectStations).subscribe((stations) => {
      this.stations = stations;
    });

    this.store.select(selectCurrentCountry).subscribe((currentCountry: any) => {
      this.currentCountry = currentCountry;
    });

    singleDataHelperService
      .getCurrentVerticalProducts()
      .subscribe((currentVerticalProducts: any) => {
        if (currentVerticalProducts && this.isProductInUrl) {
          //isProductInUrl is a flag only for the fist time
          this.isProductInUrl = null;

          // this.currentVerticalProducts = currentVerticalProducts;
          currentVerticalProducts.forEach((product: any) => {
            if (product.alias === this.isProductInUrl) {
              let productType = this.getVerticalById(this.vertical);
              this.store.dispatch(storeProductTypeFromUrl({ productType }));
              this.store.dispatch(setSelectedProduct({ selectedProduct: product }));
              if (product.alias.indexOf('wash') > -1) {
                // this.navigationService.currentPageTitle = "cafler.product-type." + product.Title + ".title";
                this.navigationService.currentPageTitle = product.title;
              }
              if (product.alias.indexOf('itv') > -1) {
                this.navigationService.currentPageTitle = 'cafler.product-type.mot.title';
              }
            }
          });
        }
      });
  }

  enums!: any;
  currentVerticalProducts!: any[];
  isProductInUrl: any;
  zoneName!: string;
  vertical!: any;
  product!: any;
  isB2B!: boolean;
  currentCountry: any;
  stationsMOT!: any[];
  stations!: any[];

  getStationMOTAddress(stationId: string): string {
    return this.stationsMOT.find((station: any) => station.Id === stationId).Address;
  }

  getStationName(stationId: string): string {
    return this.stations.find((item: any) => item.StationId === stationId).StationName;
  }

  inIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  checkIsB2B(): Observable<boolean> {
    // create a new behaviour subject with the initial value of false
    const x = new BehaviorSubject(false);
    if (this.inIframe()) {
      this.navigationService.getB2B10DataAsync().subscribe((res: any) => {
        if (res?.userId) {
          this.store.dispatch(setIsB2B({ isB2B: true, userData: res }));
          x.complete();
        }
      });
      return x.asObservable();
    } else {
      this.store.dispatch(setIsB2B({ isB2B: false, userData: null }));
      return of(true);
    }
  }

  setZoneAndRegionFromName(zoneName: string): Observable<any> {
    const testSubject$ = new BehaviorSubject({});
    this.store.select(selectCoreData).subscribe((coreData: any) => {
      coreData.Regions.forEach((region: any) => {
        region.Zones.forEach((zone: any) => {
          if (zone.Name === zoneName) {
            testSubject$.next({ zone: zone, region: region });
            // this.store.dispatch(setCurrentCountry({currentCountry: region}));
            // this.store.dispatch(setCurrentZone({currentZone: zone}));
          }
        });
      });
    });
    return testSubject$.asObservable();
  }

  injectGoogleMaps() {
    this.googlemapsService.injectGoogleMapsMainScript();
    this.googlemapsService.injectionSubject().subscribe((isMapsInjected: boolean) => {
      if (isMapsInjected) {
        this.store
          .select(selectIsAppInitialized)
          .pipe(first())
          .subscribe((isAppInitialized) => {
            // if (!isAppInitialized){
            this.store.dispatch(initApp());
            // }
            // this.store.dispatch({
            //   type: KamikazeActions.BootstrappingAppSuccess,
            //   isB2B: environment.applicationType === 'b2b'
            // });
          });
      }
    });
  }
  initTranslations(): Observable<boolean> {
    this.translate.setDefaultLang('en-UK');
    // translate.use('en-UK')
    let lang = getNavigatorLang();
    this.store.dispatch(setCurrentLang({ currentLang: lang }));

    // return of(true).pipe(delay(5000),tap(() => {console.log('translate')}));
    return of(true);
  }

  readIfThereIsAZoneInTheUrl() {
    let url = this.router.url;
    this.zoneName = url.split('/')[1];
    this.vertical = url.split('/')[2];
    this.product = url.split('/')[3];

    if (!this.isB2B && this.zoneName) {
      this.setZoneAndRegionFromName(this.zoneName).subscribe((res: any) => {
        this.store.dispatch(setCurrentCountry({ currentCountry: res.region }));
        this.store.dispatch(setCurrentZone({ currentZone: res.zone }));
      });
    }
    if (!this.isB2B && this.vertical && this.vertical === 'transfer') {
      this.store.dispatch(setCurrentVertical({ selectedVertical: 1 }));
    }
    if (!this.isB2B && this.vertical && this.vertical !== 'transfer') {
      this.enums[3].EnumItems.forEach((item: any) => {
        if (item.Name === this.vertical) {
          this.store.dispatch(setCurrentVertical({ selectedVertical: item.Value }));
        }
      });
    }
    if (!this.isB2B && this.product) {
      this.isProductInUrl = this.product;
    }
  }

  getBookedOrderLegacyFormat(orderInNewFormat: any) {
    return {
      AvailableToppings: orderInNewFormat.AvailableToppings,
      OrderHash: orderInNewFormat.OrderHash,
      ProductPrice: {
        TotalPrice: orderInNewFormat.Products[0].ProductPrice,
        TaxFreePrice: orderInNewFormat.Products[0].FreeTaxProductPrice,
        Currency: this.currentCountry?.PreferredRegionCurrency,
        CurrencySymbol: this.currentCountry?.PreferredRegionCurrencySymbol,
      },
      FixedTransferPrice: orderInNewFormat.FixedTransferPrice,
      ToppingPrice: null,
      IsPaymentRequired: orderInNewFormat.IsPaymentRequired,
      CaflerFee: {
        CaflerFeePrice: {
          TotalPrice: orderInNewFormat.ServicePrice,
          TaxFreePrice: orderInNewFormat.FreeTaxServicePrice,
          Currency: this.currentCountry?.PreferredRegionCurrency,
          CurrencySymbol: this.currentCountry?.PreferredRegionCurrencySymbol,
        },
      },
      OrderPrice: {
        TotalPrice: orderInNewFormat.OrderPrice,
        TaxFreePrice: orderInNewFormat.FreeTaxOrderPrice,
        Currency: this.currentCountry?.PreferredRegionCurrency,
        CurrencySymbol: this.currentCountry?.PreferredRegionCurrencySymbol,
      },
      ToppingFeesDiscount: null,
      PaymentData: {
        OrderHash: orderInNewFormat.OrderHash,
      },
      DiscountPrice: {
        TotalPrice: orderInNewFormat.DiscountAmount,
      },
      CrossSellingProductsAdded: [],
      AllProductsBooked: orderInNewFormat.Products,
      ZoneTax: orderInNewFormat.ZoneTax,
      couponCode: orderInNewFormat.CouponCode,
      FreeTaxFixedTransferPrice: orderInNewFormat.FreeTaxFixedTransferPrice,
      Distance: orderInNewFormat.Distance,
    };
  }

  // T0DO codigo repetido
  public getVerticalById(verticalId: number) {
    if (verticalId + '' === 'wash') {
      return 3;
    }
    if (verticalId + '' === 'mot') {
      return 7;
    }
    if (verticalId + '' === 'transfer') {
      return 1;
    }
    return 0;
    // const verticals: any = {
    //   1: 'transfer',
    //   2: 'revision',
    //   3: 'wash',
    //   4: 'refueling',
    //   5: 'pre-itv',
    //   6: 'medium-long-distance',
    //   7: 'mot',
    // };
    // return verticals[verticalId];
  }
}
