<div class="container">
    <p class="breadcrumb"><span (click)="back()" class="pointer">{{'cafler.toppings.breadbcrumb.main-menu' | translate }}</span><caf-icon class="rotate-chevron" [settings]="arrowIcon"></caf-icon> {{'cafler.toppings.breadbcrumb.insurance-menu' | translate}}</p>
    

    <div class="title-container">
      <h3 class="subtitle">{{'cafler.toppings.insurance.number-of-days.label' | translate }}</h3>
    </div>
    
    <div class="product-container">
      <caf-notification *ngIf="showError" [settings]="errorSettings"></caf-notification>

      <div class="days-container">
        <div class="days-group">
          <div (click)="chooseDays(1)" class="insurance-radio-btn"
                [ngClass]="amountOfDays === 1 ? 'selected' : ''">
            <span>1 día</span><span *ngIf="!isB2B">14,99€</span><span *ngIf="isB2B">14,99€*</span>
          </div>
          <div (click)="chooseDays(2)" class="insurance-radio-btn"
                [ngClass]="amountOfDays === 2 ? 'selected' : ''">
            <span>2 días</span><span *ngIf="!isB2B">24,99€</span><span *ngIf="isB2B">24,99€*</span>
          </div>
          <div (click)="chooseDays(3)" class="insurance-radio-btn" 
                [ngClass]="amountOfDays === 3 ? 'selected' : ''">
            <span>3 días</span><span *ngIf="!isB2B">32,99€</span><span *ngIf="isB2B">32,99€*</span>
          </div>
        </div>
        <p *ngIf="isB2B" class="iva-disclaimer">*{{'cafler.tax-fee-prices-included' | translate}}</p>
      </div>
      <div>
        <h3 class="subtitle">{{'cafler.toppings.insurance.data-label' | translate }}</h3>  
        <div *ngIf="fg">
          <div [formGroup]="fg">
            <div class="input-data">
              <caf-input [settings]="formSettings.name"></caf-input>
              <caf-error [settings]="settingsErrors.errorsName"></caf-error>
            </div>
            <div class="input-data">
              <caf-input [settings]="formSettings.lastName" (onChange)="changeDocumentType($event)"></caf-input>
              <caf-error [settings]="settingsErrors.errorsLastName"></caf-error>
            </div>
            <caf-select [settings]="formSettings.personalIDType" (onChange)="changeDocumentType($event)"></caf-select>
            <div class="input-data">
              <caf-input [settings]="formSettings.personalIDNumber" ></caf-input>
              <caf-error [settings]="settingsErrors.errorsPersonalIDNumber"></caf-error>
            </div>
            <h3 class="subtitle">{{'cafler.my-service-details.service-data.title' | translate }}</h3>  
            <div class="input-data">
              <caf-select [settings]="formSettings.licenseCountry"></caf-select>
              <caf-error [settings]="settingsErrors.errorsLicenseCountry"></caf-error>
            </div>
            <div class="input-data">
              <caf-input [settings]="formSettings.licensePlate" (onChange)="changeDocumentType($event)"></caf-input>
              <caf-error [settings]="settingsErrors.errorsLicensePlate"></caf-error>
            </div>
            <div class="input-data">
              <caf-input [settings]="formSettings.brand" (onChange)="changeDocumentType($event)"></caf-input>
              <caf-error [settings]="settingsErrors.errorsBrand"></caf-error>
            </div>
            <div class="input-data">
              <caf-input [settings]="formSettings.model" (onChange)="changeDocumentType($event)"></caf-input>
              <caf-error [settings]="settingsErrors.errorsModel"></caf-error>
            </div>
          </div>
          <caf-notification [settings]="notificationSettings"></caf-notification>
        </div>
      </div>
    
    <footer class="clau-separation">
        <caf-button
        class="caf-btn-secondary caf-btn-desk-small caf-btn-mob-medium"
        (onClick)="back()"
        [settings]="button.buttonBack"></caf-button>
        <caf-button
        class="caf-btn-complementary caf-btn-desk-small caf-btn-mob-medium"
        (onClick)="save()"
        [settings]="button.buttonSubmit"
      ></caf-button>
    </footer>
    </div>


  