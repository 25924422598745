import { Injectable } from '@angular/core';
import { CaflerProductCardSettingsModel } from '@cafler/common-ui';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { KamikazeResource } from '../resources/kamikaze.resource';
import { selectCurrentVerticalProducts } from '../store/selectors';
import { TextFormatterService } from './text-formatter.service';
import { TimeFormatterService } from './time-formatter.service';
import * as moment from 'moment';
import { VerticalsTypeEnum } from '../Enums/verticalsType.enum';

@Injectable({
  providedIn: 'root'
})
export class SingleDataHelperService {

  constructor(
    private store: Store,
    private textFormatterService: TextFormatterService,
    private kamikazeRequest: KamikazeResource,
    private timeFormatterService: TimeFormatterService,

  ) { }

  private getTranslatedCurrentVerticalProduct(product: any){
    
    let response = <any>{
      Alias: product.ProductName,
      Id: product.OldProductId,
      IsPaymentRequired: product.HasToBeCharged,
      Price: {
        Currency: product.Currency,
        CurrencySymbol: product.CurrencySymbol,
        Tax: product.TaxPercentage,
        TaxFreePrice: product.TaxFreePrice,
        TotalPrice: product.TotalPrice,
      },
      Title: product.LocalizationKey,
      Holidays: product.Holidays,
      OrdersManagerId: product.ProductId,
      Category: product.Category,
      Time: product.Time,
      MainCaracteristic: product.MainCaracteristic,
      BadgeType: product.BadgeType,
    };

    if(product.IconKey){
      response = {
        ...response,
        icon: {
          name: `caf-${product.IconKey}`,
          alt: `caf-${product.IconKey}`
        },
    }
  }

  return response;
  }

  getTranslatedProduct(product: any){
    
    let response = <any>{
      id: product.ordersManagerId,
      price:
        (Math.round(product.price.totalPrice*100)/100).toFixed(2) + 
        product.price.currencySymbol,
      fromPriceKey:
        '',
      title: `cafler.product-type.${product.title}.title`,
      description: `cafler.product-type.${product.title}.description`,
      alias: product.title,
      alt: `cafler.product-type.${product.title}.title`,
      isPaymentRequired: product.isPaymentRequired,
      holidays: product.holidays,
      ordersManagerProductId: product.ordersManagerId,
      category: product.category,
      time: product.time,
      mainCaracteristic: product.mainCaracteristic,
      badgeType: product.badgeType,
    };

    if(product.icon){
      response = {
        ...response,
        icon: {
          name: `${product.icon.name}`,
          alt: `${product.icon.name}`
        },
    }
  }

  return response;
  
  }

  getFormattedForm(form: any){
    
    const sameDeliveryAddress = form.DestinationAddress !== null
    const originCountryPhoneCode = form.OriginContactPhoneNumber?.substring(0, 3);
    const originPhone = form.OriginContactPhoneNumber?.substring(3);

    const destinationCountryPhoneCode = form.DestinationContactPhoneNumber?.substring(0, 3);
    const destinationPhone = form.DestinationContactPhoneNumber?.substring(3);

    const startDate = this.timeFormatterService.removeLocationAndOffset(form.ServiceStartDate);
    const endDate = this.timeFormatterService.removeLocationAndOffset(form.ServiceEndDate);
    
    let serviceStartDate;
    let serviceEndDate;

    const diff = moment(endDate).diff(moment(startDate),'minutes')
    const selectTimeOption = diff > 15 ? 'range' : 'specific'  
    
    // if(form.VerticalType = VerticalsTypeEnum.LONG_DISTANCE_TRANSFER){
    //   serviceStartDate = this.timeFormatterService.removeLocationAndOffset(form.ServiceStartDate);
    // }
    let appointment 
    let appointmentTime  
    
    if(form.AppointmentTime){
      appointment = new Date(form.AppointmentTime)
      appointmentTime = form.AppointmentTime.split('T')[1].split('',5).join('')
    }

    let homologationType, documentationReady, periodicMot;

    if(form.HomologationSubConfiguration){
        homologationType =  form.HomologationSubConfiguration.ReformType;
        documentationReady = form.HomologationSubConfiguration.AreDocumentsAvailable;
        periodicMot = form.HomologationSubConfiguration.HasCompletedRegularInspections;
      }

    

    return {
      serviceType: form.Id,
      pickupDate: startDate,
      startPickUpTime: form.ServiceStartDate, //startDate,
      endPickUpTime: form.ServiceEndDate, //endDate,
      selectTimeOption: selectTimeOption, 
      specificTimeRange: selectTimeOption === 'specific' ? form.ServiceStartDate : null,
      // brand: form.VehicleBrand,
      pickupAddress: {
        address: form.OriginAddress,
        pickupAddressLatitud: form.OriginAddressLatitude,
        pickupAddressLongitud: form.OriginAddressLongitude,
      },
      deliveryAddress: {
        address: form.DestinationAddress,
        deliveryAddressLatitud: form.DestinationAddressLatitude,
        deliveryAddressLongitud: form.DestinationAddressLongitude
      },
      dropoffAddress: {
        address: form.DestinationAddress,
        deliveryAddressLatitud: form.DestinationAddressLatitude,
        deliveryAddressLongitud: form.DestinationAddressLongitude
      },
    
      additionalComments: form.Comments,
      // carPlaque: form.LicensePlate,
      pickupAddressDetails: form.OriginAddressDetails,
      deliveryAddressDetails: form.DestinationAddressDetails,
      countryPhoneCode: originCountryPhoneCode,
      phone: originPhone,
      email: form.OriginContactEmailAddress,
      dropoffEmail: form.DestinationContactEmailAddress,
      // vehicleType: form.VehicleChassisType,
      // model: form.VehicleModel,
      fullName: form.OriginContactName,
      dropoffFullName: form.DestinationContactName,
      dropoffCountryPhoneCode: destinationCountryPhoneCode,
      dropoffPhone: destinationPhone,
      station: form.StationId,
      isTechnicalInspectionPaid: form.IsInspectionPaid,
      dropoffAddressDetails: form.DestinationAddressDetails,
      sameDeliveryAddress: sameDeliveryAddress,
      daysReplacement: form.AdditionalReplacementVehicleConfiguration?.AmountOfDays,
      vehicleCategory: form.AdditionalReplacementVehicleConfiguration?.VehicleCategory,
      appointmentCode: form.AppointmentCode,
      appointmentDate: appointment,
      appointmentTime: appointmentTime,
      deliveryType: form.TransferType,
      UseCustomDelivery: form.UseCustomDelivery,
      homologationType:  homologationType,
      documentationReady: documentationReady,
      periodicMot: periodicMot
   
    }
  }

  public getCurrentVerticalProducts(): Observable<CaflerProductCardSettingsModel[]> {

    return this.store.select(selectCurrentVerticalProducts).pipe(
      map((beProducts) => {
        if (beProducts) {
          const parsedProducts = this.textFormatterService.convertObjectToCamelCase(
            beProducts,
            this.textFormatterService.pascalCaseToCamelCase,
          );
          return this.translateProductsObject(parsedProducts, this.getTranslatedProduct);
        }
      }),
    );
  } 

  translateMaintenanceProductsFromOrdersManager(maintenanceProductsFromOrderManager: any){
    return this.translateProductsObject(maintenanceProductsFromOrderManager, this.getTranslatedCurrentVerticalProduct)
  }

  translateProductsFromOrdersManager(productsFromOrderManager: any) {
    return this.translateProductsObject(productsFromOrderManager.Products, this.getTranslatedCurrentVerticalProduct)
  }


  getVerticalProducts(zoneId: string, verticalType: number, isB2b: boolean): Observable<any[]> {

    const MAINTENANCE_VERTICAL = 2;
    if(verticalType === MAINTENANCE_VERTICAL){
      return this.kamikazeRequest.getVerticalProducts(zoneId, verticalType, isB2b).pipe(      
        switchMap((item: any) => {
          return of(this.translateMaintenanceProductsFromOrdersManager(item));
        }),
      );
    }
    
    return this.kamikazeRequest.getVerticalProducts(zoneId, verticalType, isB2b).pipe(      
      switchMap((item: any) => {
        return of(this.translateProductsFromOrdersManager(item));
      }),
    );
  }

  private translateProductsObject(elem: any, getTranslatedProduct: Function){
    if(!Array.isArray(elem) && typeof elem !== 'object') return 

    if(Array.isArray(elem)){
      elem.forEach((product: any, index: number, originalArray: any[])=> {
        originalArray[index] = getTranslatedProduct(product)
      });
      
      return elem
   } else{
       const categories = Object.keys(elem);
       categories.forEach((category: string) => {
       return this.translateProductsObject(elem[category], getTranslatedProduct)
       })
   }
   return elem
 }
}
