import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WashDetailsPopupService } from 'src/app/components/wash-details-topping/service/wash-details.service';
import { SingleDataHelperService } from 'src/app/helpers/single-data-helper.service';
import { NavigationService } from '../../services/navigation.service';
import { KamikazeActions } from '../../store/actions';
import { selectIsVerticalProductsLoaded } from '../../store/selectors';
import { getProductDetails } from './service.helper';

@Component({
  selector: 'app-service-selector',
  templateUrl: './service-selector.page.html',
  styleUrls: ['./service-selector.page.scss']
})
export class ServiceSelectorPage implements OnInit {

  fg!: FormGroup;
  popUpRef: any;
  private readonly destroySubjects$ = new ReplaySubject<void>(1);

  productsSettings: any = {
    id: 'service-type',
    formControlName: 'productSelector',
    isTopping: false,
    products: []
  };

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private singleDataHelperService: SingleDataHelperService,
    private washDetailsPopupService: WashDetailsPopupService,

  ) {
    const formControlName = this.productsSettings.formControlName;
    this.fg = new FormGroup({});
    this.fg.addControl(formControlName, new FormControl(null, [Validators.required]));
   }

  ngOnInit(): void {
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'maintenance-selector' });
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: false });
    this.navigationService.currentPageTitle = 'cafler.product.maintenance-selector.title';

    this.store.select(selectIsVerticalProductsLoaded)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((productsLoaded) => {
      if (productsLoaded){
        this.singleDataHelperService.getCurrentVerticalProducts()
        .pipe(takeUntil(this.destroySubjects$))
        .subscribe((products:any) => {
          if(products){
            this.productsSettings.products = products['regularProducts'];
            this.productsSettings.products.push(
              {
                "id": "maintenance-bundle",
                "price": "",
                "fromPriceKey": "",
                "title": "cafler.product-type.service.maintenance-bundle.title",
                "description": "cafler.product-type.service.maintenance-bundle.description",
                "icon": {
                  name: `caf-maintenance`,
                  alt: `caf-maintenance`
                },
                "alias": "maintenance-bundle",
                "alt": "",
                "isPaymentRequired": true,
                "holidays": [],
                "ordersManagerProductId": "maintenance-bundle",
                "category": "RegularProducts",            })
            this.productsSettings.products.map((product:any) => {
              product.moreDetails = true;
              product.moreDetailsText = "cafler.card.more-data";
            });
          }
        });
      }  
    });
  }

  showMoreDetails(product: any) {
    const popUpSettings = getProductDetails(product);
    
    if(popUpSettings === null) return;

    this.popUpRef = this.washDetailsPopupService.open(
      ''  ,
      popUpSettings,
    );
    this.popUpRef.afterClosed$
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((res:any) => {
      this.washDetailsPopupService.enableScroll();
    });
  }

  chooseMaintenanceType(page: any) {
    this.router
      .navigate([`maintenance-bundle`], { relativeTo: this.activatedRoute })
      .then((s) => {
        // this.navigationService.currentPageTitle = 'cafler.product-type.pre-mot.title222';
      });
  }

  chooseProduct(page: any) {
    const currentProduct = this.productsSettings.products.find((product: any) => {
      return product.id === page;
    });
    this.store.dispatch({
      type: KamikazeActions.SetSelectedProduct,
      selectedProduct: currentProduct,
    });
    this.router
      .navigate([`${currentProduct?.alias}`], { relativeTo: this.activatedRoute })
      .then((s) => {
        // this.navigationService.currentPageTitle = 'cafler.product-type.pre-mot.title222';
      });
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();

    if(this.popUpRef){
      this.popUpRef.close();
    }
  }

}
