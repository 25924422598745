<form class="form-container" [formGroup]="fg">
    <div *ngIf="productsSettings.products['sedan']?.length > 0">
    <h3>{{'cafler.product.mantenace.bundle.Sedan.title' | translate}}</h3>
    <div class="cards-container">
        <caf-product-card
          class="card"
          [class]="'card' + (i + 1)"
          [settings]="product"
          *ngFor="let product of productsSettings.products['sedan']; let i = index"
          (productSelected)="pickProduct($event, 'sedan')"
        ></caf-product-card>
      </div>
    </div>
    <div *ngIf="productsSettings.products['motorbike']?.length > 0">
        <h3>{{'cafler.product.mantenace.bundle.Motorbike.title' | translate}}</h3>
        <div class="cards-container">
            <caf-product-card
              class="card"
              [class]="'card' + (i + 1)"
              [settings]="product"
              *ngFor="let product of productsSettings.products['motorbike']; let i = index"
              (productSelected)="pickProduct($event, 'motorbike')"
            ></caf-product-card>
        </div>
    </div>
</form>