import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KamikazeActions } from '../../store/actions';
import { Store } from '@ngrx/store';
import { PresentationFacade } from '../../facades/presentation.facade';
import { selectIsVerticalProductsLoaded } from '../../store/selectors';
import { CaflerIconSettingsModel, CaflerPopupService } from '@cafler/common-ui';
import { WashDetailsSettingsModel } from 'src/app/components/wash-details-topping/models/wash-details-settings.model';
import { WashDetailsPopupService } from 'src/app/components/wash-details-topping/service/wash-details.service';
import { washTypes, getWashDetailsSettings } from './lavado.helper';
import { SingleDataHelperService } from 'src/app/helpers/single-data-helper.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-lavado',
  templateUrl: './lavado.page.html',
  styleUrls: ['./lavado.page.scss'],
})
export class LavadoPage implements OnInit {
  fg!: FormGroup;
  productsSettings: any = {
    id: 'lavado-selector',
    formControlName: 'productSelector',
    isTopping: false,
    products: [],
  };
  categories: string[] = [];
  popUpRef: any;
  handWashIcon: CaflerIconSettingsModel = {
    name: 'caf-wash-3D',
    alt: 'lavado a mano',
    width: '28px',
    height: '28px',
  };

  tunnelWashIcon: CaflerIconSettingsModel = {
    name: 'caf-tunnel-wash-3D',
    alt: 'lavado a en centros especializados',
    width: '28px',
    height: '28px',
  };

  detailWashIcon: CaflerIconSettingsModel = {
    name: 'caf-detail-wash-3D',
    alt: 'lavado a detalle',
    width: '28px',
    height: '28px',
  };
  private readonly destroySubjects$ = new ReplaySubject<void>(1);

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private presentationFacade: PresentationFacade,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private washDetailsPopupService: WashDetailsPopupService,
    private singleDataHelperService: SingleDataHelperService,


  ) {
    const formControlName = this.productsSettings.formControlName;
    this.fg = new FormGroup({});
    this.fg.addControl(formControlName, new FormControl(null, [Validators.required]));
    // this.store.dispatch({type: KamikazeActions.FetchAvailableVerticalProducts});
  }

  ngOnInit() {
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'lavado-selector' });
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: false });
    this.navigationService.currentPageTitle = 'cafler.product.wash.title';

    this.store.select(selectIsVerticalProductsLoaded)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((productsLoaded) => {
      if (productsLoaded)
        this.singleDataHelperService.getCurrentVerticalProducts()
        .pipe(takeUntil(this.destroySubjects$))
        .subscribe((products: any) => {
          if(products){
            this.categories = Object.keys(products);
            this.categories.forEach((category: string) => {
              products[category].map((product: any) => {
                if(product.alias){
                  product.moreDetails = true;
                }
              });
            });
            this.productsSettings.products = products;
            
            
          }
        });
    });
  }

  showMoreDetails(productAlias: washTypes) {

    const popUpSettings = getWashDetailsSettings(productAlias)
    
    this.popUpRef = this.washDetailsPopupService.open(
      ''  ,
      popUpSettings,
    );
    this.popUpRef.afterClosed$.subscribe((res:any) => {
      this.washDetailsPopupService.enableScroll();
    });
  }

  pickProduct(page: string, category: string) {
    
    const currentProduct = this.productsSettings.products[category].find((product: any) => {
      return product.id === page;
    });
    this.store.dispatch({
      type: KamikazeActions.SetSelectedProduct,
      selectedProduct: currentProduct,
    });
    this.router
      .navigate([`${currentProduct?.alias}`], { relativeTo: this.activatedRoute })
      .then((s) => {
        this.navigationService.currentPageTitle = 'cafler.product-type.wash.title';
      });
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();

    if(this.popUpRef){
      this.popUpRef.close();
    }
  }
}
