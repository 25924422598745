import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { BECoreDataModel, BEBookOrderModel } from '../models';
import { VerticalsTypeEnum } from '../Enums/verticalsType.enum';
import { AllYouNeedProduct, TimeSlot } from '../models/types';

@Injectable()
export class KamikazeResource {
  constructor(private http: HttpClient) {}

  // getCoreData(isB2B: boolean): Observable<BECoreDataModel> {
  //   return this.http.get<BECoreDataModel>(
  //     `${environment.apiURL}/core/get-core-data?isB2B=${isB2B}`,
  //   );
  // }


  addToppingsToOrder(isB2B: boolean, orderHash: string, toppings: any): Observable<any> {
    let clientType = isB2B ? 'business' : 'consumer';
    return this.http.post<any>(`${environment.ordersManagerApiURL}/${clientType}/order/${orderHash}/topping`, toppings);
  }

  removeToppingsFromOrder(isB2B: boolean, orderHash: string, toppingId: string): Observable<any> {
    let clientType = isB2B ? 'business' : 'consumer';

    return this.http.delete<any>(`${environment.ordersManagerApiURL}/${clientType}/order/${orderHash}/topping?productId=${toppingId}`,);
  }

  checkAppointmentHour(stationId: string, dateAppointment: any, isB2B: boolean): Observable<any> {
    let clientType = isB2B ? 'business' : 'consumer';
    return this.http.get(
      `${environment.ordersManagerApiURL}/${clientType}/products/technical-inspection/station/${stationId}?day=${dateAppointment}`, 
    );
  }

  bookProduct(isB2B: boolean, productData: any): Observable<any> {
    let clientType = isB2B ? 'business' : 'consumer';
    let productType = this.getProductUrl(productData.ServiceConfiguration.VerticalType);
    return this.http.post<any>(
      `${environment.ordersManagerApiURL}/${clientType}/products/${productType}`,
      productData,
    );
  }

  bookAllYouNeedProduct(isB2B: boolean, productData: AllYouNeedProduct): Observable<any> {
    let clientType = isB2B ? 'business' : 'consumer';
    return this.http.post<any>(
      `${environment.ordersManagerApiURL}/${clientType}/products/open-form`,
      productData,
    );
  }

  getTechnicalInspectionProductId(isB2B: boolean, zoneId: string, chassisType: number, fuelType: number, isHomologation: boolean): Promise<any> {
    let clientType = isB2B ? 'business' : 'consumer';
    return this.http.get<any>(
      `${environment.ordersManagerApiURL}/${clientType}/products/technical-inspection?zoneId=${zoneId}&chassisType=${chassisType}&fuelType=${fuelType}&isHomologation=${isHomologation}`,
    ).toPromise();
  }

  getVehicleReplacementProductId(isB2B: boolean, zoneId: string): Observable<any> {
    let clientType = isB2B ? 'business' : 'consumer';
    return this.http.get<any>(
      `${environment.ordersManagerApiURL}/${clientType}/products/transfer?zoneId=${zoneId}&includeReplacementVehiclePack=true`,
    );
  }

  getAllYouWantTimeAvailability(isB2B: boolean, zoneId: string, date: Date): Observable<any> {
    const clientType = isB2B ? 'business' : 'consumer';
    return this.http.get<any>(
      `${environment.ordersManagerApiURL}/${clientType}/products/open-form?zoneId=${zoneId}&day=${date.toISOString().split('T')[0]}`);
  }
  getVehicleReplacementDaysAndPrices(isB2B: boolean, zoneId: string, date: Date): Observable<any[]> {
    let clientType = isB2B ? 'business' : 'consumer';
    let formattedDate = date.toISOString().split('T')[0]; // TODO: remove this when we have a
    return this.http.get<any>(`${environment.ordersManagerApiURL}/${clientType}/products/replacement-vehicle?zoneId=${zoneId}&date=${formattedDate}&carType=basic`);
  }

  getPreTechnicalInspectionProductId(isB2B: boolean, zoneId: string, chassisType: number, fuelType: number, includeTechnicalInspection: boolean): Promise<any> {
    let clientType = isB2B ? 'business' : 'consumer';
    return this.http.get<any>(
      `${environment.ordersManagerApiURL}/${clientType}/products/pre-technical-inspection?zoneId=${zoneId}&chassisType=${chassisType}&fuelType=${fuelType}&includeTechnicalInspectionProduct=${includeTechnicalInspection}`,
    ).toPromise();
  }

  validateOperativeZone(zoneId: string, latitude: number, longitude: number){
    return this.http.post(
      `${environment.ordersManagerApiURL}/validations/operative-zone`,
      {ZoneId: zoneId, Latitude: latitude, Longitude: longitude}
      );

  }

  confirmOrder(body: any, isB2B: boolean): Observable<any> {
    let clientType = isB2B ? 'business' : 'consumer';
    return this.http.post<any>(`${environment.ordersManagerApiURL}/${clientType}/order`, 
      body
    );
  }

  getInfoRegions(): Observable<any> {
    return this.http.get(`${environment.ordersManagerApiURL}/info/regions`);
  }

  getVerticalProducts(zoneId: string, verticalType: number, isB2B: boolean): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.ordersManagerApiURL}/info/products-category?zoneId=${zoneId}&verticalType=${verticalType}&isB2B=${isB2B}`,
      {},
    );
  }

  getServices(elementsAmount: number, page: number, servicesEnum: any, userId: any){
    return this.http.get<any[]>(
      `${environment.trackingURL}/api/tracking/services?elementsAmount=${elementsAmount}&page=${page}&servicesEnum=${servicesEnum}&userId=${userId}`,
      {},
    );
  }

  cancelService(caflerHash: string, userId: any){
    return this.http.patch<any[]>(
      `${environment.trackingURL}/api/tracking/order?caflerHash=${caflerHash}&userId=${userId}`,
      {},
    );
  }

  createProductOrder(params: BEBookOrderModel, isB2B: boolean): Observable<any> {
    let typeOfClient = isB2B ? 'B2B' : 'B2C';
    return this.http.post(`${environment.apiURL}/form/${typeOfClient}/book-order`, params);
  }


  setOrderStatus(data: any) {
    return this.http.post(
      `${environment.apiURL}/form/set-order-status?orderHash=${data.OrderHash || ''}&paymentHash=${
        data.PaymentHash || ''
      }`,
      {},
    );
  }

  setCouponCode(OrderHash: string, couponCode: string, isB2B: boolean) {
    let clientType = isB2B ? 'business' : 'consumer';
    return this.http.patch(`${environment.ordersManagerApiURL}/${clientType}/order/${OrderHash}/coupon`, {
      CouponCode: couponCode,
    });
  }

  removeCoupon(OrderHash: string, isB2B: boolean) {
    let clientType = isB2B ? 'business' : 'consumer';
    return this.http.delete(`${environment.ordersManagerApiURL}/${clientType}/order/${OrderHash}/coupon`,);
  }

  getServiceDetailsData(orderHash: string){
    return this.http.get(`${environment.trackingURL}/api/tracking/order/details?caflerHash=${orderHash}`,);
  }

  getProductAvailabilitiesLegacy(dateToSend: any): Observable<any> {
    return this.http.get(
      `${environment.ordersManagerApiURL}/info/availabilities` +
        '?productId=' +
        dateToSend.productId +
        '&selectedDate=' +
        dateToSend.selectedDate +
        '&isB2B=' +
        dateToSend.isB2B,
    );
  }
  
  
  getProductAvailabilities(dateToSend: any): Observable<any> {
    const a = this.http.get(
      `${environment.ordersManagerApiURL}/info/availabilities?productId=${dateToSend.productId}&selectedDate=${dateToSend.selectedDate}&isB2B=${dateToSend.isB2B}&isSpecificHours=${dateToSend.isSpecificHours}&transferType=${dateToSend.transferType}`);
  
    return a;
  }
  // TODO: Deprecated, remove when possible
  getToppingAvailabilities(dateToSend: any): Observable<any> {
    // Hack -> Como no sabemos si hay algún topping seleccionado, los borramos antes de pedir disponibilidades
    return this.http.post(`${environment.apiURL}/form/toppings/clear-toppings`, dateToSend);
  }

  getStations(productId: string){
    return this.http.get(
      `${environment.ordersManagerApiURL}/info/stations?productId=${productId}`
    )
  }
  getMotStations(isB2B: boolean, zoneId: string): Observable<any> {
    let clientType = isB2B ? 'business' : 'consumer';
    return this.http.get(
      `${environment.ordersManagerApiURL}/${clientType}/products/technical-inspection/station?ZoneId=${zoneId}`,
    );
  }
  
  updatePaymentSession(redirectResult: string): Observable<any> {
    return this.http.post(`${environment.apiURL}/payments/update-payment-session`, {
      redirectResult,
    });
  }

  // TODO: Deprecated, remove when possible
  clearSelectedToppings(OrderHash: string): Observable<any> {
    return this.http.post(`${environment.apiURL}/form/toppings/remove-toppings`, { OrderHash });
  }
  // TODO: Deprecated, remove when possible
  createReturnToppingOrder(params: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/form/toppings/add-return-topping`, params);
  }
  // TODO: Deprecated, remove when possible
  createWashingToppingOrder(params: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/form/toppings/add-wash-topping`, params);
  }

  getToppingChecklistHtmlData(lang: string, orderHash: string){
    return this.http.get(
      `${environment.trackingURL}/api/tracking/checklist?lang=${lang}&caflerHash=${orderHash}`);
  }

  getProductUrl(verticalId: any) {
    switch (verticalId) {
      case VerticalsTypeEnum.WASH: {
        return 'car-wash';
      }
      case VerticalsTypeEnum.REFUELING:{
        return 'refueling';
      }
      case VerticalsTypeEnum.MECHANICAL_INSPECTION:{
        return 'mechanical-inspection';
      }
      case VerticalsTypeEnum.MOT: {
        return 'technical-inspection';
      }
      case VerticalsTypeEnum.TRANSFER: {
        return 'transfer';
      }
      case VerticalsTypeEnum.PRE_MOT: {
        return 'pre-technical-inspection';
      }
      case VerticalsTypeEnum.LONG_DISTANCE_TRANSFER: {
        return 'transfer-long-distance';
      }
      case VerticalsTypeEnum.VALET: {
        return 'valet';
      }
      case VerticalsTypeEnum.TOW_TRUCK: {
        return 'tow-truck';
      }
      default: {
        return '';
      }
    }
  }
}
