import { Component, Input, OnInit } from '@angular/core';
import { CaflerIconSettingsModel } from '@cafler/common-ui';
import { ProgressVerticalBarModel, serviceStep } from './progress-vertical-bar.model';

@Component({
  selector: 'progress-vertical-bar',
  templateUrl: './progress-vertical-bar.component.html',
  styleUrls: ['./progress-vertical-bar.component.scss']
})
export class ProgressVerticalBarComponent implements OnInit {

  @Input() settings: ProgressVerticalBarModel = <ProgressVerticalBarModel>{};
  
  steps: serviceStep[] = []
  iconSettings = <CaflerIconSettingsModel>{
    name: "caf-black-circle", //TODO change colour
    alt: "",
    width: "14px",
    height: "14px"
  }
  
  constructor() {}

  ngOnInit(): void {
    this.steps = this.settings.steps.map((step: serviceStep, index) => {
      const icon = this.iconSettings
      icon.alt = "step " + index + 1

      return {
        ...step,
        icon: icon
      }
    })
  }

}
