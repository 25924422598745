import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  KamikazeActions,
  setCurrentCountry,
  setCurrentVertical,
  setCurrentVerticalProducts,
  setCurrentZone,
  setIsLoading,
} from '../../store/actions';
import { PresentationFacade } from '../../facades/presentation.facade';
import { ReplaySubject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  selectCoreDataRegions,
  selectCurrentCountry,
  selectCurrentLang,
  selectCurrentZone,
  selectIsAppInitialized,
  selectIsB2B,
  selectIsCoreDataLoaded,
  selectZonesFromSelectedRegion,
} from '../../store/selectors';
import { CaflerProductCardSettingsModel, CaflerSelectSettingsModel } from '@cafler/common-ui';
import { VerticalsTypeEnum } from 'src/app/Enums/verticalsType.enum';
import { AllYouNeedProductVertical } from 'src/app/constants';

@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.page.html',
  styleUrls: ['./product-selection.page.scss'],
})
export class ProductSelectionPage implements OnInit, OnDestroy, AfterViewInit {
  isLoading$!: any;
  isB2B!: boolean;
  isAppInitialized: boolean = false;
  private readonly destroySubjects$ = new ReplaySubject<void>(1);
  fg!: FormGroup;
  productsSettings: {
    id: string;
    formControlName: string;
    isTopping: boolean;
    products: CaflerProductCardSettingsModel[];
  } = {
    id: 'product-selector',
    formControlName: 'productSelector',
    isTopping: false,
    products: [],
  };

  selectZoneSettings: CaflerSelectSettingsModel = {
    id: 'select-zone-id',
    placeholder: 'cafler.forms.select-zone.placeholder',
    options: [],
    formControlName: 'zones',
    disabled: false,
  };

  selectCountrySettings: CaflerSelectSettingsModel = {
    id: 'select-country-id',
    placeholder: 'cafler.product-selection.select-country.placeholder',
    options: [],
    formControlName: 'countries',
    disabled: false,
  };

  
  countries: any;
  zones: any;
  storedZoneId?: string;
  currentZone!: any;
  currentCountry!: any;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private store: Store,
    private presentationFacade: PresentationFacade,
    private activatedRoute: ActivatedRoute,
  ) {
    this.isLoading$ = this.presentationFacade.isLoading$;
    this.navigationService.redirectIfB2B();
    const formControlName = this.productsSettings.formControlName;
    this.fg = new FormGroup({});
    this.fg.addControl(formControlName, new FormControl(null, [Validators.required]));
    this.fg.addControl('zones', new FormControl());
    this.fg.addControl('countries', new FormControl());
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.presentationFacade.getZoneVerticals()
    //   .subscribe((res: any)=> {
    //     this.productsSettings.products = res;
    //   })
    // }, 500);
  }

  ngOnInit() {
    this.store.dispatch(setCurrentVerticalProducts({ verticalProducts: null }));
    this.store.dispatch(setCurrentVertical({ selectedVertical: 0 }));

    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: false });
    this.store.dispatch({ type: KamikazeActions.SetNextPage, nextPage: 'product-selection' });
    this.store.dispatch({ type: KamikazeActions.StoreProductData, productData: null });
    this.store.dispatch({ type: KamikazeActions.SetSelectedProduct, selectedProduct: null });
    this.navigationService.currentPageTitle = 'cafler.product-selection.title';
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'product-selection' });

    // guardamos el valor actualizado del current country
    this.store
    .select(selectCurrentCountry)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((currentCountry: any) => {
      this.currentCountry = currentCountry;
    });

    // guardamos el valor actualizado de la current zone
    this.store
      .select(selectCurrentZone)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((zone: any) => {
        this.currentZone = zone;
      });

    // comprobamos si es b2b
    this.store
      .select(selectIsB2B)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((isB2B: boolean) => {
        this.isB2B = isB2B;
      });

    // comprobamos si la app esta inicializada
    this.store
      .select(selectIsAppInitialized)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((isAppInitialized: boolean) => {
        this.isAppInitialized = isAppInitialized;
      });

    // comprobamos cuando cambia el pais
    this.store
      .select(selectCurrentCountry)
      .pipe(
        takeUntil(this.destroySubjects$),
        withLatestFrom(
          this.store.select(selectZonesFromSelectedRegion),
          this.store.select(selectCurrentZone),
        ),
      )
      .subscribe(([currentCountry, zones, currentZone]) => {
        if (currentCountry) {
          this.zones = zones;
          let zonesSelector = this.presentationFacade.getZones(zones);
          this.selectZoneSettings.options = zonesSelector;
          if (currentZone) {
            this.fg.controls.zones.patchValue(currentZone.ZoneId);
            // this.store.dispatch(setCurrentZone(currentZone));
          } else {
            this.fg.controls.zones.patchValue(zones[0].ZoneId);
            this.store.dispatch(setCurrentZone({ currentZone: zones[0] }));
          }
        }
      });

    // comprobamos cuando el core data esta cargada
    this.store
      .select(selectIsCoreDataLoaded)
      .pipe(
        takeUntil(this.destroySubjects$),
        withLatestFrom(
          this.store.select(selectCurrentLang),
          this.store.select(selectCurrentCountry),
          this.store.select(selectCurrentZone),
          this.store.select(selectCoreDataRegions),
        ),
      )
      .subscribe(([dataLoaded, currentLang, currentCountry, currentZone, coreDataRegions]) => {
        //  si existe data cargada, podemos empezar a cargar la aplicacion
        if (dataLoaded) {
          this.countries = coreDataRegions;

          // elegimos las opciones de los paises y las metemos en el selector
          let countries = this.presentationFacade.getCountries(coreDataRegions);
          let countryFavoriteByLang;
          let countriesSelector = countries.map((country: any) => {
            return country.selector;
          });
          this.selectCountrySettings.options = countriesSelector;

          //elegimos el pais si tiene uno guardado en el state
          if (currentCountry) {
            this.fg.controls.countries.setValue(currentCountry.RegionIsoCode);
          }
          // sino por defecto uno, primero buscamos si alguno coincide con el idioma de su navegador
          else {
            countryFavoriteByLang = this.countries.find((country: any) => {
              return country.LanguageIsoCode === currentLang;
            });
            // si operamos en algun pais con el mismo idioma que el navegador, lo seteamos
            if (countryFavoriteByLang) {
              this.fg.controls.countries.setValue(countryFavoriteByLang.RegionIsoCode);
              this.store.dispatch(setCurrentCountry({ currentCountry: countryFavoriteByLang }));
            }
            //sino seteamos España por defecto
            else {
              this.fg.controls.countries.setValue(countriesSelector[0].key);
              this.store.dispatch(setCurrentCountry({ currentCountry: coreDataRegions[0] }));
            }
          }

          // una vez tenemos el pais seleccionado, sacamos zonas de la region seleccionada
          if (currentCountry) {
            this.zones = currentCountry.Zones;
            let zonesSelector = this.presentationFacade.getZones(this.zones);
            this.selectZoneSettings.options = zonesSelector;
            if (currentZone) {
              this.fg.controls.zones.setValue(currentZone.ZoneId);
              this.store.dispatch(setCurrentZone({ currentZone }));
            } else {
              this.fg.controls.zones.setValue(this.zones[0].ZoneId);
              this.store.dispatch(setCurrentZone({ currentZone: this.zones[0] }));
            }
          } else if (countryFavoriteByLang) {
            this.fg.controls.zones.setValue(countryFavoriteByLang.Zones[0].ZoneId);
            this.store.dispatch(setCurrentZone({ currentZone: countryFavoriteByLang.Zones[0] }));
          } else {
            this.fg.controls.zones.setValue(coreDataRegions[0].Zones[0].ZoneId);
            this.store.dispatch(setCurrentZone({ currentZone: coreDataRegions[0].Zones[0] }));
          }
        }
      });

    this.store
      .select(selectCurrentZone)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((currentZone: any) => {
        if (currentZone) {
          this.presentationFacade.getZoneVerticals().subscribe((res: any[]) => {
            res.forEach((product:any) => {
              if(product.id === VerticalsTypeEnum.TYRES || product.id === VerticalsTypeEnum.FORMALITIES || product.id === VerticalsTypeEnum.PARKING || product.id === VerticalsTypeEnum.TOW_TRUCK){
                product.price="";
                product.fromPriceKey = 'cafler_tyres_placeholder_prices'
              }
              if(product.id === VerticalsTypeEnum.PARKING){
                product.providerSlogan ='cafler.product.provider.label';
                product.providerIcon = {
                  name: 'caf-onePark-icon',
                  alt: 'OnePark icon',
                  height: '20px',
                  width: '59px',
                };

              }
            });
            
            res.push(AllYouNeedProductVertical)
            
            this.productsSettings.products = res;
          });
        }
      });
  }

  pickProduct(page: any) {
    // si es la pagina 12, lleva a la vertical de neumaticos y salimos
    if(page === VerticalsTypeEnum.TYRES ){
      window.location.href = 'https://neumaticos.cafler.com';
      return;
    }
    // si es la pagina 14, llevamos a la vertical de parking dependiendo del pais
    if(page === VerticalsTypeEnum.PARKING ){
      switch(this.currentCountry.RegionIsoCode){
        case 'esp':
          window.location.href = 'https://www.onepark.co/es?utm_medium=Affiliates&utm_source=cafler';
          break;
        case 'fra':
          window.location.href = 'https://www.onepark.fr/?utm_source=cafler&utm_medium=Affiliates';
          break;
        default:
        break;
      }
      return;
    }
    else {
      const currentZone = this.fg.get('zones')?.value;
      const zoneSelected = this.zones.find((zone: any) => zone.ZoneId === currentZone);
      let currentPage = this.productsSettings.products.find((product: any) => product.id === page);
      const productType = this.presentationFacade.getVerticalById(Number(currentPage?.id));
      // TODO -> Revisar posible refactor
      let nextPage = productType;
      
      switch (productType) {
        case 'revision':
          nextPage = 'vehicle-service';
          break;
        case 'refueling':
          nextPage = 'refuel';
          break
      }
      this.store.dispatch({
        type: KamikazeActions.SetCurrentVertical,
        selectedVertical: currentPage?.id,
      });
      this.store.dispatch({ type: KamikazeActions.StoreProductType, productType: productType });
      this.router
        .navigate([`${zoneSelected.Name}/${nextPage}`], { relativeTo: this.activatedRoute })
        .then((s) => {
          this.navigationService.currentPageTitle = currentPage?.title || '';
        });
    }

  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }

  changeZone(zoneId: any) {
    let selectedZone = this.zones?.find((zone: any) => zone.ZoneId === zoneId);
    this.store.dispatch(setCurrentZone({ currentZone: selectedZone }));
  }

  changeCountry(countryKey: any) {
    this.fg.controls.countries.setValue(countryKey);
    let selectedCountry = this.countries.find((country: any) => {
      return country.RegionIsoCode === countryKey;
    });
    this.store.dispatch(setCurrentZone({ currentZone: null }));
    this.store.dispatch(setCurrentCountry({ currentCountry: selectedCountry }));
  }
}
