import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { CaflerIconSettingsModel } from '@cafler/common-ui';
import { ValetHowItWorksRef } from './valet-how-it-works-popup-ref';

@Component({
  selector: 'valet-how-it-works-popup',
  templateUrl: './valet-how-it-works-popup.component.html',
  styleUrls: ['./valet-how-it-works-popup.component.scss', './popup.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ValetHowItWorksPopupComponent implements OnInit {

  constructor(private ref: ValetHowItWorksRef) { }

  settings!: any;

  closeIcon = <CaflerIconSettingsModel>{
    name: 'caf-close',
    alt: 'Close icon',
    height: '19px',
    width: '19px',
  };
  
  ngOnInit(): void {
    this.settings = this.ref.data;
  
    this.settings.whatIncludesTitle
    ? this.settings.whatIncludesTitle = this.settings.whatIncludesTitle
    : this.settings.whatIncludesTitle = "cafler.wash-details.whatIncludes.title";
  }


  
  cancel() {
    this.ref.close({ info: 'cancel' });
  }
}
