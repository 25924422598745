<section class="container">
    <popup-header [settings]="headerSettings" (closeEvent)="close()"></popup-header>
    <div class="description">
        <p>{{'cafler.replacement-vehicle.pop-up.description' | translate}}</p>
        <p>{{'cafler.replacement-vehicle.pop-up.description2' | translate}}</p>
    </div>
    <div class="what-do-we-offer__container">
        <h4 class="what-do-we-offer__title">
            {{'cafler.replacement-vehicle.pop-up.vehicle-data.what.do.we.offer.title' | translate}}
        </h4>
    </div>    
    <div class="vehicle-data-display-container">
        <vehicle-data-display [settings]="vehicleDataDisplaySetting"></vehicle-data-display>
    </div>
    <popup-how-it-works [settings]="PopupHowItWorksModel"></popup-how-it-works>
    <popup-before-service-starts [settings]="beforeServiceStarts"></popup-before-service-starts>
</section>