import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectCurrentTopping,
  selectCurrentPage,
  isProductPayable,
  selectCurrentVertical,
  selectFormValidity,
  selectNextPage,
  selectPaymentHashes,
  selectSelectedToppings,
  selectProductData,
  selectIsB2B,
  selectFinancialData,
  selectBookedOrder
} from '../../store/selectors';
import { first, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { addSelectedTopping, bookOrder, KamikazeActions } from '../../store/actions';
import { environment } from '../../../environments/environment';
import { CaflerPopupService } from '@cafler/common-ui';
import { PresentationFacade } from '../../facades/presentation.facade';
import { FormsHelperService } from '../../services/forms.service';
import { KamikazeFacade } from '../../facades/kamikaze.facade';
import { AdyenKamikazeHashes } from '@cafler/common-ui/lib/modules/caf-payments-module/models/adyen-kamikaze-hashes.model';
import { VerticalsTypeEnum } from 'src/app/Enums/verticalsType.enum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  private readonly destroySubjects$ = new ReplaySubject<void>(1);
  buttonSettings: any = {
    text: 'cafler.footer.button.label',
    disabled: false,
  };

  isB2B!: boolean;
  isProductPayable!: boolean;
  nextPage: string = '';
  currentPage: string = '';
  currentVertical: any;
  currentHashes!: AdyenKamikazeHashes;
  isCurrentPageOverview!: boolean;
  isPrimaryButton: boolean = true;
  isPaymentRequired: boolean = true;

  constructor(
    private store: Store,
    private router: Router,
    private popupService: CaflerPopupService,
    private presentationFacade: PresentationFacade,
    private kamikazeFacade: KamikazeFacade,
    private formsService: FormsHelperService,
  ) {}

  ngOnInit() {
    
    this.store.select(selectIsB2B).subscribe((isB2B) => {
      this.isB2B = isB2B;
    });
    this.store
      .select(selectNextPage)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((nextPage) => (this.nextPage = nextPage));
    this.store
      .select(selectCurrentPage)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((currentPage) => {
        this.currentPage = currentPage
        this.buttonSettings.text = this.getBottomTitle(currentPage, this.isB2B, this.currentVertical);
        this.isCurrentPageOverview = this.currentPage === 'overview';
        if(currentPage === 'overview' && this.isPaymentRequired){
          this.isPrimaryButton = false;
          return
        }
        this.isPrimaryButton = true;
      });
    this.store
      .select(selectCurrentVertical)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((currentVertical) => (this.currentVertical = currentVertical));
    this.store
      .select(selectPaymentHashes)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((currentHashes) => (this.currentHashes = currentHashes));
    this.store
      .select(selectProductData)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((currentHashes) => (this.currentHashes = currentHashes));
    this.store
      .select(isProductPayable)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((isProductPayable) => {
        this.isProductPayable = isProductPayable;
      });

    this.store
      .select(selectBookedOrder)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((bookedOrder) => {
        const isPaymentRequired = bookedOrder.IsPaymentRequired;
        if(isPaymentRequired !== undefined && isPaymentRequired !== null){
          this.isPaymentRequired = isPaymentRequired;
          if(this.currentPage === 'overview' && this.isPaymentRequired){
            this.isPrimaryButton = false;
            return 
          }
          this.isPrimaryButton = true;
        }
      });
 
  }



  getBottomTitle(currentPage: string, isB2B: boolean, vertical: VerticalsTypeEnum): string {

    if(currentPage !== 'overview'){
      return 'cafler.footer.button.label'; 
    }

    if(vertical === VerticalsTypeEnum.TOW_TRUCK){
      return 'cafler.footer.button-overview.budget.label';
    }

    if(vertical === VerticalsTypeEnum.ALL_YOU_NEED){
      return 'cafler.footer.button-overview.send-consult.label';
    };
    return isB2B ? 'cafler.footer.button-overview-b2b.label' : 'cafler.footer.button-overview-b2c.label';  
    
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }

  next(): void {
    let isNextPageOverview = this.nextPage === 'overview';
    let isNextPageToppingSelection = this.nextPage === 'add-on';
    let isCurrentPageReturnForm = this.currentPage === 'return-topping-form';

    this.formsService.getCurrentForm()?.markAllAsTouched();
    if (this.formsService.checkCurrentFormValidity() === 'INVALID') {
      return;
    }

    if (this.currentPage === 'return-topping-form') {
      this.store.dispatch({ type: KamikazeActions.AddSelectedTopping });
    }

    // if (isNextPageToppingSelection) {
    //   if(this.router.url.includes('add-on')){
    //     this.store.dispatch(addSelectedTopping());
    //   }
    // }

    
    if (this.isCurrentPageOverview && (this.isB2B || !this.isPaymentRequired)) {
      // i think this condition would be possible to unify with b2c (the last one)
      this.store.dispatch({ type: KamikazeActions.ConfirmOrder });
      this.orderSucceed(this.isB2B, this.isPaymentRequired, this.currentVertical);
    } else {
      if (isNextPageOverview) {
        this.store.dispatch({ type: KamikazeActions.ClearToppingsAdded });
        this.store.dispatch({ type: KamikazeActions.ClearToppingsPicked });
        this.store.dispatch({ type: KamikazeActions.BookOrder });
      } else if (!this.isCurrentPageOverview && !isCurrentPageReturnForm) {
        this.router.navigateByUrl(`${this.router.url}/${this.nextPage}`);
      } else if (this.isCurrentPageOverview) {
        this.store.dispatch({ type: KamikazeActions.ConfirmOrder });
      }
    }
  }

  getPopupSettings(vertical: VerticalsTypeEnum): any {
    switch (vertical) {
      case VerticalsTypeEnum.TOW_TRUCK:
        return this.presentationFacade.getTowTruckModalMessageSettings();
      case VerticalsTypeEnum.ALL_YOU_NEED:
        return this.presentationFacade.getAllYouNeedModalMessageSettings();
      default:
        return this.presentationFacade.getB2BModalMessageSettings();
    }
  }
  orderSucceed(isB2B: boolean, isProductPayable: boolean = true, vertical: VerticalsTypeEnum ) {
    let x;
    if(!isProductPayable){
      x = this.popupService.open('', this.getPopupSettings(vertical));
    }else{
      x = this.popupService.open('', this.presentationFacade.getB2BModalMessageSettings());
    }
    x.afterClosed$.subscribe((res) => {
      this.store.dispatch({ type: KamikazeActions.PurchaseCompleted });
      if(isB2B){
        window.top.location.href = environment.production
          ? 'https://business.cafler.com/'
          : 'https://business.development.cafler.com/';
        return;
      }

      window.top.location.href = environment.production
        ? 'https://products.cafler.com/'
        : 'https://products.development.cafler.com/'; 
      });
  }
}
