import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, take } from 'rxjs/operators';
import { KamikazeFacade } from '../facades/kamikaze.facade';

@Injectable()
export class CheckCoordinatesWithinCaflerAreaValidator {
  zoneId!: string;
  constructor(private kamikazeFacade: KamikazeFacade) {}

  createValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      this.zoneId = this.kamikazeFacade.zoneId;
      return new Observable<ValidationErrors | null>((observer) => {
        if (control.value && control.value.googleObject && control.value.googleObject.location)
          this.kamikazeFacade.validateOperativeZone(
            this.zoneId,
            control.value.googleObject.location.latitude,
            control.value.googleObject.location.longitude,
          )
            .pipe(
              map((res) => {
                observer.next(null);
                observer.complete();
              }),
              catchError((err) => {
                observer.next({ checkCoordinatesWithinCaflerArea: true });
                observer.complete();
                return of(null);
              }),
            )
            .pipe(debounceTime(0), take(1))
            .subscribe();
        else {
          observer.next(null);
          observer.complete();
        }
      });
    };
  }
}
