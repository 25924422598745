<div class="wash-PopUp">
    <div class="wash-PopUp__header">
        <h3>{{settings.title | translate}}</h3>
        <caf-icon class="pointer" (click)="cancel()" [settings]="closeIcon"></caf-icon>
    </div>
    <div class="wash-PopUp__description">
        <p>{{settings.description | translate}}</p>
    </div>
    <div *ngIf="settings.howItWorks" class="wash-PopUp__howItWorks">
        <h4>{{ "cafler.wash-details.howItWorks.title" | translate}}</h4>
        <div *ngFor="let item of settings.howItWorks">
            <caf-icon *ngIf="item.icon" [settings]="item.icon"></caf-icon>
            <p>{{item.sentence | translate}}</p>
        </div>
    </div>

    
    <div *ngIf="settings.beforeServiceStarts" class="wash-PopUp__howItWorks">
        <h4>{{ "cafler.product-type.valet.popup.before-service-starts.title" | translate}}</h4>
        <p>{{settings.beforeServiceStarts | translate}}</p>
    </div>
    
</div>
