<form class="vertical-cards-container" [formGroup]="fg">
  <caf-topping-card
    class="card"
    [class]="'card' + (i + 1)"
    *ngFor="let topping of availableToppings; let i = index;"
    [settings]="topping"
    (toppingSelected)="pickTopping($event)"
  ></caf-topping-card>
</form>
<div *ngIf="mobileSize" class="no-topping-container">
  <a class="no-topping-link" (click)="continueWithoutTopping()">
    {{"cafler.page.toppings.no-topping-button" | translate}}</a
  >
</div>
