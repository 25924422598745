import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CaflerDatepickerSettingsModel, CaflerErrorSettingsModel, CaflerIconSettingsModel, CafNotificationSettingsModel } from '@cafler/common-ui';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { first, pairwise, takeUntil } from 'rxjs/operators';
import { PresentationFacade } from 'src/app/facades/presentation.facade';
import { FormsHelperService } from 'src/app/services/forms.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { datepickerMaxDate } from 'src/app/services/utils';
import { KamikazeActions } from 'src/app/store/actions';
import { selectCurrentCountry, selectCurrentLang, selectCurrentZone, selectIsAppInitialized, selectIsB2B, selectProductData, selectSelectedProduct, selectServiceTimeOptions, selectStations } from 'src/app/store/selectors';
import { SingleDataHelperService } from 'src/app/helpers/single-data-helper.service';
import { TimeFormatterService } from 'src/app/helpers/time-formatter.service';

@Component({
  selector: 'app-valet-form',
  templateUrl: './valet-form.component.html',
  styleUrls: ['./valet-form.component.scss']
})
export class ValetFormComponent implements OnInit, OnDestroy {
  private readonly destroySubjects$ = new ReplaySubject<void>(1);

  fg!: FormGroup;
  isLoading: boolean = true;
  isB2B: boolean = false;

  isAppInitialized: boolean = false;
  selectedProduct: any;
  currentCountry: any;
  readyToDisplayPickUpHours: boolean = false;
  readyToDisplayDropOffHours: boolean = false;
  noSpecificHourAvailable: boolean = false;

  combinationNotValid: boolean = false;
  serviceTimeOptions: any[] = [];
  dayChangedFlag = false;
  renderNewFeatureSpeechBubble?: boolean = false;
  flagSubscription: any;
  timeRangeOption: string = ""; 
  firstNewFeatureTry: boolean = true;

  isSpecificHour: boolean = true;

  noRangeHourAvailable: boolean = false;
  
  notificationSettings: CafNotificationSettingsModel = {
    type: 'info',
    text: "cafler.page.valet.info-message.label"
  }

  settings: any = {
    serviceType: {
      id: 'serviceType',
      formControlName: 'serviceType',
      placeholder: 'cafler.page.generic.service-type.placeholder',
      disabled: false,
      options: [],
    },
    pickupDate: <CaflerDatepickerSettingsModel>{
      id: 'pickupDate',
      formControlName: 'pickupDate',
      placeholder: 'cafler.pickup-info.pickup-day.placeholder',
      minDate: new Date(),
      maxDate: datepickerMaxDate(),
      icon: <CaflerIconSettingsModel>{
        alt: 'calendar',
        name: 'caf-notes-book-dark',
      },
      daysDisabled: [6,0],
      lang: 'currentLang'
    },
    dropoffDate: <CaflerDatepickerSettingsModel>{
      id: 'dropoffDate',
      formControlName: 'dropoffDate',
      placeholder: 'cafler.dropoff-info.dropoff-day.placeholder',
      minDate: new Date(),
      maxDate: datepickerMaxDate(),
      icon: <CaflerIconSettingsModel>{
        alt: 'calendar',
        name: 'caf-notes-book-dark',
      },
      daysDisabled: [6,0],
      lang: 'currentLang'
    },
    station: {
      id: 'stationSelector',
      formControlName: 'station',
      placeholder: 'cafler.page.valet-station-placeholder',
      options: [],
    },
    vehicleType: {
      id: 'vehicleType',
      formControlName: 'vehicleType',
      placeholder: 'cafler.page.fuel.body-type.placeholder',
      disabled: false,
      options: [],
    },
    
    startPickUpTime: {
      id: 'startPickUpTime',
      placeholder: 'cafler.page.service-time.specific-time.selector.placeholder',
      formControlName: 'startPickUpTime',
      options: [],
    },
    endPickUpTime: {
      id: 'endPickUpTime',
      placeholder: 'cafler.page.service-time.specific-time.selector.placeholder',
      formControlName: 'endPickUpTime',
      options: [],
    },
    fullName: {
      id: 'fullName',
      formControlName: 'fullName',
      placeholder: 'cafler.contact.name.placeholder',
      disabled: false,
    },
    countryPhoneCode: {
      id: 'countryPhoneCode',
      formControlName: 'countryPhoneCode',
      placeholder: 'cafler.contact.country-phone-code.placeholder',
      disabled: false,
      options: [],
    },
    phone: {
      id: 'phone',
      formControlName: 'phone',
      placeholder: 'cafler.contact.phone.placeholder',
      disabled: false,
      type: 'phone',
    },
    email: {
      id: 'email',
      formControlName: 'email',
      placeholder: 'cafler.contact.email.placeholder',
      disabled: false,
      type: 'email',
    },
    carPlaque: {
      id: 'carPlaque',
      formControlName: 'carPlaque',
      placeholder: 'cafler.vehicle-info.plate.placeholder',
      disabled: false,
      type: 'text',
    },
    brand: {
      id: 'brand',
      formControlName: 'brand',
      placeholder: 'cafler.vehicle-info.brand.placeholder',
      disabled: false,
      required: true,
      type: 'text',
    },
    model: {
      id: 'model',
      formControlName: 'model',
      placeholder: 'cafler.vehicle-info.model.placeholder',
      disabled: false,
      required: true,
      type: 'text',
    },
    additionalComments: {
      id: 'additionalComments',
      formControlName: 'additionalComments',
      placeholder: 'cafler.pickup-info.textarea.comments',
      minlength: 0,
      disabled: false,
      required: false,
    },
  }
  settingsErrors: any = {
    errorsStation: <CaflerErrorSettingsModel>{
      formControlName: 'station',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsPickupDate: <CaflerErrorSettingsModel>{
      formControlName: 'pickupDate',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'notPresent', text: 'cafler.forms.error.date-not-in-past' },
      ],
    },
    errorsStartPickUpTime: <CaflerErrorSettingsModel>{
      formControlName: 'startPickUpTime',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
      ],
    },
    errorsEndPickUpTime: <CaflerErrorSettingsModel>{
      formControlName: 'endPickUpTime',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
      ],
    },
    errorsDropoffDate: <CaflerErrorSettingsModel>{
      formControlName: 'dropoffDate',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'notPresent', text: 'cafler.forms.error.date-not-in-past' },
      ],
    },
    errorsFullName: <CaflerErrorSettingsModel>{
      formControlName: 'fullName',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsCountryPhoneCode: <CaflerErrorSettingsModel>{
      formControlName: 'countryPhoneCode',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsPhone: <CaflerErrorSettingsModel>{
      formControlName: 'phone',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'pattern', text: 'cafler.forms.error.phone-pattern' },
        { key: 'minlength', text: 'cafler.forms.error.min-length' },
      ],
    },
    errorsEmail: <CaflerErrorSettingsModel>{
      formControlName: 'email',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'email', text: 'cafler.forms.error.email' },
      ],
    },
    errorsVehicleType: <CaflerErrorSettingsModel>{
      formControlName: 'vehicleType',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsCarPlaque: <CaflerErrorSettingsModel>{
      formControlName: 'carPlaque',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' },{key:'invalidLicensePlate', text: 'cafler.forms.error.invalid-license-plate'}],
    },
    errorsModel: <CaflerErrorSettingsModel>{
      formControlName: 'model',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsBrand: <CaflerErrorSettingsModel>{
      formControlName: 'brand',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
  }

  constructor(
    private store: Store,
    private formsService: FormsHelperService,
    private presentationFacade: PresentationFacade,
    private navigationService: NavigationService,
    private timeFormatterService: TimeFormatterService,
    private singleDataHelperService: SingleDataHelperService,

  ) {
    this.fg = this.formsService.initializeForm(this.settings);

    this.store.select(selectCurrentCountry).subscribe((country: any) => {
      if (country) {
        // setting default prefix code number
        this.fg.get('countryPhoneCode')?.setValue(country.InternationalPhonePrefix);
        this.currentCountry = country;
      }
    });
    this.store.select(selectCurrentLang).subscribe((lang) => {
      if(lang){
        this.settings.pickupDate = {
          ...this.settings.pickupDate,
          lang: lang.split('-')[0]
        }

        this.settings.dropoffDate = {
          ...this.settings.dropoffDate,
          lang: lang.split('-')[0]
        }
      } 
    })

    this.store.select(selectStations).subscribe((stations) => {
      if (stations) {
        let formattedStations = stations.map((station: any) => {
          return {key: station.StationId, text: station.StationName};
        })
        this.settings.station.options = formattedStations;
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: true });
    this.store.dispatch({ type: KamikazeActions.SetNextPage, nextPage: 'overview' });
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'valet' });
    this.store.dispatch({ type: KamikazeActions.ClearAvailableToppings });
    this.store.dispatch({ type: KamikazeActions.GetStations });

    this.formsService.addValidatorCheckDates(this.fg)

    this.store.dispatch({
      type: KamikazeActions.SetFormValidity,
      isFormValid: this.formsService.checkCurrentFormValidity(),
    });

    this.navigationService.scrollToTop();

    // comprobamos si la app esta inicializada
    this.store
      .select(selectIsAppInitialized)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((isAppInitialized: boolean) => {
        this.isAppInitialized = isAppInitialized;
    });
  
    this.store.select(selectIsB2B).subscribe((isB2B) => {
      this.isB2B = isB2B;
    });



    this.store.select(selectSelectedProduct)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((product) => {
      if (product) {
        this.selectedProduct = product;
        this.fg.controls.serviceType.setValue(product.ordersManagerProductId);
        this.navigationService.currentPageTitle = 'cafler.product.valet.form.title';
      }
    });

    this.presentationFacade
      .getCountryCodes()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((countryCodes) => {
        this.settings.countryPhoneCode.options = countryCodes;
      });
  
    this.fg
      .get(this.settings.pickupDate.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .pipe(first())
      .subscribe(() => {
        this.readyToDisplayPickUpHours = true;
        this.updateSpecificPickUpHours(this.fg.get(this.settings.pickupDate.formControlName)?.value);
    });

    this.fg
      .get(this.settings.pickupDate.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .pipe(pairwise())
      .subscribe(([prev, next]) => {
        if((prev.getTime() === next.getTime())){
          return;
        }
        if (this.readyToDisplayPickUpHours) {
          this.resetServiceTimeStyles(true);
          this.updateSpecificPickUpHours(this.fg.get(this.settings.pickupDate.formControlName)?.value);
          this.fg.get('startPickUpTime')?.patchValue(null);
        }

        this.readyToDisplayPickUpHours = true;    
      });

    this.fg
      .get(this.settings.dropoffDate.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .pipe(first())
      .subscribe(() => {
        this.readyToDisplayDropOffHours = true;
        this.updateSpecificDropOffHours(this.fg.get(this.settings.dropoffDate.formControlName)?.value);
    });

    this.fg
      .get(this.settings.dropoffDate.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .pipe(pairwise())
      .subscribe(([prev, next]) => {
        if((prev.getTime() === next.getTime())){
          return;
        }
        if (this.readyToDisplayDropOffHours) {
          this.resetServiceTimeStyles(true);
          this.updateSpecificDropOffHours(this.fg.get(this.settings.dropoffDate.formControlName)?.value);
          this.fg.get('endPickUpTime')?.patchValue(null);
        }

        this.readyToDisplayDropOffHours = true;    
      });

      this.singleDataHelperService
      .getCurrentVerticalProducts()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((products:any) => {
        if (products) {
          this.settings.serviceType.options = products.valet.map((product: any) => {
            return { key: product.ordersManagerProductId, text: product.title, alias: product.alias };
          });
        }
      });

      this.store
        .select(selectProductData)
        .pipe(takeUntil(this.destroySubjects$))
        .subscribe((productData) => {
          
          if (this.isLoading) {
            if (!!productData) {
              this.formsService.loadFormGroupData(this.fg, productData, this.isLoading);
              this.isLoading = false;
              if(this.selectedProduct){
                this.fg.controls.serviceType.setValue(this.selectedProduct.ordersManagerProductId);
              }
            } else {
              if (this.isB2B) {
                this.formsService.preloadB2BData(this.fg);
              }
            }
          }
      });

      this.presentationFacade.getHolidays().subscribe((holidays) => {
        let holidaysDates = holidays.map((day: any) => {
          return new Date(day);
        });
  
        this.settings.pickupDate = {
          ...this.settings.pickupDate,
          datesDisabled: holidaysDates,
        };

        this.settings.dropoffDate = {
          ...this.settings.dropoffDate,
          datesDisabled: holidaysDates,
        };
      });

      this.fg.statusChanges.pipe(takeUntil(this.destroySubjects$)).subscribe((status) => {
        this.formsService.handleFormValidity(status, this.fg.value);
      });

      this.presentationFacade.getCoreDataCatalog('TransferTypeEnum').subscribe((vehicleTypes) => {
        if (vehicleTypes) {
          this.settings.vehicleType.options = vehicleTypes.map((t: any) => ({
            key: t.key,
            text: `cafler.product-type.media-larga-distancia.vehicle-type.${t.text}`,
          }));
        }
      });
  }

  
  private getFormatedTimeRange(timeOptions: any, rangeMinutes: number) {
    let formatedTimeRange = [...timeOptions];

    formatedTimeRange.forEach((entry: any) => {

      let date = new Date(entry.key.substring(0,19));
      let newDate = this.timeFormatterService.increaseTime(date, rangeMinutes);
  
      entry.text = entry.text + ' - ' + this.timeFormatterService.getTimeFromDate(newDate);
    })
    return formatedTimeRange;
  }



  updateSpecificPickUpHours(date: any){
    this.presentationFacade
      .getPickupTimeOptions(
        date,
        true,
      ).pipe(first())
      .subscribe((timeOptions: any) => {
        let timeOptionsJSON = JSON.parse(JSON.stringify(timeOptions));
        this.settings.startPickUpTime.options =  this.getFormatedTimeRange(timeOptionsJSON, 15)
      })
  }

  updateSpecificDropOffHours(date: any){
    this.presentationFacade
      .getPickupTimeOptions(
        date,
        true,
      ).pipe(first())
      .subscribe((timeOptions: any) => {
        let timeOptionsJSON = JSON.parse(JSON.stringify(timeOptions));
        this.settings.endPickUpTime.options = this.getFormatedTimeRange(timeOptionsJSON, 15);
      })
  }

  resetServiceTimeStyles(dayChangedMode?: boolean) {    
    this.combinationNotValid = false;
    if (dayChangedMode && this.serviceTimeOptions.length > 0) {
      this.dayChangedFlag = true;
    } else {
      this.dayChangedFlag = false;
    }
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }

}
