export enum ToppingEnum{
    handWash_interior = 'HandWash_interior',
    handWash_exterior = 'HandWash_exterior',
    handWash_complete = 'HandWash_complete',
    expressWash_interior = 'ExpressHandWash_interior',
    expressWash_exterior = 'ExpressHandWash_exterior',
    expressWash_complete = 'ExpressHandWash_complete',
    tunnelWash_interior = 'WashTunnel_interior',
    tunnelWash_exterior = 'WashTunnel_tunnel',
    tunnelWash_complete = 'WashTunnel_complete',
    tunnelWash_motorcycle = 'WashTunnel_motorcycle',
    refuel = 'Refueling_Refueling',
    tireInflation = 'TireInflation_TireInflation',
    checklist = 'Checklist_Checklist',
    insurance = 'Insurance_Insurance',
    cafler_fresh = 'CaflerFresh_CaflerFresh'
}