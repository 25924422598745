<form class="caf-md-container margin-top-forms" [formGroup]="fg">
  <!-- direccion de devolucion-->
  <div class="input-item">
    <span class="subtitle-1">{{'cafler.page.wash.dropoff.info.title' | translate}}</span>
    <div *ngIf="isB2B" class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-input-google-autosuggest
        class="col-start-sm-1 col-start-1 col-end-sm-10 col-end-10"
        [settings]="settings.destinationAddress"
      >
      </caf-input-google-autosuggest>
      <button
        class="h-align-center v-align-center complete-address-button col-start-sm-11 col-start-11 col-end-sm-12 col-end-12"
        (click)="getB2BAddress(settings.destinationAddress.formControlName)"
      >
        <caf-icon [settings]="settingsExtra.locationIcon"></caf-icon>
      </button>
    </div>
    <caf-input-google-autosuggest *ngIf="!isB2B" [settings]="settings.destinationAddress">
    </caf-input-google-autosuggest>
    <caf-error
      class="extra-margin-error"
      [settings]="settingsErrors.errorsDestinationAddress"
    ></caf-error>
  </div>
  <div class="input-item">
    <caf-input [settings]="settings.destinationAddressDetails"></caf-input>
  </div>
  <!-- contact delivery data-->

  <div>
    <div class="input-item">
      <span class="subtitle-1"
        >{{'cafler.pickup-info.destination-contact.title' | translate}}
      </span>
      <caf-input [settings]="settings.destinationFullName"></caf-input>
      <caf-error [settings]="settingsErrors.errorsDestinationFullName"></caf-error>
    </div>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-select
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settings.destinationCountryPhoneCode"
      ></caf-select>
      <caf-input
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settings.destinationPhone"
      ></caf-input>
    </div>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-error
        class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
        [settings]="settingsErrors.errorsDestinationPhone"
      ></caf-error>
      <caf-error
        class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
        [settings]="settingsErrors.errorsDestinationCountryPhoneCode"
      ></caf-error>
    </div>
    <div *ngIf="!isB2B" class="input-item">
      <caf-input [settings]="settings.destinationEmail"></caf-input>
      <caf-error [settings]="settingsErrors.errorsDestinationEmail"></caf-error>
    </div>
  </div>

  <!-- fecha del servicio-->
  <div class="input-item">
    <span class="subtitle-1">{{'cafler.page.wash.date.info.title' | translate}}</span>
    <caf-input [settings]="settings.destinationDate"></caf-input>
    <caf-error
      class="extra-margin-error"
      [settings]="settingsErrors.errorsDestinationDate"
    ></caf-error>
  </div>
  <div *ngIf="fg.get(settings.destinationDate.formControlName)?.valid" class="input-item">
    <caf-select
      *ngIf="settings.destinationTime.options.length > 0"
      [settings]="settings.destinationTime"
    ></caf-select>
    <p *ngIf="settings.destinationTime.options.length <= 0" class="hours-not-available">
      {{'hours-not-available' | translate }}
    </p>
  </div>
</form>
