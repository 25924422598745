import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CaflerIconSettingsModel } from '@cafler/common-ui';

@Component({
  selector: 'coupon-box',
  templateUrl: './coupon-box.component.html',
  styleUrls: ['./coupon-box.component.scss'],
})
export class CouponBoxComponent implements OnInit {
  @Output() onBoxClosed: EventEmitter<string> = new EventEmitter<any>();

  @Input() couponCode!: string;

  iconSettings = <CaflerIconSettingsModel>{
    alt: 'X',
    name: 'caf-close',
  };

  constructor() {}

  ngOnInit(): void {}

  closeBox() {
    this.onBoxClosed.emit('test');
  }
}
