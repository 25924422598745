<form
  class="product-selection-container"
  [formGroup]="fg"
  *ngIf="!isLoading$ && !isB2B && isAppInitialized"
>
  <div class="input-item caf-select-wrapper">
    <caf-select [settings]="selectCountrySettings" (onChange)="changeCountry($event)"> </caf-select>
    <caf-select
      *ngIf="selectZoneSettings.options.length"
      (onChange)="changeZone($event)"
      [settings]="selectZoneSettings"
    >
    </caf-select>
  </div>
  <div class="title-container">
    <h3>{{'cafler.initial-question.title' | translate }}</h3>
  </div>
  <div class="vertical-cards-container">
    <caf-product-card
      class="card"
      [class]="'card' + (i + 1)"
      *ngFor="let product of productsSettings.products; let i = index"
      [settings]="product"
      (productSelected)="pickProduct($event)"
    >
    </caf-product-card>
  </div>
</form>
