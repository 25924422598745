import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CaflerButtonSettingsModel, CaflerIconSettingsModel } from '@cafler/common-ui';
import { ToppingSummaryModel, WashToppingConfiguratorModel } from 'src/app/models';
import { ToppingSelectorModel } from 'src/app/models/fe/topping-selector.model';
import { washToppingIncompatibleConfig } from './wash-topping-incompatible-config';
import { ToppingEnum } from '../../Enums/topping.enum';


enum WashCategories{
  handWash = 'handWash',
  expressWash = 'expressWash',
  tunnelWash = 'tunnelWash',
}

@Component({
  selector: 'app-wash-topping-configurator',
  templateUrl: './wash-topping-configurator.component.html',
  styleUrls: ['./wash-topping-configurator.component.scss']
})
export class WashToppingConfiguratorComponent implements OnInit {

  selectedProducts!: Map<string, ToppingSummaryModel>;
  toppingsToDisplay: ToppingSelectorModel[] = [];

  incompatibles!: Map<string, string[]>;
  category: string = 'Wash';

  @Input() settings: any = <any>{};
  @Output() onGoBack: EventEmitter<number> = new EventEmitter<any>();
  @Output() onSubmit: EventEmitter<Map<string, ToppingSummaryModel>> = new EventEmitter<any>();

  
  buttons = {
    buttonSubmit: <CaflerButtonSettingsModel>{
      id: 'submitButton',
      text: 'cafler.toppings.washing.submit-button',
      actionName: 'showSpinner',
      disabled: false,
    },
    buttonBack: <CaflerButtonSettingsModel>{
      id: 'backButton',
      text: 'cafler.toppings.washing.back-button',
      actionName: 'showSpinner',
      disabled: false,
    },
  }
  
  handWash: WashToppingConfiguratorModel = {
    id: 'handWash',
    title: 'cafler.toppings.washing.handwash.title',
    description: 'cafler.toppings.washing.handwash.description',
    washProducts: []
  } 

  expressWash: WashToppingConfiguratorModel = {
    id: 'expressWash',
    title: 'cafler.toppings.washing.expresswash.title',
    description: 'cafler.toppings.washing.expresswash.description',
    washProducts: []
  } 

  tunnelWash: WashToppingConfiguratorModel = {
    id: 'tunnelWash',
    title: 'cafler.toppings.washing.tunnelwash.title',
    description: 'cafler.toppings.washing.tunnelwash.description',
    washProducts: []
  } 

  display: WashToppingConfiguratorModel[] = []

  arrowIcon = <CaflerIconSettingsModel>{
    name: 'caf-chevron-down-light',
    alt: 'Next icon',
    width: '10px',
    height: '10px',
  };


  constructor()  
  {
    this.incompatibles = washToppingIncompatibleConfig;
    this.selectedProducts = new Map()
  }

  ngOnInit(): void {
     
    let tempHandWash = this.settings.availableToppings?.filter((product: { category: string; }) => product?.category === WashCategories.handWash)
    this.handWash.washProducts  = JSON.parse(JSON.stringify(tempHandWash))
  
    let tempExpressWash = this.settings.availableToppings?.filter((product: { category: string; }) => product?.category === WashCategories.expressWash)
    this.expressWash.washProducts = JSON.parse(JSON.stringify(tempExpressWash))
   
    let tempTunnelWash = this.settings.availableToppings?.filter((product: { category: string; }) => product?.category === WashCategories.tunnelWash)
    this.tunnelWash.washProducts = JSON.parse(JSON.stringify(tempTunnelWash))

    if(tempHandWash?.length > 0) {
      this.display.push(this.handWash)
    }
    if(tempExpressWash?.length > 0) {
      this.display.push(this.expressWash)
    }
    if(tempTunnelWash?.length > 0) {
      this.display.push(this.tunnelWash)
    }

    this.toppingsToDisplay = [...this.handWash.washProducts,...this.expressWash.washProducts, ...this.tunnelWash.washProducts]

    this.settings.elementsAdded.forEach((elem: {id: string, code: string}) => {
      const topping: ToppingSummaryModel = {id: elem.id ,code: elem.code, category: this.category, serviceConfiguration: {}}
      this.selectedProducts.set(elem.code, topping)
        const selectedProduct = this.toppingsToDisplay.find((product: any) => product.toppingCode === elem.code)
        if(selectedProduct) {
          selectedProduct.checked = true
        }
    })
    
    this.disableIncompatibleProducts()
  }
  
  onSubmitEvent() {
    this.onSubmit.emit(this.selectedProducts)
  }

  onGoBackEvent() {
    this.selectedProducts.clear() 
    this.onGoBack.emit();
  }

  disableIncompatibleProducts() {
        
    this.toppingsToDisplay.map((product:any) => {
          
      const incompatiblesId = this.incompatibles.get(product.toppingCode)

      let isCompatible = true;

      if (incompatiblesId) {
        this.toppingsToDisplay.map((element:any) => {
          if(incompatiblesId.includes(element.toppingCode) && this.selectedProducts.has(element.toppingCode)){
            product.disabled = true;
            isCompatible = false;
            return;
          }
        })
        if(isCompatible) product.disabled = false;
      }
    
    })
  }

  updateSelectedProducts(clicked: boolean, id: string, code: string) {
    const topping: ToppingSummaryModel = {id: id, code: code, category: this.category, serviceConfiguration: {}}
    
    clicked
      ? this.selectedProducts.set(code,topping)
      : this.selectedProducts.delete(code);

  }
  // logic behind the selection of the wash products
  // notion: https://www.notion.so/autocafler/Toppings-689e551104b5449d915b4bb6f68b7e75
  productSelection(event: {clicked: boolean, id: string, code: string}) {
  
    const {clicked, id, code} = event;
   
    switch(code) {
      case ToppingEnum.handWash_interior: 

        this.updateSelectedProducts(clicked, id, ToppingEnum.handWash_interior)
        this.disableIncompatibleProducts()
        break;

      case ToppingEnum.handWash_exterior:
     
        this.updateSelectedProducts(clicked, id, ToppingEnum.handWash_exterior)
        this.disableIncompatibleProducts()
        break;
        
      case ToppingEnum.handWash_complete:
       
        this.updateSelectedProducts(clicked, id, ToppingEnum.handWash_complete)
        this.disableIncompatibleProducts()          
        break;

      case ToppingEnum.expressWash_interior:
        
        this.updateSelectedProducts(clicked, id, ToppingEnum.expressWash_interior)
        this.disableIncompatibleProducts()          
        break;

      case ToppingEnum.expressWash_exterior:
        
        this.updateSelectedProducts(clicked, id, ToppingEnum.expressWash_exterior)
        this.disableIncompatibleProducts()          
        break;

      case ToppingEnum.expressWash_complete:
       
        this.updateSelectedProducts(clicked, id, ToppingEnum.expressWash_complete)
        this.disableIncompatibleProducts()          
        break;

      case ToppingEnum.tunnelWash_interior:
        this.updateSelectedProducts(clicked, id, ToppingEnum.tunnelWash_interior)
        this.disableIncompatibleProducts()
        break;

      case ToppingEnum.tunnelWash_exterior:
        this.updateSelectedProducts(clicked, id, ToppingEnum.tunnelWash_exterior)
        this.disableIncompatibleProducts()
        break;

      case ToppingEnum.tunnelWash_complete:
        this.updateSelectedProducts(clicked, id, ToppingEnum.tunnelWash_complete)
        this.disableIncompatibleProducts()
        break;

      case ToppingEnum.tunnelWash_motorcycle:
        this.updateSelectedProducts(clicked, id, ToppingEnum.tunnelWash_motorcycle)
        this.disableIncompatibleProducts()
        break;
    }     
  } 
}
