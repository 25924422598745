<section class="container">
    <div class="header-container">
        <div class="img-container">
            <caf-icon [settings]="icon"></caf-icon>
        </div>
        <p>{{text | translate}}</p>
    </div>
    <div *ngIf='["PickingUp", "InProgress", "Delivering", "Finished"].includes(settings.status)'>
        <div class="progress-bar-container">
            <div class="progress-statues"
            [ngClass]="{active: settings.status === 'PickingUp', 
                completed: settings.status === 'InProgress' || settings.status === 'Finished'|| settings.status === 'Delivering'}">
                <div class="progress-bar">
                    <div class="progress-bar-value"></div>
                </div>
                <span>{{'cafler.progress-service-bar.status.picking-up.label' | translate}}</span>
            </div>
           
            <div class="progress-statues"
            [ngClass]="{active: settings.status === 'InProgress', 
                completed: settings.status === 'Finished' || settings.status === 'Delivering',
                blocked: settings.status === 'PickingUp' }">
                <div class="progress-bar">
                    <div class="progress-bar-value"></div>
                </div>
                <span>{{'cafler.progress-service-bar.status.in-progress.label' | translate}}</span>
            </div>

            <div class="progress-statues"
            [ngClass]="{active: settings.status === 'Delivering', 
                completed: settings.status === 'Finished',
                blocked: settings.status === 'PickingUp' || settings.status === 'InProgress'}">
                <div class="progress-bar">
                    <div class="progress-bar-value"></div>
                </div>
                <span>{{'cafler.progress-service-bar.status.delivering.label' | translate}}</span>
            </div>
        </div>
    </div>
</section>