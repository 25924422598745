
import { Injectable } from '@angular/core';

import * as LDClient from 'launchdarkly-js-client-sdk';
import { Subject } from 'rxjs/internal/Subject';

import { environment } from '../../environments/environment';


export const LaunchDarklyFlagNames = {
    RENDER_IN_FORM : "RenderInForm",
    RENDER_IN_OVERVIEW : "RenderInOverview",
    SPECIFIC_TIME_NEW_FEATURE: "SpecificTimeNewFeature",
}

@Injectable()
export class LaunchDarklyService {
    private ldClient: any;
    private flags: any = {};

    flagChange: Subject<any> = new Subject<any>();

    constructor() {

        this.ldClient = LDClient.initialize(environment.launchDarkly.clientSideID,
            { key: environment.launchDarkly.sdkKey, anonymous: true });

        this.ldClient.on('ready', () => {
            this.setFlags()
        });

        this.ldClient.on('change', () => {
            this.setFlags()
        });
    }

    isLoaded(): boolean {
        return this.flagChange.observers.length === 0;
    }

    /**
     * Returns the boolean value of a specific flag.
     * 
     * if the flagName does not exist it returns false.
     * @param flagName 
     */
    getFlag(flagName: string): boolean {
        const flagValue = this.flags[flagName];

        if(flagValue === undefined){
            return false;
        }

        return flagValue;
    }

    private setFlags() {
        this.flags = this.ldClient.allFlags();
        this.flagChange.next(this.flags);
    }
}