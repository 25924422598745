import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { setCurrentLang } from '../../store/actions';
import { LanguageCodesEnum } from '../../config/languageCodes.enum';
import { selectCurrentLang } from '../../store/selectors';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LanguageSelectorComponent implements OnInit {
  languageFg = new FormGroup({
    languageSelector: new FormControl(),
  });
  languageSelectorSettings = {
    id: 'languageSelector',
    formControlName: 'languageSelector',
    placeholder: 'ES',
    disabled: false,
    options: [
      { key: LanguageCodesEnum['es'], text: 'ES' },
      { key: LanguageCodesEnum['fr'], text: 'FR' },
      { key: LanguageCodesEnum['uk'], text: 'EN' },
    ],
  };

  constructor(private store: Store, public translate: TranslateService) {}

  ngOnInit() {
    this.store.select(selectCurrentLang).subscribe((lang) => {
      const current = this.languageSelectorSettings.options.find((l) => l.key === lang);
      this.languageFg.controls.languageSelector.setValue(current?.key);
    });
  }

  onLanguageChange(lang: string) {
    this.store.dispatch(setCurrentLang({ currentLang: lang }));
    this.translate.use(lang);
  }
}
