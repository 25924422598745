import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { setIsLoading } from '../store/actions';
import {
  CaflerIconSettingsModel,
  CaflerPopupService,
  CaflerPopupSettingsModel,
} from '@cafler/common-ui';
import { MyMonitoringService } from '../services/logging.service';
import { selectKamikazeState } from '../store/selectors';

/**
 * This class is for intercepting http requests. When a request starts, we set the loadingSub property
 * in the LoadingService to true. Once the request completes and we have a response, set the loadingSub
 * property to false. If an error occurs while servicing the request, set the loadingSub property to false.
 * @class {HttpRequestInterceptor}
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  kamikazeState: any;
  constructor(private store: Store, private popupService: CaflerPopupService, private myMonitoringService: MyMonitoringService) {
    this.store.select(selectKamikazeState).subscribe((kamikazeState =>{
      this.kamikazeState = kamikazeState;
    }))
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError((err) => {
          this.myMonitoringService.logException(new Error(JSON.stringify(this.kamikazeState)));
 
          if (err.status === 400 || err.status === 422 || err.status === 409) {
            if(err.status == 422 && err.error.ErrorCode === "hoursOutsideProviderRange"){
              return throwError(err.error);
            }
            if(err.status == 422 && err.error.ErrorCode === "hourOutsideRange"){
              return throwError(err.error);
            }
            
            if(err.status === 409 && err.error.ErrorCode === "toppingAdditionFailed"){
              const popupRef = this.popupService.open('', <CaflerPopupSettingsModel>{
                id: 'popupId',             
                description: 'bad-request.text-toppings.detail',
                icon: <CaflerIconSettingsModel>{
                  id: 'error',
                  name: 'caf-error-red-circle',
                  alt: 'error-icon',
                },
              });
              const subscription = popupRef.afterClosed$.subscribe((data) => {
                subscription.unsubscribe();
                this.store.dispatch(setIsLoading({ isLoading: false }));
              });
              return throwError(err.error);
            }
            const popupRef = this.popupService.open('', <CaflerPopupSettingsModel>{
              id: 'popupId',
              title: 'bad-request.text-1',
              description: 'bad-request.text-2',
              icon: <CaflerIconSettingsModel>{
                id: 'error',
                name: 'caf-error-red-circle',
                alt: 'error-icon',
              },
            });
            const subscription = popupRef.afterClosed$.subscribe((data) => {
              subscription.unsubscribe();
              this.store.dispatch(setIsLoading({ isLoading: false }));
            });
          } else if(err.status !== 425) {
            const popupRef = this.popupService.open('', <CaflerPopupSettingsModel>{
              id: 'popupId',
              title: 'internal-error.text-1',
              description: 'internal-error.text-2',
              icon: <CaflerIconSettingsModel>{
                id: 'error',
                name: 'caf-error-red-circle',
                alt: 'error-icon',
              },
            });
            const subscription = popupRef.afterClosed$.subscribe((data) => {
              subscription.unsubscribe();
              this.store.dispatch(setIsLoading({ isLoading: false }));
            });
          }
          return throwError(err.error);
        }),
      )
      .pipe(
        map<any, HttpEvent<any>>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
          }
          return evt;
        }),
      );
  }
}
