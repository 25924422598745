import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuCardModel } from 'src/app/models';

@Component({
  selector: 'app-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.scss']
})
export class MenuCardComponent implements OnInit {

  @Input() settings: MenuCardModel = <MenuCardModel>{};
  @Output() productSelected: EventEmitter<string> = new EventEmitter<string>();

  iconArrowSettings = { name: 'caf-arrow-right', alt: 'arrow-right' };
  iconDarkGreenCheckSettings = { name: 'caf-check-dark-green', alt: 'check',  width: '12px', height: '11px'};
  
  constructor() { }

  ngOnInit(): void {}

  selectCard(page: string) {
    this.productSelected.emit(page);
  }


}
