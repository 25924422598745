<form class="caf-md-container margin-top-forms" [formGroup]="fg">
  <!-- direccion recogida-->
  <div class="input-item">
    <span class="subtitle-1">{{'cafler.pickup-info.pickup-info.title' | translate}}</span>
    <div *ngIf="isB2B" class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-input-google-autosuggest
        class="col-start-sm-1 col-start-1 col-end-sm-10 col-end-10"
        (onValidAddress)="timesFailedPickupAddress=0"
        (onInvalidAddress)="countPickupErrors()"
        [settings]="settings.pickupAddress"
      >
      </caf-input-google-autosuggest>
      <button
        class="h-align-center v-align-center complete-address-button col-start-sm-11 col-start-11 col-end-sm-12 col-end-12"
        (click)="getB2BAddress(settings.pickupAddress.formControlName)"
      >
        <caf-icon [settings]="settingsExtra.locationIcon"></caf-icon>
      </button>
    </div>
    <caf-input-google-autosuggest
      *ngIf="!isB2B"
      (onValidAddress)="timesFailedPickupAddress=0"
      (onInvalidAddress)="countPickupErrors()"
      [settings]="settings.pickupAddress"
    >
    </caf-input-google-autosuggest>
    <caf-error
      *ngIf="timesFailedPickupAddress<=maxErrorsOnAddress"
      class="extra-margin-error"
      [settings]="settingsErrors.errorsPickupAddress"
    ></caf-error>
    <p *ngIf="timesFailedPickupAddress>maxErrorsOnAddress" class="error-message error-msg">
      {{'cafler.address-message-error-too-many-attempts-pickup.title' | translate }}
    </p>
  </div>
  <div class="input-item">
    <caf-input [settings]="settings.pickupAddressDetails"></caf-input>
  </div>
  <!-- same delivery address-->
  <div class="input-item">
    <caf-checkbox [settings]="settings.sameDeliveryAddress"></caf-checkbox>
  </div>
  <!-- direccion de devolucion-->
  <div *ngIf="isSameDeliveryAddress" class="input-item">
    <span class="subtitle-1">{{'cafler.page.wash.dropoff.info.title' | translate}}</span>
    <div *ngIf="isB2B" class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-input-google-autosuggest
        class="col-start-sm-1 col-start-1 col-end-sm-10 col-end-10"
        (onValidAddress)="timesFailedDropoffAddress=0"
        (onInvalidAddress)="countDropoffErrors()"
        [settings]="settings.dropoffAddress"
      >
      </caf-input-google-autosuggest>
      <button
        class="h-align-center v-align-center complete-address-button col-start-sm-11 col-start-11 col-end-sm-12 col-end-12"
        (click)="getB2BAddress(settings.dropoffAddress.formControlName)"
      >
        <caf-icon [settings]="settingsExtra.locationIcon"></caf-icon>
      </button>
    </div>
    <caf-input-google-autosuggest
      *ngIf="!isB2B"
      (onValidAddress)="timesFailedDropoffAddress=0"
      (onInvalidAddress)="countDropoffErrors()"
      [settings]="settings.dropoffAddress"
    >
    </caf-input-google-autosuggest>
    <caf-error
      *ngIf="timesFailedDropoffAddress<=maxErrorsOnAddress"
      class="extra-margin-error"
      [settings]="settingsErrors.errorsDropoffAddress"
    ></caf-error>
    <p *ngIf="timesFailedDropoffAddress>maxErrorsOnAddress" class="error-message error-msg">
      {{'cafler.address-message-error-too-many-attempts-dropoff.title' | translate }}
    </p>
    <p *ngIf="fg.errors?.sameAddress" class="error-message error-msg">
      {{'cafler.forms.error.same-address' | translate }}
    </p>
  </div>
  <div *ngIf="isSameDeliveryAddress" class="input-item">
    <caf-input [settings]="settings.dropoffAddressDetails"></caf-input>
  </div>
  <!-- fecha del servicio-->
  <div class="input-item">
    <span class="subtitle-1">{{'cafler.page.service-date.info.title' | translate}}</span>
    <caf-datepicker [settings]="settings.pickupDate"></caf-datepicker>
    <caf-error class="extra-margin-error" [settings]="settingsErrors.errorsPickupDate"></caf-error>
  </div>

  <!-- hora del servicio-->
  <div *ngIf="readyToDisplayHours && serviceTimeOptions.length > 0">
    <span class="subtitle-1">{{'cafler.page.service-time.info.title' | translate}}</span>
    <div class="caf-grid-row split-2-cols split-sm-2-cols">
      <caf-select #startServiceTimeComponent [settings]="settings.startPickUpTime"></caf-select>
      <caf-select #endServiceTimeComponent [settings]="settings.endPickUpTime"></caf-select>
    </div>
  </div>

  <div
    class="caf-grid-row split-2-cols split-sm-2-cols"
    *ngIf="readyToDisplayHours && serviceTimeOptions.length > 0"
  >
    <caf-error
      *ngIf="!combinationNotValid && !dayChangedFlag"
      class="extra-margin-error col-start-sm-1 col-start-1 col-end-sm-1 col-end-1"
      [settings]="settingsErrors.errorsStartPickUpTime"
    ></caf-error>
    <caf-error
      *ngIf="!combinationNotValid && !dayChangedFlag"
      class="extra-margin-error col-start-sm-2 col-start-2 col-end-sm-2 col-end-2"
      [settings]="settingsErrors.errorsEndPickUpTime"
    ></caf-error>
  </div>

  <p class="error-message" *ngIf="readyToDisplayHours && serviceTimeOptions.length === 0">
    {{"cafler.page.service-time.error-hours-not-available" | translate}}
  </p>
  <p class="error-message" *ngIf="dayChangedFlag">
    {{"cafler.page.service-time.error-day-changed" | translate}}
  </p>
  <p class="error-message" *ngIf="combinationNotValid">
    {{"cafler.page.service-time.error-not-compatible" | translate}}
  </p>
  <!-- vehicle information-->
  <div class="input-item">
    <span class="subtitle-1">{{'cafler.vehicle-info.title' | translate}}</span>
    <caf-input [settings]="settings.carPlaque"></caf-input>
    <caf-error [settings]="settingsErrors.errorsCarPlaque"></caf-error>
  </div>
  <div class="input-item">
    <caf-input [settings]="settings.brand"></caf-input>
  </div>
  <div class="input-item">
    <caf-input [settings]="settings.model"></caf-input>
  </div>
  <!-- contact data-->
  <div class="input-item">
    <span class="subtitle-1">{{'cafler.contact.title' | translate}}</span>
    <caf-input [settings]="settings.fullName"></caf-input>
    <caf-error [settings]="settingsErrors.errorsFullName"></caf-error>
  </div>
  <div class="caf-grid-row split-2-cols split-sm-2-cols">
    <caf-select
      class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
      [settings]="settings.countryPhoneCode"
    ></caf-select>
    <caf-input
      class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
      [settings]="settings.phone"
    ></caf-input>
  </div>
  <div class="caf-grid-row split-2-cols split-sm-2-cols">
    <caf-error
      class="col-start-sm-1 col-start-1 col-end-sm-4 col-end-2"
      [settings]="settingsErrors.errorsCountryPhoneCode"
    ></caf-error>
    <caf-error
      class="col-start-sm-5 col-start-3 col-end-sm-12 col-end-12"
      [settings]="settingsErrors.errorsPhone"
    ></caf-error>
  </div>
  <div *ngIf="!isB2B" class="input-item">
    <caf-input [settings]="settings.email"></caf-input>
    <caf-error [settings]="settingsErrors.errorsEmail"></caf-error>
  </div>
  <!-- aditional data-->
  <div class="input-item">
    <span class="subtitle-1">{{'cafler.pickup-info.comments.title' | translate}}</span>
    <caf-textarea [settings]="settings.additionalComments"></caf-textarea>
  </div>
</form>
