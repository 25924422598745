import { Injectable } from '@angular/core';

@Injectable()
export class B2b10HelperService {
  constructor() {}

  public getUserData() {
    return {
      vertical: 5,
      zoneId: '1ad0184f-e0cc-41d8-a1d1-1a2c29d1cc4f',
      userData: {},
    };
  }
}
