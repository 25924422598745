import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function forcePresentDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const time = control.value.getTime();

      if (time >= now.getTime()) {
        return null;
      } else {
        return { notPresent: true };
      }
    } else {
      return { empty: true };
    }
  };
}
