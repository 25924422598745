import { Component, OnInit, ViewChild } from '@angular/core';
import { selectCurrentCountry, selectCurrentLang, selectCurrentZone, selectIsB2B, selectMotStations, selectProductData, selectSelectedProduct, selectServiceTimeOptions } from '../../store/selectors';
import { first, pairwise, takeUntil } from 'rxjs/operators';
import { KamikazeActions, setIsLoading } from '../../store/actions';
import { CafNotificationSettingsModel, CaflerCheckboxSettingsModel, CaflerErrorSettingsModel, CaflerIconSettingsModel, CaflerSelectPriceSettingsModel, GoogleAddress, GoogleMapsService } from '@cafler/common-ui';
import { Store } from '@ngrx/store';
import { NavigationService } from '../../services/navigation.service';
import { PresentationFacade } from '../../facades/presentation.facade';
import { FormsHelperService } from '../../services/forms.service';
import { SingleDataHelperService } from '../../helpers/single-data-helper.service';
import { ReplaySubject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { operativeZonesURLByCountry } from '../../config/operativeZonesURLByCountry';
import { datepickerMaxDate } from '../../services/utils';
import { TimeFormatterService } from '../../helpers/time-formatter.service';

@Component({
  selector: 'app-itv-form-with-homologation',
  templateUrl: './itv-form-with-homologation.component.html',
  styleUrls: ['./itv-form-with-homologation.component.scss']
})
export class ItvFormWithHomologationComponent implements OnInit {

  private readonly destroySubjects$ = new ReplaySubject<void>(1);
  fg!: FormGroup;
  isLoading: boolean = true;
  isB2B: boolean = false;
  isSameDeliveryAddress!: boolean;
  timesFailedPickupAddress = 0;
  timesFailedDropoffAddress = 0;
  maxErrorsOnAddress = 4;
  startRangeAppointment: string = "";
  endRangeAppointment: string = "";
  serviceTimeOptions = [];
  currentCountry: any;
  initialForm: any;
  isDuplicated: boolean = false;
  operativeZonesURL: string = '';

  selectedProduct: any;
  vehicleOptionsOriginal: any;
  
  reformSelected = false;
  combinationNotValid: boolean = false;
  readyToDisplayHours = false;
  dayChangedFlag = false;
  timeRangeOption: string = "";
  firstNewFeatureTry: boolean = true;
  isSpecificHour: boolean = false;
  noSpecificHourAvailable: boolean = false;
  noRangeHourAvailable: boolean = false;
  pastDateWhenDuplicatingError = false;
  renderNewFeatureSpeechBubble?: boolean = false;
  duplicateSpecificHourError: boolean = false;


  @ViewChild('startServiceTimeComponent') startServiceTimeComponent: any;
  @ViewChild('endServiceTimeComponent') endServiceTimeComponent: any;

  explanationSettings: CafNotificationSettingsModel = {
    type: 'info',
    text: "cafler.homologation.message-text"
  }

  settings: any = {
    serviceType: {
      id: 'serviceType',
      formControlName: 'serviceType',
      placeholder: 'cafler.page.generic.service-type.placeholder',
      disabled: false,
      options: [],
    },
    homologationType: <CaflerSelectPriceSettingsModel>{
      id: 'homologationType',
      formControlName: 'homologationType',
      placeholder: 'cafler.page.homologation-mot.reform-type.placeholder',
      disabled: false,
      options: [],
      boldFirstOption: true,
    },
    documentationReady: <CaflerCheckboxSettingsModel>{
        id: 'documentationReady',
        formControlName: 'documentationReady',
        name: 'documentationReady',
        label: 'cafler.page.overview.documentation-ready.checkbox',
        labelAlign: 'right',
        required: false
    },
    fullName: {
      id: 'fullName',
      formControlName: 'fullName',
      placeholder: 'cafler.contact.name.placeholder',
      disabled: false,
    },
    countryPhoneCode: {
      id: 'countryPhoneCode',
      formControlName: 'countryPhoneCode',
      placeholder: 'cafler.contact.country-phone-code.placeholder',
      disabled: false,
      options: [],
    },
    vehicleType: {
      id: 'vehicleType',
      formControlName: 'vehicleType',
      placeholder: 'cafler.page.fuel.body-type.placeholder',
      disabled: false,
      options: [],
    },
    fuelType: {
      id: 'fuelType',
      formControlName: 'fuelType',
      placeholder: 'cafler.page.fuel.refuel.placeholder',
      disabled: false,
      options: [],
    },
    phone: {
      id: 'phone',
      formControlName: 'phone',
      placeholder: 'cafler.contact.phone.placeholder',
      disabled: false,
      type: 'phone',
    },
    email: {
      id: 'email',
      formControlName: 'email',
      placeholder: 'cafler.contact.email.placeholder',
      disabled: false,
      type: 'email',
    },
    carPlaque: {
      id: 'carPlaque',
      formControlName: 'carPlaque',
      placeholder: 'cafler.vehicle-info.plate.placeholder',
      disabled: false,
      type: 'text',
    },
    brand: {
      id: 'brand',
      formControlName: 'brand',
      placeholder: 'cafler.vehicle-info.brand.placeholder',
      disabled: false,
      required: true,
      type: 'text',
    },
    model: {
      id: 'model',
      formControlName: 'model',
      placeholder: 'cafler.vehicle-info.model.placeholder',
      disabled: false,
      required: true,
      type: 'text',
    },
    pickupAddress: {
      id: 'pickupAddress',
      formControlName: 'pickupAddress',
      placeholder: 'cafler.pickup-info.pickup-address.placeholder',
      options: { businessSuggestions: true, placeSuggestions: true },
    },
    pickupAddressDetails: {
      id: 'pickupAddressDetails',
      formControlName: 'pickupAddressDetails',
      placeholder: 'cafler.pickup-info.pickup-details.placeholder',
      disabled: false,
      required: false,
    },
    pickupDate: {
      id: 'pickupDate',
      formControlName: 'pickupDate',
      placeholder: 'cafler.pickup-info.pickup-day.placeholder',
      minDate: new Date(),
      maxDate: datepickerMaxDate(),
      daysDisabled: [6,0],
      icon: <CaflerIconSettingsModel>{
        alt: 'calendar',
        name: 'caf-notes-book-dark',
      },
      lang: 'currentLang'
    },
    startPickUpTime: {
      id: 'startPickUpTime',
      formControlName: 'startPickUpTime',
      placeholder: 'cafler.page.service-time.from-placeholder',
      disabled: false,
      options: [],
      required: true,
      leftIconSettings: {
        name: 'caf-clock-cafler',
        alt: 'Clock icon',
        height: '25px',
        width: '25px',
      },
    },
    endPickUpTime: {
      id: 'endPickUpTime',
      formControlName: 'endPickUpTime',
      placeholder: 'cafler.page.service-time.until-placeholder',
      disabled: true,
      options: [],
      required: false,
      leftIconSettings: {
        name: 'caf-clock-cafler',
        alt: 'Clock icon',
        height: '25px',
        width: '25px',
      },
    },
    selectTimeOption: {
      id: 'selectTimeOption',
      placeholder: 'cafler.page.service-time.select-time-option.placeholder',
      formControlName: 'selectTimeOption',
      disabled: false,
      required:false,
      options: [
        {
          key: 'range',
          text: 'cafler.page.service-time.select-time-option.range.text',
          subtext: 'cafler.page.service-time.select-time-option.range.subtext'
        },
        {
         key: 'specific',
         text: 'cafler.page.service-time.select-time-option.specific.text',
         subtext: 'cafler.page.service-time.select-time-option.specific.subtext'
        },

      ],
    },
    specificTimeRange: {
      id: 'specificTimeRange',
      placeholder: 'cafler.page.service-time.specific-time.selector.placeholder',
      formControlName: 'specificTimeRange',
      disabled: false,
      options: [],
      required:false,
      rightIconSettings: {
        name: 'caf-clock-cafler',
        alt: 'Clock icon',
        height: '25px',
        width: '25px',
      },
    },
    dropoffAddress: {
      id: 'dropoffAddress',
      formControlName: 'dropoffAddress',
      placeholder: 'cafler.pickup-info.pickup-address.placeholder',
      options: { businessSuggestions: true, placeSuggestions: true },
      required: false,
    },
    dropoffAddressDetails: {
      id: 'dropoffAddressDetails',
      formControlName: 'dropoffAddressDetails',
      placeholder: 'cafler.pickup-info.pickup-details.placeholder',
      disabled: false,
      required: false,
    },
    additionalComments: {
      id: 'additionalComments',
      formControlName: 'additionalComments',
      placeholder: 'cafler.mot-homologation.textarea.information',
      minlength: 0,
      disabled: false,
      required: false,
    }

  };
  settingsExtra: any = {
    locationIcon: <CaflerIconSettingsModel>{
      alt: 'location',
      name: 'caf-pin-location',
    },
    warningIcon: <CaflerIconSettingsModel> { name: 'caf-warning-exclamation', alt: 'warning', width: '14px', height: '14px'},

  };
  settingsErrors: any = {
    errorsFullName: <CaflerErrorSettingsModel>{
      formControlName: 'fullName',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsVehicleType: <CaflerErrorSettingsModel>{
      formControlName: 'vehicleType',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsFuelType: <CaflerErrorSettingsModel>{
      formControlName: 'vehicleType',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsCountryPhoneCode: <CaflerErrorSettingsModel>{
      formControlName: 'countryPhoneCode',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsPhone: <CaflerErrorSettingsModel>{
      formControlName: 'phone',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'pattern', text: 'cafler.forms.error.phone-pattern' },
        { key: 'minlength', text: 'cafler.forms.error.min-length' },
      ],
    },
    errorsEmail: <CaflerErrorSettingsModel>{
      formControlName: 'email',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'email', text: 'cafler.forms.error.email' },
      ],
    },
    errorsCarPlaque: <CaflerErrorSettingsModel>{
      formControlName: 'carPlaque',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' },{key:'invalidLicensePlate', text: 'cafler.forms.error.invalid-license-plate'}],
    },
    errorsModel: <CaflerErrorSettingsModel>{
      formControlName: 'model',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsBrand: <CaflerErrorSettingsModel>{
      formControlName: 'brand',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsPickupAddress: <CaflerErrorSettingsModel>{
      formControlName: 'pickupAddress',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'selectedAddress', text: 'cafler.forms.error.selected-address' },
        {
          key: 'streetNumberNeeded',
          text: 'cafler.address-message-error-missing-street-number.title',
        },

        { key: 'addressNumber', text: 'cafler.forms.error.address-number' },
        {
          key: 'checkCoordinatesWithinCaflerArea',
          text: 'cafler.forms.error.cafler-country-coverage',
        },
      ],
    },
    errorsDropoffAddress: <CaflerErrorSettingsModel>{
      formControlName: 'dropoffAddress',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'selectedAddress', text: 'cafler.forms.error.selected-address' },
        {
          key: 'streetNumberNeeded',
          text: 'cafler.address-message-error-missing-street-number.title',
        },

        { key: 'addressNumber', text: 'cafler.forms.error.address-number' },
        {
          key: 'checkCoordinatesWithinCaflerArea',
          text: 'cafler.forms.error.cafler-country-coverage',
        },
      ],
    },

  };

  settingsRadio: any = {
    withPeriodicMot: {
      name: 'periodicMot',
      formControlName: 'periodicMot',
      label: 'product.transfer.replacement.radio-button-yes',
      value: 'withPeriodicMot',
    },
    withoutPeriodicMot: {
      name: 'periodicMot',
      formControlName: 'periodicMot',
      label: 'product.transfer.replacement.radio-button-no',
      value: 'withoutPeriodicMot',
    }
  }


  constructor(
    private store: Store,
    private navigationService: NavigationService,
    private presentationFacade: PresentationFacade,
    private formsService: FormsHelperService,
    private googleMapsService: GoogleMapsService,
    private singleDataHelperService: SingleDataHelperService,
    private timeFormatterService: TimeFormatterService
  ) {

    this.initialForm = history.state.initialForm ? history.state.initialForm : null;
    this.isDuplicated = history.state.isDuplicated ? history.state.isDuplicated : false;

    this.fg = this.formsService.initializeForm(this.settings, this.initialForm);


    this.fg.addControl(this.settingsRadio.withPeriodicMot.formControlName, new FormControl('', Validators.required));
    this.fg.get(this.settingsRadio.withPeriodicMot.formControlName)?.setValue('withPeriodicMot');

    this.setMinimumPickUpDateOnTomorrow();

    this.store.select(selectCurrentZone)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((zone) => {
      if (zone) {
        this.fg.get('sameDeliveryAddress')?.setValue(false);
        if(!this.isDuplicated){
          this.fg.get('documentationReady')?.setValue(false);
        }

        this.fg.get('dropoffAddress')?.clearValidators();
        this.fg.get('dropoffAddress')?.updateValueAndValidity();

        // this.settings.pickupAddress.options.countryCode = zone?.RegionCode;
        const bbox = this.googleMapsService.getBoundingBox(
          zone?.UpperRightBoundingBoxCenterLatitude,
          zone?.UpperRightBoundingBoxCenterLongitude,
          zone?.LowerLeftBoundingBoxCenterLatitude,
          zone?.LowerLeftBoundingBoxCenterLongitude,
        );
        this.settings.pickupAddress.options.bounds = bbox;
        this.settings.dropoffAddress.options.bounds = bbox;
      }
    });

    this.store.select(selectCurrentCountry)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((country: any) => {
      if (country) {
        // setting default prefix code number
        this.fg.get('countryPhoneCode')?.setValue(country.InternationalPhonePrefix);
        this.currentCountry = country;

        const isoCode: string = country.RegionIsoCode
        if(isoCode === 'esp' || isoCode === 'gbr' || isoCode === 'fra'){
          this.operativeZonesURL = operativeZonesURLByCountry[isoCode]
        }
      }
    });

    this.store.select(selectCurrentLang)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((lang) => {
      if(lang){
        this.settings.pickupDate = {
          ...this.settings.pickupDate,
          lang: lang.split('-')[0]
        }
      }
    })

    this.presentationFacade.getCoreDataCatalog('TransferTypeEnum')
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((vehicleTypes) => {
      if (vehicleTypes) {
        this.settings.vehicleType.options = vehicleTypes.map((t: any) => ({
          key: t.key,
          text: `cafler.product-type.media-larga-distancia.vehicle-type.${t.text}`,
        }));

        this.vehicleOptionsOriginal = this.settings.vehicleType.options

        this.settings.fuelType.options = this.presentationFacade.getFuelTypeFormattedOptions(this.settings.vehicleType.options[0].key, this.currentCountry.RegionIsoCode);
      }
    });

    if (this.isDuplicated) {
      this.reformSelected = true;
      this.updateVehiclesByReformType(this.initialForm.homologationType);
      const periodicMotValue = this.initialForm.periodicMot? 'withPeriodicMot' : 'withoutPeriodicMot';
      this.fg.get('periodicMot')?.patchValue(periodicMotValue);
      this.validateFormAddress(this.settings.pickupAddress.formControlName)
    }
  }

  ngOnInit() {
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: true });
    this.store.dispatch({ type: KamikazeActions.SetNextPage, nextPage: 'overview' });
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'homologation' });
    this.store.dispatch({ type: KamikazeActions.ClearAvailableToppings });

    this.store.dispatch({
      type: KamikazeActions.SetFormValidity,
      isFormValid: this.formsService.checkCurrentFormValidity(),
    });

    this.store.select(selectIsB2B)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((isB2B) => {
      this.isB2B = isB2B;
    });

    this.store.select(selectSelectedProduct)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((product) => {
      if (product) {
        this.selectedProduct = product;
        this.fg.controls.serviceType.setValue(product.ordersManagerProductId);
        this.navigationService.currentPageTitle = 'Cafler.product-type.mot-with-homologation.tittle';
      }
    });

    // Get phone prefixes
    this.presentationFacade
      .getCountryCodes()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((countryCodes) => {
        this.settings.countryPhoneCode.options = countryCodes;
      });

    this.singleDataHelperService
      .getCurrentVerticalProducts()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((products:any) => {
        this.settings.serviceType.options = products.technicalInspection.map((product: any) => {
          return { key: product.ordersManagerProductId, text: product.title, alias: product.alias };
        });
      });

    this.fg
      .get(this.settings.homologationType.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((newValue) => {
        this.updateVehiclesByReformType(newValue);
      });

    this.fg
      .get(this.settings.vehicleType.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((newValue) => {
        this.fg.controls.fuelType.setValue(null);

        this.settings.fuelType.options = this.presentationFacade.getFuelTypeFormattedOptions(newValue, this.currentCountry.RegionIsoCode);
      });

    // Get B2B data and fill form
    this.store
      .select(selectProductData)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((productData) => {
        if (this.isLoading) {
          if (!!productData) {
            this.formsService.loadFormGroupData(this.fg, productData, this.isLoading);
            this.isSameDeliveryAddress = productData.sameDeliveryAddress;
            this.isLoading = false;
            if(this.selectedProduct){
              this.fg.controls.serviceType.setValue(this.selectedProduct.ordersManagerProductId);
            }
          } else {
            if (this.isB2B) {
              this.formsService.preloadB2BData(this.fg);
            }
          }
        }
      });

    // Remove holidays from datepicker
    this.presentationFacade.getHolidays()
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((holidays) => {
      let holidaysDates = holidays.map((day: any) => {
        return new Date(day);
      });
    });

    this.fg
    .get(this.settings.pickupDate.formControlName)
    ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
    .pipe(first())
    .subscribe(() => {
      // set range time as the default option
      this.fg
      .get(this.settings.selectTimeOption.formControlName)?.setValue('range')
      this.readyToDisplayHours = true;
    });

    this.store.select(selectServiceTimeOptions).subscribe((serviceTimeOptions: any) => {
      if(serviceTimeOptions !== undefined){
        this.serviceTimeOptions = serviceTimeOptions;
      }
    })

  this.fg
    .get(this.settings.pickupDate.formControlName)
    ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
    .pipe(pairwise())
    .subscribe(([prev, next]) => {
      if((prev.getTime() === next.getTime())){
        return;
      }
      if (this.readyToDisplayHours) {
        this.resetServiceTimeStyles(true);
        this.getSlotHours(this.isSpecificHour);
      }
      this.readyToDisplayHours = true;
    });

    this.fg
    .get(this.settings.selectTimeOption.formControlName)
    ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
    .pipe(first())
    .subscribe((timeOption) => {

      this.isSpecificHour = timeOption === 'specific';
      this.getSlotHours(this.isSpecificHour);
    });
  this.fg
    .get(this.settings.selectTimeOption.formControlName)
    ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
    .pipe(pairwise())
    .subscribe(([prev, next]) => {
      if(prev === next){
        return;
      }

      this.firstNewFeatureTry = false;
      this.isSpecificHour = next === 'specific';

      this.getSlotHours(this.isSpecificHour);
      this.resetTimeAndErrors();
    });

  this.fg
    .get(this.settings.specificTimeRange.formControlName)
    ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
    .subscribe((newValue) => {
      this.updateSpecificHours(newValue);
    });

    this.fg
    .get(this.settings.startPickUpTime.formControlName)
    ?.statusChanges.pipe(takeUntil(this.destroySubjects$))
    .subscribe((status) => {
      if(this.timeRangeOption === 'range'){
        this.changeEndPickupTimeDisability(status)
      }else{
        this.dayChangedFlag = false;
      }
    });

  this.fg
    .get(this.settings.endPickUpTime.formControlName)
    ?.statusChanges.pipe(takeUntil(this.destroySubjects$))
    .subscribe((status) => {
      if (status === 'VALID') {
        this.combinationNotValid = false;
      }
    });

  this.fg
    .get(this.settings.startPickUpTime.formControlName)
    ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
    .subscribe((newValue) => {

      if(this.timeRangeOption === 'range'){
        this.updateEndRangeHours(newValue);
        return;
      }
    });

    this.navigationService.scrollToTop();
    this.navigationService.currentPageTitle = 'Cafler.product-type.mot-with-homologation.tittle';

    this.fg.statusChanges.pipe(takeUntil(this.destroySubjects$)).subscribe((status) => {
      this.formsService.handleFormValidity(status, this.fg.value);
    });

    this.presentationFacade.getHomologationTypes()
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((homologationTypes) => {
      if (homologationTypes) {
        this.settings.homologationType.options = homologationTypes;
      }
    });

    this.fg
      .get('sameDeliveryAddress')
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((value: boolean) => {
        this.isSameDeliveryAddress = value;
        if (this.isSameDeliveryAddress) {
          this.formsService.addValidatorsAutosuggest(this.fg, 'dropoffAddress');
          this.fg.get('dropoffAddress')?.updateValueAndValidity();
        } else {
          this.fg.get('dropoffAddress')?.clearValidators();
          this.fg.get('dropoffAddress')?.setValue('');
          this.fg.get('dropoffAddressDetails')?.setValue('');
          this.fg.get('dropoffAddress')?.updateValueAndValidity();
        }
      });
  }
  getB2BAddress(formControlName: string) {
    this.presentationFacade
      .getAddressB2BToAutosuggest()
      .pipe(first())
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((addressObj) => {
        this.fg.get(formControlName)?.setValue(addressObj);
      });
  }
  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }

  countPickupErrors() {
    this.timesFailedPickupAddress = this.timesFailedPickupAddress + 1;
  }

  countDropoffErrors() {
    this.timesFailedDropoffAddress = this.timesFailedDropoffAddress + 1;
  }

  validateFormAddress(formControlName: string){
    const addressFormRef = this.fg.get(formControlName)?.value as GoogleAddress;
    addressFormRef.googleObject.isValidAddress = true
  }

  onValidAddress(value: GoogleAddress, formControlName: string){
    if(formControlName === 'pickupAddress'){
      this.timesFailedPickupAddress = 0;
    }
    if(formControlName === 'dropoffAddress'){
      this.timesFailedDropoffAddress = 0;
    }
    this.validateOperativeZone(value, formControlName)
  }

  validateOperativeZone(value: GoogleAddress, formControlName: string) {
    this.store.dispatch(setIsLoading({ isLoading: true }));
    this.presentationFacade
      .validateOperativeZone(value)
      .pipe(first())
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((response: any) => {
        this.fg.get(formControlName)?.setErrors(null);
        this.store.dispatch(setIsLoading({ isLoading: false }));
      },
      (error:any) => {
        this.fg.get(formControlName)?.setErrors({ checkCoordinatesWithinCaflerArea: true });
        this.store.dispatch(setIsLoading({ isLoading: false }));
      });
  }

  changePeriodicMot(type: string){
    this.fg.get(this.settingsRadio.withPeriodicMot.formControlName)?.setValue(type);
  }

  updateEndRangeHours(valueSelected: any) {
    this.store.select(selectServiceTimeOptions).subscribe((serviceTimeOptions: any) => {
      this.serviceTimeOptions = serviceTimeOptions;

      if(!serviceTimeOptions || serviceTimeOptions && serviceTimeOptions.length === 0) return

      // Convert the value in a selectable element
      let elementSelected = serviceTimeOptions.find((el: any) => el.key === valueSelected);

      // Update the end time select with new values
      this.settings.endPickUpTime.options = serviceTimeOptions.slice(
        this.settings.startPickUpTime.options.indexOf(elementSelected) + 3,
      );

      // Check if the end value is still valid
      let startPickUpTimeControl = this.fg.get('startPickUpTime');
      let endPickUpTimeControl = this.fg.get('endPickUpTime');

      const endPickUpTime = serviceTimeOptions.slice(3);

      let isEndHourInValidRange = endPickUpTime.find(
        (el: any) => el.key === endPickUpTimeControl?.value,
      );

      // Update end value
      if (startPickUpTimeControl?.valid && endPickUpTimeControl?.value && !isEndHourInValidRange) {
        this.fg.get('endPickUpTime')?.patchValue(null);
        this.combinationNotValid = true;
      }
    })
  }


  updateSpecificHours(valueSelected: any){
    this.store.select(selectServiceTimeOptions).subscribe((serviceTimeOptions: any) => {
      this.serviceTimeOptions = serviceTimeOptions;

      if(!serviceTimeOptions || serviceTimeOptions && serviceTimeOptions.length === 0) return

      let elementSelected: any = serviceTimeOptions.find((el: any) => el.key === valueSelected);

      if(elementSelected !== undefined && !elementSelected.disabled){
        this.settings.startPickUpTime.options = elementSelected.key;
        this.settings.endPickUpTime.options = null;

        this.fg.controls.startPickUpTime.setValue(elementSelected.key);
        this.fg.controls.endPickUpTime.setValue(null);
      }
    })

  }


  resetServiceTimeStyles(dayChangedMode?: boolean) {
    if(this.startServiceTimeComponent !== undefined){
      this.startServiceTimeComponent.resetValue();
    }
    if(this.endServiceTimeComponent !== undefined){
      this.endServiceTimeComponent.resetValue();
    }

    this.combinationNotValid = false;
    if (dayChangedMode && this.serviceTimeOptions.length > 0) {
      this.dayChangedFlag = true;
    } else {
      this.dayChangedFlag = false;
    }
  }


  getSlotHours(isSpecificHour: boolean) {
    this.presentationFacade
      .getPickupTimeOptions(
        this.fg.get(this.settings.pickupDate.formControlName)?.value,
        isSpecificHour,
      )
      .pipe(first())
      .subscribe((pickupTimeOptions: any) => {

        let timeOptions = JSON.parse(JSON.stringify(pickupTimeOptions));
        this.timeRangeOption = this.fg.get(this.settings.selectTimeOption.formControlName)?.value;

        if(isSpecificHour){
          let disabledHours = 0;
          timeOptions.map((entry: any) => {
              let date = new Date(entry.key.substring(0,19));
              let newDate = this.timeFormatterService.increaseTime(date, 15);

              entry.text = entry.text + ' - ' + this.timeFormatterService.getTimeFromDate(newDate);
              if(entry.disabled){
                disabledHours++;
              }
            })
            this.noSpecificHourAvailable = disabledHours === timeOptions.length;
            this.settings.specificTimeRange.disabled = this.noSpecificHourAvailable;

            this.settings.specificTimeRange.options = timeOptions;
            this.fg.controls[this.settings.endPickUpTime.formControlName].clearValidators();

            if(this.isDuplicated){
              const time = this.fg.get(this.settings.specificTimeRange.formControlName)?.value

              let elementSelected = timeOptions.find((el: any) => el.key === time);

              if(!elementSelected.disabled){
                this.fg.get(this.settings.specificTimeRange.formControlName)?.setValue(time)
              }else{
                this.duplicateSpecificHourError = true
              }
            }

             this.fg.controls[this.settings.endPickUpTime.formControlName].clearValidators();
        }else{
          this.settings.startPickUpTime.options = timeOptions.slice(0, -3);
          this.settings.endPickUpTime.options = timeOptions.slice(3);

          this.settings.specificTimeRange.options = timeOptions;
          this.fg.controls[this.settings.endPickUpTime.formControlName].addValidators(Validators.required);

          this.noRangeHourAvailable = timeOptions.length < 3;
          this.settings.startPickUpTime.disabled = this.noRangeHourAvailable;

          if(this.isDuplicated){
            const pickUpTime = this.fg.get(this.settings.startPickUpTime.formControlName)?.value
            const dropoffTime = this.fg.get(this.settings.endPickUpTime.formControlName)?.value

            let elementSelectedA = timeOptions.find((el: any) => el.key === pickUpTime);
            let elementSelectedB = timeOptions.find((el: any) => el.key === dropoffTime);

            if(!elementSelectedA.disabled){
              this.fg.get(this.settings.startPickUpTime.formControlName)?.setValue(pickUpTime)
            }else{
              this.duplicateSpecificHourError = true
            }
            if(!elementSelectedB.disabled){
              this.fg.get(this.settings.endPickUpTime.formControlName)?.setValue(dropoffTime)
            }else{
              this.duplicateSpecificHourError = true
            }
          }

        }

        this.store.dispatch({
          type: KamikazeActions.SetServiceTimeOptions,
          serviceTimeOptions: timeOptions,
        });

      });
  }

  changeEndPickupTimeDisability(status: string){
    if (status === 'VALID') {
      this.settings.endPickUpTime.disabled = false;
      this.dayChangedFlag = false;
    } else {
      this.settings.endPickUpTime.disabled = true;
    }
  }

  resetTimeAndErrors(){
    this.fg.get('startPickUpTime')?.patchValue(null);
    this.fg.get('endPickUpTime')?.patchValue(null);
    this.fg.get('specificTimeRange')?.patchValue(null);
    this.resetServiceTimeStyles();
    this.combinationNotValid = false;
  }

  updateVehiclesByReformType(reformType: number){
    // if its remolque ball we remove motorbike from vehicleTypes
    if(reformType === 1 || reformType === 2) {
      this.settings.vehicleType.options = this.settings.vehicleType.options.filter(
        (vehicleType: any) => vehicleType.key !== 20 && vehicleType.key !== 21);
        this.fg.controls.vehicleType.setValue(null);
    }
    else {
      this.settings.vehicleType.options = this.vehicleOptionsOriginal;
    }

    this.reformSelected = true;
  }

  setMinimumPickUpDateOnTomorrow(){
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.settings.pickupDate.minDate = tomorrow;
  }
}
