import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigablePopUpRef } from './navigable-popup-ref';
import { CaflerButtonSettingsModel, CaflerIconSettingsModel } from '@cafler/common-ui';
import { NavigablePopupSettingsModel } from './model/navigable-popup-settings.model';
import { MenuCardModel, ToppingSummaryModel } from 'src/app/models';

import { allToppingProducts } from '../wash-topping-configurator/allToppingProducts';
import { HelperService } from 'src/app/services/helper.service';
import { Store } from '@ngrx/store';
import { KamikazeActions } from 'src/app/store/actions';
import { ToppingData } from 'src/app/models/fe/toppingPostData.model';
import { selectIsB2B, selectToppingsAdded, selectToppingsPicked } from 'src/app/store/selectors';
import { ToppingEnum } from '../../Enums/topping.enum';
import { ToppingSelectorModel } from 'src/app/models/fe/topping-selector.model';
import { DisplayPopUpToppingModel } from 'src/app/models/fe/Overview/displayPopUpTopping.model';
import { DisplayType } from 'src/app/models/fe/Overview/displayType.enum';
import { toppingsNameEnum } from '../../Enums/toppings.enum';
import { CustomCurrencyPipe } from 'src/app/pipes/currency.pipe';
import { TimeFormatterService } from 'src/app/helpers/time-formatter.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'navigable-popup',
  templateUrl: './navigable-popup.component.html',
  styleUrls: ['./navigable-popup.component.scss', './popup.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigablePopupComponent implements OnInit,  OnDestroy {
  private readonly destroySubjects$ = new ReplaySubject<void>(1);

  contentType: 'template' | 'string' | 'component' | undefined;
  content: any;
  settings!: NavigablePopupSettingsModel;
  noToppingsAvailable: boolean = false;
  isB2B: boolean = false;
  closeIcon = <CaflerIconSettingsModel>{
    name: 'caf-close',
    alt: 'Close icon',
  };

  page: string = 'Home';
  availableToppings: any;
  displayType = DisplayType;
  toppingsEnum = toppingsNameEnum;

  cancelButton = <CaflerButtonSettingsModel>{
    id: 'noButton',
    text: 'cafler.page.toppings.popup.Cancel.button',
  };
  backButton = <CaflerButtonSettingsModel>{
    id: 'backButton',
    text: 'cafler.page.toppings.popup.no-services.Back.button',
  };

  displayToppings: DisplayPopUpToppingModel[] = [];

  washingToppingSettings = <MenuCardModel>{
    id: toppingsNameEnum.WASH,
    category: toppingsNameEnum.WASH,
    title: 'cafler.toppings.main-menu.washing.title',
    description:
      'cafler.toppings.main-menu.washing.description',
    elementsAdded: [],
    availableToppings: [],
  };
  refuelToppingSettings = <any>{
    id: '',
    category: toppingsNameEnum.REFUEL,
    title: 'cafler.toppings.main-menu.refuel.title',
    description:
      'cafler.toppings.refuel.description',
    type: null,
    amount: null,
    vehicleType: null,
    elementsAdded: [],
  };

  insuranceToppingSettings = <any>{
    id: '',
    category: toppingsNameEnum.INSURANCE,
    title: 'cafler.toppings.main-menu.insurance.title',
    description:
      'cafler.toppings.insurance.description',
    elementsAdded: [],
    brand: '',
    model: '',
    firstName: '',
    lastName: '',
    licensePlate: '',
    insuranceDurationAmount: 0,
    insuranceDurationType: '',
    documentNumber: '',
    documentType: '',
    vehicleRegistrationCountry: ''
  };

  tireInflationSettings = <ToppingSelectorModel>{
    id: 'TireInflation',
    title: 'cafler.toppings.main-menu.tire-inflation.title',
    description: 'cafler.toppings.main-menu.tire-inflation.description',
    toppingCode: toppingsNameEnum.TIRE_INFLATION,
    serviceDuration: '',
    category: 'Tire',
    price: '',
    additionalInformation: [],
    disabled: false,
    checked: false,
  }

  caflerFreshSettings = <ToppingSelectorModel>{
    id: 'CaflerFresh',
    title: 'cafler.toppings.main-menu.cafler-fresh.title',
    description: 'cafler.toppings.main-menu.cafler-fresh.description',
    toppingCode: toppingsNameEnum.CAFLER_FRESH,
    serviceDuration: '',
    category: 'CaflerFresh',
    price: '',
    additionalInformation: [],
    disabled: false,
    checked: false,
  }

  checklistSettings = <ToppingSelectorModel>{
    id: 'Checklist',
    title: 'cafler.toppings.main-menu.checklist.title',
    description: 'cafler.toppings.main-menu.checklist.description',
    toppingCode: toppingsNameEnum.CHECKLIST,
    serviceDuration: '',
    category: 'checklist',
    price: '',
    additionalInformation: [
      "cafler.toppings.washing.features.oil-level",
      "cafler.toppings.washing.features.wiper-blades",
      "cafler.toppings.washing.features.windshield-wipers",
      "cafler.toppings.washing.features.windshield-washer-level",
      "cafler.toppings.washing.features.tire-status",
      "cafler.toppings.washing.features.tire-presure",
      "cafler.toppings.washing.features.dashboard-lights",
      "cafler.toppings.washing.features.lights"
    ],
    disabled: false,
    checked: false,
  }

  noToppingsAvailableIcon = "assets/img/error-icon.png";

  toppingsPicked: any = [];
  toppingsAdded: any = [];

  html: any;

  constructor(
    private ref: NavigablePopUpRef,
    private helperService: HelperService,
    private customCurrencyPipe: CustomCurrencyPipe,
    private timeFormatterService: TimeFormatterService,
    private store: Store,
    ) {
    this.store.select(selectToppingsPicked).subscribe((toppingsPicked) => {
      this.toppingsPicked = toppingsPicked;
    });
    this.store.select(selectToppingsAdded).subscribe((toppingsAdded) => {
      this.toppingsAdded = toppingsAdded;
    });
  }

  cancel() {
    this.ref.close({ info: 'cancel' });
  }
  confirm() {
    this.ref.close({ info: 'confirm' });
  }


  initWashingTopping() {
    const toppingProducts = this.availableToppings[toppingsNameEnum.WASH].ToppingProducts;
    for(let toppingCategory in toppingProducts){
      toppingProducts[toppingCategory].forEach((e: any) => {

        let toppingTemplate = allToppingProducts.get(e.ToppingCode);

        if(e.DisplayCode === 'motorbike-wash-topping'){
          toppingTemplate = allToppingProducts.get('WashTunnel_motorcycle');
        }
        
        if (toppingTemplate !== undefined) {
          toppingTemplate.id = e.ToppingId;
          toppingTemplate.serviceDuration = this.timeFormatterService.extractDisplayStringFromDate(e.Duration);
          toppingTemplate.price = this.customCurrencyPipe.convert(
            this.isB2B ? e.FreeTaxPrice.toFixed(2) : e.Price.toFixed(2),
            this.helperService.currentCountry.PreferredRegionCurrencySymbol
          )
        }
        
        if(this.settings.selectedToppings?.find((t: any) => t.Code === e.ToppingCode)){
          this.washingToppingSettings.elementsAdded.push({id: e.ToppingId, code: e.ToppingCode})
        }
        
        this.washingToppingSettings.availableToppings.push(toppingTemplate);
        
      });
    }
    
    //this.menuCards.push(this.washingToppingSettings)
    this.displayToppings.push({
      id: toppingsNameEnum.WASH,
      displayType: DisplayType.CARD,
      settings: this.washingToppingSettings,
    });
  }

  initRefuelTopping() {
    const refuelToppings = this.availableToppings[toppingsNameEnum.REFUEL].ToppingProducts.HasConfiguration;
    this.refuelToppingSettings.availableToppings = refuelToppings

    let serviceConfiguration: {type: string | null, amount: string | null} = {
      type: null,
      amount: null
    };

    const refuelConf: any = this.settings.selectedToppings?.find(
      (t: any) => t.Code === ToppingEnum.refuel
    );

    if(refuelConf) {
      serviceConfiguration = refuelConf.ServiceConfiguration;
    }

    this.refuelToppingSettings.id = refuelToppings[0].ToppingId
    this.refuelToppingSettings.vehicleType = refuelToppings[0].DisplayCode.split('-')[1]
    this.refuelToppingSettings.amount = serviceConfiguration.amount
    this.refuelToppingSettings.type = serviceConfiguration.type
      
    if(serviceConfiguration.amount || serviceConfiguration.type){
      this.refuelToppingSettings.elementsAdded.push(serviceConfiguration)
    }
    this.displayToppings.push({
      id: 'refuel',
      displayType: DisplayType.CARD,
      settings: this.refuelToppingSettings,
    });
  }

  initTireInflationTopping() {
    const tireInflationToppings = this.availableToppings[toppingsNameEnum.TIRE_INFLATION].ToppingProducts.NoConfiguration;
    
    this.tireInflationSettings.id = tireInflationToppings[0].ToppingId;
    this.tireInflationSettings.price = this.customCurrencyPipe.convert(
      this.isB2B ? tireInflationToppings[0].FreeTaxPrice.toFixed(2) : tireInflationToppings[0].Price.toFixed(2),
      this.helperService.currentCountry.PreferredRegionCurrencySymbol
    )
   
    let isTireInflationAdded = this.toppingsAdded.find((elem : any) => elem.ProductId === tireInflationToppings[0].ToppingId) !== undefined;
    
    if(isTireInflationAdded) {
      this.tireInflationSettings.checked = true;
      this.tireInflationSettings.elementsAdded = 1;
    } 

    this.displayToppings.push({
      id: toppingsNameEnum.TIRE_INFLATION,
      displayType: DisplayType.SELECTOR,
      settings: this.tireInflationSettings,
    });
  }

  initCaflerFreshTopping() {
    const caflerFreshToppings = this.availableToppings[toppingsNameEnum.CAFLER_FRESH].ToppingProducts.NoConfiguration;
    
    this.caflerFreshSettings.id = caflerFreshToppings[0].ToppingId;
    this.caflerFreshSettings.price = this.customCurrencyPipe.convert(
      this.isB2B ? caflerFreshToppings[0].FreeTaxPrice.toFixed(2) : caflerFreshToppings[0].Price.toFixed(2),
      this.helperService.currentCountry.PreferredRegionCurrencySymbol
    )
   
    let isCaflerFreshAdded = this.toppingsAdded.find((elem : any) => elem.ProductId === caflerFreshToppings[0].ToppingId) !== undefined;
    
    if(isCaflerFreshAdded) {
      this.caflerFreshSettings.checked = true;
      this.caflerFreshSettings.elementsAdded = 1;
    } 

    this.displayToppings.push({
      id: toppingsNameEnum.CAFLER_FRESH,
      displayType: DisplayType.SELECTOR,
      settings: this.caflerFreshSettings,
    });
  }

  initChecklistTopping() {
    const checklistToppings = this.availableToppings[toppingsNameEnum.CHECKLIST].ToppingProducts.NoConfiguration;

    this.checklistSettings.id = checklistToppings[0].ToppingId;
    this.checklistSettings.price = this.customCurrencyPipe.convert(
      this.isB2B ? checklistToppings[0].FreeTaxPrice.toFixed(2) : checklistToppings[0].Price.toFixed(2),
      this.helperService.currentCountry.PreferredRegionCurrencySymbol
    )

    let isChecklistAdded = this.toppingsAdded.find((elem : any) => elem.ProductId === checklistToppings[0].ToppingId) !== undefined;

    if(isChecklistAdded) {
      this.checklistSettings.checked = true;
      this.checklistSettings.elementsAdded = 1;
    }

    this.displayToppings.push({
      id: toppingsNameEnum.CHECKLIST,
      displayType: DisplayType.SELECTOR,
      settings: this.checklistSettings,
    });
  }

  initInsuranceTopping(){
    const insuranceToppings = this.availableToppings[toppingsNameEnum.INSURANCE].ToppingProducts.HasConfiguration;
    this.insuranceToppingSettings.availableToppings = insuranceToppings;
    
    let serviceConfiguration:any = {
      brand: null,
      documentNumber: null,
      documentType: null,
      firstName: null,
      insuranceDurationAmount: null,
      insuranceDurationType: null,
      lastName: null,
      licensePlate: null,
      model : null,
      vehicleRegistrationCountry: null,
    };

    const insuranceConf: any = this.settings.selectedToppings?.find(
      (t: any) => t.Code === ToppingEnum.insurance
    );
    

    if(insuranceConf) {
      serviceConfiguration = insuranceConf.ServiceConfiguration;
      this.insuranceToppingSettings.elementsAdded.push(serviceConfiguration);
    }

    this.insuranceToppingSettings.id = insuranceToppings[0].ToppingId,
    this.insuranceToppingSettings.brand = serviceConfiguration.brand,
    this.insuranceToppingSettings.documentNumber = serviceConfiguration.documentNumber,
    this.insuranceToppingSettings.documentType = serviceConfiguration.documentType,
    this.insuranceToppingSettings.firstName = serviceConfiguration.firstName,
    this.insuranceToppingSettings.insuranceDurationAmount = serviceConfiguration.insuranceDurationAmount,
    this.insuranceToppingSettings.insuranceDurationType = serviceConfiguration.insuranceDurationType,
    this.insuranceToppingSettings.lastName = serviceConfiguration.lastName,
    this.insuranceToppingSettings.licensePlate = serviceConfiguration.licensePlate,
    this.insuranceToppingSettings.model = serviceConfiguration.model,
    this.insuranceToppingSettings.vehicleRegistrationCountry = serviceConfiguration.vehicleRegistrationCountry,

    
    this.displayToppings.push({
      id: toppingsNameEnum.INSURANCE,
      displayType: DisplayType.CARD,
      settings: this.insuranceToppingSettings,
    });
  }
  
  ngOnInit() {

    this.store.select(selectIsB2B)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((isB2B) => {
      this.isB2B = isB2B;
    });

    this.settings = this.ref.data;
    this.availableToppings = this.settings.availableToppings;
    this.noToppingsAvailable = Object.keys(this.availableToppings).length === 0;
    
    if (toppingsNameEnum.WASH in this.availableToppings) {

      this.initWashingTopping();
    }
    
    if (toppingsNameEnum.REFUEL in this.availableToppings) {
     this.initRefuelTopping();
    }

    if (toppingsNameEnum.TIRE_INFLATION in this.availableToppings) {
      this.initTireInflationTopping();
    }
   
    if(toppingsNameEnum.INSURANCE in this.availableToppings) {
      this.initInsuranceTopping();
    }

    if (toppingsNameEnum.CHECKLIST in this.availableToppings) {
      this.initChecklistTopping();
    }

    if(toppingsNameEnum.CAFLER_FRESH in this.availableToppings) {
      this.initCaflerFreshTopping();
    }
  }

  setPage(page: string) {
    this.page = page;
  }

  productSelect(event: any){
    this.setPage(event)
  }

  productSelection(event: {clicked: boolean, id: string, code: string}) {
    
    const {clicked, id, code} = event;
    
    switch(code) {
      case toppingsNameEnum.TIRE_INFLATION:
        if(clicked){
          this.addTireInflation(id);
          this.tireInflationSettings.elementsAdded = 1;
        }else{
          this.removeTireInflation(id);
          this.tireInflationSettings.elementsAdded = 0;
        }    
        break;
      case toppingsNameEnum.CHECKLIST:
        if(clicked){
          this.addChecklist(id);
          this.checklistSettings.elementsAdded = 1;
        }else{
          this.removeChecklist(id);
          this.checklistSettings.elementsAdded = 0;
        }
        break;
      case toppingsNameEnum.CAFLER_FRESH:
        if(clicked){
          this.addCaflerFresh(id);
          this.caflerFreshSettings.elementsAdded = 1;
        }else{
          this.removeTireInflation(id);
          this.caflerFreshSettings.elementsAdded = 0;
        }    
        break;
    }
  }

  addCaflerFresh(id: string) {
    const category = 'CaflerFresh';
    let toppingsData:ToppingData[] = []

    toppingsData.push({ProductId: id, Code: ToppingEnum.cafler_fresh, Category: category, ServiceConfiguration: {}})

    const otherToppings = this.toppingsPicked.filter((e:any) => e.Category != category);
    const sendTopping = [... otherToppings, ...toppingsData]
    
    this.store.dispatch({ type: KamikazeActions.SetToppingsPicked, toppingsPicked: sendTopping });
  }

  addChecklist(id: string) {
    const category = 'checklist';
    let toppingsData:ToppingData[] = []

    toppingsData.push({ProductId: id, Code: ToppingEnum.checklist, Category: category, ServiceConfiguration: {}})

    const otherToppings = this.toppingsPicked.filter((e:any) => e.Category != category);
    const sendTopping = [... otherToppings, ...toppingsData]
    
    this.store.dispatch({ type: KamikazeActions.SetToppingsPicked, toppingsPicked: sendTopping });
  }

  removeChecklist(id: string){
    let toppingsWithoutChecklist: [] = this.toppingsPicked.filter((e:any) => e.ProductId != id);
    this.store.dispatch({ type: KamikazeActions.SetToppingsPicked, toppingsPicked: toppingsWithoutChecklist });
  }

  addTireInflation(id: string) {
    const category = 'tireInflation';
    let toppingsData:ToppingData[] = [] 

    toppingsData.push({ProductId: id, Code: ToppingEnum.tireInflation, Category: category, ServiceConfiguration: {}})

    const otherToppings = this.toppingsPicked.filter((e:any) => e.Category != category);
    const sendTopping = [... otherToppings, ...toppingsData]

    this.store.dispatch({ type: KamikazeActions.SetToppingsPicked, toppingsPicked: sendTopping });
  }

  removeCaflerFresh(id: string) {
    let toppingsWithoutCaflerFresh: [] = this.toppingsPicked.filter((e:any) => e.ProductId != id);
    this.store.dispatch({ type: KamikazeActions.SetToppingsPicked, toppingsPicked: toppingsWithoutCaflerFresh });
  }
  

  removeTireInflation(id: string) {
    let toppingsWithoutTireInflation: [] = this.toppingsPicked.filter((e:any) => e.ProductId != id);
    this.store.dispatch({ type: KamikazeActions.SetToppingsPicked, toppingsPicked: toppingsWithoutTireInflation });
  }

  addWashing(event: Map<string, ToppingSummaryModel>) {
    const category = 'Wash';
    
    const elemsToAdd: any[] = [];
    event.forEach((value, key) => {
      elemsToAdd.push({id: value.id, code: key})
    });
    
    this.washingToppingSettings.elementsAdded = elemsToAdd;
    let toppingsData:ToppingData[] = [] 
    
    Array.from(event.values()).forEach((v: ToppingSummaryModel) => {
       toppingsData.push({ProductId: v.id, Code: v.code, Category: v.category, ServiceConfiguration: v.serviceConfiguration})
    });

    const otherToppings = this.toppingsPicked.filter((e:any) => e.Category != category);
    const sendTopping = [... otherToppings, ...toppingsData]

    this.store.dispatch({ type: KamikazeActions.SetToppingsPicked, toppingsPicked: sendTopping });

    this.setPage('Home');
  }
    
  addRefuel(event: any) {   
    const category = 'Refuel';
    let toppingsData:ToppingData[] = [];

    this.refuelToppingSettings.elementsAdded = [event];
    
    const serviceConfiguration = {
      type: event.type,
      amount: event.amount,
    } 
    
    toppingsData.push({ProductId: event.id, Code: ToppingEnum.refuel, Category: category, ServiceConfiguration: serviceConfiguration})

    const otherToppings = this.toppingsPicked.filter((e:any) => e.Category != category);
    const sendTopping = [...otherToppings, ...toppingsData]
 
    
    this.store.dispatch({ type: KamikazeActions.SetToppingsPicked, toppingsPicked: sendTopping });
    this.setPage('Home');
  }


  addInsurance(event: any) {   
    const category = 'Insurance';
    let toppingsData:ToppingData[] = [];

    this.insuranceToppingSettings.elementsAdded = [event];
    
    const serviceConfiguration = {
      brand: event.brand,
      model: event.model,
      firstName: event.ownerFirstName,
      lastName: event.ownerLastName,
      insuranceDurationAmount: event.durationAmount,
      insuranceDurationType: event.durationType,
      documentNumber: event.documentNumber,
      documentType: event.documentType,
      licansePlate: event.licensePlate,
      vehicleRegistrationCountry: event.vehicleRegistrationCountry
    } 
    
    toppingsData.push({ProductId: event.id, Code: ToppingEnum.insurance, Category: category, ServiceConfiguration: serviceConfiguration})

    const otherToppings = this.toppingsPicked.filter((e:any) => e.Category != category);
    const sendTopping = [...otherToppings, ...toppingsData]
 
    this.store.dispatch({ type: KamikazeActions.SetToppingsPicked, toppingsPicked: sendTopping });
    this.setPage('Home');
  }

  deleteInsurance(event: any) {
    const category = 'Insurance';
    this.insuranceToppingSettings.elementsAdded = [];

    const otherToppings = this.toppingsPicked.filter((e:any) => e.Category != category);
    const sendTopping = [...otherToppings]

    this.store.dispatch({ type: KamikazeActions.SetToppingsPicked, toppingsPicked: sendTopping });
    this.setPage('Home');
  }

  deleteRefuel(event: any) {
    const category = 'Refuel';

    this.refuelToppingSettings.elementsAdded = [];

    const otherToppings = this.toppingsPicked.filter((e:any) => e.Category != category);
    const sendTopping = [...otherToppings]

    this.store.dispatch({ type: KamikazeActions.SetToppingsPicked, toppingsPicked: sendTopping });
    this.setPage('Home');
  }
    
  sendToppings(){
    this.store.dispatch({ type: KamikazeActions.BookToppings });
    this.ref.close({ info: 'confirm' });
  }

  ngOnDestroy(): void {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }
}
