import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { CaflerIconSettingsModel } from '@cafler/common-ui';
import { WashDetailsSettingsModel } from './models/wash-details-settings.model';
import { WashDetailsRef } from './wash-details-popup-ref';

@Component({
  selector: 'wash-details-popup',
  templateUrl: './wash-details-popup.component.html',
  styleUrls: ['./wash-details-popup.component.scss', './popup.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WashDetailsPopupComponent implements OnInit {

  constructor(private ref: WashDetailsRef) { }

  settings!: WashDetailsSettingsModel;

  closeIcon = <CaflerIconSettingsModel>{
    name: 'caf-close',
    alt: 'Close icon',
    height: '19px',
    width: '19px',
  };
  
  ngOnInit(): void {
    this.settings = this.ref.data;
  
    this.settings.whatIncludesTitle
    ? this.settings.whatIncludesTitle = this.settings.whatIncludesTitle
    : this.settings.whatIncludesTitle = "cafler.wash-details.whatIncludes.title";
  }


  
  cancel() {
    this.ref.close({ info: 'cancel' });
  }
}
