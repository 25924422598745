<form class="form-container" [formGroup]="fg">
    <h2>{{'cafler.product.maintenance-selector.subtitle' | translate}}</h2>
    <div *ngIf="productsSettings.products.length > 2" class="cards-container">
      <caf-product-card
      class="card"
      [class]="'only-premot-card'"
      [settings]="productsSettings.products[0]"
      (productSelected)="chooseProduct($event)"
      (moreDetails)="showMoreDetails(productsSettings.products[0].alias)"
    ></caf-product-card>
      <caf-product-card
      class="card"
      [class]="'premot-mot-card'"
      [settings]="productsSettings.products[1]"
      (productSelected)="chooseProduct($event)"
      (moreDetails)="showMoreDetails(productsSettings.products[1].alias)"
    ></caf-product-card>
      <caf-product-card
      class="card"
      [class]="'premot-mot-card'"
      [settings]="productsSettings.products[2]"
      (productSelected)="chooseMaintenanceType($event)"
      (moreDetails)="showMoreDetails(productsSettings.products[2].alias)"
    ></caf-product-card>  
    </div>
  </form>