import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { CafNotificationSettingsModel, CaflerErrorSettingsModel, CaflerIconSettingsModel } from '@cafler/common-ui';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { takeUntil, first, pairwise } from 'rxjs/operators';
import { operativeZonesURLByCountry } from 'src/app/config/operativeZonesURLByCountry';
import { KamikazeFacade } from 'src/app/facades/kamikaze.facade';
import { PresentationFacade } from 'src/app/facades/presentation.facade';
import { SingleDataHelperService } from 'src/app/helpers/single-data-helper.service';
import { TimeSlot } from 'src/app/models/types';
import { FormsHelperService } from 'src/app/services/forms.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { datepickerMaxDate } from 'src/app/services/utils';
import { KamikazeActions } from 'src/app/store/actions';
import { selectCurrentCountry, selectCurrentLang, selectCurrentZone, selectIsB2B, selectProductData, selectServiceTimeOptions } from 'src/app/store/selectors';

@Component({
  selector: 'app-allYouNeed-product',
  templateUrl: './allYouNeed-product.component.html',
  styleUrls: ['./allYouNeed-product.component.scss']
})
export class AllYouNeedProductComponent implements OnInit, OnDestroy {

  isLoading: boolean = true;
  isB2B!: boolean;
  private readonly destroySubjects$ = new ReplaySubject<void>(1);
  fg!: FormGroup;  
  initialForm: any;
  isDuplicated: boolean = false;
  timesFailedPickupAddress = 0;
  timesFailedDropoffAddress = 0;
  maxErrorsOnAddress = 4;
  combinationNotValid: boolean = false;
  readyToDisplayHours = false;
  dayChangedFlag = false;
  currentCountry: any;
  operativeZonesURL: string = '';
  noRangeHourAvailable: boolean = false;
  serviceTimeOptions: TimeSlot[] = [];
  pastDateWhenDuplicatingError: boolean = false;
  zoneId: string = '';

  @ViewChild('startServiceTimeComponent') startServiceTimeComponent: any;
  @ViewChild('endServiceTimeComponent') endServiceTimeComponent: any;


  explanation = [
    {
      text: 'cafler.product.all-you-need-product.step-1',
      icon : {
        name: 'caf-transfer-black',
        alt: '',
        width: '20px',
        height: '20px',
      }
    },
    {
      text: 'cafler.product.all-you-need-product.step-2',
      icon : {
        name: 'caf-paper-check',
        alt: '',
        width: '20px',
        height: '20px',
      }
    },
    {
      text: 'cafler.product.all-you-need-product.step-3',
      icon : {
        name: 'caf-simple-car',
        alt: '',
        width: '20px',
        height: '20px',
      }
    },
  ]

  settings: any = {
    pickupDate: {
      id: 'pickupDate',
      formControlName: 'pickupDate',
      placeholder: 'cafler.pickup-info.pickup-day.placeholder',
      minDate: new Date(),
      maxDate: datepickerMaxDate(),
      daysDisabled: [6,0],
      icon: <CaflerIconSettingsModel>{
        alt: 'calendar',
        name: 'caf-notes-book-dark',
      },
      lang: 'currentLang'
    },
    startPickUpTime: {
      id: 'startPickUpTime',
      formControlName: 'startPickUpTime',
      placeholder: 'cafler.page.service-time.from-placeholder',
      disabled: false,
      options: [],
      required: true,
      leftIconSettings: {
        name: 'caf-clock-cafler',
        alt: 'Clock icon',
        height: '25px',
        width: '25px',
      },
    },
    endPickUpTime: {
      id: 'endPickUpTime',
      formControlName: 'endPickUpTime',
      placeholder: 'cafler.page.service-time.until-placeholder',
      disabled: true,
      options: [],
      required: false,
      leftIconSettings: {
        name: 'caf-clock-cafler',
        alt: 'Clock icon',
        height: '25px',
        width: '25px',
      },
    },
    fullName: {
      id: 'fullName',
      formControlName: 'fullName',
      placeholder: 'cafler.contact.name.placeholder',
      disabled: false,
      type: 'text',
    },
    description: {
      id: 'textarea',
      formControlName: 'description',
      placeholder: 'cafler.page.all-you-need-product.textarea.placeholder',
      minlength: 0,
      maxlength: 99999999,
      minHeight: '213px',
    },
    countryPhoneCode: {
      id: 'countryPhoneCode',
      formControlName: 'countryPhoneCode',
      placeholder: 'cafler.contact.country-phone-code.placeholder',
      disabled: false,
      options: [],
    },
    phone: {
      id: 'phone',
      formControlName: 'phone',
      placeholder: 'cafler.contact.phone.placeholder',
      disabled: false,
      type: 'phone',
    },
    email: {
      id: 'email',
      formControlName: 'email',
      placeholder: 'cafler.contact.email.placeholder',
      disabled: false,
      type: 'email',
    },

  };

  settingsExtra: any = {
    locationIcon: <CaflerIconSettingsModel>{
      alt: 'location',
      name: 'caf-pin-location',
    },

    infoIcon: <CaflerIconSettingsModel> {
      name: 'caf-info-tooltip-outline',
      alt: 'Info icon',
    },
    warningIcon: <CaflerIconSettingsModel> { name: 'caf-warning-exclamation', alt: 'warning', width: '14px', height: '14px'},

    disclaimerSettings: <CafNotificationSettingsModel>{
      type: 'info',
      text: "cafler.page.all-you-need-disclaimer",
      fontSize: '16px',
    },


  };

  settingsErrors: any = {
    errorsFullName: <CaflerErrorSettingsModel>{
      formControlName: 'fullName',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsCountryPhoneCode: <CaflerErrorSettingsModel>{
      formControlName: 'countryPhoneCode',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
   
    errorsPhone: <CaflerErrorSettingsModel>{
      formControlName: 'phone',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'pattern', text: 'cafler.forms.error.phone-pattern' },
        { key: 'minlength', text: 'cafler.forms.error.min-length' },
      ],
    },
    
    errorsEmail: <CaflerErrorSettingsModel>{
      formControlName: 'email',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'email', text: 'cafler.forms.error.email' },
      ],
    },
    
    errorsPickupDate: <CaflerErrorSettingsModel>{
      formControlName: 'pickupDate',
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'notPresent', text: 'cafler.forms.error.date-not-in-past' },
      ],
    },
    errorsStartPickUpTime: <CaflerErrorSettingsModel>{
      formControlName: 'startPickUpTime',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    errorsEndPickUpTime: <CaflerErrorSettingsModel>{
      formControlName: 'endPickUpTime',
      errors: [{ key: 'required', text: 'cafler.forms.error.required' }],
    },
    
    errorsPickupAddress: <CaflerErrorSettingsModel>{
      formControlName: 'pickupAddress',
      
      errors: [
        { key: 'required', text: 'cafler.forms.error.required' },
        { key: 'selectedAddress', text: 'cafler.forms.error.selected-address' },
        {
          key: 'streetNumberNeeded',
          text: 'cafler.address-message-error-missing-street-number.title',
        },

        { key: 'addressNumber', text: 'cafler.forms.error.address-number' },
        {
          key: 'checkCoordinatesWithinCaflerArea',
          text: 'cafler.forms.error.cafler-country-coverage',
        },
      ],
    },
  };
  
  constructor(
    private store: Store,
    private formsService: FormsHelperService,
    private kamikazeFacade: KamikazeFacade,
    private navigationService: NavigationService,
    private presentationFacade: PresentationFacade,
    private singleDataHelperService: SingleDataHelperService,
  ) { 
    this.initialForm = history.state.initialForm ? history.state.initialForm : null;
    this.isDuplicated = history.state.isDuplicated ? history.state.isDuplicated : false;

    this.fg = this.formsService.initializeForm(this.settings, this.initialForm);

    this.store.select(selectCurrentZone).subscribe((zone: any) => {
      if (zone) {
        this.zoneId = zone?.ZoneId;
      }
    });
    
    this.store.select(selectCurrentCountry).subscribe((country: any) => {
      if (country) {
        // setting default prefix code number
        this.fg.get('countryPhoneCode')?.setValue(country.InternationalPhonePrefix);
        this.fg.get('driverCountryPhoneCode')?.setValue(country.InternationalPhonePrefix);
        this.currentCountry = country;

        const isoCode: string = country.RegionIsoCode 
        if(isoCode === 'esp' || isoCode === 'gbr' || isoCode === 'fra'){
          this.operativeZonesURL = operativeZonesURLByCountry[isoCode]
        }
      }
    });

    this.store.select(selectCurrentLang).subscribe((lang) => {
      if(lang){
        this.settings.pickupDate = {
          ...this.settings.pickupDate,
          lang: lang.split('-')[0]
        }
      } 
    })
  }

  ngOnInit(): void {
    this.navigationService.currentPageTitle = 'cafler.product.all-you-need-product.title';

    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: true });
    this.store.dispatch({ type: KamikazeActions.SetNextPage, nextPage: 'overview' });
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'transfer-form' });
    this.store.dispatch({ type: KamikazeActions.ClearAvailableToppings });
  
    this.store.dispatch({
      type: KamikazeActions.SetFormValidity,
      isFormValid: this.formsService.checkCurrentFormValidity(),
    });
  
    this.setMinimumPickUpDateOnTomorrow()

    this.store.select(selectIsB2B).subscribe((isB2B) => {
      this.isB2B = isB2B;
    });

     this.store.select(selectServiceTimeOptions).subscribe((serviceTimeOptions) => {
      if(serviceTimeOptions !== undefined){
        this.serviceTimeOptions = serviceTimeOptions;
      }
    })

    this.fg
      .get(this.settings.pickupDate.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .pipe(first())
      .subscribe((date: Date) => {
        this.readyToDisplayHours = true;
        this.getSlotHours(); 
    });

    this.fg
      .get(this.settings.pickupDate.formControlName)
      ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
      .pipe(pairwise())
      .subscribe(([prev, next] : [Date, Date] ) => {
        if((prev.getTime() === next.getTime())){
          return;
        }
        if (this.readyToDisplayHours) {
          this.resetServiceTimeStyles(true);
          this.getSlotHours();
        }
        this.readyToDisplayHours = true;
    });

    this.fg
      .get(this.settings.startPickUpTime.formControlName)
      ?.statusChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((status) => {
        this.changeEndPickupTimeDisability(status)
    });

    this.fg
      .get(this.settings.endPickUpTime.formControlName)
      ?.statusChanges.pipe(takeUntil(this.destroySubjects$))
      .subscribe((status) => {
        if (status === 'VALID') {
          this.combinationNotValid = false;
        }
    });

    this.presentationFacade
      .getCountryCodes()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((countryCodes) => {
        this.settings.countryPhoneCode.options = countryCodes;
    });

    this.singleDataHelperService
      .getCurrentVerticalProducts()
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((products: any) => {
        if (products) {
          this.store.dispatch({
            type: KamikazeActions.SetSelectedProduct,
            selectedProduct: products[0],
          });
        }
    });

    this.store
      .select(selectProductData)
      .pipe(takeUntil(this.destroySubjects$))
      .subscribe((productData) => {
        if (this.isLoading) {
          if (!!productData) {
            this.formsService.loadFormGroupData(this.fg, productData, this.isLoading);
            this.isLoading = false;
          } else {
            if (this.isB2B) {
              this.formsService.preloadB2BData(this.fg);
            }
          
            // this.fg.controls.serviceType.setValue(this.navigationService.getSelectedProduct());
          }
        }
      });

      this.presentationFacade.getHolidays().subscribe((holidays) => {
        let holidaysDates = [];
        if(holidays){
          holidaysDates = holidays.map((day: any) => {
            return new Date(day);
          });
        }
  
  
        this.settings.pickupDate = {
          ...this.settings.pickupDate,
          datesDisabled: holidaysDates,
        };
      });

      this.fg.statusChanges.pipe(takeUntil(this.destroySubjects$)).subscribe((status) => {
        this.formsService.handleFormValidity(status, this.fg.value);
      });
  
      this.fg
        .get(this.settings.startPickUpTime.formControlName)
        ?.valueChanges.pipe(takeUntil(this.destroySubjects$))
        .subscribe((newValue) => {
          this.updateEndRangeHours(newValue);
        });
  }


  getSlotHours() {
    this.presentationFacade.getAllYouWantTimeOptions(
      this.fg.get(this.settings.pickupDate.formControlName)?.value)
      .pipe(first())
      .subscribe((res: TimeSlot[]) => {

        this.settings.startPickUpTime.options = res.slice(0, -3);
        this.settings.endPickUpTime.options = res.slice(3);
    
        this.fg.controls[this.settings.endPickUpTime.formControlName].addValidators(Validators.required);
    
        this.noRangeHourAvailable = res.length < 3;
        this.settings.startPickUpTime.disabled = this.noRangeHourAvailable;
        
        this.store.dispatch({
          type: KamikazeActions.SetServiceTimeOptions,
          serviceTimeOptions: res,
        });
      });

  }

  resetServiceTimeStyles(dayChangedMode?: boolean) {
    if(this.startServiceTimeComponent !== undefined){
      this.startServiceTimeComponent.resetValue();
    }
    if(this.endServiceTimeComponent !== undefined){
      this.endServiceTimeComponent.resetValue();
    }
    
    this.combinationNotValid = false;
    if (dayChangedMode && this.serviceTimeOptions.length > 0) {
      this.dayChangedFlag = true;
    } else {
      this.dayChangedFlag = false;
    }
  }

  updateEndRangeHours(valueSelected: any) {
    this.store.select(selectServiceTimeOptions).subscribe((serviceTimeOptions) => {
      this.serviceTimeOptions = serviceTimeOptions;

      if(!serviceTimeOptions || serviceTimeOptions && serviceTimeOptions.length === 0) return 
 
      // Convert the value in a selectable element
      let elementSelected = serviceTimeOptions.find((el: any) => el.key === valueSelected);
  
      // Update the end time select with new values
      this.settings.endPickUpTime.options = serviceTimeOptions.slice(
        this.settings.startPickUpTime.options.indexOf(elementSelected) + 3,
      );
  
      // Check if the end value is still valid
      let startPickUpTimeControl = this.fg.get('startPickUpTime');
      let endPickUpTimeControl = this.fg.get('endPickUpTime');
      
      const endPickUpTime = serviceTimeOptions.slice(3);

      let isEndHourInValidRange = endPickUpTime.find(
        (el: any) => el.key === endPickUpTimeControl?.value,
      );
  
      // Update end value
      if (startPickUpTimeControl?.valid && endPickUpTimeControl?.value && !isEndHourInValidRange) {
        this.fg.get('endPickUpTime')?.patchValue(null);
        this.combinationNotValid = true;
      }
    })
  }

  changeEndPickupTimeDisability(status: string){
    if (status === 'VALID') {
      this.settings.endPickUpTime.disabled = false;
      this.dayChangedFlag = false;
    } else {
      this.settings.endPickUpTime.disabled = true;
    }
  }

  setMinimumPickUpDateOnTomorrow(){
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.settings.pickupDate.minDate = tomorrow;
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();
  }
}
