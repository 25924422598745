import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import {TimeFormatterService} from '../helpers/time-formatter.service';


@Injectable()
export class PickUpDropOffDateValidator {
  constructor(private timeFormatterService: TimeFormatterService) {}

  createValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      
      if (!!control.get('pickupDate')?.value) {
          const pickUpDate = new Date(control.get('pickupDate')?.value);
  
          if (!!control.get('dropoffDate')?.value) {
              const dropoffDate = new Date(control.get('dropoffDate')?.value);
              
              if(pickUpDate > dropoffDate) return { dropOffDateIsLaterThanPickOff: true };
  
              if(!!control.get('startPickUpTime')?.value && !!control.get('endPickUpTime')?.value) {
                return this.checkValidRangeTime(control.get('startPickUpTime')?.value, control.get('endPickUpTime')?.value)
              }
          }
      }
    
      return null;
    };
  }

  checkValidRangeTime (startTime: string, endTime: string){
    const formattedStartDate = this.timeFormatterService.removeLocationAndOffset(startTime)
    const formattedEndDate = this.timeFormatterService.removeLocationAndOffset(endTime)
  
    if(formattedStartDate >= formattedEndDate) return { endPickUpTimeIsLaterThanStartPickUpTime: true };
    return null;
  }
}






