import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopupSettingsModel } from './model/pop-up-settings.model';
import { PopUpRef } from './pop-up-ref';

@Component({
  selector: 'pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss', './popup.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopUpComponent implements OnInit {
  @ViewChild('content', { read: ViewContainerRef }) container!: ViewContainerRef;

  contentType: 'template' | 'string' | 'component' | undefined;
  content: any;
  context: any;
  settings!: PopupSettingsModel;
  value: string = '';
  closeSub!: Subscription;

  constructor(private ref: PopUpRef, private resolver: ComponentFactoryResolver) { }

  cancel() {
    this.ref.close({ info: 'cancel' });
  }
  
  confirm() {
    this.ref.close({ info: 'confirm' });
  }

  ngOnInit() {  
     this.settings = this.ref.data;
  }
  
  ngAfterViewInit() {
      setTimeout(() => {
        this.addComponent(this.ref.content);
      },0)
  }

  addComponent(component: any) {
    const factory = this.resolver.resolveComponentFactory(component);
    const componentRef: ComponentRef<any> = this.container.createComponent(factory);
    
    this.closeSub = componentRef.instance.closeEvent?.subscribe(() => {
      this.closeSub.unsubscribe();
      this.cancel()
    })

  }
}
