import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SingleDataHelperService } from 'src/app/helpers/single-data-helper.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { KamikazeActions } from 'src/app/store/actions';
import { selectIsVerticalProductsLoaded } from 'src/app/store/selectors';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-formalities-selector',
  templateUrl: './formalities-selector.component.html',
  styleUrls: ['./formalities-selector.component.scss']
})
export class FormalitiesSelectorComponent implements OnInit {

  fg!: FormGroup;
  productsSettings: any = {
    id: 'formalities-selector',
    formControlName: 'productSelector',
    isTopping: false,
    products: [],
  };
  private readonly destroySubjects$ = new ReplaySubject<void>(1);

  constructor(
    private router: Router,
    private store: Store,
    private navigationService: NavigationService,
    private singleDataHelperService: SingleDataHelperService,
    private activatedRoute: ActivatedRoute,

  ) {
    const formControlName = this.productsSettings.formControlName;
    this.fg = new FormGroup({});
    this.fg.addControl(formControlName, new FormControl(null, [Validators.required]));
  }

  ngOnInit(): void {
    this.store.dispatch({ type: KamikazeActions.SetCurrentPage, currentPage: 'valet-selector' });
    this.store.dispatch({ type: KamikazeActions.SetFooterVisibility, isFooterVisible: false });
    this.navigationService.currentPageTitle = 'cafler.product.formalities.vertical.title';

    this.store.select(selectIsVerticalProductsLoaded)
    .pipe(takeUntil(this.destroySubjects$))
    .subscribe((productsLoaded) => {
      if (productsLoaded)
        this.singleDataHelperService.getCurrentVerticalProducts()
        .pipe(takeUntil(this.destroySubjects$))
        .subscribe((products: any) => {
          if(products){
            this.productsSettings.products = products.formalities;
            this.productsSettings.products[0].price="";
            this.productsSettings.products[0].fromPriceKey = 'cafler_tyres_placeholder_prices'
            this.productsSettings.products.push(...this.createDisabledProducts());
            
          }
        });
    });
  }


  pickProduct(productAlias: any) {
    const CHANGE_OWNERSHIP_ALIAS = "ownership-change"

    if(productAlias === CHANGE_OWNERSHIP_ALIAS){
      window.location.href = environment.ownerShipChangeURL
    }
  }

  createDisabledProducts() {
    return [
      {
        "id": "18138476-d32b-4b67-9f5b-a71ba76d468e",
        "price": "",
        "fromPriceKey": "",
        "title": "cafler.product-type.penalty-management.title",
        "description": "cafler.product-type.penalty-management.description",
        "icon": {
          name: `caf-penalty-management-3D`,
          alt: `caf-penalty-management-3D`
        },
        "alias": "penalty-management",
        "alt": "cafler.product-type.penalty-management.title",
        "isPaymentRequired": true,
        "holidays": [],
        "ordersManagerProductId": "18138476-d32b-4b67-9f5b-a71ba76d467e",
        "category": "Valet",
        "disabled": true,
        "badgeType": {
          "text": "cafler.coming.soon",
          "color": "greenCafler",
          "icon": ""
      }
    },
    {
      "id": "18138476-d32b-4b67-9f5b-a71ba76d469e",
      "price": "",
      "fromPriceKey": "",
      "title": "cafler.product-type.circulation-tax.title",
      "description": "cafler.product-type.circulation-tax.description",
      "icon": {
        name: `caf-circulation-tax-3D`,
        alt: `caf-circulation-tax-3D`
      },
      "alias": "circulation-tax",
      "alt": "cafler.product-type.circulation-tax.title",
      "isPaymentRequired": true,
      "holidays": [],
      "ordersManagerProductId": "18138476-d32b-4b67-9f5b-a71ba76d467e",
      "category": "Valet",
      "disabled": true,
      "badgeType": {
        "text": "cafler.coming.soon",
        "color": "greenCafler",
        "icon": ""
    }
  }]
  }

  ngOnDestroy() {
    this.destroySubjects$.next();
    this.destroySubjects$.complete();

  }

}
